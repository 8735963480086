import React from 'react';
import { withTheme } from 'styled-components';

const TrackingContact = ({ theme }: any) => {
  return (
    <svg width="88" height="89" viewBox="0 0 88 89" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M85.25 18.0058C85.2156 9.65272 78.3922 2.91522 70.0391 2.96679C62.2703 3.00116 55.8078 8.91366 55.0687 16.6308H6.875C4.58906 16.6308 2.75 18.4699 2.75 20.7558V81.2559C2.75 83.5418 4.58906 85.3809 6.875 85.3809H67.375C69.6609 85.3809 71.5 83.5418 71.5 81.2559V57.8809C73.7859 57.8809 75.625 56.0418 75.625 53.7559V40.0059C75.625 39.2496 75.0062 38.6309 74.25 38.6309V32.5465C80.7469 30.6902 85.2328 24.7605 85.25 18.0058ZM70.125 5.63085C76.9656 5.63085 82.5 11.1652 82.5 18.0058C82.5 24.8465 76.9656 30.3808 70.125 30.3808C63.2844 30.3808 57.75 24.8465 57.75 18.0058C57.75 11.1824 63.3016 5.63085 70.125 5.63085ZM31.625 19.3808H42.625V47.6887L38.1047 43.1684C37.5719 42.6355 36.6953 42.6355 36.1625 43.1684L31.625 47.6887V19.3808ZM68.75 81.2559C68.75 82.0121 68.1312 82.6309 67.375 82.6309H6.875C6.11875 82.6309 5.5 82.0121 5.5 81.2559V20.7558C5.5 19.9996 6.11875 19.3808 6.875 19.3808H28.875V51.0059C28.875 51.7621 29.4937 52.3809 30.25 52.3809C30.6109 52.3809 30.9719 52.2434 31.2297 51.9855L37.125 46.0902L43.0203 51.9855C43.5531 52.5184 44.4297 52.5184 44.9625 51.9855C45.2203 51.7277 45.3578 51.384 45.3578 51.0059V19.3808H55.0516C55.6359 25.6027 59.9672 30.8277 65.9828 32.5465V38.6309C65.2266 38.6309 64.6078 39.2496 64.6078 40.0059V53.7559C64.6078 56.0418 66.4469 57.8809 68.7328 57.8809V81.2559H68.75ZM72.875 53.7559C72.875 54.5121 72.2562 55.1309 71.5 55.1309H68.75C67.9938 55.1309 67.375 54.5121 67.375 53.7559V41.3809H72.875V53.7559ZM71.5 38.6309H68.75V33.0621C69.1969 33.0965 69.6609 33.1308 70.125 33.1308C70.5891 33.1308 71.0531 33.0965 71.5 33.0621V38.6309Z"
        fill={theme.colors.Primary}
      />
      <path d="M44 66.1309H46.75V74.3809H44V66.1309Z" fill={theme.colors.Primary} />
      <path d="M57.75 66.1309H60.5V74.3809H57.75V66.1309Z" fill={theme.colors.Primary} />
      <path
        d="M64.625 60.6309H39.875C39.1187 60.6309 38.5 61.2496 38.5 62.0059V78.5059C38.5 79.2621 39.1187 79.8809 39.875 79.8809H64.625C65.3813 79.8809 66 79.2621 66 78.5059V62.0059C66 61.2496 65.3813 60.6309 64.625 60.6309ZM63.25 77.1309H41.25V63.3809H63.25V77.1309Z"
        fill={theme.colors.Primary}
      />
      <path d="M49.5 68.8809H55V71.6309H49.5V68.8809Z" fill={theme.colors.Primary} />
      <path d="M60.5 16.6309H79.75V19.3809H60.5V16.6309Z" fill={theme.colors.Primary} />
      <path d="M66 11.1309H74.25V13.8809H66V11.1309Z" fill={theme.colors.Primary} />
      <path d="M66 22.1309H74.25V24.8809H66V22.1309Z" fill={theme.colors.Primary} />
    </svg>

  );
};

export default withTheme(TrackingContact);
