import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getAccountCredit } from '../../../../../../../../../utils/APICalls/Account';
import { Button } from '../../../../../../../../../../assets/elements';
import TopUp from '../../../../../../../../TopUp';
import Modal from '../../../../../../../../../../assets/Combos/Modal';
import Loader from '../../../../../../../../Loader';

const AccountCredit = ({ value, setCreditError }: any) => {
  const [credit, setCredit] = useState(0);
  const [error, setError] = useState(false);
  const [topUpOpen, setTopUpOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    getAccountCredit()
      .then((res: any) => {
        setCredit(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (credit < value) {
      setError(true);
      setCreditError(true);
    } else {
      setError(false);
      setCreditError(false);
    }
  }, [credit]);

  const handleModalClose = (refresh: any = false) => {
    if (refresh) {
      setLoading(true);

      getAccountCredit()
        .then((res: any) => {
          setCredit(res.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    setTopUpOpen(false);
  };

  return (
    loading ? (<LoaderStyled>Loading</LoaderStyled>) :
    <Wrapper error={error}>
      {
        error ? (
          <span>
            You currently have £{ credit } in Account Credit.
            This is not enough for your current order.
            <ButtonStyled
              color="dark"
              onClick={() => setTopUpOpen(true)}
            >
              Top up account balance
            </ButtonStyled>
          </span>
        ) :
          `You will have £${ (credit - value).toFixed(2) } left in your account after this payment`
      }
      {
        error && (
          <Modal
            title="Top up account balance"
            open={topUpOpen}
            close={(refresh: any) => handleModalClose(refresh)}
            back={false}
            width={6}
            closeIcon={false}
          >
            <TopUp close={(refresh: any) => handleModalClose(refresh)} />
          </Modal>
        )
      }
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs(({ error }: any) => ({
  error
}))`
  display: flex;
  margin-top: 16px;
  padding: 17px 11px;
  background: ${({ error }: any) => error ? '#F9DBE0' : '#f2f2f2'};;
  color: ${({ theme, error }: any) => error ? theme.colors.Error : theme.colors.Black};
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  border-radius: 4px;
`;

const ButtonStyled = styled(Button)`
  display: block;
  padding: 6px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-top: 22px;
`;

const LoaderStyled = styled(Loader)`
  font-size: 14px;
  padding: 6px 12px;
`;

export default AccountCredit;
