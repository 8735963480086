import React  from 'react';
import { Switch, Route, withRouter } from 'react-router';
import Header from '../Header';
import Quotes from '../Quotes';
import HomePage from '../HomePage';
import Order from '../Orders/components/Order';
import Tracking from '../TrackingPage';
import TrackingInfo from '../TrackingInfo';
import OrderSuccess from '../Orders/components/Steps/Success';
import Login from '../Login';
import Register from '../Register';
import RegisterConfirm from '../Register/RegisterConfirm';
import Account from '../Account';
import ForgotPassword from '../ForgotPassword';
import NewAccount from '../NewAccount';
import ResetPassword from '../ResetPassword';
import CountryNameRedirect from '../CountryNameRedirect';
import Logout from '../Logout';
import EmailVerified from '../EmailVerified';
import TokenExpired from '../EmailVerificationExpired';
import MyAccount from '../MyAccount';
import { metaTags } from '../../utils/metaTags';
import { homePage, HomePage as Home } from '../../utils/homePage';
import ConfirmPayPal from '../Orders/components/Steps/ConfirmPayPal';
import QuickBook from '../QuickBook';
import MultiBook from '../MultiBook';

import KriyaSuccess from '../Orders/components/Steps/Kriya/WebSuccess'
import KriyaFailed from '../Orders/components/Steps/Kriya/WebFailed'

import KriyaManualSuccess from '../Orders/components/Steps/Kriya/ManualSuccess';
import KriyaManualFailed from '../Orders/components/Steps/Kriya/ManualFailed';

const Router: React.FC = () => {
  return (
    <>
      { metaTags }
      <Header />
      <Switch>
        <Route exact path="/" component={HomePage} />
        {
          homePage === Home.PARCEL && (
            <Route path="/pallet-delivery" component={HomePage} />
          )
        }
        {
          homePage === Home.PARCEL && (
            <Route path="/freight-delivery" component={HomePage} />
          )
        }
        {
          homePage === Home.PALLET && (
            <Route path="/parcel-delivery" component={HomePage} />
          )
        }
        {
          homePage === Home.PALLET && (
            <Route path="/freight-delivery" component={HomePage} />
          )
        }
        {
          homePage === Home.FREIGHT && (
            <Route path="/parcel-delivery" component={HomePage} />
          )
        }
        {
          homePage === Home.FREIGHT && (
            <Route path="/pallet-delivery" component={HomePage} />
          )
        }
        <Route
          path="/quotes/:type/:transit/:collection/:delivery/:date/:hazardous/:stackable/:customs/:all/:load/:subType/:items"
          component={() => <Quotes />}
        />
        <Route
          path="/quotes/:type/:transit/:collection/:delivery/:date/:hazardous/:stackable/:customs/:all/:load"
          component={() => <Quotes />}
        />
        <Route
          path="/quotes/:type/:transit/:collection/:delivery/:date/:items"
          component={() => <Quotes />}
        />
        <Route
          path="/quotes/:type/:collection/:delivery/:items/:palletAndWrap"
          component={() => <Quotes />}
        />
        <Route
          path="/quotes/:collection/:delivery/:items"
          component={() => <Quotes />}
        />
        <Route path="/quotes" component={() => <Quotes />} />
        <Route path="/tracking" component={Tracking} />
        <Route path="/track" component={TrackingInfo} />
        <Route path="/order-step1" component={() => <Order />} />
        <Route path="/order-step2" component={() => <Order />} />
        <Route path="/order-step3" component={() => <Order />} />
        <Route path="/order-basket" component={() => <Order />} />
        <Route path="/payment" component={() => <Order />} />
        <Route path="/confirmpaypal" component={ConfirmPayPal} />
        <Route path="/success" component={() => <OrderSuccess />} />
        <Route path="/login" component={() => <Login />} />
        <Route path="/logout" component={() => <Logout />} />
        <Route path="/register" component={() => <Register />} />
        <Route path="/verified" component={() => <EmailVerified />} />
        <Route path="/token-expired" component={() => <TokenExpired />} />
        <Route path="/forgot-password" component={() => <ForgotPassword />} />
        <Route path="/new-account" component={() => <NewAccount />} />
        <Route path="/reset-password" component={() => <ResetPassword />} />
        <Route path="/settings" component={() => <MyAccount />} />
        <Route path="/dashboard" component={() => <MyAccount />} />
        <Route path="/quick-book" component={() => <QuickBook />} />
        <Route path="/multi-book" component={() => <MultiBook />} />
        <Route path="/invoices" component={() => <MyAccount />} />
        <Route path="/address-book" component={() => <MyAccount />} />
        <Route path="/redirect" component={CountryNameRedirect} />
        <Route
          path="/register-confirmation"
          component={() => <RegisterConfirm />}
        />
        <Route path="/account" component={() => <Account />} />
        
        <Route path="/kriya/success" component={KriyaSuccess} />
        <Route path="/kriya/failed" component={KriyaFailed} />

        <Route path="/manual/success" component={KriyaManualSuccess} />
        <Route path="/manual/failed" component={KriyaManualFailed} />
      </Switch>
    </>
  );
};

export default withRouter(Router);
