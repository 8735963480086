import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import { Button, Column, Row } from '../../../../../../assets/elements';
import BookerEmail from './components/BookerEmail';
import CollectionAddress from './components/CollectionAddress/CollectionAddress';
import CollectionDetails from './components/CollectionDetails/CollectionDetails';
import Service from '../Service';
import device from '../../../../../utils/device';
import {
  setCollectionAddress,
  setCollectionDetails,
  sendBasketData,
  setBasketItemId,
  setBookerEmail
} from '../../../../../store/actions';
import { getCookie, setCookie } from '../../../../../utils/cookies';

const StepOne = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const address = useSelector<any, any>(
    (state: any) => state.order.collectionAddress
  );
  const { type, vat } = useSelector<any, any>((state) => state.order.item);
  const order = useSelector((state: any) => state.order);
  const quoteId = useSelector<any, any>((state) => state.main.quoteId);
  const quoteUrl = useSelector<any, any>((state) => state.main.quoteUrl);

  const [addressData, setAddressData] = React.useState({
    company: '',
    addressOne: '',
    addressTwo: '',
    addressThree: '',
    city: '',
    country: '',
    zip: '',
  });
  const [bookerEmail, setBookerEmailData] = React.useState('');
  const [addressErrors, setAddressErrors] = React.useState<any>({
    company: false,
    addressOne: false,
    addressTwo: false,
    city: false,
    country: false,
    zip: false,
  });

  const [bookerEmailError, setBookerEmailError] = React.useState(false);
  const [bookerEmailErrorMessage, setBookerEmailErrorMessage] = React.useState('');

  useEffect(() => {
    setAddressData({
      ...addressData,
      ...address,
    });

    setCollectionDetailsData({
      ...collectionDetailsData,
      zip: address.zip,
      country: address.country,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const [collectionDetailsData, setCollectionDetailsData] = React.useState({
    name: '',
    addressOne: '',
    addressTwo: '',
    addressThree: '',
    city: '',
    country: '',
    email: '',
    zip: '',
    phone: '',
    reference: '',
    customsData: {
      VATStatus: (vat.customs && order.serviceType === 'pallet') ? 'company_vat' : '',
      VATNumber: '',
      EORINumber: '',
    },
  });

  const [collectionDetailsErrors, setCollectionDetailsErrors] = React.useState<any>({
    name: false,
    addressOne: false,
    city: false,
    country: false,
    zip: false,
    phone: false,
    email: false,
    customsData: {
      VATStatus: false,
      VATNumber: false,
      EORINumber: false,
    },
  });

  const requiredAddressKeys =
    type === 'dropoff' ? [] : ['addressOne', 'city', 'country', 'zip'];

  const requiredCollectionDetailsKeys =
    !type || type === 'collection'
      ? ['name', 'phone', 'email']
      : ['name', 'phone', 'addressOne', 'city', 'country', 'zip'];

  const [buttonDisabled, setButtonDisabled] = React.useState<any>(true);

  const validateAddress = () => {
    let error = false;
    let errors = { ...addressErrors };

    if (order.serviceType === 'seaFreight' && addressData.company === '') {
      errors = {
        ...errors,
        company: 'Required',
      };

      error = true;
    }

    if (
      addressData.company !== '' &&
      addressData.company.length < 3 ||
      addressData.company.length > 34
    ) {
      errors = {
        ...errors,
        company: 'This must be between 3 and 34 characters',
      };

      error = true;
    }

    if (addressData.addressOne === '') {
      errors = {
        ...errors,
        addressOne: 'Required',
      };

      error = true;
    } else if (addressData.addressOne.length > 30) {
      errors = {
        ...errors,
        addressOne: 'This must be less than 30 characters',
      };
    }

    if (
      addressData.addressTwo !== '' &&
      addressData.addressTwo.length > 30
    ) {
      errors = {
        ...errors,
        addressTwo: 'This must be less than 30 characters',
      };
    }

    if (addressData.city === '') {
      errors = {
        ...errors,
        city: 'Required',
      };

      error = true;
    } else if (addressData.city.length > 30) {
      errors = {
        ...errors,
        city: 'This must be less than 30 characters',
      };

      error = true;
    }

    if (addressData.country === '') {
      errors = {
        ...errors,
        country: 'Required',
      };

      error = true;
    }

    if (addressData.zip === '') {
      errors = {
        ...errors,
        zip: 'Required',
      };

      error = true;
    }

    setAddressErrors({ ...errors });

    return error;
  };

  const validateCollectionDetails = () => {
    let error = false;
    let errors = cloneDeep(collectionDetailsErrors);
    
    if (collectionDetailsData.name === '') {
      errors = {
        ...errors,
        name: 'Required',
      };

      error = true;
    } else if (collectionDetailsData.name.length > 30) {
      errors = {
        ...errors,
        name: 'This must be less than 30 characters',
      };

      error = true;
    }

    if (collectionDetailsData.phone === '') {
      errors = {
        ...errors,
        phone: 'Required',
      };

      error = true;
    } else if (
      !collectionDetailsData.phone.match(
        // eslint-disable-next-line
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
      )
    ) {
      errors = {
        ...errors,
        phone: 'Please enter valid phone number',
      };

      error = true;
    } else if (collectionDetailsData.phone.replace(' ', '').length < 11) {
      errors = {
        ...errors,
        phone: `Phone number must be at least ${10 - (collectionDetailsData.phone?.split(' ')[0].length - 1)} characters`,
      };

      error = true;
    } else if (collectionDetailsData.phone.replace(' ', '').length > 14) {
      errors = {
        ...errors,
        phone: `Phone number must less than or equal to ${13 - (collectionDetailsData.phone?.split(' ')[0].length - 1)} characters`,
      };

      error = true;
    }

    if (collectionDetailsData.email === '') {
      if (type !== 'dropoff') {
        errors = {
          ...errors,
          email: 'Required',
        };

        error = true;
      }
    } else if (collectionDetailsData.email.length > 50) {
      errors = {
        ...errors,
        email: 'Email must not exceed 50 characters',
      };

      error = true;
    } else if (
      !collectionDetailsData.email.match(
        //eslint-disable-line
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/
      )
    ) {
      errors = {
        ...errors,
        email: 'Please enter valid email',
      };

      error = true;
    }

    if (type === 'dropoff') {
      if (collectionDetailsData.name === '') {
        errors = {
          ...errors,
          name: 'Required',
        };

        error = true;
      }

      if (collectionDetailsData.addressOne === '') {
        errors = {
          ...errors,
          addressOne: 'Required',
        };

        error = true;
      }

      if (collectionDetailsData.city === '') {
        errors = {
          ...errors,
          city: 'Required',
        };

        error = true;
      }

      if (collectionDetailsData.country === '') {
        errors = {
          ...errors,
          country: 'Required',
        };

        error = true;
      }

      if (collectionDetailsData.zip === '') {
        errors = {
          ...errors,
          zip: 'Required',
        };

        error = true;
      }
    }

    if (vat.customs) {
      if (collectionDetailsData.customsData.VATStatus === '') {
        errors = {
          ...errors,
          customsData: {
            ...errors.customsData,
            VATStatus: 'Required',
          },
        };

        error = true;
      }

      if (collectionDetailsData.customsData.VATStatus === 'company_vat') {
        if (collectionDetailsData.customsData.VATNumber === '') {
          errors = {
            ...errors,
            customsData: {
              ...errors.customsData,
              VATNumber: 'Required',
            },
          };

          error = true;
        }

        if (collectionDetailsData.customsData.EORINumber === '') {
          errors = {
            ...errors,
            customsData: {
              ...errors.customsData,
              EORINumber: 'Required',
            },
          };

          error = true;
        } else if (collectionDetailsData.customsData.EORINumber.length > 17) {
          errors = {
            ...errors,
            customsData: {
              ...errors.customsData,
              EORINumber: 'EORI numbers should be 17 characters or less',
            },
          };

          error = true;
        }
      }
    }

    setCollectionDetailsErrors({ ...errors });

    return error;
  };

  useEffect(() => {
    let blank = false;

    requiredAddressKeys.forEach((key: any) => {
      // @ts-ignore
      if (addressData[key] === '') {
        blank = true;
      }
    });

    if (order.serviceType === 'seaFreight' && addressData.company === '') {
      blank = true;
    }

    if (addressData.company !== '' && addressData.company.length < 3 || addressData.company.length > 34) {
      blank = true;
    }

    if (
      (addressData.addressOne !== '' && addressData.addressOne.length > 30) ||
      (addressData.addressTwo !== '' && addressData.addressTwo.length > 30) ||
      (addressData.city !== '' && addressData.city.length > 30)
    ) {
      blank = true;
    }

    requiredCollectionDetailsKeys.forEach((key: any) => {
      // @ts-ignore
      if (collectionDetailsData[key] === '') {
        blank = true;
      }
    });

    if (collectionDetailsData.email.length > 50) {
      blank = true;
    }

    if (collectionDetailsData.name.length > 30) {
      blank = true;
    }

    if (vat.customs) {
      if (collectionDetailsData.customsData.VATStatus === '') {
        blank = true;
      }

      if (collectionDetailsData.customsData.VATStatus === 'company_vat') {
        if (
          collectionDetailsData.customsData.VATNumber === '' ||
          collectionDetailsData.customsData.EORINumber === ''
        ) {
          blank = true;
        }
      }
    }

    setButtonDisabled(blank);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressData, collectionDetailsData]);

  const validateBookerEmail = () => {
    if (bookerEmail === '') {
      setBookerEmailErrorMessage('Required');
      return true;
    }
    
    if (bookerEmail.length > 50) {
      setBookerEmailErrorMessage('Email must not exceed 50 characters');
      return true;
    } 
    
    if (
      !bookerEmail.match(
        //eslint-disable-line
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/
      )
    ) {
      setBookerEmailErrorMessage('Please enter valid email');
      return true;
    }
    setBookerEmailErrorMessage('');
    return false;
  };

  const handleSubmit = () => {
    const errorDetails = validateCollectionDetails();
    const errorBookerEmail = validateBookerEmail();
    setBookerEmailError(errorBookerEmail);
    const errorAddress =
      !type || type === 'collection' ? validateAddress() : false;

    if (errorDetails || errorAddress || errorBookerEmail) {
      window.scrollTo(0, 0);

      return;
    }

    dispatch(setBookerEmail(bookerEmail));
    dispatch(setCollectionAddress(addressData));
    dispatch(setCollectionDetails(collectionDetailsData));
    captureData();
    history.push('/order-step2');
  };

  const captureData = () => {
    const packageDetails = order.dims?.length? order.dims:order.packageDetails;
    packageDetails.palletAndWrap = order.palletAndWrap;
    let basketId:any = getCookie('basketId');
    if (isNaN(basketId)) basketId = 0;
    const dataToSend = {
      collectionData: { ...collectionDetailsData, ...addressData },
      item:order.item,
      packageDetails,
      currentStep: order.currentStep,
      seaFeightDetails: order.seaFreightDetails,
      userId: getCookie('userId'),
      basketItemId:order.basketItemId,
      quoteId,
      quoteUrl,
      bookerEmail,
      basket: {
        basketId,
        itemCount: getCookie('basket')
      }
    };
    if (packageDetails?.parcel?.length) dataToSend.item.quoteType = 'parcel';
    sendBasketData(dataToSend)
      .then((res: any) => {
        if (res.data.basketItemId !== order.basketItemId) {
          dispatch(setBasketItemId(res.data.basketItemId));
        }
        if (getCookie('basketId') !== res.data.basketId) {
          setCookie([
            {
              name: 'basketId',
              value: res.data.basketId,
            },
          ]);
        }
      })
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.log(`Error sending data: ${error.message}`);
      });
  };

  return (
    <>
      <RowStyled id="service-row">
        <ColumnStyled id="service-column" sizeL={7} sizeM={6}>
          <BookerEmail
            bookerEmail={bookerEmail}
            setBookerEmail={setBookerEmailData}
            error={bookerEmailError}
            setError={setBookerEmailError}
            errorMessage={bookerEmailErrorMessage}
            setErrorMessage={setBookerEmailErrorMessage}
          />
          <CollectionAddress
            address={addressData}
            setAddress={setAddressData}
            addressErrors={addressErrors}
            setAddressErrors={setAddressErrors}
            serviceType={order.serviceType}
          />
          <CollectionDetails
            localState={collectionDetailsData}
            setLocalState={setCollectionDetailsData}
            errors={collectionDetailsErrors}
            setErrors={setCollectionDetailsErrors}
            serviceType={order.serviceType}
          />
        </ColumnStyled>
        <Service />
      </RowStyled>
      <RowLast>
        <Column sizeL={1} sizeM={0} sizeS={0.5} sizeXS={0} />
        <ColumnLast sizeL={6} sizeM={6} sizeS={3}>
          <Button
            color="secondary"
            disable={buttonDisabled}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </ColumnLast>
      </RowLast>
    </>
  );
};

const RowStyled = styled(Row)`
  padding-top: 64px;
  justify-content: flex-start;

  @media ${device.laptop} {
    padding-top: 32px;
  }
`;

const RowLast = styled(Row)`
  padding-bottom: 40px;
  justify-content: flex-start;
  margin-top: 32px;
`;

const ColumnStyled = styled(Column)`
  padding: 0;

  @media ${device.laptop} {
    padding: 0 15px;
  }
`;

const ColumnLast = styled(Column)`
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media ${device.tablet} {
    padding: 0 15px;
  }
`;

export default StepOne;
