export const SET_LOADERS = 'SET_LOADERS';
export const SET_ERRORS = 'SET_ERRORS';

export const RESET_STATE = 'RESET_STATE';
export const RESET_QUOTES = 'RESET_QUOTES';

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

export const SET_PARCEL_VALUES = 'SET_PARCEL_VALUES';
export const SET_PALLET_VALUES = 'SET_PALLET_VALUES';
export const SET_FREIGHT_VALUES = 'SET_FREIGHT_VALUES';
export const SET_SEA_FREIGHT_VALUES = 'SET_SEA_FREIGHT_VALUES';

export const SET_PARCEL_QUOTES = 'SET_PARCEL_QUOTES';
export const SET_PALLET_QUOTES = 'SET_PALLET_QUOTES';
export const SET_PALLET_QUOTES_SORT = 'SET_PALLET_QUOTES_SORT';
export const SET_FREIGHT_QUOTES = 'SET_FREIGHT_QUOTES';
export const SET_SEA_FREIGHT_QUOTES = 'SET_SEA_FREIGHT_QUOTES';
export const SET_FREIGHT_QUOTES_SORT = 'SET_FREIGHT_QUOTES_SORT';

export const SET_PARCEL_COLLECTION_DATE = 'SET_PARCEL_COLLECTION_DATE';

export const SET_EARLIEST_COLLECTION_DATE = 'SET_EARLIEST_COLLECTION_DATE';
export const SET_EARLIEST_DELIVERY_DATE = 'SET_EARLIEST_DELIVERY_DATE';

export const SET_RESET_FILTERS = 'SET_RESET_FILTERS';
export const SET_PARCEL_COLLECTION_COUNTRY = 'SET_PARCEL_COLLECTION_COUNTRY';
export const SET_PARCEL_COLLECTION_POSTCODE = 'SET_PARCEL_COLLECTION_POSTCODE';
export const SET_POSTCODES_DATA = 'SET_POSTCODES_DATA';
export const SET_CURRENT_ITEM = 'SET_CURRENT_ITEM';

export const SET_CURRENT_ORDER_STEP = 'SET_CURRENT_ORDER_STEP';

export const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS';
export const SET_RECIPIENT_DETAILS = 'SET_RECIPIENT_DETAILS';

export const SET_COLLECTION_ADDRESS = 'SET_COLLECTION_ADDRESS';
export const SET_COLLECTION_DETAILS = 'SET_COLLECTION_DETAILS';

export const SET_FREIGHT_DETAILS = 'SET_FREIGHT_DETAILS';

export const ADD_ADDRESS_TO_ADDRESS_BOOK_TOGGLE = 'ADD_ADDRESS_TO_ADDRESS_BOOK_TOGGLE';

export const SET_TRACKING_DETAILS = 'SET_TRACKING_DETAILS';

export const SET_SELECTED_SERVICE = 'SET_SELECTED_SERVICE';
export const SET_ITEM_DETAILS = 'SET_ITEM_DETAILS';
export const SET_SHIPMENT_DETAILS = 'SET_SHIPMENT_DETAILS';
export const SET_CUSTOMS_DETAILS = 'SET_CUSTOMS_DETAILS';
export const SET_SEA_FREIGHT_DETAILS = 'SET_SEA_FREIGHT_DETAILS';
export const SET_INSURANCE_DETAILS = 'SET_INSURANCE_DETAILS';
export const SET_DELIVERYTIME = 'SET_DELIVERYTIME';

export const ADD_ORDER_TO_BASKET = 'ADD_ORDER_TO_BASKET';
export const ADD_ORDER_TO_BASKET_MANUAL = 'ADD_ORDER_TO_BASKET_MANUAL';
export const REMOVE_ORDER_FROM_BASKET = 'REMOVE_ORDER_FROM_BASKET';
export const RESET_BASKET = 'RESET_BASKET';

export const SET_DROPOFF_POINT = 'SET_DROPOFF_POINT';

export const SET_ORDER_DATA = 'SET_ORDER_DATA';

export const SET_USER_LOGGED = 'SET_USER_LOGGED';
export const SET_USER_SESSION = 'SET_USER_SESSION';
export const RESET_USER_SESSION = 'RESET_USER_SESSION';
export const BACK_TO_TOKEN_EXPIRED = 'BACK_TO_TOKEN_EXPIRED';

export const SET_CURRENT_QUICKBOOK_STEP = 'SET_CURRENT_QUICKBOOK_STEP';
export const GOTO_NEXT_QUICKBOOK_STEP = 'SET_CURRENT_QUICKBOOK_STEP';

export const ADD_PARCEL_TO_QUICKBOOK = 'ADD_PARCEL_TO_QUICKBOOK';

export const ADD_ACCOUNT_DISCOUNT = 'ADD_ACCOUNT_DISCOUNT';
export const SET_AFFLIATE_SCRIPT_LOADING = 'SET_AFFLIATE_SCRIPT_LOADING';
export const SET_QUOTE_ID = 'SET_QUOTE_ID';

export const SET_BASKET_ITEM_ID = 'SET_BASKET_ITEM_ID';

export const SET_BOOKER_EMAIL = 'SET_BOOKER_EMAIL';
export const SET_QUOTE_URL = 'SET_QUOTE_URL';