import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SettingBox from '../SettingBox';
import { Switch } from '../../../../../../assets/elements';
import device from '../../../../../utils/device';
import { updateUserNewsletter } from '../../../../../utils/APICalls/Account';

const NewsletterSetting = ({ userInfo }: any) => {
  const [newsletter, setNewsletter] = useState(false);
  const [disabled, setDisabled] = useState<any>(false);

  useEffect(() => {
    setNewsletter(userInfo);
  }, [userInfo]);

  const handleChange = (value: any) => {
    setDisabled(true);
    setNewsletter(value);

    updateUserNewsletter({
      enabled: value,
    }).finally(() => {
      setDisabled(false);
    });
  };

  return (
    <SettingBox title="Newsletter">
      <Subtext>
        Subscribe to receive latest news and updates from CollectMyParcel
      </Subtext>

      <div>
        <Switch
          active={newsletter}
          onChange={handleChange}
          disabled={disabled}
        />
      </div>
    </SettingBox>
  );
};

const Subtext = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  color: ${({ theme }: any) => theme.colors.MidGrey};

  @media ${device.laptop} {
    width: 40%;
    margin-bottom: 0;
  }

  @media ${device.laptopL} {
    width: 65%;
  }
`;

export default NewsletterSetting;
