import React from 'react';
import styled from 'styled-components';
import { P } from '../../../assets/elements';
import { logo } from '../../../assets/styles/themes';
import Footer from '../Footer';

const Maintenance = () => {
  return (
    <Body>
      <Wrapper>
        <Image src={logo} alt="Site logo" />
        <Title>We'll be back.</Title>
        <PStyled>We’re currently making some changes.<br /> We’ll be back online very soon.</PStyled>
      </Wrapper>
      {/* <FStyled /> */}
    </Body>
  );
};

const Body = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

const Wrapper = styled.div`
  margin: auto;
  width: 50%;
  padding: 10px;
`;

const PStyled = styled(P)`
  color: ${({ theme }: any) => theme.colors.MidGrey};
  text-align: center;
`;
const Title = styled(P)`
  color: ${({ theme }: any) => theme.colors.Black};
  text-align: center;
  font-weight: bold;
  font-size: 38px !important;
`;

const Image = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  margin-bottom: 20px;
`;

const FStyled = styled(Footer)`
  margin-top: auto;
`;

export default Maintenance;
