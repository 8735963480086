import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container } from '../../../assets/elements';
import device from '../../utils/device';
import { getCollections } from '../../utils/APICalls/footer';
import { landingPageUrl } from '../../utils/env';
import Copyright from './Copyright';

const Footer = () => {
  const [footerData, setFooterData] = useState<any>({});
  const [socials, setSocials] = useState<any>({});

  useEffect(() => {
    getCollections().then((res: any) => {
      // let socialLinks = {};

      // if (res.data?.socials && res.data?.socials?.items) {
      //   res.data.socials?.items.forEach((item: any) => {
      //     socialLinks = {
      //       ...socialLinks,
      //       [item.name]: item.url,
      //     };
      //   });
      // }

      // setSocials(socialLinks);

      delete res.data.socials;
      setFooterData(res.data);
    })
      .catch(() => {
        setFooterData({});
        setSocials({});
      });
  }, []);
  return (
    <>
      <Wrapper>
        <InnerWrapper>
          <ContainerStyled>
            <ColumnsContainer>
              <CollectionsContainer>
                {Object.values(footerData).map((data: any, i: any) => {
                  return (
                    <Collection key={i}>
                      {data.items.map((item: any, j: any) => {
                        return (
                          <FooterLinkContainer key={j}>
                            <FooterLink
                              href={`${landingPageUrl}${item.slug}`}
                              target="_blank"
                            >
                              {item.name}
                            </FooterLink>
                          </FooterLinkContainer>
                        );
                      })}
                    </Collection>
                  );
                })}
              </CollectionsContainer>
            </ColumnsContainer>
            {/* <IconsContainer>
              {
                socials?.Facebook && (
                  <Icon href={socials.Facebook} target="_blank">
                    <img src={`${ASSETS_URL}icons/FB.svg`} alt="Facebook" />
                  </Icon>
                )
              }
              {
                socials?.Twitter && (
                  <Icon href={socials.Twitter} target="_blank">
                    <img src={`${ASSETS_URL}icons/TT.svg`} alt="Twitter" />
                  </Icon>
                )
              }
              {
                socials.LinkedIn && (
                  <Icon href={socials.LinkedIn} target="_blank">
                    <img src={`${ASSETS_URL}icons/IN.svg`} alt="LinkedIn" />
                  </Icon>
                )
              }
            </IconsContainer> */}
          </ContainerStyled>
        </InnerWrapper>
      </Wrapper>
      <Copyright />
    </>
  );
};

const Wrapper = styled.div`
  background-color: ${({ theme }: any) => theme.colors.FooterBackground};
  color: ${({ theme }: any) => theme.colors.White};
  padding: 24px 16px;

  @media (min-width: 479px) {
    padding: 24px 48px;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1120px;
`;

const ContainerStyled = styled.div``;

const ColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;

  @media ${device.laptop} {
    flex-wrap: unset;
  }
`;

const CollectionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  flex-wrap: wrap;
  width: 100%;
`;

const Collection = styled.div`
  //display: flex;
  //justify-content: space-between;
  text-align: left;
  width: 100%;
  padding: 0;
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: 100%;
  margin-bottom: 32px;
  min-width: 200px;

  @media ${device.laptop} {
    width: auto;
    margin-bottom: 0;
  }
`;

const Title = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: ${({ theme }: any) => theme.colors.White};
`;

const FooterLinkContainer = styled.div`
  display: inline-block;
  margin: 4px 0;
  width: 100%;

  @media (min-width: 479px) {
    width: 50%;
  }

  @media (min-width: 991px) {
    width: 33.33%;
  }

  @media (min-width: 1280px) {
    width: 25%;
  }
`;

const FooterLink = styled.a`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin-bottom: 8px;
  color: ${({ theme }: any) => theme.colors.GrayWhite};
  text-decoration: none;
  cursor: pointer;
  white-space: normal;
  padding: 4px 0;

  &:hover {
    color: ${({ theme }: any) => theme.colors.Secondary};
  }

  &:focus {
    color: ${({ theme }: any) => theme.colors.Secondary};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const FooterLine = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin-bottom: 8px;
  color: ${({ theme }: any) => theme.colors.White};
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Icon = styled.a`
  margin-right: 26px;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
`;

export default Footer;
