import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SettingBox from '../SettingBox';
import { Button, Caption, Input } from '../../../../../../assets/elements';
import device from '../../../../../utils/device';
import { updateUserEmail } from '../../../../../utils/APICalls/Account';

const EmailSetting = ({ theme, userInfo }: any) => {
  const [email, setEmail] = useState('');
  const [oldEmail, setOldEmail] = useState('');
  const [error, setError] = useState<any>(false);
  const [disabled, setDisabled] = useState<any>(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setEmail(userInfo);
    setOldEmail(userInfo);
  }, [userInfo]);

  const handleChange = (e: any) => {
    const { value } = e.target;

    if (value.length > 50) {
      setError('Email must not exceed 50 characters');
    } else {
      setError(false);
    }

    if (disabled && value !== '' && value !== oldEmail && value.length < 50) {
      setDisabled(false);
    } else if (value === '' || value === oldEmail || value.length > 50) {
      setDisabled(true);
    }

    setEmail(value);
  };

  const validateEmail = () => {
    let emailError = false;

    if (email.length === 0) {
      setError('Email is required');

      emailError = true;
    } else if (email.length > 50) {
      setError('Email must not exceed 50 characters');

      emailError = true;
    } else if (
      !email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/)
    ) {
      setError('Please enter a valid email');

      emailError = true;
    }

    return emailError;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!validateEmail()) {
      setSaving(true);
      setDisabled(true);

      updateUserEmail({ email })
        .catch(() => {
          setError('Email already exists');
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  return (
    <SettingBox title="Email Address">
      <InputContainer>
        <Input
          theme={theme}
          value={email}
          type="text"
          placeholder="Enter email"
          onChange={handleChange}
          error={error}
        />
        {error && <ErrorText>{error}</ErrorText>}
      </InputContainer>

      <div>
        <ButtonStyled
          color="secondary"
          disable={disabled}
          disabled={disabled}
          onClick={handleSubmit}
        >
          {saving ? 'Saving...' : 'Save changes'}
        </ButtonStyled>
      </div>
    </SettingBox>
  );
};

const ButtonStyled = styled(Button)`
  white-space: nowrap;
`;

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
  position: relative;

  @media ${device.laptop} {
    width: 40%;
    margin-bottom: 0;
  }

  @media ${device.laptopL} {
    width: 65%;
  }
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

export default EmailSetting;
