import React, { useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import HeroImage from '../../../assets/images/Tracking-hero.png';
import {
  Button,
  Column,
  Container,
  H4,
  P, PSmall,
  Row,
  A,
} from '../../../assets/elements';
import device from '../../utils/device';
import { setError } from '../../store/actions';
import { trackingPrefix } from '../../utils/variables';
import TrackingTruck from '../../../assets/icons/TrackingTruck';
import TrackingContact from '../../../assets/icons/TrackingContact';
import { faqUrl, ticketUrl } from '../../utils/env';

const Tracking = ({ theme }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const loading = useSelector((state: any) => state.main.loading.tracking);

  const error = useSelector((state: any) => state.main.errors.tracking);

  const [trackingNumber, setTrackingNumber] = useState('');

  const handleSubmit = () => {
    if (trackingNumber) {
      history.push(`track?order-number=${trackingNumber}`);
    }
  };

  const handleChange = (e: any) => {
    if (error) {
      dispatch(setError('tracking', false));
    }
    const { value } = e.target;
    setTrackingNumber(value);
  };

  const handleClick = (page: any) => {
    window.open(page, '_blank');
  };

  return (
    <>
      <Wrapper>
        <Container>
          <RowStyled>
            <ColumnStyled sizeL={6} sizeS={6} sizeXS={4}>
              <H4>Track your order</H4>
              <PStyled>Enter your order number to track delivery</PStyled>
              <InputContainer>
                <InputStyled
                  theme={theme}
                  value={trackingNumber}
                  type="text"
                  placeholder={`e.g. ${trackingPrefix}-ABCDEF000001`}
                  onChange={handleChange}
                />
                <Button color="secondary" onClick={handleSubmit}>
                  {loading ? 'Tracking...' : 'Track order'}
                </Button>
                {error && (
                  <ErrorText>
                    Sorry! This order number doesn't exist. Please check and try
                    again.
                  </ErrorText>
                )}
              </InputContainer>
            </ColumnStyled>
          </RowStyled>
        </Container>
      </Wrapper>
      <WrapperBottom>
        <ContainerTiles>
          <RowStyled>
            <ColumnTiles sizeL={6} sizeS={6} sizeXS={4}>
              <TrackTile>
                <div>
                  <TrackingTruck />
                  <TrackTileHeading>Parcel not yet collected?</TrackTileHeading>
                  <TrackTileText>
                    Sorry, this doesn't happen often, we're sure there's a good reason.
                    If it's after 6pm on the booked collection day, then reschedule a collection here.
                  </TrackTileText>
                </div>
                <div>
                  <Button
                    color="secondary"
                    onClick={() => handleClick(ticketUrl)}
                  >
                    Reschedule collection
                  </Button>
                </div>
              </TrackTile>
            </ColumnTiles>
            <ColumnTiles sizeL={6} sizeS={6} sizeXS={4}>
              <TrackTile>
                <div>
                  <TrackingContact />
                  <TrackTileHeading>Need a fast answer?</TrackTileHeading>
                  <TrackTileText>
                    If you've any questions at all about your delivery,
                    then don't hesitate to get in touch with our UK based customer service team.
                    But first check out our <A onClick={() => handleClick(faqUrl)}>FAQ's page</A>{ ' ' }
                    as the answer you're looking for will probably be there.
                  </TrackTileText>
                </div>
                <div>
                  <Button
                    color="secondary"
                    onClick={() => handleClick(ticketUrl)}
                  >
                    Contact us
                  </Button>
                </div>
              </TrackTile>
            </ColumnTiles>
          </RowStyled>
        </ContainerTiles>
      </WrapperBottom>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  @media ${device.laptopL} {
    background-image: url('${HeroImage}');
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: 50%;
  }
`;

const WrapperBottom = styled.div`
  display: flex;
  width: 100%;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
`;

const ContainerTiles = styled(Container)`
  padding: 56px 0 !important;
`;

const RowStyled = styled(Row)`
  justify-content: flex-start;
`;

const ColumnStyled = styled(Column)`
  padding: 56px 0;

  @media ${device.laptopL} {
    padding: 147px 0;
  }
`;

const ColumnTiles = styled(Column)`
  padding: 0 15px;

  &:first-of-type {
    margin-bottom: 30px;
  }

  @media ${device.laptopL} {
    &:first-of-type {
      padding-left: 0;
      margin-bottom: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
`;

const PStyled = styled(P)`
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 150%;
  color: ${({ theme }: any) => theme.colors.MidGrey};
  margin: 24px 0 32px;

  @media ${device.laptop} {
    font-size: 24px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

const InputStyled = styled.input.attrs(({ type, placeholder }: any) => ({
  type,
  placeholder,
}))`
  border: 2px solid ${({ theme }) => theme.colors.WhiteGrey};
  border-radius: ${({ theme }) => theme.box.BorderRadiusSmall};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.Black};
  background-color: ${({ theme }) => theme.colors.White};
  width: 100%;
  padding: 12px 14px;
  outline: unset;
  margin: 0 0 16px 0;

  &::placeholder {
    color: ${({ theme }) => theme.colors.LightGrey};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.Secondary};
  }

  @media ${device.laptop} {
    width: 272px;
    margin: 0 16px 0 0;
  }
`;

const ErrorText = styled.div`
  display: inline-block;
  padding: 12px;
  background: rgba(224, 76, 102, 0.2);
  border-radius: 4px;
  color: #e04c66;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin-bottom: 24px;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 24px;
`;

const TrackTile = styled.div`
  width: 100%;
  height: 100%;
  padding: 32px 48px;
  text-align: left;
  background: ${({ theme }: any) => theme.colors.White};
  margin-bottom: 32px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.laptop} {
    margin-bottom: 0;
  }
`;

const TrackTileIcon = styled.img`
  width: 82.5px;
  height: auto;
  margin-bottom: 24px;
`;

const TrackTileHeading = styled(P)`
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  color: ${({ theme }: any) => theme.colors.Black};
  margin: 16px 0 24px;
  text-align: left;
`;

const TrackTileText = styled(PSmall)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`;

export default Tracking;
