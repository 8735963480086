import React, { useState } from 'react';
import styled from 'styled-components';
import { Column } from '../../../../../../../../../assets/elements';
import device from '../../../../../../../../utils/device';

const SingleSize = ({ pallet, theme, onSizeChange, value, error }: any) => {
  const [active, setActive] = useState(false);

  const handleChange = (e: any) => {
    onSizeChange(e, pallet.title.toLowerCase());
  };

  return (
    <Wrapper sizeL={2.4} sizeS={6}>
      <ContainerStyled active={active} error={error}>
        <InfoContainer>
          <TitleContainer>
            <Title>{pallet.title}</Title>
            <SubText>{pallet.subtext}</SubText>
          </TitleContainer>
        </InfoContainer>
        <ImageContainer>
          <PalletImage src={pallet.image} alt={pallet.title} />
        </ImageContainer>
        <InputContainer>
          <InputStyled
            type="text"
            placeholder="Quantity"
            value={value}
            onChange={(e: any) => onSizeChange(e, pallet.title.toLowerCase())}
            theme={theme}
            error={error}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            name={`size_${pallet.title}`}
          />
        </InputContainer>
        <MobileInfoContainer>
          <InfoContainer>
            <TitleContainer>
              <Title>{pallet.title}</Title>
              <SubText>{pallet.subtext}</SubText>
            </TitleContainer>
          </InfoContainer>
          <InputContainer>
            <InputStyled
              type="text"
              placeholder="Quantity"
              value={value}
              onChange={handleChange}
              theme={theme}
              error={error}
              onFocus={() => setActive(true)}
              onBlur={() => setActive(false)}
            />
          </InputContainer>
        </MobileInfoContainer>
      </ContainerStyled>
    </Wrapper>
  );
};

const Wrapper = styled(Column)`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  @media ${device.laptopL} {
    margin-bottom: 0;
  }
`;

const ContainerStyled = styled.div.attrs(({ active, error }: any) => ({
  active,
  error,
}))`
  display: flex;
  flex-direction: row;
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};
  border: 2px solid
    ${({ theme, active, error }: any) => {
    const activeColor = active ? theme.colors.Secondary : theme.colors.WhiteGrey;
    return error ? theme.colors.Error : activeColor;
  }};

  @media ${device.laptopL} {
    flex-direction: column;
  }
`;

const InfoContainer = styled.div`
  display: none;
  flex-direction: column;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  border-top-left-radius: ${({ theme }: any) => theme.box.BorderRadius};
  border-top-right-radius: ${({ theme }: any) => theme.box.BorderRadius};

  @media ${device.laptopL} {
    display: flex;
  }
`;

const MobileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  width: 50%;
  border-top-right-radius: ${({ theme }: any) => theme.box.BorderRadius};
  border-bottom-right-radius: ${({ theme }: any) => theme.box.BorderRadius};

  @media ${device.tablet} {
    width: 60%;
  }

  @media ${device.laptopL} {
    display: none;
  }

  & > div {
    display: flex;

    @media ${device.laptopL} {
      display: none;
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  width: 50%;
  border-top-left-radius: ${({ theme }: any) => theme.box.BorderRadius};
  border-bottom-left-radius: ${({ theme }: any) => theme.box.BorderRadius};
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    width: 40%;
  }

  @media ${device.laptopL} {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
`;

const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const SubText = styled(Title)`
  font-weight: 300;
  font-size: 14px;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const InputContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0 14px 16px;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  border-bottom-left-radius: ${({ theme }: any) => theme.box.BorderRadius};
  border-bottom-right-radius: ${({ theme }: any) => theme.box.BorderRadius};

  @media ${device.laptopL} {
    background: ${({ theme }: any) => theme.colors.White};
    display: flex;
    padding: 16px 14px;
  }
`;

const InputStyled = styled.input.attrs(({ error }: any) => ({
  error,
}))`
  border: 2px solid
    ${({ error, theme }: any) =>
    error ? theme.colors.Error : theme.colors.LightGrey};
  border-radius: 4px;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  color: #2b3131;
  background-color: #ffffff;
  width: 100%;
  padding: 12px 14px;
  outline: unset;

  &::placeholder {
    color: ${({ theme }: any) => theme.colors.LightGrey};
  }

  &:focus {
    border-color: ${({ theme }: any) => theme.colors.Secondary};
  }
`;

const PalletImage = styled.img`
  width: 100%;
  height: auto;
  align-self: flex-end;
  @media ${device.tablet} {
    width: 80%;
  }
`;

export default SingleSize;
