import React from 'react';
import styled from 'styled-components';
import { Input, Label, Caption } from '../../../../../../../../assets/elements';
import { SelectInput } from '../../../../../../Inputs';
import { countriesPostalCodesData } from '../../../../../../../mocks/quoteData';
import Required from '../../../../../../../../assets/elements/Required';

const DropDownManually = ({
  localState,
  setLocalState,
  theme,
  errors,
  setErrors,
  serviceType,
}: any) => {
  const handleChange = (e: any, key: any) => {
    const { value } = e.target;

    if (
      (key === 'addressOne' ||
        key === 'addressTwo') &&
      value.length > 30
    ) {
      setErrors({
        ...errors,
        [key]: 'This must be less than 30 characters',
      });
    } else if (key === 'company' && (value !== '' && (value.length < 3 || value.length > 34))) {
      setErrors({
        ...errors,
        [key]: 'This must be between 3 and 34 characters'
      });
    } else {
      setErrors({
        ...errors,
        [key]: false,
      });
    }

    setLocalState({
      ...localState,
      [key]: value,
    });
  };

  const handleCountryChange = (code: any) => {
    setErrors({
      ...errors,
      country: false,
    });

    setLocalState({
      ...localState,
      country: code,
    });
  };

  return (
    <>
      <Form>
        <InputWrapper>
          <LabelStyled>
            {
              serviceType === 'seaFreight' && <Required />
            }
            {
              serviceType === 'seaFreight' ? 'Company name' : 'Company Name (Required for business recipients)'
            }
          </LabelStyled>
          <Input
            placeholder=""
            value={localState.company}
            onChange={(e: any) => handleChange(e, 'company')}
            theme={theme}
            type="text"
            error={errors.company}
            autoComplete="off"
          />
          <ErrorText>{errors.company}</ErrorText>
        </InputWrapper>
        <InputWrapper>
          <LabelStyled><Required />Address line 1</LabelStyled>
          <Input
            placeholder=""
            value={localState.addressOne}
            onChange={(e: any) => handleChange(e, 'addressOne')}
            theme={theme}
            type="text"
            error={errors.addressOne}
            autoComplete="off"
          />
          <ErrorText>{errors.addressOne}</ErrorText>
        </InputWrapper>
        <InputWrapper>
          <LabelStyled>Address line 2</LabelStyled>
          <Input
            placeholder=""
            value={localState.addressTwo}
            onChange={(e: any) => handleChange(e, 'addressTwo')}
            theme={theme}
            type="text"
            error={false}
            autoComplete="off"
          />
        </InputWrapper>
        <InputWrapper>
          <LabelStyled>Address line 3</LabelStyled>
          <Input
            placeholder=""
            value={localState.addressThree}
            onChange={(e: any) => handleChange(e, 'addressThree')}
            theme={theme}
            type="text"
            error={false}
            autoComplete="off"
          />
        </InputWrapper>
        <InputWrapper>
          <LabelStyled><Required />City</LabelStyled>
          <Input
            placeholder=""
            value={localState.city}
            onChange={(e: any) => handleChange(e, 'city')}
            theme={theme}
            type="text"
            error={errors.city}
            autoComplete="off"
          />
          <ErrorText>{errors.city}</ErrorText>
        </InputWrapper>
        <InputWrapper>
          <LabelStyled>Country</LabelStyled>
          <SelectInput
            data={countriesPostalCodesData}
            error={errors.country}
            value={localState.country}
            height="50px"
            onChange={(code: string) =>
              handleCountryChange(code)}
            isDisabled={true}
          />
          <ErrorText>{errors.country}</ErrorText>
        </InputWrapper>
        <InputWrapper>
          <LabelStyled>Postcode</LabelStyled>
          <Input
            placeholder=""
            value={localState.zip}
            onChange={(e: any) => handleChange(e, 'zip')}
            theme={theme}
            type="text"
            error={errors.zip}
            autoComplete="off"
            disabled={true}
          />
          <ErrorText>{errors.zip}</ErrorText>
        </InputWrapper>
      </Form>
    </>
  );
};

const Form = styled.div<any>`
  background: #f2f2f2;
  padding: 20px 10px 1px 10px;
  border-radius: 4px;
  margin-top: 30px;
`;
const InputWrapper = styled.div`
  margin-bottom: 25px;
  position: relative;
`;
const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: normal;
  margin-left: 3px;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;
const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 3px;
`;

export default DropDownManually;
