import { environment } from '../../../app/utils/env';
import { staging } from './staging';
import { cmp } from './cmp';
import { pallets4delivery } from './pallets4delivery';
import { mpd } from './mpd';
import { p4d } from './p4d';
import { arw } from './arw';
import { auc } from './auc';
import LightenDarkenColor from '../../../app/utils/LightenDarkenColor';

let theme = staging;

if (environment === 'staging') {
  theme = staging;
}else if (environment === 'cmp') {
  theme = cmp;
}else if (environment === 'pallets4delivery') {
  theme = pallets4delivery;
}else if (environment === 'mpd') {
  theme = mpd;
}else if (environment === 'p4d') {
  theme = p4d;
} else if (environment === 'arw') {
  theme = arw;
} else if (environment === 'auc') {
  theme = auc;
}

export const main = {
  /* Colors */
  colors: {
    ...theme,
    Black: '#2B3131',
    DarkGrey: '#505050',
    GrayWhite: '#F2F3F2',
    MidGrey: '#777E7E',
    LightGrey: '#BCC0BF',
    WhiteGrey: '#F2F3F2',
    White: '#FFFFFF',

    Success: '#38AB5F',
    SuccessBackground: '#EEF4E2',
    Warning: '#F4732A',
    WarningBackGround: '#FEE6D0',
    Info: '#F4C82A',
    InfoBackground: '#F4C82A',
    Error: '#E04C66',
    ErrorBackground: '#F9DBE0',
    Pastel1: LightenDarkenColor(theme.Primary, 0.85),
    Pastel2: LightenDarkenColor(theme.Secondary, 0.85),
    InactiveText: '#888888',

    PrimaryHover: LightenDarkenColor(theme.Primary, -0.15),
    SecondaryHover: LightenDarkenColor(theme.Secondary, -0.15),
    MutedPrimary: LightenDarkenColor(theme.Primary, 0.85),
    MutedSecondary: LightenDarkenColor(theme.Primary, 0.85),

    CollectionBackground: '#EFDEFF',
    CollectionHead: '#AE5BFB',
    DropoffBackground: '#CCEDD0',
    DropoffHead: '#408F49',

    FooterBackground: '#2B3131',
    CopyrightBackground: '#2B3131',
  },

  /* Typography */
  typography: {
    FontFamily: 'Montserrat',

    H1_M_FS: '2.4rem',
    H2_M_FS: '2.2rem',
    H3_M_FS: '1.9rem',
    H4_M_FS: '1.6rem',
    H5_M_FS: '1.4rem',
    H6_M_FS: '1.2rem',

    H1_L_FS: '3rem',
    H2_L_FS: '2.75rem',
    H3_L_FS: '2.375rem',
    H4_L_FS: '2rem',
    H5_L_FS: '1.75rem',
    H6_L_FS: '1.5rem',

    Paragraph_M_FS: '1rem',
    Paragraph_L_FS: '1.2rem',

    ParagraphSmall_M_FS: '0.875rem',
    ParagraphSmall_L_FS: '1rem',

    LabelSmall_FS: '0.625rem',

    Caption_FS: '0.875rem',

    LineHeightHeadings: '135%',
    LineHeightParagraphs: '150%',
    LineHeightCaptions: '120%',
    LineHeightLabel: '150%',

    FontWeightHeadings: '600',
    FontWeightParagraphs: 'normal',
  },

  box: {
    BorderRadius: '8px',
    BorderRadiusSmall: '4px',
    BoxShadow: '-6px -6px 12px rgba(75, 75, 75, 0.22), 6px 6px 16px rgba(75, 75, 75, 0.31)',
  },
};