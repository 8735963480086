import React from 'react';
import styled from 'styled-components';
import { PSmall } from '../../../assets/elements';
import device from '../../utils/device';

import QuoteLoading from '../../../assets/images/QuoteLoading';
import P4DLoader from '../../../assets/icons/P4D_Loader.gif';
import MPDLoader from '../../../assets/icons/MPD_Loader.gif';
import { environment } from '../../utils/env';

const QuoteLoader = () => {
  return (
    <Wrapper>
      {
        environment === 'p4d' && (
          <Image src={P4DLoader} alt="Loading" height="200px" />
        )
      }
      {
        environment === 'mpd' && (
          <Image src={MPDLoader} alt="Loading" height="auto" />
        )
      }
      {
        environment !== 'p4d' && environment !== 'mpd' && (
          <QuoteLoading />
        )
      }
      <PStyled>
        We're searching for the best rates...
      </PStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 24px;
  background: ${({ theme }: any) => theme.colors.White};
  margin: 16px 0;
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};
`;

const PStyled = styled(PSmall)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  margin-top: 24px;
  width: 100%;
  color: ${({ theme }: any) => theme.colors.Primary};

  @media ${device.laptop} {
    width: 480px;
  }
`;

const Image = styled.img.attrs(({ height }: any) => ({
  height,
}))`
  width: 200px;
  height: ${({ height }: any) => height};
`;

export default QuoteLoader;
