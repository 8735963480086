import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  Button, Caption,
  Column,
  Container,
  H3,
  H4,
  P,
  Row,
} from '../../../../../../assets/elements';
import OrderItemParcel from './components/OrderItem/OrderItemParcel';
import OrderItemPallet from './components/OrderItem/OrderItemPallet';
import device from '../../../../../utils/device';
import { getOrder } from '../../../../../utils/APICalls/Account';
import OrderItemFreight from './components/OrderItem/OrderItemFreight';
import DownloadIcon from '../../../../../../assets/icons/Download.svg';
import { downloadInvoice } from '../../../../../utils/APICalls/OrderDetails';
import { environment, AFFILIATE_MERCHANT } from '../../../../../utils/env';
import { sendBasketData } from '../../../../../store/actions';
import { setCookie } from '../../../../../utils/cookies';

function getCookie(name: string) {
  const nameEQ = `${name  }=`;
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0)
      return c.substring(nameEQ.length, c.length);
  }
  return null;
}
const processSale = (merchantID: number, orderValue: string, orderRef: string, payoutCodes: string, offlineCode: string, voucherCodes: string, productList: string, sourceCurrency: string) => {
  const affc = getCookie('Affc');
  const secure = (document.location.toString().indexOf('https') === 0);

  const RN = Math.random().toString();
  const RNS = RN.substring(2, 11);

  voucherCodes = voucherCodes || '';
  productList = productList || '';
  sourceCurrency = sourceCurrency || '';
  
  return `http${secure ? 's' : ''}://scripts.affiliatefuture.com/AFSaleV5.aspx?orderID=${orderRef}&orderValue=${orderValue}&merchant=${merchantID}&payoutCodes=${payoutCodes}&offlineCode=${offlineCode}&voucher=${voucherCodes}&products=${productList}&curr=${sourceCurrency}&affc=${affc}&r=${RNS}`;
}; 

const OrderSuccess = () => {
  const [data, setData] = useState([]);
  const [isAffiliateDataSent, setIsAffiliateDataSent] = useState(false);
  const isAffliateScriptLoaded = useSelector((state: any) => state.main.isAffliateScriptLoaded);
  const token = useSelector((state: any) => state.user.session.accessToken);
  const orderData = useSelector((state: any) => state.order.orderData);
  
  const history = useHistory();
  const { search } = useLocation();
  const handleRegister = () => {
    history.push('/register');
  };

  useEffect(() => {
    if (environment === 'p4d' || environment === 'mpd') {
      if (isAffliateScriptLoaded && !isAffiliateDataSent && data.length > 0) {
        setIsAffiliateDataSent(true);
        const iframe = document.createElement('iframe');
        iframe.src = processSale(
          AFFILIATE_MERCHANT,
          (data[0] as any).price.toString(),
          (data[0] as any).orderId,
          '',
          '',
          '',
          '',
          'GBP'
        );
        iframe.frameBorder = '0';
        iframe.width = '1';
        iframe.height = '1';
        document.body.appendChild(iframe);
      }
    }

  }, [isAffliateScriptLoaded, isAffiliateDataSent, data]);

  useEffect(() => {
    const urlParam = search.toString().substring(1);

    if (urlParam) {
      getOrder(urlParam).then((response: any) => {
        setData(response.data);
      });
    } else {
      setData(orderData);
    }

  }, []);

  const handleDownloads = (type: string) => {
    downloadInvoice(search.toString().substring(1), type)
      .then((res: any) => {
        if (type === 'pdf') {
          const string = res.data.replace('data:application/pdf;base64,', '');
          const bc = atob(string);
          const bn = new Array(bc.length);
          for (let i = 0; i < bc.length; i++) {
            bn[i] = bc.charCodeAt(i);
          }
          const byteArray = new Uint8Array(bn);
          const file = new Blob([byteArray], { type: 'application/pdf;base64' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        } else if (type === 'csv') {
          window.open(res.data, '_blank');
        }
      })
      .catch((err: any) => {
        // console.log(err);
      });
  };

  return (
    <Wrapper>
      <ContainerStyled>
        <Row>
          <Column sizeL={8} sizeM={10} sizeS={6} sizeXS={4}>
            <HeadContainer>
              <H3Styled>Your payment has been received!</H3Styled>
              <Row>
                <Column sizeL={8} sizeM={8} sizeS={6} sizeXS={4}>
                  <PStyled>
                    A confirmation email will be sent to you shortly with copies
                    of your order numbers and a link back to this page to print
                    any labels.
                  </PStyled>
                </Column>
              </Row>
            </HeadContainer>
            <InvoiceRow>
              <InvoiceText>Download Invoice</InvoiceText>
              <InvoiceButtons>
                <DownloadButton color="primary" onClick={() => handleDownloads('pdf')}>
                  <Icon src={DownloadIcon} alt="Download" />
                  Download PDF
                </DownloadButton>
                <DownloadButton color="primary" onClick={() => handleDownloads('csv')}>
                  <Icon src={DownloadIcon} alt="Download" />
                  Download CSV
                </DownloadButton>
              </InvoiceButtons>
            </InvoiceRow>
            <div>
              {data.map((item: any, i: any) => {
                if (item.type === 'parcel') {
                  return (
                    <OrderItemParcel key={i} item={item} />
                  );
                }
                if (item.type === 'pallet') {
                  return (
                    <OrderItemPallet key={i} item={item} />
                  );
                }
                if (item.type === 'freight') {
                  return (
                    <OrderItemFreight key={i} item={item} />
                  );
                }
                if (item.type === 'seafreight') {
                  return (
                    <OrderItemFreight key={i} item={item} />
                  );
                }

                return (
                  <OrderItemParcel key={i} item={item} />
                );
              })}
            </div>
          </Column>
        </Row>
      </ContainerStyled>
      {token === '' && (
        <CreateAccount>
          <Container>
            <Row>
              <Column sizeL={5} sizeM={6} sizeS={4} sizeXS={4}>
                <H4Wrapper>
                  <H4Styled>Want easy access to your order history?</H4Styled>
                </H4Wrapper>
                <PStyled>
                  It takes 2 minutes and allows you to access order history,
                  invoices and your address book
                </PStyled>
              </Column>
            </Row>
            <ButtonStyled onClick={handleRegister} color="secondary">
              Sign up
            </ButtonStyled>
          </Container>
        </CreateAccount>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  background-color: #e5e5e5;
`;

const ContainerStyled = styled(Container)`
  padding: 72px 0 !important;
`;

const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const H3Styled = styled(H3)`
  margin-bottom: 32px;
  text-align: center;
`;

const PStyled = styled(P)`
  margin-bottom: 32px;
  color: ${({ theme }: any) => theme.colors.MidGrey};
  text-align: center;
`;

const CreateAccount = styled.div`
  height: 100%;
  background-color: #ffffff;
  padding: 64px 0;
`;
const H4Wrapper = styled.div`
  text-align: center;

  @media ${device.tablet} {
    width: 80%;
    margin-left: 10%;
  }
  @media ${device.laptopL} {
    width: 100%;
    margin-left: 0;
  }
`;
const H4Styled = styled(H4)`
  margin-bottom: 32px;
  text-align: center;
`;

const ButtonStyled = styled(Button)`
  padding: 10px 14px;
  border-width: 2px;
  font-weight: 600;
  line-height: 125%;
  width: 174px;
  margin-left: calc(50% - 87px);
`;

const InvoiceRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 16px 24px;
  background: ${({ theme }: any) => theme.colors.White};
  margin-bottom: 24px;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

const InvoiceText = styled(Caption)`
  font-weight: 500;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 16px;

  @media (min-width: 600px) {
    margin-bottom: 0;
  }
`;

const InvoiceButtons = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;

const DownloadButton = styled(Button)`
  padding: 14px;
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;

  &:first-of-type {
    margin-right: 0;
    margin-bottom: 8px;
  }

  @media (min-width: 450px) {
    &:first-of-type {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export default OrderSuccess;
