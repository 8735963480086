import React from 'react';
import styled from 'styled-components';
import { H6 } from '../../../../../../assets/elements/Typography/Headings';
import Modal from '../../../../../../assets/Combos/Modal';
import { PSmall, Button } from '../../../../../../assets/elements';

const ContainerSizes = ({
  open,
  close,
}: any) => {

  return (
    <Modal
      open={open}
      close={close}
      width={6}
      title="Container Sizes"
      TitleElement={H6}
    >
      <ContainerText>{`We currently support containers of the following sizes:
        20DV: 20ft x 8ft 6in
        20HQ: 20ft x 9ft 6in
        40DV: 40ft x 8ft 6in
        40HQ: 40ft x 9ft 6in
        45HQ: 45ft x 9ft 6in
        Maximum weight is 30480kg
        `}
      </ContainerText>
      <ButtonsRow>
        <Button color="black" onClick={close}>OK</Button>
      </ButtonsRow>
    </Modal>
  );
};

const ButtonsRow = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;

const ContainerText = styled(PSmall)`
  white-space: pre-line;
`

export default ContainerSizes;
