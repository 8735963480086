import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../../assets/Combos/Modal';
import { H6, PSmall } from '../../../../../assets/elements';

const DropoffLocations = ({
  open,
  setOpen,
  locations,
  clearLocations,
}: any) => {
  const closeModal = () => {
    clearLocations();
    setOpen(false);
  };

  return (
    <Modal
      title="Your nearest dropoff locations"
      TitleElement={H6}
      open={open}
      close={closeModal}
      width={6}
    >
      <Wrapper>
        {
          locations.map((location: any, i: any) => <SingleLocation key={i}>{ location.address }</SingleLocation>)
        }
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SingleLocation = styled(PSmall)`
  display: flex;
  width: 100%;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  padding: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  border-radius: 4px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export default DropoffLocations;
