import React, { useEffect } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { A, Caption, Column, Input, Label, PSmall, Row } from '../../../../../assets/elements';
import device from '../../../../utils/device';
import RemoveIcon from '../../../../../assets/icons/RemoveIcon';
import AddIcon from '../../../../../assets/icons/AddIcon';
import { countriesPostalCodesData } from '../../../../mocks/quoteData';
import { SelectInput } from '../../../Inputs';
import TariffCodeLookup from '../../../TariffCodeLookup';

const ItemDetails = ({
  parcel,
  onChange,
  onValuesChange,
  theme,
  lengthError,
  widthError,
  heightError,
  weightError,
  addNewItem,
  removeItem,
  removeParcel,
  itemError,
  valueError,
  index,
  customs,
  handleWeightMatchError,
  weightMatchError,
}: any) => {
  const { country: deliveryCountry } = useSelector((state: any) => state.order.deliveryAddress);

  useEffect(() => {
    let blank = false;

    parcel.items.forEach((item: any) => {
      blank = item.weight === '';
    });

    if (
      !blank &&
      (Number(parcel.weight.amount) !== parcel.items.reduce((acc: any, item: any) =>
        Number(acc) + (Number(item?.weight) * Number(item.quantity || 1)) || 0, 0))) {
      handleWeightMatchError(parcel.id, true);
    } else {
      handleWeightMatchError(parcel.id, false);
    }
  }, [parcel.items, parcel.weight]);

  return (
    <Wrapper>
      <LabelParcel>PARCEL {index + 1}</LabelParcel>
      <RowStyledMain>
        <ColumnPad sizeL={7} sizeM={7} sizeS={6}>
          <LabelStyled>Dimensions</LabelStyled>
          <Row>
            <ColumnStyled sizeL={3.33} sizeM={3} sizeS={1.5} sizeXS={1}>
              <Input
                placeholder="Length"
                value={parcel.length.amount}
                onChange={(e: any) => onChange(e, parcel.id, 'length')}
                theme={theme}
                type="text"
                error={!!lengthError}
              />
              <ErrorText>{lengthError}</ErrorText>
            </ColumnStyled>
            <ColumnStyled sizeL={3.33} sizeM={3} sizeS={1.5} sizeXS={1}>
              <Input
                placeholder="Width"
                value={parcel.width.amount}
                onChange={(e: any) => onChange(e, parcel.id, 'width')}
                theme={theme}
                type="text"
                error={!!widthError}
              />
              <ErrorText>{widthError}</ErrorText>
            </ColumnStyled>
            <ColumnStyled sizeL={5.33} sizeM={6} sizeS={3} sizeXS={2}>
              <Input
                placeholder="Height"
                value={parcel.height.amount}
                onChange={(e: any) => onChange(e, parcel.id, 'height')}
                theme={theme}
                type="text"
                error={!!heightError}
              />
              <InputUnitsLabel>cm</InputUnitsLabel>
              <ErrorText>{heightError}</ErrorText>
            </ColumnStyled>
          </Row>
        </ColumnPad>
        <ColumnPad sizeL={5} sizeM={5} sizeS={6}>
          <LabelStyled>Weight</LabelStyled>
          <Row>
            <ColumnStyled sizeL={12} sizeM={12}>
              <Input
                placeholder="Weight"
                value={parcel.weight.amount}
                onChange={(e: any) => onChange(e, parcel.id, 'weight')}
                theme={theme}
                type="text"
                error={!!weightError}
              />
              <InputUnitsLabel>kg</InputUnitsLabel>
            </ColumnStyled>
            <ErrorText>{weightError}</ErrorText>
          </Row>
        </ColumnPad>
      </RowStyledMain>
      {
        parcel.items.map((item: any, i: any) => (
          <RowStyled key={i}>
            <ColumnPad
              sizeL={customs ? 12 : 6}
              sizeM={customs ? 12 : 6}
              sizeS={customs ? 6 : 3}
              sizeXS={4}
            >
              <LabelStyled>Item { i + 1 }</LabelStyled>
              <Input
                placeholder="Content"
                value={item.description}
                onChange={(e: any) => onValuesChange(parcel.id, item.id, e.target.value, 'description')}
                theme={theme}
                type="text"
                error={!!itemError}
              />
              <ErrorText>{itemError}</ErrorText>
            </ColumnPad>
            {
              customs && (
                <>
                  <ColumnPad
                    sizeL={12}
                    sizeM={12}
                    sizeS={6}
                    sizeXS={4}
                  >
                    <LabelStyled>Manufactured in</LabelStyled>
                    <SelectInput
                      error={false}
                      resetError={() => {}}
                      value={item.manufactured_in}
                      onChange={(selected: any) => onValuesChange(parcel.id, item.id, selected, 'manufactured_in')}
                      data={countriesPostalCodesData}
                      height="50px"
                    />
                    <ErrorText>{itemError}</ErrorText>
                  </ColumnPad>
                  <ColumnPad
                    sizeL={12}
                    sizeM={12}
                    sizeS={6}
                    sizeXS={4}
                  >
                    <LabelStyled>Tariff Code</LabelStyled>
                    <TariffCodeLookup
                      value={item.tariff_code}
                      description={item.description}
                      manufacturedIn={item.manufactured_in}
                      deliveryCountry={deliveryCountry}
                      onChange={(selected: any) => onValuesChange(parcel.id, item.id, selected, 'tariff_code')}
                    />
                  </ColumnPad>
                  <RowStyled>
                    <ColumnPad
                      sizeL={6}
                      sizeM={6}
                      sizeS={6}
                      sizeXS={4}
                    >
                      <LabelStyled>Item weight</LabelStyled>
                      <Input
                        placeholder="Item weight"
                        value={item.weight}
                        onChange={(e: any) => onValuesChange(parcel.id, item.id, e.target.value, 'weight')}
                        theme={theme}
                        type="text"
                        error={!!itemError}
                      />
                      <ErrorText>{itemError}</ErrorText>
                    </ColumnPad>
                    <ColumnPad
                      sizeL={6}
                      sizeM={6}
                      sizeS={6}
                      sizeXS={4}
                    >
                      <LabelStyled>Number of items</LabelStyled>
                      <Input
                        placeholder="Item count"
                        value={item.quantity}
                        onChange={(e: any) => onValuesChange(parcel.id, item.id, e.target.value, 'quantity')}
                        theme={theme}
                        type="text"
                        error={!!itemError}
                      />
                      <ErrorText>{itemError}</ErrorText>
                    </ColumnPad>
                  </RowStyled>
                </>
              )
            }
            <ColumnPad
              sizeL={customs ? 6 : 3}
              sizeM={customs ? 6 : 3}
              sizeS={customs ? 3 : 1.5}
              sizeXS={3}
            >
              <LabelStyled>Item value</LabelStyled>
              <RelativeDiv>
                <Input
                  placeholder="Value"
                  value={item.value}
                  onChange={(e: any) => onValuesChange(parcel.id, item.id, e.target.value, 'value')}
                  theme={theme}
                  type="text"
                  error={!!valueError}
                />
                <CurrencyLabel>£</CurrencyLabel>
                <ErrorText>{valueError}</ErrorText>
              </RelativeDiv>
            </ColumnPad>
            <IconColumn
              sizeL={customs ? 3 : 3}
              sizeM={customs ? 3 : 3}
              sizeS={customs ? 1.5 : 1.5}
              sizeXS={1}
            >
              {parcel.items.length > 1 && (
                <IconContainer>
                  <A
                    data-tip
                    data-for={`remove-icon-${item.id}-${i}`}
                    onClick={() => removeItem(parcel.id, item.id)}
                  >
                    <RemoveIcon width={25} height={25} />
                  </A>
                  <ReactTooltip id={`remove-icon-${item.id}-${i}`} effect="solid">
                    Remove item
                  </ReactTooltip>
                </IconContainer>
              )}
              {parcel.items.length === i + 1 && (
                <IconContainer>
                  <A data-tip data-for={`add-icon-${item.id}-${i}`} onClick={() => addNewItem(parcel.id)}>
                    <AddIcon width={25} height={25} />
                  </A>
                  <ReactTooltip id={`add-icon-${item.id}-${i}`} effect="solid">
                    Add item
                  </ReactTooltip>
                </IconContainer>
              )}
            </IconColumn>
            <ColumnStyled
              sizeL={customs ? 3 : 0}
              sizeM={customs ? 3 : 0}
              sizeS={customs ? 1.5 : 0}
              sizeXS={customs ? 1.5 : 0}
            />
          </RowStyled>
        ))
      }
      {
        weightMatchError && (
          <WeightWarning>
            The total weight of your items must equal the weight of this parcel.
          </WeightWarning>
        )
      }
      {
        index > 0 && (
          <RemoveParcel onClick={() => removeParcel(parcel.id)}>
            <RemoveIcon width={25} height={25} />
            <RemoveLink>Remove parcel</RemoveLink>
          </RemoveParcel>
        )
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};
  padding: 16px;
  margin-bottom: 26px;
`;

const RowStyled = styled(Row)`
  margin-bottom: 0;
  width: 100%;
`;

const RowStyledMain = styled(RowStyled)`
  border-bottom: 2px solid ${({ theme }: any) => theme.colors.LightGrey};
  margin-bottom: 20px;
`;

const ColumnStyled = styled(Column)`
  padding: 0;
  position: relative;

  & > input {
    border-radius: unset;
  }

  &:first-child > input {
    border-top-left-radius: ${({ theme }: any) =>
    theme.box.BorderRadiusSmall};
    border-bottom-left-radius: ${({ theme }: any) =>
    theme.box.BorderRadiusSmall};
  }

  &:last-child > input {
    border-top-right-radius: ${({ theme }: any) =>
    theme.box.BorderRadiusSmall};
    border-bottom-right-radius: ${({ theme }: any) =>
    theme.box.BorderRadiusSmall};
  }
`;

const ColumnPad = styled(Column)`
  padding: 0;
  margin-bottom: 24px;
`;

const ColumnPad2 = styled(Column)`
  padding: 0;
  margin-bottom: 24px;

  @media ${device.laptop} {
    padding: 0 8px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

const IconColumn = styled(Column)`
  display: flex;
  justify-content: flex-start;
  padding: 0 8px;
  margin-bottom: 24px;
  padding-top: 22px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 0 0 50%;
  max-width: 50%;
  align-items: center;
`;

const LabelStyled = styled(Label)`
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const LabelParcel = styled(Label)`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }: any) => theme.colors.Black};
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

const InputUnitsLabel = styled(PSmall)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({ theme }: any) => theme.colors.Black};
  background-color: #DEDEDE;
  border-radius: ${({ theme }: any) =>
    `0 ${theme.box.BorderRadiusSmall} ${theme.box.BorderRadiusSmall} 0`};

  position: absolute;
  top: 1px;
  right: 1px;
  width: 40%;
  bottom: 1px;
  height: calc(100% - 2px);
`;

const CurrencyLabel = styled(InputUnitsLabel)`
  width: 30%;
  background: ${({ theme }: any) => theme.colors.Primary};
  color: ${({ theme }: any) => theme.colors.White};
`;

const RelativeDiv = styled.div`
  position: relative;
`;

const RemoveParcel = styled(A)`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-top: 24px;
  border-top: 1px dashed ${({ theme }: any) => theme.colors.LightGrey};
  cursor: pointer;
`;

const RemoveLink = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  margin-left: 10px;
`;

const WeightWarning = styled.div`
  background: #FEE6D0;
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  padding: 24px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
`;


export default ItemDetails;
