import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import device from '../../utils/device';
import {
  Input,
  Caption,
  Label,
  Row,
  Column,
  Button
} from '../../../assets/elements';
import Index from '../PasswordCriteria';
import { setUserSession } from '../../store/actions';
import { setCookie } from '../../utils/cookies';
import { GTMEvent } from '../../utils/tracking';
import { createAccount, logInUser } from '../../utils/APICalls/Account';
import { logo } from '../../../assets/styles/themes';

const Register = (props: any) => {
  const dispatch = useDispatch();
  const [details, setDetails] = React.useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = React.useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [focus, setFocus] = React.useState(false);
  const [criteriaMeet, setCriteriaMeet] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
    minimum: false,
    maximum: false,
  });
  const [creating, setCreating] = useState(false);
  const history = useHistory();

  const handleInputChange = (value: any, type: any) => {
    setErrors({
      ...errors,
      [type]: '',
    });
    setDetails({
      ...details,
      [type]: value,
    });
  };

  const validateForm = () => {
    let error = false;

    const validateName = () => {
      let nameError: string = '';

      if (details.name.length === 0) {
        nameError = 'Name is required';
        error = true;
      }

      return nameError;
    };
    const validateEmail = () => {
      let emailError = '';

      if (details.email.length === 0) {
        emailError = 'Email is required';
        error = true;
      } else if (
        !details.email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/)
      ) {
        emailError = 'Please add a valid email';
        error = true;
      }

      return emailError;
    };
    const validatePassword = () => {
      let passwordError: string = '';

      if (details.password.length === 0) {
        passwordError = 'Password is required';
        error = true;
      } else {
        for (const [, value] of Object.entries(criteriaMeet)) {
          if (!value) {
            passwordError = 'Incorrect password';
            error = true;
          }
        }
      }

      return passwordError;
    };
    const validateConfirmPassword = () => {
      let confirmPasswordError = '';

      if (details.confirmPassword.length === 0) {
        confirmPasswordError = 'Field is required';
        error = true;
      } else if (details.password !== details.confirmPassword) {
        confirmPasswordError = 'Passwords must be the same';
        error = true;
      }

      return confirmPasswordError;
    };

    setErrors({
      name: validateName(),
      email: validateEmail(),
      password: validatePassword(),
      confirmPassword: validateConfirmPassword(),
    });

    return error;
  };

  const handleSubmit = async () => {
    const error = validateForm();

    if (!error) {
      setCreating(true);
      const dataCreateAccount = {
        user: {
          name: details.name,
          email: details.email,
          password: details.password,
        },
      };
      const dataLogIn = {
        user: {
          email: details.email,
        },
        password: details.password,
      };

      await createAccount(dataCreateAccount)
        .then(() => {
          GTMEvent('userRegistration');

          logInUser(dataLogIn)
            .then((res: any) => {
              dispatch(
                setUserSession({
                  userName: res.data.user.name,
                  userId: res.data.user.id,
                  accessToken: res.data.tokens.accessToken,
                  refreshToken: res.data.tokens.refreshToken,
                })
              );

              setCookie([
                {
                  name: 'accessToken',
                  value: res.data.tokens.accessToken,
                  expiry: new Date(
                    Number(res.data.expires.access_token) * 1000
                  ).toUTCString(),
                },
                {
                  name: 'accessTokenExpiry',
                  value: res.data.expires.access_token,
                  expiry: new Date(
                    Number(res.data.expires.access_token) * 1000
                  ).toUTCString(),
                },
                {
                  name: 'refreshToken',
                  value: res.data.tokens.refreshToken,
                  expiry: new Date(
                    Number(res.data.expires.refresh_token) * 1000
                  ).toUTCString(),
                },
                {
                  name: 'refreshTokenExpiry',
                  value: res.data.expires.refresh_token,
                  expiry: new Date(
                    Number(res.data.expires.refresh_token) * 1000
                  ).toUTCString(),
                },
                {
                  name: 'userName',
                  value: res.data.user.name,
                  expiry: new Date(
                    Number(res.data.expires.refresh_token) * 1000
                  ).toUTCString(),
                },
                {
                  name: 'userId',
                  value: res.data.user.id,
                  expiry: new Date(
                    Number(res.data.expires.refresh_token) * 1000
                  ).toUTCString(),
                },
              ]);

              history.push('/register-confirmation');
            })
            .catch((errorCatch: any) => {
              // eslint-disable-next-line no-console
              console.log(errorCatch);
              setCreating(false);
              return errorCatch;
            });
        })
        .catch(() => {
          setErrors({
            ...errors,
            email: 'Email already exists.',
          });

          setCreating(false);
        });
    }
  };

  const handleRedirect = () => {
    history.push('/login');
  };

  return (
    <Wrapper>
      <ImageWrapper>
        <Image src={logo} />
      </ImageWrapper>
      <TitlesWrapper>
        <Title>Create account</Title>
        <SubTitle>
          Create an account to access your order history, invoices and address
          book.
        </SubTitle>
      </TitlesWrapper>
      <Row>
        <ColumnStyled sizeL={12} sizeM={12} sizeS={8} sizeXS={3}>
          <RowStyled>
            <LabelStyled>Name</LabelStyled>
            <InputStyled
              placeholder="Name"
              value={details.name}
              onChange={(e: any) => handleInputChange(e.target.value, 'name')}
              theme={props.theme}
              type="text"
              error={errors.name}
            />
            <ErrorText>{errors.name}</ErrorText>
          </RowStyled>
          <RowStyled>
            <LabelStyled>Email</LabelStyled>
            <InputStyled
              placeholder="Email address"
              value={details.email}
              onChange={(e: any) => handleInputChange(e.target.value, 'email')}
              theme={props.theme}
              type="text"
              error={errors.email}
            />
            <ErrorText>{errors.email}</ErrorText>
          </RowStyled>
          <RowStyled
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          >
            <LabelStyled>Password</LabelStyled>
            <InputStyled
              placeholder="Create your password"
              value={details.password}
              onChange={(e: any) =>
                handleInputChange(e.target.value, 'password')}
              theme={props.theme}
              type="password"
              error={errors.password}
            />
            <ErrorText>{errors.password}</ErrorText>
            <Index
              password={details.password}
              active={focus}
              criteriaMeet={criteriaMeet}
              setCriteriaMeet={setCriteriaMeet}
            />
          </RowStyled>
          <RowStyled>
            <LabelStyled>Confirm password</LabelStyled>
            <InputStyled
              placeholder="Confirm password"
              value={details.confirmPassword}
              onChange={(e: any) =>
                handleInputChange(e.target.value, 'confirmPassword')}
              theme={props.theme}
              type="password"
              error={errors.confirmPassword}
            />
            <ErrorText>{errors.confirmPassword}</ErrorText>
          </RowStyled>
        </ColumnStyled>
      </Row>
      <Row>
        <ButtonWrapper>
          <ButtonStyled
            onClick={handleSubmit}
            color="secondary"
            disable={creating}
            disabled={creating}
          >
            {creating ? 'Creating...' : 'Create account'}
          </ButtonStyled>
        </ButtonWrapper>
      </Row>
      <Row>
        <FootText>
          Already have an account?{' '}
          <SpanStyled onClick={handleRedirect}>Log in</SpanStyled>
        </FootText>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background: ${({ theme }: any) => theme.colors.GrayWhite};
  min-height: calc(100vh - 75px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 48px 0;
`;
const TitlesWrapper = styled.div`
  text-align: center;
  margin: 20px 0 10px;
`;
const Title = styled.h3`
  font-size: 2rem;
  margin-bottom: 20px;
`;
const SubTitle = styled.h3`
  font-weight: 200;
  width: 300px;
  line-height: 1.4rem;
`;
const ImageWrapper = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 16px;

  @media ${device.desktop} {
    padding: 12px 40px;
  }
`;
const Image = styled.img`
  height: 40px;
`;
const RowStyled = styled.div`
  box-sizing: border-box;
  position: relative;

  ${device.desktop} {
    max-width: 1140px;
  }
`;
const ColumnStyled = styled(Column)`
  padding: 0;
  position: relative;
`;
const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: 400;
  text-align: left;
  margin: 25px 0 0 5px;
`;
const InputStyled = styled(Input)<any>`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 5px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ButtonStyled = styled(Button)`
  padding: 10px 14px;
  border-width: 2px;
  line-height: 125%;
  margin-top: 35px;
`;
const FootText = styled.p`
  margin-top: 20px;
`;
const SpanStyled = styled.span`
  margin: 15px 0 30px;
  color: ${({ theme }: any) => theme.colors.Secondary};
  font-weight: bold;
  cursor: pointer;
`;

export default Register;
