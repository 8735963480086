import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import postalCodes from 'postal-codes-js';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Caption, Column, Input, Label, Row, A } from '../../../../../../../assets/elements';
import device from '../../../../../../utils/device';
import { SelectInput } from '../../../../../Inputs';
import { countriesPostalCodesData } from '../../../../../../mocks/quoteData';
import DummyTab from './DummyTab';
import { landingPageUrl, supportUrl } from '../../../../../../utils/env';
import FullLoad from './components/FullLoad';
import LessThanFullLoad from './components/LessThanFullLoad';
import { setActiveTab, setFreightValues } from '../../../../../../store/actions';
import { generateQuotesUrl } from '../../../../../../utils/quotesUrl';
import DateSelector from '../../../../../Quotes/components/FreightQuotes/Calendar';
import Switch from '../../../../../../../assets/Combos/Switch';
import InfoIcon from '../../../../../../../assets/icons/InfoIcon';
import Hazardous from '../../Modals/Hazardous';
import Container from './components/Container';

const StepOne = ({
  onQuotesPage = false,
  theme,
  closeModal,
  resetValues,
  setResetValues,
}: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const freightStore = useSelector<any, any>((state) => state.main.freight);

  const [load, setLoad] = useState('FTL');
  const [freight, setFreight] = useState<any>({
    collectionLocation: {
      country: '',
      postcode: '',
    },
    packageDetails: {
      numPallets: 26,
      loadType: 'FTL'
    },
    deliveryLocation: {
      country: '',
      postcode: '',
    },
    readyDate: '',
    loadType: 'FTL'
  });

  const [disabled, setDisabled] = useState(false);
  const [hazardous, setHazardous] = useState(false);
  const [hazardousModal, setHazardousModal] = useState(false);

  const [fromError, setFromError] = useState<any>({
    countryCodeError: false,
    postCodeError: false,
  });

  const [toError, setToError] = useState<any>({
    countryCodeError: false,
    postCodeError: false,
  });

  const [loadError, setLoadError] = useState<any>(false);
  const [dateError, setDateError] = useState<any>(false);

  useEffect(() => {
    if (resetValues) {
      setFreight({
        ...freight,
        ...freightStore,
      });

      fromCountryErrorClean();
      toCountryErrorClean();
      fromPostCodeErrorClean();
      toPostCodeErrorClean();
      setLoadError(false);
      setResetValues(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues]);

  const fromCountryErrorClean = () => {
    setFromError({
      ...fromError,
      countryCodeError: false,
    });
  };

  const toCountryErrorClean = () => {
    setToError({
      ...toError,
      countryCodeError: false,
    });
  };

  const fromPostCodeErrorClean = () => {
    setFromError({
      ...fromError,
      postCodeError: false,
    });
  };

  const toPostCodeErrorClean = () => {
    setToError({
      ...toError,
      postCodeError: false,
    });
  };

  const handlePostalCodeChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    toPostCodeErrorClean();
    fromPostCodeErrorClean();
    const { value } = e.currentTarget;

    setFreight({
      ...freight,
      [key]: {
        ...freight[key],
        postcode: value,
      },
    });
  };

  const handleSelectChange = (value: any, type: any) => {
    setFreight({
      ...freight,
      [type]: {
        ...freight[type],
        country: value,
      },
    });
  };

  const handleTabClick = (type: string = 'road') => {
    if (type === 'road') {
      return;
    }

    if (type === 'sea') {
      window.open(`${landingPageUrl}sea-freight`, '_blank');
    } else if (type === 'air') {
      window.open(`${landingPageUrl}air-freight`, '_blank');
    }
  };

  const setLoadValue = (type: string, quantity: any) => {
    setFreight({
      ...freight,
      packageDetails: {
        numPallets: quantity,
        loadType: type
      },
      loadType: type
    });

    setLoadError(false);
  };

  const validateFrom = () => {
    let error = false;

    const result = postalCodes.validate(
      freight.collectionLocation.country,
      freight.collectionLocation.postcode
    );
    const postCode = freight.collectionLocation.postcode;

    if (result && result !== true) {
      if (result.includes('Missing country code')) {
        setFromError({
          ...fromError,
          countryCodeError: result,
        });

        error = true;
      } else if (
        result.includes('Missing postal code')
      ) {
        setFromError({
          ...toError,
          postCodeError: 'Missing postcode',
          countryCodeError: false,
        });

        error = true;
      } else if (
        result.includes(
          `Postal code ${postCode} is not valid for country ${freight.collectionLocation.country}`
        )
      ) {
        setFromError({
          ...toError,
          postCodeError: 'Wrong code',
          countryCodeError: false,
        });

        error = true;
      } else if (postCode.length > 0) {
        if (isNaN(Number(postCode))) {
          setFromError({
            ...fromError,
            postCodeError: 'Wrong code',
            countryCodeError: false,
          });

          error = true;
        }
      } else {
        setFromError({
          ...fromError,
          postCodeError: false,
          countryCodeError: false,
        });

        error = false;
      }
    }
    return error;
  };

  const validateTo = () => {
    let error = false;
    const result = postalCodes.validate(
      freight.deliveryLocation.country,
      freight.deliveryLocation.postcode
    );
    const postCode = freight.deliveryLocation.postcode;

    if (result && result !== true) {
      if (result.includes('Missing country code')) {
        setToError({
          ...toError,
          countryCodeError: result,
        });

        error = true;
      } else if (
        result.includes('Missing postal code')
      ) {
        setToError({
          ...toError,
          postCodeError: 'Missing postcode',
          countryCodeError: false,
        });

        error = true;
      } else if (
        result.includes(
          `Postal code ${postCode} is not valid for country ${freight.deliveryLocation.country}`
        )
      ) {
        setToError({
          ...toError,
          postCodeError: 'Wrong code',
          countryCodeError: false,
        });

        error = true;
      } else if (postCode.length > 0) {
        if (isNaN(Number(postCode))) {
          setToError({
            ...toError,
            postCodeError: 'Wrong code',
            countryCodeError: false,
          });
          error = true;
        }
      } else {
        setToError({
          ...toError,
          postCodeError: false,
          countryCodeError: false,
        });

        error = false;
      }
    }
    return error;
  };

  const validateLoad = () => {
    let error = false;

    if (!freight.packageDetails.loadType) {
      setLoadError('Select a value');
      error = true;
    }

    if ((load === 'LTL' || load === 'container')  && freight.packageDetails.numPallets === 26 ) {
      setLoadError('Select a value');

      error = true;
    }

    if (load === 'LTL' && freight.packageDetails.loadType !== 'LTL') {
      setLoadError('Select quantity');
    }

    if (freight.readyDate === '') {
      setDateError('Required');

      error = true;
    }

    return error;
  };

  const handleReadyDateChange = (date: any) => {
    setFreight({
      ...freight,
      readyDate: date,
    });

    if (dateError) {
      setDateError(false);
    }
  };

  const handleHazardous = (value: any) => {
    if (value) {
      setHazardousModal(true);
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    setHazardous(value);
  };

  const turnHazardousOff = () => {
    setHazardous(false);

    setDisabled(false);
    setHazardousModal(false);
  };

  const handleSupport = () => {
    window.open(supportUrl, '_blank');
  };

  const handleSubmit = () => {
    const errorFrom = validateFrom();
    const errorTo = validateTo();
    const errorLoad = validateLoad();

    if (errorFrom || errorTo || errorLoad) {
      return;
    }

    dispatch(setActiveTab('freight'));
    dispatch(setFreightValues(freight));

    window.location.href = generateQuotesUrl(freight, 'freight');
  };

  return (
    <Wrapper>
      <RowStyled>
        <ColumnPad sizeL={6} sizeM={6} sizeS={6}>
          <LabelStyled>Collect from:</LabelStyled>
          <Row>
            <ColumnStyled sizeL={7} sizeM={7} sizeS={4} sizeXS={2.5}>
              <SelectInput
                title="From"
                data={countriesPostalCodesData}
                error={fromError.countryCodeError}
                resetError={fromCountryErrorClean}
                defaultValue="GB"
                value={freight.collectionLocation.country}
                onChange={(code: string) =>
                  handleSelectChange(code, 'collectionLocation')}
                height="50px"
                radius="4px"
              />
              <ErrorText>{fromError.countryCodeError}</ErrorText>
            </ColumnStyled>
            <ColumnStyled sizeL={5} sizeM={5} sizeS={2} sizeXS={1.5}>
              <InputStyled
                placeholder="Postcode"
                value={freight.collectionLocation.postcode}
                onChange={(e: any) =>
                  handlePostalCodeChange(e, 'collectionLocation')}
                theme={theme}
                type="text"
                error={!!fromError.postCodeError}
                name="postcode_collection"
              />
              <ErrorText>{fromError.postCodeError}</ErrorText>
            </ColumnStyled>
          </Row>
        </ColumnPad>
        <ColumnPad sizeL={6} sizeM={6} sizeS={6}>
          <LabelStyled>Deliver to:</LabelStyled>
          <Row>
            <ColumnStyled sizeL={7} sizeM={7} sizeS={4} sizeXS={2.5}>
              <SelectInput
                title="To"
                actionName="SET_PARCEL_TO_COUNTRYCODE"
                data={countriesPostalCodesData}
                error={toError.countryCodeError}
                resetError={toCountryErrorClean}
                defaultValue="GB"
                value={freight.deliveryLocation.country}
                onChange={(code: string) =>
                  handleSelectChange(code, 'deliveryLocation')}
                height="50px"
                radius="4px"
              />
              <ErrorText>{toError.countryCodeError}</ErrorText>
            </ColumnStyled>
            <ColumnStyled sizeL={5} sizeM={5} sizeS={2} sizeXS={1.5}>
              <InputStyled
                placeholder="Postcode"
                value={freight.deliveryLocation.postcode}
                onChange={(e: any) =>
                  handlePostalCodeChange(e, 'deliveryLocation')}
                theme={theme}
                type="text"
                error={!!toError.postCodeError}
                name="postcode_delivery"
              />
              <ErrorText>{toError.postCodeError}</ErrorText>
            </ColumnStyled>
          </Row>
        </ColumnPad>
      </RowStyled>
      <RowStyled>
        <ColumnDate sizeL={6} sizeM={6} sizeS={6}>
          <LabelStyled>Goods ready date</LabelStyled>
          <DateSelector
            date={freight.readyDate}
            setDate={handleReadyDateChange}
            error={dateError}
            minDate={new Date()}
            country={freight.collectionLocation.country}
          />
        </ColumnDate>
        <ColumnPad sizeL={6} sizeM={6} sizeS={6}>
          <LabelStyled>Hazardous cargo?</LabelStyled>
          <SwitchContainer>
            <Switch value={hazardous} onChange={(value: any) => handleHazardous(value)} />
            <Icon onClick={() => setHazardousModal(true)}><InfoIcon /></Icon>
          </SwitchContainer>
          <Hazardous
            open={hazardousModal}
            close={() => turnHazardousOff()}
            turnOff={turnHazardousOff}
          />
        </ColumnPad>
      </RowStyled>

      <ShippingWrapper>
        <LabelShipping>How much are you shipping?</LabelShipping>
        <LabelShippingSub>
          We currently only support shipments of full pallets with the following dimesions: (1M X 1.2M X 2.2M)
        </LabelShippingSub>
        <ShippingLoad>
          <FullLoad
            onClick={() => {
              setLoad('FTL');
              setLoadValue('FTL', 26);
            }}
            selected={load === 'FTL'}
          />
        </ShippingLoad>
        <ShippingLoad>
          <LessThanFullLoad
            onClick={() => {
              setLoad('LTL');
            }}
            selected={load === 'LTL'}
            onChange={setLoadValue}
            value={load === 'LTL' ? freight.packageDetails.numPallets: null}
          />
        </ShippingLoad>
        <ShippingLoad>
          <Container
            onClick={() => setLoad('container')}
            selected={load === 'container'}
            onChange={setLoadValue}
            value={load === 'container'? freightStore.packageDetails.loadType: null}
          />
        </ShippingLoad>
        <ErrorTextPad>{ loadError }</ErrorTextPad><br />
        <LabelShippingContact>
          Looking for more options? Please <AStyled onClick={handleSupport}>contact support</AStyled>
        </LabelShippingContact>
      </ShippingWrapper>

      <RowStyled>
        <ButtonStyled
          color="secondary"
          block
          onClick={handleSubmit}
          disable={disabled}
          disabled={disabled}
        >
          {onQuotesPage ? 'Update results' : 'Get an instant quote'}
        </ButtonStyled>
      </RowStyled>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
`;
const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
`;

const LabelShipping = styled(Label)`
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  font-weight: 500;
`;

const ColumnStyled = styled(Column)`
  padding: 0;
  position: relative;
`;

const RowStyled = styled(Row)`
  margin-bottom: 0;
`;

const InputStyled = styled(Input)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const ColumnPad = styled(Column)`
  padding: 0;
  margin-bottom: 24px;
  position: relative;

  @media ${device.laptop} {
    padding: 0 15px;
    margin-bottom: 24px;
  }
`;

const ColumnDate = styled(Column)`
  padding: 0;
  position: relative;
  margin-bottom: 24px;

  @media ${device.laptop} {
    padding: 0 15px;
    margin-botton: 0;
  }
`;

const ErrorText = styled(Caption)`
  color: ${({ theme: { colors } }: any) => colors.Error};
`;

const ErrorTextPad = styled(ErrorText)`
  @media ${device.laptop} {
    left: 15px;
  }

  left: 0;
`;

const ButtonStyled = styled(Button)`
  && {
    font-family: Montserrat, sans-serif !important;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Icon = styled(A)`
  margin-left: 24px;
`;

const ShippingWrapper = styled.div`
  background: ${({ theme: { colors } }: any) => colors.WhiteGrey};
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

const ShippingLoad = styled.div`
  background: ${({ theme: { colors } }: any) => colors.White};
  margin-bottom: 8px;
`;

const LabelShippingSub = styled(Label)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }: any) => theme.colors.MidGrey};
  margin-bottom: 16px;
`;

const LabelShippingContact = styled(Label)`
  font-weight: 500;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  margin-top: 16px;
`;

const AStyled = styled(A)`
  padding: 0;
`;

export default StepOne;
