import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../../../assets/Combos/Modal';
import { H6, PSmall, Button } from '../../../../../../assets/elements';
import { landingPageUrl, hazardousQuoteUrl } from '../../../../../utils/env';


const Hazardous = ({
  open,
  close,
  turnOff,
}: any) => {
  const goToBespoke = () => {
    window.open(`${landingPageUrl}${hazardousQuoteUrl}`);
  };

  const handleClose = () => {
    turnOff();
    close();
  };

  return (
    <Modal
      open={open}
      close={close}
      width={6}
      title="Hazardous Goods"
      TitleElement={H6}
    >
      <PSmall>
        P4D cannot currently quote dynamically for hazardous goods.
        We will need to collect some additional information in order to obtain a quote from our specialist partners.
      </PSmall>
      <ButtonsRow>
        <Button color="black" onClick={handleClose}>OK, go back</Button>
        <Button color="secondary" onClick={goToBespoke}>Get bespoke quote</Button>
      </ButtonsRow>
    </Modal>
  );
};

const ButtonsRow = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

export default Hazardous;
