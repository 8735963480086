import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import LoadContainer from './LoadContainer';
import { PSmall, A } from '../../../../../../../../assets/elements';
import { SelectInput } from '../../../../../../Inputs';
import device from '../../../../../../../utils/device';
import ContainerSizes from '../../../Modals/ContainerSizes';
import InfoIcon from '../../../../../../../../assets/icons/InfoIcon';
import containerTypes from '../../../../../../../utils/containerTypes.json';

const Container = ({
  onClick,
  selected,
  onChange,
  value,
}: any) => {
  const [containerType, setContainerType] = useState(value);
  const [quantity, setQuantity] = useState(1);
  const [quantityValues, setQuantityValues] = useState<any>([{ label: 1, value: 1 }]);
  const select = useRef(null);
  const [infoModal, setInfoModal] = useState(false);

  const containerValues = containerTypes.map((type) => {
    return { label: type, value: type };
  });

  useEffect(() => {
    const temp = [];
    for (let i = 20; i >= 1; i--) {
      temp.unshift({
        label: i,
        value: i,
      });
    }

    setQuantityValues(temp);
    setContainerType(value)
  }, []);


  useEffect(() => {
    if (selected && select) {
      onChange(containerType, 1);
    }
  }, [selected]);

  const handleChange = (type: string, val: any) => {
    if (type === 'type') {
      setContainerType(val);
      onChange(val, quantity)
    }
    if (type === 'quantity') {
      setQuantity(val);
      onChange(containerType, val)
    }
  };

  return (
    <LoadContainer
      onClick={onClick}
      selected={selected}
    >
      <Wrapper>
        <SubWrapper>
          <Title>Container</Title>
        </SubWrapper>
        <SubWrapper>
          {/* <SubTitle>Type:</SubTitle> */}
          <SelectInput
            innerKey={value}
            innerRef={select}
            title="Type"
            data={containerValues}
            error={false}
            resetError={() => {}}
            value={containerType}
            onChange={(type: string) => handleChange('type', type)}
            radius="4px"
          />
          <StyledA onClick={() => setInfoModal(true)}><InfoIcon /></StyledA>
        </SubWrapper>
        {/* <SubWrapper>
        <SubTitle>Quantity:</SubTitle>
        <SelectInput
          innerKey={value}
          innerRef={select}
          title="Quantity"
          data={quantityValues}
          error={false}
          resetError={() => {}}
          value={quantity}
          onChange={(quantityValue: number) => handleChange('quantity', quantityValue)}
          radius="4px"
        />
        </SubWrapper> */}
        <ContainerSizes
          open={infoModal}
          close={() => setInfoModal(false)}
        />
      </Wrapper>
    </LoadContainer>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media ${device.laptop} {
    flex-direction: row;
    align-items: center;
  }
`;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled(PSmall)`
  font-weight: 600;
  display: block;
  width: 100%;
  white-space: nowrap;
  margin-right: 16px;
`;

const SubTitle = styled(PSmall)`
  color: ${({ theme }: any) => theme.colors.MidGrey};
  white-space: nowrap;
  margin-right: 5px;
`;

const StyledA = styled(A)`
  margin-left: 5px;
`;

export default Container;
