import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Caption, Column, Container, Input, Row } from '../../../../../assets/elements';
import {
  addressBookToggle,
  goToNextQuickBookStep,
  setCollectionAddress,
  setCollectionDetails as setCollectionInfo,
} from '../../../../store/actions';
import MobileNumber from '../../../../../assets/Combos/MobileNumber';
import { VATStatusOptions } from '../../../../utils/variables';
import { SelectInput } from '../../../Inputs';
import device from '../../../../utils/device';
import ColumnWrapper from '../Elements/ColumnWrapper';
import SavedAddress from '../Elements/SavedAddress';
import CollectionAddress from './CollectionAddress';
import ContinueRow from '../Elements/ContinueRow';

const addressErrorKeys = [
  'addressOne',
  'city',
  'country',
  'zip',
];

const CollectionDetails = ({
  defaultError,
  country,
  theme,
  setDone,
  deliveryType,
  address: defAddress,
  handleContinue,
  resetServices,
}: any) => {
  const dispatch = useDispatch();

  const details = useSelector((state: any) => state.order.collectionDetails);
  const address = useSelector((state: any) => state.order.collectionAddress);

  const [addressData, setAddressData] = useState<any>({
    company: '',
    addressOne: '',
    addressTwo: '',
    addressThree: '',
    city: '',
    country: 'GB',
    zip: '',
  });

  const [addressErrors, setAddressErrors] = useState<any>({
    addressOne: false,
    city: false,
    country: false,
    zip: false,
  });

  const [collectionDetails, setCollectionDetails] = useState<any>({
    name: '',
    email: '',
    phone: '',
    customsData: {
      VATStatus: 'personal_individual',
      VATNumber: '',
      EORINumber: '',
    },
    reference: '',
  });

  const [collectionDetailsErrors, setCollectionDetailsErrors] = useState<any>({
    name: false,
    email: false,
    phone: false,
    customsData: {
      VATStatus: false,
      VATNumber: false,
      EORINumber: false,
    },
  });

  const [addToAddressBook, setAddToAddressBook] = useState(false);

  const [detailsComplete, setDetailsComplete] = useState(false);
  const [addressComplete, setAddressComplete] = useState(false);

  const [editAddress, setEditAddress] = useState(false);

  useEffect(() => {
    setCollectionDetails({
      ...collectionDetails,
      name: `${details.firstName}${details.lastName ? ` ${details.lastName}` : ''}`,
      email: details.email,
      phone: details.phone,
      customsData: {
        ...collectionDetails.customsData,
        ...details.customsData,
      },
      reference: details.reference || '',
    });
  }, [details]);

  useEffect(() => {
    let done = true;

    for (const [key] of Object.entries(collectionDetailsErrors)) {
      if (key !== 'customsData') {
        if (collectionDetails[key] === '') {
          done = false;
        }
      } else {
        if (collectionDetails.customsData.VATStatus === '') {
          done = false;
        }

        if (collectionDetails.customsData.VATStatus === 'company_vat') {
          if (
            collectionDetails.customsData.VATNumber === '' ||
            collectionDetails.customsData.EORINumber === ''
          ) {
            done = false;
          }
        }
      }
    }

    if (defaultError) {
      done = false;
    }

    setDetailsComplete(done);
  }, [collectionDetails, defaultError]);

  useEffect(() => {
    if (deliveryType === 'outbound') {
      setAddressComplete(true);
    }
  }, [deliveryType]);

  useEffect(() => {
    if (!detailsComplete || !addressComplete) {
      setDone(false);
    }
  }, [detailsComplete, addressComplete]);

  useEffect(() => {
    resetServices();
  }, [addressData, collectionDetails]);

  const validateAddress = () => {
    const errors = { ...addressErrors };
    let error = false;
    let addressValues: any;

    if (deliveryType === 'outbound' && !editAddress) {
      addressValues = defAddress;
    } else {
      addressValues = addressData;
    }

    addressErrorKeys.forEach((key: any) => {
      if (addressValues[key] === '') {
        errors[key] = 'Required';
        error = true;
      }
    });

    setAddressErrors({
      ...addressErrors,
      ...errors,
    });

    return error;
  };

  const validateDetails = () => {
    const errors = { ...collectionDetailsErrors };
    let error = false;

    for (const [key] of Object.entries(collectionDetailsErrors)) {
      if (key !== 'customsData') {
        if (collectionDetails[key] === '') {
          errors[key] = 'Required';
          error = true;
        }

        if (key === 'email' &&
          !collectionDetails[key]
            .match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/)
        ) {
          errors[key] = 'Enter valid email';
          error = true;
        }

        if (key === 'phone' &&
          !collectionDetails[key]
            .match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
        ) {
          errors[key] = 'Enter valid number';
          error = true;
        }
      } else {
        if (collectionDetails.customsData.VATStatus === '') {
          error = true;
          errors.customsData.VATStatus = 'Required';
        }

        if (collectionDetails.customsData.VATStatus === 'company_vat') {
          if (collectionDetails.customsData.VATNumber === '') {
            error = true;
            errors.customsData.VATNumber = 'Required';
          }

          if (collectionDetails.customsData.EORINumber === '') {
            error = true;
            errors.customsData.EORINumber = 'Required';
          } else if (collectionDetails.customsData.EORINumber.length > 17) {
            error = true;
            errors.customsData.EORINumber = 'EORI numbers should be 17 characters or less';
          }
        }
      }
    }

    setCollectionDetailsErrors({
      ...collectionDetailsErrors,
      ...errors,
    });

    return error;
  };

  const handleSubmit = () => {
    const error = validateDetails() || validateAddress();

    if (defaultError || error) {
      return;
    }

    let addressValues: any;

    if (deliveryType === 'outbound' && !editAddress) {
      addressValues = defAddress;
    } else {
      addressValues = addressData;
    }

    dispatch(setCollectionAddress(addressValues));
    dispatch(addressBookToggle(addToAddressBook));
    dispatch(setCollectionInfo(collectionDetails));

    handleContinue();
  };

  const handleChange = (value: any, key: any) => {
    if (collectionDetailsErrors[key]) {
      setCollectionDetailsErrors({
        ...collectionDetailsErrors,
        [key]: false,
      });
    }

    if (key === 'reference') {
      value = value.slice(0, 500);
    }

    setCollectionDetails({
      ...collectionDetails,
      [key]: value,
    });
  };

  const handleCustomsChange = (value: any, key: any) => {
    setCollectionDetailsErrors({
      ...collectionDetailsErrors,
      customsData: {
        ...collectionDetailsErrors.customsData,
        [key]: false,
      },
    });

    setCollectionDetails({
      ...collectionDetails,
      customsData: {
        ...collectionDetails.customsData,
        [key]: value,
      }
    });
  };

  const handleDone = (value: boolean) => {
    setAddressComplete(value);
  };

  return (
    <Container>
      <Row>
        <ColumnStyled sizeL={6} sizeM={6} sizeS={3} sizeXS={4}>
          {
            deliveryType === 'outbound' && !editAddress ? (
              <ColumnWrapper heading="Default address">
                <SavedAddress
                  address={address}
                  edit={setEditAddress}
                />
              </ColumnWrapper>
            ) : (
              <CollectionAddress
                setDone={(value: any) => handleDone(value)}
                defAddress={defAddress}
                deliveryType={deliveryType}
                addressData={addressData}
                setAddressData={setAddressData}
                addressErrors={addressErrors}
                setAddressErrors={setAddressErrors}
                addToAddressBook={addToAddressBook}
                setAddToAddressBook={setAddToAddressBook}
              />
            )
          }
        </ColumnStyled>
        <ColumnStyled sizeL={6} sizeM={6} sizeS={3} sizeXS={4}>
          <ColumnWrapper heading="Contact Info">
            <InputContainer>
              <Label>First name and last name</Label>
              <Input
                placeholder="Name"
                value={collectionDetails.name}
                onChange={(e: any) => handleChange(e.target.value, 'name')}
                theme={theme}
                type="text"
                error={collectionDetailsErrors.name}
              />
              {
                collectionDetailsErrors.name && (
                  <ErrorText>{ collectionDetailsErrors.name }</ErrorText>
                )
              }
            </InputContainer>
            <InputContainer>
              <Label>Email address</Label>
              <Input
                placeholder="Email address"
                value={collectionDetails.email}
                onChange={(e: any) => handleChange(e.target.value, 'email')}
                theme={theme}
                type="text"
                error={collectionDetailsErrors.email}
              />
              {
                collectionDetailsErrors.email && (
                  <ErrorText>{ collectionDetailsErrors.email }</ErrorText>
                )
              }
            </InputContainer>
            <InputContainer>
              <Label>Mobile number required to receive free SMS notifications</Label>
              <MobileNumber
                country={country}
                value={collectionDetails.phone}
                onChange={(selected: any) => handleChange(selected, 'phone')}
                error={collectionDetailsErrors.phone}
              />
            </InputContainer>
            <InputContainer>
              <Label>VAT Status</Label>
              <SelectInput
                error={collectionDetailsErrors.customsData.VATStatus}
                resetError={() => {}}
                value={collectionDetails.customsData.VATStatus}
                onChange={(selected: any) => handleCustomsChange(selected, 'VATStatus')}
                data={VATStatusOptions}
                height="48px"
              />
              {
                collectionDetailsErrors.customsData.VATStatus && (
                  <ErrorText>{ collectionDetailsErrors.customsData.VATStatus }</ErrorText>
                )
              }
            </InputContainer>
            {
              collectionDetails.customsData.VATStatus === 'company_vat' && (
                <>
                  <InputContainer>
                    <Label>VAT Number</Label>
                    <Input
                      placeholder="VAT Number"
                      value={collectionDetails.customsData.VATNumber}
                      onChange={(e: any) => handleCustomsChange(e.target.value, 'VATNumber')}
                      theme={theme}
                      type="text"
                      error={collectionDetailsErrors.customsData.VATNumber}
                    />
                    {
                      collectionDetailsErrors.customsData.VATNumber && (
                        <ErrorText>{ collectionDetailsErrors.customsData.VATNumber }</ErrorText>
                      )
                    }
                  </InputContainer>
                  <InputContainer>
                    <Label>EORI Number</Label>
                    <Input
                      placeholder="EORI Number"
                      value={collectionDetails.customsData.EORINumber}
                      onChange={(e: any) => handleCustomsChange(e.target.value, 'EORINumber')}
                      theme={theme}
                      type="text"
                      error={collectionDetailsErrors.customsData.EORINumber}
                    />
                    {
                      collectionDetailsErrors.customsData.EORINumber && (
                        <ErrorText>{ collectionDetailsErrors.customsData.EORINumber }</ErrorText>
                      )
                    }
                  </InputContainer>
                </>
              )
            }
            <InputContainer>
              <Label>Collection reference (optional)</Label>
              <Input
                placeholder="Collection reference if required"
                value={collectionDetails.reference}
                onChange={(e: any) => handleChange(e.target.value, 'reference')}
                theme={theme}
                type="text"
                error={collectionDetailsErrors.reference}
              />
            </InputContainer>
          </ColumnWrapper>
        </ColumnStyled>
      </Row>
      <ContinueRow
        handleClick={handleSubmit}
        disable={!detailsComplete || !addressComplete}
      />
    </Container>
  );
};

const SavedDetails = styled.div`
  color: ${({ theme }: any) => theme.colors.LightGrey};
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
`;

const SavedSecondLine = styled.div``;

const SecondLineData = styled.span`
  display: inline-block;
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
`;

const ColumnStyled = styled(Column)`
  padding: 0;
  margin-bottom: 16px;

  @media ${device.laptop} {
    margin-bottom: 0;

    &:first-of-type {
      padding-right: 12px;
    }

    &:last-of-type {
      padding-left: 12px;
    }
  }
`;

export const InputContainer = styled.div.attrs(({ half = false }: any) => ({
  half,
}))`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: ${({ half }: any) => half ? '50%' : '100%'};

  &:last-child {
    margin-bottom: 0;
  }

  position: relative;
`;

export const Label = styled.label`
  display: flex;
  color: ${({ theme }: any) => theme.colors.MidGrey};
  font-size: 14px;
  line-height: 120%;
  font-weight: 500;
  margin-bottom: 6px;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

export default CollectionDetails;
