import React  from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Container, PSmall } from '../../../../../assets/elements';
import { SelectInput } from '../../../Inputs';
import { getCountryFromCode } from '../../../../mocks/quoteData';
import device from '../../../../utils/device';

const options = [
  {
    label: 'Anywhere',
    value: 'anywhere',
  },
  {
    label: 'Outbound',
    value: 'outbound',
  },
  {
    label: 'Inbound',
    value: 'inbound',
  },
];

const DeliveryType = ({ selected, setSelected }: any) => {
  const {
    country: collectionCountry,
    zip: collectionZip,
  } = useSelector((state: any) => state.order.collectionAddress);

  const {
    country: deliveryCountry,
    zip: deliveryZip,
  } = useSelector((state: any) => state.order.deliveryAddress);
  return (
    <Wrapper>
      <ContainerStyled>
        <FirstWrap>
          <Text>Delivery is</Text>
          <SelectInput
            error={false}
            resetError={() => {}}
            value={selected}
            onChange={(value: any) => setSelected(value)}
            data={options}
            width="100px"
            radius="8px"
          />
        </FirstWrap>
        <Text>
          From{ ' ' }
          {collectionCountry ?
            `${getCountryFromCode(collectionCountry)}${collectionZip ? `(${collectionZip})` : ''}` : '...'} { ' ' }
          to{ ' ' }
          {deliveryCountry ?
            `${getCountryFromCode(deliveryCountry)}${deliveryZip ? `(${deliveryZip})` : ''}` : '...'}
        </Text>
      </ContainerStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }: any) => theme.colors.Primary};
  padding: 16px 0;
`;

const ContainerStyled = styled(Container)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @media ${device.laptopL} {
    flex-direction: row;
    align-items: center;
  }
`;

const FirstWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media ${device.laptopL} {
    margin-bottom: 0;
  }
`;

const Text = styled(PSmall)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }: any) => theme.colors.White};

  &:first-of-type {
    margin-right: 16px;
  }

  &:last-of-type {
    margin-left: 16px;
  }
`;

export default DeliveryType;
