import React from 'react';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { Container } from '../../../../../../../../assets/elements';
import TypeCollection from './TypeCollection';
import TypeDrop from './TypeDrop';
import device from '../../../../../../../utils/device';

const CollectionAddress = (props: any) => {
  const { type } = useSelector<any, any>((state) => state.order.item);

  return (
    <Wrapper>
      {!type || type === 'collection' ? (
        <TypeCollection {...props} />
      ) : (
        <TypeDrop {...props} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  background: #ffffff;
  border-radius: 8px;
  && {
    padding: 15px;
    @media ${device.tablet} {
      padding: 30px;
    }
  }
  height: auto;
`;

export default CollectionAddress;
