import React from 'react';
import styled from 'styled-components';
import { A, PSmall } from '../../../../../assets/elements';
import { getCountryFromCode } from '../../../../mocks/quoteData';

const SavedAddress = ({
  address,
  edit,
}: any) => {
  return (
    <Wrapper>
      {
        Object.keys(address).map((add: any, i: number) => {
          return address[add] && (add === 'country' ?
            <AddressLine key={i}>{ getCountryFromCode(address[add]) }</AddressLine>  :
            <AddressLine key={i}>{address[add]}</AddressLine>);
        })
      }
      <Link onClick={() => edit(true)}>Edit address</Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
`;

const AddressLine = styled(PSmall)`
  margin-bottom: 8px;
`;

const Link = styled(A)`
  margin-top: 18px;
`;

export default SavedAddress;
