import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Input, Label, Caption } from '../../../../../../../../assets/elements';
import { SelectInput } from '../../../../../../Inputs';
import useDebounce from '../../../../../../../utils/useDebounce';
import { PostcoderKey } from '../../../../../../../utils/env';
import Required from '../../../../../../../../assets/elements/Required';

const DropDownPostCode = ({
  localState,
  setLocalState,
  errors,
  setErrors,
  setManually,
}: any) => {
  const [data, setData] = useState([]);

  const debouncedSearchTerm = useDebounce(localState.zip, 500);

  const handleChange = (e: any, key: any) => {
    setLocalState({
      ...localState,
      [key]: e.target.value,
    });

    setErrors({
      ...errors,
      [key]: false,
    });
  };
  const handleSelectChange = (selected: any) => {
    setLocalState({
      ...localState,
      ...selected,
    });

    setErrors({
      ...errors,
      company: selected.company !== '' && (selected.company.length < 3 || selected.company.length > 34) ? 'This must be between 3 and 34 characters' : false,
      addressOne: selected.addressOne.length > 30 ? 'This must be less than 30 characters' : false,
      addressTwo: (selected.addressTwo !== '' && selected.addressTwo.length > 30) ? 'This must be less than 30 characters' : false,
      city: (selected.city !== '' && selected.city.length > 30) ? 'This must be less than 30 characters' : false,
      country: false,
      zip: false,
    });

    setManually(true);
  };

  const fetchPostCoder = () => {
    axios
      .get(
        `https://ws.postcoder.com/pcw/${PostcoderKey}/address/${localState.country}/${localState.zip}?format=json&lines=3`
      )
      .then((response: any) => {
        setData(
          response.data.map((item: any) => {
            return {
              code: {
                addressOne: item?.addressline1 || item?.street || '',
                addressTwo: item?.addressline2 || '',
                city: item?.posttown || '',
                company: item?.organisation || '',
              },
              name: item.summaryline,
            };
          })
        );
      })
      .catch((error) => {
        return [];
      });
  };

  React.useEffect(() => {
    if (debouncedSearchTerm) {
      fetchPostCoder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <>
      <LabelStyled>Postcode</LabelStyled>
      <InputPost
        placeholder="Postcode"
        value={localState.zip}
        onChange={(e: any) => handleChange(e, 'zip')}
        theme={undefined}
        type="text"
        error={errors.zip}
        autoComplete="off"
        disabled={true}
      />
      <ErrorText>{errors.zip}</ErrorText>
      <LabelStyled><Required />Return address</LabelStyled>
      <SelectWrapper>
        <SelectInput
          title="Please select collection address"
          data={data}
          error={
            errors.addressOne || errors.city || errors.country || errors.zip
          }
          resetError={() => []}
          defaultValue="GB"
          onChange={handleSelectChange}
          noOptionsMessage="No options found. Please enter manually."
        />
      </SelectWrapper>
    </>
  );
};
const InputPost = styled(Input)`
  && {
    max-width: 200px;
  }
`;
const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: normal;
  margin-top: 30px;
  color: #777e7e;
  width: 100%;
`;
const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: block;
  margin-top: 4px;
`;
const SelectWrapper = styled.div`
  height: 50px;
`;

export default DropDownPostCode;
