import React from 'react';
import styled from 'styled-components';
import { P } from '../../../assets/elements';

const NotFound = ({ children }: any) => {
  return (
    <Wrapper>
      <PStyled>{children}</PStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-contents: center;
  justify-content: center;
  width: 100%;
  padding: 24px;
  background: ${({ theme }: any) => theme.colors.White};
  margin: 16px 0;
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};
`;

const PStyled = styled(P)`
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

export default NotFound;
