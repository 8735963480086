import axios, { axiosFile } from '../API/axios';

export const createAccount = (data: any) => {
  return axios.post('/accounts/password/register', data);
};

export const getUserData = (config: any) => {
  return axios.get('/accounts/user', config);
};

export const logInUser = (data: any) => {
  return axios.post('/accounts/password/authenticate', data);
};

export const logoutUser = () => {
  return axios.post('/accounts/logout');
};

export const resetPassword = (email: any) => {
  return axios.post('accounts/password/sendResetPasswordEmail', email);
};

export const setNewPassword = (data: any) => {
  return axios.post('accounts/password/resetPassword', data);
};

export const refreshTokens = (data: any) => {
  return axios.post('accounts/refreshTokens', data);
};

export const verifyEmail = (data: any) => {
  return axios.post('accounts/password/verifyEmail', data);
};

export const getOrder = (OrderGroupId: any) => {
  return axios.get(`/order/group/${OrderGroupId}`);
};

export const getUserInfo = () => {
  return axios.post('accounts/user');
};

export const updateUserName = (data: any) => {
  return axios.post('accounts/user/changeName', data);
};

export const updateUserEmail = (data: any) => {
  return axios.post('accounts/user/changeEmail', data);
};

export const updateUserPassword = (data: any) => {
  return axios.post('accounts/password/changePassword', data);
};

export const updateUserNewsletter = (data: any) => {
  return axios.post('accounts/user/changeNewsletter', data);
};

export const getOrderHistory = (items: any, start: any) => {
  return axios.get(`order/list?take=${items}&skip=${start}`);
};

export const resendEmailConfirmation = () => {
  return axios.post('/accounts/user/resendActivationEmail');
};

export const getInvoiceList = () => {
  return axios.get('/order/invoices');
};

export const getInvoiceDownloads = (invoiceNumber: any, type: any) => {
  return axios.get(`/order/invoices/${invoiceNumber}/${type}`);
};

export const getAccountCredit = () => {
  return axios.get('/accounts/user/balance');
};

export const topUpAccount = (data: any) => {
  return axios.post('/accounts/user/balance', data);
};

export const addNewAddress = (data: any) => {
  return axios.post('/accounts/user/address', data);
};

export const getAddresses = () => {
  return axios.get('/accounts/user/address');
};

export const updateAddress = (data: any, id: any) => {
  return axios.post(`/accounts/user/address/update/${id}`, data);
};

export const makeAddressDefault = (data: any, id: any) => {
  return axios.post(`/accounts/user/address/update/${id}`, data);
};

export const getDefaultAddress = () => {
  return axios.get('/accounts/user/address?isDefault=true');
};

export const deleteAddress = (id: any) => {
  return axios.post(`/accounts/user/address/remove/${id}`);
};

export const validateMultiBookCSV = (data: any) => {
  return axiosFile.post('accounts/user/multibook/validate', data);
};

export const uploadMultiBookCSV = (data: any) => {
  return axiosFile.post('accounts/user/multibook/import', data);
};

export const getAccountDiscount = () => {
  return axios.get('/accounts/user/discount/');
};
