import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import {
  Container,
  Row,
  Column,
  A,
  PSmall,
  P,
} from '../../../../../assets/elements';
import Printer from '../../../../../assets/icons/Printer.svg';
import Info from '../../../../../assets/icons/Info.svg';
import VanIcon from '../../../../../assets/icons/Transport-van.svg';
import ShipIcon from '../../../../../assets/icons/Transport-ship.svg';
import device from '../../../../utils/device';
import { dateFormat, UK_VAT } from '../../../../utils/variables';
import Modal from '../../../../../assets/Combos/Modal';
import NICheck from '../../../../utils/NICheck';
import Collapsed from './Collapsed';
import getServiceInformationContent from '../../../Quotes/components/PalletQuotes/ServiceInformation'
import containerTypes from '../../../../utils/containerTypes.json';

const Service = () => {
  const [nearestDropoff, setNearestDropoff] = useState('');
  const [moreInfoModal, setMoreInfoModal] = useState(false);
  const [moreInfoValue, setMoreInfoValue] = useState<any>({});
  const [moreInfoText, setMoreInfoText] = useState<any>('');

  const [serviceInfoValue, setServiceInfoValue] = useState<any>({});
  const [serviceInfoText, setServiceInfoText] = useState<any>();
  const [serviceInfoModal, setServiceInfoModal] = useState(false);
  const [serviceInfoTitle, setServiceInfoTitle] = useState('')

  const [discountValue, setDiscountValue] = useState<any>(0);
  const [totalExVAT, setTotalExVAT] = useState<any>(0);
  const [VAT, setVAT] = useState<any>(0);
  const [open, setOpen] = useState(false);
  const wrapper = useRef(null);

  const [reachedTop, setReachedTop] = useState(false);
  const [reachedBottom, setReachedBottom] = useState(false);
  const [elOffset, setElOffset] = useState(0);
  const [dimensions, setDimensions] = useState<any>({
    height: 0,
    width: 0,
    right: 0,
  });

  const handleScroll = (e: any, top: any = 0, height: any = 0) => {
    if (wrapper === null) {
      return;
    }

    const topReached = document.documentElement.scrollTop >= top;

    if (topReached) {
      const step = document.querySelector('#service-column')?.getBoundingClientRect();
      setReachedBottom(step && ((step?.top + step.height - 55) <= height) || false);
    }

    setReachedTop(topReached);
  };

  const handleResize = () => {
    if (wrapper === null) {
      return;
    }

    const os = offset(wrapper.current);
    setElOffset(os.top);

    setDimensions({
      height: dimensions.height,
      right: os.right,
      width: os.width,
    });
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', (e) => handleScroll(e, os.top, os.height));
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!wrapper) {
      return;
    }

    // @ts-ignore
    const os = offset(wrapper.current);
    setElOffset(os.top);

    setDimensions({
      height: os.height,
      right: os.right,
      width: os.width,
    });
    window.removeEventListener('scroll', handleScroll);
    window.removeEventListener('resize', handleResize);

    window.addEventListener('scroll', (e) => handleScroll(e, os.top, os.height));
    window.addEventListener('resize', handleResize);
  }, [wrapper, elOffset]);

  useEffect(() => {
    const element = document.getElementById('service-row');
    if (!element) return;

    if (reachedBottom) {
      element.style.position = 'relative';
    } else {
      element.style.position = 'static';
    }
  }, [reachedBottom]);

  const offset = (el: any) => {
    if (!el) {
      return {
        top: 0,
        width: 0,
        right: 0,
      };
    }
    const rect = el.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    return {
      top: rect.top + scrollTop,
      width: rect.width,
      height: document.querySelector('#wrapper')?.getBoundingClientRect()?.height || 0,
      right: window.innerWidth - (rect.left + rect.width),
    };
  };

  const {
    name,
    logo,
    status,
    deliveryTime,
    deliveryDate,
    collectionDate,
    priceIncVat,
    price,
    type,
    printer,
    dropoffPoints,
    editDetails,
    moreInfo,
    expiryDate = new Date(),
    vat,
    pricesBeforeDiscount,
    serviceInformation,
    shippingDates,
  }: any = useSelector<any>((state) => state.order.item);

  const palletAndWrap = useSelector((state: any) => state.order.palletAndWrap);
  const serviceType = useSelector((state: any) => state.order.serviceType);
  const loadType =  useSelector((state: any) => state.order.packageDetails.loadType);

  const isContainer = containerTypes.includes(loadType);

  const discount = useSelector((state: any) => state.main.discount);

  const insurance = useSelector<any, any>(
    (state: any) => state.order.insurance
  );

  const {
    collectionLocation: { postcode = '' },
  } = useSelector((state: any) => state.main.parcel);

  const {
    collectionLocation,
    deliveryLocation,
  } = useSelector((state: any) => state.main.pallet);

  const handleDropClick = (click: any) => {
    if (!click) return;

    editDetails();
  };

  const deliveryTimeFormat = (days: number) =>
    `${days} ${days === 0 || days > 2 ? 'days' : 'day'}`;

  const calcDeliveryTime = () => {
    if (deliveryTime === 0) {
      return 'Same day';
    }

    if (deliveryTime === false || deliveryTime === null) {
      return 'Provided after booking';
    }

    return `${deliveryTime} day${(deliveryTime === 0 || deliveryTime > 1) ? 's' : ''}`;
  };

  useEffect(() => {
    const tempDis = ((Number(pricesBeforeDiscount?.price || 0) + (palletAndWrap ? Number(25.00) : 0)) *
      (Number(discount.value) / 100));

    setDiscountValue(tempDis.toFixed(2));

    setTotalExVAT((
      (Number(pricesBeforeDiscount?.price || price) +
        (palletAndWrap ? 25 : 0) +
        Number(insurance?.price || 0)) -
      tempDis
    ).toFixed(2));

    setVAT((!vat.customs || checkForNI()) ?
      (
        (Number(priceIncVat || 0) - Number(price || 0)) +
        ((UK_VAT / 100) * (insurance?.price || 0)) +
        ((palletAndWrap ? 25 : 0) * (UK_VAT / 100))
      ).toFixed(2) :
      0);
  }, [pricesBeforeDiscount, discount, insurance]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setReachedTop(false);
    if (dropoffPoints && dropoffPoints.length > 0) {
      const { distance = {} } = dropoffPoints[0];
      setNearestDropoff(`${distance?.amount}${distance?.unit}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMoreInfoClick = () => {
    ReactTooltip.hide();
    if (moreInfo?.intro) {
      setMoreInfoValue(moreInfo);
      setMoreInfoModal(true);
    }
  };

  const handleServiceInfoClick = () => {
    ReactTooltip.hide();
    if (serviceInformation?.title) {
      setServiceInfoTitle(serviceInformation.title)
      setServiceInfoValue(serviceInformation);
      setServiceInfoModal(true);
    }
  }

  useEffect(() => {
    if (moreInfoValue.intro) {
      setMoreInfoText(
        <>
          <PSmallStyled>{moreInfoValue.intro}</PSmallStyled>
          <ul>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                ️Delivery: {moreInfoValue.deliveryDays}
              </PSmall>
            </Li>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                ️Delivery window: {moreInfoValue.deliveryWindow}
              </PSmall>
            </Li>
            {moreInfoValue.tracking && (
              <Li>
                <PSmall>
                  <span role="img" aria-label="Dot">
                    ◾
                  </span>
                  ️Detailed tracking notifications
                </PSmall>
              </Li>
            )}
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                ️{moreInfoValue.redeliveryAttempts + 1} delivery attempts
              </PSmall>
            </Li>
            {moreInfoValue.podOnRequest && (
              <Li>
                <PSmall>
                  <span role="img" aria-label="Dot">
                    ◾
                  </span>
                  ️Proof of delivery upon request
                </PSmall>
              </Li>
            )}
          </ul>
        </>
      );
    }
  }, [moreInfoValue]);

  useEffect(() => {
    if (serviceInfoValue && serviceInfoValue.title) {
      let index = 0
      setServiceInfoText(getServiceInformationContent(serviceInfoValue));
    }
  }, [serviceInfoValue]);

  const handleMoreInfoClose = () => {
    setMoreInfoModal(false);
    setMoreInfoValue({});
  };

  const handleServiceInfoClose = () => {
    setServiceInfoModal(false);
    setServiceInfoValue({});
    setServiceInfoTitle('')
  };

  const checkForNI = () => (serviceType === 'pallet' && NICheck(collectionLocation, deliveryLocation));

  return (
    <ColumnService
      ref={wrapper}
      top={reachedTop}
      bottom={reachedBottom}
      width={dimensions.width}
      right={dimensions.right}
      sizeL={5}
      sizeM={4}
    >
      <Wrapper id="wrapper">
        <Collapsed
          name={name}
          logo={logo}
          totalExVAT={totalExVAT}
          VAT={VAT}
          customs={vat?.customs || false}
          checkForNI={checkForNI}
          open={open}
          setOpen={setOpen}
        />
        <DesktopWrapper open={open}>
          <Brand>
            <BrandImg src={logo} alt={`${name} logo`} />
          </Brand>
          <RowStyled>
            <Title>{name}</Title>
            <Icons>
              {
                serviceType === 'freight' && (
                  <PrinterIcon alt="Road freight" src={VanIcon} />
                )
              }
              {
                serviceType === 'seaFreight' && (
                  <PrinterIcon alt="Sea freight" src={ShipIcon} />
                )
              }

              <PrinterIcon
                data-tip
                data-for="id-icon"
                alt="info icon"
                src={Info}
                onClick={serviceInformation ? handleServiceInfoClick : handleMoreInfoClick}
              />
              <ReactTooltip id="id-icon" effect="solid">
                More info
              </ReactTooltip>

              {printer && (
                <>
                  <PrinterIcon
                    data-tip
                    data-for="printer-icon"
                    alt="printer icon"
                    src={Printer}
                  />
                  <ReactTooltip id="printer-icon" effect="solid">
                    Printer required
                  </ReactTooltip>
                </>
              )}
            </Icons>
          </RowStyled>
          {status && <ParagraphInfo>{status}</ParagraphInfo>}
          <RowStyled>
            <ColumnStyled sizeL={6} sizeM={6} sizeS={6} sizeXS={4}>
              {type ? (
                <ParcelTypeContainer type={type}>
                  <ParcelTypeHead type={type}>
                    {type === 'collection' ? 'Collection' : 'Drop off'}
                  </ParcelTypeHead>
                  <ParcelTypeData>
                    {type === 'collection' && (
                      <Moment format="ddd D MMM">{collectionDate}</Moment>
                    )}
                    {type === 'dropoff' && (
                      <DropOffMessage
                        onClick={() => handleDropClick(postcode === '')}
                      >
                        {postcode !== ''
                          ? `Nearest drop off ${nearestDropoff}`
                          : 'Add a postcode for your nearest drop off service'}
                      </DropOffMessage>
                    )}
                  </ParcelTypeData>
                </ParcelTypeContainer>
              ) : (
                <DeliveryTimeContainer>
                  <DeliveryTimeHead>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      serviceType === 'freight' || serviceType === 'seaFreight' ?
                        isContainer ? 'Earliest Collection Date' 
                          :'Estimated Transit Time' 
                        :'Collection'
                    }
                  </DeliveryTimeHead>
                  <DeliveryTimeData>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      serviceType === 'freight' || serviceType === 'seaFreight' 
                        ? isContainer 
                          ? moment(shippingDates[0].cd).format(dateFormat)
                          : calcDeliveryTime()
                        :moment(collectionDate).format(dateFormat)
                    }
                  </DeliveryTimeData>
                </DeliveryTimeContainer>
              )}
            </ColumnStyled>
            <ColumnStyled sizeL={6} sizeM={6} sizeS={6} sizeXS={4}>
              <DeliveryTimeContainer>
                <DeliveryTimeHead>
                  {
                    type && 'Delivery time'
                  }
                  {
                    !type && (
                      // eslint-disable-next-line no-nested-ternary
                      serviceType === 'freight' || serviceType === 'seaFreight'
                        ? isContainer
                          ? 'Earliest Delivery Date'
                          :'Quote Expires'
                        : 'Delivery date'
                    )
                  }
                </DeliveryTimeHead>
                <DeliveryTimeData>
                  { type && deliveryTimeFormat(deliveryTime) }
                  {
                    !type && (
                      // eslint-disable-next-line no-nested-ternary
                      serviceType === 'freight' || serviceType === 'seaFreight'
                        ? isContainer? moment(shippingDates[0].dd[0]).format(dateFormat)
                          : moment(expiryDate).format(dateFormat)
                        :moment(deliveryDate).format(dateFormat)
                    )
                  }
                </DeliveryTimeData>
              </DeliveryTimeContainer>
            </ColumnStyled>
          </RowStyled>
          <PriceContainer>
            <PricesWrapper>
              <PriceRow>
                <PriceLabel>Base Rate</PriceLabel>
                <PriceValue>
                  £{ pricesBeforeDiscount ? pricesBeforeDiscount.price : price }
                </PriceValue>
              </PriceRow>
              {
                palletAndWrap && (
                  <PriceRow>
                    <PriceLabel>
                      Pallet & Wrap Service
                    </PriceLabel>
                    <PriceValue>
                      £25.00
                    </PriceValue>
                  </PriceRow>
                )
              }
              {
                discount.value > 0 && (
                  <PriceRow>
                    <PriceLabel>{discount.value}% Discount</PriceLabel>
                    <PriceValue>
                      - £{ discountValue }
                    </PriceValue>
                  </PriceRow>
                )
              }
            </PricesWrapper>
            {
              (insurance?.price !== undefined && insurance.price >= 0) && (
                <PricesWrapper>
                  <PriceRow>
                    <PriceLabel>Insurance</PriceLabel>
                    <PriceValue>
                      {
                        insurance.price === 0 ? 'FREE' :
                          `£${ Number(insurance.price).toFixed(2) }`
                      }
                    </PriceValue>
                  </PriceRow>
                </PricesWrapper>
              )
            }
            <PricesWrapper>
              <PriceRow>
                <PriceLabel>Total excl. VAT</PriceLabel>
                <PriceValue>
                  £{ totalExVAT }
                </PriceValue>
              </PriceRow>
              {
                VAT > 0 && (
                  <PriceRow>
                    <PriceLabel>VAT</PriceLabel>
                    <PriceValue>
                      £{ VAT }
                    </PriceValue>
                  </PriceRow>
                )
              }
            </PricesWrapper>
            <PricesWrapper>
              <PriceRow>
                <div>
                  <PriceTotal>Total</PriceTotal>
                  { (!vat.customs || checkForNI()) && (<PriceVat>(incl. VAT)</PriceVat>) }
                </div>
                <Price>
                  £{ (Number(totalExVAT) + Number(VAT)).toFixed(2) }
                </Price>
              </PriceRow>
            </PricesWrapper>
          </PriceContainer>

          <Modal
            title="More information"
            open={moreInfoModal}
            close={() => handleMoreInfoClose()}
            back={true}
            width={8}
          >
            {moreInfoText}
          </Modal>
          <Modal
            title={serviceInfoTitle}
            open={serviceInfoModal}
            close={() => handleServiceInfoClose()}
            back={true}
            width={8}
          >
            {serviceInfoText}
          </Modal>
        </DesktopWrapper>
        <Overlay />
      </Wrapper>
    </ColumnService>
  );
};

const Wrapper = styled(Container)`
  width: 100%;
  height: auto;
  padding: 0;

  @media ${device.laptop} {
    border-radius: 8px;
    background: #ffffff;
    border-top: 5px solid ${({ theme }: any) => theme.colors.Secondary};
    padding: 30px;
  }
`;

const DesktopWrapper = styled.div.attrs(({ open }: any) => ({
  open,
}))`
  display: ${({ open }: any) => open ? 'block' : 'none'};
  background: ${({ theme }: any) => theme.colors.White};
  padding: 16px 40px;
  box-shadow: 0px -1px 0px rgb(0 0 0 / 10%);

  @media ${device.laptop} {
    display: block;
    padding: unset;
    box-shadow: unset;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  background: ${({ theme }: any) => theme.colors.Black};
  opacity: 0.5;
  display: none;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
`;
const Brand = styled.div`
  display: none;

  @media ${device.laptop} {
    display: block;
  }
`;

const BrandImg = styled.img`
  max-height: 88px;
  width: auto;
  max-width: 100px;
`;
const RowStyled = styled(Row)`
  justify-content: space-between;
  margin-top: 40px;
  flex-wrap: nowrap;

  @media ${device.laptop} {
    flex-wrap: wrap;
  }

  @media ${device.laptopL} {
    flex-wrap: nowrap;
  }
`;
const Icons = styled.div`
  width: auto;
  display: flex;
`;
const PrinterIcon = styled.img`
  margin-left: 20px;
`;
const ParagraphInfo = styled.p`
  color: #777e7e;
  line-height: 150%;
  margin-top: 20px;
`;

const PricesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  border-top: 2px solid ${({ theme }: any) => theme.colors.WhiteGrey};
  padding: 20px 0;

  &:last-of-type {
    padding-bottom: unset;
  }
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;

  &:last-of-type {
    margin-bottom: unset;
  }
`;

const PriceTotal = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  display: inline-block;
`;

const PriceVat = styled.p`
  color: #777e7e;
  display: inline-block;
  margin-left: 10px;
`;
const Price = styled.p`
  font-size: 1.5rem;
`;

const PriceLabel = styled(PSmall)`
  color: ${({ theme }: any) => theme.colors.DarkGrey};
`;

const PriceValue = styled(P)`
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  font-weight: 500;
`;

const ParcelTypeContainer = styled.div.attrs(({ type }: any) => ({
  type,
}))`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: ${({ type, theme }: any) =>
    type === 'collection' ? theme.colors.CollectionBackground : theme.colors.DropoffBackground};
  align-items: center;
  border-radius: 4px;

  @media ${device.laptop} {
    margin-bottom: 16px;
  }

  @media ${device.laptopL} {
    margin-bottom: 0;
  }
`;

const ParcelTypeHead = styled.div.attrs(({ type }: any) => ({
  type,
}))`
  background: ${({ type, theme }: any) =>
    type === 'collection' ? theme.colors.CollectionHead : theme.colors.DropoffHead};
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  color: ${({ theme }: any) => theme.colors.White};
  padding: 7px 4px;
  justify-content: center;
`;

const ParcelTypeData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 19px;
  line-height: 150%;
  font-weight: 500;
  margin-top: 13px;
`;
const DropOffMessage = styled(A)`
  text-decoration: underline;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 135%;
  color: ${({ theme }: any) => theme.colors.DropoffHead};
`;
const DeliveryTimeContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  color: ${({ theme }: any) => theme.colors.Black};
  border-radius: 4px;
  height: 100%;

  @media ${device.desktop} {
    height: auto;
  }
`;

const DeliveryTimeHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  padding: 7px 4px;
  text-align: center;
`;

const DeliveryTimeData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 19px;
  line-height: 150%;
  font-weight: 500;
  margin-top: 13px;
`;
const ColumnStyled = styled(Column)`
  flex: 1;
  margin-bottom: 20px;

  @media ${device.laptop} {
    margin-bottom: 0;
  }

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
`;

const Li = styled.li`
  margin-bottom: 16px;
`;

const PSmallStyled = styled(PSmall)`
  margin-bottom: 16px;
`;

const PalletAndWrapTitle = styled(PSmall)`
  font-weight: 600;
  width: 100%;
`;

const PalletAndWrapPrice = styled(PSmall)`
  font-weight: 500;
  font-size: 19px;
  line-height: 150%;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const ColumnService = styled(Column).attrs(({ top, width, right, bottom }: any) => ({
  top,
  bottom,
  width,
  right,
}))`
  position: ${({ top }: any) => top ? 'fixed' : 'absolute'};
  top: 0;
  left: 0;
  right: 0;
  padding: unset !important;
  width: 100%;
  z-index: 10;

  @media ${device.tablet} {
    padding: 0 15px;
  }

  @media ${device.laptop} {
    position: ${({ top, bottom }: any) =>
    // eslint-disable-next-line no-nested-ternary
    bottom ? 'absolute' : (top ? 'fixed' : 'static')};

    width: ${({ top, width }: any) => top ? `${width}px` : 'inherit'};
    right: ${({ top, bottom, right }: any) =>
    // eslint-disable-next-line no-nested-ternary
    bottom ? 0 : (top ? `${right}px` : 0)};

    left: unset;
    bottom: ${({ bottom }: any) => bottom ? '40px' : 'unset'};
    top: ${({ bottom }: any) => bottom ? 'unset' : '0'};
  }
`;

export default Service;
