import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { goToNextQuickBookStep, setInsuranceDetails } from '../../../../../store/actions';
import { A, PSmall } from '../../../../../../assets/elements';
import { fetchInsuranceItemsByParcel } from '../../../../../utils/APICalls/Order';
import Loader from '../../../../Loader';
import NotFound from '../../../../NotFound';
import { SelectInput } from '../../../../Inputs';

const Insurance = ({
  setDone,
  totalValue,
  selectedInsurance,
  setSelectedInsurance,
}: any) => {
  const dispatch = useDispatch();

  const parcel = useSelector((state: any) => state.order.packageDetails.parcel);
  const insurance = useSelector((state: any) => state.order.insurance);

  const [insuranceData, setInsuranceData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchInsurance();
  }, []);

  useEffect(() => {
    fetchInsurance();
  }, [totalValue]);

  const fetchInsurance = () => {
    const cost = parcel.reduce(
      (acc: any, p: any) => Number(acc) + p.items.reduce((acc2: any, item: any) => {
        return Number(acc2) + (Number(item.value || 0) * Number(item?.quantity || 1));
      }, 0),
      0
    );

    setLoading(true);
    fetchInsuranceItemsByParcel(totalValue)
      .then((res: any) => {
        if (res.data.length > 0) {
          setInsuranceData(
            res.data.map((item: any) => ({
              label: `${
                item.price === 0 ? 'Free' : `£${item.price}`
              } - Provider covers up to £${item.level}`,
              value: {
                ...item,
                insuranceId: item.id,
              },
            }))
          );
        } else {
          setInsuranceData([]);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setSelectedInsurance(insurance);
  }, []);

  useEffect(() => {
    if (selectedInsurance.insuranceId) {
      setDone(true);
    } else {
      setDone(false);
    }

    dispatch(setInsuranceDetails(selectedInsurance));
  }, [selectedInsurance]);

  const handleSubmit = () => {
    const error = selectedInsurance?.id === undefined;

    if (error) {
      return;
    }

    dispatch(setInsuranceDetails(selectedInsurance));
  };

  const handleInsuranceChange = (value: any) => {
    setSelectedInsurance(value);
  };

  return (
    <>
      <Label>Insurance cover level</Label>

      {
        loading && (
          <Loader>Loading</Loader>
        )
      }
      {
        !loading && insuranceData.length === 0 ? (
          <NotFound>No results found</NotFound>
        ) : (
          <SelectInput
            error={false}
            resetError={() => {}}
            value={selectedInsurance}
            onChange={(selected: any) => handleInsuranceChange(selected)}
            data={insuranceData}
          />
        )
      }
    </>
  );
};

const SavedInsuranceLine = styled.div`
  color: ${({ theme }: any) => theme.colors.LightGrey};
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
`;

const PSmallStyled = styled(PSmall)`
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 24px;
`;

const AStyled = styled(A)`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  display: flex;
  margin-bottom: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const InsuranceOption = styled.div.attrs(({ selected }: any) => ({
  selected,
}))`
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  border-radius: 4px;
  padding: 12px 14px;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 8px;
  cursor: pointer;
  border: 2px solid ${({ theme, selected }: any) =>
    selected ? theme.colors.Secondary : theme.colors.WhiteGrey};

  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    border: 2px solid ${({ theme, selected }: any) =>
    selected ? theme.colors.Secondary : theme.colors.Pastel2};
  }
`;

export default Insurance;
