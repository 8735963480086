import axios from 'axios';
import { PostcoderKey } from '../env';

export const fetchPostCoder = ({
  country,
  zip
}: any,
setData: any,
{
  addressOne,
  addressTwo,
  addressThree,
}: any = {
  addressOne: 'addressLine1',
  addressTwo: 'addressLine2',
  addressThree: 'addressLine3'
},
setManual = (val: any) => {},
) => {
  axios
    .get(
      `https://ws.postcoder.com/pcw/${PostcoderKey}/address/${country}/${zip}?format=json&lines=3`
    )
    .then((response: any) => {
      setData(
        response.data.map((item: any) => {
          return {
            value: {
              [addressOne]: item?.addressline1 || item?.street || '',
              [addressTwo]: item?.addressline2 || '',
              [addressThree]: item?.addressline3 || '',
              city: item?.posttown || '',
              company: item?.organisation || '',
            },
            label: item.summaryline,
          };
        })
      );
    })
    .catch((error) => {
      setManual(true);
      setData([]);
    });
};