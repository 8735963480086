import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import QuoteForm from './components/QuoteForm';
import { Container, Row, Column, H1 } from '../../../assets/elements';
import ParcelImg from '../../../assets/images/ParcelImg.png';
import PalletImg from '../../../assets/images/PalletImg.png';
import FreightImg from '../../../assets/images/FreightImg.png';
import device from '../../utils/device';
import { homePage, HomePage } from '../../utils/homePage';

const H1Styled = styled(H1)`
  margin-bottom: 32px;
  text-align: center;
  color: ${({ theme }: any) => theme.colors.White};
  font-size: 38px;
  padding: 0 15px;

  @media ${device.laptop} {
    margin-bottom: 64px;
    font-size: ${({ theme }: any) => theme.box.H1_L_FS};
  }
`;

const ContainerStyled = styled(Container)`
  padding: 48px 0;

  @media ${device.laptop} {
    padding: 90px 0;
  }
`;

const QuoteWrapper = styled.div`
  width: 100%;
  background: url('${ParcelImg}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const LandingPageQuote: React.FC = () => {
  const { pathname } = useLocation();

  const background = () => {
    if ((homePage === HomePage.PALLET && pathname === '/') || pathname === '/pallet-delivery') {
      return `url(${PalletImg})`;
    }

    if ((homePage === HomePage.PARCEL && pathname === '/') || pathname === '/parcel-delivery') {
      return `url(${ParcelImg})`;
    }

    if ((homePage === HomePage.FREIGHT && pathname === '/') || pathname === '/freight-delivery') {
      return `url(${FreightImg})`;
    } 

    return `url(${ParcelImg})`;
  };

  return (
    <QuoteWrapper
      style={{
        backgroundImage: background(),
      }}
    >
      <ContainerStyled>
        <H1Styled>
          Haulable: Faster, more efficient haulage
        </H1Styled>
        <Row>
          <Column sizeL={10} sizeM={12} sizeS={4}>
            <QuoteForm />
          </Column>
        </Row>
      </ContainerStyled>
    </QuoteWrapper>
  );
};

export default LandingPageQuote;
