import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import LoadContainer from './LoadContainer';
import { PSmall } from '../../../../../../../../assets/elements';
import { SelectInput } from '../../../../../../Inputs';
import device from '../../../../../../../utils/device';

const LessThanFullLoad = ({
  onClick,
  selected,
  value,
  onChange,
}: any) => {
  const [values, setValues] = useState<any>([{ label: 1, value: 1 }]);
  const [selectValue, setSelectValue] = useState<any>(value);
  const select = useRef(null);

  useEffect(() => {
    const temp = [];

    for (let i = 25; i >= 1; i--) {
      temp.push({
        label: `${i} pallet${(i === 0 || i > 1) ? 's' : ''}`,
        value: i,
      });
    }

    setValues(temp);
  }, []);

  useEffect(() => {
    setSelectValue(value === 26 ? 0 : value);
  }, [value]);

  useEffect(() => {
    if (selected && select.current) {
      // @ts-ignore
      select.current.focus();

      if (selectValue > 0) {
        onChange('LTL', selectValue);
      }
    }
  }, [selected]);

  const handleChange = (val: any) => {
    setSelectValue(val);
    onChange('LTL', val);
  };

  return (
    <LoadContainer
      onClick={onClick}
      selected={selected}
    >
      <Wrapper>
        <Title>Less than a trailer load</Title>
        <SelectInput
          innerKey={value}
          innerRef={select}
          title="Quantity"
          data={values}
          error={false}
          resetError={() => {}}
          value={selectValue}
          onChange={handleChange}
          radius="4px"
        />
      </Wrapper>
    </LoadContainer>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.laptop} {
    flex-direction: row;
    align-items: center;
  }
`;

const Title = styled(PSmall)`
  font-weight: 600;
  display: block;
  width: 100%;
  margin-right: 8px;
`;

export default LessThanFullLoad;
