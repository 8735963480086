import React from 'react';
import styled from 'styled-components';
import {
  Container,
  H4,
  PSmall,
  A,
} from '../../../../../../../../assets/elements';
import device from '../../../../../../../utils/device';
import AddIcon from '../../../../../../../../assets/icons/AddIcon';

const AddAnother = ({ onAdd }: any) => {
  return (
    <Wrapper>
      <H4Styled>More to send?</H4Styled>
      <PSmallStyled>Click below to arrange another delivery</PSmallStyled>
      <AStyled onClick={onAdd}>
        <Icon><AddIcon width={24} height={24} /></Icon> Add another item
      </AStyled>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  background: #ffffff;
  border-radius: 8px;
  && {
    padding: 15px;
    @media ${device.tablet} {
      padding: 30px;
    }
  }
  height: auto;

  margin-bottom: 24px;
`;

const H4Styled = styled(H4)`
  margin-bottom: 16px;
`;

const PSmallStyled = styled(PSmall)`
  margin-bottom: 42px;
`;

const AStyled = styled(A)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export default AddAnother;
