import React, { useState } from 'react';
import styled from 'styled-components';
import { Input, PSmall } from '../../../../../../assets/elements';

const Search = ({ handleChange }: any) => {
  const [value, setValue] = useState('');

  const handleValueChange = (val: any) => {
    setValue(val);
    handleChange(val);
  };

  return (
    <Wrapper>
      <Label>Search</Label>
      <Input
        type="text"
        value={value}
        onChange={(e: any) => handleValueChange(e.target.value)}
        error={false}
        placeholder="Invoice Number"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 28px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Label = styled(PSmall)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }: any) => theme.colors.MidGrey};
  margin-bottom: 6px;
`;

export default Search;
