import React, { useEffect, useState } from 'react';
import { Wrapper, Title, Tile, TileWrapper } from './elements';

const ExportReasons = ({
  exportReasons,
  onChange,
  selected,
  isDisabled,
}: any) => {
  const [reasons, setReasons] = useState<any>([]);

  useEffect(() => {
    setReasons(exportReasons.sort((a: any, b: any) => a.label.length - b.label.length));
  }, [exportReasons]);

  const handleClick = (value: any) => {
    if (isDisabled) {
      return;
    }

    onChange(value);
  };

  return (
    <Wrapper>
      <Title>Export reason</Title>

      <TileWrapper>
        {
          reasons.slice(0, 6).map((reason: any, i: any) => (
            <Tile
              key={i}
              onClick={() => handleClick(reason.value)}
              selected={reason.value === selected}
              isDisabled={isDisabled}
            >
              { reason.label }
            </Tile>
          ))
        }
      </TileWrapper>
      <TileWrapper>
        {
          reasons.slice(6, 9).map((reason: any, i: any) => (
            <Tile
              key={i}
              onClick={() => handleClick(reason.value)}
              selected={reason.value === selected}
              isDisabled={isDisabled}
            >
              { reason.label }
            </Tile>
          ))
        }
      </TileWrapper>
    </Wrapper>
  );
};

export default ExportReasons;
