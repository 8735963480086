import React from 'react';
import styled, { withTheme } from 'styled-components';
import CollapseUp from '../../icons/CollapseUp.svg';
import CollapseDown from '../../icons/CollapseDown.svg';
import DoneIcon from '../../icons/Done';

export const Header = withTheme(({ heading, open = false, done = false, setOpen, theme }: any) => {
  return (
    <Wrapper>
      <Heading>{ heading }</Heading>
      <Icons>
        {
          done && <DoneIcon color={theme.colors.Primary} />
        }
        <CollapseIcon src={open ? CollapseUp : CollapseDown} onClick={() => setOpen(!open)} />
      </Icons>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 24px;
  align-items: center;
`;

const Heading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  letter-spacing: 0.01em;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
`;

const CollapseIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-left: 16px;
`;
