import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { H4Styled } from '../Elements/H4Styled';
import BackArrow from '../../../../../assets/icons/Left-back.svg';
import Header from './Header';
import SingleResult from './SingleResult';
import Filters from './Filters';
import Loader from '../../../Loader';
import { getInvoiceList } from '../../../../utils/APICalls/Account';
import device from '../../../../utils/device';
import Search from './Search';

const Invoices = () => {
  const history = useHistory();
  const [selectedFilter, setSelectedFilter] = useState(['credit', 'invoice', 'topup']);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getInvoiceList()
      .then((res: any) => {
        const temp = res.data.map((r: any) => {
          if (r.id.includes('ACC')) {
            return {
              ...r,
              type: 'topup',
              active: true,
            };
          }

          return {
            ...r,
            active: true,
          };
        });

        setInvoiceData(temp);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleBack = () => {
    history.goBack();
  };

  const handleFilterChange = (selected: any) => {
    setSelectedFilter(selected);
  };

  useEffect(() => {
    setInvoiceData(
      invoiceData.map((item: any) => ({
        ...item,
        active: selectedFilter.includes(item.type),
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  const handleSearch = (value: any) => {
    setInvoiceData(
      invoiceData.map((item: any) => ({
        ...item,
        active: item.id.toLocaleLowerCase().includes(value.toLowerCase()),
      }))
    );
  };

  const InvoiceList = () => {
    return (
      <>
        <FilterWrapper>
          <Filters onChange={handleFilterChange} selected={selectedFilter} />
          <Search handleChange={handleSearch} />
        </FilterWrapper>
        <Header />
        {invoiceData.length > 0 ? (
          invoiceData.map((item: any, i: any) => (
            item.active && (
              <SingleResult
                key={i}
                type={item.type}
                invoiceNumber={item.id}
                date={item.date}
                amount={item.amount}
                status={item.status}
                downloads={item?.downloads || {}}
              />
            )
          ))
        ) : (
          <NoResults>No results found</NoResults>
        )}
      </>
    );
  };

  return (
    <Wrapper>
      <H4Styled>
        <Title>
          <BackIcon onClick={handleBack} src={BackArrow} alt="Back" />
          Invoices & Credit Notes
        </Title>
      </H4Styled>
      {loading ? <Loader>Loading</Loader> : InvoiceList()}
      {/* <Pagination
        pages={1}
        current={1}
        goToPage={() => {}}
      /> */}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Title = styled.span`
  display: flex;
  align-items: center;
`;

const BackIcon = styled.img`
  margin-right: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const NoResults = styled.div`
  display: flex;
  width: 100%;
  background: ${({ theme }: any) => theme.colors.White};
  border-radius: 4px;
  padding: 16px 24px;
  color: ${({ theme }: any) => theme.colors.Black};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

export default Invoices;
