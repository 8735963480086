import React from 'react';
import styled from 'styled-components';
import { Input, Label, Caption } from '../../../../../../../../assets/elements';
import { countriesPostalCodesData } from '../../../../../../../mocks/quoteData';
import { SelectInput } from '../../../../../../Inputs';
import Required from '../../../../../../../../assets/elements/Required';

const ManuallyBox = ({
  address,
  setAddress,
  theme,
  addressErrors,
  setAddressErrors,
  serviceType,
}: any) => {
  const handleChange = (e: any, key: any) => {
    const { value } = e.target;

    if (
      (key === 'addressOne' ||
      key === 'addressTwo' ||
      key === 'city') &&
      value.length > 30
    ) {
      setAddressErrors({
        ...addressErrors,
        [key]: 'This must be less than 30 characters',
      });
    } else if (key === 'company' && (value !== '' && (value.length < 3 || value.length > 34))) {
      setAddressErrors({
        ...addressErrors,
        [key]: 'This must be between 3 and 34 characters',
      });
    } else if (key in address) {
      setAddressErrors({
        ...addressErrors,
        [key]: false,
      });
    }

    setAddress({
      ...address,
      [key]: value,
    });
  };

  const handleCountryChange = (code: any) => {
    setAddressErrors({
      ...addressErrors,
      country: false,
    });

    setAddress({
      ...address,
      country: code,
    });
  };

  return (
    <>
      <SubTitle>
        You have requested a collection service. Add the collection address
        below
      </SubTitle>
      <Form type={serviceType} autoComplete="off">
        <InputWrapper>
          <LabelStyled>
            {
              serviceType === 'seaFreight' && <Required />
            }
            {
              serviceType === 'seaFreight' ? 'Company name' : 'Company Name (Required for business recipients)'
            }
          </LabelStyled>
          <Input
            placeholder=""
            value={address.company}
            onChange={(e: any) => handleChange(e, 'company')}
            theme={theme}
            type="text"
            error={addressErrors.company}
            autoComplete="off"
          />
          <ErrorText>{addressErrors.company}</ErrorText>
        </InputWrapper>
        <InputWrapper>
          <LabelStyled><Required />Address line 1</LabelStyled>
          <Input
            placeholder=""
            value={address.addressOne}
            onChange={(e: any) => handleChange(e, 'addressOne')}
            theme={theme}
            type="text"
            error={addressErrors.addressOne}
            autoComplete="off"
          />
          <ErrorText>{addressErrors.addressOne}</ErrorText>
        </InputWrapper>
        <InputWrapper>
          <LabelStyled>Address line 2</LabelStyled>
          <Input
            placeholder=""
            value={address.addressTwo}
            onChange={(e: any) => handleChange(e, 'addressTwo')}
            theme={theme}
            type="text"
            error={addressErrors.addressTwo}
            autoComplete="off"
          />
          <ErrorText>{addressErrors.addressTwo}</ErrorText>
        </InputWrapper>
        <InputWrapper>
          <LabelStyled>Address line 3</LabelStyled>
          <Input
            placeholder=""
            value={address.addressThree}
            onChange={(e: any) => handleChange(e, 'addressThree')}
            theme={theme}
            type="text"
            error={false}
            autoComplete="off"
          />
        </InputWrapper>
        <InputWrapper>
          <LabelStyled><Required />City</LabelStyled>
          <Input
            placeholder=""
            value={address.city}
            onChange={(e: any) => handleChange(e, 'city')}
            theme={theme}
            type="text"
            error={addressErrors.city}
            autoComplete="off"
          />
          <ErrorText>{addressErrors.city}</ErrorText>
        </InputWrapper>
        <InputWrapper>
          <LabelStyled>Country</LabelStyled>
          <SelectInput
            data={countriesPostalCodesData}
            error={addressErrors.country}
            value={address.country}
            height="50px"
            onChange={(code: string) =>
              handleCountryChange(code)}
            isDisabled={true}
          />
          <ErrorText>{addressErrors.country}</ErrorText>
        </InputWrapper>
        <InputWrapper>
          <LabelStyled>Postcode</LabelStyled>
          <Input
            placeholder=""
            value={address.zip}
            onChange={(e: any) => handleChange(e, 'zip')}
            theme={theme}
            type="text"
            error={addressErrors.zip}
            autoComplete="off"
            disabled={true}
          />
          <ErrorText>{addressErrors.zip}</ErrorText>
        </InputWrapper>
      </Form>
    </>
  );
};

const SubTitle = styled.p<any>`
  margin: 20px 0;
  line-height: 150%;
`;
const Form = styled.form.attrs(({ type, autoComplete }: any) => ({
  type,
  autoComplete
}))<any>`
  background: ${({ type }: any) => type === 'freight' ? '#ffffff' : '#f2f2f2'};
  padding: ${({ type }: any) => type === 'freight' ? '0' : '20px 10px 1px 10px' };
  border-radius: 4px;
`;
const InputWrapper = styled.div`
  margin-bottom: 25px;
  position: relative;
`;
const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: normal;
  margin-left: 3px;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;
const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 3px;
`;

export default ManuallyBox;
