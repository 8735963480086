import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { companyName, environment } from '../../utils/env';
import { A } from '../../../assets/elements';
import footerAddress, { poweredBy, VATNumber } from '../../utils/footer';
import device from '../../utils/device';

const Copyright = () => {
  const powered = poweredBy();

  const handleClick = () => {
    window.open(powered.link, '_blank');
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <Line>{ footerAddress() }</Line>
        <Line>
          &copy; { moment().year() } { companyName } &bull; VAT Number: { VATNumber() } &bull;{ ' ' }
          { environment === 'arw' ? 'P' : 'Pallet delivery p' }owered by <AStyled onClick={handleClick}>{ powered.name }</AStyled>
        </Line>
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px 16px;

  @media (min-width: 479px) {
    padding: 24px 48px;
  }

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${({ theme }: any) => theme.colors.White};
  background: ${({ theme }: any) => theme.colors.CopyrightBackground};
  text-align: left;
  border-top: 1px solid ${({ theme }: any) => theme.colors.White};
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1120px;
`;

const AStyled = styled(A)`
  color: ${({ theme }: any) => theme.colors.Secondary};
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: ${({ theme }: any) => theme.colors.White};
  }
`;

const Line = styled.div`
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export default Copyright;
