import React from 'react';
import styled from 'styled-components';
import device from '../../../app/utils/device';

export const TableRow = ({ children }: any) => {
  return <TableRowWrapper>{children}</TableRowWrapper>;
};

const TableRowWrapper = styled.div.attrs(({ head }: any) => ({
  head,
}))`
  display: flex;
  width: 100%;
  color: ${({ theme }: any) => theme.colors.Black};
  flex-direction: column;
  padding: 16px;

  &:first-child {
    display: none;
  }

  &:nth-child(odd) {
    background-color: ${({ theme }: any) => theme.colors.WhiteGrey};
  }

  &:nth-child(even) {
    background-color: #e8eae8;
  }

  @media ${device.laptop} {
    flex-direction: row;
    padding: 0;

    &:first-child {
      display: flex;
    }
  }
`;
