import React from 'react';
import styled from 'styled-components';

const TableRow = ({ children, border = false }: any) => {
  return (
    <Wrapper border={border}>
      { children }
    </Wrapper>
  );
};

const Wrapper = styled.tr.attrs(({ border }: any) => ({
  border,
}))`
  background: transparent;

  border-bottom: ${({ theme, border }: any) => border ? `1px solid ${theme.colors.WhiteGrey}` : 'none'};

  &:last-child {
    border-bottom: unset;
  }
`;

export default TableRow;
