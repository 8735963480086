import React from 'react';
import { withTheme } from 'styled-components';
import Modal from '../../../../../../../assets/Combos/Modal';
import PaymentProcessing from '../../../../../../../assets/icons/PaymentProcessing/PaymentProcessing';

const ProcessingPayment = ({ theme, show }: any) => {
  return (
    <Modal
      open={show}
      close={() => {}}
      width={4}
    >
      <PaymentProcessing color={theme.colors.Primary} />
    </Modal>
  );
};

export default withTheme(ProcessingPayment);
