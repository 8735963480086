import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  A,
  Row,
  Input,
  Column,
  Caption,
} from '../../../../../../../../assets/elements';
import device from '../../../../../../../utils/device';
import { landingPageUrl } from '../../../../../../../utils/env';
import AddIcon from '../../../../../../../../assets/icons/AddIcon';
import RemoveIcon from '../../../../../../../../assets/icons/RemoveIcon';
import { countriesPostalCodesData } from '../../../../../../../mocks/quoteData';
import { SelectInput } from '../../../../../../Inputs';
import TariffCodeLookup from '../../../../../../TariffCodeLookup';
import Required from '../../../../../../../../assets/elements/Required';
import CheckBoxInput from '../../../../../../Inputs/CheckBoxInput';
import CollapsedShipmentItem from './CollapsedShipmentItem';

const palletData: any = {
  1: 'Mini',
  2: 'Quarter',
  3: 'Half',
  5: 'Light',
  6: 'Full',
};

const boxTypeDisplay: any = {
  box: 'boxes',
  crate: 'crates',
  pallet: 'pallets',
  parcel: 'parcels',
  freight: 'pallets',
};

const ShipmentItem = ({
  theme,
  shipment,
  onChange,
  length,
  errors,
  weightError,
  i,
  customs = true,
  handleBlur,
  prohibited,
  serviceType,
  addItem,
  removeItem,
  copyToAll,
  deliveryCountry,
  copyToAllCheck,
  handleCheckboxChange,
  numPallets,
}: any) => {
  const [editBox, setEditBox] = useState(false);

  useEffect(() => {
    if (copyToAllCheck) {
      copyToAll();
    }
  }, [copyToAllCheck]);

  const handleBlurring = () => {
    return;

    if (!copyToAllCheck && i !== 0) {
      return;
    }

    copyToAll();
  };

  const goToProhibited = () => {
    window.open(`${landingPageUrl}faq/prohibited-items/`, '_blank');
  };

  const goToTariff = () => {
    window.open('https://www.trade-tariff.service.gov.uk/sections', '_blank');
  };

  const boxType: any = useSelector(
    (state: any) =>
      state.order.packageDetails?.seaFreight?.package?.subType ||
      state.order.serviceType
  );

  const handleChange = (value: any, id: any, key: any) => {
    onChange(shipment.id, id, key, value);
    setTimeout(() => {
      handleBlurring();
    }, 1000);
  };

  return (
    <Wrapper full={copyToAllCheck ? i === 0 : true}>
      <CaptionStyledHead>
        {serviceType === 'freight' &&
          numPallets % 26 !== 0 &&
          `Pallet ${i + 1}: Full`}
        {serviceType === 'freight' &&
          numPallets % 26 === 0 &&
          `${numPallets / 26}x Full truck load (fcl)`}
        {serviceType === 'parcel' &&
          `Parcel ${i + 1}: 
              ${shipment.length.amount}x${shipment.width.amount}x${
            shipment.height.amount
          }CM,
               ${shipment.weight.amount}KG`}
        {serviceType === 'pallet' && palletData[shipment.palletSizeId]
          ? `Pallet ${i + 1}: ${palletData[shipment.palletSizeId || 1]}`
          : null}
        {serviceType === 'pallet' && !palletData[shipment.palletSizeId]
          ? `Pallet ${i + 1}: ${shipment.length}x${shipment.width}x${
              shipment.height
            }cm (${shipment.weight}kg)`
          : null}
        {serviceType === 'seaFreight' &&
          `${boxType} ${i + 1}: 
              ${shipment.length.amount}x${shipment.width.amount}x${
            shipment.height.amount
          }CM,
               ${shipment.weight.amount}KG`}
      </CaptionStyledHead>
      {copyToAllCheck && i !== 0 && !editBox && (
        <CollapsedShipmentItem
          items={shipment.items}
          edit={() => setEditBox(true)}
          customs={customs}
        />
      )}
      {((copyToAllCheck ? i === 0 : true) || editBox) &&
        shipment.items.map((item: any, j: any) => (
          <ItemWrapper key={j}>
            {!customs && (
              <CaptionHead>
                <Required />
                Item {j + 1}
              </CaptionHead>
            )}
            <RowStyled>
              <ColumnDesc
                customs={customs}
                sizeL={customs ? 12 : 6}
                sizeM={customs ? 12 : 6}
                sizeS={customs ? 6 : 3}
                sizeXS={customs ? 4 : 2}
              >
                {customs && (
                  <CaptionStyled>
                    <Required />
                    Item {j + 1}
                  </CaptionStyled>
                )}
                <Input
                  theme={theme}
                  value={item.description}
                  type="text"
                  placeholder="Brief description"
                  onChange={(e: any) =>
                    onChange(
                      shipment.id,
                      item.id,
                      'description',
                      e.target.value
                    )
                  }
                  error={
                    (errors[shipment.id] &&
                      errors[shipment.id][item.id]?.description) ||
                    false
                  }
                  onBlur={(e: any) => {
                    handleBlur(e, shipment.id, item.id);
                    handleBlurring();
                  }}
                />
                {errors[shipment.id] &&
                  errors[shipment.id][item.id]?.description && (
                    <ErrorText>
                      {errors[shipment.id][item.id]?.description}
                    </ErrorText>
                  )}
              </ColumnDesc>
              {!customs && (
                <>
                  <ColumnStyled
                    customs={customs}
                    sizeL={4}
                    sizeM={4}
                    sizeS={2}
                    sizeXS={1.5}
                  >
                    {customs && (
                      <CaptionStyled>
                        <Required />
                        Unit value
                      </CaptionStyled>
                    )}
                    <InputContainer>
                      <Input
                        theme={theme}
                        value={item.value}
                        type="text"
                        placeholder="Value"
                        onChange={(e: any) =>
                          onChange(
                            shipment.id,
                            item.id,
                            'value',
                            e.target.value
                          )
                        }
                        error={
                          (errors[shipment.id] &&
                            errors[shipment.id][item.id]?.value) ||
                          false
                        }
                        onBlur={handleBlurring}
                      />
                      <InputCaption>£</InputCaption>
                      {errors[shipment.id] &&
                        errors[shipment.id][item.id]?.value && (
                          <ErrorText>Required</ErrorText>
                        )}
                    </InputContainer>
                  </ColumnStyled>
                  <ColumnStyled
                    customs={customs}
                    sizeL={2}
                    sizeM={2}
                    sizeS={1}
                    sizeXS={0.5}
                  >
                    <IconsContainer customs={customs}>
                      {shipment.items.length > 1 && (
                        <A onClick={() => removeItem(shipment.id, item.id)}>
                          <RemoveIcon width={24} height={24} />
                        </A>
                      )}
                      {shipment.items.length === j + 1 && (
                        <A onClick={() => addItem(shipment.id)}>
                          <AddIcon width={24} height={24} />
                        </A>
                      )}
                    </IconsContainer>
                  </ColumnStyled>
                </>
              )}
              {prohibited[item.id] && prohibited[item.id].restricted && (
                <RestrictedError>
                  You are trying to send a <Strong>restricted</Strong> item. You{' '}
                  <strong>can</strong> send these items through CollectMyParcel
                  however this will be on a no compensation basis. If they are
                  lost or damaged in transit you will be unable to make a claim
                  for compensation.
                </RestrictedError>
              )}
              {prohibited[item.id] && prohibited[item.id].prohibited && (
                <ProhibitedError>
                  You are trying to send a <Strong>prohibited</Strong> item. We
                  are unable to send these items through any courier at
                  CollectMyParcel. If you are not sure why we’ve flagged this
                  item, please check our{' '}
                  <A onClick={goToProhibited}>list of prohibited goods</A>
                </ProhibitedError>
              )}
              {customs && (
                <>
                  <ColumnStyled
                    customs={customs}
                    sizeL={12}
                    sizeM={12}
                    sizeS={6}
                    sizeXS={4}
                  >
                    <ColumnInner>
                      <CaptionStyled>
                        <Required />
                        Manufactured in
                      </CaptionStyled>
                      <SelectInput
                        error={
                          (errors[shipment.id] &&
                            errors[shipment.id][item.id]?.manufactured_in) ||
                          false
                        }
                        resetError={() => {}}
                        value={item.manufactured_in}
                        onChange={(selected: any) =>
                          handleChange(selected, item.id, 'manufactured_in')
                        }
                        data={countriesPostalCodesData}
                        height="50px"
                      />
                      {errors[shipment.id] &&
                        errors[shipment.id][item.id]?.manufactured_in && (
                          <ErrorText>Required</ErrorText>
                        )}
                    </ColumnInner>
                  </ColumnStyled>
                  <ColumnStyled
                    customs={customs}
                    sizeL={12}
                    sizeM={12}
                    sizeS={6}
                    sizeXS={4}
                  >
                    <CaptionStyled>
                      <Required />
                      Tariff code
                    </CaptionStyled>
                    <TariffCodeLookup
                      value={item.tariff_code}
                      description={item.description}
                      manufacturedIn={item.manufactured_in}
                      deliveryCountry={deliveryCountry}
                      onChange={(selected: any) =>
                        handleChange(selected, item.id, 'tariff_code')
                      }
                      onblur={handleBlurring}
                    />
                    {errors[shipment.id] &&
                      errors[shipment.id][item.id]?.tariff_code && (
                        <ErrorText>
                          {errors[shipment.id] &&
                            errors[shipment.id][item.id]?.tariff_code}
                        </ErrorText>
                      )}
                  </ColumnStyled>

                  <Row>
                    <ColumnStyled
                      customs={customs}
                      sizeL={6}
                      sizeM={6}
                      sizeS={3}
                      sizeXS={2}
                    >
                      <CaptionStyled>
                        <Required />
                        Unit weight
                      </CaptionStyled>
                      <InputContainer>
                        <Input
                          theme={theme}
                          value={item.weight}
                          type="text"
                          placeholder="Weight"
                          onChange={(e: any) =>
                            onChange(
                              shipment.id,
                              item.id,
                              'weight',
                              e.target.value
                            )
                          }
                          error={
                            (errors[shipment.id] &&
                              errors[shipment.id][item.id]?.weight) ||
                            false
                          }
                          onBlur={handleBlurring}
                        />
                        <WeightCaption>kg</WeightCaption>
                        {errors[shipment.id] &&
                          errors[shipment.id][item.id]?.weight && (
                            <ErrorText>Required</ErrorText>
                          )}
                      </InputContainer>
                    </ColumnStyled>
                    <ColumnStyled
                      customs={customs}
                      sizeL={6}
                      sizeM={6}
                      sizeS={3}
                      sizeXS={2}
                    >
                      <ColumnInner>
                        <CaptionStyled>
                          <Required />
                          Number of units
                        </CaptionStyled>
                        <Input
                          theme={theme}
                          value={item.quantity}
                          type="text"
                          placeholder="quantity"
                          onChange={(e: any) =>
                            onChange(
                              shipment.id,
                              item.id,
                              'quantity',
                              e.target.value
                            )
                          }
                          error={
                            (errors[shipment.id] &&
                              errors[shipment.id][item.id]?.quantity) ||
                            false
                          }
                          onBlur={handleBlurring}
                        />
                        {errors[shipment.id] &&
                          errors[shipment.id][item.id]?.quantity && (
                            <ErrorText>Required</ErrorText>
                          )}
                      </ColumnInner>
                    </ColumnStyled>
                  </Row>

                  <ColumnStyled
                    customs={customs}
                    sizeL={6}
                    sizeM={6}
                    sizeS={3}
                    sizeXS={2}
                  >
                    {customs && (
                      <CaptionStyled>
                        <Required />
                        Unit value
                      </CaptionStyled>
                    )}
                    <InputContainer>
                      <Input
                        theme={theme}
                        value={item.value}
                        type="text"
                        placeholder="Value"
                        onChange={(e: any) =>
                          onChange(
                            shipment.id,
                            item.id,
                            'value',
                            e.target.value
                          )
                        }
                        error={
                          (errors[shipment.id] &&
                            errors[shipment.id][item.id]?.value) ||
                          false
                        }
                        onBlur={handleBlurring}
                      />
                      <InputCaption>£</InputCaption>
                      {errors[shipment.id] &&
                        errors[shipment.id][item.id]?.value && (
                          <ErrorText>Required</ErrorText>
                        )}
                    </InputContainer>
                  </ColumnStyled>
                  <ColumnStyled
                    customs={customs}
                    sizeL={2}
                    sizeM={2}
                    sizeS={2}
                    sizeXS={1}
                  >
                    <IconsContainer customs={customs}>
                      {shipment.items.length > 1 && (
                        <A onClick={() => removeItem(shipment.id, item.id)}>
                          <RemoveIcon width={24} height={24} />
                        </A>
                      )}
                      {shipment.items.length === j + 1 && (
                        <A onClick={() => addItem(shipment.id)}>
                          <AddIcon width={24} height={24} />
                        </A>
                      )}
                    </IconsContainer>
                  </ColumnStyled>
                </>
              )}
            </RowStyled>
          </ItemWrapper>
        ))}

      {weightError && (
        <WeightWarning>
          The total weight of your items must equal the weight of this parcel.
          You can also <NewQuoteLink>get a new quote</NewQuoteLink> with
          modified information.
        </WeightWarning>
      )}
      {length !== 1 && i === 0 && (
        <CopyWrapper customs={customs}>
          <CheckBoxInput
            checked={copyToAllCheck}
            label={`All ${boxTypeDisplay[boxType]} contain the same items`}
            onChange={handleCheckboxChange}
          />
        </CopyWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs(({ full }: any) => ({
  full,
}))`
  margin-top: 16px;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  padding: 24px 16px;
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};
  transition: 0.3s all;
`;

const ItemWrapper = styled.div`
  border-bottom: 1px dashed ${({ theme }: any) => theme.colors.LightGrey};
  padding-bottom: 16px;

  &:last-of-type {
    border-bottom: unset;
    padding-bottom: unset;
  }
`;

const RowStyled = styled(Row)`
  justify-content: start;
`;

const ColumnStyled = styled(Column).attrs(({ customs }: any) => ({
  customs,
}))`
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  margin-bottom: ${({ customs }: any) => (customs ? '24px' : '')};

  &:last-of-type {
    margin-bottom: 0;
    padding-left: 15px;
  }
`;

const ColumnDesc = styled(Column).attrs(({ customs }: any) => ({
  customs,
}))`
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  margin-bottom: ${({ customs }: any) => (customs ? '24px' : '')};

  @media ${device.laptopL} {
    padding-right: ${({ customs }: any) => (customs ? '' : '15px')};
  }
`;

const ColumnInner = styled.div`
  position: relative;
`;

const CaptionStyled = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  margin-bottom: 6px;
  display: inline-block;
`;

const CaptionHead = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  margin-bottom: 6px;
  margin-top: 16px;
  display: inline-block;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const InputCaption = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  border-radius: 0 4px 4px 0;
  background: ${({ theme }: any) => theme.colors.Secondary};
  color: ${({ theme }: any) => theme.colors.White};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WeightCaption = styled(InputCaption)`
  background: #dedede;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: none;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;

  @media ${device.laptopL} {
    display: inline-block;
  }
`;

const RestrictedError = styled.div`
  background: #fee6d0;
  color: ${({ theme }: any) => theme.colors.Black};
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  padding: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 16px;
`;

const ProhibitedError = styled.div`
  background: ${({ theme }: any) => theme.colors.Error};
  color: ${({ theme }: any) => theme.colors.White};
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  padding: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 16px;

  & > button {
    color: ${({ theme }: any) => theme.colors.White};
    text-decoration: underline;
  }
`;

const Strong = styled.strong`
  font-weight: bold;
`;

const CaptionStyledHead = styled(CaptionStyled)`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

const IconsContainer = styled.div.attrs(({ customs = false }: any) => ({
  customs,
}))`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
  padding-top: 0;
`;

const CopyWrapper = styled.div.attrs(({ customs = false }: any) => ({
  customs,
}))`
  padding-top: 26px;
  color: #408f49;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
`;

const WeightWarning = styled.div`
  background: #fee6d0;
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  padding: 24px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
`;

const NewQuoteLink = styled(A)`
  text-decoration: underline;
`;

export default ShipmentItem;
