import React from 'react';
import styled from 'styled-components';
import { Button, H6, PSmall } from '../../../../../../../../assets/elements';
import Modal from '../../../../../../../../assets/Combos/Modal';

const CPCModal = ({
  open,
  close,
}: any) => {
  const findCPC = () => {
    window.open('https://www.gov.uk/government/publications/uk-trade-tariff-customs-procedure-codes/customs-procedure-codes-box-37');
  };

  return (
    <Modal
      open={open}
      close={close}
      width={6}
      title="CPC Codes"
      TitleElement={H6}
    >
      <PSmall>
        The customs procedure codes (CPCs) identify the customs and/or excise regimes{' '}
        which goods are being entered into and removed from (where this applies).
        The CPC is completed at export as well as import.
        <br />
        The CPC is based on a 2 digit community code which identifies a customs procedure,
        eg removal from warehouse, entry to free zone, and export under Outward Processing Relief (OPR).
        <br />
        For example:  10 00 00 01 is the CPC code for a GB Export of Goods that have been sold.
        <br />
        We recommend using the Gov.uk to find your CPC code to help avoid delays at customs.
      </PSmall>
      <ButtonsRow>
        <Button color="black" onClick={close}>OK, go back</Button>
        <Button color="secondary" onClick={findCPC}>Find my CPC code</Button>
      </ButtonsRow>
    </Modal>
  );
};

const ButtonsRow = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

export default CPCModal;
