import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../../../assets/elements';

const ContinueRow = ({ handleClick, disable }: any) => {
  return (
    <Wrapper>
      <Button
        color="primary"
        onClick={handleClick}
        disable={disable}
        disabled={disable}
      >
        Continue
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;
`;

export default ContinueRow;
