import React from 'react';
import styled from 'styled-components';

const SortItem = ({ children, selected, onClick, id }: any) => {
  return (
    <Wrapper selected={selected} onClick={() => onClick(id)}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs(({ selected }: any) => ({
  selected,
}))`
  padding: 8px 20px;
  display: flex;
  flex-direction: row;
  background-color: ${({ selected, theme }: any) =>
    selected ? theme.colors.Black : theme.colors.White};
  color: ${({ selected, theme }: any) =>
    selected ? theme.colors.White : theme.colors.Black};
  border-radius: 60px;
  align-items: center;
  cursor: pointer;
  margin-right: -24px;
  z-index: ${({ selected }: any) => (selected ? 1 : 0)};
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  max-height: 40px;

  &:first-child {
    padding-right: ${({ selected }: any) => (selected ? '20px' : '35px')};
  }

  &:last-child {
    padding-left: ${({ selected }: any) => (selected ? '20px' : '35px')};
  }

  &:last-child {
    margin-right: 0;
  }
`;

export default SortItem;
