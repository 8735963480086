/*
* M: Mobile devices
* L: Larger devices (Laptop/Tablet)
*
* FS: Font size
* */

export const cmp = {
  Primary: '#408F49',
  Secondary: '#93BF46',
};