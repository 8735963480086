let domain = '';
const { origin } = window.location;

if (origin.includes('pwiltsher')) {
  domain = ';domain=egistics.pwiltsher.com';
} else if (origin.includes('collectmyparcel')) {
  domain = ';domain=collectmyparcel.com';
} else if (origin.includes('pallets4delivery')) {
  domain = ';domain=pallets4delivery.com';
} else if (origin.includes('p4d')) {
  domain = ';domain=p4d.co.uk';
} else if (origin.includes('mpddev')) {
  domain = ';domain=mpddev.e-gistics.com';
} else if (origin.includes('myparceldelivery')) {
  domain = ';domain=myparceldelivery.com';
} else if (origin.includes('action-roadways')) {
  domain = ';domain=action-roadways.co.uk';
} else if (origin.includes('auctiondeliveries')) {
  domain = ';domain=auctiondeliveries.com';
}

export const getCookie = (name) => {
  const cookie = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
  return cookie ? cookie.pop() : '';
};

const essentialCookies = ['accessToken', 'accessTokenExpiry', 'refreshToken', 'refreshTokenExpiry', 'userName', 'userId'];

export const setCookie = (cookieArray) => {
  const cookieConsent = getCookie('cookie_consent');
  cookieArray.forEach(({ name, value, expiry = '' }) => {
    if (cookieConsent !== 'deny' || essentialCookies.includes(name)){
      let expires = '';

      if (expiry !== '') {
        const d = new Date();
        d.setTime(d.getTime() + (expiry*24*60*60*1000));

        expires = `expires=${d.toUTCString()};`;
      }

      document.cookie = `${name}=${value};${expires}path=/${domain}`;
    }
  });
};

export const deleteCookie = (names) => {
  names.forEach((name) => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;${domain}`;
  });
};
