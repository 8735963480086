import React from 'react';
import styled from 'styled-components';

class Dots extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { dots: 1 };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const { dots } = this.state;
      this.setState({ dots: dots === 3 ? 0 : dots + 1 });
    }, 300);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { dots } = this.state;
    const text = dots === 0 ? '' : '.'.repeat(dots);
    return (
      <DotsContainer>{text}</DotsContainer>
    );
  }
}

const DotsContainer = styled.span`
  display: inline-block;
  width: 16px;
`;

export default Dots;
