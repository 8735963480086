import React from 'react';
import Select from 'react-select';
import styled, { withTheme } from 'styled-components';
import { DropdownIndicator } from './DropdownIndicator';

const SelectInput = ({
  data,
  resetError,
  onChange,
  error,
  value,
  theme,
  height = '100%',
  innerRef= null,
  isDisabled = false,
  placeholder = 'Select...',
  minWidth = '150px',
  width = 'auto',
  fontSize = '16px',
  isSearchable = true,
  radius = '0',
  noOptionsMessage = 'No options available',
}: any) => {
  const handleChange = (selected: any) => {
    error && resetError();
    onChange(selected.value);
  };

  const options = data.map(
    ({ value: selectValue, code, name, label }: any) => ({
      value: selectValue || code,
      label: label || name,
    })
  );

  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      height: 'auto',
    }),
    control: (provided: any, state: any) => {
      const focusedColor = state.isFocused ? `${theme.colors.Secondary} !important` : `${ theme.colors.LightGrey } !important`;
      return {
        ...provided,
        border: '2px solid #F2F2F2',
        borderColor: state.selectProps.error
          ? `${theme.colors.Error} !important`
          : focusedColor,
        borderRadius: radius,
        height,
        boxShadow: 'unset',
        fontSize,
      };
    },
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? theme.colors.Pastel2 : theme.colors.White,
      fontSize: '16px',
      lineHeight: '150%',
      fontWeight: 300,
      letterSpacing: '0.01em',
      color: '#2B3131',
      padding: '8px 14px',
      ':hover': {
        ...provided[':hover'],
        backgroundColor: theme.colors.Pastel2,
      },
      ':active': {
        ...provided[':active'],
        backgroundColor: theme.colors.Pastel2,
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      margin: 0,
      zIndex: 99999999999999999,
      color: theme.colors.PrimaryHover,
    }),
  };

  return (
    <SelectS
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={options.find((op: any) => op.value === value)}
      onChange={handleChange}
      options={options}
      styles={customStyles}
      components={{ DropdownIndicator }}
      error={error}
      ref={innerRef}
      isDisabled={isDisabled}
      placeholder={placeholder}
      minWidth={minWidth}
      width={width}
      isSearchable={isSearchable}
      radius={radius}
      noOptionsMessage={() => noOptionsMessage}
    />
  );
};

const SelectS = styled(Select)`
  height: 100%;
  background: white;
  min-width: ${({ minWidth }: any) => minWidth};
  width: ${({ width }: any) => width};
  border-radius: ${({ radius }: any) => radius};
`;

export default withTheme(SelectInput);
