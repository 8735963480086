import React, { ChangeEvent } from 'react';
import styled, { withTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import {
  Row,
  Column,
  Input,
  InputUnitsLabel,
  Label,
  A,
  Caption,
} from '../../elements';
import { Theme } from '../../elements/Interfaces';
import AddIcon from '../../icons/AddIcon';
import RemoveIcon from '../../icons/RemoveIcon';
import device from '../../../app/utils/device';

interface DimensionInputProps {
  parcel: Parcel;
  onChange: (e: ChangeEvent<HTMLInputElement>, id: string, key: string) => void;
  addNewItem: () => void;
  removeItem: (id: string) => void;
  theme: Theme | undefined;
  totalItems: number;
  index: number;
  lengthError: object;
  widthError: object;
  heightError: object;
  weightError: object;
}

interface Parcel {
  id: string;
  length: Dimensions;
  width: Dimensions;
  height: Dimensions;
  weight: Dimensions;
}

interface Dimensions {
  amount: string | number;
  units: string;
}

const DimensionsInput = (props: any) => {
  const { parcel } = props;

  return (
    <>
      <ColumnPad sizeL={6} sizeM={6} sizeS={6}>
        <LabelStyled>Dimensions</LabelStyled>
        <Row>
          <ColumnStyled sizeL={3.33} sizeM={3} sizeS={1.5} sizeXS={1}>
            <Input
              placeholder="Length"
              value={parcel.length.amount}
              onChange={(e: any) => props.onChange(e, parcel.id, 'length')}
              theme={props.theme}
              type="text"
              error={!!props.lengthError}
              autoComplete="off"
              name="length"
            />
            <ErrorText>{props.lengthError}</ErrorText>
          </ColumnStyled>
          <ColumnStyled sizeL={3.33} sizeM={3} sizeS={1.5} sizeXS={1}>
            <Input
              placeholder="Width"
              value={parcel.width.amount}
              onChange={(e: any) => props.onChange(e, parcel.id, 'width')}
              theme={props.theme}
              type="text"
              error={!!props.widthError}
              autoComplete="off"
              name="width"
            />
            <ErrorText>{props.widthError}</ErrorText>
          </ColumnStyled>
          <ColumnStyled sizeL={5.33} sizeM={6} sizeS={3} sizeXS={2}>
            <Input
              placeholder="Height"
              value={parcel.height.amount}
              onChange={(e: any) => props.onChange(e, parcel.id, 'height')}
              theme={props.theme}
              type="text"
              error={!!props.heightError}
              autoComplete="off"
              name="height"
            />
            <InputUnitsLabel>cm</InputUnitsLabel>
            <ErrorText>{props.heightError}</ErrorText>
          </ColumnStyled>
        </Row>
      </ColumnPad>
      <ColumnPad sizeL={6} sizeM={6} sizeS={6}>
        <LabelStyled>Weight</LabelStyled>
        <Row>
          <ColumnStyled sizeL={5} sizeM={6} sizeS={3} sizeXS={2}>
            <Row>
              <ColumnStyled sizeL={12} sizeM={12}>
                <Input
                  placeholder="Weight"
                  value={parcel.weight.amount}
                  onChange={(e: any) => props.onChange(e, parcel.id, 'weight')}
                  theme={props.theme}
                  type="text"
                  error={!!props.weightError}
                  autoComplete="off"
                  name="weight"
                />
                <InputUnitsLabel>kg</InputUnitsLabel>
              </ColumnStyled>
              <ErrorText>{props.weightError}</ErrorText>
            </Row>
          </ColumnStyled>
          <IconColumn sizeL={7} sizeM={6} sizeS={3} sizeXS={2}>
            {props.totalItems > 1 && (
              <IconContainer>
                <A
                  data-tip
                  data-for="remove-icon"
                  onClick={() => props.removeItem(parcel.id)}
                >
                  <RemoveIcon />
                </A>
                <ReactTooltip id="remove-icon" effect="solid">
                  Remove item
                </ReactTooltip>
              </IconContainer>
            )}
            {props.totalItems === props.index + 1 && (
              <IconContainer>
                <A data-tip data-for="add-icon" onClick={props.addNewItem}>
                  <AddIcon />
                </A>
                <ReactTooltip id="add-icon" effect="solid">
                  Add item
                </ReactTooltip>
              </IconContainer>
            )}
          </IconColumn>
        </Row>
      </ColumnPad>
    </>
  );
};

const ColumnStyled = styled(Column)`
  padding: 0;
  position: relative;

  & > input {
    border-radius: unset;
  }

  &:first-child > input {
    border-top-left-radius: ${({ theme }: any) =>
    theme.box.BorderRadiusSmall};
    border-bottom-left-radius: ${({ theme }: any) =>
    theme.box.BorderRadiusSmall};
  }

  &:last-child > input {
    border-top-right-radius: ${({ theme }: any) =>
    theme.box.BorderRadiusSmall};
    border-bottom-right-radius: ${({ theme }: any) =>
    theme.box.BorderRadiusSmall};
  }
`;

const ColumnPad = styled(Column)`
  padding: 0;
  margin-bottom: 24px;

  @media ${device.laptop} {
    padding: 0 15px;
  }
`;

const IconColumn = styled(Column)`
  display: flex;
  justify-content: flex-start;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 0 0 50%;
  max-width: 50%;
`;

const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

const Icon = styled.img`
  & path {
    stroke: ${({ theme }: any) => theme.colors.Secondary};
  }
`;

export default withTheme(DimensionsInput);
