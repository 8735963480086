import React from 'react';
import styled from 'styled-components';
import { Caption, H5, Container } from '../../../../../assets/elements';
import BackgroundImage from '../../../../../assets/images/QuickbookBack.jpeg';

const QuickBookHeader = () => {
  return (
    <Wrapper background={BackgroundImage}>
      <Container>
        <Heading>Quick book</Heading>
        <Text>
          Are you short of time and know the service you want?
          Our new quick book tool helps you make a fast and no frills booking.
        </Text>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs(({ background }: any) => ({
  background,
}))`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 40px 0;
  background-image: ${({ background }: any) => `linear-gradient(0deg, rgba(68, 72, 77, 0.5), rgba(68, 72, 77, 0.5)), url('${background}')`};
  background-position: center;
  background-size: cover;
`;

const Heading = styled(H5)`
  color: ${({ theme }: any) => theme.colors.White};
  margin-bottom: 24px;
`;

const Text = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.White};
`;

export default QuickBookHeader;
