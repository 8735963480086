import { environment, specificLandingPageUrl } from './env';

const footerAddress = () => {
  if (
    environment === 'staging' ||
    environment === 'cmp' ||
    environment === 'pallets4delivery' ||
    environment === 'mpd' ||
    environment === 'p4d' ||
    environment === 'auc'
  ) {
    return 'Haulable Limited, Hyatt Place, 50-60 Broomfield Road, Chelmsford, Essex, CM1 1SW, United Kingdom';
  }

  if (environment === 'arw') {
    return 'Unit 2, River Wharf Business Park, Mullberry Way, Belvedere, Kent, DA17 6AR';
  }
  return [];
};

export const VATNumber = () => {
  if (
    environment === 'staging' ||
    environment === 'cmp' ||
    environment === 'pallets4delivery' ||
    environment === 'p4d'
  ) {
    return 'GB 373 551 391';
  }

  if (environment === 'arw') {
    return 'GB 245983426';
  }

  if (environment === 'mpd') {
    return 'GB 373 551 391';
  }

  if (environment === 'auc') {
    return 'GB 373 551 391';
  }

  return '';
};

export const poweredBy = () => {
  if (
    environment === 'cmp' ||
    environment === 'pallets4delivery' ||
    environment === 'mpd' ||
    environment === 'p4d' ||
    environment === 'auc'
  ) {
    return {
      name: 'P4D',
      link: `${specificLandingPageUrl('P4D')}pallet-delivery`,
    };
  }

  if (environment === 'arw') {
    return {
      name: 'E-gistics',
      link: 'https://www.e-gistics.com/',
    };
  }

  if (environment === 'staging') {
    return {
      name: 'Haulable',
      link: 'https://www.haulable.co.uk/',
    };
  }

  return {
    name: 'P4D',
    link: `${specificLandingPageUrl('P4D')}`,
  };
};

export default footerAddress;