import React, { useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import StepOne from '../../../Steps/StepOne';
import StepTwo from '../../../Steps/StepTwo';
import StepThree from '../../../Steps/StepThree';
import { Container } from '../../../../../../../assets/elements';
import { landingPageUrl } from '../../../../../../utils/env';

const OrderStepsContainer = (props: any) => {
  const orderItem = useSelector((state: any) => !!state?.order?.item?.serviceId || false);

  useEffect(() => {
    if (!orderItem) {
      window.location.href = landingPageUrl;
    }
  }, [orderItem]);

  return orderItem ? (
    <ContainerStyled>
      <Switch location={props.location}>
        <Route exact path="/order-step1" component={StepOne} />
        <Route exact path="/order-step2" component={StepTwo} />
        <Route exact path="/order-step3" component={StepThree} />
      </Switch>
    </ContainerStyled>
  ) : (
    <></>
  );
};

const ContainerStyled = styled(Container)`
  padding: 0 !important;
  width: 90%;
`;

export default withRouter(OrderStepsContainer);
