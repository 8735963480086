import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { PSmall, A, Row, Label, Caption, Column, Container, Button } from '../../../../../assets/elements';
import { landingPageUrl } from '../../../../utils/env';
import ItemDetails from '../Elements/ItemDetails';
import {
  addParcelToQuickbook,
  setCustomsDetails as setCustomsDetailsAction,
} from '../../../../store/actions';
import { getExportReasons } from '../../../../utils/APICalls/Customs';
import { SelectInput } from '../../../Inputs';
import device from '../../../../utils/device';
import ColumnWrapper from '../Elements/ColumnWrapper';
import Insurance from './Insurance';
import InfoIcon from '../../../../../assets/icons/InfoIcon';

const ShipmentDetails = ({
  setDone,
  allDone,
  showServices,
  customs,
  resetServices,
}: any) => {
  const [exportReasonOptions, setExportReasonsOptions] = useState([]);
  const [customsDetails, setCustomsDetails] = useState<any>({
    exportReason: '',
  });
  const [selectedInsurance, setSelectedInsurance] = useState<any>({});
  const [exportError, setExportError] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    getExportReasons().then((res: any) => {
      setExportReasonsOptions(res.data);
    });
  }, []);

  const gotToGuidelines = () => {
    window.open(`${landingPageUrl}faq/parcel-dimension-guidelines`, '_blank');
  };

  const dispatch = useDispatch();

  const [parcel, setParcel] = useState<any>([
    {
      id: uuidv4(),
      length: {
        amount: '',
        units: 'cm',
      },
      width: {
        amount: '',
        units: 'cm',
      },
      height: {
        amount: '',
        units: 'cm',
      },
      weight: {
        amount: '',
        units: 'kg',
      },
      items: [{
        id: uuidv4(),
        value: '',
        description: '',
      }],
    },
  ]);

  const [lengthError, setLengthError] = React.useState<any>({});
  const [widthError, setWidthError] = React.useState<any>({});
  const [heightError, setHeightError] = React.useState<any>({});
  const [weightError, setWeightError] = React.useState<any>({});
  const [itemError, setItemError] = React.useState<any>({});
  const [valueError, setValueError] = React.useState<any>({});

  const [completeDetails, setCompleteDetails] = useState(true);
  const [insuranceDone, setInsuranceDone] = useState(false);
  const [totalValue, setTotalValue] = useState(0.00);
  const [weightMatchError, setWeightMatchError] = useState<any>({});

  useEffect(() => {
    let done = true;

    parcel.forEach((p: any) => {
      if (
        p.length.amount === '' ||
        p.width.amount === '' ||
        p.height.amount === '' ||
        p.weight.amount === ''
      ) {
        done = false;
      }

      p.items.forEach((item: any) => {
        if (
          item.value === '' ||
          item.description === '' ||
          (customs && (
            item.weight === '' ||
            item.quantity === '' ||
            item.manufactured_in === '' ||
            item.tariff_code === ''
          ))
        ) {
          done = false;
        }
      });
    });

    if (customs && customsDetails.exportReason === '') {
      done = false;
    }

    setCompleteDetails(done);
  }, [parcel, customsDetails, insuranceDone, customs]);

  useEffect(() => {
    setDone(completeDetails && insuranceDone);
  }, [completeDetails, insuranceDone]);

  useEffect(() => {
    if (customs) {
      setParcel(parcel.map((p: any) => {
        p.items = p.items.map((item: any) => {
          item = {
            ...item,
            tariff_code: '',
            manufactured_in: '',
            weight: '',
            quantity: '',
          };

          return item;
        });

        return p;
      }));
    }
  }, [customs]);

  useEffect(() => {
    setTotalValue(parcel.reduce(
      (acc: any, p: any) => Number(acc) + p.items.reduce((acc2: any, item: any) => {
        return Number(acc2) + (Number(item.value || 0) * Number(item?.quantity || 1));
      }, 0),
      0
    ));
  }, [parcel]);

  useEffect(() => {
    let dis = false;

    Object.keys(weightMatchError).forEach((err: any) => {
      if (weightMatchError[err]) {
        dis = true;
      }
    });

    setDisabled(!allDone || dis);
  }, [allDone, weightMatchError]);

  const addNewItem = (id: any) => {
    let newPA: any = {
      id: uuidv4(),
      value: '',
      description: '',
    };

    if (customs) {
      newPA = {
        ...newPA,
        tariff_code: '',
        manufactured_in: '',
        weight: '',
        quantity: '',
      };
    }

    const newP = parcel.map((p: any) => {
      if (p.id === id) {
        p.items = [
          ...p.items,
          newPA,
        ];
      }

      return p;
    });

    setParcel(newP);
  };

  const addNewParcel = () => {
    const tempId = uuidv4();

    const newPA = {
      id: tempId,
      length: {
        amount: '',
        units: 'cm',
      },
      width: {
        amount: '',
        units: 'cm',
      },
      height: {
        amount: '',
        units: 'cm',
      },
      weight: {
        amount: '',
        units: 'kg',
      },
      items: [{
        id: uuidv4(),
        value: '',
        description: '',
      }],
    };

    if (customs) {
      newPA.items.map((p: any) => {
        return {
          ...p,
          tariff_code: '',
          manufactured_in: '',
          weight: '',
          quantity: '',
        };
      });
    }

    setParcel([
      ...parcel,
      newPA,
    ]);

    if (customs) {
      setWeightMatchError({
        ...weightMatchError,
        [tempId]: false,
      });
    }
  };

  const removeItem = (parcelId: any, id: any) => {
    const newItems = parcel.map((p: any) => {
      if (p.id === parcelId) {
        p.items = p.items.filter((item: any) => item.id !== id);
      }

      return p;
    });

    setParcel([
      ...newItems,
    ]);
  };

  const removeParcel = (parcelId: any) => {
    setParcel(parcel.filter((p: any) => p.id !== parcelId));

    if (customs) {
      const tempError = { ...weightMatchError };
      delete tempError[parcelId];

      setWeightMatchError({
        ...tempError,
      });
    }
  };

  useEffect(() => {
    resetServices();
  }, [customsDetails, parcel, selectedInsurance]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    key: string
  ) => {
    setLengthError({});
    setWidthError({});
    setHeightError({});
    setWeightError({});

    const floats = /^[0-9.]+$/;
    const { value } = e.target;

    if (
      key !== 'item' &&
      value !== '' &&
      !value.match(floats)
    ) return;

    const newItems = parcel.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          [key]: {
            ...item[key],
            amount: value,
          },
        };
      }

      return item;
    });

    setParcel([
      ...newItems,
    ]);
  };

  const handleValuesChange = (parcelId: any, itemId: any, value: any, key: any) => {
    const newItems = parcel.map((p: any) => {
      if (p.id === parcelId) {
        p.items = p.items.map((item: any) => {
          if (item.id === itemId) {
            return {
              ...item,
              [key]: value,
            };
          }

          return item;
        });
      }

      return p;
    });

    setParcel([
      ...newItems,
    ]);
  };

  const handleCustomsChange = (selected: any) => {
    setExportError(false);

    setCustomsDetails({
      exportReason: selected,
    });
  };

  const validateDimensions = () => {
    let errorLength = false;
    let errorWidth = false;
    let errorHeight = false;
    let errorWeight = false;
    const errorItem = false;
    const errorValue = false;

    const lengthErrorTemp = { ...lengthError };
    const widthErrorTemp = { ...widthError };
    const heightErrorTemp = { ...heightError };
    const weightErrorTemp = { ...weightError };
    const itemErrorTemp = { ...itemError };
    const valueErrorTemp = { ...valueError };

    parcel.forEach((p: any) => {
      const {
        id,
        length: { amount: length },
        width: { amount: width },
        height: { amount: height },
        weight: { amount: weight },
      } = p;

      if (isNaN(Number(length))) {
        lengthErrorTemp[id] = 'Wrong value';

        errorLength = true;
      } else if (Number(length) <= 0) {
        lengthErrorTemp[id] = 'Required';

        errorLength = true;
      } else {
        delete lengthErrorTemp[id];

        errorLength = false;
      }

      if (isNaN(Number(width))) {
        widthErrorTemp[id] = 'Wrong value';

        errorWidth = true;
      } else if (Number(width) <= 0) {
        widthErrorTemp[id] = 'Required';

        errorWidth = true;
      } else {
        delete widthErrorTemp[id];

        errorWidth = false;
      }

      if (isNaN(Number(height))) {
        heightErrorTemp[id] = 'Wrong value';

        errorHeight = true;
      } else if (Number(height) <= 0) {
        heightErrorTemp[id] = 'Required';

        errorHeight = true;
      } else {
        delete heightErrorTemp[id];

        errorHeight = false;
      }

      if (isNaN(Number(weight))) {
        weightErrorTemp[id] = 'Wrong value';

        errorWeight = true;
      } else if (Number(weight) <= 0) {
        weightErrorTemp[id] = 'Required';

        errorWeight = true;
      } else {
        delete weightErrorTemp[id];

        errorWeight = false;
      }
    });

    setLengthError({
      ...lengthErrorTemp,
    });

    setWidthError({
      ...widthErrorTemp,
    });

    setHeightError({
      ...heightErrorTemp,
    });

    setWeightError({
      ...weightErrorTemp,
    });

    setItemError({
      ...itemErrorTemp,
    });

    setValueError({
      ...valueErrorTemp,
    });

    return (
      errorLength ||
      errorWidth ||
      errorHeight ||
      errorWeight ||
      errorItem ||
      errorValue
    );
  };

  const validateExportReason = () => {
    if (customs) {
      setExportError(customsDetails.exportReason === '');
      return customsDetails.exportReason === '';
    }

    return false;
  };

  const handleSubmit = () => {
    const error = validateDimensions();
    const errorCustoms = validateExportReason();
    let errorWeight = false;

    Object.keys(weightMatchError).forEach((key: any) => {
      if (weightMatchError[key]) {
        errorWeight = true;
      }
    });

    if (error || errorCustoms || errorWeight) {
      return;
    }

    dispatch(setCustomsDetailsAction(customsDetails));
    dispatch(addParcelToQuickbook(parcel));
    showServices(true);
  };

  const goToProhibitedItems = () => {
    window.open(
      `${landingPageUrl}faq/prohibited-items/`,
      '_blank'
    );
  };

  const handleWeightMatchError = (id: any, value: boolean) => {
    if (customs) {
      setWeightMatchError({
        ...weightMatchError,
        [id]: value,
      });
    }
  };

  return (
    <>
      <PSmallStyled>
        Please add your items and an estimate of it's value.
        Not sure about dimensions? <A onClick={gotToGuidelines}>Check our guide</A>
      </PSmallStyled>
      <AStyled onClick={goToProhibitedItems}>
        <Icon>
          <InfoIcon />
        </Icon>
        Prohibited items
      </AStyled>
      <ContainerStyled>
        <Row>
          <ColumnStyled sizeL={6} sizeM={6} sizeS={3} sizeXS={4}>
            {parcel.map((item: any, index: any) => (
              <ItemDetails
                key={item.id}
                parcel={item}
                onChange={(e: any, id: any, key: any) => handleChange(e, id, key)}
                onValuesChange={handleValuesChange}
                addNewItem={addNewItem}
                removeItem={removeItem}
                removeParcel={removeParcel}
                index={index}
                lengthError={lengthError[item.id]}
                widthError={widthError[item.id]}
                heightError={heightError[item.id]}
                weightError={weightError[item.id]}
                itemError={itemError[item.id]}
                valueError={valueError[item.id]}
                customs={customs}
                handleWeightMatchError={handleWeightMatchError}
                weightMatchError={weightMatchError[item.id]}
              />
            ))}
            <ValueRow>
              <ValueLabel>Total Value</ValueLabel>
              <ValueAmount>£{ totalValue.toFixed(2) }</ValueAmount>
            </ValueRow>
            <ButtonParcel onClick={addNewParcel}>Add another parcel</ButtonParcel>
          </ColumnStyled>
          <ColumnStyled sizeL={6} sizeM={6} sizeS={3} sizeXS={4}>
            <ColumnWrapper>
              {
                customs && (
                  <RelativeDiv>
                    <LabelStyled>Export reason</LabelStyled>
                    <SelectInput
                      error={false}
                      resetError={() => {}}
                      value={customsDetails.exportReason}
                      onChange={(selected: any) => handleCustomsChange(selected)}
                      data={exportReasonOptions}
                    />
                    {
                      exportError && (
                        <ErrorText>Required</ErrorText>
                      )
                    }
                  </RelativeDiv>
                )
              }
              <Insurance
                setDone={setInsuranceDone}
                totalValue={totalValue}
                selectedInsurance={selectedInsurance}
                setSelectedInsurance={setSelectedInsurance}
              />
            </ColumnWrapper>
            <ServiceButton
              color="primary"
              block
              disable={disabled}
              disabled={disabled}
              onClick={handleSubmit}
            >
              Choose a service
            </ServiceButton>
          </ColumnStyled>
        </Row>
      </ContainerStyled>
    </>
  );
};

const ContainerStyled = styled(Container)`
  padding: unset;
`;

const ColumnStyled = styled(Column)`
  padding: 0;
  margin-bottom: 16px;

  @media ${device.laptop} {
    margin-bottom: 0;

    &:first-of-type {
      padding-right: 12px;
    }

    &:last-of-type {
      padding-left: 12px;
    }
  }
`;

const PSmallStyled = styled(PSmall)`
  margin-bottom: 32px;
`;

const ValueRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
`;

const ValueLabel = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  margin-bottom: 18px;
`;

const ValueAmount = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 29px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
`;

const LabelStyled = styled(Label)`
  margin-bottom: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

const RelativeDiv = styled.div`
  position: relative;
  margin-bottom: 25px;
`;

const Icon = styled.div`
  display: flex;
  margin-right: 10px;
`;

const AStyled = styled(A)`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`;

const ServiceButton = styled(Button)`
  margin-top: 16px;
  padding: 14px;
`;

const ButtonParcel = styled(A)`
  display: block;
  width: 100%;
  border: 2px solid ${({ theme }: any) => theme.colors.Secondary};
  border-radius: 4px;
  padding: 10px;
  color: ${({ theme }: any) => theme.colors.Secondary};
  background: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
`;

export default ShipmentDetails;
