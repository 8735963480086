import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DialCodes from '../../../app/utils/DialCodes';
import { SelectInput } from '../../../app/components/Inputs';
import { Caption, Input } from '../../elements';

const MobileNumber = ({
  country,
  value,
  onChange,
  error,
}: any) => {
  const [tempC, setTempC] = useState('');

  const [mobile, setMobile] = useState<any>({
    code: '',
    number: '',
  });

  const [localError, setLocalError] = useState(false);

  useEffect(() => {
    let tempCountry = 'GB';

    const temp = value.split(' ');

    if (!temp[0]) {
      tempCountry = DialCodes.filter((code: any) => country === code.country)[0]?.value;
    } else {
      tempCountry = DialCodes.filter((code: any) => temp[0] === code.value.split(' ')[1])[0]?.value;
    }

    if (tempCountry) {
      setTempC(tempCountry.split(' ')[0]);
    }

    setMobile({
      code: tempCountry,
      number: temp[1] || value,
    });
  }, [country, value]);

  const changeNumber = (key: any, v: any) => {
    if (key === 'number' && v !== '' && !v.match(/^[0-9]+$/)) {
      setLocalError(true);
      return;
    }

    setLocalError(false);

    if (key === 'code') {
      setTempC(v);

      handleOnChange(v, null);
    }

    if (key === 'number') {
      handleOnChange(null, v);
    }

    setMobile({
      ...mobile,
      [key]: v,
    });
  };

  const handleBlur = () => {
    setLocalError(false);

    handleOnChange();
  };

  const handleOnChange = (code = null, number: any = null) => {
    const tempCode = code || mobile.code;
    const tempNumber = number || mobile.number;

    if (!tempCode || !tempNumber) {
      onChange('');
    } else {
      onChange(`${tempCode.split(' ')[1] || tempCode} ${tempNumber}`);
    }
  };

  return (
    <div>
      <Wrapper>
        <SelectWrapper>
          <SelectInput
            data={DialCodes}
            error={false}
            value={DialCodes.filter((code: any) => tempC === code.country)[0]?.value}
            height="48px"
            onChange={(selected: string) =>
              changeNumber('code', selected)}
            minWidth="120px"
            width="120px"
          />
        </SelectWrapper>
        <InputWrapper>
          <Input
            placeholder="Mobile phone number"
            value={mobile.number}
            onChange={(e: any) => changeNumber('number', e.target.value)}
            onBlur={handleBlur}
            theme={null}
            type="text"
            error={error}
            autoComplete="off"
          />
        </InputWrapper>
      </Wrapper>
      <ErrorText>{ error }</ErrorText>
      {
        localError && (
          <ErrorText>This field only accepts numbers.</ErrorText>
        )
      }
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: block;
  margin-top: 4px;
`;

const InputWrapper = styled.div`
  margin-left: 8px;
  width: 100%;
`;

const SelectWrapper = styled.div`
  width: 120px;
`;

export default MobileNumber;
