import React from 'react';
import { withTheme } from 'styled-components';

const TrackingTruck = ({ theme }: any) => {
  return (
    <svg width="88" height="89" viewBox="0 0 88 89" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.25 16.6309H4.125C3.36875 16.6309 2.75 17.2496 2.75 18.0059V75.7559C2.75 76.5121 3.36875 77.1309 4.125 77.1309H11.1031C11.8594 82.3902 16.7234 86.034 21.9828 85.2777C26.2109 84.6762 29.5281 81.359 30.1297 77.1309H57.8531C58.6094 82.3902 63.4734 86.034 68.7328 85.2777C72.9609 84.6762 76.2781 81.359 76.8797 77.1309H83.875C84.6312 77.1309 85.25 76.5121 85.25 75.7559V48.2559C85.25 48.0496 85.1984 47.8262 85.1125 47.6371L75.4875 28.3871C75.2469 27.923 74.7656 27.6309 74.25 27.6309H57.75V18.0059C57.75 17.2496 57.1312 16.6309 56.375 16.6309H52.25H8.25ZM73.4078 30.3809L81.6578 46.8809H57.75V30.3809H73.4078ZM55 60.6309H5.5V55.1309H55V60.6309ZM35.75 19.3809H49.5H35.75ZM11 19.3809H24.75H11ZM49.5 16.6309H35.75H49.5ZM24.75 16.6309H11H24.75ZM52.25 19.3809H55V52.3809H5.5V19.3809H8.25H52.25ZM20.625 82.6309C16.8266 82.6309 13.75 79.5543 13.75 75.7559C13.75 71.9574 16.8266 68.8809 20.625 68.8809C24.4234 68.8809 27.5 71.9574 27.5 75.7559C27.5 79.5543 24.4234 82.6309 20.625 82.6309ZM30.1469 74.3809C29.3906 69.1215 24.5266 65.4777 19.2672 66.234C15.0391 66.8355 11.7219 70.1527 11.1203 74.3809H5.5V63.3809H55V74.3809H30.1469ZM67.375 82.6309C63.5766 82.6309 60.5 79.5543 60.5 75.7559C60.5 71.9574 63.5766 68.8809 67.375 68.8809C71.1734 68.8809 74.25 71.9574 74.25 75.7559C74.25 79.5543 71.1734 82.6309 67.375 82.6309ZM76.8969 74.3809C76.1406 69.1215 71.2766 65.4777 66.0172 66.234C61.7891 66.8355 58.4719 70.1527 57.8703 74.3809H57.75V49.6309H82.5V74.3809H76.8969Z"
        fill={theme.colors.Primary}
      />
      <path d="M66 52.3809H60.5V55.1309H66V52.3809Z" fill={theme.colors.Primary} />
      <path
        d="M20.625 71.6309C18.3391 71.6309 16.5 73.4699 16.5 75.7559C16.5 78.0418 18.3391 79.8809 20.625 79.8809C22.9109 79.8809 24.75 78.0418 24.75 75.7559C24.75 73.4699 22.9109 71.6309 20.625 71.6309ZM20.625 77.1309C19.8688 77.1309 19.25 76.5121 19.25 75.7559C19.25 74.9996 19.8688 74.3809 20.625 74.3809C21.3812 74.3809 22 74.9996 22 75.7559C22 76.5121 21.3812 77.1309 20.625 77.1309Z"
        fill={theme.colors.Primary}
      />
      <path
        d="M67.375 71.6309C65.0891 71.6309 63.25 73.4699 63.25 75.7559C63.25 78.0418 65.0891 79.8809 67.375 79.8809C69.6609 79.8809 71.5 78.0418 71.5 75.7559C71.5 73.4699 69.6609 71.6309 67.375 71.6309ZM67.375 77.1309C66.6188 77.1309 66 76.5121 66 75.7559C66 74.9996 66.6188 74.3809 67.375 74.3809C68.1312 74.3809 68.75 74.9996 68.75 75.7559C68.75 76.5121 68.1312 77.1309 67.375 77.1309Z"
        fill={theme.colors.Primary}
      />
      <path
        d="M27.7244 41.2792C27.7144 40.9182 27.7094 40.6475 27.7094 40.467C27.7094 39.4041 27.8598 38.4866 28.1606 37.7145C28.3812 37.1329 28.7372 36.5463 29.2285 35.9547C29.5895 35.5235 30.2363 34.8968 31.1688 34.0745C32.1114 33.2423 32.7231 32.5804 33.0038 32.0891C33.2846 31.5978 33.425 31.0613 33.425 30.4797C33.425 29.4268 33.0139 28.5043 32.1916 27.7122C31.3694 26.91 30.3616 26.5089 29.1684 26.5089C28.0152 26.5089 27.0526 26.8699 26.2805 27.5918C25.5084 28.3138 25.002 29.4419 24.7614 30.9761L21.9788 30.6452C22.2294 28.5896 22.9715 27.0153 24.2048 25.9223C25.4482 24.8293 27.0877 24.2828 29.1233 24.2828C31.2791 24.2828 32.9988 24.8694 34.2823 26.0426C35.5658 27.2158 36.2076 28.6347 36.2076 30.2992C36.2076 31.2618 35.982 32.1493 35.5307 32.9615C35.0795 33.7737 34.1971 34.7614 32.8835 35.9246C32.0011 36.7067 31.4245 37.2833 31.1538 37.6543C30.8831 38.0253 30.6825 38.4515 30.5521 38.9328C30.4218 39.4141 30.3466 40.1962 30.3265 41.2792H27.7244ZM27.559 46.709V43.6256H30.6424V46.709H27.559Z"
        fill={theme.colors.Primary}
      />
    </svg>
  );
};

export default withTheme(TrackingTruck);
