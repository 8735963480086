import React, { useEffect } from 'react';
import { PSmall, A } from '../../../assets/elements';
import styled from 'styled-components';

const MPDTracking = () => {
  useEffect(() => {
    (function (e, t, n) {
      const i = e.getElementsByTagName(t)[0];
      if (e.getElementById(n)) return;
      const r = e.createElement(t);
      r.id = n;
      // @ts-ignore
      r.src = 'https://button.aftership.com/all.js';
      // @ts-ignore
      i.parentNode.insertBefore(r, i);
    })(document, 'script', 'aftership-jssdk');
  });

  const handleGetInTouch = () => {
    window.open('https://help.myparceldelivery.com/', '_blank');
  };

  return (
    <>
      <Information>
        This order was placed before we updated our system.
        Please use the form below to get your tracking information.
      </Information>
      <div
        className="as-track-button"
        data-size="medium"
        data-domain="egistics.aftership.com/?page-name=mpd"
        data-look-up-option="order-number-and-email"
      />
      <NoInformation>
        No tracking information? <A onClick={handleGetInTouch}>Get in touch</A>
      </NoInformation>
    </>
  );
};

const Information = styled(PSmall)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`;

const NoInformation = styled(Information)`
  margin-bottom: 0;
  margin-top: 24px;
`;

export default MPDTracking;
