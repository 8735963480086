import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const OrderNumber = ({ orderNumber }: any) => {
  return (
    <Wrapper to={`/track?order-number=${orderNumber}`}>
      Order #: {orderNumber}
    </Wrapper>
  );
};

const Wrapper = styled(Link)`
  padding: 8px 22px;
  background: ${({ theme }: any) => theme.colors.Black};
  color: ${({ theme }: any) => theme.colors.White};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  width: 100%;
  text-decoration: unset;
  flex: 0 0 100%;
`;

export default OrderNumber;
