import React from 'react';
import styled from 'styled-components';

const TableHead = ({ children }: any) => {
  return (
    <Wrapper>
      { children }
    </Wrapper>
  );
};

const Wrapper = styled.thead`
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  border-radius: 8px 8px 0 0;
`;

export default TableHead;
