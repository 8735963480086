import React from 'react';
import styled from 'styled-components';
import Moment from 'react-moment';
import device from '../../../../../../../utils/device';

const CollectionDate = ({ date, title }: any) => {
  return (
    <ParcelTypeContainer>
      <ParcelTypeHead>{title}</ParcelTypeHead>
      <ParcelTypeData>
        <Moment format="ddd D MMM">{date}</Moment>
      </ParcelTypeData>
    </ParcelTypeContainer>
  );
};

const ParcelTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: ${({ theme }: any) => theme.colors.CollectionBackground};
  align-items: center;
  border-radius: 4px;
  height: 100%;
  margin-bottom: 16px;
  width: calc(50% - 4px);
  margin-right: 4px;

  @media ${device.laptop} {
    width: 100%;
    margin-right: 0;
  }
`;

const ParcelTypeHead = styled.div`
  background: ${({ theme }: any) => theme.colors.CollectionHead};;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  color: ${({ theme }: any) => theme.colors.White};
  padding: 7px 4px;
  justify-content: center;
`;

const ParcelTypeData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 19px;
  line-height: 150%;
  font-weight: 500;
  margin-top: 13px;
`;

export default CollectionDate;
