import { createStore, applyMiddleware, compose } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import rootReducer, { AppState } from './reducers/index';
import { persist } from './persist';
import { setCookie } from '../utils/cookies';
// @ts-ignore

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk as ThunkMiddleware<AppState, any>),
    // @ts-ignore no-underscore-dangle
    /* eslint no-underscore-dangle: 0 */
    ((window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION__()) ||
      compose
  )
);

const listener = () => {
  const {
    order: { basket },
  } = store.getState();

  persist(basket, 'order.basket');
  setCookie([{ name: 'basket', value: basket.length }]);
};

store.subscribe(listener);

export default store;
