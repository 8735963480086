import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import DownIcon from '../../../../assets/icons/Down.svg';
import Logout from '../../../../assets/icons/Logout.svg';
import { resetUserSession } from '../../../store/actions';
import { deleteCookie } from '../../../utils/cookies';
import { logoutUser } from '../../../utils/APICalls/Account';
import { landingPageUrl } from '../../../utils/env';

function useOutsideAlerter(ref: any, fn: any) {
  React.useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        fn(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}

const UserNav = ({ logged, active, setActive }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const session = useSelector<any, any>((state: any) => state.user.session);

  const handleClick = (path: any) => {
    setActive(false);
    path && history.push(path);
  };

  const handleLogout = () => {
    logoutUser()
      .then(() => {
        deleteCookie([
          'userId',
          'userName',
          'accessToken',
          'refreshToken',
          'refreshTokenExpiry',
          'accessTokenExpiry',
        ]);
        dispatch(resetUserSession());
        window.location.href = landingPageUrl;
      })
      .finally(() => {
        setActive(false);
      });
  };

  return (
    <Wrapper>
      <UserName onClick={() => setActive(!active)}>
        <p>{session.userName}</p>
        <img src={DownIcon} alt="down" />
      </UserName>
      <OptionsList active={active}>
        <Option onClick={() => handleClick('/dashboard')}>My account</Option>
        <Option onClick={() => handleClick('/settings')}>Settings</Option>
        <Option onClick={handleLogout}>
          Log out
          <OptionImage src={Logout} alt="logout" />
        </Option>
      </OptionsList>
    </Wrapper>
  );
};

export default function OutsideAlerter(props: any) {
  const [active, setActive] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setActive);

  return (
    <div ref={wrapperRef}>
      <UserNav active={active} setActive={setActive} />
    </div>
  );
}

const Wrapper = styled.div`
  display: flex;
  // justify-content: space-between;
  // flex-direction: column;
  width: 160px;
  position: relative;
`;
const UserName = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  border: 2px solid #f2f2f2;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  align-items: center;
  padding: 0 15px;
  height: 50px;
`;
const OptionsList = styled.ul<any>`
  display: ${({ active }: any) => (active ? 'flex' : 'none')};
  justify-content: space-between;
  position: absolute;
  top: 30px;
  flex-direction: column;
  width: 100%;
  top: 47px;
  border-radius: 2px;
  border: 2px solid #f2f2f2;
  border-radius: 0px 0px 4px 4px;
  z-index: 1;
`;
const Option = styled.li`
  width: 100%;
  border-bottom: 2px solid #f2f2f2;
  padding: 15px;
  background-color: white;
  cursor: pointer;
  position: relative;

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background: ${({ theme }: any) => theme.colors.MutedPrimary};
    color: ${({ theme }: any) => theme.colors.Primary};
  }
`;

const OptionImage = styled.img<any>`
  position: absolute;
  right: 15px;
  top: 12px;
`;
