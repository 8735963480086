import React from 'react';
import { withTheme } from 'styled-components';
import './QuoteLoading.css';

const QuoteLoading = ({
  theme,
}: any) => {
  return (
    <svg
      id="quoteloader"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 300"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="168"
      height="168"
    >

      <g id="quoteloader-ship_to" transform="translate(388.125062,195.313848)">
        <g id="quoteloader-ship" transform="scale(-0.603643,0.599074) translate(-151.968745,-147.781006)">
          <path
            id="quoteloader-ship_control"
            d="M88.687500,72.500000L32.437500,72.500000L32.437500,87.250000L18.375000,87.250000L18.375000,112.625000L32.437500,122L32.437500,168.875000L88.687500,168.875000L88.687500,122L102.750000,112.625000L102.750000,87.250000L88.687500,87.250000L88.687500,72.500000Z"
            clipRule="evenodd"
            fill="rgb(205,205,205)"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <path
            id="quoteloader-ship_body"
            d="M285.562000,154.812000L285.562000,167.885000C285.563000,178.283000,282.996000,188.520000,278.090000,197.688000C273.183000,206.855000,266.089000,214.670000,257.438000,220.437000L257.438000,248.562000L74.625000,248.562000C66.621400,248.567000,58.695500,246.993000,51.300300,243.932000C43.905200,240.871000,37.185800,236.383000,31.526400,230.724000C25.867000,225.064000,21.378600,218.345000,18.317700,210.950000C15.256700,203.555000,13.683400,195.629000,13.687500,187.625000L13.687500,154.812000L37.125000,154.812000L51.187500,168.875000L210.562000,168.875000L229.312000,154.812000L285.562000,154.812000Z"
            fill={theme.colors.Primary}
            stroke="none"
            strokeWidth="1"
          />
          <path
            id="quoteloader-ship_bottom"
            d="M257.438000,220.438000L257.438000,248.562000L74.625000,248.562000C64.389900,248.568000,54.318400,245.994000,45.341100,241.079000C36.363700,236.163000,28.770100,229.064000,23.261700,220.438000L257.438000,220.438000Z"
            fill="rgb(68,72,77)"
            stroke="none"
            strokeWidth="1"
          />
          <path
            id="quoteloader-ship_crank"
            d="M249.234000,61.062500L252.750000,47L276.188000,47L279.703000,61.062500L290.250000,61.062500L290.250000,70.437500L272.240000,70.437500L285.562000,154.812000L257.438000,154.812000L257.438000,70.437500L238.688000,70.437500L238.688000,61.062500L249.234000,61.062500Z"
            clipRule="evenodd"
            fill="rgb(123,130,138)"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <path
            id="quoteloader-ship_window"
            d="M55.875000,117.312000L46.500000,117.312000L46.500000,126.688000L55.875000,126.688000L55.875000,117.312000ZM74.625000,117.312000L65.250000,117.312000L65.250000,126.688000L74.625000,126.688000L74.625000,117.312000ZM46.500000,136.062000L55.875000,136.062000L55.875000,145.438000L46.500000,145.438000L46.500000,136.062000ZM74.625000,136.062000L65.250000,136.062000L65.250000,145.438000L74.625000,145.438000L74.625000,136.062000Z"
            clipRule="evenodd"
            fill="rgb(255,255,255)"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
          <path
            id="quoteloader-ship_container2"
            d="M257.438000,168.875000L257.438000,112.625000L201.188000,112.625000L201.188000,84.500000L144.938000,84.500000L144.938000,112.625000L88.687500,112.625000L88.687500,168.875000L257.438000,168.875000Z"
            fill="rgb(233,233,233)"
            stroke="none"
            strokeWidth="1"
          />
          <path
            id="quoteloader-ship_container1"
            d="M205.875000,136.062000L205.875000,112.625000L201.188000,112.625000L201.188000,103.250000L144.938000,103.250000L144.938000,112.625000L140.250000,112.625000L140.250000,136.062000L88.687500,136.062000L88.687500,145.438000L140.250000,145.438000L140.250000,168.875000L149.625000,168.875000L149.625000,145.438000L196.500000,145.438000L196.500000,168.875000L205.875000,168.875000L205.875000,145.438000L257.438000,145.438000L257.438000,136.062000L205.875000,136.062000ZM149.625000,136.062000L149.625000,112.625000L196.500000,112.625000L196.500000,136.062000L149.625000,136.062000Z"
            fill="rgb(222,222,222)"
            stroke="none"
            strokeWidth="1"
          />
        </g>
      </g>
      <g id="quoteloader-truck_to" transform="translate(-52.313259,255.715110)">
        <g id="quoteloader-truck" transform="scale(-0.380000,0.370000) translate(-147.626935,-171.151855)">
          <path
            id="quoteloader-truck_body"
            d="M285.254000,80.982700L104.916000,80.982700L104.916000,232.847000L285.254000,232.847000L285.254000,80.982700Z"
            fill="rgb(233,233,233)"
            stroke="none"
            strokeWidth="1"
          />
          <path
            id="quoteloader-truck_front"
            d="M9.999860,185.389000L9.999860,232.847000L104.915000,232.847000L104.915000,118.949000L58.578000,118.949000C56.815300,118.949000,55.087400,119.440000,53.588000,120.366000C52.088500,121.293000,50.876700,122.619000,50.088500,124.196000L28.982900,166.406000L13.796500,177.796000C12.617700,178.680000,11.660900,179.827000,11.001900,181.145000C10.342900,182.463000,9.999860,183.916000,9.999860,185.389000Z"
            fill={theme.colors.Primary}
            stroke="none"
            strokeWidth="1"
          />
          <path
            id="quoteloader-truck_tyre2"
            d="M237.797000,261.321000C222.071000,261.321000,209.322000,248.573000,209.322000,232.847000C209.322000,217.121000,222.071000,204.372000,237.797000,204.372000C253.523000,204.372000,266.271000,217.121000,266.271000,232.847000C266.271000,248.573000,253.523000,261.321000,237.797000,261.321000Z"
            fill="rgb(96,102,109)"
            stroke="none"
            strokeWidth="1"
          />
          <path
            id="quoteloader-truck_wheel2"
            d="M237.797000,242.338000C232.555000,242.338000,228.306000,238.089000,228.306000,232.847000C228.306000,227.605000,232.555000,223.355000,237.797000,223.355000C243.039000,223.355000,247.289000,227.605000,247.289000,232.847000C247.289000,238.089000,243.039000,242.338000,237.797000,242.338000Z"
            fill="rgb(223,223,221)"
            stroke="none"
            strokeWidth="1"
          />
          <path
            id="quoteloader-truck_tyre1"
            d="M62.203700,261.321000C46.477600,261.321000,33.729100,248.573000,33.729100,232.847000C33.729100,217.121000,46.477600,204.372000,62.203700,204.372000C77.929700,204.372000,90.678200,217.121000,90.678200,232.847000C90.678200,248.573000,77.929700,261.321000,62.203700,261.321000Z"
            fill="rgb(96,102,109)"
            stroke="none"
            strokeWidth="1"
          />
          <path
            id="quoteloader-truck_wheel1"
            d="M62.203800,242.338000C56.961800,242.338000,52.712300,238.089000,52.712300,232.847000C52.712300,227.605000,56.961800,223.355000,62.203800,223.355000C67.445800,223.355000,71.695300,227.605000,71.695300,232.847000C71.695300,238.089000,67.445800,242.338000,62.203800,242.338000Z"
            fill="rgb(223,223,221)"
            stroke="none"
            strokeWidth="1"
          />
          <path
            id="quoteloader-truck_window"
            d="M28.983800,166.406000L76.441400,166.406000L76.441400,137.932000L43.221100,137.932000L28.983800,166.406000Z"
            fill="rgb(255,255,255)"
            stroke="none"
            strokeWidth="1"
          />
          <path
            id="quoteloader-truck_light"
            d="M26.610500,194.881000L10.000400,194.881000L10.000400,213.864000L26.610500,213.864000C27.239800,213.864000,27.843400,213.614000,28.288400,213.169000C28.733400,212.724000,28.983400,212.120000,28.983400,211.491000L28.983400,197.254000C28.983400,196.624000,28.733400,196.021000,28.288400,195.576000C27.843400,195.131000,27.239800,194.881000,26.610500,194.881000Z"
            fill="rgb(245,245,245)"
            stroke="none"
            strokeWidth="1"
          />
        </g>
      </g>
      <g id="quoteloader-plane_to" transform="translate(-90.184844,129.803725)">
        <g id="quoteloader-plane_ts" transform="rotate(-19.314938) scale(0.603868,0.600000)">
          <g id="quoteloader-plane" transform="translate(-150,-149.654999)">
            <path
              id="quoteloader-plane_body"
              d="M258.621000,185.862000L41.379300,185.862000C18.526300,185.862000,0,167.336000,0,144.483000L0,92.758800L263.793000,92.758800L300,128.966000L300,144.483000C300,167.336000,281.474000,185.862000,258.621000,185.862000Z"
              fill="rgb(233,233,233)"
              stroke="none"
              strokeWidth="1"
            />
            <path
              id="quoteloader-plane_tail"
              d="M51.241400,92.758700L0,92.758700L0,25.517300L25.862100,25.517300L51.241400,92.758700Z"
              fill={theme.colors.Primary}
              stroke="none"
              strokeWidth="1"
            />
            <path
              id="quoteloader-plane_wing2"
              d="M134.483000,289.310000L109.104000,289.310000L109.104000,154.828000L196.552000,154.828000L134.483000,289.310000Z"
              fill={theme.colors.Primary}
              stroke="none"
              strokeWidth="1"
            />
            <path
              id="quoteloader-plane_wing1"
              d="M134.483000,10L109.104000,10L109.104000,92.758600L186.207000,92.758600L134.483000,10Z"
              fill={theme.colors.Primary}
              stroke="none"
              strokeWidth="1"
            />
            <path
              id="quoteloader-plane_windows"
              d="M93.103400,113.448000L51.724100,113.448000L51.724100,134.138000L93.103400,134.138000L93.103400,113.448000ZM155.172000,113.448000L113.793000,113.448000L113.793000,134.138000L155.172000,134.138000L155.172000,113.448000ZM175.862000,113.448000L217.242000,113.448000L217.242000,134.138000L175.862000,134.138000L175.862000,113.448000ZM300,134.138000L248.276000,134.138000L248.276000,113.448000L284.483000,113.448000L300,128.966000L300,134.138000Z"
              clipRule="evenodd"
              fill="rgb(255,255,255)"
              fillRule="evenodd"
              stroke="none"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
      <g id="quoteloader-road2" opacity="0">
        <path
          id="quoteloader-path1"
          d="M247.289000,57.253700L180.848000,57.253700L180.848000,66.745200L247.289000,66.745200L247.289000,57.253700Z"
          transform="matrix(1 0 0 1 -20 0)"
          fill="rgb(239,98,36)"
          stroke="none"
          strokeWidth="1"
        />
        <path
          id="quoteloader-path2"
          d="M161.865000,247.084000L104.916000,247.084000L104.916000,256.576000L161.865000,256.576000L161.865000,247.084000Z"
          transform="matrix(1 0 0 1 17 0)"
          fill="rgb(239,98,36)"
          stroke="none"
          strokeWidth="1"
        />
        <path
          id="quoteloader-path3"
          d="M271.017000,38.270800L214.068000,38.270800L214.068000,47.762300L271.017000,47.762300L271.017000,38.270800Z"
          fill="rgb(239,98,36)"
          stroke="none"
          strokeWidth="1"
        />
      </g>
      <g id="quoteloader-sea2" transform="matrix(1 0 0 1 6 0)" opacity="0">
        <path
          id="quoteloader-path4"
          d="M9,243.875000L23.261700,243.875000L23.261700,253.250000L9,253.250000L9,243.875000Z"
          fill="rgb(239,98,36)"
          stroke="none"
          strokeWidth="1"
        />
        <path
          id="quoteloader-path5"
          d="M27.750000,243.875000L271.500000,243.875000L271.500000,253.250000L27.750000,253.250000L27.750000,243.875000Z"
          transform="matrix(1 0 0 1 6 0)"
          fill="rgb(239,98,36)"
          stroke="none"
          strokeWidth="1"
        />
      </g>
      <g
        id="quoteloader-air2"
        transform="matrix(0.91354545764260 -0.40673664307580 0.40673664307580 0.91354545764260 -8.44660898032248 45.70258619296450)"
        opacity="0"
      >
        <path
          id="quoteloader-path6"
          d="M36.207000,10L70.018257,10L70.018257,20.344800L36.207000,20.344800L36.207000,10Z"
          transform="matrix(1 0 0 1 19.53528040413784 10.72218510986851)"
          fill="rgb(239,98,36)"
          stroke="none"
          strokeWidth="1"
        />
        <path
          id="quoteloader-path7"
          d="M56.896500,30.689700L77.586100,30.689700L77.586100,41.034500L56.896500,41.034500L56.896500,30.689700Z"
          transform="matrix(1 0 0 1 -11.60664870712949 -28.43868579844870)"
          fill="rgb(239,98,36)"
          stroke="none"
          strokeWidth="1"
        />
        <path
          id="quoteloader-path8"
          d="M36.207000,253.104000L77.586300,253.104000L77.586300,263.448000L36.207000,263.448000L36.207000,253.104000Z"
          fill="rgb(239,98,36)"
          stroke="none"
          strokeWidth="1"
        />
        <path
          id="quoteloader-path9"
          d="M56.896500,273.793000L77.586100,273.793000L77.586100,284.138000L56.896500,284.138000L56.896500,273.793000Z"
          transform="matrix(1 0 0 1 10.99479570767124 -1.41026493731619)"
          fill="rgb(239,98,36)"
          stroke="none"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default withTheme(QuoteLoading);
