import React from 'react';
import { Helmet } from 'react-helmet';
import { environment } from './env';

const metas = {
  staging: (
    <Helmet>
      <title>Haulable</title>
      <meta
        name="description"
        content="Cheap parcel delivery and pallet collection service in the UK. Use our online system to easily book and track packages. Get a quote today!"
      />

      <meta itemProp="name" content="Haulable: Cheap Parcel Delivery"/>
      <meta
        itemProp="description"
        content="Cheap parcel delivery and pallet collection service in the UK. Use our online system to easily book and track packages. Get a quote today!"
      />
      <meta
        itemProp="image"
        content="https://uploads-ssl.webflow.com/5f6469efdb39940c338336c0/5f97f7ab84eaeb85f1da6080_Hero%201440.jpg"
      />

      <meta property="og:url" content="http://app.collectmyparcel.com"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content="Haulable: Cheap Parcel Delivery"/>
      <meta
        property="og:description"
        content="Cheap parcel delivery and pallet collection service in the UK. Use our online system to easily book and track packages. Get a quote today!"
      />
      <meta
        property="og:image"
        content="https://uploads-ssl.webflow.com/5f6469efdb39940c338336c0/5f97f7ab84eaeb85f1da6080_Hero%201440.jpg"
      />

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content="Haulable: Cheap Parcel Delivery"/>
      <meta
        name="twitter:description"
        content="Cheap parcel delivery and pallet collection service in the UK. Use our online system to easily book and track packages. Get a quote today!"
      />
      <meta
        name="twitter:image"
        content="https://uploads-ssl.webflow.com/5f6469efdb39940c338336c0/5f97f7ab84eaeb85f1da6080_Hero%201440.jpg"
      />

    </Helmet>
  ),
  cmp: (
    <Helmet>
      <title>collectmyparcel: Cheap Parcel Delivery</title>
      <meta
        name="description"
        content="Cheap parcel delivery and pallet collection service in the UK. Use our online system to easily book and track packages. Get a quote today!"
      />

      <meta itemProp="name" content="collectmyparcel: Cheap Parcel Delivery"/>
      <meta
        itemProp="description"
        content="Cheap parcel delivery and pallet collection service in the UK. Use our online system to easily book and track packages. Get a quote today!"
      />
      <meta
        itemProp="image"
        content="https://uploads-ssl.webflow.com/5f6469efdb39940c338336c0/5f97f7ab84eaeb85f1da6080_Hero%201440.jpg"
      />

      <meta property="og:url" content="http://app.collectmyparcel.com"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content="collectmyparcel: Cheap Parcel Delivery"/>
      <meta
        property="og:description"
        content="Cheap parcel delivery and pallet collection service in the UK. Use our online system to easily book and track packages. Get a quote today!"
      />
      <meta
        property="og:image"
        content="https://uploads-ssl.webflow.com/5f6469efdb39940c338336c0/5f97f7ab84eaeb85f1da6080_Hero%201440.jpg"
      />

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content="collectmyparcel: Cheap Parcel Delivery"/>
      <meta
        name="twitter:description"
        content="Cheap parcel delivery and pallet collection service in the UK. Use our online system to easily book and track packages. Get a quote today!"
      />
      <meta
        name="twitter:image"
        content="https://uploads-ssl.webflow.com/5f6469efdb39940c338336c0/5f97f7ab84eaeb85f1da6080_Hero%201440.jpg"
      />

    </Helmet>
  ),
  pallets4delivery: (
    <Helmet>
      <title> Pallets4Delivery.com - Cheap UK and European pallet delivery </title>
      <meta
        name="description"
        content="Get an instant quote online or over the phone. Best rates on pallet delivery services across the UK and Europe. Freight collections from just £30"
      />

      <meta itemProp="name" content="Pallets4Delivery.com - Cheap UK and European pallet delivery"/>
      <meta
        itemProp="description"
        content="Get an instant quote online or over the phone. Best rates on pallet delivery services across the UK and Europe. Freight collections from just £30"
      />
      <meta
        itemProp="image"
        content="https://uploads-ssl.webflow.com/5fb29d39d78ba4bd5adf7fe5/5fb3e249103f7269b8718be1_PLT_Hero.png"
      />

      <meta property="og:url" content="https://pallets4delivery.com"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content="Pallets4Delivery.com - Cheap UK and European pallet delivery"/>
      <meta
        property="og:description"
        content="Get an instant quote online or over the phone. Best rates on pallet delivery services across the UK and Europe. Freight collections from just £30"
      />
      <meta
        property="og:image"
        content="https://uploads-ssl.webflow.com/5fb29d39d78ba4bd5adf7fe5/5fb3e249103f7269b8718be1_PLT_Hero.png"
      />

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content="Pallets4Delivery.com - Cheap UK and European pallet delivery"/>
      <meta
        name="twitter:description"
        content="Get an instant quote online or over the phone. Best rates on pallet delivery services across the UK and Europe. Freight collections from just £30"
      />
      <meta
        name="twitter:image"
        content="https://uploads-ssl.webflow.com/5fb29d39d78ba4bd5adf7fe5/5fb3e249103f7269b8718be1_PLT_Hero.png"
      />
    </Helmet>
  ),
  mpd: (
    <Helmet>
      <title>Parcel Delivery UK | My Parcel Delivery Courier Service</title>
      <meta
        name="description"
        content="Parcel Delivery made easy. Get a quote online, choose from a wide range of courier services, book in just a few clicks and send securely."
      />

      <meta itemProp="name" content="Parcel Delivery UK | My Parcel Delivery Courier Service"/>
      <meta
        itemProp="description"
        content="Parcel Delivery made easy. Get a quote online, choose from a wide range of courier services, book in just a few clicks and send securely."
      />
      <meta
        itemProp="image"
        content="https://uploads-ssl.webflow.com/5fb23f7034563f34ad46ec82/5fb2673041beeb9520e58a11_MPD-SEO-parcel.png"
      />

      <meta property="og:url" content="https://www.myparceldelivery.com"/>
      <meta property="og:type" content="website"/>
      <meta
        property="og:title"
        content="Parcel Delivery UK | My Parcel Delivery Courier Service"
      />
      <meta
        property="og:description"
        content="Parcel Delivery made easy. Get a quote online, choose from a wide range of courier services, book in just a few clicks and send securely."
      />
      <meta
        property="og:image"
        content="https://uploads-ssl.webflow.com/5fb23f7034563f34ad46ec82/5fb2673041beeb9520e58a11_MPD-SEO-parcel.png"
      />

      <meta name="twitter:card" content="summary_large_image"/>
      <meta
        name="twitter:title"
        content="Parcel Delivery UK | My Parcel Delivery Courier Service"
      />
      <meta
        name="twitter:description"
        content="Parcel Delivery made easy. Get a quote online, choose from a wide range of courier services, book in just a few clicks and send securely."
      />
      <meta
        name="twitter:image"
        content="https://uploads-ssl.webflow.com/5fb23f7034563f34ad46ec82/5fb2673041beeb9520e58a11_MPD-SEO-parcel.png"
      />
    </Helmet>
  ),
  p4d: (
    <Helmet>
      <title>P4D: Major couriers at discounted prices | International, Pallets</title>
      <meta
        name="description"
        content="Cheaper courier services with DHL, TNT, Parcelforce and more. Worldwide shipping. Imports. Same Day parcel delivery - book online now! Official reseller."
      />

      <meta itemProp="name" content="P4D: Major couriers at discounted prices | International, Pallets"/>
      <meta
        itemProp="description"
        content="Cheaper courier services with DHL, TNT, Parcelforce and more. Worldwide shipping. Imports. Same Day parcel delivery - book online now! Official reseller."
      />
      <meta
        itemProp="image"
        content="https://uploads-ssl.webflow.com/5fb578f0e022587cb8cd260b/5fb57a454bc81011aeac04a5_p4d-hero.png"
      />

      <meta property="og:url" content="http://www.p4d.co.uk"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content="P4D: Major couriers at discounted prices | International, Pallets"/>
      <meta
        property="og:description"
        content="Cheaper courier services with DHL, TNT, Parcelforce and more. Worldwide shipping. Imports. Same Day parcel delivery - book online now! Official reseller."
      />
      <meta
        property="og:image"
        content="https://uploads-ssl.webflow.com/5fb578f0e022587cb8cd260b/5fb57a454bc81011aeac04a5_p4d-hero.png"
      />

      <meta name="twitter:card" content="summary_large_image"/>
      <meta
        name="twitter:title"
        content="P4D: Major couriers at discounted prices | International, Pallets"
      />
      <meta
        name="twitter:description"
        content="Cheaper courier services with DHL, TNT, Parcelforce and more. Worldwide shipping. Imports. Same Day parcel delivery - book online now! Official reseller."
      />
      <meta
        name="twitter:image"
        content="https://uploads-ssl.webflow.com/5fb578f0e022587cb8cd260b/5fb57a454bc81011aeac04a5_p4d-hero.png"
      />
    </Helmet>
  ),
  arw: (
    <Helmet>
      <title>Action Roadways</title>
      <meta name="description" content=""/>

      <meta itemProp="name" content="Action Roadways"/>
      <meta itemProp="description" content=""/>
      <meta itemProp="image" content=""/>

      <meta property="og:url" content="https://action-roadways.co.uk"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content="Action Roadways"/>
      <meta property="og:description" content=""/>
      <meta property="og:image" content=""/>

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content="Action Roadways"/>
      <meta name="twitter:description" content=""/>
      <meta name="twitter:image" content=""/>
    </Helmet>
  ),
  auc: (
    <Helmet>
      <title>Auction deliveries: Cheap Parcel Delivery</title>
      <meta
        name="description"
        content="Cheap parcel delivery and pallet collection service in the UK. Use our online system to easily book and track packages. Get a quote today!"
      />

      <meta itemProp="name" content="collectmyparcel: Cheap Parcel Delivery"/>
      <meta
        itemProp="description"
        content="Cheap parcel delivery and pallet collection service in the UK. Use our online system to easily book and track packages. Get a quote today!"
      />
      <meta
        itemProp="image"
        content="https://uploads-ssl.webflow.com/5f6469efdb39940c338336c0/5f97f7ab84eaeb85f1da6080_Hero%201440.jpg"
      />

      <meta property="og:url" content="http://app.collectmyparcel.com"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content="collectmyparcel: Cheap Parcel Delivery"/>
      <meta
        property="og:description"
        content="Cheap parcel delivery and pallet collection service in the UK. Use our online system to easily book and track packages. Get a quote today!"
      />
      <meta
        property="og:image"
        content="https://uploads-ssl.webflow.com/5f6469efdb39940c338336c0/5f97f7ab84eaeb85f1da6080_Hero%201440.jpg"
      />

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content="collectmyparcel: Cheap Parcel Delivery"/>
      <meta
        name="twitter:description"
        content="Cheap parcel delivery and pallet collection service in the UK. Use our online system to easily book and track packages. Get a quote today!"
      />
      <meta
        name="twitter:image"
        content="https://uploads-ssl.webflow.com/5f6469efdb39940c338336c0/5f97f7ab84eaeb85f1da6080_Hero%201440.jpg"
      />

    </Helmet>
  ),
};

export const metaTags = metas[environment];
