import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { A, PSmall } from '../../../../../../assets/elements';
import Discount from '../../../../../../assets/icons/icon_discount.svg';
import Modal from '../../../../../../assets/Combos/Modal';
import { companyName, supportUrl } from '../../../../../utils/env';
import { getAccountDiscount } from '../../../../../utils/APICalls/Account';

const AccountDiscount = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [discount, setDiscount] = useState({
    value: 0,
    applicableFrom: '',
  });

  const handleContact = () => {
    window.open(supportUrl, '_blank');
  };

  useEffect(() => {
    getAccountDiscount()
      .then((res: any) => {
        const { value = 0, updatedAt: applicableFrom = '' } = res.data;

        if (value > 0) {
          setDiscount({
            value,
            applicableFrom,
          });
        }
      })
      .catch(() => {
        // Error
      });
  }, []);

  if (discount.value === 0) {
    return <></>;
  }

  return (
    <>
      <Wrapper>
        <DiscountIcon src={Discount} alt="Discount" />
        <DiscountContent>
          <DiscountHead>{ discount.value }% Discount applied</DiscountHead>
          <DiscountText>
            Applicable from: { moment(discount.applicableFrom).format('YYYY-MM-DD') || 'N/A' }
          </DiscountText>
          <DiscountLink onClick={() => setModalOpen(true)}>More info</DiscountLink>
        </DiscountContent>
      </Wrapper>

      <Modal
        title="More information"
        open={modalOpen}
        close={() => setModalOpen(false)}
        back={false}
        width={6}
      >
        <ModalText>
          Your business discounts will be applied across all services on { companyName }.
        </ModalText>
        <ModalText>
          You will be able to see your discount applied when you get a quote.
          This will also be documented in your invoices.
        </ModalText>
        <ModalText>
          We can offer bespoke rates for high volume business senders as well as pallet and freight orders.
          To discuss please <A onClick={handleContact}>get in touch</A>.
        </ModalText>
      </Modal>
    </>
  );
};

const Wrapper = styled.div`
  margin-top: 16px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border: 1px solid ${({ theme }: any) => theme.colors.Primary};
  border-radius: 4px;
  background: transparent;
`;

const DiscountIcon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;

const DiscountContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  align-items: flex-start;
`;

const DiscountHead = styled.p`
  font-weight: 600;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  margin-bottom: 4px;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const DiscountText = styled.p`
  font-weight: normal;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  margin-bottom: 4px;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const DiscountLink = styled(A)`
  font-weight: 500;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
`;

const ModalText = styled(PSmall)`
  margin-bottom: 8px;
`;

export default AccountDiscount;
