import React from 'react';
import styled from 'styled-components';
import { P } from '../../../assets/elements';
import Dots from '../../../assets/elements/Dots/index';

const Loader = ({ children }: any) => {
  return (
    <Wrapper>
      <PStyled>
        {children}
        <Dots />
      </PStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-contents: center;
  justify-content: center;
  width: 100%;
  padding: 24px;
  background: ${({ theme }: any) => theme.colors.White};
  margin: 16px 0;
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};
`;

const PStyled = styled(P)`
  color: ${({ theme }: any) => theme.colors.Primary};

  &::after {
    display: inline-block;
    width: 10px;
  }
`;

export default Loader;
