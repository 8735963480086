import styled from 'styled-components';
import device from '../../../../app/utils/device';

export const P = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }: any) => theme.typography.Paragraph_L_FS};
  line-height: ${({ theme }: any) => theme.typography.LineHeightParagraphs};

  @media ${device.laptopL} {
    font-size: ${({ theme }: any) => theme.typography.Paragraph_L_FS};
    line-height: ${({ theme }: any) => theme.typography.LineHeightParagraphs};
  }
`;

export const PSmall = styled.p`
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }: any) => theme.colors.Black};
  font-size: ${({ theme }: any) => theme.typography.Paragraph_M_FS};
  line-height: ${({ theme }: any) => theme.typography.LineHeightParagraphs};
`;

export const Caption = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }: any) => theme.typography.Caption_FS};
  line-height: ${({ theme }: any) => theme.typography.LineHeightCaptions};
`;

export const Lead = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 150%;
  color: ${({ theme }: any) => theme.colors.Black};
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media ${device.laptop} {
    font-weight: 500;
    font-size: 24px;
  }
`;
