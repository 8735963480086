import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { landingPageUrl } from '../../utils/env';
import { A, Button, Column, Container, H4, H6, PSmall, Row } from '../../../assets/elements';
import device from '../../utils/device';
import StepOne from './components/StepOne';
import StepTwo from './components/StepTwo';

const MultiBook = () => {
  const session = useSelector((state: any) => state.user.session);

  const [currentStep, setCurrentStep] = useState(1);
  const [rates, setRates] = useState<any>([]);
  const [tempRate, setTempRate] = useState<any>({});
  const [files, setFiles] = useState<any>([]);
  const [rows, setRows] = useState<any>(0);
  const [serviceType, setServiceType] = useState('cheapest');
  const [error, setError] = useState([]);

  if (session.accessToken === '') {
    window.location.href = landingPageUrl;
  }

  const setRatesData = (data: any) => {
    setTempRate(data);
  };

  useEffect(() => {
    if (tempRate?.item) {
      setRates([
        ...rates,
        tempRate,
      ]);
    }
  }, [tempRate]);

  const setNewFiles = (file: any) => {
    setFiles([
      ...files,
      file,
    ]);
  };

  return (
    <Wrapper>
      <ContainerStyled>
        <RowStyled>
          <Column sizeL={6} sizeM={6} sizeS={5} sizeXS={4}>
            <H4Styled>Multi-book</H4Styled>
            <PSmallStyled>
              If you have plenty of parcels to send,
              you don’t want to spend too long keying in the information.
              Our CSV tool is just the ticket to speed up the process and {' '}
              let you get on with your day.
            </PSmallStyled>
          </Column>
        </RowStyled>
        <RowStyled>
          {
            currentStep === 1 &&
              <StepOne
                setRatesData={setRatesData}
                setCurrentStep={setCurrentStep}
                setFiles={setNewFiles}
                rows={rows}
                setRows={setRows}
                serviceType={serviceType}
                setServiceType={setServiceType}
                error={error}
                setImportError={setError}
              />
          }
          {
            currentStep === 2 &&
              <StepTwo
                rates={rates}
                setRates={setRates}
                files={files}
                setCurrentStep={setCurrentStep}
                rows={rows}
                serviceType={serviceType}
                error={error}
              />
          }
        </RowStyled>
      </ContainerStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 76px);
  background-color: #EEEEEE;
  flex-direction: column;
`;

const ContainerStyled = styled(Container)`
  padding: 40px 0;

  @media ${device.laptop} {
    padding: 48px 0;
  }
`;

const RowStyled = styled(Row)`
  justify-content: center;

  @media ${device.laptopL} {
    justify-content: flex-start;
  }
`;

const H4Styled = styled(H4)`
  margin-bottom: 24px;
`;

const H6Styled = styled(H6)`
  margin-bottom: 32px;
`;

const PSmallStyled = styled(PSmall)`
  color: ${({ theme }: any) => theme.colors.MidGrey};
  margin-bottom: 24px;
`;

const Card = styled.div`
  background: ${({ theme }: any) => theme.colors.White};
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};
  padding: 32px 34px;
`;

const ColumnStyled = styled(Column)`
  margin-bottom: 24px;

  @media ${device.laptopL} {
    margin-bottom: 0;
  }
`;

const ButtonStyled = styled(Button)`
  margin-bottom: 32px;
`;

const TopTips = styled(PSmall)`
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const GuideLink = styled(A)`
  margin-top: 8px;
`;

const Ul = styled.ul``;

const Li = styled.li`
  margin-bottom: 8px;

  &:first-child {
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  :before {
    content: '•';
    margin-right: 8px;
  }
`;

export default MultiBook;
