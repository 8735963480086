export default [{ 'label': '🇦🇫 +93', 'value': 'AF +93', 'country': 'AF' }, {
  'label': '🇦🇽 +358',
  'value': 'AX +358',
  'country': 'AX',
}, { 'label': '🇦🇱 +355', 'value': 'AL +355', 'country': 'AL' }, {
  'label': '🇩🇿 +213',
  'value': 'DZ +213',
  'country': 'DZ',
}, { 'label': '🇦🇸 +1684', 'value': 'AS +1684', 'country': 'AS' }, {
  'label': '🇦🇩 +376',
  'value': 'AD +376',
  'country': 'AD',
}, { 'label': '🇦🇴 +244', 'value': 'AO +244', 'country': 'AO' }, {
  'label': '🇦🇮 +1264',
  'value': 'AI +1264',
  'country': 'AI',
}, { 'label': '🇦🇶 +672', 'value': 'AQ +672', 'country': 'AQ' }, {
  'label': '🇦🇬 +1268',
  'value': 'AG +1268',
  'country': 'AG',
}, { 'label': '🇦🇷 +54', 'value': 'AR +54', 'country': 'AR' }, {
  'label': '🇦🇲 +374',
  'value': 'AM +374',
  'country': 'AM',
}, { 'label': '🇦🇼 +297', 'value': 'AW +297', 'country': 'AW' }, {
  'label': '🇦🇺 +61',
  'value': 'AU +61',
  'country': 'AU',
}, { 'label': '🇦🇹 +43', 'value': 'AT +43', 'country': 'AT' }, {
  'label': '🇦🇿 +994',
  'value': 'AZ +994',
  'country': 'AZ',
}, { 'label': '🇧🇸 +1242', 'value': 'BS +1242', 'country': 'BS' }, {
  'label': '🇧🇭 +973',
  'value': 'BH +973',
  'country': 'BH',
}, { 'label': '🇧🇩 +880', 'value': 'BD +880', 'country': 'BD' }, {
  'label': '🇧🇧 +1246',
  'value': 'BB +1246',
  'country': 'BB',
}, { 'label': '🇧🇾 +375', 'value': 'BY +375', 'country': 'BY' }, {
  'label': '🇧🇪 +32',
  'value': 'BE +32',
  'country': 'BE',
}, { 'label': '🇧🇿 +501', 'value': 'BZ +501', 'country': 'BZ' }, {
  'label': '🇧🇯 +229',
  'value': 'BJ +229',
  'country': 'BJ',
}, { 'label': '🇧🇲 +1441', 'value': 'BM +1441', 'country': 'BM' }, {
  'label': '🇧🇹 +975',
  'value': 'BT +975',
  'country': 'BT',
}, { 'label': '🇧🇴 +591', 'value': 'BO +591', 'country': 'BO' }, {
  'label': '🇧🇦 +387',
  'value': 'BA +387',
  'country': 'BA',
}, { 'label': '🇧🇼 +267', 'value': 'BW +267', 'country': 'BW' }, {
  'label': '🇧🇻 +47',
  'value': 'BV +47',
  'country': 'BV',
}, { 'label': '🇧🇷 +55', 'value': 'BR +55', 'country': 'BR' }, {
  'label': '🇮🇴 +246',
  'value': 'IO +246',
  'country': 'IO',
}, { 'label': '🇧🇳 +673', 'value': 'BN +673', 'country': 'BN' }, {
  'label': '🇧🇬 +359',
  'value': 'BG +359',
  'country': 'BG',
}, { 'label': '🇧🇫 +226', 'value': 'BF +226', 'country': 'BF' }, {
  'label': '🇧🇮 +257',
  'value': 'BI +257',
  'country': 'BI',
}, { 'label': '🇰🇭 +855', 'value': 'KH +855', 'country': 'KH' }, {
  'label': '🇨🇲 +237',
  'value': 'CM +237',
  'country': 'CM',
}, { 'label': '🇨🇦 +1', 'value': 'CA +1', 'country': 'CA' }, {
  'label': '🇨🇻 +238',
  'value': 'CV +238',
  'country': 'CV',
}, { 'label': '🇰🇾 +345', 'value': 'KY +345', 'country': 'KY' }, {
  'label': '🇨🇫 +236',
  'value': 'CF +236',
  'country': 'CF',
}, { 'label': '🇹🇩 +235', 'value': 'TD +235', 'country': 'TD' }, {
  'label': '🇨🇱 +56',
  'value': 'CL +56',
  'country': 'CL',
}, { 'label': '🇨🇳 +86', 'value': 'CN +86', 'country': 'CN' }, {
  'label': '🇨🇽 +61',
  'value': 'CX +61',
  'country': 'CX',
}, { 'label': '🇨🇨 +61', 'value': 'CC +61', 'country': 'CC' }, {
  'label': '🇨🇴 +57',
  'value': 'CO +57',
  'country': 'CO',
}, { 'label': '🇰🇲 +269', 'value': 'KM +269', 'country': 'KM' }, {
  'label': '🇨🇬 +242',
  'value': 'CG +242',
  'country': 'CG',
}, { 'label': '🇨🇩 +243', 'value': 'CD +243', 'country': 'CD' }, {
  'label': '🇨🇰 +682',
  'value': 'CK +682',
  'country': 'CK',
}, { 'label': '🇨🇷 +506', 'value': 'CR +506', 'country': 'CR' }, {
  'label': '🇨🇮 +225',
  'value': 'CI +225',
  'country': 'CI',
}, { 'label': '🇭🇷 +385', 'value': 'HR +385', 'country': 'HR' }, {
  'label': '🇨🇺 +53',
  'value': 'CU +53',
  'country': 'CU',
}, { 'label': '🇨🇾 +357', 'value': 'CY +357', 'country': 'CY' }, {
  'label': '🇨🇿 +420',
  'value': 'CZ +420',
  'country': 'CZ',
}, { 'label': '🇩🇰 +45', 'value': 'DK +45', 'country': 'DK' }, {
  'label': '🇩🇯 +253',
  'value': 'DJ +253',
  'country': 'DJ',
}, { 'label': '🇩🇲 +1767', 'value': 'DM +1767', 'country': 'DM' }, {
  'label': '🇩🇴 +1849',
  'value': 'DO +1849',
  'country': 'DO',
}, { 'label': '🇪🇨 +593', 'value': 'EC +593', 'country': 'EC' }, {
  'label': '🇪🇬 +20',
  'value': 'EG +20',
  'country': 'EG',
}, { 'label': '🇸🇻 +503', 'value': 'SV +503', 'country': 'SV' }, {
  'label': '🇬🇶 +240',
  'value': 'GQ +240',
  'country': 'GQ',
}, { 'label': '🇪🇷 +291', 'value': 'ER +291', 'country': 'ER' }, {
  'label': '🇪🇪 +372',
  'value': 'EE +372',
  'country': 'EE',
}, { 'label': '🇪🇹 +251', 'value': 'ET +251', 'country': 'ET' }, {
  'label': '🇫🇰 +500',
  'value': 'FK +500',
  'country': 'FK',
}, { 'label': '🇫🇴 +298', 'value': 'FO +298', 'country': 'FO' }, {
  'label': '🇫🇯 +679',
  'value': 'FJ +679',
  'country': 'FJ',
}, { 'label': '🇫🇮 +358', 'value': 'FI +358', 'country': 'FI' }, {
  'label': '🇫🇷 +33',
  'value': 'FR +33',
  'country': 'FR',
}, { 'label': '🇬🇫 +594', 'value': 'GF +594', 'country': 'GF' }, {
  'label': '🇵🇫 +689',
  'value': 'PF +689',
  'country': 'PF',
}, { 'label': '🇹🇫 +262', 'value': 'TF +262', 'country': 'TF' }, {
  'label': '🇬🇦 +241',
  'value': 'GA +241',
  'country': 'GA',
}, { 'label': '🇬🇲 +220', 'value': 'GM +220', 'country': 'GM' }, {
  'label': '🇬🇪 +995',
  'value': 'GE +995',
  'country': 'GE',
}, { 'label': '🇩🇪 +49', 'value': 'DE +49', 'country': 'DE' }, {
  'label': '🇬🇭 +233',
  'value': 'GH +233',
  'country': 'GH',
}, { 'label': '🇬🇮 +350', 'value': 'GI +350', 'country': 'GI' }, {
  'label': '🇬🇷 +30',
  'value': 'GR +30',
  'country': 'GR',
}, { 'label': '🇬🇱 +299', 'value': 'GL +299', 'country': 'GL' }, {
  'label': '🇬🇩 +1473',
  'value': 'GD +1473',
  'country': 'GD',
}, { 'label': '🇬🇵 +590', 'value': 'GP +590', 'country': 'GP' }, {
  'label': '🇬🇺 +1671',
  'value': 'GU +1671',
  'country': 'GU',
}, { 'label': '🇬🇹 +502', 'value': 'GT +502', 'country': 'GT' }, {
  'label': '🇬🇬 +44',
  'value': 'GG +44',
  'country': 'GG',
}, { 'label': '🇬🇳 +224', 'value': 'GN +224', 'country': 'GN' }, {
  'label': '🇬🇼 +245',
  'value': 'GW +245',
  'country': 'GW',
}, { 'label': '🇬🇾 +592', 'value': 'GY +592', 'country': 'GY' }, {
  'label': '🇭🇹 +509',
  'value': 'HT +509',
  'country': 'HT',
}, { 'label': '🇭🇲 +672', 'value': 'HM +672', 'country': 'HM' }, {
  'label': '🇻🇦 +379',
  'value': 'VA +379',
  'country': 'VA',
}, { 'label': '🇭🇳 +504', 'value': 'HN +504', 'country': 'HN' }, {
  'label': '🇭🇰 +852',
  'value': 'HK +852',
  'country': 'HK',
}, { 'label': '🇭🇺 +36', 'value': 'HU +36', 'country': 'HU' }, {
  'label': '🇮🇸 +354',
  'value': 'IS +354',
  'country': 'IS',
}, { 'label': '🇮🇳 +91', 'value': 'IN +91', 'country': 'IN' }, {
  'label': '🇮🇩 +62',
  'value': 'ID +62',
  'country': 'ID',
}, { 'label': '🇮🇷 +98', 'value': 'IR +98', 'country': 'IR' }, {
  'label': '🇮🇶 +964',
  'value': 'IQ +964',
  'country': 'IQ',
}, { 'label': '🇮🇪 +353', 'value': 'IE +353', 'country': 'IE' }, {
  'label': '🇮🇲 +44',
  'value': 'IM +44',
  'country': 'IM',
}, { 'label': '🇮🇱 +972', 'value': 'IL +972', 'country': 'IL' }, {
  'label': '🇮🇹 +39',
  'value': 'IT +39',
  'country': 'IT',
}, { 'label': '🇯🇲 +1876', 'value': 'JM +1876', 'country': 'JM' }, {
  'label': '🇯🇵 +81',
  'value': 'JP +81',
  'country': 'JP',
}, { 'label': '🇯🇪 +44', 'value': 'JE +44', 'country': 'JE' }, {
  'label': '🇯🇴 +962',
  'value': 'JO +962',
  'country': 'JO',
}, { 'label': '🇰🇿 +7', 'value': 'KZ +7', 'country': 'KZ' }, {
  'label': '🇰🇪 +254',
  'value': 'KE +254',
  'country': 'KE',
}, { 'label': '🇰🇮 +686', 'value': 'KI +686', 'country': 'KI' }, {
  'label': '🇰🇵 +850',
  'value': 'KP +850',
  'country': 'KP',
}, { 'label': '🇰🇷 +82', 'value': 'KR +82', 'country': 'KR' }, {
  'label': '🇽🇰 +383',
  'value': 'XK +383',
  'country': 'XK',
}, { 'label': '🇰🇼 +965', 'value': 'KW +965', 'country': 'KW' }, {
  'label': '🇰🇬 +996',
  'value': 'KG +996',
  'country': 'KG',
}, { 'label': '🇱🇦 +856', 'value': 'LA +856', 'country': 'LA' }, {
  'label': '🇱🇻 +371',
  'value': 'LV +371',
  'country': 'LV',
}, { 'label': '🇱🇧 +961', 'value': 'LB +961', 'country': 'LB' }, {
  'label': '🇱🇸 +266',
  'value': 'LS +266',
  'country': 'LS',
}, { 'label': '🇱🇷 +231', 'value': 'LR +231', 'country': 'LR' }, {
  'label': '🇱🇾 +218',
  'value': 'LY +218',
  'country': 'LY',
}, { 'label': '🇱🇮 +423', 'value': 'LI +423', 'country': 'LI' }, {
  'label': '🇱🇹 +370',
  'value': 'LT +370',
  'country': 'LT',
}, { 'label': '🇱🇺 +352', 'value': 'LU +352', 'country': 'LU' }, {
  'label': '🇲🇴 +853',
  'value': 'MO +853',
  'country': 'MO',
}, { 'label': '🇲🇰 +389', 'value': 'MK +389', 'country': 'MK' }, {
  'label': '🇲🇬 +261',
  'value': 'MG +261',
  'country': 'MG',
}, { 'label': '🇲🇼 +265', 'value': 'MW +265', 'country': 'MW' }, {
  'label': '🇲🇾 +60',
  'value': 'MY +60',
  'country': 'MY',
}, { 'label': '🇲🇻 +960', 'value': 'MV +960', 'country': 'MV' }, {
  'label': '🇲🇱 +223',
  'value': 'ML +223',
  'country': 'ML',
}, { 'label': '🇲🇹 +356', 'value': 'MT +356', 'country': 'MT' }, {
  'label': '🇲🇭 +692',
  'value': 'MH +692',
  'country': 'MH',
}, { 'label': '🇲🇶 +596', 'value': 'MQ +596', 'country': 'MQ' }, {
  'label': '🇲🇷 +222',
  'value': 'MR +222',
  'country': 'MR',
}, { 'label': '🇲🇺 +230', 'value': 'MU +230', 'country': 'MU' }, {
  'label': '🇾🇹 +262',
  'value': 'YT +262',
  'country': 'YT',
}, { 'label': '🇲🇽 +52', 'value': 'MX +52', 'country': 'MX' }, {
  'label': '🇫🇲 +691',
  'value': 'FM +691',
  'country': 'FM',
}, { 'label': '🇲🇩 +373', 'value': 'MD +373', 'country': 'MD' }, {
  'label': '🇲🇨 +377',
  'value': 'MC +377',
  'country': 'MC',
}, { 'label': '🇲🇳 +976', 'value': 'MN +976', 'country': 'MN' }, {
  'label': '🇲🇪 +382',
  'value': 'ME +382',
  'country': 'ME',
}, { 'label': '🇲🇸 +1664', 'value': 'MS +1664', 'country': 'MS' }, {
  'label': '🇲🇦 +212',
  'value': 'MA +212',
  'country': 'MA',
}, { 'label': '🇲🇿 +258', 'value': 'MZ +258', 'country': 'MZ' }, {
  'label': '🇲🇲 +95',
  'value': 'MM +95',
  'country': 'MM',
}, { 'label': '🇳🇦 +264', 'value': 'NA +264', 'country': 'NA' }, {
  'label': '🇳🇷 +674',
  'value': 'NR +674',
  'country': 'NR',
}, { 'label': '🇳🇵 +977', 'value': 'NP +977', 'country': 'NP' }, {
  'label': '🇳🇱 +31',
  'value': 'NL +31',
  'country': 'NL',
}, { 'label': ' +599', 'value': 'AN +599', 'country': 'AN' }, {
  'label': '🇳🇨 +687',
  'value': 'NC +687',
  'country': 'NC',
}, { 'label': '🇳🇿 +64', 'value': 'NZ +64', 'country': 'NZ' }, {
  'label': '🇳🇮 +505',
  'value': 'NI +505',
  'country': 'NI',
}, { 'label': '🇳🇪 +227', 'value': 'NE +227', 'country': 'NE' }, {
  'label': '🇳🇬 +234',
  'value': 'NG +234',
  'country': 'NG',
}, { 'label': '🇳🇺 +683', 'value': 'NU +683', 'country': 'NU' }, {
  'label': '🇳🇫 +672',
  'value': 'NF +672',
  'country': 'NF',
}, { 'label': '🇲🇵 +1670', 'value': 'MP +1670', 'country': 'MP' }, {
  'label': '🇳🇴 +47',
  'value': 'NO +47',
  'country': 'NO',
}, { 'label': '🇴🇲 +968', 'value': 'OM +968', 'country': 'OM' }, {
  'label': '🇵🇰 +92',
  'value': 'PK +92',
  'country': 'PK',
}, { 'label': '🇵🇼 +680', 'value': 'PW +680', 'country': 'PW' }, {
  'label': '🇵🇸 +970',
  'value': 'PS +970',
  'country': 'PS',
}, { 'label': '🇵🇦 +507', 'value': 'PA +507', 'country': 'PA' }, {
  'label': '🇵🇬 +675',
  'value': 'PG +675',
  'country': 'PG',
}, { 'label': '🇵🇾 +595', 'value': 'PY +595', 'country': 'PY' }, {
  'label': '🇵🇪 +51',
  'value': 'PE +51',
  'country': 'PE',
}, { 'label': '🇵🇭 +63', 'value': 'PH +63', 'country': 'PH' }, {
  'label': '🇵🇳 +64',
  'value': 'PN +64',
  'country': 'PN',
}, { 'label': '🇵🇱 +48', 'value': 'PL +48', 'country': 'PL' }, {
  'label': '🇵🇹 +351',
  'value': 'PT +351',
  'country': 'PT',
}, { 'label': '🇵🇷 +1939', 'value': 'PR +1939', 'country': 'PR' }, {
  'label': '🇶🇦 +974',
  'value': 'QA +974',
  'country': 'QA',
}, { 'label': '🇷🇴 +40', 'value': 'RO +40', 'country': 'RO' }, {
  'label': '🇷🇺 +7',
  'value': 'RU +7',
  'country': 'RU',
}, { 'label': '🇷🇼 +250', 'value': 'RW +250', 'country': 'RW' }, {
  'label': '🇷🇪 +262',
  'value': 'RE +262',
  'country': 'RE',
}, { 'label': '🇧🇱 +590', 'value': 'BL +590', 'country': 'BL' }, {
  'label': '🇸🇭 +290',
  'value': 'SH +290',
  'country': 'SH',
}, { 'label': '🇰🇳 +1869', 'value': 'KN +1869', 'country': 'KN' }, {
  'label': '🇱🇨 +1758',
  'value': 'LC +1758',
  'country': 'LC',
}, { 'label': '🇲🇫 +590', 'value': 'MF +590', 'country': 'MF' }, {
  'label': '🇵🇲 +508',
  'value': 'PM +508',
  'country': 'PM',
}, { 'label': '🇻🇨 +1784', 'value': 'VC +1784', 'country': 'VC' }, {
  'label': '🇼🇸 +685',
  'value': 'WS +685',
  'country': 'WS',
}, { 'label': '🇸🇲 +378', 'value': 'SM +378', 'country': 'SM' }, {
  'label': '🇸🇹 +239',
  'value': 'ST +239',
  'country': 'ST',
}, { 'label': '🇸🇦 +966', 'value': 'SA +966', 'country': 'SA' }, {
  'label': '🇸🇳 +221',
  'value': 'SN +221',
  'country': 'SN',
}, { 'label': '🇷🇸 +381', 'value': 'RS +381', 'country': 'RS' }, {
  'label': '🇸🇨 +248',
  'value': 'SC +248',
  'country': 'SC',
}, { 'label': '🇸🇱 +232', 'value': 'SL +232', 'country': 'SL' }, {
  'label': '🇸🇬 +65',
  'value': 'SG +65',
  'country': 'SG',
}, { 'label': '🇸🇰 +421', 'value': 'SK +421', 'country': 'SK' }, {
  'label': '🇸🇮 +386',
  'value': 'SI +386',
  'country': 'SI',
}, { 'label': '🇸🇧 +677', 'value': 'SB +677', 'country': 'SB' }, {
  'label': '🇸🇴 +252',
  'value': 'SO +252',
  'country': 'SO',
}, { 'label': '🇿🇦 +27', 'value': 'ZA +27', 'country': 'ZA' }, {
  'label': '🇸🇸 +211',
  'value': 'SS +211',
  'country': 'SS',
}, { 'label': '🇬🇸 +500', 'value': 'GS +500', 'country': 'GS' }, {
  'label': '🇪🇸 +34',
  'value': 'ES +34',
  'country': 'ES',
}, { 'label': '🇱🇰 +94', 'value': 'LK +94', 'country': 'LK' }, {
  'label': '🇸🇩 +249',
  'value': 'SD +249',
  'country': 'SD',
}, { 'label': '🇸🇷 +597', 'value': 'SR +597', 'country': 'SR' }, {
  'label': '🇸🇯 +47',
  'value': 'SJ +47',
  'country': 'SJ',
}, { 'label': '🇸🇿 +268', 'value': 'SZ +268', 'country': 'SZ' }, {
  'label': '🇸🇪 +46',
  'value': 'SE +46',
  'country': 'SE',
}, { 'label': '🇨🇭 +41', 'value': 'CH +41', 'country': 'CH' }, {
  'label': '🇸🇾 +963',
  'value': 'SY +963',
  'country': 'SY',
}, { 'label': '🇹🇼 +886', 'value': 'TW +886', 'country': 'TW' }, {
  'label': '🇹🇯 +992',
  'value': 'TJ +992',
  'country': 'TJ',
}, { 'label': '🇹🇿 +255', 'value': 'TZ +255', 'country': 'TZ' }, {
  'label': '🇹🇭 +66',
  'value': 'TH +66',
  'country': 'TH',
}, { 'label': '🇹🇱 +670', 'value': 'TL +670', 'country': 'TL' }, {
  'label': '🇹🇬 +228',
  'value': 'TG +228',
  'country': 'TG',
}, { 'label': '🇹🇰 +690', 'value': 'TK +690', 'country': 'TK' }, {
  'label': '🇹🇴 +676',
  'value': 'TO +676',
  'country': 'TO',
}, { 'label': '🇹🇹 +1868', 'value': 'TT +1868', 'country': 'TT' }, {
  'label': '🇹🇳 +216',
  'value': 'TN +216',
  'country': 'TN',
}, { 'label': '🇹🇷 +90', 'value': 'TR +90', 'country': 'TR' }, {
  'label': '🇹🇲 +993',
  'value': 'TM +993',
  'country': 'TM',
}, { 'label': '🇹🇨 +1649', 'value': 'TC +1649', 'country': 'TC' }, {
  'label': '🇹🇻 +688',
  'value': 'TV +688',
  'country': 'TV',
}, { 'label': '🇺🇬 +256', 'value': 'UG +256', 'country': 'UG' }, {
  'label': '🇺🇦 +380',
  'value': 'UA +380',
  'country': 'UA',
}, { 'label': '🇦🇪 +971', 'value': 'AE +971', 'country': 'AE' }, {
  'label': '🇬🇧 +44',
  'value': 'GB +44',
  'country': 'GB',
}, { 'label': '🇺🇸 +1', 'value': 'US +1', 'country': 'US' }, {
  'label': '🇺🇾 +598',
  'value': 'UY +598',
  'country': 'UY',
}, { 'label': '🇺🇿 +998', 'value': 'UZ +998', 'country': 'UZ' }, {
  'label': '🇻🇺 +678',
  'value': 'VU +678',
  'country': 'VU',
}, { 'label': '🇻🇪 +58', 'value': 'VE +58', 'country': 'VE' }, {
  'label': '🇻🇳 +84',
  'value': 'VN +84',
  'country': 'VN',
}, { 'label': '🇻🇬 +1284', 'value': 'VG +1284', 'country': 'VG' }, {
  'label': '🇻🇮 +1340',
  'value': 'VI +1340',
  'country': 'VI',
}, { 'label': '🇼🇫 +681', 'value': 'WF +681', 'country': 'WF' }, {
  'label': '🇾🇪 +967',
  'value': 'YE +967',
  'country': 'YE',
}, { 'label': '🇿🇲 +260', 'value': 'ZM +260', 'country': 'ZM' }, {
  'label': '🇿🇼 +263',
  'value': 'ZW +263',
  'country': 'ZW',
}];