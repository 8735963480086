import React from 'react';
import styled from 'styled-components';
import { A, Button, H6 } from '../../../../../../../../../assets/elements';
import Modal from '../../../../../../../../../assets/Combos/Modal';

const LabelModal = ({ link, open, setOpen }: any) => {
  if (link.length <= 1) {
    return <></>;
  }

  const openLabel = (url: any) => {
    window.open(url, '_blank');
  };

  const openAllLabels = () => {
    link.forEach((url: any) => {
      window.open(url, '_blank');
    });
  };

  return (
    <Modal
      title="Print shipping labels"
      TitleElement={H6}
      open={open}
      close={() => setOpen(false)}
      width={6}
    >
      <LabelsList>
        {
          link.map((label: any, i: any) => (
            <SingleLabel key={i}>
              <LabelTitle>Parcel { i + 1 }</LabelTitle>
              <ButtonStyled color="dark" onClick={() => openLabel(label)}>Print label</ButtonStyled>
            </SingleLabel>
          ))
        }
      </LabelsList>
      <AllLabelsRow>
        <ButtonBig onClick={openAllLabels}>Print all labels</ButtonBig>
      </AllLabelsRow>
    </Modal>
  );
};

const LabelsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SingleLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const LabelTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const ButtonStyled = styled(Button)`
  padding: 7px 12px;
`;

const AllLabelsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 24px;
`;

const ButtonBig = styled(A)`
  background: #2757F2;
  border-radius: 4px;
  padding: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.02em;
  color: ${({ theme }: any) => theme.colors.White};
`;

export default LabelModal;