import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import device from '../../../../../../../utils/device';
import { dateFormat } from '../../../../../../../utils/variables';

const DeliveryTime = ({ date, time, title }: any) => {
  const calcDeliveryTime = () => {
    if (time === 0) {
      return 'Same day';
    }

    if (time === false || time === null) {
      return 'Not available';
    }

    return `${time} day${(time === 0 || time > 1) ? 's' : ''}`;
  };

  return (
    <DeliveryTimeContainer>
      <DeliveryTimeHead>{title}</DeliveryTimeHead>
      <DeliveryTimeData>
        {
          date !== undefined
            ? moment(date).format(dateFormat)
            : calcDeliveryTime()
        }
      </DeliveryTimeData>
    </DeliveryTimeContainer>
  );
};

const DeliveryTimeContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  color: ${({ theme }: any) => theme.colors.Black};
  border-radius: 4px;
  height: 100%;

  @media ${device.desktop} {
    height: auto;
  }

  margin-bottom: 16px;
  width: calc(50% - 4px);

  @media ${device.laptop} {
    width: 100%;

    &:first-of-type {
      margin-right: 0;
    }

    &:last-of-type {
      margin-left: 0;
    }
  }

  &:first-of-type {
    margin-right: 4px;
  }

  &:last-of-type {
    margin-left: 4px;
  }
`;

const DeliveryTimeHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  padding: 7px 4px;
`;

const DeliveryTimeData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 19px;
  line-height: 150%;
  font-weight: 500;
  margin-top: 13px;
`;

export default DeliveryTime;
