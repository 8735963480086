import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import device from '../../utils/device';
import {
  Button,
  Column,
  Container,
  H4,
  PSmall,
  Row,
} from '../../../assets/elements';
import { setBackToTokenExpired } from '../../store/actions';
import { landingPageUrl } from '../../utils/env';
import { resendEmailConfirmation } from '../../utils/APICalls/Account';
import { logo } from '../../../assets/styles/themes';

const TokenExpired = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [sending, setSending] = useState<any>(false);
  const [alreadyVerified, setAlreadyVerified] = useState(false);
  const token =
    useSelector((state: any) => state.user.session.accessToken) || '';

  useEffect(() => {
    if (token === '') {
      dispatch(setBackToTokenExpired(true));
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewLink = () => {
    setSending(true);

    resendEmailConfirmation()
      .then(() => {
        setSending(false);
        history.push('/register-confirmation');
      })
      .catch(() => {
        setSending(false);
        setAlreadyVerified(true);

        setTimeout(() => {
          window.location.href = landingPageUrl;
        }, 500);
      });
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <Column sizeL={4} sizeM={4} sizeS={3} sizeXS={4}>
            <ImageWrapper>
              <Image src={logo} alt="Site logo" />
            </ImageWrapper>
            {alreadyVerified ? (
              <>
                <H4Styled>Your email has already been verified.</H4Styled>
                <SubTitle>Redirecting...</SubTitle>
              </>
            ) : (
              <>
                <H4Styled>Your confirmation link has expired</H4Styled>
                <SubTitle>
                  Resend your confirmation email to get a new link
                </SubTitle>
                <ButtonWrapper>
                  <Button
                    color="black"
                    disable={sending}
                    disabled={sending}
                    onClick={handleNewLink}
                  >
                    {sending ? 'Sending...' : 'Send new link'}
                  </Button>
                </ButtonWrapper>
              </>
            )}
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background: ${({ theme }: any) => theme.colors.GrayWhite};
  min-height: calc(100vh - 76px);
  display: flex;
  justify-content: center;
  padding: 32px 0;

  @media ${device.laptop} {
    padding: 48px 0;
  }
`;

const ImageWrapper = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  height: 40px;
  margin-bottom: 48px;
`;

const H4Styled = styled(H4)`
  text-align: center;
  margin-bottom: 16px;
`;

const SubTitle = styled(PSmall)`
  text-align: center;
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export default TokenExpired;
