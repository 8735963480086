import React from 'react';
import styled from 'styled-components';

export const Table = ({ children }: any) => {
  return <TableWrapper>{children}</TableWrapper>;
};

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
