import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import postalCodes from 'postal-codes-js';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import {
  Button,
  Caption,
  Column,
  Input,
  Label,
  Row,
  A,
} from '../../../../../../../assets/elements';
import device from '../../../../../../utils/device';
import { SelectInput } from '../../../../../Inputs';
import { countriesPostalCodesData } from '../../../../../../mocks/quoteData';
import FullLoad from './components/FullLoad';
import LessThanFullLoad from './components/LessThanFullLoad';
import { generateQuotesUrl } from '../../../../../../utils/quotesUrl';
import Switch from '../../../../../../../assets/Combos/Switch';
import DateSelector from '../../../../../Quotes/components/FreightQuotes/Calendar';
import InfoIcon from '../../../../../../../assets/icons/InfoIcon';
import Hazardous from '../../Modals/Hazardous';
import Stackable from '../../Modals/Stackable';
import CustomsClearance from '../../Modals/CustomsClearance';

const tempID = uuidv4();
const StepOne = ({ onQuotesPage = false, theme }: any) => {
  const seaFreight = useSelector<any, any>((state) => state.main.seaFreight);

  const [hazardousModal, setHazardousModal] = useState(false);
  const [stackableModal, setStackableModal] = useState(false);
  const [customsModal, setCustomsModal] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const [allQuotes, setAllQuotes] = useState(false);
  const [load, setLoad] = useState('full');
  const [freight, setFreight] = useState<any>({
    collectionLocation: {
      country: '',
      postcode: '',
    },
    packageDetails: {
      readyDate: '',
      hazardous: false,
      stackable: false,
      customs: false,
      package: {
        type: 'FCL',
        subType: 'pallet',
        items: [
          {
            id: tempID,
            weight: {
              amount: '',
              unit: 'kg',
            },
            length: {
              amount: '',
              unit: 'cm',
            },
            width: {
              amount: '',
              unit: 'cm',
            },
            height: {
              amount: '',
              unit: 'cm',
            },
            quantity: {
              amount: 1,
            },
          },
        ],
      },
    },
    deliveryLocation: {
      country: '',
      postcode: '',
    },
  });

  useEffect(() => {
    setFreight({
      ...seaFreight,
      packageDetails: {
        ...seaFreight.packageDetails,
        hazardous: seaFreight.packageDetails.hazardous === 'true',
        stackable: seaFreight.packageDetails.stackable === 'true',
        customs: seaFreight.packageDetails.customs === 'true',
      },
    });

    const itemErrors: any = {};
    seaFreight.packageDetails.package.items.forEach((item: any) => {
      itemErrors[item.id] = {
        length: false,
        width: false,
        height: false,
        weight: false,
      };
    });

    setPackageError({
      ...packageError,
      package: {
        items: itemErrors,
      },
    });

    setLoad(seaFreight.packageDetails.package.type === 'FCL' ? 'full' : 'less');
  }, [seaFreight]);

  const [fromError, setFromError] = useState<any>({
    countryCodeError: false,
    postCodeError: false,
  });

  const [toError, setToError] = useState<any>({
    countryCodeError: false,
    postCodeError: false,
  });

  const [loadError, setLoadError] = useState<any>(false);

  const [packageError, setPackageError] = useState({
    hazardous: false,
    readyDate: false,
    package: {
      items: {
        [tempID]: {
          length: false,
          width: false,
          height: false,
          weight: false,
        },
      },
    },
  });

  const handleAllQuotesChange = () => {
    setAllQuotes(!allQuotes);
  };

  const addNewItem = () => {
    const id = uuidv4();

    setFreight({
      ...freight,
      packageDetails: {
        ...freight.packageDetails,
        package: {
          ...freight.packageDetails.package,
          items: [
            ...freight.packageDetails.package.items,
            {
              id,
              weight: {
                amount: '',
                unit: 'kg',
              },
              length: {
                amount: '',
                unit: 'cm',
              },
              width: {
                amount: '',
                unit: 'cm',
              },
              height: {
                amount: '',
                unit: 'cm',
              },
              quantity: {
                amount: 1,
              },
            },
          ],
        },
      },
    });

    setPackageError({
      ...packageError,
      package: {
        ...packageError.package,
        items: {
          ...packageError.package.items,
          [id]: {
            length: false,
            width: false,
            height: false,
            weight: false,
          },
        },
      },
    });
  };

  const removeItem = (id: any) => {
    const items = freight.packageDetails.package.items.filter(
      (item: any) => item.id !== id
    );
    setFreight({
      ...freight,
      packageDetails: {
        ...freight.packageDetails,
        package: {
          ...freight.packageDetails.package,
          items: [...items],
        },
      },
    });

    const tempErrors = { ...packageError };
    delete tempErrors.package.items[id];

    setPackageError({
      ...tempErrors,
    });
  };

  const handleDimensionsChange = (value: any, id: any, key: string) => {
    setFreight({
      ...freight,
      packageDetails: {
        ...freight.packageDetails,
        package: {
          ...freight.packageDetails.package,
          items: freight.packageDetails.package.items.map((item: any) => {
            if (item.id === id) {
              return {
                ...item,
                [key]: {
                  amount: value,
                },
              };
            }

            return { ...item };
          }),
        },
      },
    });

    setPackageError({
      ...packageError,
      package: {
        ...packageError.package,
        items: {
          ...packageError.package.items,
          [id]: {
            ...packageError.package.items[id],
            [key]: false,
          },
        },
      },
    });
  };

  const fromCountryErrorClean = () => {
    setFromError({
      ...fromError,
      countryCodeError: false,
    });
  };

  const toCountryErrorClean = () => {
    setToError({
      ...toError,
      countryCodeError: false,
    });
  };

  const fromPostCodeErrorClean = () => {
    setFromError({
      ...fromError,
      postCodeError: false,
    });
  };

  const toPostCodeErrorClean = () => {
    setToError({
      ...toError,
      postCodeError: false,
    });
  };

  const handlePostalCodeChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    toPostCodeErrorClean();
    fromPostCodeErrorClean();
    const { value } = e.currentTarget;

    setFreight({
      ...freight,
      [key]: {
        ...freight[key],
        postcode: value,
      },
    });
  };

  const handleSelectChange = (value: any, type: any) => {
    setFreight({
      ...freight,
      [type]: {
        ...freight[type],
        country: value,
      },
    });
  };

  const selectLoad = (value: any) => {
    setLoad(value);
  };

  const onSubTypeChange = (value: string) => {
    setFreight({
      ...freight,
      packageDetails: {
        ...freight.packageDetails,
        package: {
          ...freight.packageDetails.package,
          subType: value,
        },
      },
    });
  };

  const handleSwitchClick = (value: any, key: string) => {
    if (key === 'hazardous' && value) {
      setHazardousModal(true);
      setDisabled(true);
    } else if (key === 'hazardous' && !value) {
      setDisabled(false);
    }

    setFreight({
      ...freight,
      packageDetails: {
        ...freight.packageDetails,
        [key]: value,
      },
    });
  };

  const handleReadyDateChange = (date: any) => {
    setFreight({
      ...freight,
      packageDetails: {
        ...freight.packageDetails,
        readyDate: date,
      },
    });

    if (packageError.readyDate) {
      setPackageError({
        ...packageError,
        readyDate: false,
      });
    }
  };

  const turnHazardousOff = () => {
    setFreight({
      ...freight,
      packageDetails: {
        ...freight.packageDetails,
        hazardous: false,
      },
    });

    setDisabled(false);
    setHazardousModal(false);
  };

  const validateFrom = () => {
    let error = false;

    const result = postalCodes.validate(
      freight.collectionLocation.country,
      freight.collectionLocation.postcode
    );
    const postCode = freight.collectionLocation.postcode;

    if (result && result !== true) {
      if (result.includes('Missing country code')) {
        setFromError({
          ...fromError,
          countryCodeError: result,
        });

        error = true;
      } else if (result.includes('Missing postal code')) {
        setFromError({
          ...toError,
          postCodeError: 'Missing postcode',
          countryCodeError: false,
        });

        error = true;
      } else if (
        result.includes(
          `Postal code ${postCode} is not valid for country ${freight.collectionLocation.country}`
        )
      ) {
        setFromError({
          ...toError,
          postCodeError: 'Wrong code',
          countryCodeError: false,
        });

        error = true;
      } else if (postCode.length > 0) {
        if (isNaN(Number(postCode))) {
          setFromError({
            ...fromError,
            postCodeError: 'Wrong code',
            countryCodeError: false,
          });

          error = true;
        }
      } else {
        setFromError({
          ...fromError,
          postCodeError: false,
          countryCodeError: false,
        });

        error = false;
      }
    }
    return error;
  };

  const validateTo = () => {
    let error = false;
    const result = postalCodes.validate(
      freight.deliveryLocation.country,
      freight.deliveryLocation.postcode
    );
    const postCode = freight.deliveryLocation.postcode;

    if (result && result !== true) {
      if (result.includes('Missing country code')) {
        setToError({
          ...toError,
          countryCodeError: result,
        });

        error = true;
      } else if (result.includes('Missing postal code')) {
        setToError({
          ...toError,
          postCodeError: 'Missing postcode',
          countryCodeError: false,
        });

        error = true;
      } else if (
        result.includes(
          `Postal code ${postCode} is not valid for country ${freight.deliveryLocation.country}`
        )
      ) {
        setToError({
          ...toError,
          postCodeError: 'Wrong code',
          countryCodeError: false,
        });

        error = true;
      } else if (postCode.length > 0) {
        if (isNaN(Number(postCode))) {
          setToError({
            ...toError,
            postCodeError: 'Wrong code',
            countryCodeError: false,
          });
          error = true;
        }
      } else {
        setToError({
          ...toError,
          postCodeError: false,
          countryCodeError: false,
        });

        error = false;
      }
    }
    return error;
  };

  const validateLoad = () => {
    if (load === 'full') {
      return false;
    }

    let error = false;
    let errors: any = {
      ...packageError,
    };

    if (freight.packageDetails.readyDate === '') {
      errors = {
        ...errors,
        readyDate: 'Required',
      };

      error = true;
    }

    if (freight.packageDetails.hazardous === true) {
      errors = {
        ...errors,
        hazardous: 'This cannot be true.',
      };

      error = true;
    }

    freight.packageDetails.package.items.forEach((item: any) => {
      if (item.weight.amount === '') {
        errors.package.items[item.id].weight = 'Required';

        error = true;
      }

      if (item.length.amount === '') {
        errors.package.items[item.id].length = 'Required';

        error = true;
      }

      if (item.width.amount === '') {
        errors.package.items[item.id].width = 'Required';

        error = true;
      }

      if (item.height.amount === '') {
        errors.package.items[item.id].height = 'Required';

        error = true;
      }
    });

    setPackageError({
      ...errors,
    });

    return error;
  };

  const handleSubmit = () => {
    const errorFrom = validateFrom();
    const errorTo = validateTo();
    const errorLoad = validateLoad();

    if (errorFrom || errorTo || errorLoad) {
      return;
    }

    window.location.href = generateQuotesUrl(
      freight,
      'seaFreight',
      allQuotes,
      load
    );
  };

  return (
    <>
      <Wrapper>
        {/* <CheckboxWrapper>
          <CheckBoxInput
            label="Include quotes from all freight services (Road+Air+Sea)"
            checked={allQuotes}
            onChange={handleAllQuotesChange}
          />
        </CheckboxWrapper> */}
        <RowStyled>
          <ColumnPad sizeL={6} sizeM={6} sizeS={6}>
            <LabelStyled>Collect from:</LabelStyled>
            <Row>
              <ColumnStyledSelect sizeL={7} sizeM={7} sizeS={4} sizeXS={2.5}>
                <SelectInput
                  title="From"
                  data={countriesPostalCodesData}
                  error={fromError.countryCodeError}
                  resetError={fromCountryErrorClean}
                  defaultValue="GB"
                  value={freight.collectionLocation.country}
                  onChange={(code: string) =>
                    handleSelectChange(code, 'collectionLocation')
                  }
                  height="50px"
                />
                <ErrorText>{fromError.countryCodeError}</ErrorText>
              </ColumnStyledSelect>
              <ColumnStyled sizeL={5} sizeM={5} sizeS={2} sizeXS={1.5}>
                <InputStyled
                  placeholder="Postcode"
                  value={freight.collectionLocation.postcode}
                  onChange={(e: any) =>
                    handlePostalCodeChange(e, 'collectionLocation')
                  }
                  theme={theme}
                  type="text"
                  error={!!fromError.postCodeError}
                />
                <ErrorText>{fromError.postCodeError}</ErrorText>
              </ColumnStyled>
            </Row>
          </ColumnPad>
          <ColumnPad sizeL={6} sizeM={6} sizeS={6}>
            <LabelStyled>Deliver to:</LabelStyled>
            <Row>
              <ColumnStyledSelect sizeL={7} sizeM={7} sizeS={4} sizeXS={2.5}>
                <SelectInput
                  title="To"
                  actionName="SET_PARCEL_TO_COUNTRYCODE"
                  data={countriesPostalCodesData}
                  error={toError.countryCodeError}
                  resetError={toCountryErrorClean}
                  defaultValue="GB"
                  value={freight.deliveryLocation.country}
                  onChange={(code: string) =>
                    handleSelectChange(code, 'deliveryLocation')
                  }
                  height="50px"
                />
                <ErrorText>{toError.countryCodeError}</ErrorText>
              </ColumnStyledSelect>
              <ColumnStyled sizeL={5} sizeM={5} sizeS={2} sizeXS={1.5}>
                <InputStyled
                  placeholder="Postcode"
                  value={freight.deliveryLocation.postcode}
                  onChange={(e: any) =>
                    handlePostalCodeChange(e, 'deliveryLocation')
                  }
                  theme={theme}
                  type="text"
                  error={!!toError.postCodeError}
                />
                <ErrorText>{toError.postCodeError}</ErrorText>
              </ColumnStyled>
            </Row>
          </ColumnPad>
        </RowStyled>
        <RowLeft>
          <ColumnPad sizeL={6} sizeM={6} sizeS={6}>
            <LabelStyled>Goods ready date</LabelStyled>
            <DateSelector
              date={freight.packageDetails.readyDate}
              setDate={handleReadyDateChange}
              error={packageError.readyDate}
              minDate={new Date()}
              country={freight.collectionLocation.country}
            />
          </ColumnPad>
        </RowLeft>

        <RowStyled>
          <ColumnPad sizeL={4} sizeM={4} sizeS={6} sizeXS={4}>
            <LabelStyled>Hazardous cargo?</LabelStyled>
            <SwitchContainer>
              <Switch
                value={freight.packageDetails.hazardous}
                onChange={(value: any) => handleSwitchClick(value, 'hazardous')}
              />
              <Icon onClick={() => setHazardousModal(true)}>
                <InfoIcon />
              </Icon>
            </SwitchContainer>
          </ColumnPad>
          <ColumnPad sizeL={4} sizeM={4} sizeS={6} sizeXS={4}>
            <LabelStyled>Stackable cargo?</LabelStyled>
            <SwitchContainer>
              <Switch
                value={freight.packageDetails.stackable}
                onChange={(value: any) => handleSwitchClick(value, 'stackable')}
              />
              <Icon onClick={() => setStackableModal(true)}>
                <InfoIcon />
              </Icon>
            </SwitchContainer>
          </ColumnPad>
          <ColumnPad sizeL={4} sizeM={4} sizeS={6} sizeXS={4}>
            <LabelStyled>Need customs clearance?</LabelStyled>
            <SwitchContainer>
              <Switch
                value={freight.packageDetails.customs}
                onChange={(value: any) => handleSwitchClick(value, 'customs')}
              />
              <Icon onClick={() => setCustomsModal(true)}>
                <InfoIcon />
              </Icon>
            </SwitchContainer>
          </ColumnPad>
        </RowStyled>
        <ShippingWrapper>
          <LabelShipping>How much are you shipping?</LabelShipping>
          <ShippingLoad>
            <FullLoad
              onClick={() => selectLoad('full')}
              selected={load === 'full'}
            />
          </ShippingLoad>
          <ShippingLoad>
            <LessThanFullLoad
              onClick={() => selectLoad('less')}
              selected={load === 'less'}
              onSubTypeChange={onSubTypeChange}
              subType={freight.packageDetails.package.subType}
              items={freight.packageDetails.package.items}
              errors={packageError.package.items}
              addNewItem={addNewItem}
              removeItem={removeItem}
              handleChange={handleDimensionsChange}
              setDisabled={setDisabled}
            />
            <ErrorTextPad>{loadError}</ErrorTextPad>
          </ShippingLoad>
        </ShippingWrapper>
        <RowStyled>
          <ButtonStyled
            color="secondary"
            block
            onClick={handleSubmit}
            disabled={disabled}
            disable={disabled}
          >
            {onQuotesPage ? 'Update results' : 'Get an instant quote'}
          </ButtonStyled>
        </RowStyled>
      </Wrapper>
      <Hazardous
        open={hazardousModal}
        close={() => turnHazardousOff()}
        turnOff={turnHazardousOff}
      />
      <Stackable open={stackableModal} close={() => setStackableModal(false)} />
      <CustomsClearance
        open={customsModal}
        close={() => setCustomsModal(false)}
      />
    </>
  );
};

const Wrapper = styled.section`
  width: 100%;
`;

const CheckboxWrapper = styled.div`
  margin-bottom: 16px;
`;

const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  text-transform: unset;
`;

const LabelShipping = styled(Label)`
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  text-transform: unset;
`;

const ColumnStyled = styled(Column)`
  padding: 0;
  position: relative;
`;

const ColumnStyledSelect = styled(Column)`
  padding: 0;
  position: relative;
  margin-right: -6px;
`;

const RowStyled = styled(Row)`
  margin-bottom: 0;
`;

const RowLeft = styled(RowStyled)`
  justify-content: flex-start;
`;

const InputStyled = styled(Input)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const ColumnPad = styled(Column)`
  padding: 0;
  margin-bottom: 24px;
  position: relative;

  @media ${device.laptopL} {
    padding: 0 15px;
    margin-bottom: 24px;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
`;

const ShippingLoad = styled.div`
  background: ${({ theme: { colors } }: any) => colors.White};
  margin-bottom: 8px;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme: { colors } }: any) => colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

const ErrorTextPad = styled(ErrorText)`
  @media ${device.laptop} {
    left: 15px;
  }

  left: 0;
`;

const ButtonStyled = styled(Button)`
  && {
    font-family: Montserrat, sans-serif !important;
  }
`;

const ShippingWrapper = styled.div`
  background: ${({ theme: { colors } }: any) => colors.WhiteGrey};
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Icon = styled(A)`
  margin-left: 24px;
`;

export default StepOne;
