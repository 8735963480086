import { v4 as uuid } from 'uuid';

import {
  ADD_ORDER_TO_BASKET,
  REMOVE_ORDER_FROM_BASKET,
  RESET_BASKET,
  RESET_STATE,
  SET_COLLECTION_ADDRESS,
  SET_COLLECTION_DETAILS,
  SET_CURRENT_ITEM,
  SET_CUSTOMS_DETAILS,
  SET_DELIVERY_ADDRESS,
  SET_DROPOFF_POINT,
  SET_INSURANCE_DETAILS,
  SET_ITEM_DETAILS,
  SET_ORDER_DATA,
  SET_POSTCODES_DATA,
  SET_RECIPIENT_DETAILS,
  SET_SELECTED_SERVICE,
  SET_SHIPMENT_DETAILS,
  SET_DELIVERYTIME,
  SET_CURRENT_ORDER_STEP,
  GOTO_NEXT_QUICKBOOK_STEP,
  ADD_PARCEL_TO_QUICKBOOK,
  ADD_ADDRESS_TO_ADDRESS_BOOK_TOGGLE,
  SET_FREIGHT_DETAILS,
  ADD_ORDER_TO_BASKET_MANUAL,
  SET_SEA_FREIGHT_DETAILS,
  SET_BASKET_ITEM_ID
} from '../actionConstants';
import { getPersistedState } from '../persist';
import { GTMECommerce } from '../../utils/tracking';

const initState: any = {
  item: {},
  collectionAddress: {
    company: '',
    addressOne: '',
    addressTwo: '',
    addressThree: '',
    city: '',
    country: '',
    zip: '',
  },
  collectionDetails: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    customsData: {
      VATStatus: 'personal_individual',
      VATNumber: '',
      EORINumber: '',
    },
    reference: '',
  },
  dropoffPoint: {
    id: '',
    postcode: '',
    countryCode: '',
  },
  deliveryAddress: {
    company: '',
    addressOne: '',
    addressTwo: '',
    addressThree: '',
    city: '',
    country: '',
    zip: '',
  },
  recipientDetails: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    customsData: {
      VATStatus: 'personal_individual',
      VATNumber: '',
      EORINumber: '',
    },
  },
  packageDetails: {
    parcel: [],
    pallet: [],
    freight: [],
    seaFreight: [],
  },
  seaFreightDetails: {},
  serviceId: '',
  serviceType: 'freight',
  palletAndWrap: false,
  insurance: {},
  customs: false,
  basket: getPersistedState('order.basket') || [],
  orderData: [],
  vat_information: {},
  deliveryTime: false,
  currentStep: 1,
  addressBookToggle: false,
  basketItemId: 0
};

const orderReducer = (state = initState, { type, payload }: any): any => {
  switch (type) {
    case RESET_STATE:
      return {
        ...state,
        packageDetails: {
          parcel: [],
          pallet: [],
        },
        serviceType: '',
        serviceId: '',
        item: {},
        collectionAddress: {
          company: '',
          addressOne: '',
          addressTwo: '',
          addressThree: '',
          city: '',
          country: '',
          zip: '',
        },
        collectionDetails: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          reference: '',
        },
        dropoffPoint: {
          id: '',
          postcode: '',
          countryCode: '',
        },
        deliveryAddress: {
          company: '',
          addressOne: '',
          addressTwo: '',
          addressThree: '',
          city: '',
          country: '',
          zip: '',
        },
        recipientDetails: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        },
        insurance: {},
        currentStep: 1,
        addressBookToggle: false,
      };
    case SET_CURRENT_ITEM:
      return {
        ...state,
        item: { ...payload },
      };
    case SET_POSTCODES_DATA:
      return {
        ...state,
        collectionAddress: {
          postCodesData: payload,
        },
      };
    case SET_SELECTED_SERVICE:
      return {
        ...state,
        serviceId: payload.service.serviceId || payload.service.id,
        item: payload.service,
        serviceType: payload.type,
      };
    case SET_CURRENT_ORDER_STEP:
      return {
        ...state,
        currentStep: payload,
      };
    case GOTO_NEXT_QUICKBOOK_STEP:
      return {
        ...state,
        currentStep: payload === 0 ? state.currentStep + 1 : payload,
      };
    case SET_ITEM_DETAILS:
      return {
        ...state,
        packageDetails: {
          ...state.packageDetails,
          [payload.type]: payload.item.packageDetails,
          loadType: payload.item.loadType
        },
        collectionAddress: {
          ...state.collectionAddress,
          zip: payload.item.collectionLocation.postcode,
          country: payload.item.collectionLocation.country,
        },
        deliveryAddress: {
          ...state.deliveryAddress,
          zip: payload.item.deliveryLocation.postcode,
          country: payload.item.deliveryLocation.country,
        },
        dims: payload.item.dims,
        palletAndWrap: payload.item.palletAndWrap,
      };
    case SET_SHIPMENT_DETAILS:
      // eslint-disable-next-line no-case-declarations
      let details: any = payload.type === 'freight' ? {} : [];
      if (state.serviceType === 'freight' && payload.shipment.length === 1) {
        details = { ...payload.shipment[0] };
      } else if (state.serviceType === 'freight' && payload.loadType === 'LTL') {
        details = payload.shipment.filter((x: any) => { return x.numPallets; });
      } else {
        details = payload.shipment;
      }
      if (state.dims && state.dims.length) {
        return {
          ...state,
          packageDetails: [],
          dims: [...details],
        };
      } else {
        return {
          ...state,
          packageDetails: {
            ...state.packageDetails,
            [payload.type]:
              payload.type === 'freight' && payload.shipment.length === 1
                ? { ...details }
                : [...details],
          },
        };
      }
    case SET_CUSTOMS_DETAILS:
      return {
        ...state,
        vat_information: {
          vat_status: payload.VATStatus,
          vat_number: payload.VATNumber,
          eori_number: payload.EORINumber,
          export_reason: payload.exportReason,
          manufactured: payload.manufacturedIn,
          incoTerms: payload.incoTerms,
          CPCCode: payload.CPCCode,
        },
      };
    case SET_INSURANCE_DETAILS:
      return {
        ...state,
        insurance: { ...payload },
      };
    case SET_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddress: {
          ...state.deliveryAddress,
          ...payload,
        },
      };
    case SET_COLLECTION_ADDRESS:
      return {
        ...state,
        collectionAddress: {
          ...state.collectionAddress,
          ...payload,
        },
      };
    case SET_RECIPIENT_DETAILS:
      // eslint-disable-next-line no-case-declarations
      const name = payload.name.split(' ');
      // eslint-disable-next-line no-case-declarations
      const firstName = name[0];
      name.shift();
      // eslint-disable-next-line no-case-declarations
      const lastName = name.join(' ');

      return {
        ...state,
        recipientDetails: {
          ...state.recipientDetails,
          firstName,
          lastName,
          email: payload.email,
          phone: payload.phone,
          customsData: {
            ...state.recipientDetails.customsData,
            ...payload.customsData,
          },
        },
      };
    case SET_COLLECTION_DETAILS:
      // eslint-disable-next-line no-case-declarations
      const names = payload.name.split(' ');

      // eslint-disable-next-line no-case-declarations
      const first = names[0];
      names.shift();

      // eslint-disable-next-line no-case-declarations
      const last = names.join(' ');

      // eslint-disable-next-line no-case-declarations
      const payload2 = { ...payload };
      delete payload2.name;

      return {
        ...state,
        collectionDetails: {
          ...state.collectionDetails,
          firstName: first,
          lastName: last,
          ...payload2,
        },
      };
    case SET_FREIGHT_DETAILS:
      return {
        ...state,
        freightDetails: {
          [payload.type]: {
            ...state.freightDetails[payload.type],
            ...payload.data,
          },
        },
      };
    case ADD_ADDRESS_TO_ADDRESS_BOOK_TOGGLE:
      return {
        ...state,
        addressBookToggle: payload,
      };
    case SET_DROPOFF_POINT:
      return {
        ...state,
        dropoffPoint: {
          ...state.dropoffPoint,
          ...payload,
        },
      };
    case SET_SEA_FREIGHT_DETAILS:
      return {
        ...state,
        seaFreightDetails: {
          ...payload,
        },
      };
    case ADD_ORDER_TO_BASKET:
      GTMECommerce(
        {
          currencyCode: 'GBP',
          add: {
            products: [
              {
                name: `${
                  state.item?.carrier ? `${state.item.carrier} - ` : ''
                }${state.item.name}`,
                id: `${state.item.rateId}-${state.item.serviceId}-${
                  state.serviceType === 'parcel'
                    ? state.item.type
                    : state.serviceType
                }`,
                price: state.item.priceIncVat,
                category: state.serviceType,
                variant:
                  state.serviceType === 'parcel'
                    ? state.item.type
                    : 'collection',
                quantity: 1,
              },
            ],
          },
        },
        'addToCart'
      );

      return {
        ...state,
        basket: [
          ...state.basket,
          {
            id: uuid(),
            basketItemId: state.basketItemId,
            item: {
              ...state.item,
              insurance: {
                ...state.insurance,
              },
            },
            collectionAddress: state.collectionAddress.addressOne
              ? {
                  ...state.collectionDetails,
                  ...state.collectionAddress,
                }
              : {
                  ...state.collectionDetails,
                },
            deliveryAddress: {
              ...state.deliveryAddress,
              ...state.recipientDetails,
            },
            packageDetails: state.packageDetails[state.serviceType],
            dims: state.dims,
            seaFreightDetails:
              state.serviceType === 'seaFreight'
                ? state.seaFreightDetails
                : undefined,
            serviceId: state.serviceId,
            serviceType: state.serviceType,
            palletAndWrap: state.palletAndWrap,
            dropoffPoint: state.dropoffPoint,
            vat_information: state.vat_information,
            deliveryTime: state.deliveryTime || '',
            loadType: state.packageDetails.loadType,
          },
        ],
      };
    case ADD_ORDER_TO_BASKET_MANUAL:
      return {
        ...state,
        basket: [...state.basket, ...payload],
      };
    case REMOVE_ORDER_FROM_BASKET:
      return {
        ...state,
        basket: state.basket.filter((item: any) => item.id !== payload),
      };
    case RESET_BASKET:
      return {
        ...state,
        basket: [],
      };
    case SET_ORDER_DATA:
      return {
        ...state,
        orderData: { ...payload },
      };
    case SET_DELIVERYTIME:
      return {
        ...state,
        deliveryTime: payload,
      };
    case ADD_PARCEL_TO_QUICKBOOK:
      return {
        ...state,
        packageDetails: {
          ...state.packageDetails,
          parcel: [...payload],
        },
      };
      case SET_BASKET_ITEM_ID:
        return {
          ...state,
          basketItemId: payload
        }
    default:
      return state;
  }
};

export default orderReducer;
