import React, { useEffect, useState } from 'react';
import { Wrapper, Tile, TileWrapper, Title } from './elements';

const InsuranceRates = ({
  insuranceRates,
  onChange,
  selected,
  isDisabled,
}: any) => {
  const [rates, setRates] = useState<any>([]);

  const generateInsurance = (insuranceData: any) => {
    let tempI = [];

    if (insuranceData.length > 3) {
      tempI = [
        insuranceData[0],
        insuranceData[insuranceData.length - 2],
        insuranceData[insuranceData.length - 1],
      ];
    } else {
      tempI = insuranceData;
    }

    return tempI.map((item: any) => ({
      label: `${
        item.price === 0 ? 'Free' : `£${item.price}`
      } - Provider covers up to £${item.level}`,
      value: {
        ...item,
        insuranceId: item.id,
      },
    }));
  };

  useEffect(() => {
    setRates(generateInsurance(insuranceRates));
  }, [insuranceRates]);

  const handleClick = (value: any) => {
    if (isDisabled) {
      return;
    }

    onChange(value);
  };

  return (
    <Wrapper>
      <Title>Insurance</Title>

      <TileWrapper>
        {
          rates.map((rate: any, i: any) => (
            <Tile
              key={i}
              onClick={() => handleClick(rate.value)}
              selected={selected.id === rate.value.insuranceId}
              isDisabled={isDisabled}
            >
              { rate.label }
            </Tile>
          ))
        }
      </TileWrapper>
    </Wrapper>
  );
};

export default InsuranceRates;
