import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Input, Label, Caption, Container } from '../../../../../../../assets/elements';
import { SelectInput } from '../../../../../Inputs';
import Required from '../../../../../../../assets/elements/Required';
import device from '../../../../../../utils/device';

const BookerEmail = ({
  bookerEmail,
  setBookerEmail,
  error,
  setError,
  errorMessage,
  setErrorMessage
}: any) => {

  const handleChange = (value: any) => {
    setError(false);
    setErrorMessage('');
    setBookerEmail(value);
  };

  return (
    <Wrapper>
      <H2>Your email address</H2>
      <LabelStyled><Required />Email address</LabelStyled>
      <Input
        placeholder="Your email address"
        value={bookerEmail}
        onChange={(e: any) => handleChange(e.target.value)}
        theme={undefined}
        type="text"
        error={error}
        autoComplete="off"
      />
      <ErrorText>{errorMessage}</ErrorText>
    </Wrapper>
  );
};
const H2 = styled.h2`
  font-size: 32px;
  line-height: 135%;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: normal;
  margin-top: 30px;
  color: #777e7e;
`;
const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: block;
  margin-top: 4px;
`;

const Wrapper = styled(Container)`
  background: #ffffff;
  border-radius: 8px;
  && {
    padding: 15px;
    @media ${device.tablet} {
      padding: 30px;
    }
  }
  height: auto;
  margin-bottom: 40px;
`;

export default BookerEmail;
