import { combineReducers } from 'redux';
import mainReducer from './mainReducer';
import orderReducer from './orderReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers<any>({
  main: mainReducer,
  order: orderReducer,
  user: userReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
