import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Label } from '../../../assets/elements';
import { InputContainer } from '../MyAccount/components/AddressBook/components/Elements';
import { SelectInput } from '../Inputs';
import { getAddresses } from '../../utils/APICalls/Account';

const SavedAddress = ({
  address,
  setAddress,
  setMobileNumber = () => {},
}: any) => {
  const [data, setData] = useState([]);
  const handleAddressChange = (value: any) => {
    const { phone, ...temp } = value;

    setMobileNumber(phone);
    setAddress({
      ...address,
      ...temp,
    });
  };

  useEffect(() => {
    getAddresses()
      .then((res: any) => {
        setData(res.data.map((item: any) => {
          return {
            value: {
              company: item?.company || '',
              addressOne: item?.addressLine1 || '',
              addressTwo: item?.addressLine2 || '',
              addressThree: item?.addressLine3 || '',
              city: item?.city || '',
              country: item?.country || '',
              zip: item?.zip || '',
              phone: item?.phone || '',
            },
            label: `${item.addressLine1 ? `${item.addressLine1}, ` : ''}
                    ${item.addressLine2 ? `${item.addressLine2}, ` : ''}
                    ${item.addressLine3 ? `${item.addressLine3}, ` : ''}
                    ${item.city ? `${item.city}, ` : ''}
                    ${item.zip ? `${item.zip}, ` : ''}
                    ${item.country ? `${item.country}` : ''}`,
          };
        }));
      });
  }, []);

  return (
    <Wrapper>
      <InputContainer>
        <LabelStyled>Choose address</LabelStyled>
        <SelectInput
          title="Please select"
          data={data}
          error={false}
          resetError={() => []}
          defaultValue="GB"
          onChange={handleAddressChange}
        />
      </InputContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

export default SavedAddress;
