import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Moment from 'react-moment';
import moment from 'moment';
import { A, Button, Column, P, Row } from '../../../../../assets/elements';
import InfoIcon from '../../../../../assets/icons/Info.svg';
import PrinterIcon from '../../../../../assets/icons/Printer.svg';
import device from '../../../../utils/device';
import { dateFormat } from '../../../../utils/variables';
import { SelectInput } from '../../../Inputs';
import { setParcelCollectionDate } from '../../../../store/actions';
import InsuranceInfo from '../InsuranceInfo';

const SingleResult = ({
  id,
  name,
  logo,
  status = '',
  deliveryTime,
  collectionDate,
  collectionDays,
  price,
  priceIncVat,
  type,
  printer,
  customs,
  moreInfo,
  setMoreInfo,
  openMoreInfoModal,
  dropoffPoints = [],
  editDetails = () => {},
  onSelect,
  holidays,
  sameday,
  openLocations,
  setLocations,
  pricesBeforeDiscount,
  coveredInsurance = 0,
  openInsurance,
  setOpenEmailModal,
  serviceInformation,
  setServiceInfo,
  openServiceInfoModal,
  setServiceInfoTitle,
}: any) => {
  const dispatch = useDispatch();

  const {
    collectionLocation: { postcode = '' },
  } = useSelector((state: any) => state.main.parcel);

  const [nearestDropoff, setNearestDropoff] = useState<any>('');

  const [collectionDates, setCollectionDates] = useState<any>([]);

  const handleSubmit = () => {
    onSelect(id);
  };

  const deliveryTimeFormat = (days: number) =>
    `${days} ${days === 0 || days > 2 ? 'days' : 'day'}`;

  const handleMoreInfoClick = () => {
    setMoreInfo(moreInfo);
    ReactTooltip.hide();
    openMoreInfoModal();
  };

  const handleServiceInfoClick = () => {
    if (serviceInformation) {
      setServiceInfoTitle(serviceInformation.title)
      setServiceInfo(serviceInformation);
      ReactTooltip.hide();
      openServiceInfoModal();
    }
  };

  useEffect(() => {
    if (dropoffPoints.length > 0) {
      const { distance = {} } = dropoffPoints[0];
      setNearestDropoff(`${Number(distance?.amount).toFixed(2)}${distance?.unit}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type === 'collection') {
      setCollectionDates(generateCollectionDates(holidays));
    }
  }, [holidays]);

  const generateCollectionDates = (holidayList: any) => {
    let days = 5;
    const dates: any[] = [];
    const tempDate = moment(collectionDate).subtract(1, 'days');

    while (days > 0) {
      const newDate = tempDate.add(1, 'days');

      if (
        holidayList.filter((holiday: any) => moment(holiday).isSame(moment(newDate), 'days')).length === 0 &&
        collectionDays.includes(newDate.isoWeekday())
      ) {
        dates.push({
          label: newDate.format(dateFormat),
          value: newDate.format('yyyy-MM-DD'),
        });
        days -= 1;
      }
    }

    return dates;
  };

  const handleDropClick = (click: any) => {
    if (!click) {
      setLocations(dropoffPoints);
      openLocations(true);

      return;
    }

    editDetails();
  };

  const setCollectionDate = (date: any) => {
    dispatch(setParcelCollectionDate({ id, date }));
  };

  return (
    <RowStyled>
      <ColumnStyled sizeL={2} sizeM={2} sizeXS={4}>
        <Brand>
          <BrandImg src={logo} alt={`${name} logo`} />
        </Brand>
      </ColumnStyled>
      <ColumnStyled sizeL={3} sizeM={10} sizeXS={4}>
        <InfoContainer>
          <TitleContainer>
            <P>{name}</P>
            <IconsContainer>
              <Icon
                onClick={serviceInformation ? handleServiceInfoClick : handleMoreInfoClick}
                data-tip
                data-for="info-icon"
                src={InfoIcon}
              />
              <ReactTooltip id="info-icon" effect="solid">
                More information
              </ReactTooltip>

              {printer ? (
                <>
                  <Icon data-tip data-for="printer-icon" src={PrinterIcon} />
                  <ReactTooltip id="printer-icon" effect="solid">
                    Printer required
                  </ReactTooltip>
                </>
              ) : (
                ''
              )}
            </IconsContainer>
          </TitleContainer>
          <InsuranceInfo coveredInsurance={coveredInsurance} openInsurance={openInsurance} />
        </InfoContainer>
      </ColumnStyled>
      <ColumnStyledSmall sizeL={2} sizeM={3} sizeXS={2}>
        <ParcelTypeContainer type={type}>
          <ParcelTypeHead type={type}>
            {type === 'collection' ? 'Collection' : 'Drop off'}
          </ParcelTypeHead>
          <ParcelTypeData>
            {type === 'collection' && (
              <>
                {
                  sameday ? (
                    <Moment format={dateFormat}>{collectionDate}</Moment>
                  ) : (
                    <SelectContainer>
                      <SelectInput
                        error={false}
                        resetError={() => {}}
                        value={collectionDate}
                        onChange={(selected: any) => setCollectionDate(selected)}
                        data={collectionDates}
                        height="40px"
                        width="100%"
                        minWidth="unset"
                        fontSize="14px"
                      />
                    </SelectContainer>
                  )
                }
              </>
            )}
            {type === 'dropoff' && (
              <DropOffMessage onClick={() => handleDropClick(postcode === '')}>
                {postcode !== ''
                  ? `Nearest drop off ${nearestDropoff}`
                  : 'Add a postcode for your nearest drop off service'}
              </DropOffMessage>
            )}
          </ParcelTypeData>
        </ParcelTypeContainer>
      </ColumnStyledSmall>
      <ColumnStyledSmall sizeL={2} sizeM={3} sizeXS={2}>
        <DeliveryTimeContainer>
          <DeliveryTimeHead>Delivery time</DeliveryTimeHead>
          <DeliveryTimeData>
            {deliveryTimeFormat(deliveryTime)}
          </DeliveryTimeData>
        </DeliveryTimeContainer>
      </ColumnStyledSmall>
      <PriceColumn sizeL={1.6} sizeM={3} sizeXS={2}>
        <PriceContainer>
          <Price>{`£${price}`}</Price>
          {
            pricesBeforeDiscount && <PriceOriginal>£{ Number(pricesBeforeDiscount.price).toFixed(2) }</PriceOriginal>
          }
          {!customs && (
            <PriceVAT>incl. VAT {`£${priceIncVat}`}</PriceVAT>
          )}
        </PriceContainer>
      </PriceColumn>
      <Column sizeL={1.4} sizeM={3} sizeXS={2}>
        <ButtonContainer>
          <Button onClick={handleSubmit} color="secondary">
            Book
          </Button>
          <LinkWrapper onClick={() => setOpenEmailModal(true)}>Email Quote</LinkWrapper>
        </ButtonContainer>
      </Column>
    </RowStyled>
  );
};

const RowStyled = styled(Row)`
  background: ${({ theme }: any) => theme.colors.White};
  width: 100%;
  margin-bottom: 16px;
  padding: 24px;
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};
`;

const ColumnStyled = styled(Column)`
  margin-bottom: 26px;

  @media ${device.desktop} {
    margin-bottom: 0;
  }
`;

const ColumnStyledSmall = styled(Column)`
  margin-bottom: 26px;

  @media ${device.laptop} {
    margin-bottom: 0;
  }
`;

const Brand = styled.div``;
const BrandImg = styled.img`
  max-height: 88px;
  width: auto;
  max-width: 100px;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Status = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #777e7e;
  flex: none;
  order: 1;
  align-self: flex-start;
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 76px;
  flex: 0 0 76px;
`;

const Icon = styled.img``;

const ParcelTypeContainer = styled.div.attrs(({ type }: any) => ({
  type,
}))`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: ${({ type, theme }: any) =>
    type === 'collection' ? theme.colors.CollectionBackground : theme.colors.DropoffBackground};
  align-items: center;
  border-radius: 4px;
  height: 100%;
`;

const ParcelTypeHead = styled.div.attrs(({ type }: any) => ({
  type,
}))`
  background: ${({ type, theme }: any) =>
    type === 'collection' ? theme.colors.CollectionHead : theme.colors.DropoffHead};
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  color: ${({ theme }: any) => theme.colors.White};
  padding: 7px 4px;
  justify-content: center;
`;

const ParcelTypeData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 19px;
  line-height: 150%;
  font-weight: 500;
  margin-top: 13px;
  width: 100%;
`;

const DeliveryTimeContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  color: ${({ theme }: any) => theme.colors.Black};
  border-radius: 4px;
  height: 100%;
`;

const DeliveryTimeHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  padding: 7px 4px;
`;

const DeliveryTimeData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 19px;
  line-height: 150%;
  font-weight: 500;
  margin-top: 13px;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 8px;
  height: 100%;

  @media ${device.desktop} {
    height: auto;
  }
`;

const PriceHead = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const Price = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 135%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 4px;
`;

const PriceVAT = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const DropOffMessage = styled(A)`
  text-decoration: underline;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 135%;
  color: ${({ theme }: any) => theme.colors.DropoffHead};
`;

const SelectContainer = styled.div`
  display: flex;
  padding: 5px;
  width: 100%;
`;

const PriceColumn = styled(Column)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PriceOriginal = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-decoration: line-through;
  margin-bottom: 4px;
`;

const LinkWrapper = styled(A)`
width: 100%;
text-align: center;
margin-top: 0.7rem;
font-size: 0.8rem;
white-space: nowrap;
    &:hover {
    color: ${({ theme }: any) => theme.colors.SecondaryHover};
  }
`;

export default SingleResult;
