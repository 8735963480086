import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ContinueButton from '../Elements/ContinueButton';
import {
  addOrderToBasket,
  resetState,
  setDropoffPoint,
  setParcelQuotes, setParcelQuotesAction,
  setSelectedService as setSelectedServiceAction,
} from '../../../../store/actions';
import { P, PSmall } from '../../../../../assets/elements';
import Loader from '../../../Loader';
import NotFound from '../../../NotFound';
import SingleQuote from '../Elements/SingleQuote';
import Modal from '../../../../../assets/Combos/Modal';
import { GTMECommerce } from '../../../../utils/tracking';
import { addNewAddress } from '../../../../utils/APICalls/Account';
import device from '../../../../utils/device';
import CheckBoxInput from '../../../Inputs/CheckBoxInput';
import { sort } from '../../../../utils/sorting';
import { companyName } from '../../../../utils/env';
import { getUnavailableDates } from '../../../../utils/APICalls/UnavailableDates';

const Service = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const parcel = useSelector((state: any) => state.order.packageDetails.parcel);

  const { country: collectionCountry, zip: collectionZip } =
    useSelector((state: any) => state.order.collectionAddress);

  const deliveryAddress =
    useSelector((state: any) => state.order.deliveryAddress);

  const recipientDetails =
    useSelector((state: any) => state.order.recipientDetails);

  const addressBookToggle =
    useSelector((state: any) => state.order.addressBookToggle);

  const loading = useSelector((state: any) => state.main.loading.quotes);
  const quotes = useSelector((state: any) => state.main.parcelQuotes);

  const [selectedService, setSelectedService] = useState<any>('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [moreInfoValue, setMoreInfoValue] = useState<any>({});
  const [moreInfoText, setMoreInfoText] = useState<any>();
  const [moreInfoModal, setMoreInfoModal] = useState(false);
  const [conditions, setConditions] = useState(false);
  const [dropoffFilter, setDropoffFilter] = useState(false);
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    dispatch(setParcelQuotes({
      collectionLocation: {
        postcode: collectionZip,
        country: collectionCountry,
      },
      deliveryLocation: {
        postcode: deliveryAddress.zip,
        country: deliveryAddress.country,
      },
      packageDetails: parcel,
    }));
  }, [parcel]);

  useEffect(() => {
    if (selectedService === '' || !conditions) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [selectedService, conditions]);

  const handleSubmit = () => {
    const error = (selectedService === '') || !conditions;

    if (error) {
      return;
    }

    const selectedQuote = quotes.filter(({ id }: any) => id === selectedService)[0];

    if (addressBookToggle) {
      addNewAddress(formatAddress());
    }

    if (selectedQuote.type === 'dropoff') {
      const dropoffPoint = selectedQuote.dropoffPoints[0];

      dispatch(setDropoffPoint({
        id: dropoffPoint.id,
        postcode: collectionZip,
        countryCode: collectionCountry,
      }));
    }

    GTMECommerce({
      currencyCode: 'GBP',
      add: {
        products: [{
          name: `${selectedQuote?.carrier ? `${selectedQuote.carrier} - ` : ''}${selectedQuote.name}`,
          id: `${selectedQuote.rateId}-${selectedQuote.serviceId}-${selectedQuote.type}`,
          price: selectedQuote.priceIncVat,
          category: 'parcel',
          variant: selectedQuote.type,
          quantity: 1,
        }],
      },
    },
    'addToCart');

    dispatch(setSelectedServiceAction(selectedQuote, 'parcel'));
    dispatch(addOrderToBasket());
    dispatch(resetState());

    history.push('/order-basket');
  };

  useEffect(() => {
    if (collectionCountry) {
      getUnavailableDates(collectionCountry)
        .then((res: any) => {
          setHolidays(res.data.map((holidayDate: any) => new Date(holidayDate)));
        });
    }
  }, [collectionCountry]);

  const formatAddress = () => {
    const newAddress = { ...deliveryAddress, ...recipientDetails };

    newAddress.addressLine1 = newAddress.addressOne;
    newAddress.addressLine2 = newAddress.addressTwo;
    newAddress.addressLine3 = newAddress.addressThree;
    newAddress.isDefault = false;

    delete newAddress.adressOne;
    delete newAddress.adressTwo;
    delete newAddress.adressThree;

    return newAddress;
  };

  const handleSelect = (id: any) => {
    setSelectedService(id);
  };

  const setMoreInfoData = (value: any) => {
    setMoreInfoValue(value);
  };

  const handleMoreInfoClose = () => {
    setMoreInfoModal(false);
    setMoreInfoData({});
  };

  useEffect(() => {
    if (moreInfoValue.intro) {
      setMoreInfoText(
        <>
          <PSmallStyled>{moreInfoValue.intro}</PSmallStyled>
          <ul>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                ️Delivery: {moreInfoValue.deliveryDays}
              </PSmall>
            </Li>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                ️Delivery window: {moreInfoValue.deliveryWindow}
              </PSmall>
            </Li>
            {moreInfoValue.tracking && (
              <Li>
                <PSmall>
                  <span role="img" aria-label="Dot">
                    ◾
                  </span>
                  ️Detailed tracking notifications
                </PSmall>
              </Li>
            )}
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                ️{moreInfoValue.redeliveryAttempts + 1} delivery attempts
              </PSmall>
            </Li>
            {moreInfoValue.podOnRequest && (
              <Li>
                <PSmall>
                  <span role="img" aria-label="Dot">
                    ◾
                  </span>
                  ️Proof of delivery upon request
                </PSmall>
              </Li>
            )}
          </ul>
        </>
      );
    }
  }, [moreInfoValue]);

  useEffect(() => {
    dispatch(setParcelQuotesAction(sort([...quotes].map((quote: any) => {
      return {
        ...quote,
        active: dropoffFilter ? quote.type === 'dropoff' : true,
      };
    }), 'price')));
  }, [dropoffFilter]);

  return (
    <>
      {
        loading && <Loader>Finding quotes</Loader>
      }
      {
        !loading && (quotes.length === 0 ? (
          <NotFound>
            Sorry! No results found.
            Please update your item details to find a quote.
          </NotFound>
        ) : (
          <Wrapper>
            <Title>Choose a service</Title>
            <CheckBoxInput
              checked={dropoffFilter}
              label="Show only Drop-off services"
              onChange={() => setDropoffFilter(!dropoffFilter)}
            />
            <QuotesWrapper>
              {
                quotes.map(({
                  id,
                  rateId,
                  name,
                  logo,
                  status,
                  collectionDate = '',
                  deliveryTime = 1,
                  price,
                  priceIncVat,
                  type,
                  printer,
                  moreInfo,
                  dropoffPoints,
                  active,
                  pricesBeforeDiscount = null,
                  sameday,
                  collectionDays,
                }: any) => {
                  return (
                    <SingleQuote
                      key={`${id}-${rateId}`}
                      id={id}
                      name={name}
                      logo={logo}
                      status={status}
                      deliveryTime={deliveryTime}
                      collectionDate={collectionDate}
                      price={price}
                      priceIncVat={priceIncVat}
                      pricesBeforeDiscount={pricesBeforeDiscount}
                      type={type}
                      printer={printer}
                      moreInfo={moreInfo}
                      dropoffPoints={dropoffPoints}
                      setMoreInfo={setMoreInfoData}
                      openMoreInfoModal={() => setMoreInfoModal(true)}
                      onSelect={handleSelect}
                      selected={selectedService === id}
                      active={active}
                      sameday={sameday}
                      holidays={holidays}
                      collectionDays={collectionDays}
                    />
                  );
                })
              }
            </QuotesWrapper>
            <CheckBoxInput
              checked={conditions}
              label={`I agree that the information entered into this booking is correct and the items are packaged in accordance with the packaging guidelines. I confirm that I have read and understood the restricted & prohibited items list for details of goods that carry restrictions on all services. I understand no claim for loss or damage can be made if the goods sent are on either of these lists. I understand that if the size and/or weight of the parcel is incorrect, I may be liable for additional charges, and the delivery of the parcel will be delayed until full payment is made. I have read and agree to the terms & conditions of ${ companyName }.`}
              onChange={() => setConditions(!conditions)}
            />
            <ContinueButton
              onClick={handleSubmit}
              disable={buttonDisabled}
            >
              Add to basket
            </ContinueButton>
          </Wrapper>
        ))
      }

      <Modal
        title="More information"
        open={moreInfoModal}
        close={() => handleMoreInfoClose()}
        back={true}
        width={8}
      >
        {moreInfoText}
      </Modal>
    </>
  );
};

const PSmallStyled = styled(PSmall)`
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 24px;
`;

const Li = styled.li`
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  border-radius: 8px;
  background: ${({ theme }: any) => theme.colors.White};
  padding: 24px;
  margin-top: 16px;
`;

const QuotesWrapper = styled.div`
  max-height: 1450px;
  overflow: auto;
  margin: 16px 0 24px;

  @media ${device.laptopL} {
    max-height: 1150px
  }
`;

const Title = styled(P)`
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 24px;
`;

export default Service;
