import React from 'react';
import styled from 'styled-components';
import { H6, PSmall } from '../../../../../../assets/elements';
import Modal from '../../../../../../assets/Combos/Modal';
import { ASSETS_URL } from '../../../../../utils/variables';

const PackageTypes = ({
  open,
  close,
}: any) => {
  return (
    <Modal
      open={open}
      close={close}
      width={6}
      title="Package types"
      TitleElement={H6}
    >
      <ImagesRow>
        <ImagesColumn>
          <Image src={`${ASSETS_URL}icons/fullpallet.svg`} />
          <ImageCaption>Pallet</ImageCaption>
        </ImagesColumn>
        <ImagesColumn>
          <Image src={`${ASSETS_URL}icons/boxes.svg`} />
          <ImageCaption>Box</ImageCaption>
        </ImagesColumn>
        <ImagesColumn>
          <Image src={`${ASSETS_URL}icons/crates.svg`} />
          <ImageCaption>Crate</ImageCaption>
        </ImagesColumn>
      </ImagesRow>
      <PSmallStyled>
        Maximum weight per piece: 3000kg
        <br />
        Maximum volume per piece: 13.5cbm
        <br />
        1 piece = 1 pallet, 1 crate or 1 box
      </PSmallStyled>
      <PSmallStyled>
        Maximum weight per shipment: 3629kg
        <br />
        Maximum volume per shipment: 14.15cbm
      </PSmallStyled>
    </Modal>
  );
};

const ImagesRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
`;

const ImagesColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Image = styled.img``;

const ImageCaption = styled.span`
  display: block;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  line-height: 19px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  margin-top: 16px;
`;

const PSmallStyled = styled(PSmall)`
  line-height: 200%;

  &:last-of-type {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px dashed ${({ theme }: any) => theme.colors.LightGrey};
  }
`;

export default PackageTypes;
