export const getPersistedState = (path: any) => {
  try {
    const state = localStorage.getItem(`e-gistics-state.${path}`);

    return state === null ? undefined : JSON.parse(state);
  } catch {
    return undefined;
  }
};

export const persist = (value: any, path: any) => {
  try {
    const state = JSON.stringify(value);
    localStorage.setItem(`e-gistics-state.${path}`, state);
  } catch {
    // ignore write errors
  }
};
