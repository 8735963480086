import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const SingleMethod = ({
  method: {
    title,
    id,
    images,
    onlyLoggedIn,
  },
  selected,
  setSelected,
}: any) => {
  const loggedIn = useSelector<any, any>(
    (state: any) => state.user.session.accessToken !== ''
  );

  return (onlyLoggedIn && !loggedIn) ? <></> :
    (
      <Wrapper selected={selected} onClick={() => setSelected(id)}>
        <Title selected={selected}>{title}</Title>
        <ImageContainer>
          {images.map((image: any, i: any) => (
            <img src={image.url} alt={image.title} key={i} />
          ))}
        </ImageContainer>
      </Wrapper>
    );
};

const Wrapper = styled.div.attrs(({ selected }: any) => ({
  selected,
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: ${({ theme }: any) => theme.colors.White};
  border: 2px solid
    ${({ theme, selected }: any) =>
    selected ? theme.colors.Secondary : '#F2F2F2'};
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  margin-bottom: 16px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.div.attrs(({ selected }: any) => ({
  selected,
}))`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }: any) => theme.colors.Black};
  padding-left: 27px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 1px;
    width: 8px;
    height: 8px;
    padding: 4px;
    border: 2px solid
      ${({ theme, selected }: any) =>
    selected ? theme.colors.Secondary : '#F2F2F2'};
    border-radius: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    left: 5.4px;
    top: 6.4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${({ theme, selected }: any) =>
    selected ? theme.colors.Secondary : theme.WhiteColor};
  }
`;

const ImageContainer = styled.div`
  display: flex;

  & > img {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export default SingleMethod;
