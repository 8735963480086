import React from 'react';
import styled from 'styled-components';

const TableBody = ({ children }: any) => {
  return (
    <Wrapper>
      { children }
    </Wrapper>
  );
};

const Wrapper = styled.tbody`
  background: ${({ theme }: any) => theme.colors.White};
  border-bottom: 1px solid ${({ theme }: any) => theme.colors.WhiteGrey};
`;

export default TableBody;
