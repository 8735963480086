import React from 'react';
import styled from 'styled-components';
import { PSmall } from '../../../../../assets/elements';

const ServiceInformation = (serviceInfo: any) => {
  let index = 0
  return(
      <>
        <SubTitleBoldTop>
          {serviceInfo.subTitle1}
          <br />{serviceInfo.subTitle2}
        </SubTitleBoldTop>
        <PSmallStyled dangerouslySetInnerHTML={ { __html: serviceInfo.description } } />
        <ul>
          {serviceInfo.usp.map((usp: any) => {
            index++
            return (
              <Li key={index}>
                <PSmall>
                  <span role="img" aria-label="Dot">
                    ◾
                  </span>
                  {' '}{usp}
                </PSmall>
              </Li>
            )
          })}
        </ul>
      </>
  )
}

const Li = styled.li`
  margin-bottom: 16px;
`;

const PSmallStyled = styled(PSmall)`
  margin-bottom: 16px;
`;

const SubTitle = styled.p<any>`
  margin: 20px 0;
  line-height: 150%;
  color: #777e7e;
`;

const SubTitleBoldTop = styled(SubTitle)`
  font-weight: bold;
  margin-top: 0;
`;


export default ServiceInformation