import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Theme } from '../Interfaces';

interface InputProps {
  theme: Theme | undefined;
  value: string | number;
  type: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
  autoComplete: string;
}

export const Input = (props: any) => {
  return (
    <>
      <InputStyled
        type={props.type}
        placeholder={props.placeholder}
        theme={props.theme}
        onChange={props.onChange}
        value={props.value}
        error={props.error}
        onBlur={props.onBlur || null}
        autoComplete={props.autoComplete || 'on'}
        disabled={props.disabled || false}
        width={props.width || '100%'}
        ref={props.Ref || null}
      />
    </>
  );
};

const InputStyled = styled.input.attrs((props: any) => ({
  type: props.type,
  placeholder: props.placeholder,
  onChange: props.onChange,
  error: props.error,
  onBlur: props.onBlur,
  autoComplete: props.autoComplete || 'on',
  disabled: props.disabled || false,
  width: props.width || '100%',
}))`
  border: 2px solid
    ${({ theme, error }: any) =>
    error ? theme.colors.Error : theme.colors.LightGrey};
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  background: ${({ theme, disabled }: any) => disabled ? '#f2f2f2' : theme.colors.White};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  color: ${({ theme }: any) => theme.colors.Black};
  width: ${({ width }: any) => width};
  padding: 12px 14px;
  outline: unset;

  &::placeholder {
    color: ${({ theme }: any) => theme.colors.LightGrey};
  }

  &:focus {
    border-color: ${({ theme, error }: any) =>
    error ? theme.ErrorColor : theme.SecondaryColor};
  }
`;
