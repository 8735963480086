import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { H4 } from '../../../../../../../../assets/elements';
import PostcodeBox from './PostcodeBox';
import ManuallyBox from './ManuallyBox';
import CheckBoxInput from '../../../../../../Inputs/CheckBoxInput';

const TypeDelivery = (props: any) => {
  const handleTypeChange = () => {
    window.scrollTo(0, 0);
    props.setInputManually(!props.inputManually);
  };

  const { zip: postcode } = useSelector<any, any>(
    (state) => state.order.deliveryAddress
  );

  const loggedIn = useSelector<any, any>(
    (state: any) => state.user.session.accessToken !== ''
  );

  useEffect(() => {
    postcode.length && props.setInputManually(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = () => {
    props.setAddToAddressBook(!props.addToAddressBook);
  };

  return (
    <>
      <H4>Delivery address</H4>
      {props.addressIncomplete && (
        <Error>Please fill all the required information.</Error>
      )}
      {!props.inputManually ? (
        <>
          <PostcodeBox {...props} setManually={props.setInputManually} setErrors={props.setErrors} />

          {
            loggedIn && (
              <AddressBookRow>
                <CheckBoxInput
                  checked={props.addToAddressBook}
                  label="Add to address book"
                  onChange={handleCheckboxChange}
                />
              </AddressBookRow>
            )
          }
        </>
      ) : (
        <>
          <ManuallyBox {...props} />

          {
            loggedIn && (
              <AddressBookRow>
                <CheckBoxInput
                  checked={props.addToAddressBook}
                  label="Add to address book"
                  onChange={handleCheckboxChange}
                />
              </AddressBookRow>
            )
          }
        </>
      )}
      <ActionText onClick={() => handleTypeChange()}>
        {!props.inputManually ? 'Enter address manually' : 'Enter postcode'}
      </ActionText>
    </>
  );
};

const ActionText = styled.p`
  margin-top: 50px;
  cursor: pointer;
  color: ${({ theme }: any) => theme.colors.Secondary};
`;

const Error = styled.div`
  display: inline-block;
  padding: 12px;
  background: rgba(224, 76, 102, 0.2);
  border-radius: 4px;
  color: ${({ theme }: any) => theme.colors.Error};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin: 12px 0;
`;

const AddressBookRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
`;

export default TypeDelivery;
