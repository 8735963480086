import { create } from 'axios';
import { apiUrl as baseURL, landingPageUrl, token } from '../env';
import { serverErrors } from './serverErrors';
import { deleteCookie, getCookie } from '../cookies';

const axios = create({
  baseURL,
  headers: {
    common: { 'Content-Type': 'application/json' },
  },
});

// create global error handling logic
const handleErrors = (error) => {
  const { response: { status } = {} } = error || {};
  if (status === 403 || status === 401) {
    /* eslint-disable no-console */
    console.log('Unauthorised request.');

    deleteCookie([
      'userId',
      'userName',
      'accessToken',
      'refreshToken',
      'refreshTokenExpiry',
      'accessTokenExpiry',
    ]);

    window.location.href = landingPageUrl;
  }

  return Promise.reject(serverErrors(error));
};

// global request handling
axios.interceptors.response.use(
  (response) => response,
  (error) => handleErrors(error),
);

axios.interceptors.request.use(
  (config) => {
    const request = config;

    if (token) {
      request.headers.common['x-api-token'] = token;
    }

    const accessToken = getCookie('accessToken');

    if (accessToken) {
      request.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    return request;
  },
  (error) => Promise.reject(error),
);

const axiosFile = create({
  baseURL,
  headers: { 'Content-Type': 'multipart/form-data;boundary:abcd--' },
});

// global request handling
axiosFile.interceptors.response.use(
  (response) => response,
  (error) => handleErrors(error),
);

axiosFile.interceptors.request.use(
  (config) => {
    const request = config;

    if (token) {
      request.headers.common['x-api-token'] = token;
    }

    const accessToken = getCookie('accessToken');

    if (accessToken) {
      request.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    return request;
  },
  (error) => Promise.reject(error),
);

export default axios;
export { axiosFile };