import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { InputContainer, Label } from '../MyAccount/components/AddressBook/components/Elements';
import { Caption, Input } from '../../../assets/elements';
import { SelectInput } from '../Inputs';
import useDebounce from '../../utils/useDebounce';
import { fetchPostCoder } from '../../utils/APICalls/Postcoder';
import MobileNumber from '../../../assets/Combos/MobileNumber';
import { countriesPostalCodesData } from '../../mocks/quoteData';
import { validateMobile } from '../../utils/validateMobile';

const postcoderAddressKeys = {
  addressOne: 'addressOne',
  addressTwo: 'addressTwo',
  addressThree: 'addressThree',
};

const PostcodeAddress = ({
  theme,
  address,
  setAddress,
  errors,
  setErrors,
  phone = false,
  country = false,
  addressKeys = postcoderAddressKeys,
  setManual = () => {},
}: any) => {
  const [data, setData] = useState<any>([]);

  const debouncedSearchTerm = useDebounce(address.zip, 500);
  const debouncedSearchTermC = useDebounce(address.country, 500);

  useEffect(() => {
    if (debouncedSearchTerm || debouncedSearchTermC) {
      fetchPostCoder(address, setData, addressKeys, setManual);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, debouncedSearchTermC]);

  const handleChange = (value: any, key: any) => {
    if (
      (key === 'company' ||
        key === addressKeys.addressOne ||
        key === addressKeys.addressTwo ||
        key === 'city') &&
      value.length > 30
    ) {
      setErrors({
        ...errors,
        [key]: 'This must be less than 30 characters',
      });

      setManual(true);
    } else if (key === 'phone' && value.length > 0 && validateMobile(value)) {
      setErrors({
        ...errors,
        [key]: validateMobile(value, true),
      });
    } else if (key in address) {
      setErrors({
        ...errors,
        [key]: false,
      });
    }

    setAddress({
      ...address,
      [key]: value,
    });
  };

  const handleAddressChange = (value: any) => {
    if (
      value.company.length > 30 ||
      value[addressKeys.addressOne]?.length > 30 ||
      value[addressKeys.addressTwo]?.length > 30 ||
      value.city.length > 30
    ) {
      setManual(true);
    }

    setErrors({
      ...errors,
      company: value.company.length > 30 ? 'This must be less than 30 characters' : false,
      addressOne: value[addressKeys.addressOne]?.length > 30 ? 'This must be less than 30 characters' : false,
      addressTwo: value[addressKeys.addressTwo].length > 30 ? 'This must be less than 30 characters' : false,
      city: value.city.length > 30 ? 'This must be less than 30 characters' : false,
      country: false,
      zip: false,
    });

    setAddress({
      ...address,
      ...value,
    });
  };

  return (
    <Wrapper>
      <PostcodeRow>
        <InputContainer half>
          <LabelStyled>Postcode</LabelStyled>
          <Input
            theme={theme}
            value={address.zip}
            type="text"
            placeholder="Postcode"
            onChange={(e: any) => handleChange(e.target.value, 'zip')}
            error={errors.zip}
          />
        </InputContainer>
        {
          country && (
            <InputContainer half>
              <LabelStyled>Country</LabelStyled>
              <SelectInput
                title="Please select"
                data={countriesPostalCodesData}
                error={false}
                resetError={() => []}
                defaultValue="GB"
                value={address.country}
                onChange={(value: any) => handleChange(value, 'country')}
                height="50px"
              />
            </InputContainer>
          )
        }
      </PostcodeRow>

      <InputContainer>
        <LabelStyled>Address</LabelStyled>
        <SelectInput
          title="Please select"
          data={data}
          error={false}
          resetError={() => []}
          defaultValue="GB"
          onChange={handleAddressChange}
        />
      </InputContainer>
      {
        phone && (
          <InputContainer>
            <LabelStyled>Mobile number required to receive free SMS notifications</LabelStyled>
            <MobileNumber
              country={address.country}
              value={address.phone}
              onChange={(selected: any) => handleChange(selected, 'phone')}
              error={errors.phone}
            />
          </InputContainer>
        )
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const PostcodeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export default PostcodeAddress;