import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import device from '../../utils/device';
import {
  A,
  Button,
  Caption,
  Column,
  Container,
  H4,
  Input,
  Label,
  Row,
} from '../../../assets/elements';
import PasswordCriteria from '../PasswordCriteria';
import { useQuery } from '../../utils/hooks/useQuery';
import { landingPageUrl } from '../../utils/env';
import { setNewPassword } from '../../utils/APICalls/Account';

const ResetPassword = ({ theme }: any) => {
  const history = useHistory();
  const query = useQuery();

  const [passwords, setPasswords] = useState({
    password: '',
    confirmPassword: '',
  });

  const [passwordsError, setPasswordsError] = useState<any>({
    password: false,
    confirmPassword: false,
  });

  const [settingPassword, setSettingPassword] = useState(false);

  const [focus, setFocus] = React.useState(false);

  const [criteriaMeet, setCriteriaMeet] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
    minimum: false,
  });

  const [resetError, setResetError] = useState<any>(false);

  const handlePasswordChange = (e: any) => {
    setPasswords({
      ...passwords,
      password: e.target.value,
    });
  };

  const handleConfirmChange = (e: any) => {
    const { value } = e.target;

    setPasswords({
      ...passwords,
      confirmPassword: value,
    });

    if (value === '') {
      setPasswordsError({
        ...passwordsError,
        confirmPassword: false,
      });
    } else if (value !== passwords.password) {
      setPasswordsError({
        ...passwordsError,
        confirmPassword: "The password doesn't match the one above.",
      });
    } else {
      setPasswordsError({
        ...passwordsError,
        confirmPassword: false,
      });
    }
  };

  const validateForm = () => {
    let error = false;
    let errors = { ...passwordsError };

    if (passwords.password === '') {
      errors = {
        ...errors,
        password: 'Required',
      };

      error = true;
    } else if (!validateCriteria()) {
      errors = {
        ...errors,
        password: 'Invalid password',
      };

      error = true;
    }

    if (passwords.confirmPassword === '') {
      errors = {
        ...errors,
        confirmPassword: 'Required',
      };

      error = true;
    } else if (passwords.password !== passwords.confirmPassword) {
      errors = {
        ...errors,
        confirmPassword: "The password doesn't match the one above.",
      };

      error = true;
    }

    setPasswordsError({ ...errors });

    return error;
  };

  const validateCriteria = () => {
    for (const [, value] of Object.entries(criteriaMeet)) {
      if (!value) {
        return false;
      }
    }

    return true;
  };

  const handleBack = () => {
    window.location.href = landingPageUrl;
  };

  const handleSubmit = (e: any) => {
    e.nativeEvent.preventDefault();

    setResetError(false);
    const error = validateForm();

    if (!error) {
      setSettingPassword(true);

      setNewPassword({
        token: query.get('token'),
        newPassword: passwords.password,
      })
        .then(() => {
          history.push('/login');
        })
        .catch(() => {
          setResetError('There was some problem. Please try again later.');
          window.scroll(0, 0);
        })
        .finally(() => {
          setSettingPassword(false);
        });
    }
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <Column sizeL={4} sizeM={4} sizeS={3} sizeXS={4}>
            <H4Styled>Create a new password</H4Styled>
            {resetError && <ErrorStyled>{resetError}</ErrorStyled>}
            <InputWrapper
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            >
              <LabelStyled>Password</LabelStyled>
              <Input
                placeholder="Enter your password"
                value={passwords.password}
                onChange={(e: any) => handlePasswordChange(e)}
                theme={theme}
                type="password"
                error={passwordsError.password}
              />
              <PasswordCriteria
                password={passwords.password}
                active={focus}
                criteriaMeet={criteriaMeet}
                setCriteriaMeet={setCriteriaMeet}
              />
              {passwordsError.password && (
                <ErrorText>{passwordsError.password}</ErrorText>
              )}
            </InputWrapper>
            <InputWrapper>
              <LabelStyled>Confirm password</LabelStyled>
              <Input
                placeholder="Confirm password"
                value={passwords.confirmPassword}
                onChange={(e: any) => handleConfirmChange(e)}
                theme={theme}
                type="password"
                error={passwordsError.confirmPassword}
              />
              {passwordsError.confirmPassword && (
                <ErrorText>{passwordsError.confirmPassword}</ErrorText>
              )}
            </InputWrapper>
            <ButtonsContainer>
              <Button onClick={handleSubmit} color="secondary">
                {settingPassword ? 'Setting password...' : 'Set password'}
              </Button>
              <AStyled onClick={handleBack}>Cancel</AStyled>
            </ButtonsContainer>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background: ${({ theme }: any) => theme.colors.GrayWhite};
  min-height: calc(100vh - 76px);
  display: flex;
  justify-content: center;
  padding: 32px 0;

  @media ${device.laptop} {
    padding: 48px 0;
  }
`;

const H4Styled = styled(H4)`
  text-align: center;
  margin-bottom: 32px;
  font-size: 25px;
  line-height: 34px;

  @media ${device.laptopL} {
    font-size: 25px;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  position: relative;
`;

const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: 400;
  text-align: left;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const AStyled = styled(A)`
  margin: 34px 0 48px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorStyled = styled.div`
  display: inline-block;
  padding: 12px;
  background: rgba(224, 76, 102, 0.2);
  border-radius: 4px;
  color: ${({ theme }: any) => theme.colors.Error};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin-bottom: 24px;
  text-align: center;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

export default ResetPassword;
