import React from 'react';
import { withTheme } from 'styled-components';

const AddIcon = ({
  theme,
  width = 12,
  height = 12,
}: any) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 2L3 5.84L7 10" stroke={theme.colors.Primary} strokeLinecap="round" />
    </svg>
  );
};

export default withTheme(AddIcon);
