import React from 'react';
import styled from 'styled-components';
import { InputContainer, Label } from '../MyAccount/components/AddressBook/components/Elements';
import { Caption, Input } from '../../../assets/elements';
import { SelectInput } from '../Inputs';
import { countriesPostalCodesData } from '../../mocks/quoteData';
import device from '../../utils/device';
import MobileNumber from '../../../assets/Combos/MobileNumber';
import { validateMobile } from '../../utils/validateMobile';

const ManualAddress = ({
  theme,
  address,
  setAddress,
  errors,
  setErrors,
  phone = false,
  wrapper = true,
}: any) => {
  const handleChange = (value: any, key: any) => {
    if (
      (key === 'company' ||
        key === 'addressOne' ||
        key === 'addressLine1' ||
        key === 'addressTwo' ||
        key === 'addressLine2' ||
        key === 'city') &&
      value.length > 30
    ) {
      setErrors({
        ...errors,
        [key]: 'This must be less than 30 characters',
      });
    } else if (key === 'phone' && value.length > 0 && validateMobile(value)) {
      setErrors({
        ...errors,
        [key]: validateMobile(value, true),
      });
    } else if (key in address) {
      setErrors({
        ...errors,
        [key]: false,
      });
    }

    setAddress({
      ...address,
      [key]: value,
    });
  };

  return (
    <Wrapper wrapper={wrapper}>
      <InputContainer>
        <LabelStyled>Address line 1</LabelStyled>
        <Input
          theme={theme}
          value={address.addressLine1 || address.addressOne}
          type="text"
          placeholder="Address line 1"
          onChange={(e: any) =>
            handleChange(
              e.target.value,
              address.addressLine1 ? 'addressLine1' : 'addressOne'
            )}
          error={address.addressLine1 ? errors.addressLine1 : errors.addressOne}
        />
        <ErrorText>{ address.addressLine1 ? errors.addressLine1 : errors.addressOne }</ErrorText>
      </InputContainer>
      <InputContainer>
        <LabelStyled>Address line 2 (optional)</LabelStyled>
        <Input
          theme={theme}
          value={address.addressLine2 || address.addressTwo}
          type="text"
          placeholder="Address line 2"
          onChange={(e: any) =>
            handleChange(
              e.target.value,
              address.addressLine2 ? 'addressLine2' : 'addressTwo'
            )}
          error={address.addressLine2 ? errors.addressLine2 : errors.addressTwo}
        />
        <ErrorText>{ address.addressLine2 ? errors.addressLine2 : errors.addressTwo }</ErrorText>
      </InputContainer>
      <InputContainer>
        <LabelStyled>Address line 3 (optional)</LabelStyled>
        <Input
          theme={theme}
          value={address.addressLine3}
          type="text"
          placeholder="Address line 3"
          onChange={(e: any) =>
            handleChange(
              e.target.value,
              address.addressLine3 ? 'addressLine3' : 'addressThree'
            )}
          error={address.addressLine3 ? errors.addressLine3 : errors.addressThree}
        />
        <ErrorText>{ address.addressLine3 ? errors.addressLine3 : errors.addressThree }</ErrorText>
      </InputContainer>
      <InputRow>
        <InputContainer>
          <LabelStyled>City</LabelStyled>
          <Input
            theme={theme}
            value={address.city}
            type="text"
            placeholder="City"
            onChange={(e: any) => handleChange(e.target.value, 'city')}
            error={errors.city}
          />
          <ErrorText>{ errors.city }</ErrorText>
        </InputContainer>
        <InputContainer>
          <LabelStyled>Postcode</LabelStyled>
          <Input
            theme={theme}
            value={address.zip}
            type="text"
            placeholder="Postcode"
            onChange={(e: any) => handleChange(e.target.value, 'zip')}
            error={errors.zip}
          />
          <ErrorText>{ errors.zip }</ErrorText>
        </InputContainer>
      </InputRow>
      <InputContainer>
        <LabelStyled>Country</LabelStyled>
        <SelectInput
          title="Please select"
          data={countriesPostalCodesData}
          error={false}
          resetError={() => []}
          defaultValue="GB"
          value={address.country}
          onChange={(value: any) => handleChange(value, 'country')}
        />
      </InputContainer>
      {
        phone && (
          <InputContainer>
            <LabelStyled>Mobile number required to receive free SMS notifications</LabelStyled>
            <MobileNumber
              country={address.country}
              value={address.phone}
              onChange={(selected: any) => handleChange(selected, 'phone')}
              error={errors.phone}
            />
          </InputContainer>
        )
      }
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs(({ wrapper }: any) => ({
  wrapper,
}))`
  background: ${({ wrapper }: any) => wrapper ? '#F2F2F2' : 'none'};
  border-radius: ${({ wrapper }: any) => wrapper ? '4px' : '0'};
  padding: ${({ wrapper }: any) => wrapper ? '16px' : '0'};;
  margin-bottom: 24px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  & > div {
    width: 100%;
    margin-bottom: 32px !important;
  }

  @media ${device.tablet} {
    flex-direction: row;

    & > div {
      width: 49%;
    }
  }
`;

const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

export default ManualAddress;