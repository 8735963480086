import { environment, Environments } from './env';

export const HomePage = {
  PARCEL: 'PARCEL',
  PALLET: 'PALLET',
  FREIGHT: 'FREIGHT',
};

const getHomePage = () => {
  if (
    environment.toUpperCase() === Environments.PALLETS4DELIVERY ||
    environment.toUpperCase() === Environments.ARW ||
    environment.toUpperCase() === Environments.STAGING
  ) {
    return HomePage.PALLET;
  }

  return HomePage.PARCEL;
};

export const homePage = getHomePage();
