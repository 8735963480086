import React from 'react';
import styled from 'styled-components';
import { Header } from './Header';
import  { Body } from './Body';

export const SingleAccordion = ({ head, body, open, done, setOpen }: any) => {
  return (
    <Wrapper>
      <Header heading={head} open={open} done={done} setOpen={setOpen} />
      <Body visible={open}>{ body }</Body>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }: any) => theme.colors.White};
  border-radius: 8px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: unset;
  }
`;
