import React from 'react';
import styled from 'styled-components';
import LoadContainer from './LoadContainer';
import { PSmall } from '../../../../../../../../assets/elements';
import device from '../../../../../../../utils/device';

const FullLoad = ({ onClick, selected }: any) => {
  return (
    <LoadContainer
      onClick={onClick}
      selected={selected}
    >
      <Wrapper>
        <Title>Full trailer load</Title>
        <SubTitle>(up to 26 pallets)</SubTitle>
      </Wrapper>
    </LoadContainer>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.laptop} {
    flex-direction: row;
    align-items: center;
  }
`;

const Title = styled(PSmall)`
  font-weight: 600;
  display: block;
  width: 100%;
  margin-right: 8px;
`;

const SubTitle = styled(PSmall)`
  color: ${({ theme }: any) => theme.colors.MidGrey};
  white-space: nowrap;
`;

export default FullLoad;
