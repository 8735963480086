import React from 'react';
import styled from 'styled-components';

export const Accordion = ({ children }: any) => {
  return (
    <Wrapper>{ children }</Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
