import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, A, Input, Caption } from '../../../assets/elements';
import { ZonosClassify } from '../../utils/APICalls/Zonos';
import AccordionList from '../AccordionList';
import { landingPageUrl } from '../../utils/env';

const TariffCodeLookup = ({
  value,
  description,
  manufacturedIn,
  deliveryCountry,
  onChange,
  onblur,
}: any) => {
  const [disabled, setDisabled] = useState(true);
  const [manual, setManual] = useState(false);
  const [tariffData, setTariffData] = useState<any>({});
  const [error, setError] = useState<any>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDisabled(description === '' || manufacturedIn === '');
  }, [description, manufacturedIn]);

  const findTariffCode = () => {
    if (disabled) {
      return;
    }

    setLoading(true);

    ZonosClassify(description, manufacturedIn, deliveryCountry)
      .then((res: any) => {
        if (res.data.error_message) {
          setError(res.data.error_message);
          setTariffData({
            chapter: {
              description: res.data.error_message,
            },
          });

          setLoading(false);
          return;
        }

        onChange(res.data.classified_code);

        let chapter: any = '';
        let heading: any = '';
        let subheading: any = '';
        let tariff: any = '';

        res.data.codes.forEach((code: any) => {
          if (code.type === 'chapter') {
            chapter = code;
          }

          if (code.type === 'heading') {
            heading = code;
          }

          if (code.type === 'subHeading') {
            subheading = code;
          }

          if (code.type === 'tariff') {
            tariff = code;
          }
        });

        setTariffData({
          chapter,
          heading,
          subheading,
          tariff,
        });
        setLoading(false);
      })
      .catch((err: any) => {
        setError(err.data?.error_message || 'Technical error');
        setLoading(false);
        setManual(true);
      });
  };

  useEffect(() => {
    if (value && !tariffData?.chapter) {
      setManual(true);
    } else if (tariffData?.chapter) {
      setManual(false);
    }
  }, [value, tariffData]);

  useEffect(() => {
    setTariffData({});
  }, [description]);

  const clearTariffCode = () => {
    setTariffData({});
    onChange('');
    setManual(false);
    setError(false);
  };

  const changeManual = (selection: any) => {
    setTariffData({});
    onChange('');

    setManual(selection);
  };

  const goToLookupTool = () => {
    window.open(`${landingPageUrl}tariff-code-lookup`, '_blank');
  };

  const handleChange = (val: any) => {
    onChange(val);
    setError(false);
  };

  return (
    <>
      {
        // eslint-disable-next-line no-nested-ternary
        manual ? (
          <Wrapper>
            <Input
              theme={null}
              value={value}
              type="text"
              placeholder="0000.00.0000"
              onChange={(e: any) => handleChange(e.target.value)}
              error={false}
              width="50%"
            />
            <AStyled onClick={() => changeManual(false)}>Find Tariff Code</AStyled>
            {
              error && <ErrorText>{ error }</ErrorText>
            }
          </Wrapper>
        ) : (
          tariffData?.chapter ? (
            <>
              <Wrapper>
                <Input
                  theme={null}
                  value={value}
                  type="text"
                  placeholder="0000.00.0000"
                  onChange={(e: any) => handleChange(e.target.value)}
                  error={false}
                  width="50%"
                  disabled={true}
                  onBlur={onblur}
                />
                <AStyled onClick={() => clearTariffCode()}>Clear and find again</AStyled>
              </Wrapper>
              <AccordionList
                levels={Object.values(tariffData).map((data: any) => data.description)}
              />
              <CaptionStyled>
                Not correct? <A onClick={() => goToLookupTool()}>Lookup tool</A>
              </CaptionStyled>
            </>
          ) : (
            <Wrapper>
              <ButtonStyled
                color="secondary"
                disabled={disabled || loading}
                disable={disabled || loading}
                onClick={() => findTariffCode()}
              >
                {
                  loading ? 'Finding...' : 'Find Tariff code'
                }
              </ButtonStyled>
              <AStyled onClick={() => changeManual(true)}>I already know my code</AStyled>
            </Wrapper>
          )
        )
      }
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const ButtonStyled = styled(Button)`
  width: 50%;
`;

const AStyled = styled(A)`
  margin-left: 16px;
`;

const CaptionStyled = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.MidGrey};
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

export default TariffCodeLookup;
