import React, { useState } from 'react';
import styled from 'styled-components';

const AccordionListItem = ({
  content,
  opened = false,
  arrow = true,
  children
}: any) => {
  const [open, setOpen] = useState(opened);

  return (
    <Wrapper>
      <Content>
        {
          arrow && (
            open ? (
              <Arrow onClick={() => setOpen(!open)}>&#x25BC;</Arrow>
            ) : (
              <Arrow onClick={() => setOpen(!open)}>&#x25BA;</Arrow>
            )
          )
        }
        { content }
      </Content>
      { open && children }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-left: 20px;
  position: relative;
`;

const Content = styled.div`
  padding-left: 20px;
  position: relative;
  margin-bottom: 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Arrow = styled.div.attrs(({ open }: any) => ({
  open
}))`
  position: absolute;
  left: 0;
  top: calc(50% - 10px);
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
`;

export default AccordionListItem;