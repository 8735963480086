import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Column, P, Row } from '../../../../../../../assets/elements';
import DeliveryTime from '../OrderItem/DateContainer/DeliveryTime';
import OrderNumber from '../OrderItem/DateContainer/OrderNumber';
import device from '../../../../../../utils/device';
import { faqUrl } from '../../../../../../utils/env';
import DownloadButtons from './Elements/DownloadButtons';
import PrintLabel from '../../../../../Orders/components/Steps/Success/components/DateContainer/PrintLabel';

const OrderItemPallet = ({
  item: {
    orderId,
    orderGroupId,
    collectionLocation,
    deliveryLocation,
    packageDetails,
    collectionDate,
    deliveryDate,
    carrier,
    insurance,
    deliveryTime = '',
    refund,
    labelUrl,
  },
}: any) => {
  const numberOfItems = packageDetails.reduce(
    (acc: any, current: any) => Number(acc) + 1,
    0
  );

  const goToSupport = (e: any) => {
    e.preventDefault();

    window.open(faqUrl, '_blank');
  };

  return (
    <Wrapper>
      <Row>
        <ColumnStyledBase sizeL={7.5} sizeM={7} sizeS={4} sizeXS={4}>
          <ItemHead>
            <LogoContainer>
              <Logo src={carrier?.logo || ''} />
            </LogoContainer>
            <Title>{carrier?.name}</Title>
          </ItemHead>
          <RowStyled>
            <ColumnStyled sizeL={4} sizeM={4} sizeS={2} sizeXS={2}>
              <OrderTitle>Send from</OrderTitle>
              <AddressBlock>
                {collectionLocation?.company && (
                  <AddressLine>{collectionLocation?.company}</AddressLine>
                )}
                {collectionLocation?.address1 && (
                  <AddressLine>{collectionLocation?.address1}</AddressLine>
                )}
                {collectionLocation?.address2 && (
                  <AddressLine>{collectionLocation?.address2}</AddressLine>
                )}
                {collectionLocation?.address3 && (
                  <AddressLine>{collectionLocation?.address3}</AddressLine>
                )}
                {collectionLocation?.city && (
                  <AddressLine>{collectionLocation?.city}</AddressLine>
                )}
                {collectionLocation?.postcode && (
                  <AddressLine>{collectionLocation?.postcode}</AddressLine>
                )}
                {collectionLocation?.country && (
                  <AddressLine>{collectionLocation?.country}</AddressLine>
                )}
              </AddressBlock>
              <AddressBlock>
                {(collectionLocation?.firstName ||
                  collectionLocation?.lastName) && (
                  <AddressLine>
                    {collectionLocation?.firstName || ''}
                    {collectionLocation?.lastName || ''}
                  </AddressLine>
                )}
                {collectionLocation?.email && (
                  <AddressLine>{collectionLocation?.email}</AddressLine>
                )}
                {collectionLocation?.phone && (
                  <AddressLine>{collectionLocation?.phone}</AddressLine>
                )}
              </AddressBlock>
            </ColumnStyled>
            <ColumnStyled sizeL={4} sizeM={4} sizeS={2} sizeXS={2}>
              <OrderTitle>Delivery to</OrderTitle>
              <AddressBlock>
                {deliveryLocation?.company && (
                  <AddressLine>{deliveryLocation?.company}</AddressLine>
                )}
                {deliveryLocation?.address1 && (
                  <AddressLine>{deliveryLocation?.address1}</AddressLine>
                )}
                {deliveryLocation?.address2 && (
                  <AddressLine>{deliveryLocation?.address2}</AddressLine>
                )}
                {deliveryLocation?.address3 && (
                  <AddressLine>{deliveryLocation?.address3}</AddressLine>
                )}
                {deliveryLocation?.city && (
                  <AddressLine>{deliveryLocation?.city}</AddressLine>
                )}
                {deliveryLocation?.postcode && (
                  <AddressLine>{deliveryLocation?.postcode}</AddressLine>
                )}
                {deliveryLocation?.country && (
                  <AddressLine>{deliveryLocation?.country}</AddressLine>
                )}
              </AddressBlock>
              <AddressBlock>
                {(deliveryLocation?.firstName ||
                  deliveryLocation?.lastName) && (
                  <AddressLine>
                    {deliveryLocation?.firstName || ''}{' '}
                    {deliveryLocation?.lastName || ''}
                  </AddressLine>
                )}
                {deliveryLocation?.email && (
                  <AddressLine>{deliveryLocation?.email}</AddressLine>
                )}
                {deliveryLocation?.phone && (
                  <AddressLine>{deliveryLocation?.phone}</AddressLine>
                )}
              </AddressBlock>
            </ColumnStyled>
            <ColumnStyled sizeL={4} sizeM={4} sizeS={2} sizeXS={2}>
              <OrderTitle>Order details</OrderTitle>
              <AddressBlock>
                <AddressLine>
                  {numberOfItems} item
                  {numberOfItems === 0 || numberOfItems >= 2 ? 's' : ''}
                </AddressLine>
              </AddressBlock>
              <AddressBlock>
                <AddressLine>
                  {`${
                    insurance.price === 0 ? 'Free' : `£${insurance.price}`
                  } - Provider covers up to £${insurance.value}`}
                </AddressLine>
              </AddressBlock>
              {deliveryTime && (
                <AddressBlock>
                  <AddressLine>Collection timeslot: {deliveryTime}</AddressLine>
                </AddressBlock>
              )}
            </ColumnStyled>
          </RowStyled>
        </ColumnStyledBase>
        <ColumnStyledBase sizeL={4.5} sizeM={5} sizeS={2} sizeXS={4}>
          <SideWrapper>
            <SideContainer>
              <DeliveryTime date={collectionDate} title="Collection" />
              <DeliveryTime date={deliveryDate} title="Delivery date" />
              <OrderContainer>
                {labelUrl && <PrintLabel link={labelUrl} />}
                <OrderNumber orderNumber={orderId} />
                <DownloadButtons orderGroupId={orderGroupId} />
              </OrderContainer>
            </SideContainer>
          </SideWrapper>
        </ColumnStyledBase>
      </Row>
      {refund.length > 0 && (
        <Row>
          <ColumnStyledBase sizeL={12} sizeM={12} sizeS={6} sizeXS={4}>
            <RefundBox>
              <RefundHeading>
                You were refunded £{refund[0].total} on{' '}
                {moment(refund[0].updatedAt).format('DD/MM/yyyy')}.
              </RefundHeading>
              If there is anything else we can do for you, please{' '}
              <SupportLink onClick={goToSupport}>contact support</SupportLink>.
            </RefundBox>
          </ColumnStyledBase>
        </Row>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  background: ${({ theme }: any) => theme.colors.White};
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ColumnStyledBase = styled(Column)`
  padding: 0 15px;

  @media ${device.laptopL} {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;

const ItemHead = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;

  @media ${device.tablet} {
    flex-direction: row;
    align-items: center;
  }

  @media ${device.laptop} {
    align-items: unset;
    margin-bottom: 50px;
  }
`;

const LogoContainer = styled.div`
  width: auto;
  max-width: 120px;

  @media ${device.laptop} {
    width: 120px;
  }
`;

const Logo = styled.img`
  max-width: 120px;
  height: auto;
  max-height: 60px;
`;

const Title = styled(P)`
  width: 100%;
  font-weight: 600;
  margin-top: 8px;

  @media ${device.tablet} {
    padding-left: 32px;
    margin-top: 0;
  }
`;

const RowStyled = styled(Row)`
  justify-content: flex-start;
`;

const ColumnStyled = styled(Column)`
  &:first-child {
    padding-right: 15px;
    padding-left: 0;
  }

  &:last-child {
    padding-left: 0;
  }

  &:nth-child(2) {
    padding-left: 15px;
  }

  margin-bottom: 16px;

  @media ${device.laptop} {
    &:first-child {
      padding-left: 0px;
    }

    &:last-child {
      padding-right: 0;
      padding-left: 15px;
    }

    margin-bottom: 0;
  }
`;

const OrderTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 18px;
`;

const AddressBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const AddressLine = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const SideContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media ${device.laptop} {
    flex-direction: column;
  }
`;

const SideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const OrderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const RefundBox = styled.div`
  width: 100%;
  text-align: left;
  background: #fee6d0;
  padding: 12px 16px;
  border-radius: 4px;
  color: ${({ theme }: any) => theme.colors.Black};
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  margin-top: 16px;
  letter-spacing: 0.01em;
`;

const RefundHeading = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 4px;
  letter-spacing: 0.01em;
`;

const SupportLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }: any) => theme.colors.Black};
  font-weight: 300 !important;
  cursor: pointer;
`;

export default OrderItemPallet;
