import moment from 'moment-timezone';
import React from 'react';
import styled from 'styled-components';
import { H6, PSmall, A } from '../../../../../assets/elements';
import { environment } from '../../../../utils/env'

const inBusinessHours = () => {
  const now = moment.tz(moment().toDate(), 'Europe/London')
  if (now.format('d') === '0' || now.format('d') === '6') return false;
  if (parseInt(now.format('H')) < 9 || parseInt(now.format('H')) > 17) return false
  return true;
}

const gotoSalesPage = () => {
  window.location.href = 'https://www.p4d.co.uk/contact-sales?utm_source=quote-page&utm_medium=banner&utm_campaign=quote_help'
}

const SalesBanner = (props: any) => {
  if (environment.toUpperCase() === 'DEV' || environment.toUpperCase() === 'P4D') {
    const showPhoneNumber = inBusinessHours()
    return (
      <Wrapper>
        <StyledText>
          Have a question?
          {' '}
          <A onClick={gotoSalesPage}>Contact our Sales team.</A>
          {/* { showPhoneNumber && <span> Alternatively, you can call us on <PhoneNumber>01245 955617</PhoneNumber></span>} */}
        </StyledText>
      </Wrapper>
    );
  } else {
    if (!inBusinessHours()) return null;
    return (
      <Wrapper>
        <StyledText>
          Have a question? Call us today to place your booking - <PhoneNumber>01245 955617</PhoneNumber>
        </StyledText>
      </Wrapper>
    );
  }
};

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }: any) => theme.colors.White};
  border-radius: 9px;
  border: 2px solid ${({ theme }: any) => theme.colors.Secondary};
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

const PhoneNumber = styled.span`
  color: ${({ theme }: any) => theme.colors.Secondary};
`;

const StyledH6 = styled(H6)`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 34px;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const StyledText = styled(PSmall)`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }: any) => theme.colors.Black};
`;

export default SalesBanner;