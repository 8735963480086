import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useHistory } from 'react-router';
import { Button, PSmall } from '../../../../../../../assets/elements';

const OrderItem = ({ order, setCurrentOrder }: any) => {
  const history = useHistory();

  const handleTracking = () => {
    history.push(`track?order-number=${order.orderId}`);
  };

  const handleOrderClick = () => {
    setCurrentOrder(order.orderData, order.orderId, order.orderGroupId, order.type, order.refund, order.labelUrl);
  };

  return (
    <Wrapper>
      <OrderInfo>
        <OrderNumber onClick={handleOrderClick}>{order.orderId}</OrderNumber>
        <OrderDate>
          {moment(order.orderDate).format('dddd, D MMMM YYYY | h:mm a')}
        </OrderDate>
      </OrderInfo>
      <ButtonContainer>
        <ButtonStyled color="secondary" onClick={handleTracking}>
          Track
        </ButtonStyled>
      </ButtonContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0000001a;
`;

const OrderInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderNumber = styled(PSmall)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${({ theme }: any) => theme.colors.Secondary};
  margin-bottom: 4px;
  cursor: pointer;
`;

const OrderDate = styled(PSmall)`
  color: ${({ theme }: any) => theme.colors.MidGrey};
  font-size: 16px;
  line-height: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonStyled = styled(Button)`
  padding: 7px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
`;

export default OrderItem;
