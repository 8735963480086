import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Moment from 'react-moment';
import moment from 'moment';
import {
  A,
  Column,
  Container,
  P,
  Row,
} from '../../../../../../../../assets/elements';
import device from '../../../../../../../utils/device';
import Info from '../../../../../../../../assets/icons/Info.svg';
import Printer from '../../../../../../../../assets/icons/Printer.svg';
import Delete from '../../../../../../../../assets/icons/Delete.svg';
import { dateFormat } from '../../../../../../../utils/variables';
import NICheck from '../../../../../../../utils/NICheck';
import containerTypes from '../../../../../../../utils/containerTypes.json';

const OrderItem = ({
  item: {
    item,
    deliveryAddress,
    collectionAddress,
    packageDetails,
    id,
    serviceType,
    deliveryTime,
    dims,
    loadType
  },
  onRemove,
  openMoreInfo,
  setMoreInfo,
}: any) => {
  const deliveryTimeFormat = (days: number) =>
    `${days} ${days === 0 || days > 2 ? 'days' : 'day'}`;

  const isContainer = containerTypes.includes(loadType) || containerTypes.includes(packageDetails?.loadType);

  const processOrderDetails = () => {
    if (isContainer) return `${packageDetails.quantity} x ${packageDetails.loadType || loadType} Container`;

    if (serviceType === 'freight' && Array.isArray(packageDetails)) {
      const quantity = packageDetails.reduce((acc: number, ship: any) => acc + ship.numPallets, 0);
      return `${quantity} pallet${quantity > 1 ? 's':''}`;
    }
    return `${packageDetails.numPallets} pallet${
      packageDetails.numPallets > 1 ? 's' : ''}`;
  };

  const handleMoreInfoClick = () => {
    if (item?.moreInfo?.intro) {
      setMoreInfo(item.moreInfo);
      openMoreInfo();
    }
  };

  const numberOfItemsCalc = () => {
    if (serviceType === 'freight') {
      return 1;
    } else if (serviceType === 'parcel') {
      return packageDetails.length;
    } else if (packageDetails && packageDetails.length) {
      return packageDetails.reduce(
        (acc: any, current: any) => Number(acc) + 1,
        0
      );
    } else if (dims && dims.length) {
      return dims.reduce(
        (acc: any, current: any) => Number(acc) + Number(current.quantity),
        0
      );
    } else {
      return 0
    }
  };
  const numberOfItems = numberOfItemsCalc();

  const calcDeliveryTime = () => {
    if (item.deliveryTime === 0) {
      return 'Same day';
    }

    if (item.deliveryTime === false || item.deliveryTime === null) {
      return 'Provided after booking';
    }

    return `${item.deliveryTime} day${
      item.deliveryTime === 0 || item.deliveryTime > 1 ? 's' : ''
    }`;
  };

  const checkForNI = () =>
    serviceType === 'pallet' && NICheck(collectionAddress, deliveryAddress);

  return (
    <Wrapper>
      <RowStyled>
        <ColumnStyled sizeL={4} sizeM={4}>
          <CarrierLogo src={item.logo} alt={`${item.name} logo`} />
        </ColumnStyled>
        <ColumnStyled sizeL={8} sizeM={8}>
          <TitleContainer>
            <P>{item.name}</P>
            <Icons>
              <>
                <InfoIcon
                  data-tip
                  data-for="id-icon"
                  alt="info icon"
                  src={Info}
                  onClick={handleMoreInfoClick}
                />
                <ReactTooltip id="id-icon" effect="solid">
                  More info
                </ReactTooltip>
              </>
              {item.printer && (
                <>
                  <PrinterIcon
                    data-tip
                    data-for="printer-icon"
                    alt="printer icon"
                    src={Printer}
                  />
                  <ReactTooltip id="printer-icon" effect="solid">
                    Printer required
                  </ReactTooltip>
                </>
              )}
            </Icons>
          </TitleContainer>
          <Status>{item.status}</Status>
        </ColumnStyled>
      </RowStyled>
      <RowStyled>
        <ColumnStyled sizeL={6} sizeM={6} sizeS={3} sizeXS={2}>
          {item.type ? (
            <ParcelTypeContainer type={item.type}>
              <ParcelTypeHead type={item.type}>
                {item.type === 'collection' ? 'Collection' : 'Drop off'}
              </ParcelTypeHead>
              <ParcelTypeData>
                {item.type === 'collection' && (
                  <Moment format="ddd D MMM">{item.collectionDate}</Moment>
                )}
                {item.type === 'dropoff' && item?.dropoffPoints && (
                  <DropOffMessage>
                    Nearest drop off
                    {item?.dropoffPoints[0]?.distance?.amount}
                    {item?.dropoffPoints[0]?.distance?.unit}
                  </DropOffMessage>
                )}
              </ParcelTypeData>
            </ParcelTypeContainer>
          ) : (
            <DeliveryTimeContainer>
              <DeliveryTimeHead>
                {serviceType === 'freight' || serviceType === 'seaFreight'
                  ? isContainer ? 'Earliest Collection Date' : 'Estimated Transit Time'
                  : 'Collection'}
              </DeliveryTimeHead>
              <DeliveryTimeData>
                {(serviceType === 'freight' || serviceType === 'seaFreight') && !isContainer
                  ? calcDeliveryTime()
                  : moment(item.shippingDates[0].cd).format(dateFormat)}
              </DeliveryTimeData>
            </DeliveryTimeContainer>
          )}
        </ColumnStyled>
        <ColumnStyled sizeL={6} sizeM={6} sizeS={3} sizeXS={2}>
          <DeliveryTimeContainer>
            <DeliveryTimeHead>
              {(serviceType === 'freight' || serviceType === 'seaFreight') && !isContainer &&
                'Quote Expires'}
              {serviceType !== 'freight' &&
                serviceType !== 'seaFreight' &&
                (item.type ? 'Delivery time' : 'Delivery date')}
              {isContainer && 'Earliest Delivery Date'}
            </DeliveryTimeHead>
            <DeliveryTimeData>
              {(serviceType === 'freight' || serviceType === 'seaFreight') && !isContainer &&
                moment(item?.expires || '').format(dateFormat)}
              {serviceType !== 'freight' &&
                serviceType !== 'seaFreight' &&
                (item.type
                  ? deliveryTimeFormat(item.deliveryTime)
                  : moment(item.deliveryDate).format(dateFormat))}
              {isContainer && moment(item.shippingDates[0].dd[0]).format(dateFormat)}
            </DeliveryTimeData>
          </DeliveryTimeContainer>
        </ColumnStyled>
      </RowStyled>
      <RowBorder>
        {serviceType === 'parcel' && item.type === 'dropoff' ? (
          ''
        ) : (
          <Column sizeL={4} sizeM={4} sizeS={2} sizeXS={2}>
            <OrderTitle>Send from</OrderTitle>
            <AddressBlock>
              {collectionAddress?.company && (
                <AddressLine>{collectionAddress?.company}</AddressLine>
              )}
              {collectionAddress?.addressOne && (
                <AddressLine>{collectionAddress?.addressOne}</AddressLine>
              )}
              {collectionAddress?.addressTwo && (
                <AddressLine>{collectionAddress?.addressTwo}</AddressLine>
              )}
              {collectionAddress?.addressThree && (
                <AddressLine>{collectionAddress?.addressThree}</AddressLine>
              )}
              {collectionAddress?.city && (
                <AddressLine>{collectionAddress?.city}</AddressLine>
              )}
              {collectionAddress?.zip && (
                <AddressLine>{collectionAddress?.zip}</AddressLine>
              )}
              {collectionAddress?.country && (
                <AddressLine>{collectionAddress?.country}</AddressLine>
              )}
            </AddressBlock>
            <AddressBlock>
              {(collectionAddress?.firstName ||
                collectionAddress?.lastName) && (
                <AddressLine>
                  {collectionAddress?.firstName || ''}
                  {collectionAddress?.lastName || ''}
                </AddressLine>
              )}
              {collectionAddress?.email && (
                <AddressLine>{collectionAddress?.email}</AddressLine>
              )}
              {collectionAddress?.phone && (
                <AddressLine>{collectionAddress?.phone}</AddressLine>
              )}
            </AddressBlock>
          </Column>
        )}
        <Column sizeL={4} sizeM={4} sizeS={2} sizeXS={2}>
          <OrderTitle>Delivery to</OrderTitle>
          <AddressBlock>
            {deliveryAddress?.company && (
              <AddressLine>{deliveryAddress?.company}</AddressLine>
            )}
            {deliveryAddress?.addressOne && (
              <AddressLine>{deliveryAddress?.addressOne}</AddressLine>
            )}
            {deliveryAddress?.addressTwo && (
              <AddressLine>{deliveryAddress?.addressTwo}</AddressLine>
            )}
            {deliveryAddress?.addressThree && (
              <AddressLine>{deliveryAddress?.addressThree}</AddressLine>
            )}
            {deliveryAddress?.city && (
              <AddressLine>{deliveryAddress?.city}</AddressLine>
            )}
            {deliveryAddress?.zip && (
              <AddressLine>{deliveryAddress?.zip}</AddressLine>
            )}
            {deliveryAddress?.country && (
              <AddressLine>{deliveryAddress?.country}</AddressLine>
            )}
          </AddressBlock>
          <AddressBlock>
            {(deliveryAddress?.firstName || deliveryAddress?.lastName) && (
              <AddressLine>
                {deliveryAddress?.firstName || ''}{' '}
                {deliveryAddress?.lastName || ''}
              </AddressLine>
            )}
            {deliveryAddress?.email && (
              <AddressLine>{deliveryAddress?.email}</AddressLine>
            )}
            {deliveryAddress?.phone && (
              <AddressLine>{deliveryAddress?.phone}</AddressLine>
            )}
          </AddressBlock>
        </Column>
        <Column sizeL={4} sizeM={4} sizeS={2} sizeXS={4}>
          <OrderTitle>Order details</OrderTitle>
          <AddressBlock>
            <AddressLine>
              {serviceType === 'freight' ? processOrderDetails() : (
                <>
                  {numberOfItems} item
                  {numberOfItems === 0 || numberOfItems >= 2 ? 's' : ''}
                </>
              )}
            </AddressLine>
          </AddressBlock>
          <AddressBlock>
            <AddressLine>
              {item?.insurance?.price
                ? `${
                    item?.insurance?.price === 0 || 0
                      ? 'Free'
                      : `£${item?.insurance?.price || 0}`
                  } - Provider covers up to £${item?.insurance?.level || 0}`
                : 'No additional cover purchased'}
            </AddressLine>
          </AddressBlock>
          {deliveryTime && (
            <AddressBlock>
              <AddressLine>Delivery Timeslot: {deliveryTime}</AddressLine>
            </AddressBlock>
          )}
        </Column>
      </RowBorder>
      <OrderItemFooter>
        <RemoveItem onClick={() => onRemove(id)}>
          <RemoveIcon src={Delete} alt="Remove from basket" />
          Remove from basket
        </RemoveItem>
        <ItemPrice>
          {(!item.vat.customs || checkForNI()) && (
            <PriceLabel>excl. VAT</PriceLabel>
          )}
          <Price>
            £
            {(
              Number(item.price) + (Number(item?.insurance?.price) || 0)
            ).toFixed(2)}
          </Price>
        </ItemPrice>
      </OrderItemFooter>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  background: #ffffff;
  border-radius: 8px;
  && {
    padding: 15px;
    @media ${device.tablet} {
      padding: 30px;
    }
  }
  height: auto;

  margin-bottom: 24px;
`;

const RowStyled = styled(Row)`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const RowBorder = styled(Row)`
  margin-bottom: 24px;
  padding: 24px 10px;
  border-top: 2px solid #f2f3f2;
  border-bottom: 2px solid #f2f3f2;
  justify-content: flex-start;
`;

const ColumnStyled = styled(Column)`
  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

const CarrierLogo = styled.img`
  width: auto;
  max-height: 88px;
  max-width: 100px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Icons = styled.div`
  width: auto;
  display: flex;
`;

const InfoIcon = styled.img`
  cursor: pointer;
`;

const PrinterIcon = styled.img`
  margin-left: 20px;
`;

const Status = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  /* or 12px */

  display: flex;
  align-items: center;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const ParcelTypeContainer = styled.div.attrs(({ type }: any) => ({
  type,
}))`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: ${({ type, theme }: any) =>
    type === 'collection'
      ? theme.colors.CollectionBackground
      : theme.colors.DropoffBackground};
  align-items: center;
  border-radius: 4px;
  height: 100%;

  @media ${device.laptop} {
    margin-bottom: 16px;
  }

  @media ${device.laptopL} {
    margin-bottom: 0;
  }
`;

const ParcelTypeHead = styled.div.attrs(({ type }: any) => ({
  type,
}))`
  background: ${({ type, theme }: any) =>
    type === 'collection'
      ? theme.colors.CollectionHead
      : theme.colors.DropoffHead};
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  color: ${({ theme }: any) => theme.colors.White};
  padding: 7px 4px;
  justify-content: center;
`;

const ParcelTypeData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 19px;
  line-height: 150%;
  font-weight: 500;
  margin-top: 13px;
`;

const DropOffMessage = styled(A)`
  text-decoration: underline;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 135%;
  color: ${({ theme }: any) => theme.colors.DropoffHead};
`;
const DeliveryTimeContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  color: ${({ theme }: any) => theme.colors.Black};
  border-radius: 4px;
  height: 100%;

  @media ${device.desktop} {
    height: auto;
  }
`;

const DeliveryTimeHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  padding: 7px 4px;
`;

const DeliveryTimeData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 19px;
  line-height: 150%;
  font-weight: 500;
  margin-top: 13px;
`;

const OrderTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 18px;
`;

const AddressBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const AddressLine = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const OrderItemFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 420px) {
    flex-direction: row;
  }
`;

const RemoveItem = styled(A)`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }: any) => theme.colors.MidGrey};
  display: flex;
  align-items: center;
  cursor: pointer;
  order: 2;
  margin-bottom: 10px;

  @media (min-width: 420px) {
    order: 1;
    margin-bottom: 0;
  }
`;

const RemoveIcon = styled.img`
  margin-right: 10px;
`;

const ItemPrice = styled.div`
  display: flex;
  align-items: baseline;
  order: 1;

  @media (min-width: 420px) {
    order: 2;
  }
`;

const PriceLabel = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: ${({ theme }: any) => theme.colors.MidGrey};
  margin-right: 10px;
`;

const Price = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 135%;
  color: ${({ theme }: any) => theme.colors.Black};
`;

export default OrderItem;
