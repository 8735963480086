import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from '../../../../assets/elements';
import device from '../../../utils/device';
import Logout from '../../../../assets/icons/Logout.svg';
import { resetUserSession } from '../../../store/actions';
import { deleteCookie } from '../../../utils/cookies';
import { logoutUser } from '../../../utils/APICalls/Account';
import { landingPageUrl } from '../../../utils/env';

const UserNavMobile = ({ logged, setOpen }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    logoutUser().finally((err: any) => {
      deleteCookie([
        'userId',
        'userName',
        'accessToken',
        'refreshToken',
        'refreshTokenExpiry',
        'accessTokenExpiry',
      ]);
      dispatch(resetUserSession());
      window.location.href = landingPageUrl;
      setOpen(false);
    });
  };

  const handleAccountClick = (path: any) => {
    history.push(path);
    setOpen(false);
  };

  const handleLogIn = () => {
    history.push('/login');
    setOpen(false);
  };

  const handleRegister = () => {
    history.push('/register');
    setOpen(false);
  };

  return (
    <Wrapper>
      <WrapperButtons>
        {logged ? (
          <>
            <OptionsList>
              <Option onClick={() => handleAccountClick('/dashboard')}>
                My account
              </Option>
              <Option onClick={() => handleAccountClick('/settings')}>
                Settings
              </Option>
              <Option onClick={handleLogout}>
                Log out
                <OptionImage src={Logout} alt="logout" />
              </Option>
            </OptionsList>
          </>
        ) : (
          <>
            <ButtonStyled onClick={handleLogIn}>Log in</ButtonStyled>
            <ButtonStyled onClick={handleRegister} color="secondary">
              Sign up
            </ButtonStyled>
          </>
        )}
      </WrapperButtons>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const UserNav = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: none;

  @media ${device.laptop} {
    display: flex;
  }
`;

const WrapperButtons = styled(UserNav)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  & > button {
    width: 100%;
    margin: 0;
  }

  & > button:first-child {
    margin-bottom: 12px;
  }

  @media ${device.laptop} {
    display: none;
  }
`;

const ButtonStyled = styled(Button)`
  padding: 10px 14px;
  border-width: 2px;
  font-weight: 600;
  line-height: 125%;
  margin-right: 10px;
`;
const OptionsList = styled.ul<any>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`;
const Option = styled.li`
  width: 100%;
  border-bottom: 2px solid #f2f2f2;
  padding: 15px 0;
  background-color: white;
  cursor: pointer;
  position: relative;
  font-weight: 500;
`;

const OptionImage = styled.img<any>`
  position: absolute;
  right: 15px;
  top: 12px;
`;

export default UserNavMobile;
