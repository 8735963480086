import React from 'react';

const DoneIcon = ({ color = '#5FB047', width = 50, height = 50 }: any) => {
  return (
    <svg width={width} height={height} viewBox="0 0 46 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd)">
        <circle cx="34" cy="18" r="12" fill={color} />
      </g>
      <path d="M29 18.5714L32 22L39 14" stroke="white" strokeWidth="2" />
      <defs>
        <filter
          id="filter0_dd"
          x="0"
          y="-16"
          width="68"
          height="68"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="8" dy="8" />
          <feGaussianBlur stdDeviation="7" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.839216 0 0 0 0 0.870588 0 0 0 0 0.839216 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="-8" dy="-8" />
          <feGaussianBlur stdDeviation="7" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.838177 0 0 0 0 0.870833 0 0 0 0 0.838177 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default DoneIcon;
