import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { addDays, getDay, getMonth, getYear } from 'date-fns';
import CalendarIcon from '../../../../../assets/icons/calendar.svg';
import { A, Caption, PSmall } from '../../../../../assets/elements';
import 'react-datepicker/dist/react-datepicker.min.css';
import PreviousArrow from '../../../../../assets/icons/PreviousArrow';
import NextArrow from '../../../../../assets/icons/NextArrow';
import { monthNames } from '../../../../utils/variables';
import { getUnavailableDates } from '../../../../utils/APICalls/UnavailableDates';

const DateSelector = ({ date, setDate, error, minDate, country }: any) => {
  const [holidays, setHolidays] = useState([]);
  const gotAvailableDates = useRef(false)


  const isWeekday = (thisDate: any) => {
    const day = getDay(thisDate);
    return day !== 0 && day !== 6;
  };

  // useEffect(() => {
  //   getUnavailableDates(country).then((res: any) => {
  //     setHolidays(res.data.map((holidayDate: any) => new Date(holidayDate)));
  //     console.log(res)
  //   });
  // }, []);


  //above useEffect replacement as it was not behaving as expected

  if(country){
    if(!gotAvailableDates.current){
      getUnavailableDates(country).then((res: any) => {
        setHolidays(res.data.map((holidayDate: any) => new Date(holidayDate)));
      });
      gotAvailableDates.current = true
    }
  }




  return (
    <RelativeDiv>
      <DatePicker
        selected={date}
        onChange={(value: any) => setDate(value)}
        customInput={<CustomDateSelector />}
        minDate={new Date(minDate)}
        maxDate={addDays(new Date(), 365)}
        filterDate={isWeekday}
        dateFormat="dd/MM/yyyy"
        renderCustomHeader={customHeader}
        popperClassName="calendar-popper"
        excludeDates={holidays}
      />
      {error ? <ErrorText>Select a collection date first</ErrorText> : ''}
    </RelativeDiv>
  );
};

const CustomDateSelector = ({ value, onClick }: any) => {
  return (
    <Wrapper onClick={onClick}>
      <Icon src={CalendarIcon} alt="Calendar" />
      {value === '' ? <Placeholder>Select date</Placeholder> : value}
    </Wrapper>
  );
};

const customHeader = ({ date, decreaseMonth, increaseMonth }: any) => (
  <HeaderWrapper>
    <HeaderDate>
      {monthNames[getMonth(date)]} {getYear(date)}
    </HeaderDate>
    <ArrowContainer>
      <Arrow onClick={decreaseMonth}>
        <PreviousArrow />
      </Arrow>
      <Arrow onClick={increaseMonth}>
        <NextArrow />
      </Arrow>
    </ArrowContainer>
  </HeaderWrapper>
);

const RelativeDiv = styled.div`
  position: relative;

  & > div.react-datepicker-wrapper {
    border: 2px solid ${({ theme }: any) => theme.colors.LightGrey};
    border-radius: 4px;
  }
`;

const Wrapper = styled(PSmall)`
  display: flex;
  justify-content: flex-start;
  padding: 12px 14px;
  background: ${({ theme }: any) => theme.colors.White};
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  width: 200px;
  align-items: center;
`;

const Placeholder = styled.span`
  color: ${({ theme }: any) => theme.colors.LightGrey};
`;

const Icon = styled.img`
  margin-right: 8px;
`;

const HeaderWrapper = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  background: ${({ theme }: any) => theme.colors.White};
`;

const HeaderDate = styled(PSmall)`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const ArrowContainer = styled.div`
  display: flex;
`;

const Arrow = styled(A)`
  margin-right: 26px;

  &:last-of-type {
    margin-right: 0;
  }
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

export default DateSelector;
