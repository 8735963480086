import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import BasketLogo from '../../../assets/icons/Basket.svg';
import MenuOpen from '../../../assets/icons/MenuOpen.svg';
import MenuClosed from '../../../assets/icons/MenuClosed.svg';
import DownIcon from '../../../assets/icons/Down.svg';
import device from '../../utils/device';
import UserNav from './components/UserNav';
import UserNavMobile from './components/UserNavMobile';
import { faqUrl, landingPageUrl, salesEnquiryUrl } from '../../utils/env';
import { logo } from '../../../assets/styles/themes';

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [tabMenuOpen, setTabMenuOpen] = useState(false);

  const basket = useSelector<any, any>((state: any) => state.order.basket);
  const loggedIn = useSelector<any, any>(
    (state: any) => state.user.session.accessToken !== ''
  );

  const handleClick = () => {
    setMenuOpen(false);
  };

  const goToHome = () => {
    window.location.href = landingPageUrl;
  };

  return (
    <MainNavContainer>
      <Wrapper>
        <ImageWrapper onClick={goToHome}>
          <Image src={logo} />
        </ImageWrapper>
        <NavWrapper>
          <Nav>
            <NavLinksContainer>
              <NavLinkA href={landingPageUrl}>Quote</NavLinkA>
              <TabMenuToggler onClick={() => setTabMenuOpen(!tabMenuOpen)}>
                <img src={DownIcon} alt="Down" />
              </TabMenuToggler>
              <NavLinkTab open={tabMenuOpen}>
                <NavLink to="/tracking">Tracking</NavLink>
                <NavLinkA
                  href={faqUrl}
                  target="_blank"
                >
                  Help Centre
                </NavLinkA>
                { salesEnquiryUrl && 
                  <NavLinkA
                    href={salesEnquiryUrl}
                    target="_blank"
                  >
                    Sales Enquiry
                  </NavLinkA>
                } 
              </NavLinkTab>
            </NavLinksContainer>
            <NavLinkIcon to={basket.length > 0 ? '/order-basket' : '/'}>
              <NavLinkIconWrapper>
                <img src={BasketLogo} alt="Basket" />
                {basket.length > 0 && (
                  <BasketCounter>{basket.length}</BasketCounter>
                )}
              </NavLinkIconWrapper>
            </NavLinkIcon>
            <MenuToggler onClick={() => setMenuOpen(!menuOpen)}>
              {menuOpen ? (
                <img src={MenuOpen} alt="Closed" />
              ) : (
                <img src={MenuClosed} alt="Closed" />
              )}
            </MenuToggler>
            <UserNav logged={loggedIn} />
          </Nav>
        </NavWrapper>
      </Wrapper>
      <MobileNav open={menuOpen}>
        <NavLinkA href={landingPageUrl}>Quote & book</NavLinkA>
        <NavLink to="/tracking" onClick={handleClick}>
          Tracking
        </NavLink>
        <NavLinkA
          href={faqUrl}
          target="_blank"
        >
          Help Centre
        </NavLinkA>
        { salesEnquiryUrl && 
          <NavLinkA
            href={salesEnquiryUrl}
            target="_blank"
          >
            Sales Enquiry
          </NavLinkA>
        } 
        <UserNavMobile logged={loggedIn} setOpen={setMenuOpen} />
      </MobileNav>
    </MainNavContainer>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;
const ImageWrapper = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;

  @media ${device.desktop} {
    padding: 12px 40px;
  }
`;
const Image = styled.img`
  max-height: 40px;
  max-width: 117px;
  width: auto;
  height: auto;
`;
const NavWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 16px;
`;
const MainNavContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: column;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

  @media ${device.laptop} {
    flex-direction: row;
  }
`;
const Nav = styled.nav`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;
const NavLinksContainer = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: none;
  position: relative;

  @media ${device.laptop} {
    display: flex;
  }
`;
const NavLink = styled(Link)`
  padding: 10px 15px;
  color: ${({ theme }: any) => theme.colors.Black};
  outline: unset;

  &:hover {
    color: ${({ theme }: any) => theme.colors.Primary};
  }

  @media ${device.laptop} {
    padding: 10px 15px;
  }

  @media ${device.desktop} {
    padding: 10px 15px;
  }
`;
const NavLinkA = styled.a<any>`
  padding: 10px 15px;
  color: ${({ theme }: any) => theme.colors.Black};
  outline: unset;

  &:hover {
    color: ${({ theme }: any) => theme.colors.Primary};
  }

  @media ${device.laptop} {
    padding: 10px 15px;
  }

  @media ${device.desktop} {
    padding: 10px 15px;
  }
`;
const NavLinkIcon = styled(NavLink)`
  padding: 10px 15px;

  @media ${device.laptop} {
    padding-right: 24px;
  }
`;
const NavLinkIconWrapper = styled.div`
  position: relative;
`;
const BasketCounter = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  color: ${({ theme }: any) => theme.colors.White};
  background: ${({ theme }: any) => theme.colors.Secondary};
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.02em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
`;
const MenuToggler = styled.a`
  display: flex;
  cursor: pointer;

  @media ${device.laptop} {
    display: none;
  }
`;
const TabMenuToggler = styled.a`
  display: none;
  cursor: pointer;

  @media ${device.laptop} {
    display: flex;
    padding-right: 48px;
  }

  @media ${device.desktop} {
    display: none;
  }
`;
const MobileNav = styled.div.attrs((props: { open: boolean }) => ({
  open: props.open,
}))`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px;

  & > a {
    padding: 12px 0;
    font-weight: 500;
    border-bottom: 2px solid #f2f2f2;
  }

  @media ${device.laptop} {
    display: none;
  }
`;
const NavLinkTab = styled.div.attrs((props: { open: boolean }) => ({
  open: props.open,
}))`
  display: flex;
  z-index: 5;

  @media ${device.laptop} {
    display: ${(props) => (props.open ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(119, 126, 126, 0.4);
    border-radius: 4px;
    width: 100%;
  }

  @media ${device.desktop} {
    display: flex;
    flex-direction: row;
    position: static;
    top: unset;
    left: unset;
    background: transparent;
    box-shadow: unset;
    border-radius: unset;
    width: auto;
  }
`;

export default Header;
