import styled from 'styled-components';

export const InputContainer = styled.div.attrs(({ half = false }: any) => ({
  half,
}))`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: ${({ half }: any) => half ? '49%' : '100%'};
  position: relative;
`;

export const Label = styled.label`
  display: flex;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  font-size: 14px;
  line-height: 120%;
  font-weight: normal;
  margin-bottom: 6px;
`;
