import React from 'react';
import styled from 'styled-components';

const LoadContainer = ({ selected, children, onClick, type = 'full' }: any) => {
  return (
    <Wrapper
      selected={selected}
      onClick={onClick}
      type={type}
    >
      { children }
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs(({ selected, onClick, type }: any) => ({
  selected,
  onClick,
  type,
}))`
  padding: 17px;
  border: 2px solid;
  border-color: ${({ theme, selected }: any) => selected ? theme.colors.Secondary : theme.colors.WhiteGrey};
  position: relative;
  border-radius:  ${({ theme }: any) => theme.box.BorderRadiusSmall};
  display: flex;

  :before {
    content: ' ';
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: ${({ theme }: any) => theme.colors.White};
    border: 2px solid;
    border-color: ${({ theme, selected }: any) => selected ? theme.colors.Secondary : theme.colors.WhiteGrey};
    position: absolute;
    left: 17px;
    top: ${({ type }: any) => type === 'full' ? '18px' : '28px'};
    cursor: pointer;
  }

  :after {
    content: ' ';
    display: ${({ selected }: any) => selected ? 'block' : 'none'};
    width: 12px;
    height: 12px;
    left: 22px;
    top: ${({ type }: any) => type === 'full' ? '23px' : '33px'};
    background: ${({ theme }: any) => theme.colors.Secondary};
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
  }
`;

export default LoadContainer;
