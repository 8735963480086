import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import SettingBox from '../SettingBox';
import { Button, Input } from '../../../../../../assets/elements';
import device from '../../../../../utils/device';
import { setUserSession } from '../../../../../store/actions';
import { setCookie } from '../../../../../utils/cookies';
import { updateUserName } from '../../../../../utils/APICalls/Account';

const NameSetting = ({ theme, userInfo }: any) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [oldName, setOldName] = useState('');
  const [error, setError] = useState<any>(false);
  const [disabled, setDisabled] = useState<any>(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setName(userInfo);
    setOldName(userInfo);
  }, [userInfo]);

  const handleChange = (e: any) => {
    const { value } = e.target;

    if (disabled && value !== '' && value !== oldName) {
      setDisabled(false);
    } else if (value === oldName || value === '') {
      setDisabled(true);
    }

    setError(false);
    setName(value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setSaving(true);
    setDisabled(true);

    updateUserName({ name })
      .then(() => {
        dispatch(
          setUserSession({
            userName: name,
          })
        );

        setCookie([
          {
            name: 'username',
            value: name,
          },
        ]);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <SettingBox title="Name">
      <InputContainer>
        <Input
          theme={theme}
          value={name}
          type="text"
          placeholder="Enter name"
          onChange={handleChange}
          error={error}
        />
      </InputContainer>

      <div>
        <ButtonStyled
          color="secondary"
          disable={disabled}
          disabled={disabled}
          onClick={handleSubmit}
        >
          {saving ? 'Saving...' : 'Save changes'}
        </ButtonStyled>
      </div>
    </SettingBox>
  );
};

const ButtonStyled = styled(Button)`
  white-space: nowrap;
  width: auto;
`;

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;

  @media ${device.laptop} {
    width: 40%;
    margin-bottom: 0;
  }

  @media ${device.laptopL} {
    width: 65%;
  }
`;

export default NameSetting;
