import { environment } from '../../../app/utils/env';

import { staging } from './staging';
import { cmp } from './cmp';
import { pallets4delivery } from './pallets4delivery';
import { mpd } from './mpd';
import { p4d } from './p4d';
import { arw } from './arw';
import { auc } from './auc';

import EgisticsLogo from '../../images/EGISTICS-LOGO.png';
import CMPLogo from '../../images/CMP-LOGO.png';
import Pallets4DelLogo from '../../images/Pallets4Del-Logo.png';
import MPDLogo from '../../images/MPD-Logo.png';
import P4DLogo from '../../images/P4D-Logo.png';
import ARWLogo from '../../images/ARW-logo.png';
import AUCLogo from '../../images/AUCLogo.png';
import HaulableLogo from '../../images/haulable-logo.png'
import { main } from './main';

export const ASSETS_URL = 'https://e-gistics-landing-page.s3.eu-west-2.amazonaws.com/assets/favicons/';

const getTheme = () => {
  return main;
};

const getLogo = () => {
  if (environment === 'staging') {
    return HaulableLogo;
  }

  if (environment === 'cmp') {
    return CMPLogo;
  }

  if (environment === 'pallets4delivery') {
    return Pallets4DelLogo;
  }

  if (environment === 'mpd') {
    return MPDLogo;
  }

  if (environment === 'p4d') {
    return P4DLogo;
  }

  if (environment === 'arw') {
    return ARWLogo;
  }

  if (environment === 'auc') {
    return AUCLogo;
  }

  return EgisticsLogo;
};

const getFavicon = () => {
  if (environment === 'staging') {
    return `${ASSETS_URL}haulable_favicon.png`;
  }
  
  if (environment === 'cmp') {
    return `${ASSETS_URL}CMP_favicon.svg`;
  }

  if (environment === 'pallets4delivery') {
    return `${ASSETS_URL}Pallets4Del_favicon.png`;
  }

  if (environment === 'mpd') {
    return `${ASSETS_URL}MPD_favicon.png`;
  }

  if (environment === 'p4d') {
    return `${ASSETS_URL}P4D_favicon.ico`;
  }

  if (environment === 'arw') {
    return `${ASSETS_URL}ARW_favicon.png`;
  }

  if (environment === 'auc') {
    return `${ASSETS_URL}CMP_favicon.png`;
  }

  return EgisticsLogo;
};

export const theme = getTheme();
export const logo = getLogo();
export const favicon = getFavicon();
