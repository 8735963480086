import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import device from '../../../../../../utils/device';

const AccountLink = ({ icon, title, link }: any) => {
  return (
    <Wrapper>
      <LinkStyled to={link}>
        <Icon src={icon} alt={title} /> {title}
      </LinkStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }: any) => theme.colors.White};
  border-radius: 9px;
  padding: 16px 18px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  width: 100%;
  white-space: nowrap;

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.laptopL} {
    width: 100%;
  }
`;

const LinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: ${({ theme }: any) => theme.colors.Black};

  &:hover {
    color: ${({ theme }: any) => theme.colors.Black};
  }
`;

const Icon = styled.img`
  margin-right: 10px;
`;

export default AccountLink;
