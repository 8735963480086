import React from 'react';
import styled from 'styled-components';
import { Button, PSmall } from '../../../../../assets/elements';
import { useHistory } from 'react-router-dom';

const NoDefaultAddress = () => {
  const history = useHistory();

  const goToAddressBook = () => {
    history.push('/address-book');
  };

  return (
    <Wrapper>
      <TextBlock>
        <TextHeading>Save a default address for faster booking</TextHeading>
        <TextContent>
          Pin your default address in your address book so you don’t need to re-select it here.
        </TextContent>
      </TextBlock>
      <ButtonStyled color="black" onClick={goToAddressBook}>Go to address book</ButtonStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }: any) => theme.colors.Pastel1};
  border-radius: 8px;
  margin-bottom: 16px;
`;

const TextBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TextHeading = styled(PSmall)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 8px;
`;

const TextContent = styled(PSmall)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
`;

const ButtonStyled = styled(Button)`
  white-space: nowrap;
`;

export default NoDefaultAddress;
