import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { withTheme } from 'styled-components';
import { initGTM, PageView } from '../../utils/tracking';
import { landingPageUrl, environment, AFFILIATE_MERCHANT_ID, MAINTENANCE_MODE } from '../../utils/env';
import { deleteCookie, getCookie, setCookie } from '../../utils/cookies';
import { resetUserSession, setUserSession, setAffliateLoaded } from '../../store/actions';
import {
  checkAccessToken,
  checkAccessTokenExpiry,
  checkRefreshToken,
  checkRefreshTokenExpiry,
} from '../../utils/checkTokens';
import Router from './Router';
import { refreshTokens } from '../../utils/APICalls/Account';
import { cookieConsent } from '../../utils/cookieConsent';
import './main.css';
import { favicon } from '../../../assets/styles/themes';
import { MainWrapper } from '../../../assets/elements';
import Footer from '../Footer';
import Maintenance from '../Maintenance';

const Main = ({ theme }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  // allowed paths for maintenance mode
  const allowedPathNames: string[] = [];

  useEffect(() => {
    initGTM();

    cookieConsent(theme);
  }, []);

  useEffect(() => {
    if (environment === 'p4d' || environment === 'mpd') {
      const script = document.createElement('script');
      script.src = 'https://scripts.affiliatefuture.com/AFFunctions.js';
      script.addEventListener('load', (args) => {
        dispatch(setAffliateLoaded(true));
      });
      document.body.appendChild(script);

      const tagScript = document.createElement('script');
      tagScript.src = `https://tags.affiliatefuture.com/${AFFILIATE_MERCHANT_ID[environment.toUpperCase()]}.js`;
      document.body.appendChild(tagScript);
    }
  });

  useEffect(() => {
    const link = document.querySelector('link[rel="icon"]');
    // @ts-ignore
    link.href = favicon;
  }, []);

  useEffect(() => {
    PageView();
    handleTokens();

    window.scroll(0, 0);

    history.listen(() => {
      PageView();
      handleTokens();

      window.scroll(0, 0);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleTokens = () => {
    const refreshExpiry = checkRefreshTokenExpiry();
    const accessExpiry = checkAccessTokenExpiry();

    if (checkRefreshToken()) {
      if (refreshExpiry <= 0) {
        deleteCookie([
          'userId',
          'userName',
          'accessToken',
          'refreshToken',
          'refreshTokenExpiry',
          'accessTokenExpiry',
        ]);
        dispatch(resetUserSession());
      } else if (checkAccessToken()) {
        if (accessExpiry <= 10) {
          refreshTokens({
            refreshToken: getCookie('refreshToken'),
            accessToken: getCookie('accessToken'),
          }).then((res: any) => {
            const { user, tokens, expires } = res.data;

            dispatch(
              setUserSession({
                userName: user.name,
                userId: user.id,
                accessToken: tokens.accessToken,
                refreshToken: tokens.refreshToken,
              })
            );

            setCookie([
              {
                name: 'refreshToken',
                value: tokens.refreshToken,
                expiry: new Date(
                  Number(expires.refresh_token) * 1000
                ).toUTCString(),
              },
              {
                name: 'accessToken',
                value: tokens.accessToken,
                expiry: new Date(
                  Number(expires.access_token) * 1000
                ).toUTCString(),
              },
              {
                name: 'refreshTokenExpiry',
                value: expires.refresh_token,
                expiry: new Date(
                  Number(expires.refresh_token) * 1000
                ).toUTCString(),
              },
              {
                name: 'accessTokenExpiry',
                value: expires.access_token,
                expiry: new Date(
                  Number(expires.access_token) * 1000
                ).toUTCString(),
              },
            ]);
          });
        }
      }
    }
  };

  const Elem = () => {
    const {
      location: { href },
    } = window;

    if (
      (
        !href.includes('heroku') &&
        !href.includes('localhost') &&
        pathname === '/'
      ) || (
        !href.includes('heroku') &&
        !href.includes('localhost') &&
        pathname === '/pallet-delivery'
      )
    ) {
      window.location.href = landingPageUrl;
      return <></>;
    } 
    return <Router />;
    
  };

  return (
    <>
      {MAINTENANCE_MODE[environment.toUpperCase()] && !allowedPathNames.includes(window.location.pathname)? <Maintenance />:
      <MainWrapper>
        <Elem />
        <Footer />
      </MainWrapper>}
    </>
  );
};

export default withTheme(Main);
