import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { v4 as uuid } from 'uuid';
import SingleResult from './SingleResult';
import Filter from '../../../Filter';
import { Container, PSmall } from '../../../../../assets/elements';
import {
  setItemDetails,
  setParcelQuotes,
  setParcelValues,
  setSelectedService,
} from '../../../../store/actions';
import Modal from '../../../../../assets/Combos/Modal';
import StepOneParcel from '../../../LandingPageQuote/components/QuoteForm/Parcel/StepOne';
import DeliverBanner from './DeliverBanner';
import { GTMECommerce } from '../../../../utils/tracking';
import { getUnavailableDates } from '../../../../utils/APICalls/UnavailableDates';
import QuoteLoader from '../../../QuoteLoader';
import DropoffLocations from './DropoffLocations';
import EmailQuote from '../EmailQuote';
import MessageBanner from '../../MessageBanner';
import ManualQuoteRequest from '../ManualQuoteRequest/ManualQuoteRequest';
import getServiceInformationContent from './ServiceInformation'

const ParcelQuotes = ({ openInsurance, openEmailModal, setOpenEmailModal }: any) => {
  const history = useHistory();
  const { collection, delivery, items: parcelItems } = useParams();

  const [editDetailsModal, setEditDetailsModal] = useState(false);
  const [resetValues, setResetValues] = useState(false);
  const [moreInfoValue, setMoreInfoValue] = useState<any>({});
  const [moreInfoText, setMoreInfoText] = useState<any>();
  const [moreInfoModal, setMoreInfoModal] = useState(false);

  const [serviceInfoValue, setServiceInfoValue] = useState<any>({});
  const [serviceInfoText, setServiceInfoText] = useState<any>();
  const [serviceInfoModal, setServiceInfoModal] = useState(false);
  const [serviceInfoTitle, setServiceInfoTitle] = useState('')

  const [dropoffModal, setDropoffModal] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [holidays, setHolidays] = useState<any>([]);

  const quotes = useSelector<any, any>((state) => state.main.parcelQuotes);

  const parcel = useSelector<any, any>((state) => state.main.parcel);

  const loading = useSelector<any, any>((state) => state.main.loading.quotes);

  const dispatch = useDispatch();

  useEffect(() => {
    if (collection) {
      const collectionData = collection.split(':');
      const deliveryData = delivery.split(':');

      !collectionData[0] &&
      parcel.collectionLocation.country === '' &&
      history.push('/');

      const items = parcelItems.split(':');
      const packageDetails = items.map((pack: any) => {
        const item = pack.split(',');

        return {
          id: uuid(),
          weight: {
            amount: item[0],
            unit: 'kg',
          },
          length: {
            amount: item[1],
            unit: 'cm',
          },
          width: {
            amount: item[2],
            unit: 'cm',
          },
          height: {
            amount: item[3],
            unit: 'cm',
          },
        };
      });

      dispatch(
        setParcelValues({
          collectionLocation: {
            country: collectionData[0],
            postcode: !collectionData[1] ? '' : collectionData[1],
          },
          deliveryLocation: {
            country: deliveryData[0],
            postcode: !deliveryData[1] ? '' : deliveryData[1],
          },
          packageDetails,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (parcel.collectionLocation.country) {
      getUnavailableDates(parcel.collectionLocation.country)
        .then((res: any) => {
          setHolidays(res.data.map((holidayDate: any) => new Date(holidayDate)));
        });
    }
  }, [parcel.collectionLocation.country]);

  useEffect(() => {
    parcel.collectionLocation.country === '' ||
    dispatch(setParcelQuotes(parcel));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcel, dispatch]);

  const handleSelect = (selected: any) => {
    let position = 0;

    const selectedQuote = quotes.filter(({ id }: any, i: any) => {
      if (id === selected) {
        position = i + 1;
        return true;
      }

      return false;
    })[0];

    GTMECommerce(
      {
        click: {
          products: [{
            name: `${selectedQuote.carrier} - ${selectedQuote.name}`,
            id: `${selectedQuote.rateId}-${selectedQuote.serviceId}-${selectedQuote.type}`,
            price: selectedQuote.priceIncVat,
            category: 'parcel',
            variant: selectedQuote.type,
            position,
          }],
        },
      },
      'productClick',
    );

    dispatch(setSelectedService(selectedQuote, 'parcel'));
    dispatch(setItemDetails(parcel, 'parcel'));

    history.push('/order-step1');
  };

  useEffect(() => {
    if (moreInfoValue.intro) {
      if (moreInfoValue.restrictions) {
        setMoreInfoText(
          <>
            <PSmallStyled>{moreInfoValue.intro}</PSmallStyled>
            <PSmallStyled>Key features</PSmallStyled>
            <ul>
              <Li>
                <PSmall>
                  <span role="img" aria-label="Dot">
                    ◾
                  </span>
                  ️Delivery: {moreInfoValue.deliveryDays}
                </PSmall>
              </Li>
              <Li>
                <PSmall>
                  <span role="img" aria-label="Dot">
                    ◾
                  </span>
                  ️Delivery window: {moreInfoValue.deliveryWindow}
                </PSmall>
              </Li>
              {moreInfoValue.tracking && (
                <Li>
                  <PSmall>
                    <span role="img" aria-label="Dot">
                      ◾
                    </span>
                    ️Detailed tracking notifications
                  </PSmall>
                </Li>
              )}
              <Li>
                <PSmall>
                  <span role="img" aria-label="Dot">
                    ◾
                  </span>
                  ️Printer not required
                </PSmall>
              </Li>
              <PSmallStyled>Restrictions</PSmallStyled>
              {moreInfoValue.restrictions.map((res: any, i: any) =>
                <Li key={i}>
                  <PSmall>
                    <span role="img" aria-label="Dot">
                      ◾
                    </span>
                    {res}
                  </PSmall>
                </Li>,
              )}
            </ul>
          </>,
        );
      } else {
        setMoreInfoText(
          <>
            <PSmallStyled>{moreInfoValue.intro}</PSmallStyled>
            <ul>
              <Li>
                <PSmall>
                  <span role="img" aria-label="Dot">
                    ◾
                  </span>
                  ️Delivery: {moreInfoValue.deliveryDays}
                </PSmall>
              </Li>
              <Li>
                <PSmall>
                  <span role="img" aria-label="Dot">
                    ◾
                  </span>
                  ️Delivery window: {moreInfoValue.deliveryWindow}
                </PSmall>
              </Li>
              {moreInfoValue.tracking && (
                <Li>
                  <PSmall>
                    <span role="img" aria-label="Dot">
                      ◾
                    </span>
                    ️Detailed tracking notifications
                  </PSmall>
                </Li>
              )}
              <Li>
                <PSmall>
                  <span role="img" aria-label="Dot">
                    ◾
                  </span>
                  ️{moreInfoValue.redeliveryAttempts + 1} delivery attempts
                </PSmall>
              </Li>
              {moreInfoValue.podOnRequest && (
                <Li>
                  <PSmall>
                    <span role="img" aria-label="Dot">
                      ◾
                    </span>
                    ️Proof of delivery upon request
                  </PSmall>
                </Li>
              )}
            </ul>
          </>,
        );
      }
    }
  }, [moreInfoValue]);

  useEffect(() => {
    if (serviceInfoValue && serviceInfoValue.title) {
      let index = 0
      setServiceInfoText(getServiceInformationContent(serviceInfoValue));
    }
  }, [serviceInfoValue]);

  const setMoreInfoData = (value: any) => {
    setMoreInfoValue(value);
  };

  const setServiceInfoData = (value: any) => {
    setServiceInfoValue(value)
  }

  const quotesDisplay =
    quotes.length === 0 ||
    quotes.filter(({ active }: any) => active).length === 0 ? (
      <ManualQuoteRequest type='parcel' customDimensions={false} />
      ) : (
        quotes.map(
          ({
            id,
            rateId,
            name,
            logo,
            status,
            collectionDate = '',
            collectionDays = [],
            deliveryTime = 1,
            price,
            priceIncVat,
            type,
            printer,
            active,
            moreInfo,
            dropoffPoints,
            vat,
            sameday,
            pricesBeforeDiscount,
            coveredInsurance = 0,
            serviceInformation
          }: any) => {
            return (
              active && (
                <SingleResult
                  key={`${id}-${rateId}`}
                  id={id}
                  name={name}
                  logo={logo}
                  status={status}
                  deliveryTime={deliveryTime}
                  collectionDate={collectionDate}
                  collectionDays={collectionDays}
                  price={price}
                  priceIncVat={priceIncVat}
                  type={type}
                  printer={printer}
                  moreInfo={moreInfo}
                  dropoffPoints={dropoffPoints}
                  setMoreInfo={setMoreInfoData}
                  openMoreInfoModal={() => setMoreInfoModal(true)}
                  editDetails={() => toggleEditDetailsModal(true)}
                  customs={vat?.customs || false}
                  onSelect={handleSelect}
                  holidays={holidays}
                  sameday={sameday}
                  openLocations={setDropoffModal}
                  setLocations={(locations: any) => setSelectedLocations(locations)}
                  pricesBeforeDiscount={pricesBeforeDiscount}
                  coveredInsurance={coveredInsurance}
                  openInsurance={openInsurance}
                  setOpenEmailModal={setOpenEmailModal}
                  setServiceInfo={setServiceInfoData}
                  setServiceInfoTitle={setServiceInfoTitle}
                  openServiceInfoModal={() => setServiceInfoModal(true)}
                  serviceInformation={serviceInformation}
                />
              )
            );
          },
        )
      );

  const toggleEditDetailsModal = (value: boolean) => {
    setEditDetailsModal(value);
  };

  const handleEditDetailsClick = () => {
    setResetValues(true);
    toggleEditDetailsModal(true);
  };

  const handleMoreInfoClose = () => {
    setMoreInfoModal(false);
    setMoreInfoData({});
  };

  const handleServiceInfoClose = () => {
    setServiceInfoModal(false);
    setServiceInfoData({});
    setServiceInfoTitle('')
  };

  return (
    <>
      <DeliverBanner handleEditDetailsClick={handleEditDetailsClick} />
      
      <Filter />
      <Container>
        <MessageBanner />
        {
          !loading &&
          <EmailWrapper>
            <EmailQuote
              packageType="parcel"
              open={openEmailModal}
              setOpen={setOpenEmailModal}
            />
          </EmailWrapper>
        }
        <ResultsWrapper>
          {loading ? <QuoteLoader /> : quotesDisplay}
        </ResultsWrapper>
      </Container>
      <Modal
        title="Edit details"
        open={editDetailsModal}
        close={() => toggleEditDetailsModal(false)}
        width={8}
      >
        <StepOneParcel
          resetValues={resetValues}
          setResetValues={setResetValues}
          onQuotesPage={true}
          closeModal={() => toggleEditDetailsModal(false)}
        />
      </Modal>
      <Modal
        title="More information"
        open={moreInfoModal}
        close={() => handleMoreInfoClose()}
        back={true}
        width={8}
      >
        {moreInfoText}
      </Modal>
      <Modal
        title={serviceInfoTitle}
        open={serviceInfoModal}
        close={() => handleServiceInfoClose()}
        back={true}
        width={8}
      >
        {serviceInfoText}
      </Modal>
      <DropoffLocations
        open={dropoffModal}
        setOpen={setDropoffModal}
        locations={selectedLocations}
        clearLocations={() => setSelectedLocations([])}
      />
    </>
  );
}
;

const ResultsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Li = styled.li`
  margin-bottom: 16px;
`;

const PSmallStyled = styled(PSmall)`
  margin-bottom: 16px;
`;

const EmailWrapper = styled.div`
  margin-bottom: 24px;
`;

export default ParcelQuotes;
