/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import SingleResultPallet from './SingleResult';
import SingleResultFreight from '../FreightQuotes/SingleResult';
import MessageBanner from '../../MessageBanner';
import SalesBanner from './SalesBanner';
import {
  resetQuotes,
  setFreightQuotes,
  setItemDetails,
  setPallet,
  setPalletQuotes,
  setPalletQuotesSortedAction,
  setSelectedService,
} from '../../../../store/actions';
import NotFound from '../../../NotFound';
import Modal from '../../../../../assets/Combos/Modal';
import StepOnePallet from '../../../LandingPageQuote/components/QuoteForm/Pallet/StepOne';
import DateTile from './DateTile';
import { dateFormat } from '../../../../utils/variables';
import { salesEmailAddress } from '../../../../utils/env';
import Sort from '../../../Sort';
import { sort } from '../../../../utils/sorting';
import device from '../../../../utils/device';
import { SelectInput } from '../../../Inputs';
import { A, Container, H6, PSmall } from '../../../../../assets/elements';
import DeliverBanner from './DeliverBanner';
import { GTMECommerce } from '../../../../utils/tracking';
import QuoteLoader from '../../../QuoteLoader';
import EmailQuote from '../EmailQuote';
import DatesLoader from '../../../Loader/DatesLoader';
import { v4 as uuidv4 } from 'uuid';
import getServiceInformationContent from './ServiceInformation'
import ManualQuoteRequest from '../ManualQuoteRequest/ManualQuoteRequest';

const sortOptions = [
  {
    id: 0,
    label: 'Cheapest',
  },
  {
    id: 1,
    label: 'Quickest',
  },
];

export const SortValuesMap = {
  CHEAPEST: 0,
  QUICKEST: 1,
};

const palletSizes: any = {
  mini: {
    palletSizeId: 1,
    quantity: '',
  },
  quarter: {
    palletSizeId: 2,
    quantity: '',
  },
  half: {
    palletSizeId: 3,
    quantity: '',
  },
  light: {
    palletSizeId: 5,
    quantity: '',
  },
  full: {
    palletSizeId: 6,
    quantity: '',
  },
};

let dims: any = [];

const PalletQuotes = ({ openInsurance, openEmailModal, setOpenEmailModal }: any) => {
  const history = useHistory();
  const { collection, delivery, items: palletItems, palletAndWrap } = useParams<any>();

  const [editDetailsModal, setEditDetailsModal] = useState(false);
  const [resetValues, setResetValues] = useState(false);
  const [moreInfoValue, setMoreInfoValue] = useState<any>({});
  const [moreInfoText, setMoreInfoText] = useState<any>();
  const [moreInfoModal, setMoreInfoModal] = useState(false);

  const [serviceInfoValue, setServiceInfoValue] = useState<any>({});
  const [serviceInfoText, setServiceInfoText] = useState<any>();
  const [serviceInfoModal, setServiceInfoModal] = useState(false);
  const [serviceInfoTitle, setServiceInfoTitle] = useState('')

  const [sortSelected, setSortSelected] = useState(0);
  const [customDimensions, setCustomDimensions] = useState(false);
  const [selectedCollectionDate, setSelectedCollectionDate] = useState(
    'anytime'
  );

  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState('anytime');
  /* Earliest collection date */
  const [collectionDates, setCollectionDates] = useState([
    {
      value: 'anytime',
      label: 'Anytime',
      active: true,
    },
  ]);
  const [deliveryDates, setDeliveryDates] = useState([
    {
      value: 'anytime',
      label: 'Anytime',
      active: true,
    },
  ]);

  const [showCollectionDates, setShowCollectionDates] = useState<number>(5);
  const [showDeliveryDates, setShowDeliveryDates] = useState<number>(5);
  const [domestic, setDomestic ] = useState(true);

  const quotes = useSelector<any, any>((state) => state.main.palletQuotes);

  const pallet = useSelector<any, any>((state) => state.main.pallet);

  const loading = useSelector<any, any>((state) => state.main.loading.quotes);

  const dispatch = useDispatch();

  const discount = useSelector((state: any) => state.main.discount);

  useEffect(() => {
    setDomestic(pallet.collectionLocation?.country === pallet.deliveryLocation?.country && pallet.deliveryLocation?.country === 'GB');
  }, [pallet]);

  useEffect(() => {
    const collectionDatesArray: any = [];
    const deliveryDatesArray: any =[];
    quotes.map((quote: any) => {
      quote.shippingDates.map((shippingDate: any) => {
        const collectionDate = shippingDate.cd;
        if (!collectionDatesArray.includes(collectionDate)) collectionDatesArray.push(collectionDate);
        shippingDate.dd.map((deliveryDate: string) => {
          const afterCollection = moment(deliveryDate).valueOf() > moment(selectedCollectionDate).valueOf() || selectedCollectionDate === 'anytime';
          if (!deliveryDatesArray.includes(deliveryDate) && afterCollection) {
            deliveryDatesArray.push(deliveryDate);
          }
        });
      });
    });
    const collectionTiles = processDateTiles(collectionDatesArray, showCollectionDates);
    const deliveryTiles = processDateTiles(deliveryDatesArray, showDeliveryDates);
    setCollectionDates(collectionTiles);
    setDeliveryDates(deliveryTiles);
  }, [quotes, selectedCollectionDate]);

  useEffect(() => {
    if (collection) {
      const collectionData = collection.split(':');
      const deliveryData = delivery.split(':');

      !collectionData[0] &&
        pallet.collectionLocation.country === '' &&
        history.push('/');

      const items = palletItems.split(':');
      const packageDetails = { ...palletSizes };
      items.forEach((pack: any) => {
        const item = pack.split(',');

        if (isNaN(item[0])) {
          packageDetails[item[0]] = {
            ...packageDetails[item[0]],
            quantity: Number(item[1]),
          };
        } else {
          setCustomDimensions(true);
          dims = [
            ...dims,
            {
              id: uuidv4(),
              length: Number(item[0]),
              width: Number(item[1]),
              height: Number(item[2]),
              weight: Number(item[3]),
              quantity: Number(item[4]),
            },
          ];
        }
      });

      dispatch(
        setPallet({
          collectionLocation: {
            country: collectionData[0],
            postcode: !collectionData[1] ? '' : collectionData[1],
          },
          deliveryLocation: {
            country: deliveryData[0],
            postcode: !deliveryData[1] ? '' : deliveryData[1],
          },
          packageDetails,
          dims,
          palletAndWrap: palletAndWrap === 'true',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      moment(selectedCollectionDate).diff(selectedDeliveryDate, 'days') >= 0
    ) {
      setSelectedDeliveryDate('anytime');
    }
    const payload = quotes.map((quote: any) => {
      return processActiveQuote(quote);
    });

    dispatch(setPalletQuotesSortedAction(payload));
  }, [selectedCollectionDate, selectedDeliveryDate]);

  useEffect(() => {
    let quantity = 0;
    if (pallet?.dims?.length) {
      pallet?.dims?.forEach((item: any) => {
        quantity += Number(item.quantity);
      });
    } else {
      let key: any;
      let value: any;
      for ([key, value] of Object.entries(pallet.packageDetails)) {
        quantity += Number(value.quantity);
      }
    }

    dispatch(setPalletQuotesSortedAction([]));

    if (pallet.collectionLocation.country !== '') {
      dispatch(
        setPalletQuotes(generateRequestObject(pallet, true), false, false, true)
      );

      if (quantity >= 6) {
        dispatch(setFreightQuotes(generateFreightRequestObject(pallet, quantity, 'LTL'), true));
      }
    }
  }, [pallet, dispatch]);

  useEffect(() => {
    if (moreInfoValue.intro) {
      setMoreInfoText(
        <>
          <PSmallStyled>{moreInfoValue.intro}</PSmallStyled>
          <ul>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                ️Delivery: {moreInfoValue.deliveryDays}
              </PSmall>
            </Li>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                ️Delivery window: {moreInfoValue.deliveryWindow}
              </PSmall>
            </Li>
            {moreInfoValue.tracking && (
              <Li>
                <PSmall>
                  <span role="img" aria-label="Dot">
                    ◾
                  </span>
                  ️Detailed tracking notifications
                </PSmall>
              </Li>
            )}
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                ️{moreInfoValue.redeliveryAttempts + 1} delivery attempts
              </PSmall>
            </Li>
            {moreInfoValue.podOnRequest && (
              <Li>
                <PSmall>
                  <span role="img" aria-label="Dot">
                    ◾
                  </span>
                  ️Proof of delivery upon request
                </PSmall>
              </Li>
            )}
          </ul>
        </>
      );
    }
  }, [moreInfoValue]);

  useEffect(() => {
    if (serviceInfoValue && serviceInfoValue.title) {
      let index = 0
      setServiceInfoText(getServiceInformationContent(serviceInfoValue));
    }
  }, [serviceInfoValue]);

  const generateRequestObject = (items: any, remove: boolean = false) => {
    const palletItem = { ...items };
    const extras = [];

    if (palletItem.palletAndWrap) {
      extras.push('palletAndWrap');
    }

    if (remove) {
      delete palletItem.palletAndWrap;
    }

    if (extras.length !== 0) {
      return {
        ...palletItem,
        packageDetails: Object.values(palletItem.packageDetails).filter(
          (item: any) => item.quantity
        ),
        extras,
      };
    }
    return {
      ...palletItem,
      packageDetails: Object.values(palletItem.packageDetails).filter(
        (item: any) => item.quantity
      ),
    };
  };

  const processDateTiles = (dates: any, numOfDates: number) => {
    const sortedDatesArray: any  = dates.sort((a: string, b: string) => moment(a).valueOf() - moment(b).valueOf());
    const datesArray: any[] = [
      {
        label: 'Anytime',
        value: 'anytime',
        active: true,
      },
    ];
    const tomorrow = moment().add(1, 'day').endOf('day');
    sortedDatesArray.map((date: any) => {
      const tomorrowSubtext = date < tomorrow ? 'Tomorrow' : '';
      datesArray.push({
        label: moment(date).format(dateFormat),
        value: moment(date).format('yyyy-MM-DD'),
        active: datesArray.length < numOfDates,
        caption: moment(0, 'HH').isSame(date) ? 'Today' : tomorrowSubtext,
      });
    });
    return datesArray;
  };

  const generateFreightRequestObject = (items: any, numPallets: number, loadType: string) => {
    const tempItem = cloneDeep(items);
    delete tempItem.packageDetails;
    delete tempItem.palletAndWrap;

    return {
      ...items,
      numPallets,
      loadType
    };
  };

  const showMoreCollectionDates = () => {
    const days = showCollectionDates === 5 ? 10 : 5;
    setShowCollectionDates(days);

    const dates = collectionDates.map((date: any, i: any) => {
      date.active = i < days;

      return date;
    });

    setCollectionDates(dates);
  };

  const showMoreDeliveryDates = () => {
    const days = showDeliveryDates === 5 ? 10 : 5;
    setShowDeliveryDates(days);

    const dates = deliveryDates.map((date: any, i: any) => {
      date.active = i < days;

      return date;
    });

    setDeliveryDates(dates);
  };

  const setMoreInfoData = (value: any) => {
    setMoreInfoValue(value);
  };

  const setServiceInfoData = (value: any) => {
    setServiceInfoValue(value)
  }

  const handleSortChange = (data: any) => {
    setSortSelected(data);

    let payload;

    if (data === SortValuesMap.CHEAPEST) {
      payload = sort(quotes, 'price');
    } else if (data === SortValuesMap.QUICKEST) {
      payload = quotes.sort((a: any, b: any) =>
        moment(a.shippingDates[0].dd[0]).diff(moment(b.shippingDates[0].dd[0]))
      );
    }

    dispatch(setPalletQuotesSortedAction(payload));
  };

  const handleSelect = (selected: any, selectedD: any, selectedC: any) => {
    let position = 0;
    const selectedQuote = quotes.filter(({ serviceId }: any, i: any) => {
      if (serviceId === selected) {
        position = i + 1;
        return true;
      }

      return false;
    })[0];

    selectedQuote.collectionDate = moment(selectedC).format('yyyy-MM-DD');

    selectedQuote.deliveryDate = moment(selectedD).format('yyyy-MM-DD');

    GTMECommerce(
      {
        click: {
          products: [
            {
              name: selectedQuote.name,
              id: `${selectedQuote.rateId}-${selectedQuote.serviceId}-pallet`,
              price: selectedQuote.priceIncVat,
              category: 'pallet',
              variant: 'collection',
              position,
            },
          ],
        },
      },
      'productClick'
    );

    dispatch(
      setSelectedService(selectedQuote, selectedQuote?.quoteType || 'pallet')
    );
    dispatch(
      setItemDetails(
        generateRequestObject(pallet),
        selectedQuote?.quoteType || 'pallet'
      )
    );
    dispatch(resetQuotes());

    history.push('/order-step1');
  };

  const [noQuoteInformation, setNoQuoteInformation] = useState({
    request: pallet.data,
    name:'',
    company:'',
    email: '',
    phone: '',
    quoteId: '',
    submitted: false,
  });

  // populate collection and delivery dates

  const collectionDatesJSX = (
    <DatesWrapper>
      <H6Styled>Collection date</H6Styled>
      <DatesBig>
        <DatesContainer>
          {collectionDates.map(
            (date: any, i: number) =>
              date.active && (
                <DateTile
                  key={i}
                  label={date.label}
                  value={date.value}
                  caption={date.caption}
                  selected={date.value === selectedCollectionDate}
                  onChange={setSelectedCollectionDate}
                />
              )
          )}
        </DatesContainer>
        <div>
          <ButtonShowMore onClick={showMoreCollectionDates}>
            {showCollectionDates === 5 ? 'Show more dates' : 'Show less'}
          </ButtonShowMore>
        </div>
      </DatesBig>
      <DatesSmall>
        <SelectInput
          data={collectionDates}
          error={false}
          resetError={() => {}}
          value={selectedCollectionDate}
          onChange={setSelectedCollectionDate}
        />
      </DatesSmall>
    </DatesWrapper>
  );

  const deliveryDatesJSX = (
    <DatesWrapper>
      <H6Styled>Delivery date</H6Styled>
      <DatesBig>
        <DatesContainer>
          {deliveryDates.map(
            (date: any, i: number) =>
              date.active && (
                <DateTile
                  key={i}
                  label={date.label}
                  value={date.value}
                  caption={date.caption}
                  selected={date.value === selectedDeliveryDate}
                  onChange={setSelectedDeliveryDate}
                />
              )
          )}
        </DatesContainer>
        <div>
          <ButtonShowMore onClick={showMoreDeliveryDates}>
            {showDeliveryDates === 5 ? 'Show more dates' : 'Show less'}
          </ButtonShowMore>
        </div>
      </DatesBig>
      <DatesSmall>
        <SelectInput
          data={deliveryDates}
          error={false}
          resetError={() => {}}
          value={selectedDeliveryDate}
          onChange={setSelectedDeliveryDate}
        />
      </DatesSmall>
    </DatesWrapper>
  );

  const SortJSX = (
    <SortWrapper>
      <Sort
        options={sortOptions}
        selected={sortSelected}
        onChange={handleSortChange}
      />
      <EmailQuote
        packageType="pallet"
        filters={{
          collectionDate: selectedCollectionDate,
          deliveryDate: selectedDeliveryDate,
        }}
        open={openEmailModal}
        setOpen={setOpenEmailModal}
      />
    </SortWrapper>
  );

  const processActiveQuote = (quote: any) => {
    let active = false;
    if (selectedCollectionDate === 'anytime' && selectedDeliveryDate === 'anytime') {
      active = true;
    } else if (selectedCollectionDate !== 'anytime' && selectedDeliveryDate !== 'anytime') {
      quote.shippingDates.map((shippingDate: any) => {
        if (shippingDate.cd === selectedCollectionDate && shippingDate.dd.includes(selectedDeliveryDate)) {
          active = true;
        }
      });
    } else if (selectedCollectionDate !== 'anytime') {
      quote.shippingDates.map((shippingDate: any) => {
        if (shippingDate.cd === selectedCollectionDate) {
          active = true;
        }
      });
    } else if (selectedDeliveryDate !== 'anytime') {
      quote.shippingDates.map((shippingDate: any) => {
        if (shippingDate.dd.includes(selectedDeliveryDate)) {
          active = true;
        }
      });
    }
    quote.active = active;
    return quote;
  };

  const processQuoteDisplay = (inputQuote: any) => {
    const quote = processActiveQuote(inputQuote);

    if (!quote.active) return <></>;
    if (quote.quoteType === 'pallet') {
      return <SingleResultPallet
        key={quote.serviceId}
        id={quote.serviceId}
        name={quote.name}
        logo={quote.logo}
        status={quote.status}
        deliveryDays={quote.deliveryDays}
        price={quote.price}
        priceIncVat={quote.priceIncVat}
        moreInfo={quote.moreInfo}
        setMoreInfo={setMoreInfoData}
        openMoreInfoModal={() => setMoreInfoModal(true)}
        selectedCD={selectedCollectionDate}
        selectedDD={selectedDeliveryDate}
        onSelect={handleSelect}
        customs={quote.vat.customs}
        timed={quote.timed}
        timeslots={quote.timeslots}
        pallet={pallet}
        pricesBeforeDiscount={quote.pricesBeforeDiscount}
        discount={discount.value}
        palletAndWrap={pallet.palletAndWrap}
        coveredInsurance={quote.coveredInsurance}
        openInsurance={openInsurance}
        weight={quote.weight}
        setServiceInfo={setServiceInfoData}
        setServiceInfoTitle={setServiceInfoTitle}
        openServiceInfoModal={() => setServiceInfoModal(true)}
        serviceInformation={quote.serviceInformation}
        setOpenEmailModal={setOpenEmailModal}
        shippingDates={quote.shippingDates}
      />;
    } 
    return <SingleResultFreight
      key={quote.serviceId}
      id={quote.serviceId}
      name={quote.name}
      logo={quote.logo}
      status={quote.status}
      expires={quote.expires}
      price={quote.price}
      priceIncVat={quote.priceIncVat}
      moreInfo={quote.moreInfo}
      setMoreInfo={setMoreInfoData}
      openMoreInfoModal={() => setMoreInfoModal(true)}
      onSelect={handleSelect}
      pricesBeforeDiscount={quote.pricesBeforeDiscount}
      coveredInsurance={quote.coveredInsurance}
      openInsurance={openInsurance}
      setOpenEmailModal={setOpenEmailModal}
      shippingDates={quote.shippingDates}
    />;
    
  };

  const toggleEditDetailsModal = (value: boolean) => {
    setEditDetailsModal(value);
  };

  const handleEditDetailsClick = () => {
    setResetValues(true);
    toggleEditDetailsModal(true);
  };

  const handleMoreInfoClose = () => {
    setMoreInfoModal(false);
    setMoreInfoData({});
  };

  const handleServiceInfoClose = () => {
    setServiceInfoModal(false);
    setServiceInfoData({});
    setServiceInfoTitle('')
  };

  const handleBusinessAccountClick = (e: any) => {
    window.location.href = `mailto:${salesEmailAddress}?subject=Business%20Account%20Enquiry&body=I\'m%20interested%20in%20opening%20a%20business%20account.`;
  };

  return (
    <>
      <DeliverBanner
        data={pallet}
        handleEditDetailsClick={handleEditDetailsClick}
      />
      <Container>
        <ResultsWrapper>
          {MessageBanner && <MessageBanner />}
          {loading ? (
            <QuoteLoader />
          ) : (quotes.length === 0 ?
            <ManualQuoteRequest type='pallet' customDimensions={customDimensions}/> : (
              <>
                {collectionDates.length > 1 && domestic ? (
                  <>
                    {collectionDatesJSX}
                    {deliveryDatesJSX}
                    {SortJSX}
                  </>
                ) : (
                  domestic ? <DatesLoader /> : null
                )}
                {SalesBanner && <SalesBanner pallet={pallet} />}
                {quotes.filter(({ active }: any) => active).length === 0 ? (
                  <NotFound>
                    Sorry! No results found. Please remove any filters or update your item
                    details to find a quote.
                  </NotFound>
                ): quotes.map((quote: any) => {
                  return processQuoteDisplay(quote);
                })}
              </>
            )
          )}
        </ResultsWrapper>
      </Container>
      <Modal
        title="Edit details"
        open={editDetailsModal}
        close={() => toggleEditDetailsModal(false)}
        width={10}
      >
        <StepOnePallet
          resetValues={resetValues}
          setResetValues={setResetValues}
          onQuotesPage={true}
          closeModal={() => toggleEditDetailsModal(false)}
          customDimensions={customDimensions}
        />
      </Modal>
      <Modal
        title="More information"
        open={moreInfoModal}
        close={() => handleMoreInfoClose()}
        back={true}
        width={8}
      >
        {moreInfoText}
      </Modal>
      <Modal
        title={serviceInfoTitle}
        open={serviceInfoModal}
        close={() => handleServiceInfoClose()}
        back={true}
        width={8}
      >
        {serviceInfoText}
      </Modal>
      <Modal
        title="Thank you!"
        open={noQuoteInformation.submitted}
        close={() =>
          setNoQuoteInformation({
            request: null,
            name: '',
            company: '',
            email: '',
            phone: '',
            submitted: false,
            quoteId: '',
          })
        }
        width={8}
      >
        <PStyled>
          Your request has been successfully sent to our sales team. We aim to
          respond within 2 business hours.
        </PStyled>
        <PStyled>
          <br /> If you are a business, you could be eligible for even better
          rates via our account managed solution.{' '}
          <ButtonNoResults onClick={handleBusinessAccountClick}>
            Contact our sales team
          </ButtonNoResults>{' '}
          today and let them know you're interested in opening a business
          account.
        </PStyled>
      </Modal>
    </>
  );
};

const ResultsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Li = styled.li`
  margin-bottom: 16px;
`;

const PSmallStyled = styled(PSmall)`
  margin-bottom: 16px;
`;

const SortWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 38px;
  flex-direction: column;
  align-items: flex-end;

  @media ${device.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

const DatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  @media ${device.laptop} {
    margin-bottom: 20px;
  }
`;

const DatesBig = styled.div`
  flex-direction: column;
  width: 100%;
  display: none;

  @media ${device.laptop} {
    display: flex;
  }
`;

const DatesSmall = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.laptop} {
    display: none;
  }
`;

const DatesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const H6Styled = styled(H6)`
  margin-bottom: 10px;
`;

const ButtonNoResults = styled(A)``;

const ButtonShowMore = styled(A)`
  display: flex;
  margin-top: 10px;
  margin-left: 4px;
`;

const PStyled = styled.p<any>`
  line-height: 150%;
  color: #777e7e;
`;

export default PalletQuotes;
