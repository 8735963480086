import React  from 'react';
import styled from 'styled-components';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Container, Row, Column, A } from '../../../../../../assets/elements';
import Printer from '../../../../../../assets/icons/Printer.svg';
import Info from '../../../../../../assets/icons/Info.svg';
import device from '../../../../../utils/device';
import { dateFormat } from '../../../../../utils/variables';
import VanIcon from '../../../../../../assets/icons/Transport-van.svg';
import ShipIcon from '../../../../../../assets/icons/Transport-ship.svg';

const Service = ({ items, openMoreInfo, setMoreInfo, totalPrice }: any) => {

  const deliveryTimeFormat = (days: number) =>
    `${days} ${days === 0 || days > 2 ? 'days' : 'day'}`;

  const handleMoreInfoClick = (moreInfo: any) => {
    if (moreInfo?.intro) {
      setMoreInfo(moreInfo);
      openMoreInfo();
    }
  };

  const calcDeliveryTime = (days: any) => {
    if (days === 0) {
      return 'Same day';
    }

    if (days === false || days === null) {
      return 'Provided after booking';
    }

    return `${days} day${(days === 0 || days > 1) ? 's' : ''}`;
  };

  return (
    <Wrapper>
      {items !== undefined &&
        items.map(
          (
            {
              item: {
                name,
                logo,
                status,
                deliveryTime,
                deliveryDate,
                collectionDate,
                price,
                priceIncVat,
                type,
                printer,
                dropoffPoints = [],
                editDetails,
                moreInfo,
                vat,
                insurance = {},
                expiryDate = new Date(),
              },
              serviceType,
              collectionAddress,
              deliveryAddress,
            }: any,
            i: any
          ) => (
            <ItemContainer key={i}>
              <Brand>
                <BrandImg src={logo} alt={`${name} logo`} />
              </Brand>
              <RowStyled>
                <Title>{name}</Title>
                <Icons>
                  <>
                    {
                      serviceType === 'freight' && (
                        <PrinterIcon alt="Road freight" src={VanIcon} />
                      )
                    }
                    {
                      serviceType === 'seaFreight' && (
                        <PrinterIcon alt="Sea freight" src={ShipIcon} />
                      )
                    }
                    <PrinterIcon
                      data-tip
                      data-for="id-icon"
                      alt="info icon"
                      src={Info}
                      onClick={() => handleMoreInfoClick(moreInfo)}
                    />
                    <ReactTooltip id="id-icon" effect="solid">
                      More info
                    </ReactTooltip>
                  </>
                  {printer && (
                    <>
                      <PrinterIcon
                        data-tip
                        data-for="printer-icon"
                        alt="printer icon"
                        src={Printer}
                      />
                      <ReactTooltip id="printer-icon" effect="solid">
                        Printer required
                      </ReactTooltip>
                    </>
                  )}
                </Icons>
              </RowStyled>
              <RowStyled>
                <ColumnStyled sizeL={6} sizeM={6} sizeS={6} sizeXS={2}>
                  {type ? (
                    <ParcelTypeContainer type={type}>
                      <ParcelTypeHead type={type}>
                        {type === 'collection' ? 'Collection' : 'Drop off'}
                      </ParcelTypeHead>
                      <ParcelTypeData>
                        {type === 'collection' && (
                          <Moment format="ddd D MMM">{collectionDate}</Moment>
                        )}
                        {type === 'dropoff' && (
                          <DropOffMessage>
                            Nearest drop off{' '}
                            {dropoffPoints[0]?.distance?.amount}
                            {dropoffPoints[0]?.distance?.unit}
                          </DropOffMessage>
                        )}
                      </ParcelTypeData>
                    </ParcelTypeContainer>
                  ) : (
                    <DeliveryTimeContainer>
                      <DeliveryTimeHead>
                        {
                          serviceType === 'freight' || serviceType === 'seaFreight' ?
                            'Estimated Transit Time' :
                            'Collection'
                        }
                      </DeliveryTimeHead>
                      <DeliveryTimeData>
                        {
                          serviceType === 'freight' || serviceType === 'seaFreight' ?
                            calcDeliveryTime(deliveryTime) :
                            moment(collectionDate).format(dateFormat)
                        }
                      </DeliveryTimeData>
                    </DeliveryTimeContainer>
                  )}
                </ColumnStyled>
                <ColumnStyled sizeL={6} sizeM={6} sizeS={6} sizeXS={2}>
                  <DeliveryTimeContainer>
                    <DeliveryTimeHead>
                      {
                        type && 'Delivery time'
                      }
                      {
                        !type && (serviceType === 'freight' || serviceType === 'seaFreight' ? 'Quote Expires' : 'Delivery date')
                      }
                    </DeliveryTimeHead>
                    <DeliveryTimeData>
                      { type && deliveryTimeFormat(deliveryTime) }
                      { !type && (serviceType === 'freight' || serviceType === 'seaFreight' ? moment(expiryDate).format(dateFormat) : moment(deliveryDate).format(dateFormat))}
                    </DeliveryTimeData>
                  </DeliveryTimeContainer>
                </ColumnStyled>
              </RowStyled>
            </ItemContainer>
          )
        )}
      <TotalPrice>
        <TotalPriceLabel>
          <TotalPriceTitle>Total</TotalPriceTitle>
          <TotalPriceSubTitle>VAT included</TotalPriceSubTitle>
        </TotalPriceLabel>
        <TotalPriceValue>£{totalPrice.toFixed(2)}</TotalPriceValue>
      </TotalPrice>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  background: #ffffff;
  border-radius: 8px;
  && {
    padding: 15px;
    @media ${device.tablet} {
      padding: 30px;
    }
  }
  width: 100%;
  height: auto;
  border-top: 5px solid ${({ theme }: any) => theme.colors.Secondary};
`;

const ItemContainer = styled.div`
  padding: 24px 0;
  border-bottom: 2px solid #f2f3f2;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
`;
const Brand = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BrandImg = styled.img`
  max-height: 88px;
  width: auto;
  max-width: 100px;
`;
const RowStyled = styled(Row)`
  justify-content: space-between;
  margin-top: 40px;
  flex-wrap: nowrap;

  @media ${device.laptop} {
    flex-wrap: wrap;
  }

  @media ${device.laptopL} {
    flex-wrap: nowrap;
  }
`;
const Icons = styled.div`
  width: auto;
  display: flex;
`;
const PrinterIcon = styled.img`
  margin-left: 20px;
`;
const ParagraphInfo = styled.p`
  color: #777e7e;
  line-height: 150%;
  margin-top: 20px;
`;
const Price = styled.p`
  font-weight: 500;
  font-size: 19px;
  line-height: 150%;
`;
const ParcelTypeContainer = styled.div.attrs(({ type }: any) => ({
  type,
}))`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: ${({ type, theme }: any) =>
    type === 'collection' ? theme.colors.CollectionBackground : theme.colors.DropoffBackground};
  align-items: center;
  border-radius: 4px;
  height: 100%;

  @media ${device.laptop} {
    margin-bottom: 16px;
  }

  @media ${device.laptopL} {
    margin-bottom: 0;
  }
`;

const ParcelTypeHead = styled.div.attrs(({ type }: any) => ({
  type,
}))`
  background: ${({ type, theme }: any) =>
    type === 'collection' ? theme.colors.CollectionHead : theme.colors.DropoffHead};
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  color: ${({ theme }: any) => theme.colors.White};
  padding: 7px 4px;
  justify-content: center;
`;

const ParcelTypeData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 19px;
  line-height: 150%;
  font-weight: 500;
  margin-top: 13px;
`;
const DropOffMessage = styled(A)`
  text-decoration: underline;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 135%;
  color: ${({ theme }: any) => theme.colors.DropoffHead};
`;
const DeliveryTimeContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  color: ${({ theme }: any) => theme.colors.Black};
  border-radius: 4px;
  height: 100%;
`;

const DeliveryTimeHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  padding: 7px 4px;
  text-align: center;
`;

const DeliveryTimeData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 19px;
  line-height: 150%;
  font-weight: 500;
  margin-top: 13px;
`;
const ColumnStyled = styled(Column)`
  flex: 1;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  @media ${device.laptop} {
    margin-bottom: 20px;
  }

  @media ${device.laptopL} {
    margin-bottom: 0;
  }
`;

const TotalPrice = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 22px;
`;

const TotalPriceLabel = styled.div`
  display: flex;
  align-items: baseline;
`;

const TotalPriceTitle = styled.div`
  font-weight: 500;
  font-size: 19px;
  line-height: 150%;
  margin-right: 5px;
`;

const TotalPriceSubTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const TotalPriceValue = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 135%;
`;

export default Service;
