import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from '../../../../../utils/hooks/useQuery';
import Loader from '../../../../Loader';
import { completeKriyaTransaction } from '../../../../../utils/APICalls/Order';
import { resetBasket, sendBasketData } from '../../../../../store/actions';
import { GTMECommerce } from '../../../../../utils/tracking';
import { setCookie } from '../../../../../utils/cookies';

const FailedKriya = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    history.push('/payment')
  });

  return (
    <Wrapper>
      <Loader>Redirecting back to the payment page </Loader>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 50vh;
  align-items: center;
`;

export default FailedKriya;
