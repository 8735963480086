import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import device from '../../utils/device';
import {
  A,
  Button,
  Caption,
  Column,
  Container,
  H4,
  Input,
  Label,
  PSmall,
  Row,
} from '../../../assets/elements';
import { resetPassword } from '../../utils/APICalls/Account';

const ForgotPassword = ({ theme }: any) => {
  const history = useHistory();

  const [email, setEmail] = useState('');

  const [emailError, setEmailError] = useState<any>(false);

  const [linkSending, setLinkSending] = useState(false);

  const [linkSent, setLinkSent] = useState(false);

  const [resetError, setResetError] = useState<any>(false);

  const handleChange = (e: any) => {
    setEmailError(false);

    setEmail(e.target.value);
  };

  const validateForm = () => {
    let error = false;

    if (email === '') {
      setEmailError('Required');

      error = true;
    } else if (
      !email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/)
    ) {
      setEmailError('Enter valid email');

      error = true;
    }

    return error;
  };

  const handleBack = () => {
    // @ts-ignore
    history.goBack();
  };

  const goToSignin = () => {
    history.push('/login');
  };

  const handleSubmit = (e: any) => {
    e.nativeEvent.preventDefault();

    setResetError(false);
    const error = validateForm();

    if (!error) {
      setLinkSending(true);

      resetPassword({
        email,
      })
        .then((res: any) => {
          setLinkSent(true);
        })
        .catch(() => {
          setResetError('Sorry! This email does not exist in our database');
          setLinkSent(false);
          window.scroll(0, 0);
        })
        .finally(() => {
          setLinkSending(false);
        });
    }
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <Column sizeL={4} sizeM={4} sizeS={3} sizeXS={4}>
            <H4Styled>
              {linkSent ? 'Check out your email' : 'Forgot password?'}
            </H4Styled>
            <SubTitle>
              {linkSent
                ? "We've sent a reset password link to your inbox. Please open the link to reset your password."
                : "Enter your email address below and we'll send you a reset link"}
            </SubTitle>
            {linkSent && (
              <ButtonWrapper>
                <Button color="black" onClick={goToSignin}>
                  Back to Sign in
                </Button>
              </ButtonWrapper>
            )}
            {!linkSent && (
              <>
                {resetError && <ErrorStyled>{resetError}</ErrorStyled>}
                <InputWrapper>
                  <LabelStyled>Email</LabelStyled>
                  <Input
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e: any) => handleChange(e)}
                    theme={theme}
                    type="text"
                    error={emailError}
                  />
                  {emailError && <ErrorText>{emailError}</ErrorText>}
                </InputWrapper>
                <ButtonsContainer>
                  <Button onClick={handleSubmit} color="secondary">
                    {linkSending
                      ? 'Sending link...'
                      : 'Send reset password link'}
                  </Button>
                  <AStyled onClick={handleBack}>Cancel</AStyled>
                </ButtonsContainer>
              </>
            )}
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background: ${({ theme }: any) => theme.colors.GrayWhite};
  min-height: calc(100vh - 76px);
  display: flex;
  justify-content: center;
  padding: 32px 0;

  @media ${device.laptop} {
    padding: 48px 0;
  }
`;

const H4Styled = styled(H4)`
  text-align: center;
  margin-bottom: 16px;
  font-size: 25px;
  line-height: 34px;

  @media ${device.laptopL} {
    font-size: 25px;
  }
`;

const SubTitle = styled(PSmall)`
  text-align: center;
  margin-bottom: 32px;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  position: relative;
`;

const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: 400;
  text-align: left;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const AStyled = styled(A)`
  margin: 34px 0 48px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorStyled = styled.div`
  display: inline-block;
  padding: 12px;
  background: rgba(224, 76, 102, 0.2);
  border-radius: 4px;
  color: ${({ theme }: any) => theme.colors.Error};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin-bottom: 24px;
  text-align: center;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export default ForgotPassword;
