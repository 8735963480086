import React from 'react';
import styled from 'styled-components';

const FilterItem = ({ children, selected, onClick, id }: any) => {
  return (
    <Wrapper selected={selected} onClick={() => onClick(id)}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs(({ selected }: any) => ({
  selected,
}))`
  padding: 8px 20px;
  display: flex;
  flex-direction: row;
  background-color: ${({ selected, theme }: any) =>
    selected ? theme.colors.Black : theme.colors.White};
  color: ${({ selected, theme }: any) =>
    selected ? theme.colors.White : theme.colors.Black};
  border-radius: 60px;
  margin-right: 24px;
  align-items: center;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
`;

export default FilterItem;
