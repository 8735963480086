import React, { useState } from 'react';
import styled from 'styled-components';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { Caption, Column, Input, Row } from '../../../../assets/elements';
import device from '../../../utils/device';

const CardDetails = ({
  theme,
  zip,
  errors,
  handleChange,
  setErrors,
  details,
  setDetails,
}: any) => {
  const [focused, setFocused] = useState('');

  const styleObject = {
    base: {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#2B3131',
      backgroundColor: '#FFFFFF',
      '::placeholder': {
        color: '#BCC0BF',
      },
      ':focus': {
        color: '#2B3131',
      },
    },
  };

  const handleFocus = (type: string) => {
    setFocused(type);
  };

  const handleBlur = () => {
    setFocused('');
  };

  const handleDetailsChange = (e: any, key: any) => {
    if (e.error) {
      setErrors({
        ...errors,
        [key]: e.error.message,
      });
    } else {
      setDetails({
        ...details,
        [key]: e.empty,
      });

      setErrors({
        ...errors,
        [key]: false,
      });
    }
  };

  return (
    <Wrapper>
      <Heading>Card details</Heading>
      <RowStyled>
        <ColumnStyled sizeL={12} sizeM={12} sizeS={6} sizeXS={4}>
          <CaptionStyled>Card number</CaptionStyled>
          <CardElementContainer
            focused={focused === 'number'}
            error={errors.cardNumber}
          >
            <CardNumberElement
              options={{ style: styleObject }}
              onFocus={() => handleFocus('number')}
              // @ts-ignore
              onBlur={handleBlur}
              onChange={(e: any) => handleDetailsChange(e, 'cardNumber')}
            />
          </CardElementContainer>
          {errors.cardNumber && <ErrorText>{errors.cardNumber}</ErrorText>}
        </ColumnStyled>
      </RowStyled>
      <RowStyled>
        <ColumnStyled sizeL={6} sizeM={6} sizeS={3} sizeXS={4}>
          <CaptionStyled>Expiry date</CaptionStyled>
          <CardElementContainer
            focused={focused === 'expiryDate'}
            error={errors.expiryDate}
          >
            <CardExpiryElement
              options={{ style: styleObject }}
              onFocus={() => handleFocus('expiryDate')}
              onBlur={handleBlur}
              onChange={(e: any) => handleDetailsChange(e, 'expiryDate')}
            />
          </CardElementContainer>
          {errors.expiryDate && <ErrorText>{errors.expiryDate}</ErrorText>}
        </ColumnStyled>
        <ColumnStyled sizeL={6} sizeM={6} sizeS={3} sizeXS={4}>
          <CaptionStyled>CVC</CaptionStyled>
          <CardElementContainer
            focused={focused === 'cvv'}
            error={errors.expiryDate}
          >
            <CardCvcElement
              options={{ style: styleObject }}
              onFocus={() => handleFocus('cvv')}
              onBlur={handleBlur}
              onChange={(e: any) => handleDetailsChange(e, 'cvv')}
            />
          </CardElementContainer>
          {errors.cvc && <ErrorText>{errors.cvc}</ErrorText>}
        </ColumnStyled>
      </RowStyled>
      <RowPostcode>
        <ColumnPostcode sizeL={6} sizeM={6} sizeS={3} sizeXS={4}>
          <CaptionStyled>Postcode</CaptionStyled>
          <Input
            theme={theme}
            value={zip}
            type="text"
            placeholder="Postcode"
            onChange={(e: any) => handleChange(e)}
            error={errors.zip}
          />
          {errors.zip && <ErrorText>{errors.zip}</ErrorText>}
        </ColumnPostcode>
      </RowPostcode>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 16px;
`;

const RowStyled = styled(Row)`
  margin-bottom: 23px;
`;

const RowPostcode = styled(Row)`
  justify-content: flex-start;
`;

const ColumnPostcode = styled(Column)`
  padding: 0;

  @media ${device.laptop} {
    padding-right: 15px;
    padding-left: 0;
  }
`;

const ColumnStyled = styled(Column)`
  position: relative;
  padding: 0;

  &:first-child {
    margin-bottom: 23px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    padding: 0 15px;

    &:first-child {
      margin-bottom: 0;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

const CaptionStyled = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.MidGrey};
  margin-bottom: 4px;
  display: inline-block;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: none;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;

  @media ${device.laptopL} {
    display: inline-block;
  }
`;

const CardElementContainer = styled.div.attrs(({ focused, error }: any) => ({
  focused,
  error,
}))`
  border: 2px solid;
  border-color: ${({ theme, focused, error }: any) =>
  // eslint-disable-next-line no-nested-ternary
    error
      ? theme.colors.Error
      : focused
        ? theme.colors.Secondary
        : theme.colors.WhiteGrey};
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  width: 100%;
  padding: 12px 14px;
  outline: unset;

  &:focus {
    border-color: ${({ theme }: any) => theme.colors.Secondary};
  }
`;

export default CardDetails;
