import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import DropdownPostCode from './DropdownPostCode';
import DropdownManually from './DropdownManually';
import { Input, Label, Caption } from '../../../../../../../../assets/elements';
import { SelectInput } from '../../../../../../Inputs';
import { VATStatusOptions } from '../../../../../../../utils/variables';
import MobileNumber from '../../../../../../../../assets/Combos/MobileNumber';
import Required from '../../../../../../../../assets/elements/Required';

const Collection = (props: any) => {
  const [inputManually, setInputManually] = React.useState<any>(true);

  const handleTypeChange = () => {
    setInputManually(!inputManually);
  };

  const { postcode } = useSelector<any, any>(
    (state) => state.main.parcel.deliveryLocation
  );

  const { vat } = useSelector<any, any>((state: any) => state.order.item);

  useEffect(() => {
    if (postcode.length) {
      setInputManually(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value: any, key: any) => {
    props.setLocalState({
      ...props.localState,
      [key]: value,
    });

    if (key === 'name' && value.length > 30) {
      props.setErrors({
        ...props.errors,
        [key]: 'This must be less than 30 characters',
      });
    } else {
      props.setErrors({
        ...props.errors,
        [key]: false,
      });
    }
  };

  const handleCustomsChange = (value: any, key: any) => {
    props.setErrors({
      ...props.errors,
      customsData: {
        ...props.errors.customsData,
        [key]: false,
      },
    });

    props.setLocalState({
      ...props.localState,
      customsData: {
        ...props.localState.customsData,
        [key]: value,
      }
    });
  };

  return (
    <>
      <H2>Return details</H2>
      <SubTitle>
        A copy of your address is needed in case the parcel cannot be delivered
      </SubTitle>
      <LabelStyled><Required />First name and last name</LabelStyled>
      <Input
        placeholder="Name or company name"
        value={props.localState.name}
        onChange={(e: any) => handleChange(e.target.value, 'name')}
        theme={undefined}
        type="text"
        error={props.errors.name}
      />
      <ErrorText>{props.errors.name}</ErrorText>
      <LabelStyled><Required />Mobile number required to receive free SMS notifications</LabelStyled>
      <MobileNumber
        country={props.localState.country}
        value={props.localState.phone}
        onChange={(selected: any) => handleChange(selected, 'phone')}
        error={props.errors.phone}
      />

      {
        vat.customs && (
          <>
            <LabelStyled><Required />VAT status</LabelStyled>
            <SelectInput
              error={props.errors.customsData.VATStatus}
              resetError={() => {}}
              value={props.localState.customsData.VATStatus}
              onChange={(selected: any) => handleCustomsChange(selected, 'VATStatus')}
              data={VATStatusOptions}
              height="48px"
            />
            <ErrorText>{props.errors.customsData.VATStatus}</ErrorText>

            {
              props.localState.customsData.VATStatus === 'company_vat' && (
                <>
                  <LabelStyled><Required />VAT Number</LabelStyled>
                  <Input
                    placeholder="VAT Number"
                    value={props.localState.customsData.VATNumber}
                    onChange={(e: any) => handleCustomsChange(e.target.value, 'VATNumber')}
                    theme={undefined}
                    type="text"
                    error={props.errors.customsData.VATNumber}
                  />
                  <ErrorText>{props.errors.customsData.VATNumber}</ErrorText>

                  <LabelStyled><Required />EORI Number</LabelStyled>
                  <Input
                    placeholder="EORI Number"
                    value={props.localState.customsData.EORINumber}
                    onChange={(e: any) => handleCustomsChange(e.target.value, 'EORINumber')}
                    theme={undefined}
                    type="text"
                    error={props.errors.customsData.EORINumber}
                  />
                  <ErrorText>{props.errors.customsData.EORINumber}</ErrorText>
                </>
              )
            }
          </>
        )
      }

      {!inputManually ? (
        <DropdownPostCode {...props} setManually={setInputManually} />
      ) : (
        <DropdownManually {...props} />
      )}
      <ActionText onClick={() => handleTypeChange()}>
        {!inputManually ? 'Enter address manually' : 'Enter postcode'}
      </ActionText>
    </>
  );
};
const H2 = styled.h2`
  font-size: 32px;
  line-height: 135%;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const SubTitle = styled.p<any>`
  margin: 20px 0;
  line-height: 150%;
`;
const ActionText = styled.p`
  margin-top: 50px;
  cursor: pointer;
  color: ${({ theme }: any) => theme.colors.Secondary};
`;
const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: normal;
  margin-top: 30px;
  color: #777e7e;
  width: 100%;
`;
const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: block;
  margin-top: 4px;
`;

export default Collection;
