import React from 'react';
import styled from 'styled-components';
import device from '../../../../../utils/device';

const allSelected = ['invoice', 'credit', 'topup'];

const Filters = ({ onChange, selected }: any) => {
  return (
    <Wrapper>
      <FilterItem
        selected={allSelected.every((i: any) => selected.includes(i))}
        onClick={() => onChange(['invoice', 'credit', 'topup'])}
      >
        All
      </FilterItem>
      <FilterItem
        selected={selected.length === 1 && selected.includes('invoice')}
        onClick={() => onChange(['invoice'])}
      >
        Invoices
      </FilterItem>
      <FilterItem
        selected={selected.length === 1 && selected.includes('credit')}
        onClick={() => onChange(['credit'])}
      >
        Credit Notes
      </FilterItem>
      <FilterItem
        selected={selected.length === 1 && selected.includes('topup')}
        onClick={() => onChange(['topup'])}
      >
        Top Up
      </FilterItem>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 28px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
`;

const FilterItem = styled.div.attrs(({ selected }: any) => ({
  selected,
}))`
  background: ${({ theme, selected }: any) =>
    selected ? theme.colors.Black : theme.colors.White};
  border-radius: 60px;
  padding: 8px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${({ theme, selected }: any) =>
    selected ? theme.colors.White : theme.colors.Black};
  margin-right: 16px;
  margin-top: 8px;
  cursor: ${({ selected }: any) =>
    selected ? 'default' : 'pointer'};

  &:last-child {
    margin-right: 0;
  }

  @media ${device.laptop} {
    font-size: 16px;
    margin-top: 0;
  }
`;

export default Filters;
