import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getP4DTracking } from '../../utils/APICalls/P4dTracking';

import './P4DTracking.css';
import { Container, Error } from '../../../assets/elements';
import Loader from '../Loader';

const P4DTracking = ({ orderNumber }: any) => {
  const [trackingHtml, setTrackingHtml] = useState<any>('');
  const [error, setError] = useState<any>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getP4DTracking(orderNumber)
      .then((res: any) => {
        setTrackingHtml(res.data);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Wrapper>
      {
        error && (
          <Error>Please try again later</Error>
        )
      }
      {
        !error && (loading ? (
          <Loader>Loading</Loader>
        ) : (
          <Container className="tracking-container" dangerouslySetInnerHTML={{ __html:  trackingHtml }} />
        ))
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin-top: 5px;
`;

export default P4DTracking;
