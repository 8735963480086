import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import {
  Button,
  Column,
  Container,
  H6,
  Input,
  Row,
} from '../../../assets/elements';
import device from '../../utils/device';
import { trackingPrefix } from '../../utils/variables';

const TrackingSection = (props: any) => {
  const history = useHistory();
  const [trackingNumber, setTrackingNumber] = useState('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTrackingNumber(e.currentTarget.value);
  };

  const handleSubmit = () => {
    if (trackingNumber) {
      history.push(`track?order-number=${trackingNumber}`);
    }
  };

  return (
    <TrackingContainer>
      <Container>
        <RowStyled>
          <ColumnHeading sizeL={7} sizeS={3.5}>
            <H6Styled>Enter your order number to track delivery</H6Styled>
          </ColumnHeading>
          <ColumnStyled sizeL={5} sizeS={2.5}>
            <Row>
              <ColumnStyled sizeL={7} sizeS={3.5} sizeXS={2.5}>
                <InputStyled
                  theme={props.theme}
                  value={trackingNumber}
                  type="text"
                  placeholder={`e.g. ${trackingPrefix}-ABCDEF000001`}
                  onChange={handleChange}
                  error={false}
                />
              </ColumnStyled>
              <ColumnStyled sizeL={5} sizeS={2.5} sizeXS={1.5}>
                <ButtonStyled onClick={handleSubmit} color="white">
                  Track order
                </ButtonStyled>
              </ColumnStyled>
            </Row>
          </ColumnStyled>
        </RowStyled>
      </Container>
    </TrackingContainer>
  );
};

const TrackingContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }: any) => theme.colors.Primary};
  color: ${({ theme }: any) => theme.colors.White};
`;

const RowStyled = styled(Row)`
  align-items: center;
  padding: 42px 15px;

  @media ${device.laptop} {
    padding: 0;
  }
`;

const ColumnHeading = styled(Column)`
  padding: 0;
  margin-bottom: 24px;

  @media ${device.laptop} {
    padding: 48px 0;
    margin-bottom: 0;
  }
`;

const ColumnStyled = styled(Column)`
  padding: 0;
`;

const H6Styled = styled(H6)`
  color: ${({ theme }: any) => theme.colors.White};
`;

const InputStyled = styled(Input)`
  padding: 12px 16px;
  background: ${({ theme }: any) => theme.colors.White};
  opacity: 0.1;
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: unset;

  &::placeholder {
    color: ${({ theme }: any) => theme.colors.White};
    opacity: 0.7;
  }
`;

const ButtonStyled = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  padding: 12px 16px;

  @media ${device.desktop} {
    padding: 12px 22px;
  }
`;

export default TrackingSection;
