import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../../assets/elements';
import EmailIcon from '../../../../../assets/icons/email.svg';
import EmailModal from './EmailModal';

const EmailQuote = ({ packageType, filters = null, open, setOpen }: any) => {

  return (
    <>
      <Wrapper>
        <ButtonStyled color="primary" onClick={() => setOpen(true)}>
          <Icon src={EmailIcon} />
          Email quote
        </ButtonStyled>
      </Wrapper>
      <EmailModal open={open} setOpen={setOpen} packageType={packageType} filters={filters} />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 24px;
`;

const ButtonStyled = styled(Button)`
  padding: 10px 14px;
`;

const Icon = styled.img`
  margin-right: 10px;
`;

export default EmailQuote;
