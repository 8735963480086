import React from 'react';
import styled from 'styled-components';
import device from '../../../../../utils/device';

const Cell20 = ({ children }: any) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  display: none;
  width: 20%;
  padding: 16px 0;

  @media ${device.laptop} {
    display: flex;
    width: 20%;
  }

  @media ${device.laptopL} {
    width: 19%;
  }
`;

export default Cell20;
