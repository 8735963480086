import React from 'react';
import styled from 'styled-components';

const Switch = ({ value, onChange }: any) => {
  return (
    <Wrapper>
      <SwitchBox selected={value === false} onClick={() => onChange(false)}>No</SwitchBox>
      <SwitchBox selected={value === true} onClick={() => onChange(true)}>Yes</SwitchBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const SwitchBox = styled.a.attrs(({ selected }: any) => ({
  selected,
}))`
  display: block;
  width: 55px;
  padding: 16px 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: ${({ theme, selected }: any) => selected ? theme.colors.White : theme.colors.Black};
  background-color: ${({ theme, selected }: any) => selected ? theme.colors.Black : theme.colors.WhiteGrey};
  border-radius: 4px;
  text-align: center;
  pointer-events: ${({ selected }: any) => selected ? 'none' : 'all'};
  cursor: pointer;
`;

export default Switch;
