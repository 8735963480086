import React from 'react';
import styled from 'styled-components';
import Loader from '../../../assets/icons/Loader';
import { PSmall } from '../../../assets/elements';

const DatesLoader = () => {
  return (
    <Wrapper>
      <Loader />
      <PSmallStyled>Finding available collection and delivery dates...</PSmallStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 0 40px 0;
  align-items: center;
`;

const PSmallStyled = styled(PSmall)`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin-left: 36px;
`;

export default DatesLoader;