import React from 'react';
import styled from 'styled-components';
import device from '../../../../../../../utils/device';
import { A } from '../../../../../../../../assets/elements/Link';

const DropoffLocation = ({ title, dropoffPoint }: any) => {
  return (
    <ParcelTypeContainer>
      <ParcelTypeHead>{title}</ParcelTypeHead>
      <ParcelTypeData>
        <DropOffMessage>
          Nearest drop off
          {` ${dropoffPoint?.distance?.amount}`}
          {dropoffPoint?.distance?.unit}
        </DropOffMessage>
      </ParcelTypeData>
    </ParcelTypeContainer>
  );
};

const ParcelTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 8px 20px 8px;
  background: ${({ theme }: any) => theme.colors.DropoffBackground};;
  align-items: center;
  border-radius: 4px;
  height: 100%;
  margin-bottom: 16px;
  width: calc(50% - 4px);
  margin-right: 4px;

  @media ${device.laptop} {
    width: 100%;
    margin-right: 0;
  }
`;

const ParcelTypeHead = styled.div`
  background: ${({ theme }: any) => theme.colors.DropoffHead};
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  color: ${({ theme }: any) => theme.colors.White};
  padding: 7px 4px;
  justify-content: center;
`;

const ParcelTypeData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 19px;
  line-height: 150%;
  font-weight: 500;
  margin-top: 13px;
`;

const DropOffMessage = styled(A)`
  text-decoration: underline;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 135%;
  color: ${({ theme }: any) => theme.colors.DropoffHead};
`;

export default DropoffLocation;
