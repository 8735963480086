import React from 'react';

const FreightIcon = ({ color }: any) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 5.82132L14.359 3L22 5.78101M2 5.82132V16.4859L5.84615 18.7429M2 5.82132L5.84615 7.51411M22 5.78101L9.69231 9.2069M22 5.78101V16.7962L9.69231 21M9.69231 9.2069V21M9.69231 9.2069L5.84615 7.51411M9.69231 21L5.84615 18.7429M5.84615 18.7429V7.51411" stroke={color} strokeWidth="2" />
    </svg>

  );
};

export default FreightIcon;
