import React from 'react';
import styled from 'styled-components';
import { H6, PSmall, A, Button } from '../../../assets/elements';
import Modal from '../../../assets/Combos/Modal';
import { supportUrl } from '../../utils/env';

const LoginHelp = ({
  open,
  setOpen,
  goToReset,
}: any) => {
  const goToSupport = () => {
    window.open(`${supportUrl}tickets/new`, '_blank');
  };

  return (
    <Modal
      title="Change of account login"
      TitleElement={H6}
      open={open}
      close={() => setOpen(false)}
      width={6}
    >
      <Text>
        Username is no longer valid for accessing your account.
        Please use your email address to log in.
        <br />
        <br />
        If you’re having trouble signing in,
        please try to <A onClick={goToReset}>reset password</A> {' '}
        or <A onClick={goToSupport}>contact support</A> to retrieve your registered email info.
      </Text>
      <div>
        <ButtonStyled color="black" onClick={() => setOpen(false)}>Okay</ButtonStyled>
      </div>
    </Modal>
  );
};

const Text = styled(PSmall)`
  margin-bottom: 24px;
`;

const ButtonStyled = styled(Button)`
  border-width: 2px;
`;

export default LoginHelp;
