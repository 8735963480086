import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { landingPageUrl } from '../../utils/env';
import { Container } from '../../../assets/elements';
import device from '../../utils/device';
import ShipmentDetails from './components/ShipmentDetails';
import { getDefaultAddress } from '../../utils/APICalls/Account';
import {
  setCollectionAddress as setCollectionAddressAction,
  setDeliveryAddress as setDeliveryAddressAction,
  setCollectionDetails as setCollectionDetailsAction,
  setRecipientInfo as setDeliveryDetailsAction, setParcelQuotesAction,
} from '../../store/actions';
import CollectionDetails from './components/CollectionDetails';
import { Accordion, SingleAccordion } from '../../../assets/Combos/Accordion';
import DeliveryDetails from './components/DeliveryDetails';
import QuickBookHeader from './components/Elements/QuickBookHeader';
import DeliveryType from './components/Elements/DeliveryType';
import Service from './components/Service';
import NoDefaultAddress from './components/Elements/NoDefaultAddress';
import { checkCustomsRequirement } from '../../utils/APICalls/Order';

const blankAddress = {
  company: '',
  addressOne: '',
  addressTwo: '',
  addressThree: '',
  city: '',
  country: '',
  zip: '',
};

const blankDetails = {
  name: '',
  email: '',
  phone: '',
};

const QuickBook = () => {
  const dispatch = useDispatch();
  const session = useSelector((state: any) => state.user.session);

  if (session.accessToken === '') {
    window.location.href = landingPageUrl;
  }

  const [address, setAddress] = useState<any>({});
  const [details, setDetails] = useState<any>({});
  const [defaultAddressError, setDefaultAddressError] = useState(false);
  const [collectionCountry, setCollectionCountry] = useState('');
  const [currentStep, setCurrentStep] = useState<any>(['collection']);
  const [doneSteps, setDoneSteps] = useState<any>([]);
  const [deliveryType, setDeliveryType] = useState('outbound');
  const [showServices, setShowServices] = useState(false);
  const [customs, setCustoms] = useState(false);

  useEffect(() => {
    setDoneSteps([]);
    getDefaultAddress()
      .then((res: any) => {
        if (res.data.length === 0) {
          setDefaultAddressError(true);
          setDeliveryType('anywhere');
        } else {
          const add = res.data[0] || {};

          setCollectionCountry(add.country);

          setAddress({
            company: add.company,
            addressOne: add.addressLine1,
            addressTwo: add.addressLine2,
            addressThree: add.addressLine3,
            city: add.city,
            country: add.country,
            zip: add.zip,
          });

          setDetails({
            name: `${add.firstName}${add.lastName ? ` ${add.lastName}` : ''}`,
            email: add.email,
            phone: add.phone,
          });
        }
      })
      .catch(() => {
        setDefaultAddressError(true);
        setDeliveryType('anywhere');
      });
  }, []);

  const handleOpen = (value: boolean, type: string) => {
    setCurrentStep(value ? type : '');
  };

  const handleDone = (value: boolean, type: string) => {
    setDoneSteps(value ? [ ...doneSteps, type ] : doneSteps.filter((c: any) => c !== type));
  };

  const handleContinue = (current: any, next: any) => {
    handleDone(true, current);

    let tempSteps = [ ...currentStep ];

    if (!tempSteps.includes(next)) {
      tempSteps = [ ...tempSteps, next ];
    }

    setCurrentStep(tempSteps.filter((s: any) => s !== current));
  };

  useEffect(() => {
    if (address?.country === undefined || details?.phone === undefined) {
      return;
    }

    let collectionAddress;
    let collectionDetails;
    let deliveryAddress;
    let deliveryDetails;

    if (deliveryType === 'outbound') {
      collectionAddress = { ...address };
      collectionDetails = { ...details };
      deliveryAddress = { ...blankAddress };
      deliveryDetails = { ...blankDetails };
    } else if (deliveryType === 'inbound') {
      collectionAddress = { ...blankAddress };
      collectionDetails = { ...blankDetails };
      deliveryAddress = { ...address };
      deliveryDetails = { ...details };
    } else {
      collectionAddress = { ...blankAddress };
      collectionDetails = { ...blankDetails };
      deliveryAddress = { ...blankAddress };
      deliveryDetails = { ...blankDetails };
    }

    dispatch(setCollectionAddressAction(collectionAddress));
    dispatch(setCollectionDetailsAction(collectionDetails));
    dispatch(setDeliveryAddressAction(deliveryAddress));
    dispatch(setDeliveryDetailsAction(deliveryDetails));
  }, [deliveryType, address, details]);

  const checkCustoms = (collCountry: any, deliveryCountry: any) => {
    checkCustomsRequirement(collCountry, deliveryCountry)
      .then((res: any) => {
        setCustoms(res.data || false);
      })
      .catch(() => {});
  };

  const handleShowServices = (value: boolean) => {
    setShowServices(value);
    if (value) {
      setCurrentStep([]);
    }
  };

  const resetServices = () => {
    if (showServices) {
      setShowServices(false);
      dispatch(setParcelQuotesAction([]));
    }
  };

  return (
    <Wrapper>
      <QuickBookHeader />
      <DeliveryType selected={deliveryType} setSelected={setDeliveryType} />
      <ContainerStyled>
        {
          defaultAddressError && <NoDefaultAddress />
        }
        <Accordion>
          <SingleAccordion
            head="Step 1 of 3: Collection details"
            body={
              <CollectionDetails
                setDone={(val: boolean) => handleDone(val, 'collection')}
                country={collectionCountry}
                deliveryType={deliveryType}
                address={address}
                handleContinue={() => handleContinue('collection', 'delivery')}
                resetServices={resetServices}
              />
            }
            open={currentStep.includes('collection')}
            done={doneSteps.includes('collection')}
            setOpen={(val: boolean) => handleOpen(val, 'collection')}
          />
          <SingleAccordion
            head="Step 2 of 3: Delivery details"
            body={
              <DeliveryDetails
                setDone={(val: boolean) => handleDone(val, 'delivery')}
                deliveryType={deliveryType}
                done={doneSteps.includes('delivery')}
                customs={customs}
                checkCustoms={checkCustoms}
                address={address}
                handleContinue={() => handleContinue('delivery', 'shipment')}
                resetServices={resetServices}
              />
            }
            open={currentStep.includes('delivery')}
            done={doneSteps.includes('delivery')}
            setOpen={(val: boolean) => handleOpen(val, 'delivery')}
          />
          <SingleAccordion
            head="Step 3 of 3: Shipment details"
            body={
              <ShipmentDetails
                setDone={(val: boolean) => handleDone(val, 'shipment')}
                allDone={
                  doneSteps.includes('collection') &&
                  doneSteps.includes('delivery') &&
                  doneSteps.includes('shipment')
                }
                open={currentStep.includes('shipment')}
                showServices={handleShowServices}
                customs={customs}
                resetServices={resetServices}
              />
            }
            open={currentStep.includes('shipment')}
            done={doneSteps.includes('shipment')}
            setOpen={(val: boolean) => handleOpen(val, 'shipment')}
          />
        </Accordion>
        {
          showServices && <Service />
        }
      </ContainerStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 76px);
  background-color: #e5e5e5;
  flex-direction: column;
`;

const ContainerStyled = styled(Container)`
  padding: 24px 0;

  @media ${device.laptop} {
    padding: 32px 0;
  }
`;

export default QuickBook;
