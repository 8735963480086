import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Button, H6, PSmall, Error as ErrorBox } from '../../../../../assets/elements';
import EmailMyQuoteModal from '../../../../../assets/Combos/EmailMyQuoteModal';
import EmailSentIcon from '../../../../../assets/icons/EmailSent.svg';
import { emailQuote } from '../../../../utils/APICalls/Email';
import device from '../../../../utils/device';
import Loader from '../../../../../assets/icons/Loader';

const EmailModal = ({ open, setOpen, packageType, filters = null }: any) => {
  const [EPError, setEPError] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const inputEl = useRef(null);

  const { collectionLocation, deliveryLocation, packageDetails, dims } = useSelector((state: any) => state.main[packageType]);

  useEffect(() => {
    if (email !== '' && !error) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [error, email]);

  useEffect(() => {
    if (inputEl?.current === null) {
      return;
    }

    if (open) {
      // @ts-ignore
      inputEl.current.focus();
    }
  }, [open, inputEl]);

  const handleChange = (e: any, key: string) => {
    const { value } = e.target;
    setError(false);
    setEPError(false);
    if (key === 'email') setEmail(value);
    if (key === 'name') setName(value);
    if (key === 'phone') setPhone(value);
  };

  const validateEmails = () => {
    let emailError = false;
    const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;

    email.split(',').forEach((value: any) => {
      if (!value.trim().match(regex)) {
        emailError = true;
      }
    });

    setError(emailError);
    return emailError;
  };

  const handleSubmit = () => {
    setEPError(false);

    if (validateEmails()) {
      return;
    }

    setLoading(true);

    let packageD = packageDetails


    if (packageType === 'parcel') {
      packageD = {
        packageDetails
      };
    }
    if (packageType === 'pallet') {
      if (dims.length) {
        packageD = {
          dims,
          packageDetails: [],
          filters,
        };
      } else {
        packageD = Object.values(packageD);
        packageD = {
          packageDetails: packageD.filter((value: any) => value.quantity !== ''),
          dims: [],
          filters,
        };
      }
    } 
    if (packageType === 'freight') {
      packageD = {
        numPallets: packageD.numPallets,
        loadType: packageD.loadType
      };
    }

    const data = {
      recipients: email.split(',').map((value: any) => value.trim()),
      name,
      phone,
      packageType: packageType === 'seaFreight' ? 'seafreight' : packageType,
      ratePayload: {
        collectionLocation,
        deliveryLocation,
        ...packageD,
      }
    };

    emailQuote(data)
      .then((res: any) => {
        if (res.data) {
          setEmailSent(true);
          
        } else {
          setEPError(true);
        }
      }).catch(() => {
        setEPError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetAndClose = () => {
    setLoading(false);
    setEmailSent(false);
    setEPError(false);
    setEmail('');
    setName('');
    setPhone('');
    setOpen(false);
  };

  const handleClose = () => {
    setLoading(false);
    setEmailSent(false);
    setEPError(false);
    setOpen(false);
  };

  return (
    <EmailMyQuoteModal
      title={(!emailSent && !loading) ? 'Email your quote' : ''}
      TitleElement={H6}
      open={open}
      close={handleClose}
      width={6}
      outsideClose={!loading}
    >
      {!emailSent && !loading && (
      <TitleStyled>
        Not ready to book? Email this quote to yourself for future booking
      </TitleStyled>)}
      {
        emailSent && (
          <SentWrapper>
            <SentIcon src={EmailSentIcon} alt="Email sent" />
            <SentText>
              Your quote has been sent. Please check your inbox.
            </SentText>
            <ButtonStyled color="secondary" onClick={resetAndClose}>OK</ButtonStyled>
          </SentWrapper>
        )
      }
      {
        !emailSent && (loading ? (
          <LoadingWrapper>
            <Loader />
            <LoadingText>Sending quote</LoadingText>
          </LoadingWrapper>
        ) : (
          <>
            {
            EPError && <ErrorBox>Some error occured. Please try again later.</ErrorBox>
          }
            <Form>
              <Label>Name</Label>
              <InputStyled
                value={name}
                type="text"
                placeholder="Enter your name"
                onChange={(e: any) =>
                  handleChange(e, 'name')}
              />
              <Label>Email</Label>
              <InputStyled
                value={email}
                type="text"
                placeholder="Enter your email"
                onChange={(e: any) =>
                  handleChange(e, 'email')}
              />
              <Label>Phone number</Label>
              <InputStyled
                value={phone}
                type="text"
                placeholder="Enter phone number"
                onChange={(e: any) =>
                  handleChange(e, 'phone')}
              />
              {
                error && <Error>Please enter a valid email address</Error>
              }
              <ButtonWrapper>
                <ButtonStyled
                  color="secondary"
                  onClick={handleSubmit}
                  disable={disabled}
                  disabled={disabled}
                >
                  Email me this quote
                </ButtonStyled>
              </ButtonWrapper>
            </Form>
          </>
        ))
      }
    </EmailMyQuoteModal>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.mobileL} {
    min-width: 240px;
  }
`;

const LoadingText = styled(PSmall)`
  margin-top: 24px;
  color:  white;
  text-align: center;
  padding: 0 50px 0 50px;
`;

const InputStyled = styled.input`
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  background: rgba(255, 255, 255, 0.1);
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  color: ${({ theme }: any) => theme.colors.White};
  width: ${({ width }: any) => width};
  padding: 12px 14px;
  border-style: none;
  ::placeholder {
    color: white;
    opacity: 0.5;
    font-size: 14px;
  }
  margin: 0 0 30px 0;
`;
const TitleStyled = styled(H6)`
  color: ${({ theme }: any) => theme.colors.White};
  text-align: center;
  max-width: 210px;
  padding: 0 0 0 0;
  @media ${device.laptop} {
    padding: 0 80px 0 80px;
    max-width: unset;
  }
`;

const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Form = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  max-width: 210px;
  padding: 0 0 0 0;
  @media ${device.laptop} {
    padding: 0 80px 0 80px;
    max-width: unset;
  }
`;

const Label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: ${({ theme }: any) => theme.colors.White};
  margin-bottom: 6px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SentIcon = styled.img``;

const SentText = styled(PSmall)`
  margin-bottom: 24px;
  color: white;
`;

const Error = styled(PSmall)`
  color: ${({ theme }: any) => theme.colors.White};
  margin-top: 6px;
`;

export default EmailModal;
