import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Row,
  Input,
  Column,
  Caption,
} from '../../../../../../../../assets/elements';
import device from '../../../../../../../utils/device';
import { SelectInput } from '../../../../../../Inputs';
import Required from '../../../../../../../../assets/elements/Required';
import containerTypes from '../../../../../../../utils/containerTypes.json';

const FreightItem = ({
  theme,
  shipment,
  onChange,
  onNumPalletsChange,
  errors,
  data,
  container
}: any) => {

  const load = containerTypes.includes(shipment.loadType) ? 'containers':'pallets';

  return (
    <Wrapper key={shipment.id}>
      {container ? null : (
        <>
          <CaptionStyled><Required />Number of {load}</CaptionStyled>
          <Row>
            <ColumnDesc sizeL={6}>
              <SelectInput
                data={data}
                error={errors.numPallets || errors.totalQuantity}
                value={load === 'pallets' ? shipment.numPallets : 1}
                height="50px"
                onChange={(value: string) =>
                  onNumPalletsChange(shipment.id, value)}
              />
              {errors[shipment.id]?.numPallets && <ErrorText>Required</ErrorText>}
              {errors[shipment.id]?.totalQuantity && <ErrorText>{errors[shipment.id]?.totalQuantity}</ErrorText>}
            </ColumnDesc>
            <ColumnStyled sizeL={6}>
              <InputContainer>
                <Input
                  theme={theme}
                  value={shipment.weight}
                  type="text"
                  placeholder="Weight"
                  onChange={(e: any) => onChange(shipment.id, 'weight', e.target.value)}
                  error={errors[shipment.id]?.weight || false}
                />
                <InputCaption>kg</InputCaption>
                {errors[shipment.id]?.weight && <ErrorText>Required</ErrorText>}
              </InputContainer>
            </ColumnStyled>
          </Row>
          <CaptionStyled><Required />Shipment details</CaptionStyled>
        </>)}
      <Row>
        <ColumnDesc sizeL={6}>
          <InputContainer>
            <Input
              theme={theme}
              value={shipment.description}
              type="text"
              placeholder="Goods description"
              onChange={(e: any) => onChange(shipment.id, 'description', e.target.value)}
              error={errors[shipment.id]?.description || false}
            />
            {errors[shipment.id]?.description && <ErrorText>{errors[shipment.id]?.description}</ErrorText>}
          </InputContainer>
        </ColumnDesc>
        <ColumnStyled sizeL={6}>
          <InputContainer>
            <Input
              theme={theme}
              value={shipment.value}
              type="text"
              placeholder="Value"
              onChange={(e: any) => onChange(shipment.id, 'value', e.target.value)}
              error={errors[shipment.id]?.value || false}
            />
            <CurrencyCaption>£</CurrencyCaption>
            {errors[shipment.id]?.value && <ErrorText>Required</ErrorText>}
          </InputContainer>
        </ColumnStyled>
      </Row>
      {container ? <CaptionStyled><Required />Maximum container weight is 30480kg</CaptionStyled> : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 24px;
`;

const ColumnStyled = styled(Column)`
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media ${device.laptopL} {
    &:first-child {
      margin-bottom: 0;
    }

    ${({ customs }: any) => {
    if (customs) {
      return `
        &:first-of-type {
          padding-right: 15px;
        }
        &:last-of-type {
          padding-left: 15px;
        }
      `;
    }
    return `
      &:last-of-type {
        padding-left: 15px;
      }
    `;
  }}
  }
`;

const ColumnDesc = styled(Column)`
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  margin-bottom: 16px;

  @media ${device.laptopL} {
    padding-right: ${({ customs }: any) => (customs ? '' : '15px')};
  }
`;

const CaptionStyled = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  margin-bottom: 6px;
  display: inline-block;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const InputCaption = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  border-radius: 0 4px 4px 0;
  background: ${({ theme }: any) => theme.colors.LightGrey};
  color: ${({ theme }: any) => theme.colors.Black};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: none;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;

  @media ${device.laptopL} {
    display: inline-block;
  }
`;

const CurrencyCaption = styled(InputCaption)`
  background: ${({ theme }: any) => theme.colors.Secondary};
  color: ${({ theme }: any) => theme.colors.White};
`;

export default FreightItem;
