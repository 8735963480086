import { staging } from './staging';

/*
* M: Mobile devices
* L: Larger devices (Laptop/Tablet)
*
* FS: Font size
* */

export const pallets4delivery = {
  ...staging,
  /* Colors */
  colors: {
    Primary: '#5FB047',
    Secondary: '#EF6224',
    Black: '#222222',
    DarkGrey: '#616265',
    GrayWhite: '#FAFAFA',
    MidGrey: '#848B9A',
    LightGrey: '#DDDDDD',
    WhiteGrey: '#FAFAFA',
    White: '#FFFFFF',
    Error: '#CA0E16',
    Pastel1: '#F1FFED',
    Pastel2: '#FFEDE2',
    InactiveText: '#888888',

    PrimaryHover: '#58A342',
    SecondaryHover: '#E15011',
    MutedPrimary: '#F1FFED',
    MutedSecondary: '#FFEDE2',

    CollectionBackground: '#EFDEFF',
    CollectionHead: '#AE5BFB',
    DropoffBackground: '#CCEDD0',
    DropoffHead: '#408F49',

    FooterBackground: '#848B9A',
    CopyrightBackground: '#5fb047',

    orderNavigation: {
      circle: '#EF6224',
      line: '#EF6224',
    },
  },
};