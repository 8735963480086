import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { A } from '../../../../../../../assets/elements';
import EditIcon from '../../../../../../../assets/icons/Edit.svg';
import DeleteIcon from '../../../../../../../assets/icons/DeleteAddress.svg';
import PinIcon from '../../../../../../../assets/icons/Pin.svg';
import PinnedIcon from '../../../../../../../assets/icons/Pinned.svg';
import { countriesPostalCodesData } from '../../../../../../mocks/quoteData';

const AddressRow = ({
  address,
  setEditAddress,
  triggerDelete,
  triggerDefault,
  makingDefault,
}: any) => {
  const [country, setCountry] = useState('');

  useEffect(() => {
    countriesPostalCodesData.every(({ name, code }: any) => {
      if (code === address.country) {
        setCountry(name);

        return false;
      }
    });
  }, []);

  const handleEdit = () => {
    setEditAddress({ ...address });
  };

  return (
    <Wrapper>
      <Address>
        {address.addressLine1 ? `${address.addressLine1}, ` : ''}
        {address.addressLine2 ? `${address.addressLine2}, ` : ''}
        {address.addressLine3 ? `${address.addressLine3}, ` : ''}
        {address.city ? `${address.city}, ` : ''}
        {address.zip ? `${address.zip}, ` : ''}
        {country ? `${country}` : `${address.country}`}
      </Address>
      <Buttons>
        <Button
          data-tip
          data-for={`setDefault-${address.id}`}
          onClick={address.isDefault ? () => {} : () => triggerDefault(address)}
          disable={makingDefault}
          disabled={makingDefault}
        >
          <img
            src={address.isDefault ? PinnedIcon : PinIcon}
            alt={address.isDefault ? 'Default' : 'Set as default'}
          />
        </Button>
        <ReactTooltip
          id={`setDefault-${address.id}`}
          effect="solid"
        >
          {
            address.isDefault ? 'Default collection address' :
              'Set as default collection address'
          }
        </ReactTooltip>

        <Button
          data-tip
          data-for={`edit-${address.id}`}
          onClick={() => handleEdit()}
        >
          <img src={EditIcon} alt="Edit" />
        </Button>
        <ReactTooltip
          id={`edit-${address.id}`}
          effect="solid"
        >
          Edit
        </ReactTooltip>

        <Button
          data-tip
          data-for={`delete-${address.id}`}
          onClick={() => triggerDelete(address.id)}
        >
          <img src={DeleteIcon} alt="Delete" />
        </Button>
        <ReactTooltip
          id={`delete-${address.id}`}
          effect="solid"
        >
          Delete
        </ReactTooltip>
      </Buttons>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 0 24px;
  justify-content: space-between;
  background: ${({ theme }: any) => theme.colors.White};
  border-radius: 4px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Address = styled.div`
  padding: 18px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding-right: 16px;
`;

const Buttons = styled.div`
  display: flex;
`;

const Button = styled(A).attrs(({ disable }: any) => ({
  disable
}))`
  margin-right: 18px;
  pointer-events: ${({ disable }: any) => disable ? 'none' : 'all'};
  opacity: ${({ disable }: any) => disable ? '0.5' : '1'};

  &:last-child {
    margin-right: 0;
  }
`;

export default AddressRow;
