import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import OrderForm from './components/OrderForm/OrderForm';
import { Container } from '../../../../../assets/elements';
import device from '../../../../utils/device';
import { StripeAPIKey } from '../../../../utils/stripe';
import Navigation from './components/Navigation';
import { setCurrentOrderStep } from '../../../../store/actions';

const stripePromise = loadStripe(StripeAPIKey);

const Order = (props: any) => {
  const dispatch = useDispatch();
  const currentStep = useSelector((state: any) => state?.order?.currentStep || 1);
  const { pathname }: any = props.location;

  useEffect(() => {
    if (pathname.includes('order-step1')) {
      dispatch(setCurrentOrderStep(1));
    } else if (pathname.includes('order-step2')) {
      dispatch(setCurrentOrderStep(2));
    } else if (pathname.includes('order-step3')) {
      dispatch(setCurrentOrderStep(3));
    } else if (pathname.includes('order-basket')) {
      dispatch(setCurrentOrderStep(4));
    } else if (pathname.includes('payment')) {
      dispatch(setCurrentOrderStep(5));
    }
  }, [dispatch, pathname]);

  return (
    <Wrapper>
      <Navigation
        steps={5}
        current={currentStep}
      />
      <OrderWrapper>
        <Elements stripe={stripePromise}>
          <OrderForm />
        </Elements>
      </OrderWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background: ${({ theme }: any) => theme.colors.GrayWhite};
`;

const OrderWrapper = styled(Container)`
  // padding: 48px 0;
  //
  // @media ${device.laptop} {
  //   padding: 90px 0;
  // }
  position: relative;
`;

export default withRouter(Order);
