import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ManualAddress from '../../../../../ManualAddress/ManualAddress';
import PostcodeAddress from '../../../../../PostcodeAddress/PostcodeAddress';
import { A, Button } from '../../../../../../../assets/elements';
import { CheckBoxInput } from '../../../../../Inputs';
import { addNewAddress, updateAddress } from '../../../../../../utils/APICalls/Account';

const tempAddress = {
  company: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  zip: '',
  city: '',
  country: 'GB',
  isDefault: false,
  id: '',
};

const postcoderAddressKeys = {
  addressOne: 'addressLine1',
  addressTwo: 'addressLine2',
  addressThree: 'addressLine3',
};

const AddAddress = ({
  close,
  full = false,
  id = false,
  editAddress = tempAddress,
}: any) => {
  const [manual, setManual] = useState(false);
  const [address, setAddress] = useState(tempAddress);

  const [addressErrors, setAddressErrors] = useState<any>({
    company: false,
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    addressLine1: false,
    addressLine2: false,
    zip: false,
    city: false,
    country: false,
    isDefault: false,
  });

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setManual(full);

    if (id) {
      setAddress({
        ...editAddress,
      });
    }

    return () => {
      setManual(false);
      setAddress(tempAddress);

      setAddressErrors({
        company: false,
        firstName: false,
        lastName: false,
        phone: false,
        email: false,
        addressLine1: false,
        addressLine2: false,
        zip: false,
        city: false,
        country: false,
        isDefault: false,
      });

      setButtonDisabled(true);
      setProcessing(false);
    };
  }, [editAddress, full, id]);

  useEffect(() => {
    if (
      address.company.length > 30 ||
      address.addressLine1 === '' ||
      address.addressLine1.length > 30 ||
      address.addressLine2.length > 30 ||
      address.city === '' ||
      address.city.length > 30 ||
      address.country === '' ||
      address.zip === '' ||
      address.phone === ''
    ) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [address]);

  const handleCheckboxChange = () => {
    setAddress({
      ...address,
      isDefault: !address.isDefault,
    });
  };

  const validateAddress = () => {
    const errors = { ...addressErrors };
    let error = false;

    if (address.company.length > 30) {
      errors.company = 'This must be less than 30 characters';
      error = true;
    }

    if (address.addressLine1 === '') {
      errors.addressLine1 = 'Required';
      error = true;
    } else if (address.addressLine1.length > 30) {
      errors.addressLine1 = 'This must be less than 30 characters';
      error = true;
    }

    if (address.addressLine2.length > 30) {
      errors.addressLine2 = 'This must be less than 30 characters';
      error = true;
    }

    if (address.city === '') {
      errors.city = 'Required';
      error = true;
    }

    if (address.country === '') {
      errors.country = 'Required';
      error = true;
    }

    if (address.zip === '') {
      errors.zip = 'Required';
      error = true;
    }

    if (address.phone === '') {
      errors.phone = 'Required';
      error = true;
    } else if (!address.phone.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)) {
      errors.phone = 'Please enter a valid phone number';
      error = true;
    } else if (address.phone.replace(' ', '').length < 11) {
      errors.phone = `Phone number must be at least ${10 - (address.phone?.split(' ')[0].length - 1)} characters`;
      error = true;
    } else if (address.phone.replace(' ', '').length > 14) {
      errors.phone = `Phone number must be less than or equal to ${13 - (address.phone?.split(' ')[0].length - 1)} characters`;
      error = true;
    }

    setAddressErrors({ ...errors });

    return error;
  };

  const handleSubmit = () => {
    const error = validateAddress();

    if (error) {
      return;
    }

    setProcessing(true);

    if (id) {
      updateAddress(address, id)
        .then(() => {
          setProcessing(false);
          close();
        })
        .catch(() => {
          setProcessing(false);
        });
    } else {
      const tempAdd = { ...address };
      delete tempAdd.id;

      addNewAddress(tempAdd)
        .then(() => {
          setProcessing(false);
          close();
        })
        .catch(() => {
          setProcessing(false);
        });
    }
  };

  return (
    <Wrapper>
      {
        manual ? (
          <ManualAddress
            address={address}
            setAddress={setAddress}
            errors={addressErrors}
            setErrors={setAddressErrors}
            phone={true}
          />
        ) : (
          <PostcodeAddress
            address={address}
            setAddress={setAddress}
            errors={addressErrors}
            setErrors={setAddressErrors}
            phone={true}
            addressKeys={postcoderAddressKeys}
            setManual={setManual}
          />
        )
      }

      <DefaultContainer>
        <CheckBoxInput
          checked={address.isDefault}
          label="Set as default collection address"
          onChange={handleCheckboxChange}
        />
      </DefaultContainer>

      <AStyled onClick={() => setManual(!manual)}>
        {
          manual ? 'Search by postcode' : 'Enter address manually'
        }
      </AStyled>

      <Button
        color="secondary"
        block={true}
        disabled={buttonDisabled || processing}
        disable={buttonDisabled || processing}
        onClick={() => handleSubmit()}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          processing ? 'Saving...' :
            id ? 'Save address' : 'Add address'
        }
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const DefaultContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 32px;
`;

const AStyled = styled(A)`
  margin-bottom: 32px;
`;

export default AddAddress;
