import React, { useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import {
  Row,
  Column,
  Input,
  A, PSmall,
} from '../../../../../../../../assets/elements';
import AddIcon from '../../../../../../../../assets/icons/AddIcon';
import RemoveIcon from '../../../../../../../../assets/icons/RemoveIcon';
import device from '../../../../../../../utils/device';
import { SelectInput } from '../../../../../../Inputs';

const LCLItems = ({
  items,
  errors,
  theme,
  removeItem,
  addNewItem,
  handleChange,
  show,
  setDisabled,
  subType,
}: any) => {
  const [values, setValues] = useState<any>([{ label: 1, value: 1 }]);
  const [valErrors, setValErrors] = useState<any>({
    volume: false,
    weight: false,
  });

  const [totalValErrors, setTotalValErrors] = useState<any>({
    volume: false,
    weight: false,
  });

  const [totals, setTotals] = useState({
    quantity: 1,
    volume: 0,
    weight: 0,
  });

  useEffect(() => {
    let quantity = 0;
    let volume = 0;
    let weight = 0;
    const tempErrors: any = {};

    items.forEach((item: any) => {
      volume += ((Number(item.length.amount) * Number(item.width.amount) * Number(item.height.amount)) / 1000000) *
        Number(item.quantity.amount);
      quantity += Number(item.quantity.amount);
      weight += Number(item.weight.amount) * Number(item.quantity.amount);

      tempErrors[item.id] = {
        volume: Number(((Number(item.length.amount) * Number(item.width.amount) * Number(item.height.amount)) / 1000000)
          .toFixed(1)) > 13.5,
        weight: Number(item.weight.amount) > 3000
      };
    });

    setValErrors({
      ...tempErrors,
    });

    setTotals({
      quantity,
      volume,
      weight,
    });
  }, [items]);

  useEffect(() => {
    let dis = false;
    Object.keys(valErrors).some((id: any) => {
      if (valErrors[id].weight || valErrors[id].volume) {
        dis = true;
        setDisabled(true);
        return true;
      }

      return false;
    });

    if (totalValErrors.weight || totalValErrors.volume) {
      dis = true;
      setDisabled(true);
    }

    if (!dis) {
      setDisabled(false);
    }
  }, [valErrors, totalValErrors]);

  useEffect(() => {
    const tempErrors = {
      ...totalValErrors,
    };

    if (totals.weight > 3629) {
      tempErrors.weight = 'The weight of this shipment exceeds the maximum weight of 3629kg';
    } else {
      tempErrors.weight = false;
    }

    if (totals.volume > 14.15) {
      tempErrors.volume = 'The volume of this shipment exceeds the maximum volume of 14.15cbm';
    } else {
      tempErrors.volume = false;
    }

    setTotalValErrors(tempErrors);
  }, [totals]);

  useEffect(() => {
    const temp = [];

    for (let i = 25; i >= 1; i--) {
      temp.push({
        label: `${i}`,
        value: i,
      });
    }

    setValues(temp);
  }, []);

  return (
    <Wrapper show={show}>
      {
        items.map((item: any, index: any) => (
          <WrapperItems key={index}>
            <RowStyled>
              <ColumnPad sizeL={6} sizeM={6} sizeS={6}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <LabelStyled>Dimensions</LabelStyled>
                <Row>
                  <ColumnStyled sizeL={3.33} sizeM={3} sizeS={1.5} sizeXS={1}>
                    <Input
                      placeholder="Length"
                      value={item.length.amount}
                      onChange={(e: any) => handleChange(e.target.value, item.id, 'length')}
                      theme={undefined}
                      type="text"
                      error={!!errors[item.id].length}
                    />
                    <ErrorLine>{errors[item.id].length}</ErrorLine>
                  </ColumnStyled>
                  <ColumnStyledMargin sizeL={3.33} sizeM={3} sizeS={1.5} sizeXS={1}>
                    <Input
                      placeholder="Width"
                      value={item.width.amount}
                      onChange={(e: any) => handleChange(e.target.value, item.id, 'width')}
                      theme={undefined}
                      type="text"
                      error={!!errors[item.id].width}
                    />
                    <ErrorLine>{errors[item.id].width}</ErrorLine>
                  </ColumnStyledMargin>
                  <ColumnStyledMargin sizeL={5.33} sizeM={6} sizeS={3} sizeXS={2}>
                    <Input
                      placeholder="Height"
                      value={item.height.amount}
                      onChange={(e: any) => handleChange(e.target.value, item.id, 'height')}
                      theme={undefined}
                      type="text"
                      error={!!errors[item.id].height}
                    />
                    <InputUnitsLabel>cm</InputUnitsLabel>
                    <ErrorLine>{errors[item.id].height}</ErrorLine>
                  </ColumnStyledMargin>
                </Row>
              </ColumnPad>
              <ColumnWeightWrap sizeL={3} sizeM={3} sizeS={6}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <LabelStyled>Weight</LabelStyled>
                <Row>
                  <ColumnWeight sizeL={12} sizeM={12}>
                    <Input
                      placeholder="Weight"
                      value={item.weight.amount}
                      onChange={(e: any) => handleChange(e.target.value, item.id, 'weight')}
                      theme={undefined}
                      type="text"
                      error={!!errors[item.id].weight}
                    />
                    <InputUnitsLabel>kg</InputUnitsLabel>
                  </ColumnWeight>
                  <ErrorLine>{errors[item.id].weight}</ErrorLine>
                </Row>
              </ColumnWeightWrap>
              <ColumnPad sizeL={2} sizeM={2} sizeS={4} sizeXS={3}>
                <div>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <LabelStyled>Quantity</LabelStyled>
                  <div>
                    <SelectInput
                      title="Quantity"
                      data={values}
                      error={errors[item.id].quantity}
                      value={Number(item.quantity.amount)}
                      onChange={(value: string) => handleChange(value, item.id, 'quantity')}
                      width="100%"
                      minWidth="unset"
                      height="50px"
                      radius="4px"
                    />
                  </div>
                  <ErrorLine>{errors[item.id].quantity}</ErrorLine>
                </div>
              </ColumnPad>
              <ColumnPad sizeL={1} sizeM={1} sizeS={2} sizeXS={1}>
                <ColumnIcon style={{ padding: 0 }} sizeL={12} sizeM={12} sizeS={2} sizeXS={4}>
                  {items.length > 1 && (
                    <IconContainer>
                      {/* eslint-disable-next-line react/button-has-type */}
                      <A data-tip="" data-for="remove-icon" onClick={() => removeItem(item.id)}>
                        <RemoveIcon width={20} height={20} />
                      </A>
                      <ReactTooltip id="remove-icon" effect="solid">
                        Remove item
                      </ReactTooltip>
                    </IconContainer>
                  )}
                </ColumnIcon>
              </ColumnPad>
            </RowStyled>
            {
              valErrors[item.id] !== undefined && (valErrors[item.id].weight || valErrors[item.id].volume) && (
                <ErrorBox>
                  {
                    valErrors[item.id].volume &&
                    <ErrorLine>The volume of these items exceeds the maximum volume of 13.5cbm per piece</ErrorLine>
                  }
                  {
                    valErrors[item.id].weight &&
                    <ErrorLine>The weight of these items exceeds the maximum weight of 3000kg per piece</ErrorLine>
                  }
                </ErrorBox>
              )
            }
          </WrapperItems>
        ))
      }
      <AddAnother onClick={addNewItem}>
        <AddIcon width={16} height={16} color={theme.colors.Primary} />
        <AddAnotherText>Add another package</AddAnotherText>
      </AddAnother>

      <TotalsContainer>
        TOTAL: { totals.quantity } { subType.toUpperCase() }(S),
        { totals.volume.toFixed(4) } CBM, { totals.weight.toFixed(3) } KGS
      </TotalsContainer>

      {
        (totalValErrors.weight || totalValErrors.volume) && (
          <TotalErrorBox>
            {
              totalValErrors.weight && (
                <ErrorLine>{ totalValErrors.weight }</ErrorLine>
              )
            }
            {
              totalValErrors.volume && (
                <ErrorLine>{ totalValErrors.volume }</ErrorLine>
              )
            }
          </TotalErrorBox>
        )
      }
    </Wrapper>
  );
};

const WrapperItems = styled.div`
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const RowStyled = styled(Row)`
  
`;

const LabelStyled = styled.label`
  margin-bottom: 8px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  display: inline-block;
`;

const Wrapper = styled.div.attrs(({ show }: any) => ({
  show,
}))`
  display: ${({ show }: any) => show ? 'block' : 'none'};
  margin-top: 16px;
`;

const ColumnStyled = styled(Column)`
  padding: 0;
  position: relative;

  & > input {
    border-radius: unset;
  }

  &:first-child > input {
    border-top-left-radius: ${({ theme }: any) =>
    theme.box.BorderRadiusSmall};
    border-bottom-left-radius: ${({ theme }: any) =>
    theme.box.BorderRadiusSmall};
  }

  &:last-child > input {
    border-top-right-radius: ${({ theme }: any) =>
    theme.box.BorderRadiusSmall};
    border-bottom-right-radius: ${({ theme }: any) =>
    theme.box.BorderRadiusSmall};
  }
`;

const ColumnStyledMargin = styled(ColumnStyled)`
  margin-left: -4px;
`;

const ColumnWeight = styled(ColumnStyled)`
  & > input {
    border-radius: 4px !important;
  }
`;

const ColumnPad = styled(Column)`
  margin-bottom: 0;
  padding: 0;
  text-align: left;

  @media ${device.laptopL} {
    padding: 0 15px;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
`;

const ColumnWeightWrap = styled(Column)`
  margin-bottom: 0;
  padding: 0;
  text-align: left;
`;

const ColumnIcon = styled(Column)`
  justify-content: flex-start;
  display: flex;
  padding: 0;
  align-items: center;
  height: 100%;
`;

const AddAnother = styled(A)`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const AddAnotherText = styled.span`
  margin-left: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  color: ${({ theme }: any) => theme.colors.Primary};
`;

const TotalsContainer = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-top: 24px;
`;

const ErrorBox = styled.div`
  padding: 8px 12px;
  border-radius: 4px;
  background: ${({ theme }: any) => theme.colors.ErrorBackground};
  color: ${({ theme }: any) => theme.colors.Error};
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 8px;
`;

const ErrorLine = styled.span`
  display: block;
`;

export const InputUnitsLabel = styled(PSmall)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({ theme }: any) => theme.colors.Black};
  background-color: #DEDEDE;
  border-radius: ${({ theme }: any) =>
    `0 ${theme.box.BorderRadiusSmall} ${theme.box.BorderRadiusSmall} 0`};

  position: absolute;
  top: 2px;
  right: 2px;
  width: 40%;
  bottom: 2px;
  height: calc(100% - 4px);
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 0 0 50%;
  max-width: 50%;
`;

const TotalErrorBox = styled(ErrorBox)`
  margin-top: 16px;
  margin-bottom: 0;
`;

export default withTheme(LCLItems);
