import styled from 'styled-components';
import device from '../../../app/utils/device';

export const Row = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  ${device.desktop} {
    max-width: 1140px;
  }
`;
