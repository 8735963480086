import React from 'react';
import styled from 'styled-components';

const LoadContainer = ({ selected, children, onClick }: any) => {
  return (
    <Wrapper
      selected={selected}
      onClick={onClick}
    >
      { children }
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs(({ selected, onClick }: any) => ({
  selected,
  onClick,
}))`
  padding: 12px 17px 12px 48px;
  border: 2px solid;
  border-color: ${({ theme, selected }: any) => selected ? theme.colors.Secondary : theme.colors.WhiteGrey};
  position: relative;
  border-radius:  ${({ theme }: any) => theme.box.BorderRadiusSmall};
  display: flex;
  align-items: center;
  cursor: pointer;

  :before {
    content: ' ';
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: ${({ theme }: any) => theme.colors.White};
    border: 2px solid;
    border-color: ${({ theme, selected }: any) => selected ? theme.colors.Secondary : theme.colors.WhiteGrey};
    position: absolute;
    left: 17px;
  }

  :after {
    content: ' ';
    display: ${({ selected }: any) => selected ? 'block' : 'none'};
    width: 12px;
    height: 12px;
    left: 22px;
    background: ${({ theme }: any) => theme.colors.Secondary};
    border-radius: 50%;
    position: absolute;
  }
`;

export default LoadContainer;
