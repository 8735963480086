import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Cell30 from '../Elements/Cell30';
import Cell20 from '../Elements/Cell20';
import Cell10 from '../Elements/Cell10';
import { Caption, PSmall } from '../../../../../../assets/elements';
import device from '../../../../../utils/device';
import Cell70 from '../Elements/Cell70';
import Dots from '../../../../../../assets/elements/Dots';
import { getInvoiceDownloads } from '../../../../../utils/APICalls/Account';

const SingleResult = ({ type, invoiceNumber, date, amount, status }: any) => {
  const [downloadLink, setDownloadLink] = useState<any>('');
  const [downloading, setDownloading] = useState<any>(false);
  let downloadButton: any = '';

  useEffect(() => {
    if (downloadLink !== '' && downloadButton !== null) {
      downloadButton.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadLink]);

  const handleDownloads = (typeOfDownload: any) => {
    setDownloading(typeOfDownload);

    getInvoiceDownloads(invoiceNumber, typeOfDownload)
      .then((res: any) => {
        setDownloadLink(res.data);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  return (
    <Wrapper>
      <Cell30>
        <div>
          <ResultType type={type}>{type}</ResultType>
        </div>
        <BoldValue>{invoiceNumber}</BoldValue>
      </Cell30>
      <Cell20>
        <CaptionStyled>{moment(date).format('DD MMM YYYY')}</CaptionStyled>
      </Cell20>
      <Cell20>
        <BoldValue>£{amount.toFixed(2)}</BoldValue>
      </Cell20>
      <Cell20>
        <BoldValue>{status}</BoldValue>
      </Cell20>
      <Cell70>
        <CaptionStyled>{moment(date).format('DD MMM YYYY')}</CaptionStyled>
        <BoldValue>
          £{amount} ({status})
        </BoldValue>
      </Cell70>
      <Cell10>
        {
          type === 'topup' ? '' : (
            <DownloadLinks>
              {downloading === 'pdf' ? (
                <Downloading>
                  <Dots />
                </Downloading>
              ) : (
                <DownloadLink onClick={() => handleDownloads('pdf')}>
                  PDF
                </DownloadLink>
              )}
              {downloading === 'csv' ? (
                <Downloading>
                  <Dots />
                </Downloading>
              ) : (
                <DownloadLink onClick={() => handleDownloads('csv')}>
                  CSV
                </DownloadLink>
              )}
              <HiddenLink
                ref={(button: any) => {
                  downloadButton = button;
                }}
                href={downloadLink}
                download
              />
            </DownloadLinks>
          )
        }
      </Cell10>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 0 16px;
  width: 100%;
  margin-bottom: 9px;
  background: ${({ theme }: any) => theme.colors.White};
  flex-wrap: wrap;
  justify-content: flex-end;

  @media ${device.laptop} {
    padding: 0 24px;
  }
`;

const ResultType = styled.span.attrs(({ type }: any) => ({
  type,
}))`
  background: ${({ type, theme }: any) => getTypeColor(type, theme.colors)};
  border-radius: 4px;
  padding: 6px 8px;
  color: ${({ theme }: any) => theme.colors.Black};
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-transform: capitalize;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 8px;

  @media ${device.laptop} {
    margin-bottom: 0;
  }
`;

const BoldValue = styled(PSmall)`
  font-weight: 600;
  text-transform: capitalize;
`;

const CaptionStyled = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.MidGrey};
  margin-bottom: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  @media ${device.laptop} {
    margin-bottom: unset;
  }
`;

const DownloadLinks = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const DownloadLink = styled.a`
  color: ${({ theme }: any) => theme.colors.Secondary};
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-decoration: unset;
  cursor: pointer;
`;

const HiddenLink = styled.a`
  display: none;
`;

const Downloading = styled.div`
  display: flex;
  color: ${({ theme }: any) => theme.colors.Secondary};
  font-size: 20px;
  font-weight: 600;
`;

const getTypeColor = (type: any, colors: any) => {
  if (type === 'invoice') {
    return colors.WarningBackGround;
  }

  if (type === 'credit') {
    return colors.SuccessBackground;
  }

  if (type === 'topup') {
    return colors.CollectionBackground;
  }

  return colors.SuccessBackground;
};

export default SingleResult;
