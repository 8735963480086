import React from 'react';
import styled from 'styled-components';
import { Wrapper } from '../Elements/Wrapper';
import { H4Styled } from '../Elements/H4Styled';
import { Column, Row } from '../../../../../assets/elements';
import device from '../../../../utils/device';
import AccountCredit from './AccountCredit';
import LatestOrders from './LatestOrders';
import AccountLink from './AccountCredit/AccountLink';
import MultiBookIcon from '../../../../../assets/icons/multibook.svg';
import QuickBookIcon from '../../../../../assets/icons/quickbook.svg';
import InvoiceIcon from '../../../../../assets/icons/Invoice.svg';
import AddressIcon from '../../../../../assets/icons/Address.svg';
import AccountDiscount from './AccountDiscount';

const Dashboard = ({ session, topupRef }: any) => {
  return (
    <Wrapper>
      <H4Styled>Hello, {session.userName}</H4Styled>
      <Row>
        <ColumnStyled sizeL={4} sizeM={4} sizeS={6} sizeXS={4}>
          <AccountCredit topupRef={topupRef} />
          <AccountDiscount />
          <AccountLinks>
            <AccountLink
              icon={QuickBookIcon}
              title="Quick book"
              link="/quick-book"
            />
            <AccountLink
              icon={MultiBookIcon}
              title="Multi book"
              link="/multi-book"
            />
            <AccountLink
              icon={InvoiceIcon}
              title="Invoice and Credit Notes"
              link="/invoices"
            />
            <AccountLink
              icon={AddressIcon}
              title="Address Book"
              link="/address-book"
            />
          </AccountLinks>
        </ColumnStyled>
        <ColumnStyled sizeL={8} sizeM={8} sizeS={6} sizeXS={4}>
          <LatestOrders />
        </ColumnStyled>
      </Row>
    </Wrapper>
  );
};

const ColumnStyled = styled(Column)`
  padding: 0;

  @media ${device.laptopL} {
    padding: 0 15px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

const AccountLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  @media ${device.laptop} {
    flex-direction: column;
    justify-content: space-between;
  }

  @media ${device.laptopL} {
    flex-direction: column;
    justify-content: unset;
    margin-bottom: unset;
  }
`;

const AccountLinkRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div {
    width: 48%;
  }
`;

export default Dashboard;
