import React from 'react';
import styled from 'styled-components';
import AccordionListItem from './ListItem';

const AccordionList = ({ levels }: any) => {
  return (
    <Wrapper>
      <AccordionListItem content={levels[0]}>
        {
          levels[1] && (
            <AccordionListItem content={levels[1]}>
              {
                levels[2] && (
                  <AccordionListItem content={levels[2]} arrow={false} />
                )
              }
            </AccordionListItem>
          )
        }
      </AccordionListItem>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 8px 14px 8px 10px;
  border-left: 1px solid #BCC0BF;
  color: ${({ theme }: any) => theme.colors.MidGrey};
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  margin-bottom: 16px;
`;

export default AccordionList;