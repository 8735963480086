import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../../../../utils/hooks/useQuery';
import { completeManualKriyaTransaction } from '../../../../../utils/APICalls/Order';
import { P } from '../../../../../../assets/elements';

const KriyaManualSuccess = () => {
  const query = useQuery();
  const history = useHistory();
  const [pageText, setPageText] = useState('Processing');

  const kriyaTransactionId = query.get('tId');

  // Stop Api getting called multiple times due to useEffect bug.
  const apiCalled = useRef(false);

  useEffect(() => {
    if(apiCalled.current) return;
    apiCalled.current = true;
    if (!kriyaTransactionId) {
      history.push('/');
      return;
    }
    completeManualKriyaTransaction(kriyaTransactionId, 'completed')
      .then((res: any) => {
        if (res.data.salesAgent) {
          setPageText(`Payment has completed successfully, Your sales agent is ${res.data.salesAgent} and they will be in contact shortly.`);
        } else {
          setPageText('Payment has completed successfully, Your sales agent will be in contact shortly.');
        }
      })
      .catch((err: any) => {
        console.log('manual kriya confirm error', err);
        setPageText('Something has gone wrong. Your sales agent will be in contact shortly.');
      });
  });

  return (
    <Wrapper>
      <P>{pageText}</P>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  margin-left:10% ;
  height: 50vh;
  align-items: center;
  justify-content: center;
`;

export default KriyaManualSuccess;