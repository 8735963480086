import styled from 'styled-components';
import { PSmall } from '../Typography/Paragraphs';

export const InputUnitsLabel = styled(PSmall)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({ theme }: any) => theme.colors.Black};
  background-color: #DEDEDE;
  border-radius: ${({ theme }: any) =>
    `0 ${theme.box.BorderRadiusSmall} ${theme.box.BorderRadiusSmall} 0`};

  position: absolute;
  top: 2px;
  right: 2px;
  width: 40%;
  bottom: 2px;
  height: calc(100% - 4px);
`;
