import React from 'react';
import styled from 'styled-components';
import device from '../../../../../utils/device';

const Cell30 = ({ children }: any) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  width: 40%;
  padding: 16px 0;
  flex-direction: column;

  @media ${device.laptop} {
    flex-direction: row;
    width: 40%;
  }

  @media ${device.laptopL} {
    width: 30%;
  }
`;

export default Cell30;
