import React from 'react';

import styled from 'styled-components';
import UPSLogo from '../../../assets/images/UPS_logo.svg';
import DPDLogo from '../../../assets/images/DPD_logo.svg';
import { Column, Container, Row } from '../../../assets/elements';
import device from '../../utils/device';

const DeliveryPartners = () => {
  return (
    <Container>
      <RowStyled>
        <ColumnStyled sizeL={2} sizeS={1} sizeXS={2}>
          <img src={UPSLogo} alt="UPS" />
        </ColumnStyled>
        <ColumnStyled sizeL={2} sizeS={1} sizeXS={2}>
          <img src={DPDLogo} alt="DPD" />
        </ColumnStyled>
        <ColumnStyled sizeL={2} sizeS={1} sizeXS={2}>
          <img src={UPSLogo} alt="UPS" />
        </ColumnStyled>
        <ColumnStyled sizeL={2} sizeS={1} sizeXS={2}>
          <img src={DPDLogo} alt="DPD" />
        </ColumnStyled>
        <ColumnStyled sizeL={2} sizeS={1} sizeXS={2}>
          <img src={UPSLogo} alt="UPS" />
        </ColumnStyled>
        <ColumnStyled sizeL={2} sizeS={1} sizeXS={2}>
          <img src={DPDLogo} alt="DPD" />
        </ColumnStyled>
      </RowStyled>
    </Container>
  );
};

const RowStyled = styled(Row)`
  padding: 32px 0;
`;

const ColumnStyled = styled(Column)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;

  &:nth-child(odd) {
    justify-content: flex-end;
  }

  &:nth-child(even) {
    justify-content: flex-start;
  }

  @media ${device.laptop} {
    margin-bottom: 0;

    &:nth-child(odd),
    &:nth-child(even) {
      justify-content: center;
    }
  }
`;

export default DeliveryPartners;
