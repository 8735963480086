import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../../../../../../assets/elements';
import DownloadIcon from '../../../../../../../../assets/icons/Download.svg';
import { downloadInvoice } from '../../../../../../../utils/APICalls/OrderDetails';

const DownloadButtons = ({ orderGroupId }: any) => {
  const handleDownloads = (type: string) => {
    downloadInvoice(orderGroupId, type)
      .then((res: any) => {
        if (type === 'pdf') {
          const string = res.data.replace('data:application/pdf;base64,', '');
          const bc = atob(string);
          const bn = new Array(bc.length);
          for (let i = 0; i < bc.length; i++) {
            bn[i] = bc.charCodeAt(i);
          }
          const byteArray = new Uint8Array(bn);
          const file = new Blob([byteArray], { type: 'application/pdf;base64' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        } else if (type === 'csv') {
          window.open(res.data, '_blank');
        }
      })
      .catch((err: any) => {
        // err
      });
  };

  return (
    <Wrapper>
      <ButtonStyled color="primary" block onClick={() => handleDownloads('pdf')}>
        <Icon src={DownloadIcon} alt="Download" />
        Download Invoice PDF
      </ButtonStyled>
      <ButtonStyled color="primary" block onClick={() => handleDownloads('csv')}>
        <Icon src={DownloadIcon} alt="Download" />
        Download Invoice CSV
      </ButtonStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const ButtonStyled = styled(Button)`
  padding: 14px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;

  &:first-of-type {
    margin-bottom: 16px;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export default DownloadButtons;
