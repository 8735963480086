import React from 'react';

const PalletIcon = ({ color }: any) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 2L21 7V17L12 22L3 17V7L12 2Z" stroke={color} strokeWidth="2" />
      <path d="M3 7L12 12L21 7" stroke={color} strokeWidth="2" />
      <path d="M12 12V22" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default PalletIcon;
