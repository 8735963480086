import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { H6 } from '../../../../../../assets/elements';
import OrderItem from './OrderItem';
import Pagination from '../../../../Pagination';
import Loader from '../../../../Loader';
import Modal from '../../../../../../assets/Combos/Modal';
import OrderItemParcel from './OrderItemDetail/OrderItemParcel';
import OrderItemPallet from './OrderItemDetail/OrderItemPallet';
import { getOrderHistory } from '../../../../../utils/APICalls/Account';
import OrderItemFreight from './OrderItemDetail/OrderItemFreight';

const LatestOrders = () => {
  const [orders, setOrders] = useState([]);
  const [start, setStart] = useState(0);
  const [pages, setPages] = useState(0);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<any>({});

  useEffect(() => {
    getOrders(start);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  const goToPage = (pageNumber: any) => {
    if (!pageNumber) {
      return false;
    }

    window.scroll(0, 0);
    setStart((pageNumber - 1) * 10);
    setCurrent(pageNumber);
    return true;
  };

  const getOrders = (startFrom: any) => {
    setLoading(true);

    getOrderHistory(10, startFrom)
      .then((res: any) => {
        setOrders(res.data.data);
        setPages(Math.ceil(res.data.count / 10));
        setCurrent(Math.floor(start / 10) + 1);
      })
      .catch(() => {
        //exception
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setOrder = (order: any, orderId: any, orderGroupId: any, type: any, refund: any, labelUrl: any = []) => {
    setCurrentOrder({
      ...order,
      orderId,
      orderGroupId,
      type,
      refund,
      labelUrl,
    });
    setModalOpen(true);
  };

  const orderList =
    !orders || orders.length === 0
      ? 'No orders found.'
      : orders.map((order: any, i: any) => (
        <OrderItem key={i} order={order} setCurrentOrder={setOrder} />
      ));

  const modalData = () => {
    if (currentOrder.type === 'parcel') {
      return <OrderItemParcel item={currentOrder} />;
    }

    if (currentOrder.type === 'pallet') {
      return <OrderItemPallet item={currentOrder} />;
    }

    if (currentOrder.type === 'freight') {
      return <OrderItemFreight item={currentOrder} />;
    }

    return <OrderItemParcel item={currentOrder} />;
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setCurrentOrder({});
  };

  return (
    <Wrapper>
      <H6Styled>Latest orders</H6Styled>
      {loading ? <Loader>Loading</Loader> : orderList}
      {pages > 0 && (
        <Pagination pages={pages} current={current} goToPage={goToPage} />
      )}
      <Modal
        open={modalOpen}
        close={handleModalClose}
        back={false}
        width={8}
        floatingClose={true}
      >
        {currentOrder.type ? modalData() : ''}
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }: any) => theme.colors.White};
  border-radius: 9px;
  padding: 32px;
`;

const H6Styled = styled(H6)`
  margin-bottom: 24px;
`;

export default LatestOrders;
