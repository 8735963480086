import React from 'react';
import { Switch, Route, withRouter } from 'react-router';
import OrderBasket from '../../../Steps/OrderBasket';
import Payment from '../../../Steps/Payment';
import { Container } from '../../../../../../../assets/elements';
import OrderStepsContainer from './OrderStepsContainer';
import styled from 'styled-components';

const OrderForm = ({ location }: any) => {
  return (
    <ContainerStyled>
      <Switch location={location}>
        <Route exact path="/order-step1" component={OrderStepsContainer} />
        <Route exact path="/order-step2" component={OrderStepsContainer} />
        <Route exact path="/order-step3" component={OrderStepsContainer} />
        <Route exact path="/order-basket" component={OrderBasket} />
        <Route exact path="/payment" component={Payment} />
      </Switch>
    </ContainerStyled>
  );
};

const ContainerStyled = styled(Container)`
  padding: 0 !important;
`;

export default withRouter(OrderForm);
