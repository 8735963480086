import { environment, Environments } from './env';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const dateFormat = 'ddd D MMM';
export const ASSETS_URL =
  'https://e-gistics-landing-page.s3.eu-west-2.amazonaws.com/assets/';

export const UK_VAT = 20;

const env = environment.toUpperCase();

const getTrackingPrefix = () => {
  if (env === Environments.STAGING || env === Environments.CMP) {
    return 'HAU';
  }

  if (env === Environments.MPD) {
    return 'MPD';
  }

  if (env === Environments.P4D) {
    return 'P4D';
  }

  if (env === Environments.PALLETS4DELIVERY) {
    return 'PLT';
  }

  if (env === Environments.ARW) {
    return 'ARW';
  }

  if (env === Environments.AUC) {
    return 'AUC';
  }

  return 'CMP';
};

export const trackingPrefix = getTrackingPrefix();

export const VATStatusOptions = [
  {
    label: 'Personal Individual',
    value: 'personal_individual',
  },
  {
    label: 'Company - Non VAT Registered',
    value: 'company_non_vat',
  },
  {
    label: 'Company - VAT Registered',
    value: 'company_vat',
  },
];
