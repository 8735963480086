import React from 'react';
import styled from 'styled-components';
import BoxIcon from '../../../../../assets/icons/freight-box.svg';
import { countriesPostalCodesData } from '../../../../mocks/quoteData';
import { Container, A } from '../../../../../assets/elements';
import device from '../../../../utils/device';

const DeliverBanner = ({
  data: { collectionLocation, deliveryLocation, packageDetails },
  handleEditDetailsClick,
}: any) => {
  const transformToName = (countryCode: string) => {
    const selected = countriesPostalCodesData.find(
      (element) => element.code === countryCode
    );
    return selected && selected.name;
  };

  return (
    <Wrapper>
      <ContainerStyled>
        <TextWrapper>
          <TextImg src={BoxIcon} alt="box" />
            <SpanStyled>
              {transformToName(collectionLocation.country)}
              {collectionLocation.postcode &&
                ` (${collectionLocation.postcode})`}{' '}
              to
              <span> </span>
              {transformToName(deliveryLocation.country)}
              {deliveryLocation.postcode && ` (${deliveryLocation.postcode})`}:
            </SpanStyled>
            <span> </span>
            {
              packageDetails.package.type === 'FCL' ?
                'Full Container Load' :
                'Less than Full Container Load'
            }
        </TextWrapper>
        <AStyled onClick={handleEditDetailsClick}>Edit details</AStyled>
      </ContainerStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background: white;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  padding: 14px 0;
  margin-bottom: 40px;

  @media ${device.laptop} {
    padding: 14px 40px;
  }
`;
const ContainerStyled = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const TextWrapper = styled.div`
  color: #2b3131;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
`;
const TextImg = styled.img<any>`
  margin-right: 15px;
  user-select: none;
`;

const SpanStyled = styled.div`
  font-weight: bold;
  display: inline-block;
  margin-top: 2px;
`;
const AStyled = styled(A)`
  margin: 10px 0;
  min-width: 80px;
  margin-left: 20px;
`;

export default DeliverBanner;
