import React from 'react';
import styled from 'styled-components';
import device from '../../../app/utils/device';

export const TableCell = ({ head = false, width = 100, children }: any) => {
  return (
    <TableCellWrapper head={head} width={width}>
      {children}
    </TableCellWrapper>
  );
};

const TableCellWrapper = styled.div.attrs(({ head, width }: any) => ({
  head,
  width,
}))`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  background-color: ${({ head, theme }: any) =>
    head ? theme.colors.Secondary : 'transparent'};
  color: ${({ head, theme }: any) => (head ? theme.colors.White : 'inherit')};
  font-weight: ${({ head }: any) => (head ? 600 : 'normal')};
  width: 100%;
  margin-bottom: 4px;
  font-size: 14px;

  @media ${device.laptop} {
    width: ${({ width }: any) => `${width || 100}%`};
    padding: 16px;
    margin-bottom: 0;
    font-size: 16px;
  }
`;
