import React from 'react';
import styled from 'styled-components';
import {
  Button,
  Container,
} from '../../../../../../../assets/elements';
import device from '../../../../../../utils/device';
import { supportUrl } from '../../../../../../utils/env';

const TechnicalError = ({ active, order }: any) => {

  const handleContact = () => {
    window.open(supportUrl);
  };

  return (
    <>
      <Wrapper active={active}>
        <Title>Your order has not been completed</Title>
        <ErrorText>
          There seems to be a technical issue so we couldn't process the payment.
          Please contact support for assistance{ typeof order === 'string' ? ` - your reference number is ${order}` : '' }
        </ErrorText>
        <ButtonBlack color="black" onClick={handleContact}>
          Contact support
        </ButtonBlack>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(Container)<any>`
    display: ${({ active }: any) => (active ? 'block' : 'none')};
  background: #FEE6D0;
  border-radius: 8px;
  && {
    padding: 15px;
    @media ${device.tablet} {
      padding: 30px;
    }
  }
  
  height: auto;
  margin-bottom: 16px;

  @media ${device.laptop} {
    margin-bottom: 24px;
  }
`;
const Title = styled.h3<any>`
  font-weight: 600;
font-size: 24px;
line-height: 32px;
color: #2B3131;
`;
const ErrorText = styled.p<any>`
  font-weight: normal;
line-height: 153%;
letter-spacing: 0.01em;
color: #777E7E;
margin: 15px 0;
`;
const ButtonBlack = styled(Button)<any>`
  background: black;
  color: white;
  cursor: pointer;
  padding: 6px 12px;
  
  &:hover {
   background: white;
  color: black;
  }
`;



export default TechnicalError;
