import React from 'react';
import styled from 'styled-components';
import device from '../../../../../utils/device';

const Cell10 = ({ children }: any) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  width: 13%;
  min-width: 80px;
  padding: 10px 0;

  @media ${device.laptop} {
    padding: 16px 0;
  }

  @media ${device.laptopL} {
    width: 13%;
  }
`;

export default Cell10;
