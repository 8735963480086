import TagManager from 'react-gtm-module';
import { environment, GTM_AUTH, GTM_ENV, GTM_ID } from './env';

let tagManagerArgs: any = {
  gtmId: GTM_ID || '',
};

if (environment === 'staging' || environment === 'cmp' || environment === 'auc') {
  tagManagerArgs = {
    ...tagManagerArgs,
    auth: GTM_AUTH,
    preview: `env-${GTM_ENV}`
  };
}

export const initGTM = () => TagManager.initialize(tagManagerArgs);

export const PageView = () =>
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageView',
      pagePath: window.location.pathname + window.location.search,
      pageTitle: window.document.title,
    }
  });

export const GTMEvent = (event: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
    },
  });
};

export const GTMLead = (value: number, currency = 'GBP') => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'generateLead',
      ecommerce: {
        currency,
        value
      }
    },
  });
}

export const GTMECommerce = (
  data: any,
  event: any = undefined,
) => {
  const dataLayerValue = {
    'event': event,
    ecommerce: {
      ...data,
    },
  };

  TagManager.dataLayer({
    dataLayer: {
      ...dataLayerValue,
    },
  });
};
