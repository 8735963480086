import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import MultiOptions from './Components/MultiOptions';
import { setParcelQuotesAction, setResetFilters } from '../../store/actions';
import device from '../../utils/device';
import Sort from '../Sort';
import { sort } from '../../utils/sorting';
import { Container } from '../../../assets/elements';

export const FilterValuesMap = {
  COLLECTION_DROP_OFF: 0,
  COLLECTION: 1,
  DROP_OFF: 2,
};

export const SortValuesMap = {
  CHEAPEST: 0,
  QUICKEST: 1,
};

const FilterValuesArray = ['', 'collection', 'dropoff'];

const Filter = () => {
  const dispatch = useDispatch();
  const items: any = useSelector<any>((state) => state.main.parcelQuotes);
  const reset: any = useSelector<any>((state) => state.main.resetFilters);

  useEffect(() => {
    const resetFilters = () => {
      setSelectedPrinter(0);
      setSelected(FilterValuesMap.COLLECTION_DROP_OFF);
      setSortSelected(SortValuesMap.CHEAPEST);

      handleCollectionChange(FilterValuesMap.COLLECTION_DROP_OFF, true);
      dispatch(setResetFilters(false));
    };

    if (reset) resetFilters();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const [options] = useState([
    {
      id: 0,
      label: 'All services',
    },
    {
      id: 1,
      label: 'Collection',
    },
    {
      id: 2,
      label: 'Drop off',
    },
  ]);

  const printerOptions = [
    {
      id: 0,
      label: 'Optional',
    },
    {
      id: 1,
      label: 'I don\'t have a printer',
    },
  ];

  const sortOptions = [
    {
      id: 0,
      label: 'Cheapest',
    },
    {
      id: 1,
      label: 'Quickest',
    },
  ];

  const [selected, setSelected] = useState(FilterValuesMap.COLLECTION_DROP_OFF);
  const [selectedPrinter, setSelectedPrinter] = useState(0);
  const [sortSelected, setSortSelected] = useState(SortValuesMap.CHEAPEST);

  const handlePrinterChange = (value: any) => {
    setSelectedPrinter(value);

    const payload = items.map((item: any) => {
      item.active =
        (value === 0 ? true : (item.printer === false)) &&
        (FilterValuesArray[selected] === '' ||
          FilterValuesArray[selected] === item.type);
      return item;
    });

    dispatch(setParcelQuotesAction(payload));
  };

  const handleCollectionChange = (type: any, resetF = false) => {
    setSelected(type);
    let payload = [];

    const printerValue = resetF ? 0 : selectedPrinter;

    if (type === FilterValuesMap.COLLECTION) {
      payload = items.map((item: any) => {
        item.active =
          item.type === 'collection' && (printerValue === 0 ? true : (item.printer === false));
        return item;
      });
    } else if (type === FilterValuesMap.DROP_OFF) {
      payload = items.map((item: any) => {
        item.active =
          item.type === 'dropoff' && (printerValue === 0 ? true : (item.printer === false));
        return item;
      });
    } else if (type === FilterValuesMap.COLLECTION_DROP_OFF) {
      payload = items.map((item: any) => {
        item.active = (printerValue === 0 ? true : (item.printer === false));
        return item;
      });
    }

    dispatch(setParcelQuotesAction(payload));
  };

  const handleSortChange = (data: any) => {
    setSortSelected(data);
    let payload;
    if (data === SortValuesMap.CHEAPEST) {
      payload = sort(items, 'price');
    } else if (data === SortValuesMap.QUICKEST) {
      payload = sort(items, 'deliveryTime');
    }

    dispatch(setParcelQuotesAction(payload));
  };

  return (
    <Wrapper>
      <ContainerStyled>
        <MultiOptions
          options={options}
          selected={selected}
          onChange={handleCollectionChange}
          title="Service type"
        />
        <MultiOptions
          options={printerOptions}
          selected={selectedPrinter}
          onChange={handlePrinterChange}
          title="Printer"
        />
        <SortWrapper>
          <Title>Sort</Title>
          <Sort
            options={sortOptions}
            selected={sortSelected}
            onChange={handleSortChange}
          />
        </SortWrapper>
      </ContainerStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }: any) => theme.colors.LightGrey};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  padding: 0 24px;

  @media ${device.laptop} {
    padding: 0;
  }
`;

const ContainerStyled = styled(Container)`
  padding: 24px 0;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    @media ${device.laptop} {
      flex-direction: row;
      margin-bottom: 0;
    }
  }

  @media ${device.laptop} {
    flex-direction: row;
    padding: 24px 0;
  }
`;

const SortWrapper = styled.div`
  display: flex;
  flex-direction: column !important;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  margin-bottom: 8px;
`;

export default Filter;
