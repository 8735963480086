import React from 'react';
import styled from 'styled-components';

const TableColumn = ({ children, colSpan = 1 }: any) => {
  return (
    <Wrapper colSpan={colSpan}>
      { children }
    </Wrapper>
  );
};

const Wrapper = styled.td.attrs(({ colSpan = 1 }: any) => ({
  colSpan,
}))`
  background: transparent;
  text-align: center;
  padding: 8px 16px;
  vertical-align: middle;
  white-space: nowrap;
`;

export default TableColumn;
