import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ParcelQuotes from './components/ParcelQuotes';
import PalletQuotes from './components/PalletQuotes';
import FreightQuotes from './components/FreightQuotes';
import { getAccountDiscount } from '../../utils/APICalls/Account';
import { addAccountDiscount, setQuoteUrl } from '../../store/actions';
import Modal from '../../../assets/Combos/Modal';
import { H6, PSmall } from '../../../assets/elements';
import SeaFreightQuotes from './components/SeaFreightQuotes';

const Quotes: React.FC = () => {
  const { type, transit } = useParams();
  const dispatch = useDispatch();

  const mainState = useSelector<any, any>((state) => state.main);
  const activeTab = useSelector<any, any>((state) => state.main.activeTab);
  const session = useSelector((state: any) => state.user.session);
  const [insuranceModal, setInsuranceModal] = useState(false);
  const [freightTransit, setFreightTransit] = useState('');
  const [openEmailModel, setOpenEmailModal] = useState(false);
  const emailModalAutoOpened = useRef(false);
  let timeout: any;

  useEffect(() => {
    if (type === 'freight') {
      setFreightTransit(transit);
    }
  }, [type]);

  useEffect(() => {
    if (session.accessToken) {
      getAccountDiscount()
        .then((res: any) => {
          const { value = 0, updatedAt: applicableFrom = '' } = res.data;

          if (value > 0) {
            dispatch(addAccountDiscount({
              value,
              applicableFrom,
            }));
          }
        })
        .catch(() => {
          // Error
        });
    }
  }, [session]);

  useEffect(() => {
    dispatch(setQuoteUrl(window.location.href));
  }, []);

  // const restartTimer = () => {
  //   if (timeout) {
  //     clearTimeout(timeout);
  //   }
  //   timeout = setTimeout(() => {
  //     emailModalAutoOpened.current = true;
  //     setOpenEmailModal(true);
  //   }, 1000 * 20); // 20 seconds
  // };

  // const onMouseMove = () => {
  //   if (emailModalAutoOpened.current) return;
  //   // check for quotes loaded before starting timer
  //   if (mainState.palletQuotes.length || mainState.parcelQuotes.length || mainState.freightQuotes.length || mainState.seaFreightQuotes.length) {
  //     restartTimer()
  //   };
  // };

  const openEmailModalFunc = (value: any) => {
    if (!emailModalAutoOpened.current) emailModalAutoOpened.current = true;
    if (timeout) clearTimeout(timeout);
    setOpenEmailModal(value);
  };

    //<Wrapper onMouseMoveCapture={onMouseMove}>
  return (
    <Wrapper>
      {activeTab === 'parcel' && !type && <ParcelQuotes openInsurance={() => setInsuranceModal(true)} openEmailModal={openEmailModel} setOpenEmailModal={openEmailModalFunc}  />}
      {(activeTab === 'pallet' || type === 'pallet') && <PalletQuotes openInsurance={() => setInsuranceModal(true)} openEmailModal={openEmailModel} setOpenEmailModal={openEmailModalFunc}  />}
      {((activeTab === 'freight' || type === 'freight') && freightTransit === 'road') && <FreightQuotes openInsurance={() => setInsuranceModal(true)} openEmailModal={openEmailModel} setOpenEmailModal={openEmailModalFunc}  />}
      {((activeTab === 'freight' || type === 'freight') && freightTransit === 'sea') && <SeaFreightQuotes openInsurance={() => setInsuranceModal(true)} openEmailModal={openEmailModel} setOpenEmailModal={openEmailModalFunc}  />}
      <Modal
        open={insuranceModal}
        close={() => setInsuranceModal(false)}
        width={6}
        title="Insurance"
        TitleElement={H6}
      >
        <InsuranceText>
          Our insurance covers your shipment for loss or damage up to the amount listed.
          You can add more cover up to £1,000 in the next steps.
        </InsuranceText>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  background-color: #f2f3f2;
  height: 100%;
  min-height: calc(100vh - 76px);
`;

const InsuranceText = styled(PSmall)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }: any) => theme.colors.Black};
`;

export default Quotes;
