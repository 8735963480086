import React from 'react';
import styled from 'styled-components';
import LeftArrowMuted from '../../../assets/icons/LeftMuted.svg';
import RightArrow from '../../../assets/icons/Right.svg';
import { SelectInput } from '../Inputs';

const Pagination = ({ pages, current, goToPage }: any) => {
  return (
    <Wrapper>
      <Links>
        <Link
          onClick={() => goToPage(current === 1 ? false : current - 1)}
        >
          <img src={LeftArrowMuted} alt="Previous" />
        </Link>
        {/* {[...Array(pages)].map((page: any, i: any) => (
          <Link
            key={i}
            onClick={() => goToPage(i + 1 === current ? false : i + 1)}
          >
            {i + 1}
          </Link>
        ))} */}
        <SelectInput
          title=""
          data={[...Array(pages)].map((page: any, i: any) => {
            return {
              label: `${i + 1} of ${pages}`,
              value: (i + 1)
            };
          })}
          height="48px"
          minWidth="110px"
          error={false}
          resetError={() => {}}
          value={current}
          onChange={(selected: any) => goToPage(selected === current ? false : selected)}
          isSearchable={false}
        />
        <Link
          onClick={() => goToPage(current === pages ? false : current + 1)}
        >
          <img src={RightArrow} alt="Next" />
        </Link>
      </Links>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
`;

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Link = styled.a`
  width: 44px;
  height: 48px;
  background: ${({ theme }: any) => theme.colors.White};
  border: 2px solid ${({ theme }: any) => theme.colors.WhiteGrey};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${({ theme }: any) => theme.colors.Pastel2};
  }
`;

export default Pagination;
