import React, { useState } from 'react';
import styled from 'styled-components';
import PrintIcon from '../../../../../../../../../assets/icons/print.svg';
import LabelModal from './LabelModal';

const PrintLabel = ({ link = [] }: any) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e: any) => {
    e.nativeEvent.preventDefault();

    if (Array.isArray(link)) {
      if (link.length === 1) {
        window.open(link[0], '_blank');
      } else if (link[0] === 'data:application/pdf;base64') {
        const bc = atob(link[1]);
        const bn = new Array(bc.length);
        for (let i = 0; i < bc.length; i++) {
          bn[i] = bc.charCodeAt(i);
        }
        const byteArray = new Uint8Array(bn);
        const file = new Blob([byteArray], { type: 'application/pdf;base64' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      } else {
        setOpen(true);
      }
    }
  };

  if (Array.isArray(link)) {
    return (
      <>
        <Wrapper onClick={handleClick}>
          <Icon src={PrintIcon} />
          Print label
        </Wrapper>
        {
          Array.isArray(link) && link.length > 1 && (
            <LabelModal
              link={link}
              open={open}
              setOpen={setOpen}
            />
          )
        }
      </>
    );
  }

  return <></>;

};

const Wrapper = styled.div`
  padding: 8px 22px;
  background: #2757f2;
  color: ${({ theme }: any) => theme.colors.White};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  width: 100%;
  flex: 0 0 100%;
  margin-bottom: 16px;
  cursor: pointer;
`;

const Icon = styled.img`
  margin-right: 10px;
`;

export default PrintLabel;
