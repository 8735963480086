import React from 'react';
import styled from 'styled-components';
import device from '../../../../../utils/device';

const Cell70 = ({ children }: any) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  width: 60%;
  padding: 16px 0;
  flex-direction: column;
  align-items: flex-end;

  @media ${device.laptop} {
    display: none;
  }
`;

export default Cell70;
