import moment from 'moment';

export const calculateForAnyTime = (deliveryTime: number, earliestCollectionDate: string, deliveryDate: string, deliveryDays: number[], collectionHolidays: [] = [], delivertHolidays: [] = []) => {
  const tempCD = moment(earliestCollectionDate);
  const tempDD = moment(deliveryDate);

  if (deliveryDays.length === 1 && deliveryDays.includes(6)) {
    const searchDate = tempDD;

    while (searchDate.isoWeekday() !== 6) {
      searchDate.add(1, 'day');
    }

    return searchDate;
  }

  if (tempCD.isoWeekday() === 5) {
    return tempCD.add(deliveryTime + 2, 'days');
  }

  if (
    tempDD.isoWeekday() > 6 ||
    tempDD.isoWeekday() === 0
  ) {
    return tempCD.add(deliveryTime + 2, 'days');
  }

  if ((
    tempDD.isoWeekday() > 6
  ) &&
    !deliveryDays.includes(6)
  ) {
    return tempDD.weekday(8);
  }

  if ((
    tempCD.isoWeekday() === 4 &&
    deliveryTime > 1
  ) &&
    !deliveryDays.includes(6)
  ) {
    return moment(tempCD).add(deliveryTime + 2, 'days');
  }

  return moment(deliveryDate);
};
