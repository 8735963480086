import React, { useEffect, useState } from 'react';
import NameSetting from './NameSetting';
import EmailSetting from './EmailSetting';
import PasswordSetting from './PasswordSetting';
import NewsletterSetting from './NewsLetterSetting';
import { Wrapper } from '../Elements/Wrapper';
import { H4Styled } from '../Elements/H4Styled';
import { getUserInfo } from '../../../../utils/APICalls/Account';

const Settings = () => {
  const [userInfo, setUserInfo] = useState<any>({
    passwords: {
      password: '',
      confirmPassword: '',
    },
    name: '',
    newsletter: '',
    email: '',
  });

  useEffect(() => {
    getUserInfo().then((res: any) => {
      setUserInfo({
        ...userInfo,
        name: res.data.name,
        newsletter: res.data.newsletter,
        email: res.data.emails[0].address,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <H4Styled>Settings</H4Styled>
      <NameSetting userInfo={userInfo.name} />
      <EmailSetting userInfo={userInfo.email} />
      <PasswordSetting userInfo={userInfo.email} />
      <NewsletterSetting userInfo={userInfo.newsletter} />
    </Wrapper>
  );
};

export default Settings;
