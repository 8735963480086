import React from 'react';
import styled from 'styled-components';

const Required = () => {
  return <Wrapper>*</Wrapper>;
};

const Wrapper = styled.span`
  color: ${({ theme }: any) => theme.colors.Primary};
  font-size: inherit;
  display: inline-block;
  margin-right: 4px;
`;

export default Required;
