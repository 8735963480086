import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../../../../../assets/Combos/Modal';
import { A, H6, PSmall } from '../../../../../../../../assets/elements';
import AnimalIcon from '../../../../../../../../assets/icons/prohibited/animal.svg';
import AppliancesIcon from '../../../../../../../../assets/icons/prohibited/appliances.svg';
import DrugsIcon from '../../../../../../../../assets/icons/prohibited/drugs.svg';
import HazardousIcon from '../../../../../../../../assets/icons/prohibited/hazardous.svg';
import LiquidsIcon from '../../../../../../../../assets/icons/prohibited/liquids.svg';
import PerishableIcon from '../../../../../../../../assets/icons/prohibited/perishable.svg';
import VehicleIcon from '../../../../../../../../assets/icons/prohibited/vehicle.svg';
import WeaponsIcon from '../../../../../../../../assets/icons/prohibited/weapons.svg';
import { landingPageUrl } from '../../../../../../../utils/env';

const ProhibitedItems = ({ open, setOpen }: any) => {
  const goToProhibited = () => {
    window.open(
      `${landingPageUrl}faq/prohibited-items/`,
      '_blank'
    );
  };

  return (
    <Modal
      title="Prohibited items"
      TitleElement={H6}
      open={open}
      close={() => setOpen(false)}
      width={7}
    >
      <PStyled>
        Prohibited goods cannot be shipped by courier under any circumstances {' '}
        and are not covered for loss or damage.
      </PStyled>

      <ImageWrapper>
        <ImageContent>
          <Image src={AnimalIcon} alt="animal" />
          <Content>Animal and Animal Related</Content>
        </ImageContent>
        <ImageContent>
          <Image src={AppliancesIcon} alt="appliances" />
          <Content>Appliances</Content>
        </ImageContent>
        <ImageContent>
          <Image src={DrugsIcon} alt="drugs" />
          <Content>Drugs or Medicines</Content>
        </ImageContent>
        <ImageContent>
          <Image src={HazardousIcon} alt="hazardous" />
          <Content>Hazardous items</Content>
        </ImageContent>
      </ImageWrapper>
      <ImageWrapper>
        <ImageContent>
          <Image src={LiquidsIcon} alt="liquids" />
          <Content>Liquids</Content>
        </ImageContent>
        <ImageContent>
          <Image src={PerishableIcon} alt="perishable" />
          <Content>Perishable Goods</Content>
        </ImageContent>
        <ImageContent>
          <Image src={VehicleIcon} alt="vehicle" />
          <Content>Vehicle Parts</Content>
        </ImageContent>
        <ImageContent>
          <Image src={WeaponsIcon} alt="weapons" />
          <Content>Weapons</Content>
        </ImageContent>
      </ImageWrapper>

      <AStyled onClick={goToProhibited}>
        View the full list of prohibited and restricted items
      </AStyled>

      <SmallPrint>
        It is your responsibility to make sure the items you send are not prohibited.
        If prohibited goods are stopped at a sorting depot or at the point of attempted entry,
        the courier company is entitled to return the item to you, destroy it,
        or dispose of it and also charge back any reasonable associated costs incurred to do so;
        we will pass this cost onto you as the sender.
        Attempts to ship dangerous goods can also lead to prosecution by the authorities.
      </SmallPrint>
    </Modal>
  );
};

const PStyled = styled(PSmall)`
  margin-bottom: 16px;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const ImageContent = styled.div`
  padding: 14px 8px 8px;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  width: 25%;
  margin-right: 8px;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }
`;

const Image = styled.img`
  margin-bottom: 10px;
`;

const Content = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`;

const AStyled = styled(A)`
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  margin: 23px 0;
  text-align: left;
`;

const SmallPrint = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

export default ProhibitedItems;
