import React from 'react';
import styled from 'styled-components';

export const Body = ({ children, visible }: any) => {
  return (
    <Wrapper visible={visible}>{ children }</Wrapper>
  );
};

const Wrapper = styled.div.attrs(({ visible }: any) => ({
  visible,
}))`
  padding: 0 24px 24px;
  display: ${({ visible }: any) => visible ? 'block' : 'none'};
`;
