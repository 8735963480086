/*
* M: Mobile devices
* L: Larger devices (Laptop/Tablet)
*
* FS: Font size
* */

export const auc = {
  Primary: '#080044',
  Secondary: '#F68B1B',
};