import axios from '../API/axios';

export const ZonosClassify = (
  description: any,
  countryOfOrigin: any,
  shipToCountry: any,
) => {
  return axios.post('zonos/classify', {
    countryOfOrigin,
    description,
    shipToCountry,
  });
};
