import React from 'react';
import styled from 'styled-components';
import { H6, PSmall, A } from '../../../../../../assets/elements';
import Modal from '../../../../../../assets/Combos/Modal';
import { supportUrl } from '../../../../../utils/env';

const CustomsClearance = ({
  open,
  close,
}: any) => {
  const handleContactUs = () => {
    window.open(supportUrl);
  };

  return (
    <Modal
      open={open}
      close={close}
      width={6}
      title="Customs Clearance"
      TitleElement={H6}
    >
      <PSmall>
        Customs clearance are the checks made by the local government when goods enter a country.
        This is a requirement for all shipments including those made between UK and EU.
        <br />
        P4D partners with a specialist customs clearance agent to manage customs documentation,
        compliance and processes. If this is something you require, please select <strong>YES</strong>.
        <br />
        If you already have a broker to ensure compliance in this area, we can work with them directly.
        In this case, please select <strong>NO</strong>.
      </PSmall>
      <MoreInfo onClick={handleContactUs}>
        Contact us for further support
      </MoreInfo>
    </Modal>
  );
};

const MoreInfo = styled(A)`
  margin-top: 31px;
  text-align: left;
  padding-left: 0;
`;

export default CustomsClearance;
