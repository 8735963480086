import React from 'react';

const ParcelIcon = ({ color }: any) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="9" width="14" height="11" stroke={color} strokeWidth="2" />
      <rect width="16" height="4" transform="matrix(1 0 0 -1 4 9)" stroke={color} strokeWidth="2" />
    </svg>

  );
};

export default ParcelIcon;
