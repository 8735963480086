import styled from 'styled-components';
import { Theme } from '../Interfaces';

interface ThemeProps {
  block?: boolean;
  round?: boolean;
  color?: string;
  theme: Theme;
}

export const Button = styled.button.attrs(
  ({ disable = false, block = false }: any) => ({
    type: 'button',
    disable,
    block,
  })
)`
  border-radius: ${(props: any) =>
    props.round ? '50%' : props.theme.box.BorderRadiusSmall};
  padding: 12px 22px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({ color, theme, disable }: any) =>
    disable ? '#DDDDDD' : getBackgroundColor(color, theme)};
  color: ${(props: any) => getTextColor(props.color, props.theme)};
  width: ${(props: any) => (props.block ? '100%' : 'auto')};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02rem;
  border: 1px solid
    ${({ color, theme, disable }: any) =>
    disable ? '#DDDDDD' : getBorderColor(color, theme)};
  outline: none;
  cursor: pointer;
  transition: 100ms;

  &:hover {
    background-color: ${({ color, theme, disable }: any) =>
    disable ? '#DDDDDD' : getHoverColor(color, theme)};
  }
`;

const getBackgroundColor = (color: string | undefined, theme: any) => {
  switch (color) {
    case 'primary':
      return theme.colors.Primary;

    case 'secondary':
      return theme.colors.Secondary;

    case 'white':
      return theme.colors.White;

    case 'black':
      return 'transparent';

    case 'grey':
      return '#DDDDDD';

    case 'dark':
      return theme.colors.Black;

    default:
      return 'transparent';
  }
};

const getTextColor = (color: string | undefined, theme: any) => {
  switch (color) {
    case 'primary':
      return theme.colors.White;

    case 'secondary':
      return theme.colors.White;

    case 'white':
      return theme.colors.Black;

    case 'black':
      return theme.colors.Black;

    case 'grey':
      return theme.colors.White;

    case 'dark':
      return theme.colors.White;

    default:
      return theme.colors.Primary;
  }
};

const getBorderColor = (color: string | undefined, theme: any) => {
  switch (color) {
    case 'primary':
      return theme.colors.Primary;

    case 'secondary':
      return theme.colors.Secondary;

    case 'white':
      return theme.colors.Secondary;

    case 'black':
      return theme.colors.Black;

    case 'grey':
      return '#DDDDDD';

    case 'dark':
      return theme.colors.Black;

    default:
      return theme.colors.Primary;
  }
};

const getHoverColor = (color: string | undefined, theme: any) => {
  switch (color) {
    case 'primary':
      return theme.colors.PrimaryHover;

    case 'secondary':
      return theme.colors.SecondaryHover;

    case 'white':
      return theme.colors.MutedSecondary;

    case 'dark':
      return theme.colors.Black;

    case 'black':
      return theme.colors.White;

    default:
      return theme.colors.MutedPrimary;
  }
};
