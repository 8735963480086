import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button } from '../../../../../../assets/elements';
import device from '../../../../../utils/device';
import Modal from '../../../../../../assets/Combos/Modal';
import TopUp from '../../../../TopUp';
import { StripeAPIKey } from '../../../../../utils/stripe';
import { getAccountCredit } from '../../../../../utils/APICalls/Account';

const stripePromise = loadStripe(StripeAPIKey);

const AccountCredit = ({ topupRef }: any) => {
  const [topUpModal, setTopUpModal] = useState(false);
  const [credit, setCredit] = useState(0);

  useEffect(() => {
    getCredit();
  }, []);

  const handleTopUpClose = (refresh: any = false) => {
    refresh && getCredit();

    setTopUpModal(false);
  };

  const getCredit = () => {
    getAccountCredit().then((res: any) => {
      setCredit(res.data.toFixed(2));
    });
  };

  return (
    <Wrapper>
      <CreditBox>
        <CreditValue>£{credit}</CreditValue>
        <CreditLabel>Account Credit</CreditLabel>
      </CreditBox>
      <ButtonStyled ref={topupRef} color="black" onClick={() => setTopUpModal(true)}>
        Top up
      </ButtonStyled>
      <Modal
        title="Top up account balance"
        open={topUpModal}
        close={() => setTopUpModal(false)}
        back={false}
        width={6}
        closeIcon={false}
      >
        <Elements stripe={stripePromise}>
          <TopUp close={handleTopUpClose} />
        </Elements>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }: any) => theme.colors.White};
  border-radius: 9px;
  padding: 14px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media ${device.laptop} {
    width: 48%;
  }

  @media ${device.laptopL} {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 32px;
  }
`;

const CreditBox = styled.div`
  @media ${device.laptopL} {
    width: 160px;
    height: 160px;
    border: 8px solid #eef4e2;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }

  display: flex;
  flex-direction: column;
`;

const CreditLabel = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  order: 1;

  @media ${device.laptopL} {
    order: 2;
  }
`;

const CreditValue = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  order: 2;

  @media ${device.laptopL} {
    order: 1;
    font-size: 24px;
    line-height: 36px;
  }
`;

const ButtonStyled = styled(Button)`
  padding: 10px 14px;
  border-width: 2px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
`;

export default AccountCredit;
