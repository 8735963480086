import styled from 'styled-components';
import device from '../../../../app/utils/device';

export const Label = styled.label`
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;

  // @media ${device.laptopL} {
  //   font-size: ${({ theme }: any) => theme.typography.LabelSmall_FS};
  //   line-height: ${({ theme }: any) => theme.typography.LineHeightLabel};
  // }
`;
