import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Input, Label, Caption } from '../../../../../../../../assets/elements';
import { SelectInput } from '../../../../../../Inputs';
import { VATStatusOptions } from '../../../../../../../utils/variables';
import MobileNumber from '../../../../../../../../assets/Combos/MobileNumber';
import Required from '../../../../../../../../assets/elements/Required';
import { validateMobile } from '../../../../../../../utils/validateMobile';

const Collection = ({
  localState,
  setLocalState,
  errors,
  setErrors,
}: any) => {
  const { vat } = useSelector<any, any>((state: any) => state.order.item);
  const serviceType = useSelector((state: any) => state.order.serviceType);

  const [disabledVAT, setDisabledVAT] = useState(false);

  const handleChange = (value: any, key: any) => {
    if (key === 'name' && value.length > 30) {
      setErrors({
        ...errors,
        [key]: 'This must be less than 30 characters'
      });
    } else if (key === 'email' && value.length > 50) {
      setErrors({
        ...errors,
        [key]: 'Email must not exceed 50 characters'
      });
    } else if (key === 'phone' && value.length > 0 && validateMobile(value)) {
      setErrors({
        ...errors,
        [key]: validateMobile(value, true),
      });
    } else if (key === 'reference') {
      value = value.slice(0, 500);
    } else {
      setErrors({
        ...errors,
        [key]: false,
      });
    }

    setLocalState({
      ...localState,
      [key]: value,
    });
  };

  const handleCustomsChange = (value: any, key: any) => {
    setErrors({
      ...errors,
      customsData: {
        ...errors.customsData,
        [key]: false,
      },
    });

    setLocalState({
      ...localState,
      customsData: {
        ...localState.customsData,
        [key]: value,
      }
    });
  };

  useEffect(() => {
    if (vat.customs && serviceType === 'pallet') {
      setDisabledVAT(true);
    }
  }, []);

  return (
    <>
      <H2>Collection details</H2>
      <SubTitle>
        Contact details should be provided for the person/company handing over
        the goods
      </SubTitle>
      <LabelStyled><Required />First name and last name</LabelStyled>
      <Input
        placeholder="Contact Name"
        value={localState.name}
        onChange={(e: any) => handleChange(e.target.value, 'name')}
        theme={undefined}
        type="text"
        error={errors.name}
        autoComplete="off"
      />
      <ErrorText>{errors.name}</ErrorText>
      <LabelStyled><Required />Email address</LabelStyled>
      <Input
        placeholder="Contact email address"
        value={localState.email}
        onChange={(e: any) => handleChange(e.target.value, 'email')}
        theme={undefined}
        type="text"
        error={errors.email}
        autoComplete="off"
      />
      <ErrorText>{errors.email}</ErrorText>
      <LabelStyled><Required />Mobile number required to receive free SMS notifications</LabelStyled>
      <MobileNumber
        country={localState.country}
        value={localState.phone}
        onChange={(selected: any) => handleChange(selected, 'phone')}
        error={errors.phone}
      />
      {
        vat.customs && (
          <>
            <LabelStyled><Required />VAT status</LabelStyled>
            <SelectInput
              error={errors.customsData.VATStatus}
              resetError={() => {}}
              value={localState.customsData.VATStatus}
              onChange={(selected: any) => handleCustomsChange(selected, 'VATStatus')}
              data={VATStatusOptions}
              isDisabled={disabledVAT}
              height="48px"
            />
            <ErrorText>{errors.customsData.VATStatus}</ErrorText>

            {
              localState.customsData.VATStatus === 'company_vat' && (
                <>
                  <LabelStyled><Required />VAT Number</LabelStyled>
                  <Input
                    placeholder="VAT Number"
                    value={localState.customsData.VATNumber}
                    onChange={(e: any) => handleCustomsChange(e.target.value, 'VATNumber')}
                    theme={undefined}
                    type="text"
                    error={errors.customsData.VATNumber}
                    autoComplete="off"
                  />
                  <ErrorText>{errors.customsData.VATNumber}</ErrorText>

                  <LabelStyled><Required />EORI Number</LabelStyled>
                  <Input
                    placeholder="EORI Number"
                    value={localState.customsData.EORINumber}
                    onChange={(e: any) => handleCustomsChange(e.target.value, 'EORINumber')}
                    theme={undefined}
                    type="text"
                    error={errors.customsData.EORINumber}
                    autoComplete="off"
                  />
                  <ErrorText>{errors.customsData.EORINumber}</ErrorText>
                </>
              )
            }
          </>
        )
      }

      <LabelStyled>Collection reference (optional)</LabelStyled>
      <Input
        placeholder="Collection reference if required"
        value={localState.reference}
        onChange={(e: any) => handleChange(e.target.value, 'reference')}
        theme={undefined}
        type="text"
        error={false}
      />
    </>
  );
};
const H2 = styled.h2`
  font-size: 32px;
  line-height: 135%;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const SubTitle = styled.p<any>`
  margin: 20px 0;
  line-height: 150%;
`;
const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: normal;
  margin-top: 30px;
  color: #777e7e;
`;
const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: block;
  margin-top: 4px;
`;

export default Collection;
