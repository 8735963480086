import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ theme }: any) => theme.colors.Pastel2};
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  padding: 12px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 8px;
  text-align: left;
`;

export const TileWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  &:first-of-type {
    margin-bottom: 8px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Tile = styled.div.attrs(({ selected, isDisabled }: any) => ({
  selected,
  isDisabled,
}))`
  background: ${({ theme }: any) => theme.colors.White};
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  border: 2px solid ${({ theme, selected }: any) =>
    selected ? theme.colors.Secondary : theme.colors.WhiteGrey};
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  padding: 12px 42px;
  cursor: ${({ isDisabled }: any) => isDisabled ? 'not-allowed' : 'pointer'};
  width: 100%;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
`;