import React  from 'react';
import styled from 'styled-components';
import device from '../../../../utils/device';

import CollapseUp from '../../../../../assets/icons/ArrowUp.svg';
import CollapseDown from '../../../../../assets/icons/ArrowDown.svg';
import { A } from '../../../../../assets/elements';

const Collapsed = ({
  name,
  logo,
  totalExVAT,
  VAT,
  customs,
  checkForNI,
  open,
  setOpen,
}: any) => {
  return (
    <Wrapper>
      <LeftWrap>
        <Logo src={logo} alt={name} />
        <PriceRow>
          <div>
            <PriceTotal>Total</PriceTotal>
            { (!customs || checkForNI()) && (<PriceVat>(incl. VAT)</PriceVat>) }
          </div>
          <Price>
            £{ (Number(totalExVAT) + Number(VAT)).toFixed(2) }
          </Price>
        </PriceRow>
      </LeftWrap>
      <A onClick={() => setOpen(!open)}>
        <CollapseIcon src={open ? CollapseUp : CollapseDown} alt="Collapse" />
      </A>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 40px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #F2F3F2;
  box-shadow: 0 4px 6px -8px ${({ theme }: any) => theme.colors.Black};

  @media ${device.laptop} {
    display: none;
  }
`;

const LeftWrap = styled.div`
  display: flex;
`;

const Logo = styled.img`
  height: 32px;
  width: auto;
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 8px;

  &:last-of-type {
    margin-bottom: unset;
  }
`;

const PriceTotal = styled.p`
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
`;

const PriceVat = styled.p`
  color: #777e7e;
  display: inline-block;
  margin-left: 10px;
  font-size: 12px;
  margin-right: 8px;
`;

const Price = styled.p`
  font-size: 14px;
`;

const CollapseIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export default Collapsed;
