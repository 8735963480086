export const validateMobile = (value: any, error: boolean = false) => {
  if (value.replace(' ', '').length < 11) {
    if (error) {
      return `Phone number must be at least ${10 - (value?.split(' ')[0].length - 1)} characters`;
    }

    return true;
  }

  if (value.replace(' ', '').length > 14) {
    if (error) {
      return `Phone number must be less than or equal to ${13 - (value?.split(' ')[0].length - 1)} characters`;
    }

    return true;
  }

  return false;
};
