import React from 'react';
import styled from 'styled-components';
import { Container } from '../../../../../../../../assets/elements/Container';
import device from '../../../../../../../utils/device';
import { H4 } from '../../../../../../../../assets/elements/Typography/Headings';
import { PSmall } from '../../../../../../../../assets/elements/Typography/Paragraphs';

const YourOrder = () => {
  return (
    <Wrapper>
      <H4Styled>Your order</H4Styled>
      <PSmallStyled>
        Please carefully check the contents of your basket before selecting a
        payment method.
      </PSmallStyled>
      <PSmallStyled>
        If you have more deliveries to add simply go back to the beginning and
        add them. The items in this basket will still be here when you get back.
      </PSmallStyled>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  background: #ffffff;
  border-radius: 8px;
  && {
    padding: 15px;
    @media ${device.tablet} {
      padding: 30px;
    }
  }
  height: auto;

  margin-bottom: 24px;
`;

const H4Styled = styled(H4)`
  margin-bottom: 16px;
`;

const PSmallStyled = styled(PSmall)`
  &:first-of-type {
    margin-bottom: 27px;
  }
`;

// @ts-ignore
export default YourOrder;
