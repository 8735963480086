import React from 'react';
import styled from 'styled-components';

export const Switch = ({ active, onChange, disabled }: any) => {
  const handleClick = (value: any) => {
    if (!disabled && value !== active) {
      onChange(value);
    }
  };

  return (
    <Wrapper>
      <SwitchButton
        active={active}
        onClick={() => handleClick(true)}
        disabled={disabled}
      >
        On
      </SwitchButton>
      <SwitchButton
        active={!active}
        onClick={() => handleClick(false)}
        disabled={disabled}
      >
        Off
      </SwitchButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
`;

const SwitchButton = styled.a.attrs(({ active, disabled }: any) => ({
  active,
  disabled,
}))`
  display: flex;
  text-decoration: none;
  color: ${({ theme, active }: any) =>
    active ? theme.colors.White : theme.colors.DarkGrey};
  background: ${({ theme, active, disabled }: any) =>
    getBackgroundColor(theme, active, disabled)};
  border: 1px solid
    ${({ theme, active, disabled }: any) =>
    getBorderColor(theme, active, disabled)};
  padding: 14px 18px;

  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  &:last-child {
    border-radius: 0px 4px 4px 0px;
  }
`;

const getBackgroundColor = (theme: any, active: any, disabled: any) => {
  if (active) {
    if (disabled) {
      return theme.colors.DarkGrey;
    }

    return theme.colors.Primary;
  }

  return theme.colors.White;
};

const getBorderColor = (theme: any, active: any, disabled: any) => {
  if (active) {
    if (disabled) {
      return theme.colors.DarkGrey;
    }

    return theme.colors.Primary;
  }

  return theme.colors.DarkGrey;
};
