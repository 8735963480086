import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Row, Column, Button, PSmall } from '../../../../../../assets/elements';
import device from '../../../../../utils/device';
import Service from './Service';
import YourOrder from './components/YourOrder';
import OrderItem from './components/OrderItem';
import AddAnother from './components/AddAnother';
import { removeOrderFromBasket, sendBasketData } from '../../../../../store/actions';
import Modal from '../../../../../../assets/Combos/Modal';
import { landingPageUrl } from '../../../../../utils/env';
import { GTMECommerce } from '../../../../../utils/tracking';
import { getCookie, setCookie } from '../../../../../utils/cookies';

const OrderBasket = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [moreInfoModal, setMoreInfoModal] = useState<any>(false);
  const [moreInfoValue, setMoreInfoValue] = useState<any>({});
  const [moreInfoText, setMoreInfoText] = useState<any>('');

  const [removeOrderModal, setRemoveOrderModal] = useState<any>(false);
  const [selectedId, setSelectedId] = useState<any>('');
  const basketId = getCookie('basketId');

  const basket = useSelector<any, any>((state: any) => state.order.basket);

  useEffect(() => {
    if (basket.length === 0) {
      window.location.href = landingPageUrl;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basket]);

  useEffect(() => {
    basket.forEach(async (item: any) => {
      if (moment(item.item.collectionDate).diff(moment(), 'days') < 0) {
        const toRemove = basket.filter((bItem: any) => bItem.id === item.id)[0];

        GTMECommerce(
          {
            name: `${
              toRemove.item?.carrier ? `${toRemove.item.carrier} - ` : ''
            }${toRemove.item.name}`,
            id: `${toRemove.item.rateId}-${toRemove.item.serviceId}-${
              toRemove.serviceType === 'parcel'
                ? toRemove.item.type
                : toRemove.serviceType
            }`,
            price: toRemove.item.priceIncVat,
            category: toRemove.serviceType,
            variant:
              toRemove.serviceType === 'parcel'
                ? toRemove.item.type
                : 'collection',
            quantity: 1,
          },
          'removeFromCart'
        );

        dispatch(removeOrderFromBasket(item.id));
      }
    });
  }, []);

  const handleSubmit = async () => {
    await captureData();
    history.push('/payment');
  };

  const captureData = async () => {

    const dataToSend = {
      currentStep: 5,
      basket: {
        basketId:getCookie('basketId'),
        status: 'gone_to_payment',
      }
    };

    await sendBasketData(dataToSend)

      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.log(`Error sending data: ${error.message}`);
      });
  };

  const handleBack = () => {
    history.push('/order-step2');
  };

  const handleAdd = () => {
    window.location.href = landingPageUrl;
  };

  const handleRemove = () => {
    setRemoveOrderModal(false);
    const toRemove = basket.filter((item: any) => item.id === selectedId)[0];
    GTMECommerce(
      {
        name: `${toRemove.item?.carrier ? `${toRemove.item.carrier} - ` : ''}${
          toRemove.item.name
        }`,
        id: `${toRemove.item.rateId}-${toRemove.item.serviceId}-${
          toRemove.serviceType === 'parcel'
            ? toRemove.item.type
            : toRemove.serviceType
        }`,
        price: toRemove.item.priceIncVat,
        category: toRemove.serviceType,
        variant:
          toRemove.serviceType === 'parcel' ? toRemove.item.type : 'collection',
        quantity: 1,
      },
      'removeFromCart'
    );
    dispatch(removeOrderFromBasket(selectedId));
    deleteFromDatabase(toRemove);
    setSelectedId('');
  };

  const deleteFromDatabase = async (item:any) => {
    const dataToSend = {
      currentStep: 4,
      basketItemId:item.basketItemId,
      basket: {
        basketId,
        itemCount: getCookie('basket')
      }
    };
    await sendBasketData(dataToSend)
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.log(`Error sending data: ${error.message}`);
      });
  };

  const handleMoreInfoClose = () => {
    setMoreInfoModal(false);
    setMoreInfoValue({});
  };

  const openMoreInfo = () => {
    setMoreInfoModal(true);
    ReactTooltip.hide();
  };

  const openRemoveOrderModal = (id: any) => {
    setSelectedId(id);
    setRemoveOrderModal(true);
  };

  useEffect(() => {
    if (moreInfoValue.intro) {
      setMoreInfoText(
        <>
          <PSmallStyled>{moreInfoValue.intro}</PSmallStyled>
          <ul>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                ️Delivery: {moreInfoValue.deliveryDays}
              </PSmall>
            </Li>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                ️Delivery window: {moreInfoValue.deliveryWindow}
              </PSmall>
            </Li>
            {moreInfoValue.tracking && (
              <Li>
                <PSmall>
                  <span role="img" aria-label="Dot">
                    ◾
                  </span>
                  ️Detailed tracking notifications
                </PSmall>
              </Li>
            )}
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                ️{moreInfoValue.redeliveryAttempts + 1} delivery attempts
              </PSmall>
            </Li>
            {moreInfoValue.podOnRequest && (
              <Li>
                <PSmall>
                  <span role="img" aria-label="Dot">
                    ◾
                  </span>
                  ️Proof of delivery upon request
                </PSmall>
              </Li>
            )}
          </ul>
        </>
      );
    }
  }, [moreInfoValue]);

  return (
    <>
      <RowStyled>
        <ColumnStyled sizeL={6} sizeM={7} sizeS={3.5}>
          <YourOrder />
          {basket.map((item: any, i: any) => (
            <OrderItem
              key={i}
              item={item}
              onRemove={openRemoveOrderModal}
              openMoreInfo={openMoreInfo}
              setMoreInfo={setMoreInfoValue}
            />
          ))}
          <AddAnother onAdd={handleAdd} />
        </ColumnStyled>
        <ColumnStyled sizeL={4} sizeM={5} sizeS={2.5}>
          <Service
            items={basket}
            openMoreInfo={openMoreInfo}
            setMoreInfo={setMoreInfoValue}
          />
        </ColumnStyled>
      </RowStyled>
      <RowLast>
        <ColumnLast sizeL={6} sizeM={7} sizeS={3.5}>
          <Button color="black" onClick={handleBack}>
            Back
          </Button>
          <Button color="secondary" onClick={handleSubmit}>
            Go to payment
          </Button>
        </ColumnLast>
      </RowLast>
      <Modal
        title="More information"
        open={moreInfoModal}
        close={() => handleMoreInfoClose()}
        back={true}
        width={8}
      >
        {moreInfoText}
      </Modal>
      <Modal
        title="Remove from basket"
        open={removeOrderModal}
        close={() => setRemoveOrderModal(false)}
        back={false}
        width={6}
      >
        <div>
          <PSmallStyled>
            Are you sure you want to remove this item from basket?
          </PSmallStyled>
          <ButtonRow>
            <Button color="grey" onClick={() => setRemoveOrderModal(false)}>
              Cancel
            </Button>
            <Button color="secondary" onClick={handleRemove}>
              Yes
            </Button>
          </ButtonRow>
        </div>
      </Modal>
    </>
  );
};

const RowStyled = styled(Row)`
  padding-top: 32px;
  justify-content: center;
`;

const RowLast = styled(Row)`
  padding-bottom: 40px;
  justify-content: flex-start;
  margin-top: 32px;
`;

const ColumnStyled = styled(Column)`
  padding: 0px;

  @media ${device.tablet} {
    padding 0 15px;
  }
`;

const ColumnLast = styled(Column)`
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${device.tablet} {
    padding 0 15px;
  }

  @media ${device.desktop} {
    margin-left: 8.33%;
  } 
`;

const Li = styled.li`
  margin-bottom: 16px;
`;

const PSmallStyled = styled(PSmall)`
  margin-bottom: 16px;
`;

const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export default OrderBasket;
