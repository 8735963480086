import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import postalCodes from 'postal-codes-js';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { countriesPostalCodesData } from '../../../../../../mocks/quoteData';
import {
  Button,
  Input,
  Column,
  Label,
  Row,
  Caption,
} from '../../../../../../../assets/elements';

import { SelectInput } from '../../../../../Inputs';
import DimensionsInput from '../../../../../../../assets/Combos/DimensionsInput';
import device from '../../../../../../utils/device';
import { setActiveTab, setParcelValues } from '../../../../../../store/actions';
import { generateQuotesUrl } from '../../../../../../utils/quotesUrl';

const newItem = {
  length: {
    amount: '',
    units: 'cm',
  },
  width: {
    amount: '',
    units: 'cm',
  },
  height: {
    amount: '',
    units: 'cm',
  },
  weight: {
    amount: '',
    units: 'kg',
  },
};

const StepOne = (props: any) => {
  const dispatch = useDispatch();
  const parcelStore = useSelector<any, any>((state) => state.main.parcel);
  const [parcel, setParcel] = useState({
    collectionLocation: {
      country: '',
      postcode: '',
    },
    packageDetails: [
      {
        id: uuidv4(),
        length: {
          amount: '',
          units: 'cm',
        },
        width: {
          amount: '',
          units: 'cm',
        },
        height: {
          amount: '',
          units: 'cm',
        },
        weight: {
          amount: '',
          units: 'kg',
        },
      },
    ],
    deliveryLocation: {
      country: '',
      postcode: '',
    },
  });

  useEffect(() => {
    setParcel({
      ...parcel,
      ...parcelStore,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.resetValues) {
      setParcel({
        ...parcel,
        ...parcelStore,
      });
      fromCountryErrorClean();
      toCountryErrorClean();
      fromPostCodeErrorClean();
      toPostCodeErrorClean();
      setLengthError({});
      setWidthError({});
      setHeightError({});
      setWeightError({});
      props.setResetValues(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resetValues]);

  const history = useHistory();
  const [fromError, setFromError] = React.useState<any>({
    countryCodeError: false,
    postCodeError: false,
  });
  const [lengthError, setLengthError] = React.useState<any>({});
  const [widthError, setWidthError] = React.useState<any>({});
  const [heightError, setHeightError] = React.useState<any>({});
  const [weightError, setWeightError] = React.useState<any>({});
  const [toError, setToError] = React.useState<any>({
    countryCodeError: false,
    postCodeError: false,
  });

  const addNewItem = () => {
    const { packageDetails } = parcel;
    setParcel({
      ...parcel,
      packageDetails: [
        ...packageDetails,
        {
          id: uuidv4(),
          ...newItem,
        },
      ],
    });
  };

  const removeItem = (id: string) => {
    const { packageDetails } = parcel;

    const newItems = packageDetails.filter((item) => {
      return item.id !== id;
    });

    setParcel({
      ...parcel,
      packageDetails: [...newItems],
    });
  };

  const validateFrom = () => {
    let error = false;

    const result = postalCodes.validate(
      parcel.collectionLocation.country,
      parcel.collectionLocation.postcode
    );
    const postCode = parcel.collectionLocation.postcode;

    if (result && result !== true) {
      if (result.includes('Missing country code')) {
        setFromError({
          ...fromError,
          countryCodeError: result,
        });

        error = true;
      } else if (
        result.includes('Missing postal code.')
      ) {
        setFromError({
          ...fromError,
          postCodeError: 'Missing postcode',
          countryCodeError: false,
        });

        error = true;
      } else if (
        result.includes(
          `Postal code ${postCode} is not valid for country ${parcel.collectionLocation.country}`
        )
      ) {
        setFromError({
          ...fromError,
          postCodeError: 'Wrong code',
          countryCodeError: false,
        });

        error = true;
      } else if (postCode.length > 0) {
        if (isNaN(Number(postCode))) {
          setFromError({
            ...fromError,
            postCodeError: 'Wrong code',
            countryCodeError: false,
          });

          error = true;
        }
      } else {
        setFromError({
          ...fromError,
          postCodeError: false,
          countryCodeError: false,
        });

        error = false;
      }
    }
    return error;
  };

  const validateDimensions = () => {
    const { packageDetails } = parcel;
    let errorLength = false;
    let errorWidth = false;
    let errorHeight = false;
    let errorWeight = false;

    packageDetails.forEach((item) => {
      const {
        id,
        length: { amount: length },
        width: { amount: width },
        height: { amount: height },
        weight: { amount: weight },
      } = item;

      if (isNaN(Number(length))) {
        setLengthError({
          [id]: 'Wrong value',
        });

        errorLength = true;
      } else if (Number(length) <= 0) {
        setLengthError({
          [id]: 'Required',
        });

        errorLength = true;
      } else {
        delete lengthError[id];

        errorLength = false;
      }

      if (isNaN(Number(width))) {
        setWidthError({
          [id]: 'Wrong value',
        });

        errorWidth = true;
      } else if (Number(width) <= 0) {
        setWidthError({
          [id]: 'Required',
        });

        errorWidth = true;
      } else {
        delete widthError[id];

        errorWidth = false;
      }

      if (isNaN(Number(height))) {
        setHeightError({
          [id]: 'Wrong value',
        });

        errorHeight = true;
      } else if (Number(height) <= 0) {
        setHeightError({
          [id]: 'Required',
        });

        errorHeight = true;
      } else {
        delete heightError[id];

        errorHeight = false;
      }

      if (isNaN(Number(weight))) {
        setWeightError({
          [id]: 'Wrong value',
        });

        errorWeight = true;
      } else if (Number(weight) <= 0) {
        setWeightError({
          [id]: 'Required',
        });

        errorWeight = true;
      } else {
        delete weightError[id];

        errorWeight = false;
      }
    });

    return errorLength || errorWidth || errorHeight || errorWeight;
  };

  const validateTo = () => {
    let error = false;
    const result = postalCodes.validate(
      parcel.deliveryLocation.country,
      parcel.deliveryLocation.postcode
    );
    const postCode = parcel.deliveryLocation.postcode;

    if (result && result !== true) {
      if (result.includes('Missing country code')) {
        setToError({
          ...toError,
          countryCodeError: result,
        });

        error = true;
      } else if (
        result.includes('Missing postal code.')
      ) {
        setToError({
          ...toError,
          postCodeError: 'Missing postcode',
          countryCodeError: false,
        });

        error = true;
      } else if (
        result.includes(
          `Postal code ${postCode} is not valid for country ${parcel.deliveryLocation.country}`
        )
      ) {
        setToError({
          ...toError,
          postCodeError: 'Wrong code',
          countryCodeError: false,
        });

        error = true;
      } else if (postCode.length > 0) {
        if (isNaN(Number(postCode))) {
          setToError({
            ...toError,
            postCodeError: 'Wrong code',
            countryCodeError: false,
          });
          error = true;
        }
      } else {
        setToError({
          ...toError,
          postCodeError: false,
          countryCodeError: false,
        });

        error = false;
      }
    }
    return error;
  };

  const fromCountryErrorClean = () => {
    setFromError({
      ...fromError,
      countryCodeError: false,
    });
  };
  const toCountryErrorClean = () => {
    setToError({
      ...toError,
      countryCodeError: false,
    });
  };
  const fromPostCodeErrorClean = () => {
    setFromError({
      ...fromError,
      postCodeError: false,
    });
  };
  const toPostCodeErrorClean = () => {
    setToError({
      ...toError,
      postCodeError: false,
    });
  };

  const handleSubmit = () => {
    const errorFrom = validateFrom();
    const errorTo = validateTo();
    const errorDimensions = validateDimensions();

    const error = errorFrom || errorTo || errorDimensions;

    if (!error) {
      dispatch(setParcelValues(parcel));
      dispatch(setActiveTab('parcel'));

      window.location.href = generateQuotesUrl(parcel, 'parcel');
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    key: string
  ) => {
    setLengthError({});
    setWidthError({});
    setHeightError({});
    setWeightError({});

    const floats = /^[0-9.]+$/;
    const { value } = e.currentTarget;

    if (value !== '' && !value.match(floats)) return;

    const { packageDetails } = parcel;
    const newItems = packageDetails.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [key]: {
            // @ts-ignore
            ...item[key],
            amount: Number(value) || Number(''),
          },
        };
      }

      return item;
    });

    setParcel({
      ...parcel,
      packageDetails: [...newItems],
    });
  };

  const handlePostalCodeChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    toPostCodeErrorClean();
    fromPostCodeErrorClean();
    const { value } = e.currentTarget;

    setParcel({
      ...parcel,
      [key]: {
        // @ts-ignore
        ...parcel[key],
        postcode: value,
      },
    });
  };

  // @ts-ignore
  const handleSelectChange = (value, type) => {
    setParcel({
      ...parcel,
      [type]: {
        // @ts-ignore
        ...parcel[type],
        country: value,
      },
    });
  };

  return (
    <Wrapper>
      <RowStyled>
        <ColumnPad sizeL={6} sizeM={6} sizeS={6}>
          <LabelStyled>Collect from:</LabelStyled>
          <Row>
            <ColumnStyled sizeL={7} sizeM={7} sizeS={4} sizeXS={2.5}>
              <SelectInput
                title="From"
                data={countriesPostalCodesData}
                error={fromError.countryCodeError}
                resetError={fromCountryErrorClean}
                defaultValue="GB"
                value={parcel.collectionLocation.country}
                onChange={(code: string) =>
                  handleSelectChange(code, 'collectionLocation')}
              />
              <ErrorText>{fromError.countryCodeError}</ErrorText>
            </ColumnStyled>
            <ColumnStyled sizeL={5} sizeM={5} sizeS={2} sizeXS={1.5}>
              <InputStyled
                placeholder="Postcode"
                value={parcel.collectionLocation.postcode}
                onChange={(e: any) =>
                  handlePostalCodeChange(e, 'collectionLocation')}
                theme={props.theme}
                type="text"
                error={!!fromError.postCodeError}
                name="postcode_collection"
              />
              <ErrorText>{fromError.postCodeError}</ErrorText>
            </ColumnStyled>
          </Row>
        </ColumnPad>
        <ColumnPad sizeL={6} sizeM={6} sizeS={6}>
          <LabelStyled>Deliver to:</LabelStyled>
          <Row>
            <ColumnStyled sizeL={7} sizeM={7} sizeS={4} sizeXS={2.5}>
              <SelectInput
                title="To"
                actionName="SET_PARCEL_TO_COUNTRYCODE"
                data={countriesPostalCodesData}
                error={toError.countryCodeError}
                resetError={toCountryErrorClean}
                defaultValue="GB"
                value={parcel.deliveryLocation.country}
                onChange={(code: string) =>
                  handleSelectChange(code, 'deliveryLocation')}
              />
              <ErrorText>{toError.countryCodeError}</ErrorText>
            </ColumnStyled>
            <ColumnStyled sizeL={5} sizeM={5} sizeS={2} sizeXS={1.5}>
              <InputStyled
                placeholder="Postcode"
                value={parcel.deliveryLocation.postcode}
                onChange={(e: any) =>
                  handlePostalCodeChange(e, 'deliveryLocation')}
                theme={props.theme}
                type="text"
                error={!!toError.postCodeError}
                name="postcode_delivery"
              />
              <ErrorText>{toError.postCodeError}</ErrorText>
            </ColumnStyled>
          </Row>
        </ColumnPad>
      </RowStyled>
      {parcel.packageDetails.map((item: any, index: any) => (
        <RowStyled key={item.id}>
          <DimensionsInput
            parcel={item}
            onChange={(e: any, id: any, key: any) => handleChange(e, id, key)}
            theme={props.theme}
            addNewItem={addNewItem}
            removeItem={removeItem}
            totalItems={parcel.packageDetails.length}
            index={index}
            lengthError={lengthError[item.id]}
            widthError={widthError[item.id]}
            heightError={heightError[item.id]}
            weightError={weightError[item.id]}
          />
        </RowStyled>
      ))}
      <RowStyled>
        <ButtonStyled color="secondary" block onClick={handleSubmit}>
          {props.onQuotesPage ? 'Update results' : 'Get an instant quote'}
        </ButtonStyled>
      </RowStyled>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
`;
const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
`;

const ColumnStyled = styled(Column)`
  padding: 0;
  position: relative;
`;

const RowStyled = styled(Row)`
  margin-bottom: 0;
`;

const InputStyled = styled(Input)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const ColumnPad = styled(Column)`
  padding: 0;
  margin-bottom: 24px;

  @media ${device.laptop} {
    padding: 0 15px;
    margin-bottom: 24px;
  }
`;

const ErrorText = styled(Caption)`
  color: ${({ theme: { colors } }: any) => colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;
const ButtonStyled = styled(Button)`
  && {
    font-family: Montserrat, sans-serif !important;
  }
`;

export default StepOne;
