import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import postalCodes from 'postal-codes-js';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { countriesPostalCodesData } from '../../../../../../mocks/quoteData';
import {
  setActiveTab,
  setPallet as setPalletStore,
} from '../../../../../../store/actions';

import { SelectInput } from '../../../../../Inputs';

import CheckBoxInput from '../../../../../Inputs/CheckBoxInput';
import {
  Row,
  Column,
  Label,
  Caption,
  Input,
  Button,
  Error,
  A,
} from '../../../../../../../assets/elements';

import device from '../../../../../../utils/device';

import PalletSizes from './components/PalletSizes';
import { generateQuotesUrl } from '../../../../../../utils/quotesUrl';
import CustomDimensions from './components/CustomDimensions';
import { v4 as uuidv4 } from 'uuid';

const StepOne = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const palletStore: any = useSelector<any>((state) => state.main.pallet);
  const [pallet, setPallet] = useState({
    collectionLocation: {
      country: '',
      postcode: '',
    },
    packageDetails: {
      mini: {
        palletSizeId: 1,
        quantity: '',
      },
      quarter: {
        palletSizeId: 2,
        quantity: '',
      },
      half: {
        palletSizeId: 3,
        quantity: '',
      },
      light: {
        palletSizeId: 5,
        quantity: '',
      },
      full: {
        palletSizeId: 6,
        quantity: '',
      },
    },
    palletAndWrap: false,
    deliveryLocation: {
      country: '',
      postcode: '',
    },
    dims: [
      {
        id: '',
        weight: '',
        length: '',
        width: '',
        height: '',
        quantity: '',
      },
    ],
  });

  const [showCustomDimensions, setShowCustomDimensions] = useState(
    props.customDimensions
  );

  const [fromError, setFromError] = React.useState<any>({
    countryCodeError: false,
    postCodeError: false,
  });
  const [toError, setToError] = React.useState<any>({
    countryCodeError: false,
    postCodeError: false,
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [quotesDisabled, setQuotesDisabled] = useState(false);

  useEffect(() => {
    setPallet({
      ...pallet,
      ...palletStore,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.resetValues) {
      setPallet({
        ...pallet,
        ...palletStore,
      });
      fromCountryErrorClean();
      toCountryErrorClean();
      fromPostCodeErrorClean();
      toPostCodeErrorClean();
      sizesImagesErrorClean();

      props.setResetValues(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resetValues]);

  useEffect(() => {
    //reset errors
    setErrorMessage('');

    let error = '';
    for (const [key, value] of Object.entries(pallet.packageDetails)) {
      //check if error exists
      if (error === '') {
        error = validateQuantity(value.quantity);
      } else {
        break;
      }
    }
    error !== '' ? setErrorMessage(error) : setQuotesDisabled(false);
  }, [pallet]);

  const validateQuantity = (value: any) => {
    let error = '';
    if (value !== '' && isNaN(Number(value))) {
      error = 'Quantity must be a number';
    } else if (Number(value) > 26) {
      error = 'Maximum quantity is 26';
    } else if (value !== '' && Number(value) < 1) {
      error = 'Quantity cannot be less than 1';
    }

    if (error !== '') {
      setQuotesDisabled(true);
    }
    return error;
  };

  const validateFrom = () => {
    let error = false;

    const result = postalCodes.validate(
      pallet.collectionLocation.country,
      pallet.collectionLocation.postcode
    );
    const postCode = pallet.collectionLocation.postcode;

    if (result && result !== true) {
      if (result.includes('Missing country code')) {
        setFromError({
          ...fromError,
          countryCodeError: result,
        });

        error = true;
      } else if (result.includes('Missing postal code.')) {
        setFromError({
          ...fromError,
          postCodeError: 'Missing postcode',
          countryCodeError: false,
        });

        error = true;
      } else if (
        result.includes(
          `Postal code ${postCode} is not valid for country ${pallet.collectionLocation.country}`
        )
      ) {
        setFromError({
          ...fromError,
          postCodeError: 'Wrong code',
          countryCodeError: false,
        });

        error = true;
      } else if (postCode.length > 0) {
        if (isNaN(Number(postCode))) {
          setFromError({
            ...fromError,
            postCodeError: 'Wrong code',
            countryCodeError: false,
          });

          error = true;
        }
      } else {
        setFromError({
          ...fromError,
          postCodeError: false,
          countryCodeError: false,
        });

        error = false;
      }
    }
    return error;
  };

  const validateTo = () => {
    let error = false;
    const result = postalCodes.validate(
      pallet.deliveryLocation.country,
      pallet.deliveryLocation.postcode
    );
    const postCode = pallet.deliveryLocation.postcode;

    if (result && result !== true) {
      if (result.includes('Missing country code')) {
        setToError({
          ...toError,
          countryCodeError: result,
        });

        error = true;
      } else if (result.includes('Missing postal code.')) {
        setToError({
          ...toError,
          postCodeError: 'Missing postcode',
          countryCodeError: false,
        });

        error = true;
      } else if (
        result.includes(
          `Postal code ${postCode} is not valid for country ${pallet.deliveryLocation.country}`
        )
      ) {
        setToError({
          ...toError,
          postCodeError: 'Wrong code',
          countryCodeError: false,
        });

        error = true;
      } else if (postCode.length > 0) {
        if (isNaN(Number(postCode))) {
          setToError({
            ...toError,
            postCodeError: 'Wrong code',
            countryCodeError: false,
          });
          error = true;
        }
      } else {
        setToError({
          ...toError,
          postCodeError: false,
          countryCodeError: false,
        });

        error = false;
      }
    }
    return error;
  };

  const validateSizesImages = () => {
    const { mini, quarter, half, light, full } = pallet.packageDetails;

    if (
      !mini.quantity &&
      !quarter.quantity &&
      !half.quantity &&
      !light.quantity &&
      !full.quantity
    ) {
      setErrorMessage('Enter quantity for at least one pallet size.');
      return true;
    }

    return false;
  };

  const validateCustomInputs = () => {
    let error = false;
    pallet.dims.forEach((item: any) => {
      if (
        !item.weight ||
        !item.length ||
        !item.width ||
        !item.height ||
        !item.quantity
      ) {
        error = true;
      }
    });
    if (error) {
      setErrorMessage('Please fill in all package details.');
    } else {
      setErrorMessage('');
    }

    return error;
  };

  const fromCountryErrorClean = () => {
    setFromError({
      ...fromError,
      countryCodeError: false,
    });
  };
  const fromPostCodeErrorClean = () => {
    setFromError({
      ...fromError,
      postCodeError: false,
    });
  };
  const toCountryErrorClean = () => {
    setToError({
      ...toError,
      countryCodeError: false,
    });
  };
  const toPostCodeErrorClean = () => {
    setToError({
      ...toError,
      postCodeError: false,
    });
  };
  const sizesImagesErrorClean = () => {
    if (errorMessage === '') {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = () => {
    const errorFrom = validateFrom();
    const errorTo = validateTo();
    if (showCustomDimensions) {
      const errorInput = validateCustomInputs();
      if (!(errorFrom || errorTo || errorInput)) {
        dispatch(setPalletStore(pallet));
        dispatch(setActiveTab('pallet'));
        window.location.href = generateQuotesUrl(pallet, 'dims');
      }
    } else {
      const errorSize = validateSizesImages();
      if (!(errorFrom || errorTo || errorSize)) {
        dispatch(setPalletStore(pallet));
        dispatch(setActiveTab('pallet'));
        window.location.href = generateQuotesUrl(pallet, 'pallet');
      }
    }
  };

  const handleSelectChange = (value: any, type: any) => {
    setPallet({
      ...pallet,
      [type]: {
        // @ts-ignore
        ...pallet[type],
        country: value,
      },
    });
  };

  const handlePostalCodeChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    toPostCodeErrorClean();
    fromPostCodeErrorClean();
    const { value } = e.currentTarget;

    setPallet({
      ...pallet,
      [key]: {
        // @ts-ignore
        ...pallet[key],
        postcode: value,
      },
    });
  };

  const handleCheckboxChange = () => {
    setPallet({
      ...pallet,
      palletAndWrap: !pallet.palletAndWrap,
    });
  };

  const handleSizeChange = (e: any, key: any) => {
    const numbers = /^[0-9]+$/;
    const { value } = e.target;

    sizesImagesErrorClean();

    setPallet({
      ...pallet,
      packageDetails: {
        ...pallet.packageDetails,
        [key]: {
          // @ts-ignore
          ...pallet.packageDetails[key],
          quantity: value,
        },
      },
    });
  };

  const handleCustomDimensionsSwitch = () => {
    setPallet({
      ...pallet,
      packageDetails: {
        mini: {
          palletSizeId: 1,
          quantity: '',
        },
        quarter: {
          palletSizeId: 2,
          quantity: '',
        },
        half: {
          palletSizeId: 3,
          quantity: '',
        },
        light: {
          palletSizeId: 5,
          quantity: '',
        },
        full: {
          palletSizeId: 6,
          quantity: '',
        },
      },
      dims: [
        {
          id: uuidv4(),
          weight: '',
          length: '',
          width: '',
          height: '',
          quantity: '1',
        },
      ],
    });
    setErrorMessage('');
    setShowCustomDimensions(!showCustomDimensions);
  };

  return (
    <Wrapper>
      <RowStyled>
        <ColumnPad sizeL={5} sizeM={5} sizeS={6}>
          <LabelStyled>Collect from:</LabelStyled>
          <Roww>
            <ColumnStyled sizeL={7} sizeM={6} sizeS={4} sizeXS={2.5}>
              <SelectInput
                title="From"
                data={countriesPostalCodesData}
                error={fromError.countryCodeError}
                resetError={fromCountryErrorClean}
                value={pallet.collectionLocation.country}
                onChange={(code: string) =>
                  handleSelectChange(code, 'collectionLocation')
                }
              />
              <ErrorText>{fromError.countryCodeError}</ErrorText>
            </ColumnStyled>
            <ColumnStyled sizeL={5} sizeM={6} sizeS={2} sizeXS={1.5}>
              <InputStyled
                placeholder="Postcode"
                value={pallet.collectionLocation.postcode}
                onChange={(e: any) =>
                  handlePostalCodeChange(e, 'collectionLocation')
                }
                theme={props.theme}
                type="text"
                error={!!fromError.postCodeError}
                name="postcode_collection"
              />
              <ErrorText>{fromError.postCodeError}</ErrorText>
            </ColumnStyled>
          </Roww>
        </ColumnPad>
        <ColumnPad sizeL={5} sizeM={5} sizeS={6}>
          <LabelStyled>Deliver to:</LabelStyled>
          <Row>
            <ColumnStyled sizeL={7} sizeM={6} sizeS={4} sizeXS={2.5}>
              <SelectInput
                title="To"
                actionName="SET_PARCEL_TO_COUNTRYCODE"
                data={countriesPostalCodesData}
                error={toError.countryCodeError}
                resetError={toCountryErrorClean}
                value={pallet.deliveryLocation.country}
                onChange={(code: string) =>
                  handleSelectChange(code, 'deliveryLocation')
                }
              />
              <ErrorText>{toError.countryCodeError}</ErrorText>
            </ColumnStyled>
            <ColumnStyled sizeL={5} sizeM={6} sizeS={2} sizeXS={1.5}>
              <InputStyled
                placeholder="Postcode"
                value={pallet.deliveryLocation.postcode}
                onChange={(e: any) =>
                  handlePostalCodeChange(e, 'deliveryLocation')
                }
                theme={props.theme}
                type="text"
                error={!!toError.postCodeError}
                name="postcode_delivery"
              />
              <ErrorText>{toError.postCodeError}</ErrorText>
            </ColumnStyled>
          </Row>
        </ColumnPad>
      </RowStyled>
      {showCustomDimensions ? (
        <CustomDimensions
          theme={props.theme}
          setQuotesDisabled={setQuotesDisabled}
          handleCustomDimensionsSwitch={handleCustomDimensionsSwitch}
          validateQuantity={validateQuantity}
          pallet={pallet}
          setPallet={setPallet}
        />
      ) : (
        <PalletSizes
          packageDetails={pallet.packageDetails}
          onSizeChange={handleSizeChange}
          error={false}
        />
      )}
      <div className="RowStyled">
        {!showCustomDimensions &&
        (pallet.collectionLocation.country !== 'GB' ||
          pallet.deliveryLocation.country !== 'GB') ? (
          <Column sizeL={12} sizeS={6}>
            <WarningMessage>
              We recommend using exact dimensions for international shipments, 
              as this will allow us to provide you with our most competitive quote.
            </WarningMessage>
          </Column>
        ) : null}
      </div>
      <ErrorAndSwitchWrapper emptyError={errorMessage === ''}>
        {errorMessage !== '' ? (
          <ErrorMessageStyled>{`${errorMessage}`}</ErrorMessageStyled>
        ) : null}
        <CustomDimensionsSwitch onClick={() => handleCustomDimensionsSwitch()}>
          {showCustomDimensions
            ? null
            : 'I know the exact dimensions of my pallet'}
        </CustomDimensionsSwitch>
      </ErrorAndSwitchWrapper>
      <RowMore>
        <Row>
          {/* <ColumnPallet sizeL={12} sizeS={6}>
            <CheckBoxInput
              checked={pallet.palletAndWrap}
              label="I need a pallet"
              onChange={handleCheckboxChange}
            />
          </ColumnPallet> */}
        </Row>
        <RowStyled>
          <Column sizeL={12} sizeS={6}>
            <ButtonStyled
              color="secondary"
              block
              onClick={handleSubmit}
              disabled={quotesDisabled}
              disable={quotesDisabled}
            >
              {props.onQuotesPage ? 'Update results' : 'Get an instant quote'}
            </ButtonStyled>
          </Column>
        </RowStyled>
      </RowMore>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
`;

const RowStyled = styled(Row)`
  margin-bottom: 0;
  justify-content: flex-start;
`;

const ColumnPad = styled(Column)`
  padding: 0;
  margin-bottom: 24px;

  @media ${device.laptop} {
    padding: 0 15px;
    margin-bottom: 24px;
  }
`;

const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
`;

const ColumnStyled = styled(Column)`
  padding: 0;
  position: relative;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

const InputStyled = styled(Input)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const ColumnPallet = styled(Column)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

const ButtonStyled = styled(Button)`
  && {
    font-family: Montserrat, sans-serif !important;
  }
`;
const RowMore = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 24px;
`;

const ErrorAndSwitchWrapper = styled(Column)<{ emptyError: boolean }>`
  ${({ emptyError }) => {
    if (emptyError) {
      return 'justify-content: flex-end;';
    } else {
      return 'justify-content: space-between;';
    }
  }}
  display: flex;
  align-items: baseline;
`;

const CustomDimensionsSwitch = styled(A)`
  margin-top: 15px;
  float: right;
  display: flex;
`;

const ErrorMessageStyled = styled.p`
  color: ${({ theme }: any) => theme.colors.Error};
`;

const WarningMessage = styled.p`
  color: ${({ theme }: any) => theme.colors.Error};
  margin-top: 10px;
`;

const Roww = styled.div`
  box-sizing: border-box;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 1440px) {
    max-width: 1140px;
  }
`;

export default StepOne;
