import React from 'react';
import styled from 'styled-components';

const ColumnWrapper = ({ heading = '', children }: any) => {
  return (
    <Wrapper>
      {
        heading !== '' && <Heading>{ heading }</Heading>
      }
      { children }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  padding: 24px;
  border-radius: 6px;
`;

const Heading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  letter-spacing: 0.01em;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 16px;
`;

export default ColumnWrapper;
