import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { A } from '../../../../../../assets/elements';
import PostcodeAddress from '../../../../PostcodeAddress/PostcodeAddress';
import ManualAddress from '../../../../ManualAddress/ManualAddress';
import SavedAddress from '../../../../SavedAddress/SavedAddress';
import { setCollectionDetails } from '../../../../../store/actions';
import CheckBoxInput from '../../../../Inputs/CheckBoxInput';
import ColumnWrapper from '../../Elements/ColumnWrapper';

const addressTypeConstants = {
  POSTCODE: 'postcode',
  MANUAL: 'manual',
  SAVED: 'saved',
};

const addressErrorKeys = [
  'addressOne',
  'city',
  'country',
  'zip',
];

const CollectionAddress = ({
  setDone,
  defAddress,
  deliveryType,
  addressData,
  addressErrors,
  setAddressData,
  setAddressErrors,
  addToAddressBook,
  setAddToAddressBook,
  resetServices,
}: any) => {
  const dispatch = useDispatch();

  const details = useSelector((state: any) => state.order.collectionDetails);

  const [addressType, setAddressType] = useState<any>(addressTypeConstants.POSTCODE);

  const [detailsComplete, setDetailsComplete] = useState(false);

  useEffect(() => {
    if (deliveryType === 'outbound') {
      setAddressData({
        ...addressData,
        ...defAddress,
        country: defAddress.country !== '' ?
          defAddress.country : addressData.country
      });
    } else {
      setAddressData({
        company: '',
        addressOne: '',
        addressTwo: '',
        addressThree: '',
        city: '',
        country: 'GB',
        zip: '',
      });
    }
  }, [deliveryType]);

  useEffect(() => {
    let done = true;

    addressErrorKeys.forEach((key: any) => {
      if (addressData[key] === '') {
        done = false;
      }
    });

    setDetailsComplete(done);
  }, [addressData]);

  useEffect(() => {
    setDone(detailsComplete);
  }, [detailsComplete]);

  const handleMobileChange = (value: any) => {
    dispatch(setCollectionDetails({
      ...details,
      phone: value,
      name: '',
    }));
  };

  const handleCheckboxChange = () => {
    setAddToAddressBook(!addToAddressBook);
  };

  return (
    <>
      <ColumnWrapper heading="Address">
        <>
          {
            addressType === addressTypeConstants.POSTCODE && (
              <>
                <PostcodeAddress
                  address={addressData}
                  setAddress={setAddressData}
                  errors={addressErrors}
                  setErrors={setAddressErrors}
                  country={true}
                />
                <AddressBookRow>
                  <CheckBoxInput
                    checked={addToAddressBook}
                    label="Add to address book"
                    onChange={handleCheckboxChange}
                  />
                </AddressBookRow>
              </>
            )
        }
          {
          addressType === addressTypeConstants.MANUAL && (
            <>
              <ManualAddress
                address={addressData}
                setAddress={setAddressData}
                errors={addressErrors}
                setErrors={setAddressErrors}
                wrapper={false}
              />
              <AddressBookRow>
                <CheckBoxInput
                  checked={addToAddressBook}
                  label="Add to address book"
                  onChange={handleCheckboxChange}
                />
              </AddressBookRow>
            </>
          )
        }
          {
          addressType === addressTypeConstants.SAVED && (
            <SavedAddress
              address={addressData}
              setAddress={setAddressData}
              setMobileNumber={handleMobileChange}
            />
          )
        }

          <LinksRow>
            {
            addressType !== addressTypeConstants.SAVED && (
            <LinkButton
              onClick={() => setAddressType(addressTypeConstants.SAVED)}
            >
              Select from address book
            </LinkButton>
            )
          }
            {
            addressType !== addressTypeConstants.POSTCODE && (
            <LinkButton
              onClick={() => setAddressType(addressTypeConstants.POSTCODE)}
            >
              Search by postcode
            </LinkButton>
            )
          }
            {
            addressType !== addressTypeConstants.MANUAL && (
            <LinkButton
              onClick={() => setAddressType(addressTypeConstants.MANUAL)}
            >
              Enter manually
            </LinkButton>
            )
          }
          </LinksRow>
        </>
      </ColumnWrapper>
    </>
  );
};

const LinksRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const LinkButton = styled(A)`
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
`;

const AddressBookRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 32px;
`;

export default CollectionAddress;
