import React from 'react';
import LandingPageQuoteQuote from '../LandingPageQuote/Quote';
import DeliveryPartners from '../DeliveryPartners';
import TrackingSection from '../TrackingSection';

const Main: React.FC = () => {
  return (
    <>
      <LandingPageQuoteQuote />
      <DeliveryPartners />
      <TrackingSection />
    </>
  );
};

export default Main;
