import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import KriyaIcon from '../../../../../../../../../../assets/icons/Kriya.png';
import CheckBoxInput from '../../../../../../../../Inputs/CheckBoxInput';

const BuyNowPayLater = ({ value, handleChange, acceptedKriyaStatement }: any) => {
  return (
    <Wrapper>
      <Heading>What is this?</Heading>
      <p><strong>Only available to Limited companies with a GB Registration number.</strong></p>
      <br />
      <p>Credit limits may change and subject to Kriya terms and conditions. A credit check will be performed against your company</p>
      <br />
      <p>By clicking "Place Order" you will be redirected to Kriya's website who will determine eligibility, you will need to provide your company registration number.</p>
      <br />
      <CheckBoxInput
        checked={acceptedKriyaStatement}
        label="I have a company registration number and am acting on behalf of the company in question."
        onChange={handleChange}
      />
      <br />
      <p>Finance provided by <a href=""><img height="25" src={KriyaIcon} alt="Kriya" /></a></p>
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs(({ error = false }: any) => ({
  error
}))`
  display: block;
  margin-top: 16px;
  padding: 17px 11px;
  background: ${({ error }: any) => error ? '#F9DBE0' : '#f2f2f2'};;
  color: ${({ theme, error }: any) => error ? theme.colors.Error : theme.colors.Black};
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  border-radius: 4px;
`;

const Heading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 16px;
`;
export default BuyNowPayLater;
