import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import LoadContainer from './LoadContainer';
import { PSmall, A } from '../../../../../../../../assets/elements';
import { SelectInput } from '../../../../../../Inputs';
import LCLItems from './LCLItems';
import InfoIcon from '../../../../../../../../assets/icons/InfoIcon';
import PackageTypes from '../../../Modals/PackageTypes';
import device from '../../../../../../../utils/device';

const values = [
  {
    label: 'Pallets',
    value: 'pallet',
  },
  {
    label: 'Boxes',
    value: 'box',
  },
  {
    label: 'Crates',
    value: 'crate',
  },
];

const LessThanFullLoad = ({
  onClick,
  selected,
  value,
  onSubTypeChange,
  items,
  errors,
  addNewItem,
  removeItem,
  handleChange,
  setDisabled,
  subType,
}: any) => {
  const [selectValue, setSelectValue] = useState<any>('pallet');
  const [packageTypesModal, setPackageTypesModal] = useState(false);

  const select = useRef(null);

  useEffect(() => {
    setSelectValue(subType);
  }, [subType]);

  const handleTypeChange = (val: any) => {
    setSelectValue(val);
    onSubTypeChange(val);
  };

  return (
    <>
      <LoadContainer
        onClick={onClick}
        selected={selected}
        type="less"
      >
        <Wrapper>
          <MainRow>
            <Title>Less than a Container Load</Title>
            <SelectAndIcon>
              <SelectInput
                innerKey={value}
                innerRef={select}
                title="Quantity"
                data={values}
                error={false}
                resetError={() => {}}
                value={selectValue}
                onChange={handleTypeChange}
                radius="4px"
              />
              <Icon onClick={() => setPackageTypesModal(true)}><InfoIcon /></Icon>
            </SelectAndIcon>
          </MainRow>
          <LCLItems
            items={items}
            errors={errors}
            removeItem={removeItem}
            addNewItem={addNewItem}
            handleChange={handleChange}
            show={selected}
            setDisabled={setDisabled}
            subType={subType}
          />
        </Wrapper>
      </LoadContainer>
      <PackageTypes
        open={packageTypesModal}
        close={() => setPackageTypesModal(false)}
      />
    </>
  );
};

const Wrapper = styled.div`
  
`;

const SelectAndIcon = styled.div`
  display: flex;
  padding-left: 35px;
  
  @media ${device.laptop} {
    padding-left: 0;
  }
`;

const MainRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;

  @media ${device.laptop} {
    flex-direction: row;
    align-items: center;
  }
`;

const Title = styled(PSmall)`
  font-weight: 600;
  display: block;
  margin-right: 16px;
  padding-left: 35px;
`;

const Icon = styled(A)`
  margin-left: 24px;
`;

export default LessThanFullLoad;
