/*
 * M: Mobile devices
 * L: Larger devices (Laptop/Tablet)
 *
 * FS: Font size
 * */

export const staging = {
  Primary: '#da5d71',
  Secondary: '#2aceb6',
};
