import React from 'react';
import { withTheme } from 'styled-components';

const InfoIcon = ({
  theme,
  width = 20,
  height = 20,
}: any) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 14V9" stroke={theme.colors.Secondary} />
      <path d="M10 6V7" stroke={theme.colors.Secondary} />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.99935 18.3334C14.6017 18.3334 18.3327 14.6024 18.3327 10C18.3327 5.39765 14.6017 1.66669 9.99935 1.66669C5.39698 1.66669 1.66602 5.39765 1.66602 10C1.66602 14.6024 5.39698 18.3334 9.99935 18.3334Z" stroke={theme.colors.Secondary} />
    </svg>
  );
};

export default withTheme(InfoIcon);
