import React from 'react';
import styled from 'styled-components';
import Cell30 from '../Elements/Cell30';
import Cell20 from '../Elements/Cell20';
import device from '../../../../../utils/device';

const Header = () => {
  return (
    <Wrapper>
      <Cell30>
        <Text>Invoice Number</Text>
      </Cell30>
      <Cell20>
        <Text>Date</Text>
      </Cell20>
      <Cell20>
        <Text>Total Amount</Text>
      </Cell20>
      <Cell20>
        <Text>Status</Text>
      </Cell20>
      <Cell10Styled>
        <Text>Download</Text>
      </Cell10Styled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: none;
  padding: 0 24px;
  width: 100%;
  margin-bottom: 5px;
  flex-wrap: wrap;

  @media ${device.laptop} {
    display: flex;
  }
`;

const Text = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
`;

const Cell10Styled = styled.div`
  display: none;
  width: 10%;
  padding: 16px 0;

  @media ${device.laptopL} {
    display: flex;
  }
`;

export default Header;
