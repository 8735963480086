import {
  SET_USER_LOGGED,
  SET_USER_SESSION,
  RESET_USER_SESSION,
  BACK_TO_TOKEN_EXPIRED,
  SET_CURRENT_QUICKBOOK_STEP,
} from '../actionConstants';
import { getCookie } from '../../utils/cookies';

const initState: any = {
  logged: false,
  userDetails: {
    name: '',
  },
  session: {
    userId: getCookie('userId') || '',
    userName: getCookie('userName') || '',
    refreshToken: getCookie('refreshToken') || '',
    accessToken: getCookie('accessToken') || '',
  },
  backToTokenExpired: false,
  currentStep: 1,
};

const userReducer = (state = initState, { type, payload }: any): any => {
  switch (type) {
    case SET_USER_LOGGED:
      return {
        ...state,
        logged: payload,
      };
    case SET_USER_SESSION:
      return {
        ...state,
        session: {
          ...state.session,
          ...payload,
        },
      };
    case RESET_USER_SESSION:
      return {
        ...state,
        session: {
          userId: '',
          userName: '',
          refreshToken: '',
          accessToken: '',
        },
      };
    case BACK_TO_TOKEN_EXPIRED:
      return {
        ...state,
        backToTokenExpired: payload,
      };
    case SET_CURRENT_QUICKBOOK_STEP:
      return {
        ...state,
        currentStep: payload,
      };
    default:
      return state;
  }
};

export default userReducer;
