import React from 'react';
import PalletMini from '../../../../../../../../../assets/images/pallet-mini.png';
import PalletQuarter from '../../../../../../../../../assets/images/pallet-quarter.png';
import PalletHalf from '../../../../../../../../../assets/images/pallet-half.png';
import PalletLight from '../../../../../../../../../assets/images/pallet-light.png';
import PalletFull from '../../../../../../../../../assets/images/pallet-full.png';
import { Row } from '../../../../../../../../../assets/elements';
import SingleSize from './SingleSize';

const palletData = [
  {
    id: 1,
    title: 'Mini',
    subtext: 'Up to 150kg',
    image: PalletMini,
  },
  {
    id: 2,
    title: 'Quarter',
    subtext: 'Up to 250kg',
    image: PalletQuarter,
  },
  {
    id: 3,
    title: 'Half',
    subtext: 'Up to 500kg',
    image: PalletHalf,
  },
  {
    id: 5,
    title: 'Light',
    subtext: 'Up to 750kg',
    image: PalletLight,
  },
  {
    id: 6,
    title: 'Full',
    subtext: 'Up to 1000kg',
    image: PalletFull,
  },
];

const PalletSizes = ({ onSizeChange, packageDetails, error }: any) => {
  return (
    <Row>
      {palletData.map((pallet: any) => {
        return (
          <SingleSize
            key={pallet.id}
            pallet={pallet}
            value={packageDetails[pallet.title.toLowerCase()].quantity || ''}
            onSizeChange={onSizeChange}
            error={error}
          />
        );
      })}
    </Row>
  );
};

export default PalletSizes;
