import styled from 'styled-components';
import device from '../../../app/utils/device';

interface ColumnProps {
  sizeXS?: number;
  sizeS?: number;
  sizeM?: number;
  sizeL?: number;
}

const columnSize = {
  Desktop: 12,
  Laptop: 12,
  Tablet: 6,
  Mobile: 4,
};

export const Column = styled.div`
  padding: 0 15px;
  position: relative;
  flex: 0 0
    ${(props: any) =>
    props.sizeXS ? `${100 * (props.sizeXS / columnSize.Mobile)}%` : '100%'};
  max-width: ${(props: any) =>
    props.sizeXS ? `${100 * (props.sizeXS / columnSize.Mobile)}%` : '100%'};

  @media ${device.laptop} {
    flex: 0 0
      ${(props: any) =>
    props.sizeS
      ? `${100 * (props.sizeS / columnSize.Tablet)}%`
      : `${100 * (props.sizeM / columnSize.Laptop)}%`};
    max-width: ${(props: any) =>
    props.sizeS
      ? `${100 * (props.sizeS / columnSize.Tablet)}%`
      : `${100 * (props.sizeM / columnSize.Laptop)}%`};
  }

  @media ${device.laptopL} {
    flex: 0 0
      ${(props: any) =>
    props.sizeM
      ? `${100 * (props.sizeM / columnSize.Laptop)}%`
      : `${100 * (props.sizeL / columnSize.Desktop)}%`};
    max-width: ${(props: any) =>
    props.sizeM
      ? `${100 * (props.sizeM / columnSize.Laptop)}%`
      : `${100 * (props.sizeL / columnSize.Desktop)}%`};
  }

  @media ${device.desktop} {
    flex: 0 0
      ${(props: ColumnProps) =>
    props.sizeL ? `${100 * (props.sizeL / columnSize.Desktop)}%` : '100%'};
    max-width: ${(props: ColumnProps) =>
    props.sizeL ? `${100 * (props.sizeL / columnSize.Desktop)}%` : '100%'};
  }
`;
