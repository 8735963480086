import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SettingBox from '../SettingBox';
import { Button, Caption, Input } from '../../../../../../assets/elements';
import device from '../../../../../utils/device';
import PasswordCriteria from '../../../../PasswordCriteria';
import { updateUserPassword } from '../../../../../utils/APICalls/Account';

const PasswordSetting = ({ theme }: any) => {
  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
  });
  const [error, setError] = useState<any>({
    oldPassword: '',
    newPassword: '',
  });
  const [disabled, setDisabled] = useState<any>(true);
  const [saving, setSaving] = useState(false);

  const [focus, setFocus] = React.useState(false);
  const [criteriaMeet, setCriteriaMeet] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
    minimum: false,
  });

  useEffect(() => {
    if (passwords.oldPassword !== '' && passwords.newPassword !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [passwords]);

  const handlePasswordChange = (e: any, key: any) => {
    const { value } = e.target;

    setError({
      ...error,
      [key]: false,
    });
    setPasswords({
      ...passwords,
      [key]: value,
    });
  };

  const validateCriteria = () => {
    for (const [, value] of Object.entries(criteriaMeet)) {
      if (!value) {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!validateCriteria()) {
      setError({
        ...error,
        newPassword: 'Invalid password',
      });

      return;
    }

    setSaving(true);
    setDisabled(true);

    updateUserPassword({ ...passwords })
      .catch(() => {
        setError({
          ...error,
          oldPassword: 'Incorrect password',
        });
      })
      .finally(() => {
        setSaving(false);
        setPasswords({
          oldPassword: '',
          newPassword: '',
        });
      });
  };

  return (
    <SettingBox title="Password">
      <PasswordContainer>
        <InputContainer>
          <Label>Current password</Label>
          <Input
            theme={theme}
            value={passwords.oldPassword}
            type="password"
            placeholder="Current password"
            onChange={(e: any) => handlePasswordChange(e, 'oldPassword')}
            error={error.oldPassword}
          />
          {error.oldPassword && <ErrorText>{error.oldPassword}</ErrorText>}
        </InputContainer>
        <InputContainer
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        >
          <Label>New password</Label>
          <Input
            theme={theme}
            value={passwords.newPassword}
            type="password"
            placeholder="New password"
            onChange={(e: any) => handlePasswordChange(e, 'newPassword')}
            error={error.newPassword}
          />
          {error.newPassword && <ErrorText>{error.newPassword}</ErrorText>}
          <PasswordCriteria
            password={passwords.newPassword}
            active={focus}
            criteriaMeet={criteriaMeet}
            setCriteriaMeet={setCriteriaMeet}
          />
        </InputContainer>
      </PasswordContainer>

      <div>
        <ButtonStyled
          color="secondary"
          disable={disabled}
          disabled={disabled}
          onClick={handleSubmit}
        >
          {saving ? 'Saving...' : 'Save changes'}
        </ButtonStyled>
      </div>
    </SettingBox>
  );
};

const ButtonStyled = styled(Button)`
  white-space: nowrap;
`;

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
  position: relative;

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.laptopL} {
    width: 48%;
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  margin-bottom: 6px;
`;

const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;

  @media ${device.laptop} {
    width: 40%;
    margin-bottom: 0;
  }

  @media ${device.laptopL} {
    width: 65%;
    flex-direction: row;
  }
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

export default PasswordSetting;
