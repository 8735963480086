import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import device from '../../utils/device';
import { Button, Column, Container, H4, Row } from '../../../assets/elements';
import { landingPageUrl } from '../../utils/env';
import { useQuery } from '../../utils/hooks/useQuery';
import Loader from '../Loader';
import { verifyEmail } from '../../utils/APICalls/Account';
import { logo } from '../../../assets/styles/themes';

const EmailVerified = () => {
  const history = useHistory();
  const query = useQuery();

  const [verifying, setVerifying] = useState(true);

  useEffect(() => {
    const token = query.get('token');

    verifyEmail({ token })
      .then(() => {
        setVerifying(false);
      })
      .catch(() => {
        history.push('/token-expired');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToHome = () => {
    window.location.href = landingPageUrl;
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <Column sizeL={4} sizeM={4} sizeS={3} sizeXS={4}>
            <ImageWrapper>
              <Image src={logo} alt="Site logo" />
            </ImageWrapper>
            <H4Styled>
              {verifying ? (
                <Loader>Verifying</Loader>
              ) : (
                'Your email has been verified!'
              )}
            </H4Styled>
            {!verifying && (
              <ButtonWrapper>
                <Button color="black" onClick={goToHome}>
                  Back to Home
                </Button>
              </ButtonWrapper>
            )}
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background: ${({ theme }: any) => theme.colors.GrayWhite};
  min-height: calc(100vh - 76px);
  display: flex;
  justify-content: center;
  padding: 32px 0;

  @media ${device.laptop} {
    padding: 48px 0;
  }
`;

const ImageWrapper = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  height: 40px;
  margin-bottom: 48px;
`;

const H4Styled = styled(H4)`
  text-align: center;
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export default EmailVerified;
