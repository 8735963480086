import React from 'react';
import styled from 'styled-components';
import { H6 } from '../../../../../../assets/elements';
import device from '../../../../../utils/device';

const SettingBox = ({ title, children }: any) => {
  return (
    <Wrapper>
      <H6Styled>{title}</H6Styled>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }: any) => theme.colors.White};
  padding: 32px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 1px;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const H6Styled = styled(H6)`
  margin-bottom: 24px;
`;

const ChildrenWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

export default SettingBox;
