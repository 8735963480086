import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { Label } from '../../../../../../assets/elements';

const QuoteTab = ({
  current,
  Icon,
  label,
  url = '',
  path = '',
  theme,
}: any) => {
  return url !== '' ? (
    <TabWrapper to={url} active={current}>
      <Icon color={current ? theme.colors.White : theme.colors.MidGrey} />
      <LabelStyled>{label}</LabelStyled>
    </TabWrapper>
  ) : (
    <TabWrapperExt href={path} active={current}>
      <Icon color={current ? theme.colors.White : theme.colors.MidGrey} />
      <LabelStyled>{label}</LabelStyled>
    </TabWrapperExt>
  );
};

const TabWrapper = styled(Link).attrs(({ active }: any) => ({
  active,
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 25px;
  color: ${({ theme, active }: any) =>
    active ? theme.colors.White : theme.colors.MidGrey};
  cursor: pointer;
  align-items: center;
`;

const TabWrapperExt = styled.a.attrs(({ active }: any) => ({
  active,
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 25px;
  color: ${({ theme, active }: any) =>
    active ? theme.colors.White : theme.colors.MidGrey};
  cursor: pointer;
  align-items: center;
  text-decoration: unset;

  &:hover {
    color: ${({ theme, active }: any) =>
    active ? theme.colors.White : theme.colors.MidGrey};
  }
`;

const LabelStyled = styled(Label)`
  margin-top: 7px;
`;

export default withTheme(QuoteTab);
