import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { H6, PSmall } from '../../../assets/elements';

const MessageBanner = () => {
  let message = '';
  const now = moment();
  if (now.isBefore(moment('2021-12-23'))) {
    message = 'Last posting date for Christmas will be 22/12. There will be no collection or delivery on Christmas Eve.';
  } else if (now.isBefore(moment('2021-12-30'))) {
    message = 'Christmas delivery has now closed. Your next available collection dates are 29/12 and 30/12.';
  } else if (now.isBefore(moment('2022-01-03'))) {
    message = 'There will be no collection available between 31/12 - 03/01. Your next available date will be Tuesday 4th January.';
  }
  if (message) {
    return (
      <Wrapper>
        <StyledText>{message}</StyledText>
      </Wrapper>
    );
  } else {
    return null
  }
};

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }: any) => theme.colors.White};
  border-radius: 9px;
  border: 2px solid ${({ theme }: any) => theme.colors.Secondary};
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

const StyledH6 = styled(H6)`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 34px;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const StyledText = styled(PSmall)`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }: any) => theme.colors.Black};
`;

export default MessageBanner;
