const learnMoreStyle = (theme) => ({
  color: theme.SecondaryColor,
});

export const cookieConsent = (theme) => {
  let domain = '';

  if (origin.includes('egistics.pwiltsher')) {
    domain = 'egistics.pwiltsher.com';
  } else if (origin.includes('collectmyparcel')) {
    domain = 'collectmyparcel.com';
  } else if (origin.includes('pallets4delivery')) {
    domain = 'pallets4delivery.com';
  } else if (origin.includes('p4d')) {
    domain = 'p4d.co.uk';
  } else if (origin.includes('mpddev')) {
    domain = 'mpddev.e-gistics.com';
  } else if (origin.includes('myparceldelivery')) {
    domain = 'myparceldelivery.com';
  } else if (origin.includes('action-roadways')) {
    domain = 'action-roadways.co.uk';
  } else if (origin.includes('auctiondeliveries')) {
    domain = 'auctiondeliveries.com';
  }

  // @ts-ignore
  window.cookieconsent.initialise({
    palette: {
      popup: {
        background: theme.colors.White,
        text: theme.colors.Black,
        link: theme.colors.Secondary,
      },
      button: {
        background: theme.colors.Secondary,
        text: theme.colors.White
      }
    },
    position: 'bottom-left',
    type: 'opt-in',
    content: {
      message: 'This website stores data such as cookies to enable necessary site functionality, including analytics, targeting, and personalization.',
      allow: 'Accept all',
      href: 'https://www.collectmyparcel.com/other/privacy-policy',
    },
    elements: {
      deny: '<a aria-label="deny cookies" tabindex="0" class="cc-btn cc-deny deny-cookies">Decline</a>',
    },
    revokable: false,
    law: {
      regionalLaw: false,
    },
    revokeBtn: '<div class=”cc-revoke”></div>',
    cookie: {
      name: 'cookie_consent',
      domain,
    },
  });
};
