import React from 'react';
import styled from 'styled-components';

import { useHistory } from 'react-router';
import { Button } from '../../../../assets/elements';
import device from '../../../utils/device';
import DropDownMenu from './DropDownMenu';

const UserNav = ({ logged }: any) => {
  const history = useHistory();

  const handleLogIn = () => {
    history.push('/login');
  };

  const handleRegister = () => {
    history.push('/register');
  };

  return (
    <Wrapper>
      {logged ? (
        <DropDownMenu />
      ) : (
        <ButtonWrapper>
          <ButtonStyled onClick={handleLogIn}>Log in</ButtonStyled>
          <ButtonStyled onClick={handleRegister} color="secondary">
            Sign up
          </ButtonStyled>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: none;

  @media ${device.laptop} {
    display: flex;
  }
  justify-content: space-between;
  flex-direction: row;
  width: 221px;
`;
const ButtonWrapper = styled.div`
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
`;
const ButtonStyled = styled(Button)`
  padding: 10px 14px;
  border-width: 2px;
  font-weight: 600;
  line-height: 125%;
  margin-right: 10px;
`;

export default UserNav;
