import { environment, Environments } from './env';

let key: string;

if (environment.toUpperCase() === Environments.DEV) {
  key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV || '';
} else if (environment.toUpperCase() === Environments.STAGING) {
  key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV || '';
} else if (environment.toUpperCase() === Environments.ARW) {
  key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_ARW || '';
} else if (environment.toUpperCase() === Environments.MPD) {
  key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_MPD || '';
} else if (environment.toUpperCase() === Environments.P4D) {
  key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_P4D || '';
} else {
  key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_PROD || '';
}

export const StripeAPIKey = key;
