import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button, H4, PSmall } from '../../../../../assets/elements';
import BackArrow from '../../../../../assets/icons/Left-back.svg';
import NotFound from '../../../NotFound';
import Modal from '../../../../../assets/Combos/Modal';
import AddAddress from './components/AddAddress';
import Loader from '../../../Loader';
import { deleteAddress, getAddresses, makeAddressDefault } from '../../../../utils/APICalls/Account';
import AddressRow from './components/AddressRow';

const AddressBook = () => {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [editAddress, setEditAddress] = useState<any>({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<any>(false);
  const [deleting, setDeleting] = useState<any>(false);
  const [makingDefault, setMakingDefault] = useState(false);

  useEffect(() => {
    setLoading(true);

    loadAddresses();
  }, []);

  const loadAddresses = () => {
    getAddresses()
      .then((res: any) => {
        setAddresses(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleModalClose = (refresh: any = false) => {
    if (refresh) {
      loadAddresses();
    }
    setEditAddress({});
    setModal(false);
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleEditAddress = (address: any) => {
    setEditAddress({ ...address });
    setModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteId(false);
    setDeleteModal(false);
  };

  const triggerDelete = (id: any) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  const deleteAddressAction = () => {
    setDeleting(true);
    deleteAddress(deleteId)
      .then(() => {
        loadAddresses();
        handleDeleteModalClose();
        setDeleting(false);
      })
      .catch(() => {
        setDeleting(false);
        handleDeleteModalClose();
      });
  };

  const triggerDefault = (add: any) => {
    setMakingDefault(true);
    makeAddressDefault({ ...add, isDefault: true }, add.id)
      .then(() => {
        loadAddresses();
        setMakingDefault(false);
      })
      .catch(() => {
        setMakingDefault(false);
      });
  };

  return (
    <Wrapper>
      <Title>
        <H4Styled>
          <BackIcon onClick={handleBack} src={BackArrow} alt="Back" />
          Address Book
        </H4Styled>
        <ButtonStyled
          color="black"
          onClick={() => setModal(true)}
        >
          Add address
        </ButtonStyled>
      </Title>
      {
        loading && <Loader>Loading</Loader>
      }
      {
        !loading && (
          addresses.length === 0 ? (
            <NotFound>No addresses found.</NotFound>
          ) : (
            <>
              {
                addresses.map((address: any, i: any) => (
                  <AddressRow
                    address={address}
                    key={i}
                    setEditAddress={handleEditAddress}
                    triggerDelete={triggerDelete}
                    triggerDefault={triggerDefault}
                    makingDefault={makingDefault}
                  />
                ))
              }
            </>
          )
        )
      }

      <Modal
        title={editAddress?.id ? 'Edit address' : 'Add address'}
        open={modal}
        close={() => handleModalClose(false)}
        back={false}
        width={6}
      >
        <AddAddress
          close={() => handleModalClose(true)}
          editAddress={editAddress}
          id={editAddress?.id || false}
          full={!!editAddress?.id}
        />
      </Modal>

      <Modal
        title="Delete address"
        open={deleteModal}
        close={() => handleDeleteModalClose()}
        back={false}
        width={6}
      >
        <PSmall>
          Are you sure you want to delete this address?
        </PSmall>
        <DeleteButtons>
          <Button
            color="black"
            onClick={() => handleDeleteModalClose()}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={() => deleteAddressAction()}
            disable={deleting}
            disabled={deleting}
          >
            {
              deleting ? 'Deleting...' : 'Delete'
            }
          </Button>
        </DeleteButtons>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const H4Styled = styled(H4)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (min-width: 380px) {
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const BackIcon = styled.img`
  margin-right: 24px;
  width: 24px;
  height: 24px;
`;

const ButtonStyled = styled(Button)`
  padding: 10px 14px;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  border-width: 2px;
  margin-left: auto;
`;

const DeleteButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

export default AddressBook;
