import React from 'react';
import styled from 'styled-components';
import { getCountryFromCode } from '../../../../../../../mocks/quoteData';
import { A } from '../../../../../../../../assets/elements';

const CollapsedShipmentItem = ({ items, edit, customs }: any) => {
  return (
    <Wrapper>
      <Line>
        <LineHeading>Item:</LineHeading>
        {
          items.map((item: any, i: any) => (
            <>
              { item.description }
              {
                customs && (
                  <>
                    x { item.quantity }{ i !== items.length - 1 && ', '}
                  </>
                )
              }
            </>
          ))
        }
      </Line>
      {
        customs && (
          <Line>
            <LineHeading>Manufactured in</LineHeading>
            {
              items.map((item: any, i: any) => (
                <>{ getCountryFromCode(item.manufactured_in) }{ i !== items.length - 1 && ', '} </>
              ))
            }
          </Line>
        )
      }
      {
        customs && (
          <Line>
            <LineHeading>Tariff Code</LineHeading>
            {
              items.map((item: any, i: any) => (
                <>{ item.tariff_code }{ i !== items.length - 1 && ', '} </>
              ))
            }
          </Line>
        )
      }
      {
        customs ? (
          <Line>
            <LineHeading>Unit weight and value</LineHeading>
            {
              items.map((item: any, i: any) => (
                <>
                  {
                    (item.weight !== '' || item.value !== '') && (
                      <>
                        {item.weight !== '' && `${item.weight}kg`}
                        {item.value !== '' && `${item.weight !== '' && ', '}£${item.value}`}
                        {i !== items.length - 1 && ', '}
                      </>
                    )
                  }
                </>
              ))
            }
          </Line>
        ) : (
          <Line>
            <LineHeading>Total value</LineHeading>
            {
              items.map((item: any, i: any) => (
                <>
                  {
                    (item.value !== '') && (
                      <>
                        £{item.value}
                        {i !== items.length - 1 && ', '}
                      </>
                    )
                  }
                </>
              ))
            }
          </Line>
        )
      }

      <EditButton onClick={edit}>Edit</EditButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Line = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const LineHeading = styled.p`
  font-weight: 600;
  margin-right: 6px;
`;

const EditButton = styled(A)`
  margin-top: 26px;
  text-align: left;
  color: ${({ theme }: any) => theme.colors.Primary};
`;

export default CollapsedShipmentItem;
