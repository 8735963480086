import moment from 'moment';
import { getCookie } from './cookies';


export const checkRefreshToken = () => !!getCookie('refreshToken');
export const checkAccessToken = () => !!getCookie('accessToken');

export const checkAccessTokenExpiry = () => {
  const expiry = getCookie('accessTokenExpiry');

  if (!expiry) return false;

  return moment(new Date(Number(expiry) * 1000).toUTCString()).diff(moment(), 'minutes');
};

export const checkRefreshTokenExpiry = () => {
  const expiry = getCookie('refreshTokenExpiry');

  if (!expiry) return false;

  return moment(new Date(Number(expiry) * 1000).toUTCString()).diff(moment(), 'minutes');
};