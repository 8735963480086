import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Wrapper, Title, Tile, TileWrapper } from './elements';
import { getUnavailableDates } from '../../../../../utils/APICalls/UnavailableDates';

const CollectionDates = ({
  collectionDate,
  onChange,
  selected,
  isDisabled,
  country,
}: any) => {
  const [dates, setDates] = useState<any>([]);
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    getUnavailableDates(country)
      .then((res: any) => {
        setHolidays(res.data.map((holidayDate: any) => new Date(holidayDate)));
      });
  }, [country]);

  const generateCollection = (date: any) => {
    let days = 6;
    const exclude = [0, 6, 7];
    const datesList: any[] = [];
    const tempDate = moment(date).subtract(1, 'days');

    while (days > 0) {
      const newDate = tempDate.add(1, 'days');

      if (
        !exclude.includes(newDate.isoWeekday()) ||
        holidays.filter((holiday: any) => moment(holiday).isSame(moment(newDate))).length === 0
      ) {
        datesList.push(newDate.format('yyyy-MM-DD'));
        days -= 1;
      }
    }

    return datesList;
  };

  useEffect(() => {
    setDates(generateCollection(collectionDate));
  }, [collectionDate, holidays]);

  const handleClick = (value: any) => {
    if (isDisabled) {
      return;
    }

    onChange(value);
  };

  return (
    <Wrapper>
      <Title>Collection date</Title>

      <TileWrapper>
        {
          dates.map((date: any, i: any) => (
            <Tile
              key={i}
              onClick={() => handleClick(date)}
              selected={date === selected}
              isDisabled={isDisabled}
            >
              { date }
            </Tile>
          ))
        }
      </TileWrapper>
    </Wrapper>
  );
};

export default CollectionDates;
