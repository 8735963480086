import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import cloneDeep from 'lodash.clonedeep';
import {
  A,
  Container,
  H4,
  PSmall,
} from '../../../../../../../../assets/elements';
import device from '../../../../../../../utils/device';
import ShipmentItem from './ShipmentItem';
import InfoIconImage from '../../../../../../../../assets/icons/Info-green.svg';
import InfoIcon from '../../../../../../../../assets/icons/InfoIcon';
import TariffModal from './TariffModal';
import { checkProhibited } from '../../../../../../../utils/APICalls/Order';
import FreightItem from './FreightItem';
import ProhibitedItems from './ProhibitedItems';

const ShipmentDetails = ({
  shipment,
  onChange,
  onFreightChange,
  onNumPalletsChange,
  itemsLength,
  errors,
  vat,
  deliveryCountry,
  prohibitedErrors,
  setProhibitedErrors,
  serviceType,
  numPallets,
  addItem,
  removeItem,
  copyToAll,
  inputCount,
  container
}: any) => {
  const [tariffModal, setTariffModal] = useState(false);
  const [totalValue, setTotalValue] = useState(0);
  const [grossWeight, setGrossWeight] = useState(0);
  const [weightError, setWeightError] = useState<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [copyToAllCheck, setCopyToAllCheck] = useState(false);

  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const tempData: any = [];

    for (let i = numPallets; i >= 1; i--) {
      tempData.push({
        label: `${i} ${container ? 'container' : 'pallet'}${i > 1 ? 's' : ''}`,
        value: i,
      });
    }

    setData(tempData);
  }, []);

  useEffect(() => {
    if (serviceType === 'freight') {
      return;
    }

    shipment.length > 0 &&
      setTotalValue(
        shipment.reduce(
          (acc: any, ship: any) =>
            Number(acc) +
            ship.items.reduce((acc2: any, item: any) => {
              return (
                Number(acc2) + Number(item.value || 0) * (item?.quantity || 1)
              );
            }, 0),
          0
        )
      );
  }, [shipment]);

  useEffect(() => {
    if (serviceType === 'freight') {
      return;
    }

    if ((vat?.customs || false) && shipment.length > 0) {
      const error: any = {};
      let totalWeight = 0;

      shipment.forEach((ship: any) => {
        let weight = 0;
        let emptyWeight = false;

        ship.items.forEach((item: any) => {
          if (
            (serviceType === 'parcel' || serviceType === 'seaFreight') &&
            item.weight === ''
          ) {
            emptyWeight = true;
          } else {
            weight += Number(item.weight) * Number(item.quantity || 1);
          }
        });

        totalWeight += weight;

        if (
          (serviceType === 'parcel' || serviceType === 'seaFreight') &&
          !emptyWeight &&
          Number(ship.weight.amount) !== weight
        ) {
          error[ship.id] = true;
        }
      });

      setGrossWeight(totalWeight);
      setWeightError(error);
    }
  }, [shipment]);

  const openTariff = (e: any) => {
    e.preventDefault();

    setTariffModal(true);
  };

  const handleBlur = (e: any, shipmentId: any, id: any) => {
    const { value } = e.target;

    if (value === '') {
      const tempErrors1 = cloneDeep(prohibitedErrors);

      tempErrors1[shipmentId][id] = {
        prohibited: false,
        restricted: false,
      };

      setProhibitedErrors(tempErrors1);
      return;
    }

    checkProhibited({ word: value }).then((res: any) => {
      const tempErrors = cloneDeep(prohibitedErrors);

      tempErrors[shipmentId][id] = {
        prohibited: res.data === null ? false : res.data.isProhibited,
        restricted: res.data === null ? false : res.data.isRestricted,
      };

      setProhibitedErrors(tempErrors);
    });
  };

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleCheckboxChange = () => {
    setCopyToAllCheck(!copyToAllCheck);
  };

  return (
    <Wrapper>
      <H4>Your shipment</H4>
      <PSmallStyled>
        You have requested booking for {itemsLength} item
        {itemsLength === 0 || itemsLength > 1 ? 's' : ''}. Please add a brief
        description of the item{itemsLength === 0 || itemsLength > 1 ? 's' : ''}{' '}
        and an estimate of it's value.
      </PSmallStyled>
      <LinkContainer>
        <A onClick={handleClick}>
          <Icon>
            <InfoIcon />
          </Icon>
          About prohibited items
        </A>
      </LinkContainer>
      <ProhibitedItems open={modalOpen} setOpen={setModalOpen} />

      {vat.customs &&
        (deliveryCountry === 'US' ? (
          <TariffBanner green={totalValue !== 0 && totalValue <= vat.gbp_vat}>
            {totalValue !== 0 && totalValue <= vat.gbp_vat ? (
              <span>
                Great news! Since your items are less than ${vat.local_vat} (£
                {vat.gbp_vat}), your recipient won't have to pay any import tax.
              </span>
            ) : (
              <span>
                {totalValue === 0 ? (
                  <span>
                    If your items are valued above ${vat.local_vat} USD (£
                    {vat.gbp_vat}), we strongly recommend adding a{' '}
                    <AStyled onClick={openTariff}>Tariff Code</AStyled> to avoid
                    delays in customs.
                  </span>
                ) : (
                  <span>
                    Your items are valued above ${vat.local_vat} USD (£
                    {vat.gbp_vat}), we strongly recommend adding a{' '}
                    <AStyled onClick={openTariff}>Tariff Code</AStyled> to avoid
                    delays in customs.
                  </span>
                )}
              </span>
            )}
          </TariffBanner>
        ) : (
          <TariffCopy>
            <TariffIcon src={InfoIconImage} alt="Tariff info" />
            <TariffText>
              We recommend adding a <A onClick={openTariff}>Tariff Code</A> to
              avoid delays in customs.
            </TariffText>
          </TariffCopy>
        ))}
      {shipment.map((ship: any, i: any) => (
        <div key={i}>
          {serviceType === 'freight' && (!vat?.customs || false) ? i + 1 > inputCount ? null : (
            <FreightItem
              shipment={ship}
              onChange={onFreightChange}
              errors={errors}
              onNumPalletsChange={onNumPalletsChange}
              key={`f-${i}`}
              data={data}
              container={container}
            />
          ) : (
            <ShipmentItem
              shipment={ship}
              length={shipment.length}
              onChange={onChange}
              errors={errors}
              weightError={!!weightError[ship.id]}
              i={i}
              key={i}
              customs={vat.customs}
              handleBlur={handleBlur}
              prohibited={prohibitedErrors[ship.id] || {}}
              serviceType={serviceType}
              addItem={addItem}
              removeItem={removeItem}
              copyToAll={copyToAll}
              deliveryCountry={deliveryCountry}
              copyToAllCheck={copyToAllCheck}
              handleCheckboxChange={handleCheckboxChange}
              numPallets={numPallets}
            />
          )}
        </div>
      ))}
      {!(serviceType === 'freight') && (
        <TotalValueContainer>
          <span>Total Value</span>
          <TotalValue>£{totalValue.toFixed(2)}</TotalValue>
        </TotalValueContainer>
      )}
      {!(serviceType === 'freight') && (vat?.customs || false) && (
        <TotalValueContainer>
          <span>Gross Weight</span>
          <TotalValue>{grossWeight}kg</TotalValue>
        </TotalValueContainer>
      )}
      {(vat?.customs || false) && (
        <TariffModal open={tariffModal} setOpen={setTariffModal} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  background: #ffffff;
  border-radius: 8px;
  && {
    padding: 15px;
    @media ${device.tablet} {
      padding: 30px;
    }
  }
  height: auto;
  margin-bottom: 16px;

  @media ${device.laptop} {
    margin-bottom: 24px;
  }
`;

const PSmallStyled = styled(PSmall)`
  margin-top: 16px;
`;

const TariffCopy = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 16px;
`;

const TariffIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const TariffText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const TariffBanner = styled.div.attrs(({ green }: any) => ({
  green,
}))`
  margin-top: 16px;
  padding: 8px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${({ theme, green }: any) =>
    green ? theme.colors.Primary : '#F4732A'};
  background: ${({ green }: any) => (green ? '#EEF4E2' : '#FEE6D0')};
`;

const AStyled = styled(A)`
  color: #f4732a;
  text-decoration: underline;
`;

const TotalValueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
`;

const TotalValue = styled.span`
  font-size: 19px;
  font-weight: 500;
  line-height: 29px;
`;

const Icon = styled.div`
  display: flex;
  margin-right: 10px;
`;

const LinkContainer = styled.div`
  margin: 24px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  & > button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

export default ShipmentDetails;
