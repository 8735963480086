import React from 'react';
import styled from 'styled-components';
import device from '../../utils/device';
import {
  Button,
  Column,
  Container,
  H4,
  PSmall,
  Row,
} from '../../../assets/elements';
import { landingPageUrl } from '../../utils/env';
import { logo } from '../../../assets/styles/themes';

const RegisterConfirm = () => {
  const handleClick = () => {
    window.location.href = landingPageUrl;
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <Column sizeL={4} sizeM={4} sizeS={3} sizeXS={4}>
            <ImageWrapper>
              <Image src={logo} alt="Site logo" />
            </ImageWrapper>
            <H4Styled>Check out your email</H4Styled>
            <SubTitle>
              A confirmation email has been sent to your inbox
            </SubTitle>
            <ButtonWrapper>
              <Button color="black" onClick={handleClick}>
                Back to Home
              </Button>
            </ButtonWrapper>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background: ${({ theme }: any) => theme.colors.GrayWhite};
  min-height: calc(100vh - 76px);
  display: flex;
  justify-content: center;
  padding: 32px 0;

  @media ${device.laptop} {
    padding: 48px 0;
  }
`;

const ImageWrapper = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  height: 40px;
  margin-bottom: 48px;
`;

const H4Styled = styled(H4)`
  text-align: center;
  margin-bottom: 16px;
`;

const SubTitle = styled(PSmall)`
  text-align: center;
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export default RegisterConfirm;
