import styled from 'styled-components';
import device from '../../../app/utils/device';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 15px;
  margin: 0 auto;

  @media ${device.laptop} {
    max-width: 720px;
    padding: 0;
  }

  @media ${device.laptopL} {
    max-width: 992px;
  }

  @media (min-width: 1240px) {
    max-width: 1140px;
  }

  @media ${device.desktopL} {
    max-width: 1140px;
  }
`;
