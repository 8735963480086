import React from 'react';
import styled from 'styled-components';
import { H6, PSmall, A } from '../../../../../../assets/elements';
import Modal from '../../../../../../assets/Combos/Modal';

const Stackable = ({
  open,
  close,
}: any) => {
  return (
    <Modal
      open={open}
      close={close}
      width={6}
      title="Stackable Cargo"
      TitleElement={H6}
    >
      <PSmall>
        Stackable cargo are considered those,
        where it is possible to safely and securely stack other items on top of another.
        This ensures an efficient use of the space available within a truck or container.
        <br />
        If the goods are non stackable, there will be an additional charge applied to the shipment.
        Goods may be deemed as non-stackable due their content or shape,
        the packaging they are housed in or if marked with ‘DO NOT STACK’ instructions.
      </PSmall>
      <SubText>
        N.B – This will not be applicable for road freight.
      </SubText>
      <MoreInfo>
        View more information
      </MoreInfo>
    </Modal>
  );
};

const SubText = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }: any) => theme.colors.MidGrey};
  margin-top: 24px;
`;

const MoreInfo = styled(A)`
  margin-top: 31px;
  text-align: left;
  padding-left: 0;
`;

export default Stackable;
