import React from 'react';
import { components } from 'react-select';
import DownIcon from '../../../../assets/icons/Down.svg';
import UpIcon from '../../../../assets/icons/Up.svg';

export const DropdownIndicator = (props: any) => {
  const {
    selectProps: { menuIsOpen = false },
  } = props;
  return (
    <components.DropdownIndicator {...props}>
      <img src={menuIsOpen ? UpIcon : DownIcon} alt="down" />
    </components.DropdownIndicator>
  );
};
