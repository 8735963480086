import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { A, Row, Column, Label, Input, Button } from '../../../../../assets/elements';
import device from '../../../../utils/device';
import { countriesPostalCodesData } from '../../../../mocks/quoteData';
import { submitManualPalletQuote } from '../../../../store/actions';
import { GTMLead } from '../../../../utils/tracking';
import containerTypes from '../../../../utils/containerTypes.json';

const ManualQuoteRequest = ({type, customDimensions }: any) => {

  const shipment = useSelector<any, any>((state) => state.main[type]);
  const quoteId = useSelector<any, any>((state) => state.main.quoteId);
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const [noQuoteInformation, setNoQuoteInformation] = useState({
    request: shipment.data,
    name:'',
    company:'',
    email: '',
    phone: '',
    quoteId: '',
    submitted: false,
    type
  });

  const transformToName = (countryCode: string) => {
    const selected = countriesPostalCodesData.find(
      (element) => element.code === countryCode
    );
    return selected && selected.name;
  };

  const noQuoteCollection = {
    country: transformToName(shipment.collectionLocation.country),
    postcode: shipment.collectionLocation.postcode,
  };

  const noQuoteDelivery = {
    country: transformToName(shipment.deliveryLocation.country),
    postcode: shipment.deliveryLocation.postcode,
  };

  const getShippingString = () => {
    const shippingArray: string[] = [];
    if (type === 'pallet') {
      if (customDimensions) {
        shipment.dims.forEach((item: any) => {
          shippingArray.push(
            `${item.length}x${item.width}x${item.height}cm(${item.weight}kg,Qty:${item.quantity})`
          );
        });
      } else {
        Object.keys(shipment.packageDetails).forEach((size: any) => {
          const palletSize = shipment.packageDetails[size];
          if (palletSize && palletSize.quantity && palletSize.quantity > 0) {
            shippingArray.push(
              `${palletSize.quantity}x ${size} pallet${
                palletSize.quantity !== 1 ? 's' : ''
              }`
            );
          }
        });
      }
    }

    if (type === 'freight') {
      if (shipment.loadType === 'FTL' || shipment.loadType === 'LTL') return `${shipment.packageDetails.numPallets} x Pallet${
        shipment.packageDetails.numPallets !== 1 ? 's' : ''}`;
      if (containerTypes.includes(shipment.loadType)) return `${shipment.packageDetails.numPallets} x ${shipment.loadType} Container${
        shipment.packageDetails.numPallets !== 1 ? 's' : ''}`;
    }

    if (type === 'parcel') {
      shipment.packageDetails.forEach((parcelSize: any) => {
        if (parcelSize) {
          const parcelDims = `${parcelSize.height.amount}x${parcelSize.length.amount}x${parcelSize.width.amount}cm,${parcelSize.weight.amount}kg`;
          shippingArray.push(`${parcelDims}`);
        }
      });
    }

    if (shippingArray.length > 1) {
      const lastItem = shippingArray.pop();
      return shippingArray.join(', ') + ` and ${lastItem}.`;
    } else {
      return shippingArray.join(', ');
    }
  };

  const handleNoQuoteChange = (fieldName: string, e: any) => {
    if (!noQuoteInformation.request) {
      noQuoteInformation.request = shipment;
      noQuoteInformation.quoteId = quoteId;
    }

    const value = e.currentTarget.value;

    if (fieldName === 'email') {
      if (value.length > 100) {
        e.currentTarget.value = value.substring(0, 100);
      }
    } else if (fieldName === 'phone') {
      if (value.length > 20) {
        e.currentTarget.value = value.substring(0, 20);
      }
    }

    const data: any = noQuoteInformation;
    data[fieldName] = value;
    setNoQuoteInformation(data);
  };

  const handleNoQuoteSubmit = (e: any) => {
    if (
      !noQuoteInformation.email.match(
        // eslint-disable-next-line no-useless-escape
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/
      )
    ) {
      alert('Please check email.');
      return false;
    }


    // make phone number mandotory

    if (
      !noQuoteInformation.phone.match(
        // eslint-disable-next-line no-useless-escape
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
      )
    ) {
      alert('Please check phone number.');
      return false;
    }

    if(!noQuoteInformation.name) {
      alert('Please enter name.');
      return false;
    }
    setDisabled(true);
    dispatch(submitManualPalletQuote(noQuoteInformation));
    GTMLead(75);
    setNoQuoteInformation({ ...noQuoteInformation, submitted: true });
  };

  return (
    <>
      <Wrapper>
        <Row>
          <ColumnStyled>
            <H2>We are on it!</H2>
            <SubTitle>
              Thank you for your request. We want to give you the best price for your shipment, 
              so in this instance we have presented the details you have provided straight to 
              one of our logistics specialists. Our team have access to realtime wholesale 
              pricing and know the best options to meet your needs.
            </SubTitle>
            <SubTitle>
              The good news is we can be back to you typically within 15 minutes (in office hours) 
              with your quote. Sometimes we might need a little more information to ensure you 
              are presented with the best option to meet your needs. If so, we will give you a quick call!
            </SubTitle>
            <SubTitleBold>
              Summary of Your Interest
            </SubTitleBold>
            <RowStyled>
              <ColumnStyled sizeL={4} sizeM={4}>
                <PStyled>Collection from:</PStyled>
                <PStyled>{noQuoteCollection.country}</PStyled>
                <PStyled>{noQuoteCollection.postcode}</PStyled>
              </ColumnStyled>
              <ColumnStyled sizeL={4} sizeM={4}>
                <PStyled>Delivery to:</PStyled>
                <PStyled>{noQuoteDelivery.country}</PStyled>
                <PStyled>{noQuoteDelivery.postcode}</PStyled>
              </ColumnStyled>
              <ColumnStyled sizeL={4} sizeM={4}>
                <PStyled>Shipping:</PStyled>
                <PStyled>{getShippingString()}</PStyled>
              </ColumnStyled>
            </RowStyled>
            <SubTitle>
              So, if you can just fill in your details below – we will be right back to you with your quote!
            </SubTitle>
          </ColumnStyled>
        </Row>
        <Row>
          <ColumnStyled sizeL={3} sizeS={3}>
            <LabelStyled>Name:</LabelStyled>
            <Input
              placeholder="Name"
              onChange={(e: any) => handleNoQuoteChange('name', e)}
              type="text"
              name="name"
              disabled={disabled}
            />
          </ColumnStyled>
          <ColumnStyled sizeL={3} sizeS={3}>
            <LabelStyled>Company (optional):</LabelStyled>
            <Input
              placeholder="Company"
              onChange={(e: any) => handleNoQuoteChange('company', e)}
              type="text"
              name="company"
              disabled={disabled}
            />
          </ColumnStyled>
          <ColumnStyled sizeL={3} sizeS={3}>
            <LabelStyled>Email Address:</LabelStyled>
            <Input
              placeholder="Email"
              onChange={(e: any) => handleNoQuoteChange('email', e)}
              type="text"
              name="email"
              disabled={disabled}
            />
          </ColumnStyled>
          <ColumnStyled sizeL={3} sizeS={3}>
            <LabelStyled>Phone:</LabelStyled>
            <Input
              placeholder="Phone"
              onChange={(e: any) => handleNoQuoteChange('phone', e)}
              type="text"
              name="phone"
              disabled={disabled}
            />
          </ColumnStyled>
          <ColumnStyled sizeL={4} sizeS={3}>
            <LabelStyled>&nbsp;</LabelStyled>
            <ButtonStyled color="secondary" block disable={disabled} disabled={disabled} onClick={handleNoQuoteSubmit}>
              Request Manual Quote
            </ButtonStyled>
            {disabled? <SubTitleCentered>Thank you, Your request has been sent!</SubTitleCentered>:null}
          </ColumnStyled>
          <ColumnStyled>
            <SubTitle>
              {/* Or, if you have any questions or just want a bit more expert advice, 
              give us a call on <ButtonNoResults>01245 955617</ButtonNoResults>. */}
              {/* <br />  */}
              Business hours are Monday to Friday: 9am to 5pm.
            </SubTitle>
          </ColumnStyled>
        </Row>
      </Wrapper>
    </>
  );
};

const ButtonNoResults = styled(A)``;

const RowStyled = styled(Row)`
  margin-top: 20px;
  margin-bottom: 0;
  justify-content: flex-start;
`;

const ButtonStyled = styled(Button)`
  && {
    font-family: Montserrat, sans-serif !important;
  }
`;

const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: normal;
  color: #777e7e;
`;

const H2 = styled.h2`
  font-size: 32px;
  line-height: 135%;
  color: #777e7e;
`;

const SubTitle = styled.p<any>`
  margin: 20px 0;
  line-height: 150%;
  color: #777e7e;
`;

const PStyled = styled.p<any>`
  line-height: 150%;
  color: #777e7e;
`;

const ColumnStyled = styled(Column)`
  padding: 3px;

  @media ${device.laptop} {
    padding: 3px 15px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  background: ${({ theme }: any) => theme.colors.White};
  margin: 16px 0;
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};
`;

const SubTitleBold = styled(SubTitle)`
  font-weight: bold;
`;

const SubTitleCentered = styled(SubTitleBold)`
  text-align: center;
`;

export default ManualQuoteRequest;
