import axios from '../API/axios';

export const getDropoffPoints = (payload: any) => {
  return axios.post('/parcels/dropoffPoints/', payload);
};

export const getPaymentMethods = () => {
  return axios.get('/paymentmethod');
};

export const verifyPayPalTransaction = (token: any) => {
  return axios.get(`/payment/paypal/verify/${token}`);
};

export const completeKriyaTransaction = (transactionId: any) => {
  return axios.get(`/payment/kriya/confirm/${transactionId}`);
};

export const completeManualKriyaTransaction = (transactionId: any, state: string) => {
  return axios.get(`/payment/kriya/manual/${transactionId}/${state}`);
};

export const completeStripeTransaction = (token: any) => {
  return axios.get(`/payment/stripe/complete/${token}`);
};

export const fetchInsuranceItems = (cost: any, serviceId: any, type?: 'pallet' | 'parcel', weight?: number) => {
  const palletPath = (type === 'pallet') ? `/${type}/${weight}` : '';
  return axios.get(`/insurance/${serviceId}/${cost}${palletPath}`);
};

export const fetchInsuranceItemsByParcel = (cost: any) => {
  return axios.get(`/insurance/parcel/${cost}`);
};

export const checkProhibited = (data: any) => {
  return axios.post('/items/keyword/check', data);
};

export const getSingleOrder = (orderId: any) => {
  return axios.get(`/order/single/${orderId}`);
};

export const checkCustomsRequirement = (collectionCountry: any, deliveryCountry: any) => {
  return axios.get(`/customs/required/${collectionCountry}/${deliveryCountry}`);
};
