import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import cloneDeep from 'lodash.clonedeep';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import {
  Button,
  Caption,
  Column,
  H6,
  Row,
  PSmall,
  A,
} from '../../../../../assets/elements';
import Table from '../elements/Table';
import TableColumn from '../elements/Column';
import TableRow from '../elements/Row';
import TableHead from '../elements/Head';
import TableBody from '../elements/Body';
import device from '../../../../utils/device';
import {
  getAccountDiscount,
  uploadMultiBookCSV,
} from '../../../../utils/APICalls/Account';
import {
  addAccountDiscount,
  addOrderToBasketManual,
} from '../../../../store/actions';
import Modal from '../../../../../assets/Combos/Modal';
import { GTMECommerce } from '../../../../utils/tracking';
import CollectionDates from './elements/CollectionDates';
import InsuranceRates from './elements/InsuranceRates';
import RemoveIcon from '../../../../../assets/icons/RemoveIcon';
import { disconnectSocket } from '../../../../utils/APICalls/Sockets';
import { getExportReasons } from '../../../../utils/APICalls/Customs';
import ExportReasons from './elements/ExportReasons';
import NICheck from '../../../../utils/NICheck';
import { UK_VAT } from '../../../../utils/variables';

const StepTwo = ({
  rates,
  setRates,
  files,
  rows,
  setCurrentStep,
  serviceType,
  error,
}: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [total, setTotal] = useState<any>(0);
  const [vat, setVat] = useState<any>(0);
  const [prices, setPrices] = useState<any>({
    services: 0,
    discount: 0,
    insurance: 0,
    vat: 0,
    total: 0,
  });
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [cancelModal, setCancelModal] = useState(false);
  const [allReceived, setAllReceived] = useState(false);
  const [exportReasons, setExportReasons] = useState([]);

  const discount = useSelector((state: any) => state.main.discount);

  useEffect(() => {
    files.forEach((file: any) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', serviceType);

      uploadMultiBookCSV(formData);
    });
  }, []);

  useEffect(() => {
    getAccountDiscount()
      .then((res: any) => {
        const { value = 0, updatedAt: applicableFrom = '' } = res.data;

        if (value > 0) {
          dispatch(
            addAccountDiscount({
              value,
              applicableFrom,
            })
          );
        }
      })
      .catch(() => {
        // Error
      });
  }, []);

  useEffect(() => {
    getExportReasons().then((res: any) => {
      setExportReasons(res.data);
    });
  }, []);

  useEffect(() => {
    if (rates.length === rows - Object.keys(error).length) {
      setAllReceived(true);
      disconnectSocket();
    }
  }, [rates, error]);

  const checkForNI = (location: any) =>
    serviceType === 'pallet' && NICheck(location.collection, location.delivery);

  const locationObject = (item: any) => {
    return {
      collection: {
        country: item.collectionCountry,
        zip: item.collectionPostcode,
      },
      delivery: {
        country: item.deliveryCountry,
        zip: item.deliveryPostcode,
      },
    };
  };

  useEffect(() => {
    let price = 0;
    let tempVat = 0;
    let discountValue = 0;
    let insurance = 0;

    rates.forEach((rate: any) => {
      price += Number(
        rate.rate?.pricesBeforeDiscount?.price || rate.rate.price
      );
      tempVat +=
        !rate.rate.vat.customs || checkForNI(locationObject(rate.item))
          ? Number(rate.rate.priceIncVat) -
            Number(rate.rate.price) +
            (UK_VAT / 100) * Number(rate.rate.selectedInsurance?.price || 0)
          : 0;
      discountValue +=
        Number(rate.rate?.pricesBeforeDiscount?.price || rate.rate.price) *
        (Number(discount?.value || 0) / 100);
      insurance += Number(rate.rate.selectedInsurance?.price || 0);
    });

    setPrices({
      services: price,
      discount: discountValue,
      insurance,
      vat: tempVat,
      total: price + tempVat + insurance - discountValue,
    });
  }, [rates, discount]);

  useEffect(() => {
    let disabled = false;

    if (!allReceived) {
      disabled = true;
    }

    rates.forEach((rate: any) => {
      if (
        !rate.rate.selectedInsurance?.id ||
        rate.rate.selectedInsurance.id === ''
      ) {
        disabled = true;
      }

      if (rate.rate.selectedCollectionDate === '') {
        disabled = true;
      }

      if (rate.rate.vat.customs && rate.rate.exportReason === '') {
        disabled = true;
      }
    });

    setButtonDisabled(disabled);
  }, [rates]);

  const selectProperties = (key: any, property: any, value: any) => {
    const tempRates = rates.map((rate: any) => {
      if (rate.rate.key === key) {
        rate.rate[property] = value;
      }

      return rate;
    });

    setRates(tempRates);
  };

  const removeRate = (key: any) => {
    if (!allReceived) {
      return;
    }

    setRates(rates.filter(({ rate }: any) => rate.key !== key));
  };

  const addToBasket = () => {
    setButtonDisabled(true);
    let totalPrice = 0;
    const orders = rates.map((rate: any) => {
      const collectionName = rate.item.collectionFirstandLastName.split(' ');
      const deliveryName = rate.item.deliveryFirstandLastName.split(' ');

      const collectionFirst = collectionName[0];
      collectionName.shift();
      const collectionLast = collectionName.join(' ');

      const deliveryFirst = collectionName[0];
      deliveryName.shift();
      const deliveryLast = collectionName.join(' ');

      const item = cloneDeep(rate.rate);

      item.collectionDate = item.selectedCollectionDate;
      item.insurance = item.selectedInsurance;

      delete item.selectedCollectionDate;
      delete item.selectedInsurance;
      delete item.exportReason;

      totalPrice += Number(rate.rate.price);

      return {
        id: uuid(),
        collectionAddress: {
          firstName: collectionFirst,
          lastName: collectionLast,
          email: rate.item.collectionEmail,
          phone: rate.item.collectionPhone,
          reference: rate.item.collectionReference,
          addressOne: rate.item.collectionAddressLine1,
          addressTow: rate.item.collectionAddressLine2,
          addressThree: rate.item.collectionAddressLine3,
          city: rate.item.collectionCity,
          country: rate.item.collectionCountry,
          zip: rate.item.collectionPostcode,
          company: rate.item.collectionCompanyName,
          customsData: {
            VATStatus: rate.item.senderVATStatus,
            VATNumber: rate.item.senderVATNumber,
            EORINumber: rate.item.senderEORINumber,
          },
        },
        deliveryAddress: {
          firstName: deliveryFirst,
          lastName: deliveryLast,
          email: rate.item.deliveryEmail,
          phone: rate.item.deliveryPhone,
          addressOne: rate.item.deliveryAddressLine1,
          addressTow: rate.item.deliveryAddressLine2,
          addressThree: rate.item.deliveryAddressLine3,
          city: rate.item.deliveryCity,
          country: rate.item.deliveryCountry,
          zip: rate.item.deliveryPostcode,
          company: rate.item.deliveryBusinessname,
          customsData: {
            VATStatus: rate.item.receiverVATStatus,
            VATNumber: rate.item.receiverVATNumber,
            EORINumber: rate.item.receiverEORINumber,
          },
        },
        packageDetails: [
          {
            items: rate.item.items.map((it: any) => {
              if (it.itemTariffCode) {
                // If customs
                return {
                  value: it.itemvalue,
                  description: it.itemDescription,
                  tariff_code: it.itemTariffCode,
                  weight: it.itemweight,
                  quantity: it.itemQuantity,
                  manufactured_in: it.countryofmanufacture,
                };
              }

              return {
                value: it.itemvalue,
                description: it.itemDescription,
              };
            }),
            length: {
              amount: rate.item.parcelLengthcm,
              unit: 'cm',
            },
            width: {
              amount: rate.item.parcelWidthcm,
              unit: 'cm',
            },
            height: {
              amount: rate.item.parcelHeightcm,
              unit: 'cm',
            },
            weight: {
              amount: rate.item.parcelWeightkg,
              unit: 'kg',
            },
          },
        ],
        serviceType: 'parcel',
        serviceId: item.serviceId,
        vat_information: {
          export_reason: item.exportReason,
        },
        item,
      };
    });

    GTMECommerce(
      {
        currencyCode: 'GBP',
        add: {
          products: orders.map((rate: any) => ({
            name: `${rate.item?.carrier ? `${rate.item.carrier} - ` : ''}${
              rate.item.name
            }`,
            id: `${rate.item.rateId}-${rate.item.serviceId}-${rate.item.type}`,
            price: rate.item.priceIncVat,
            category: 'parcel',
            variant: rate.item.type,
            quantity: 1,
          })),
        },
      },
      'addToCart'
    );

    dispatch(addOrderToBasketManual(orders));
    history.push('/order-basket');
  };

  const toggleCancelModal = (value: any) => {
    setCancelModal(value);
  };

  const goBack = () => {
    setCancelModal(false);
    setRates([]);

    setCurrentStep(1);
    window.location.reload();
  };

  return (
    <Column sizeL={12} sizeM={12} sizeS={6} sizeXS={4}>
      <Card>
        <HeaderRow>
          <Title>
            <H6>Booking summary</H6>
            <SubTitle finished={rates.length === rows}>
              {allReceived
                ? `Quotes provided for ${rows} out of ${rows} orders`
                : `Retrieving quotes for ${
                    rates.length + 1
                  } out of ${rows} orders...`}
              {Object.keys(error).length > 0 && (
                <ErrorLine>
                  Error with {Object.keys(error).length} order
                  {Object.keys(error).length === 0 ||
                  Object.keys(error).length > 1
                    ? 's'
                    : ''}
                </ErrorLine>
              )}
            </SubTitle>
          </Title>
          <div>
            <PriceContainer>
              <SubPrice>Services: £{prices.services.toFixed(2)}</SubPrice>
              {discount.value > 0 && (
                <SubPrice>
                  {discount?.value || 0}% Discount: £
                  {prices?.discount?.toFixed(2) || 0}
                </SubPrice>
              )}
              <SubPrice>
                Insurance:{' '}
                {prices.insurance === 0
                  ? 'FREE'
                  : `£${prices.insurance.toFixed(2)}`}
              </SubPrice>
              {prices.vat > 0 && (
                <SubPrice>VAT: £{prices.vat.toFixed(2)}</SubPrice>
              )}
              <H6>Total: £{prices.total.toFixed(2)}</H6>
            </PriceContainer>
          </div>
        </HeaderRow>
        {Object.keys(error).length > 0 && (
          <ErrorContainer>
            <ErrorTitle>
              The total weight of your items must equal the weight of this
              parcel
            </ErrorTitle>
            {Object.keys(error).map((err: any, i: any) =>
              error[err][0].msg === 'NO_RATES_AVAILABLE' ? (
                <></>
              ) : (
                <>
                  <ErrorLine key={i}>
                    OrderRef {err}: Parcel weight ={' '}
                    {error[err][0]?.order.item.parcelWeightkg}kg, Item weight ={' '}
                    {error[err][0].order.item.items.reduce(
                      (acc: any, item: any) =>
                        acc +
                        (Number(item.itemweight || 0) *
                          Number(item.itemQuantity) || 0),
                      0
                    )}
                    kg
                  </ErrorLine>
                </>
              )
            )}
          </ErrorContainer>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableColumn>
                <BoldHeading>Order Ref</BoldHeading>
              </TableColumn>
              <TableColumn colSpan="2">
                <BoldHeading>Collection / Delivery</BoldHeading>
                <HeadingCaption>Postcode & Country</HeadingCaption>
              </TableColumn>
              <TableColumn>
                <BoldHeading>Length</BoldHeading>
                <HeadingCaption>CM</HeadingCaption>
              </TableColumn>
              <TableColumn>
                <BoldHeading>Width</BoldHeading>
                <HeadingCaption>CM</HeadingCaption>
              </TableColumn>
              <TableColumn>
                <BoldHeading>Height</BoldHeading>
                <HeadingCaption>CM</HeadingCaption>
              </TableColumn>
              <TableColumn>
                <BoldHeading>Weight</BoldHeading>
                <HeadingCaption>KG</HeadingCaption>
              </TableColumn>
              <TableColumn>
                <BoldHeading>Item</BoldHeading>
                <BoldHeading>Qty</BoldHeading>
              </TableColumn>
              <TableColumn>
                <BoldHeading>Service</BoldHeading>
              </TableColumn>
              <TableColumn />
            </TableRow>
          </TableHead>
          <TableBody>
            {rates.map(({ rate, item }: any, i: any) => (
              <React.Fragment key={i}>
                <TableRow>
                  <TableColumn>
                    <BlackValue>{item.orderReference}</BlackValue>
                  </TableColumn>
                  <TableColumn>
                    <BlackValue>{item.collectionPostcode}</BlackValue>
                    <BlackValue>{item.collectionCountry}</BlackValue>
                  </TableColumn>
                  <TableColumn>
                    <BlackValue>{item.deliveryPostcode}</BlackValue>
                    <BlackValue>{item.deliveryCountry}</BlackValue>
                  </TableColumn>
                  <TableColumn>
                    <BlackValue>{item.parcelLengthcm}</BlackValue>
                  </TableColumn>
                  <TableColumn>
                    <BlackValue>{item.parcelWidthcm}</BlackValue>
                  </TableColumn>
                  <TableColumn>
                    <BlackValue>{item.parcelHeightcm}</BlackValue>
                  </TableColumn>
                  <TableColumn>
                    <BlackValue>{item.parcelWeightkg}</BlackValue>
                  </TableColumn>
                  <TableColumn>
                    <BlackValue>
                      {item.items.reduce(
                        (acc: any, data: any) =>
                          acc + Number(data.itemQuantity),
                        0
                      )}
                    </BlackValue>
                  </TableColumn>
                  <TableColumn>
                    <BoldValue>
                      {rate.carrier} - <br />
                      {rate.name} - £{rate.price}
                    </BoldValue>
                  </TableColumn>
                  <TableColumn>
                    <RemoveRate
                      isDisabled={!allReceived}
                      onClick={() => removeRate(rate.key)}
                    >
                      <RemoveIcon width={20} height={20} />
                    </RemoveRate>
                  </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn colSpan={10}>
                    <CollectionDates
                      collectionDate={rate.collectionDate}
                      onChange={(value: string) =>
                        selectProperties(
                          rate.key,
                          'selectedCollectionDate',
                          value
                        )
                      }
                      selected={rate.selectedCollectionDate}
                      isDisabled={!allReceived}
                      country={item.collectionCountry}
                    />
                  </TableColumn>
                </TableRow>
                <TableRow border={!rate.vat.customs}>
                  <TableColumn colSpan={10}>
                    <InsuranceRates
                      insuranceRates={rate.insurance}
                      onChange={(value: string) =>
                        selectProperties(rate.key, 'selectedInsurance', value)
                      }
                      selected={rate.selectedInsurance}
                      isDisabled={!allReceived}
                    />
                  </TableColumn>
                </TableRow>
                {rate.vat.customs && (
                  <TableRow border={rate.vat.customs}>
                    <TableColumn colSpan={10}>
                      <ExportReasons
                        exportReasons={exportReasons}
                        onChange={(value: string) =>
                          selectProperties(rate.key, 'exportReason', value)
                        }
                        selected={rate.exportReason}
                        isDisabled={!allReceived}
                      />
                    </TableColumn>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        <ButtonsRow>
          <ButtonsColumn sizeL={6} sizeM={6} sizeS={3} sizeXS={4}>
            <Button color="black" block onClick={() => toggleCancelModal(true)}>
              Back
            </Button>
          </ButtonsColumn>
          <ButtonsColumn sizeL={6} sizeM={6} sizeS={3} sizeXS={4}>
            <Button
              color="secondary"
              block
              disable={buttonDisabled}
              disabled={buttonDisabled}
              onClick={addToBasket}
            >
              Add to basket
            </Button>
          </ButtonsColumn>
        </ButtonsRow>
      </Card>
      <Modal
        title="Cancel order"
        open={cancelModal}
        close={() => setCancelModal(false)}
        width={6}
      >
        <ModalText>
          Are you sure you want to stop processing these orders?
        </ModalText>
        <Row>
          <Column sizeL={6} sizeM={6} sizeS={3} sizeXS={2}>
            <Button
              color="black"
              block
              onClick={() => toggleCancelModal(false)}
            >
              No
            </Button>
          </Column>
          <Column sizeL={6} sizeM={6} sizeS={3} sizeXS={2}>
            <Button color="secondary" block onClick={goBack}>
              Yes
            </Button>
          </Column>
        </Row>
      </Modal>
    </Column>
  );
};

const Card = styled.div`
  background: ${({ theme }: any) => theme.colors.White};
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};
  padding: 32px 34px;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const SubTitle = styled(PSmall).attrs(({ finished }: any) => ({
  finished,
}))`
  color: ${({ finished }: any) => (finished ? '#148D47' : '#AE5BFB')};
  margin-top: 24px;
`;

const PriceContainer = styled.div`
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
  margin-top: 16px;

  @media ${device.laptop} {
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 0;
  }
`;

const SubPrice = styled(PSmall)`
  color: ${({ theme }: any) => theme.colors.MidGrey};
  margin-bottom: 8px;
`;

const BoldHeading = styled.p`
  font-weight: 600;
  line-height: 135%;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 4px;
`;

const HeadingCaption = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const MidGreyValue = styled.p`
  color: ${({ theme }: any) => theme.colors.MidGrey};
  line-height: 32px;
`;

const BlackValue = styled.p`
  color: ${({ theme }: any) => theme.colors.Black};
  line-height: 32px;
`;

const BoldValue = styled(BlackValue)`
  font-weight: 600;
`;

const ButtonsRow = styled(Row)`
  margin-top: 24px;
`;

const ButtonsColumn = styled(Column)`
  padding: unset;

  &:first-child {
    margin-bottom: 16px;
  }

  @media ${device.laptop} {
    padding: 0 15px;

    &:first-child {
      padding-left: unset;
      margin-bottom: unset;
    }

    &:last-child {
      padding-right: unset;
    }
  }
`;

const ModalText = styled.p`
  margin-bottom: 16px;
  padding: 0 15px;
`;

const RemoveRate = styled(A).attrs(({ isDisabled }: any) => ({
  isDisabled,
}))`
  cursor: ${({ isDisabled }: any) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

const ErrorContainer = styled.div`
  background: #f9dbe0;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
`;

const ErrorTitle = styled.p`
  color: ${({ theme }: any) => theme.colors.Error};
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  margin-bottom: 8px;
`;

const ErrorLine = styled.p`
  color: ${({ theme }: any) => theme.colors.Error};
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  display: block;
`;

export default StepTwo;
