import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../../../../../assets/Combos/Modal';
import {
  A,
  Caption,
  H6,
  PSmall,
} from '../../../../../../../../assets/elements';
import { landingPageUrl } from '../../../../../../../utils/env';

const TariffModal = ({ open, setOpen }: any) => {
  const openTariffLink = () => {
    window.open(`${landingPageUrl}tariff-code-lookup`, '_blank');
  };

  return (
    <Modal
      title="What is a Tariff Harmonisation Code?"
      TitleElement={H6}
      open={open}
      close={() => setOpen(false)}
      width={7}
    >
      <PSmall>
        Tariff Codes are an internationally recognised standard which means your
        product items can easily be identified worldwide by all customs systems,
        regardless of language barriers.
      </PSmall>

      <AStyled onClick={openTariffLink}>Find your tariff code</AStyled>

      <Caption>
        CollectMyParcel is not responsible for covering any additional costs if
        the code submitted with the item is incorrect.
      </Caption>
    </Modal>
  );
};

const AStyled = styled(A)`
  margin: 26px 0;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

export default TariffModal;
