import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  H4,
} from '../../../assets/elements';
import { countriesPostalCodesData } from '../../mocks/quoteData';

const Redirect = () => {

  const history = useHistory();

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();

  const urlParam = query.get('url');

  const lookupCountryCode = (country: any) => {
    const match = countriesPostalCodesData.find((c) => c.name === country);
    if (match) {
      return match.code;
    }
    return null;
  };


  useEffect(() => {
    const redirect = (url: any) => {
      if(!url) {
        history.push('/');
        return;
      }
      const countryFrom = url.slice(url.indexOf('{') + 2, url.indexOf('}'));
      const countryTo = url.slice(
        url.lastIndexOf('{') + 1,
        url.lastIndexOf('}') - 1
      );
  
      const countryFromCode = lookupCountryCode(countryFrom);
      const countryToCode = lookupCountryCode(countryTo);
      if ( !countryFromCode || !countryToCode ) {
        history.push('/');
        return;
      }
      const newURL = url
        .replace(`{{${countryFrom}}}`, countryFromCode)
        .replace(`{{${countryTo}}}`, countryToCode);
      history.push(newURL);
    };

    redirect(urlParam);
  });

  return (
    <H4>Redirecting...</H4>
  );
};

export default Redirect;
