import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { Input, Label, Caption } from '../../../../../../../../assets/elements';
import Loader from '../../../../../../Loader';
import {
  setDropoffPoint,
  setLoading,
} from '../../../../../../../store/actions';
import { getDropoffPoints } from '../../../../../../../utils/APICalls/Order';

const TypeDrop = ({ addressErrors }: any) => {
  const [data, setData] = useState<any>([]);
  const [deliveryPostcode, setDeliveryPostcode] = useState('');

  const dispatch = useDispatch();

  const handleOptionChange = (e: any, id: any) => {
    const payload = data.map((item: any) => {
      return {
        ...item,
        active: item.id === id,
      };
    });

    setData(payload);
  };

  const { item, collectionAddress, packageDetails } = useSelector<any, any>(
    (state) => state.order
  );

  const fetchDropOffPoints = (postCode: any) => {
    const requestData = {
      carrier: item.carrier,
      location: {
        postcode: postCode,
        country: collectionAddress.country,
      },
      packageDetails: [
        {
          width: {
            amount: packageDetails.parcel[0].width.amount,
            units: packageDetails.parcel[0].width.units,
          },
          length: {
            amount: packageDetails.parcel[0].length.amount,
            units: packageDetails.parcel[0].length.units,
          },
          height: {
            amount: packageDetails.parcel[0].height.amount,
            units: packageDetails.parcel[0].height.units,
          },
          weight: {
            amount: packageDetails.parcel[0].weight.amount,
            units: packageDetails.parcel[0].weight.units,
          },
        },
      ],
    };

    dispatch(setLoading('dropOffPoints', true));

    getDropoffPoints(requestData)
      .then((response: any) => {
        const payload = response.data.map((option: any) => {
          return {
            ...option,
            active: false,
          };
        });

        dispatch(
          setDropoffPoint({
            id: payload[0]?.id || '',
            postcode: collectionAddress?.zip || '',
            countryCode: collectionAddress?.country || '',
          })
        );

        setData(payload);
      })
      .catch(() => {
        setData([]);
        return [];
      })
      .finally(() => {
        dispatch(setLoading('dropOffPoints', false));
      });
  };

  const handleDeliveryAddressChange = (e: any) => {
    setDeliveryPostcode(e.target.value);
    fetchDropOffPoints(e.target.value);
  };

  useEffect(() => {
    collectionAddress.zip && setDeliveryPostcode(collectionAddress.zip);
    collectionAddress.zip && fetchDropOffPoints(collectionAddress.zip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loading = useSelector<any, any>(
    (state) => state.main.loading.dropOffPoints!
  );
  return (
    <>
      <H2>Your nearest drop off locations</H2>
      {collectionAddress.zip.length <= 0 && (
        <InputWrapper>
          <PStyled>
            Add a postcode below to see your nearest drop off locations
          </PStyled>
          <LabelStyledPost>Postcode</LabelStyledPost>
          <Input
            placeholder="Enter a postcode"
            value={deliveryPostcode}
            onChange={(e: any) => handleDeliveryAddressChange(e)}
            theme={undefined}
            type="text"
            error={addressErrors.zip}
            autoComplete="off"
            disabled={true}
          />
          <ErrorText>{addressErrors.zip}</ErrorText>
        </InputWrapper>
      )}
      {loading ? (
        <Loader>Finding drop-off locations</Loader>
      ) : (
        <Fieldset>
          {data.map((option: any) => {
            return (
              <Option key={option.id}>
                <input
                  type="radio"
                  id={option.id}
                  name="value"
                  value={option.id}
                  onChange={(e) => handleOptionChange(e, option.id)}
                />
                <LabelStyled htmlFor={option.id}>{option.address}</LabelStyled>
              </Option>
            );
          })}
        </Fieldset>
      )}
    </>
  );
};

const H2 = styled.h2`
  font-size: 32px;
  line-height: 135%;
  color: ${({ theme }: any) => theme.colors.Black};
`;
const Fieldset = styled.fieldset`
  input[type='radio'] {
    display: none;
  }
  margin-top: 40px;
`;
const Option = styled.div<any>`
  width: 100%;
    min-height: 60px;
    position: relative;
    margin-bottom: 20px;
    background: #F2F3F2;
    border-radius: 4px;
    padding: 10px;
`;

const LabelStyled = styled.label`
  line-height: 1.4;
  &:before {
    content: ' ';
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
`;

const LabelStyledPost = styled(Label)`
  margin-bottom: 8px;
  font-weight: normal;
  margin-top: 30px;
  color: #777e7e;
  width: 100%;
`;
const InputWrapper = styled.div`
  margin-bottom: 25px;
  position: relative;
`;
const PStyled = styled.p`
  margin-top: 20px;
`;
const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 3px;
`;

export default TypeDrop;
