import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Label, Caption, Input, A } from '../../../../../../../../assets/elements';
import device from '../../../../../../../utils/device';
import { SelectInput } from '../../../../../../Inputs';
import { getExportReasons } from '../../../../../../../utils/APICalls/Customs';
import Required from '../../../../../../../../assets/elements/Required';
import { useSelector } from 'react-redux';
import CustomsClearance from '../../../../../../LandingPageQuote/components/QuoteForm/Modals/CustomsClearance';
import CPCModal from './CPCModal';
import InfoIcon from '../../../../../../../../assets/icons/InfoIcon';

const incotermsData = [
  {
    label: 'DAP',
    value: 'DAP',
  },
  {
    label: 'EXW',
    value: 'EXW',
  },
];

export const CustomsDetails = ({
  details,
  setDetails,
  errors,
  setErrors,
  serviceType,
}: any) => {
  const [exportReasonOptions, setExportReasonsOptions] = useState([]);
  const [customsModal, setCustomsModal] = useState(false);
  const [cpcModal, setCpcModal] = useState(false);

  const { country: collectionCountry } = useSelector((state: any) => state.order.collectionAddress);
  const { country: deliveryCountry } = useSelector((state: any) => state.order.deliveryAddress);

  useEffect(() => {
    getExportReasons().then((res: any) => {
      setExportReasonsOptions(res.data);
    });
  }, []);

  const handleChange = (value: any, key: any) => {
    if (errors[key]) {
      setErrors({
        ...errors,
        [key]: false,
      });
    }

    setDetails({
      ...details,
      [key]: value,
    });
  };

  useEffect(() => {
    if (collectionCountry === 'GB' && details.exportReason === 'sold') {
      handleChange('10 00 00 01', 'CPCCode');
    } else if (deliveryCountry === 'GB' && details.exportReason === 'sold') {
      handleChange('140 00 000', 'CPCCode');
    } else {
      handleChange('', 'CPCCode');
    }
  }, [details.exportReason, collectionCountry, deliveryCountry]);

  return (
    <Wrapper>
      <H2>Customs details</H2>

      <LabelStyled><Required />Export Reason</LabelStyled>
      <SelectInput
        error={errors.exportReason}
        resetError={() => {}}
        value={details.exportReason}
        onChange={(selected: any) => handleChange(selected, 'exportReason')}
        data={exportReasonOptions}
      />
      <ErrorText>{errors.exportReason}</ErrorText>

      {
        (serviceType === 'freight' || serviceType === 'seaFreight') && (
          <>
            <LabelStyled><Required />Incoterms</LabelStyled>
            <SelectInput
              error={errors.incoTerms}
              resetError={() => {}}
              value={details.incoTerms}
              onChange={(selected: any) => handleChange(selected, 'incoTerms')}
              data={incotermsData}
            />
            <ErrorText>{errors.incoTerms}</ErrorText>

            <LabelStyled>CPC code</LabelStyled>
            <Input
              theme={null}
              value={details.CPCCode}
              type="text"
              placeholder="Value"
              onChange={(e: any) => handleChange(e.target.value, 'CPCCode')}
              error={errors.CPCCode}
            />
            <ErrorText>{errors.CPCCode}</ErrorText>
            {
              (collectionCountry === 'GB' || deliveryCountry === 'GB') && details.exportReason === 'sold' && (
                <CPCText>
                  The above code is added as this is a GB { collectionCountry === 'GB' ? 'export' : 'import' } of Goods that have been sold.
                </CPCText>
              )
            }

            <LinksWrapper>
              <Link onClick={() => setCpcModal(true)}>
                <InfoIcon /> <LinkText>What is CPC code?</LinkText>
              </Link>
              <Link onClick={() => setCustomsModal(true)}>
                <InfoIcon /> <LinkText>Do you need customs clearance?</LinkText>
              </Link>
            </LinksWrapper>
            <CustomsClearance open={customsModal} close={() => setCustomsModal(false)} />
            <CPCModal open={cpcModal} close={() => setCpcModal(false)} />
          </>
        )
      }
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  background: #ffffff;
  border-radius: 8px;
  && {
    padding: 15px;
    @media ${device.tablet} {
      padding: 30px;
    }
  }
  height: auto;
  margin: 40px 0;
`;

const H2 = styled.h2`
  font-size: 32px;
  line-height: 135%;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: normal;
  margin-top: 30px;
  color: #777e7e;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: block;
  margin-top: 4px;
`;

const LinksWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30px;

  @media ${device.laptop} {
    flex-direction: row;
    align-items: center;
  }
`;

const Link = styled(A)`
  margin-right: 16px;
  display: flex;
  align-items: center;

  &:last-of-type {
    margin-right: unset;
  }
`;

const LinkText = styled.span`
  margin-left: 6px;
`;

const CPCText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }: any) => theme.colors.MidGrey};
  margin-top: 6px;
`;