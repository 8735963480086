import React from 'react';
import styled from 'styled-components';
import LoadContainer from './LoadContainer';
import { PSmall } from '../../../../../../../../assets/elements';

const FullLoad = ({ onClick, selected }: any) => {
  return (
    <LoadContainer
      onClick={onClick}
      selected={selected}
    >
      <Wrapper>
        <Title>Full Container Load</Title>
      </Wrapper>
    </LoadContainer>
  );
};

const Wrapper = styled.div`
  cursor: pointer;
`;

const Title = styled(PSmall)`
  font-weight: 600;
  display: block;
  width: 100%;
  margin: unset;
  padding-left: 35px;
`;

export default FullLoad;
