import React from 'react';
import { withTheme } from 'styled-components';

const AddIcon = ({
  theme,
  width = 32,
  height = 32,
}: any) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.6667 16H9.33334" stroke={theme.colors.Secondary} strokeWidth="2" strokeLinecap="round" />
      <path d="M16 22.6667V9.33337" stroke={theme.colors.Secondary} strokeWidth="2" strokeLinecap="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 29.3333C23.3638 29.3333 29.3333 23.3638 29.3333 16C29.3333 8.63616 23.3638 2.66663 16 2.66663C8.63619 2.66663 2.66666 8.63616 2.66666 16C2.66666 23.3638 8.63619 29.3333 16 29.3333Z" stroke={theme.colors.Secondary} strokeWidth="2" />
    </svg>
  );
};

export default withTheme(AddIcon);
