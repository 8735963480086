/*
* M: Mobile devices
* L: Larger devices (Laptop/Tablet)
*
* FS: Font size
* */

export const p4d = {
  Primary: '#009FDA',
  Secondary: '#F09A3F',
};