export const mapQuotesToGTM = (data: any) => {
  return data.map((item: any, i: any) => ({
    name: `${item?.carrier ? `${item.carrier} - ` : ''}${item.name}`,
    id: `${item.rateId}-${item.serviceId}-${item?.type || item?.quoteType}`,
    price: item.priceIncVat,
    category: item?.quoteType || 'parcel',
    variant: item?.type || 'collection',
    list: 'quotes',
    position: i + 1,
  }));
};
