import React from 'react';
import styled from 'styled-components';
import CrossIcon from '../../../../../assets/icons/cross.svg';
import OKIcon from '../../../../../assets/icons/ok.svg';

const TailLiftInfo = ({ tailLift }: any) => {
  return (
    <Wrapper tailLift={tailLift}>
      <Icon src={tailLift ? OKIcon : CrossIcon} alt="Icon" />
      {
        tailLift ? (
          <>Tail lift included</>
        ) : (
          <>Tail lift not included</>
        )
      }
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs(({ tailLift }: any) => ({
  tailLift,
}))`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-top: 16px;
  color: ${({ theme, tailLift }: any) => tailLift ? theme.colors.Success : theme.colors.Warning};
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  margin-right: 4px;
  width: 16px;
  height: 16px;
`;

export default TailLiftInfo;
