import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import device from '../../../../../utils/device';

const Navigation = ({ steps, current }: any) => {
  const [status, setStatus] = useState([
    'disabled',
    'disabled',
    'disabled',
    'disabled',
    'disabled',
  ]);

  const [stepsState, setStepState] = useState<any>([]);
  useEffect(() => {
    let tempSteps: any = [];

    for (let i = 1; i <= steps; i++) {
      tempSteps = [ ...tempSteps, i ];
    }

    setStepState(tempSteps);
  }, []);
  /*  React.useEffect(() => {
    if (pathname.includes('order-step1')) {
      setStatus(['active', 'disabled', 'disabled', 'disabled', 'disabled']);
    } else if (pathname.includes('order-step2')) {
      setStatus(['done', 'active', 'disabled', 'disabled', 'disabled']);
    } else if (pathname.includes('order-step3')) {
      setStatus(['done', 'done', 'active', 'disabled', 'disabled']);
    } else if (pathname.includes('order-basket')) {
      setStatus(['done', 'done', 'done', 'active', 'disabled']);
    } else if (pathname.includes('payment')) {
      setStatus(['done', 'done', 'done', 'done', 'active']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]); */

  const Icon = ({ statusProp }: any) => {
    let el: any;

    if (statusProp === 'done') {
      el = (
        <SvgWrapper>
          <svg
            width="12"
            height="11"
            viewBox="0 0 12 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 5.57143L4 9L11 1" stroke="white" strokeWidth="2" />
          </svg>
        </SvgWrapper>
      );
    } else if (statusProp === 'active') {
      el = <CircleSmall />;
    } else {
      el = <CircleBig />;
    }

    return <>{el}</>;
  };

  return (
    <Wrapper>
      <CircleWrapper>
        {
          stepsState.map((step: any, i: any) => {
            const isDone = step < current;
            const isActive = step === current;
            let currentStatus = isDone ? 'done' : false;

            if (!currentStatus) {
              currentStatus = isActive ? 'active' : 'disabled';
            }

            return (
              <Circle
                key={i}
                status={currentStatus}
              >
                <Icon statusProp={currentStatus} />
              </Circle>
            );
          })
        }
      </CircleWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background: white;
  border-top: 1px solid #ddd;
  align-items: center;
`;
const CircleWrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media ${device.tablet} {
    width: 400px;
  }

  @media ${device.laptop} {
    width: 600px;
  }
`;
const Circle = styled.span<any>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ status, theme }: any) =>
    status === 'disabled' ? theme.colors.Pastel2 : theme.colors.Secondary};
  display: block;
  position: relative;
  z-index: 2;

  &:first-of-type {
    &:before {
      display: none;
    }
  }

  @media ${device.laptop} {
    width: 24px;
    height: 24px;
  }

  &:before {
    content: '';
    display: block;

    height: 4px;
    background: ${({ status, theme }: any) =>
    status === 'disabled' ? theme.colors.Pastel2 : theme.colors.Secondary};
    position: absolute;
    top: 9px;
    z-index: -1;
    left: -50px;
    width: 50px;

    @media ${device.tablet} {
      width: 75px;
      left: -75px;
    }

    @media ${device.laptop} {
      // width: 188px;
      // left: -95px;
      width: 120px;
      left: -120px;
    }
  }
`;

const CircleSmall = styled.span<any>`
  display: block;
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 4px);
  background: white;
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

const CircleBig = styled.span<any>`
  display: block;
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 7px);
  background: white;
  border-radius: 50%;
  height: 14px;
  width: 14px;
`;
const SvgWrapper = styled.div<any>`
  transform: scale(1.1);
  position: absolute;
  top: 3px;
  left: 4px;

  @media ${device.laptop} {
    top: 5px;
    left: 6px;
  }
`;

export default withRouter(Navigation);
