import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Button, Column, Container, P, Row, A } from '../../../../../assets/elements';
import InfoIcon from '../../../../../assets/icons/Info.svg';
import device from '../../../../utils/device';
import { dateFormat } from '../../../../utils/variables';
import { SelectInput } from '../../../Inputs';
import { setTimeSlot } from '../../../../store/actions';
import NICheck from '../../../../utils/NICheck';
import InsuranceInfo from '../InsuranceInfo';
import TailLiftInfo from '../TailLifeInfo';
import { calculateForAnyTime } from '../../../../utils/deliveryDate';

const SingleResult = ({
  id,
  name,
  logo,
  status = '',
  price,
  priceIncVat,
  moreInfo,
  setMoreInfo,
  openMoreInfoModal,
  selectedCD,
  selectedDD,
  onSelect,
  timed,
  customs,
  timeslots,
  deliveryDays,
  pallet,
  pricesBeforeDiscount = null,
  discount,
  palletAndWrap,
  coveredInsurance,
  openInsurance,
  weight,
  serviceInformation,
  setServiceInfo,
  openServiceInfoModal,
  setServiceInfoTitle,
  setOpenEmailModal,
  shippingDates,
}: any) => {
  const [timeData, setTimeData] = useState([]);
  const [collectionTimeslot, setCollectionTimeslot] = useState([]);
  const dispatch = useDispatch();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = (selectedD: any) => {
    const selectedC = selectedCD === 'anytime'
      ? moment(shippingDates[0].cd)
      : moment(selectedCD);
    onSelect(id, selectedD, selectedC);
  };

  const handleMoreInfoClick = () => {
    setMoreInfo(moreInfo);
    ReactTooltip.hide();
    openMoreInfoModal();
  };

  const handleServiceInfoClick = () => {
    if (serviceInformation) {
      setServiceInfoTitle(serviceInformation.title)
      setServiceInfo(serviceInformation);
      ReactTooltip.hide();
      openServiceInfoModal();
    }
  };

  const formatDate = (date: any) => moment(date).format(dateFormat);

  const displayDD = () => {
    if (selectedCD === 'anytime' && selectedDD === 'anytime') {
      return moment(shippingDates[0].dd[0]);
    } 
    
    if (selectedCD !== 'anytime' && selectedDD === 'anytime') {
      const collectionDate = shippingDates.find((date: any) => date.cd === selectedCD);
      if (collectionDate?.dd?.length) return moment(collectionDate.dd[0]);
    }

    if (!selectedDD) return moment(shippingDates[0].dd[0]);
    
    return moment(selectedDD);
  };

  const calculateTime = ({ start, end, interval }: any) => {
    let times: any = [{ name: start, code: start }];
    let currentTime = start;

    while (currentTime !== end) {
      const currentMin = parseInt(currentTime.slice(-2), 10);
      const currentHour = parseInt(currentTime.slice(-5), 10);

      const newMin = currentMin + interval;
      if (newMin !== 60) {
        currentTime = `${String(currentHour)}:${String(newMin)}`;
      } else {
        currentTime = `${String(currentHour + 1)}:00`;
      }

      times = [...times, { name: currentTime, code: currentTime }];
    }

    return times;
  };

  const handleTimeSelect = (e:any) => {
    dispatch(setTimeSlot(e));

    setCollectionTimeslot(e);
    setButtonDisabled(false);
  };

  useEffect(() => {
    // setTimeData(calculateTime(timeslots || { start: '09:00', end: '17:00', interval: 15 }));
    timed && setTimeData(calculateTime(timeslots));
    timed && setButtonDisabled(true);
  }, []);

  return (
    <Wrapper>
      <RowStyled>
        <ColumnStyled sizeL={2} sizeM={2} sizeXS={4}>
          <Brand>
            <BrandImg src={logo} alt={`${name} logo`} />
          </Brand>
        </ColumnStyled>
        <ColumnStyled sizeL={3} sizeM={10} sizeXS={4}>
          <InfoContainer>
            <TitleContainer>
              <P>{name}</P>
              <Icon
                onClick={serviceInformation ? handleServiceInfoClick : handleMoreInfoClick}
                data-tip
                data-for="info-icon"
                src={InfoIcon}
              />
              <ReactTooltip id="info-icon" effect="solid">
                More information
              </ReactTooltip>
            </TitleContainer>
            <InsuranceInfo coveredInsurance={coveredInsurance} openInsurance={openInsurance} isPallet={true} weight={weight} />
            <TailLiftInfo tailLift={true} />
          </InfoContainer>
        </ColumnStyled>
        <ColumnStyledSmall sizeL={2} sizeM={3} sizeXS={2}>
          <DeliveryTimeContainer>
            <DeliveryTimeHead>
              {selectedCD === 'anytime' ? 'Earliest ' : ''}Collection Date
            </DeliveryTimeHead>
            <DeliveryTimeData>
              {selectedCD === 'anytime'
                ? formatDate(moment(shippingDates[0].cd))
                : formatDate(moment(selectedCD))}
            </DeliveryTimeData>
          </DeliveryTimeContainer>
        </ColumnStyledSmall>
        <ColumnStyledSmall sizeL={2} sizeM={3} sizeXS={2}>
          <DeliveryTimeContainer>
            <DeliveryTimeHead>
              {selectedDD === 'anytime' ? 'Earliest ' : ''}Delivery date
            </DeliveryTimeHead>
            <DeliveryTimeData>{formatDate(displayDD())}</DeliveryTimeData>
          </DeliveryTimeContainer>
        </ColumnStyledSmall>
        <PriceColumn sizeL={1.6} sizeM={3} sizeXS={2}>
          <PriceContainer>
            <Price>
              {`£${ (Number(price) + (palletAndWrap ? (25 - (25 * discount / 100)) : 0)).toFixed(2) }`}
            </Price>
            {
              pricesBeforeDiscount &&
              <PriceOriginal>
                £{ (Number(pricesBeforeDiscount.price) + (palletAndWrap ? 25 : 0)).toFixed(2) }
              </PriceOriginal>
            }
            {(!customs || NICheck(pallet.collectionLocation, pallet.deliveryLocation)) && (
              <PriceVAT>
                incl. VAT {`£${(Number(priceIncVat) +
                (palletAndWrap ? 30 - (30 * discount / 100) : 0)).toFixed(2)}`}
              </PriceVAT>
            )}
          </PriceContainer>
        </PriceColumn>
        <Column sizeL={1.4} sizeM={3} sizeXS={2}>
          <ButtonContainer>
            <Button
              disable={buttonDisabled}
              disabled={buttonDisabled}
              onClick={() => handleSubmit(displayDD())}
              color="secondary"
            >
              Book
            </Button>
            <LinkWrapper onClick={() => setOpenEmailModal(true)}>Email Quote</LinkWrapper>
          </ButtonContainer>
          
        </Column>
      </RowStyled>
      {timed && (
        <TimedDelivery>
          <ContainerStyled>
            <TextWrapper>
              <TextP>
                This is a <SpanB>Timed Delivery</SpanB> service.
                Please choose the <SpanB>time*</SpanB> you want your delivery to arrive.
              </TextP>
              <SubTextP>
                * Your item(s) will be delivered within 30mins of the chosen time
              </SubTextP>
            </TextWrapper>
            <SelectWrapper>
              <SelectInput
                title="Timeslot"
                data={timeData}
                value={collectionTimeslot}
                onChange={(e: any) => handleTimeSelect(e)}
              />
            </SelectWrapper>
          </ContainerStyled>
        </TimedDelivery>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
    background: white;
  margin-bottom: 16px;
  width: 100%;
  border-radius: 4px;
`;

const RowStyled = styled(Row)`
  width: 100%;
  padding: 24px;
`;

const ColumnStyled = styled(Column)`
  margin-bottom: 26px;

  @media ${device.desktop} {
    margin-bottom: 0;
  }
`;

const ColumnStyledSmall = styled(Column)`
  margin-bottom: 26px;

  @media ${device.laptop} {
    margin-bottom: 0;
  }
`;

const Brand = styled.div``;
const BrandImg = styled.img`
  max-height: 88px;
  width: auto;
  max-width: 100px;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-around;
`;

const Status = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #777e7e;
  flex: none;
  order: 1;
  align-self: flex-start;
`;

const Icon = styled.img`
  margin-left: 25px;
`;

const DeliveryTimeContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
  color: ${({ theme }: any) => theme.colors.Black};
  border-radius: 4px;
  height: 100%;
`;

const DeliveryTimeHead = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  padding: 0 4px;
  text-align: center;
`;

const DeliveryTimeData = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 19px;
  line-height: 150%;
  font-weight: 500;
  margin-top: 5px;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 8px;
  height: 100%;

  @media ${device.desktop} {
    height: auto;
  }
`;

const PriceHead = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const PriceColumn = styled(Column)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Price = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 135%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-bottom: 4px;
`;

const PriceOriginal = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-decoration: line-through;
  margin-bottom: 4px;
`;

const PriceVAT = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const TimedDelivery = styled(Row)`
  background: #7D8288;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
padding: 14px 0;
    border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;


  @media ${device.laptop} {
    padding: 14px 40px;
    padding: 0 40px;
  }
`;

const ContainerStyled = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;
const TextWrapper = styled.div`
  color: white;
  font-weight: 400;
`;
const TextP = styled.p<any>`
  margin-bottom: 20px;
  margin-bottom: 10px;
  width: 100%;
    font-size: 14px;

`;
const SubTextP = styled.p<any>`
  margin-top: 2px;
    width: 100%;
  font-size: 12px;

`;
const SpanB = styled.span<any>`
  font-weight: bold;
`;
const SelectWrapper = styled.div`
  color: #2b3131;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;

  & > div {
    border-radius: 4px !important;
  }
`;

const LinkWrapper = styled(A)`
width: 100%;
text-align: center;
margin-top: 0.7rem;
font-size: 0.8rem;
white-space: nowrap;
    &:hover {
    color: ${({ theme }: any) => theme.colors.SecondaryHover};
  }
`;


export default SingleResult;
