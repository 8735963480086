import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import cloneDeep from 'lodash.clonedeep';
import { Button, Column, Row } from '../../../../../../assets/elements';
import device from '../../../../../utils/device';
import Service from '../Service';
import ShipmentDetails from './components/ShipmentDetails';
import InsuranceDetails from './components/InsuranceDetails';
import {
  addOrderToBasket,
  resetState,
  setCustomsDetails as setCustomsDetailsAction,
  setInsuranceDetails,
  setSeaFreightDetails,
  setShipmentDetails,
  sendBasketData,
  setOrderData
} from '../../../../../store/actions';
import { CustomsDetails } from './components/CustomsDetails';
import { addNewAddress } from '../../../../../utils/APICalls/Account';
import { getCookie } from '../../../../../utils/cookies';
import containerTypes from '../../../../../utils/containerTypes.json';

const StepThree = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedInsurance, setSelectedInsurance] = useState<any>({});
  const [insuranceError, setInsuranceError] = useState<any>(false);

  const [shipment, setShipment] = useState<any>([]);
  const [seaFreightData, setSeaFreightData] = useState<any>({
    readyDate: '',
    hazardous: '',
    stackable: '',
    customs: '',
    packageType: '',
  });
  const [buttonDisabled, setButtonDisabled] = useState<any>(true);
  const [inputCount, setInputCount] = useState(1);

  const deliveryCountry =
    useSelector((state: any) => state.order.deliveryAddress.country) || '';
  const vat = useSelector((state: any) => state.order.item.vat) || {
    customs: false,
  };
  const serviceType = useSelector((state: any) => state.order.serviceType);

  const items = useSelector(
    (state: any) => state.order.packageDetails[serviceType]
  ) || [{}];

  const [container, setContainer] = useState(containerTypes.includes(items.loadType));
  const dims = useSelector((state: any) => state.order.dims) || [];
  const order = useSelector<any, any>((state: any) => state.order);

  const deliveryAddress = useSelector(
    (state: any) => state.order.deliveryAddress
  );

  const recipientDetails = useSelector(
    (state: any) => state.order.recipientDetails
  );

  const addressBookToggle = useSelector(
    (state: any) => state.order.addressBookToggle
  );

  const continueButtonClicked = useRef(false)

  const [shipmentErrors, setShipmentErrors] = useState<any>({});

  const [customsDetails, setCustomsDetails] = useState<any>({
    exportReason: '',
    incoTerms: '',
    CPCCode: '',
  });

  const [customsDetailsErrors, setCustomsDetailsErrors] = useState<any>({
    exportReason: false,
    incoTerms: '',
  });

  const [prohibitedErrors, setProhibitedErrors] = useState<any>([]);
  const [prohibitedError, setProhibitedError] = useState(false);

  useEffect(() => {
    if (serviceType === 'pallet') {
      const palletItems: any[] = [];
      if (dims.length) {
        dims.forEach((item: any) => {
          for (let i = 0; i < item.quantity; i++) {
            const value: any = {
              height: item.height,
              width: item.width,
              length: item.length,
              weight: item.weight,
              quantity: 1,
              id: uuid(),
              type: serviceType,
              items: [],
            };

            value.items.push({
              id: uuid(),
              value: '',
              description: '',
            });

            if (vat?.customs || false) {
              value.items[0] = {
                ...value.items[0],
                weight: '',
                quantity: '',
                tariff_code: '',
                manufactured_in: '',
              };
            }

            palletItems.push(value);
          }
        });
      } else {
        items.forEach((item: any) => {
          for (let i = 0; i < item.quantity; i++) {
            const value: any = {
              ...item,
              id: uuid(),
              type: serviceType,
              items: [],
            };

            value.items.push({
              id: uuid(),
              value: '',
              description: '',
            });

            if (vat?.customs || false) {
              value.items[0] = {
                ...value.items[0],
                weight: '',
                quantity: '',
                tariff_code: '',
                manufactured_in: '',
              };
            }

            palletItems.push(value);
          }
        });
      }

      const prohibErrors: any = {};

      palletItems.forEach((item: any) => {
        prohibErrors[item.id] = {};
        item.items.forEach((shipItem: any) => {
          prohibErrors[item.id][shipItem.id] = {
            restricted: false,
            prohibited: false,
          };
        });
      });
      setProhibitedErrors(prohibErrors);
      setShipment(palletItems);
    } else if (serviceType === 'seaFreight') {
      const freightItems: any[] = [];

      items.package.items.forEach((item: any) => {
        for (let i = 0; i < item.quantity.amount; i++) {
          const value: any = {
            ...item,
            id: uuid(),
            type: serviceType,
            items: [],
          };

          value.items.push({
            id: uuid(),
            value: '',
            description: '',
          });

          if (vat?.customs || false) {
            value.items[0] = {
              ...value.items[0],
              weight: '',
              quantity: '',
              tariff_code: '',
              manufactured_in: '',
            };
          }

          freightItems.push(value);
        }
      });

      const prohibErrors: any = {};

      freightItems.forEach((item: any) => {
        prohibErrors[item.id] = {};
        item.items.forEach((shipItem: any) => {
          prohibErrors[item.id][shipItem.id] = {
            restricted: false,
            prohibited: false,
          };
        });
      });
      setProhibitedErrors(prohibErrors);

      setShipment(freightItems);

      setSeaFreightData({
        readyDate: items.readyDate,
        hazardous: items.hazardous,
        stackable: items.stackable,
        customs: items.customs,
        packageType: items.package.subType,
      });
    } else if (serviceType === 'parcel') {
      const tempShip = items.map((item: any) => {
        if (vat.customs) {
          return {
            ...item,
            items: [
              {
                id: uuid(),
                value: '',
                description: '',
                tariff_code: '',
                manufactured_in: '',
                weight: '',
                quantity: '',
              },
            ],
          };
        }

        return {
          ...item,
          items: [
            {
              id: uuid(),
              value: '',
              description: '',
            },
          ],
        };
      });

      setShipment(tempShip);

      const prohibErrors: any = {};

      tempShip.forEach((item: any) => {
        prohibErrors[item.id] = {};
        item.items.forEach((shipItem: any) => {
          prohibErrors[item.id][shipItem.id] = {
            restricted: false,
            prohibited: false,
          };
        });
      });

      setProhibitedErrors(prohibErrors);
    } else if (serviceType === 'freight') {
      if (Array.isArray(items)) {
        let numPallets = 0;
        items.forEach(item => {
          numPallets += item.quantity;
        });
        const loadType = numPallets === 26? 'FTL': 'LTL';
        const newOrder = order;
        newOrder.packageDetails.freight = { numPallets, loadType };
        newOrder.packageDetails.loadType = loadType;
        setOrderData(newOrder);
      }
      if (order.packageDetails[serviceType].loadType === 'LTL') {
        const freightItems: any[] = [];

        for (let i = 0; i < order.packageDetails[serviceType].numPallets; i++) {
          const value: any = {
            id: uuid(),
            type: serviceType,
            items: [],
            numPallets: '',
            weight: '',
            description: '',
            value: '',
          };

          value.items.push({
            id: uuid(),
            value: '',
            description: '',
            weight: ''
          });

          if (vat?.customs || false) {
            value.items[0] = {
              ...value.items[0],
              weight: '',
              quantity: '',
              tariff_code: '',
              manufactured_in: '',
            };
          }

          freightItems.push(value);
        }

        const prohibErrors: any = {};

        freightItems.forEach((item: any) => {
          prohibErrors[item.id] = {};
          item.items.forEach((shipItem: any) => {
            prohibErrors[item.id][shipItem.id] = {
              restricted: false,
              prohibited: false,
            };
          });
        });
        setProhibitedErrors(prohibErrors);
        setShipment(freightItems);
      } else if (items.loadType === 'FTL' && vat?.customs) {
        const freightItems: any[] = [];

        const value: any = {
          id: uuid(),
          type: serviceType,
          items: [],
        };

        value.items.push({
          id: uuid(),
          value: '',
          description: '',
        });

        if (vat?.customs || false) {
          value.items[0] = {
            ...value.items[0],
            weight: '',
            quantity: '',
            tariff_code: '',
            manufactured_in: '',
          };
        }

        freightItems.push(value);

        const prohibErrors: any = {};

        freightItems.forEach((item: any) => {
          prohibErrors[item.id] = {};
          item.items.forEach((shipItem: any) => {
            prohibErrors[item.id][shipItem.id] = {
              restricted: false,
              prohibited: false,
            };
          });
        });
        setProhibitedErrors(prohibErrors);

        setShipment(freightItems);
      } else if (container) {
        const freightItems: any[] = [];

        for (let i = 0; i < order.packageDetails[serviceType].numPallets; i++) {
          let value: any = {
            id: uuid(),
            value: '',
            description: '',
            loadType: items.loadType,
            quantity: 1
          };

          if (vat?.customs) {
            value = {
              ...value,
              weight: '',
              quantity: 1,
              tariff_code: '',
              manufactured_in: '',
            };
          }

          freightItems.push(value);
        }

        const prohibErrors: any = {};

        freightItems.forEach((item: any) => {
          prohibErrors[item.id] = {};
          prohibErrors[item.id] = {
            restricted: false,
            prohibited: false,
          };
        });
        setProhibitedErrors(prohibErrors);
        setShipment(freightItems);
      } else {
        // setSeaFreightData({
        //   readyDate: items.readyDate,
        //   hazardous: items.hazardous,
        //   stackable: items.stackable,
        //   customs: items.customs,
        //   packageType: items.package.subType,
        // });

        const tempShip = [
          {
            id: uuid(),
            value: items?.value || '',
            description: items?.description || '',
            weight: items?.weight || '',
            numPallets: items?.numPallets || '',
            type: serviceType,
          },
        ];

        setShipment(tempShip);

        setProhibitedErrors(
          tempShip.map((item: any) => ({
            id: item.id,
            restricted: false,
            prohibited: false,
          }))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let blank = false;

    if (
      serviceType === 'freight' &&
      items?.loadType === 'FTL' &&
      (!vat?.customs || false)
    ) {
      if (shipment.length > 0) {
        shipment.forEach((ship: any) => {
          if (
            ship.description === '' ||
            ship.value === '' ||
            ship.weight === '' ||
            ship.numPallets === ''
          ) {
            blank = true;
          }
        });
      }
    } else {
      let count = 0;
      shipment.forEach((ship: any) => {
        if (count >= shipment.length) return;
        if (
          ship.description === '' ||
          ship.value === '' ||
          ship.weight === '' ||
          ship.numPallets === ''
        ) {
          blank = true;
        }
        count += Number(ship.numPallets) || 0;
      });
    }

    if (
      serviceType !== 'freight' &&
      serviceType !== 'seaFreight' &&
      !selectedInsurance?.insuranceId
    ) {
      blank = true;
    }

    if (vat?.customs || false) {
      if (customsDetails.exportReason === '') {
        blank = true;
      }

      if (serviceType === 'freight' || serviceType === 'seaFreight') {
        if (customsDetails.incoTerms === '') {
          blank = true;
        }
      }
    }

    if (prohibitedError) {
      blank = true;
    }

    setButtonDisabled(blank);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment, selectedInsurance, customsDetails, prohibitedError]);

  useEffect(() => {
    let error = false;

    Object.values(prohibitedErrors).forEach((item: any) => {
      Object.values(item).forEach((value: any) => {
        if (value.restricted || value.prohibited) {
          error = value;
        }
      });
    });

    setProhibitedError(error);
  }, [prohibitedErrors]);

  const handleFreightChange = (shipmentId: any, key: any, value: any) => {
    if (
      value === '.' ||
      ((key === 'value' || key === 'weight') && isNaN(value)) ||
      !checkDecimals(value)
    ) {
      return;
    }

    const tempErrors = cloneDeep(shipmentErrors);

    if (tempErrors[shipmentId] && tempErrors[shipmentId][key]) {
      delete tempErrors[shipmentId][key];

      setShipmentErrors(tempErrors);
    }

    let newValue: any = '';

    newValue = key === 'weight' || key === 'value' ? Number(value) : value;

    const tempShip = cloneDeep(shipment).map((ship: any) => {
      
      if (ship.id === shipmentId) {
        if (items.loadType === 'LTL') {
          return {
            ...ship,
            [key]: newValue,
            items: [{...ship.items[0], [key]: newValue}]
          };
        }
        return {
          ...ship,
          [key]: newValue,
        };
        
      }
      return ship;
    });

    setShipment(tempShip);
  };

  const resetError = (shipmentId: any, id: any, key: any) => {
    if (shipmentErrors[shipmentId] && shipmentErrors[shipmentId][id]) {
      const errors = cloneDeep(shipmentErrors);
      delete errors[shipmentId][id][key];

      setShipmentErrors({
        ...errors,
      });
    }
  };

  const handleChange = (shipmentId: any, id: any, key: any, value: any) => {
    if (
      value === '.' ||
      ((key === 'value' || key === 'weight' || key === 'quantity') &&
        isNaN(value)) ||
      !checkDecimals(value)
    ) {
      return;
    }

    resetError(shipmentId, id, key);

    let returnValue = value;

    if (key === 'value' || key === 'weight' || key === 'quantity') {
      if (value === '') {
        returnValue = '';
      } else if (value === 0) {
        returnValue = 0;
      } else {
        returnValue = value;
      }
    }

    if (key === 'value') {
      returnValue = Number(value);
    }

    if (key === 'description') {
      returnValue = value.slice(0, 500);
    }

    const tempShip = cloneDeep(shipment).map((ship: any) => {
      if (ship.id === shipmentId) {
        const tempShipItems = ship.items.map((shipItem: any) => {
          if (shipItem.id === id) {
            if (vat.customs && key === 'description') {
              return {
                ...shipItem,
                [key]: returnValue,
                tariff_code: '',
              };
            }

            return {
              ...shipItem,
              [key]: returnValue,
            };
          }

          return shipItem;
        });
        return {
          ...ship,
          items: [...tempShipItems],
        };
      }
      return { ...ship };
    });

    setShipment(tempShip);
  };

  const checkDecimals = (value: any) => {
    if (!value.includes('.')) {
      return true;
    }
    const split = value.split('.');

    return split[1].length <= 2;
  };

  const addNewItem = (id: any) => {
    const newId = uuid();
    let newItem: any = {
      id: newId,
      description: '',
      value: '',
    };

    if (vat.customs) {
      newItem = {
        ...newItem,
        manufactured_in: '',
        tariff_code: '',
        weight: '',
        quantity: '',
      };
    }

    const tempShip = shipment.map((ship: any) => {
      if (ship.id === id) {
        return {
          ...ship,
          items: [...ship.items, { ...newItem }],
        };
      }

      const tempProhib = cloneDeep(prohibitedErrors);
      tempProhib[id][newId] = {
        prohibited: false,
        restricted: false,
      };

      setProhibitedErrors(tempProhib);

      return ship;
    });

    setShipment(tempShip);
  };

  const removeItem = (shipmentId: any, itemId: any) => {
    const tempShip = shipment.map((ship: any) => {
      if (ship.id === shipmentId) {
        return {
          ...ship,
          items: ship.items.filter((item: any) => item.id !== itemId),
        };
      }

      return ship;
    });

    setShipment(tempShip);

    const tempProhib = cloneDeep(prohibitedErrors);

    delete tempProhib[shipmentId][itemId];

    setProhibitedErrors(tempProhib);
  };

  const copyToAllParcels = () => {
    const tempProhib = cloneDeep(prohibitedErrors);

    const copyItems = cloneDeep(shipment[0].items);

    const tempShip = shipment.map((ship: any, i: any) => {
      if (i !== 0) {
        return {
          ...ship,
          items: copyItems.map((ci: any) => {
            const newId = uuid();

            tempProhib[ship.id][newId] = {
              restricted: false,
              prohibited: false,
            };

            return {
              ...ci,
              id: newId,
            };
          }),
        };
      }

      return ship;
    });

    setProhibitedErrors(tempProhib);
    setShipment(tempShip);
  };

  const handleNumPalletsChange = (id: any, value: any) => {
    if (items.loadType === 'LTL') {
      let count = 0;
      let inputs = 0;
      const tempErrors = cloneDeep(shipmentErrors);
      setShipment(
        shipment.map((ship: any) => {
          if (ship.id === id) {
            count += value;
            if (count > shipment.length) {
              alert(`Total quantity cant be more than ${shipment.length}`);
              inputs += 1;
              delete ship.numPallets;
            } else {
              inputs += 1;
              ship.numPallets = value;
            }
          } else if (ship.numPallets && count < shipment.length) {
            inputs += 1;
            count += ship.numPallets;
          } else if (count + ship.numPallets > shipment.length) {
            delete ship.numPallets;
          }
          if (tempErrors[ship.id] && tempErrors[ship.id].totalQuantity) tempErrors[ship.id].totalQuantity = '';
          return ship;
        })
      );
      setShipmentErrors(tempErrors);
      count < shipment.length ? setInputCount(inputs + 1) : setInputCount(inputs);
    } else {
      setShipment(
        shipment.map((ship: any) => {
          if (ship.id === id) {
            return {
              ...ship,
              numPallets: value,
            };
          }

          return { ...ship };
        })
      );
    }
  };

  const validateShipment = () => {
    let error = false;
    let tempErrors: any = {};

    if (serviceType === 'freight' && items.loadType === 'FTL') {
      shipment.forEach((ship: any) => {
        tempErrors = {
          ...tempErrors,
          [ship.id]: {
            ...tempErrors[ship.id],
            description: ship.description === '' ? 'Required' : false,
            value: ship.value === '' ? 'Required' : false,
            numPallets: ship?.numPallets === '' ? 'Required' : false,
            weight: ship?.weight === '' ? 'Required' : false,
            manufactured_in:
              (vat.customs &&
                (ship?.manufactured_in === '' ? 'Required' : false)) ||
              false,
          },
        };
        if (
          tempErrors[ship.id].description ||
          tempErrors[ship.id].value ||
          tempErrors[ship.id].weight ||
          tempErrors[ship.id].numPallets ||
          (vat.customs && tempErrors[ship.id].tariff_code) ||
          (vat.customs && tempErrors[ship.id].manufactured_in)
        ) {
          error = true;
        }
      });
    }

    if (serviceType === 'freight' && items.loadType === 'LTL') {
      const filteredShipment = shipment.filter((x: any) => { return x.numPallets; });
      const quantity = filteredShipment.reduce((acc: number, ship: any) => acc + ship.numPallets, 0);
      let totalQuantityError = '';
      if (shipment.length !== quantity) {
        error = true;
        totalQuantityError = `Total quantity must be ${shipment.length}`;
      }
      filteredShipment.forEach((ship: any) => {
        ship.items.forEach((item: any) => {
          let tariffError: any = false;
          let descriptionError: any = false;

          if (vat.customs) {
            const tempTariff = item.tariff_code.replaceAll('.', '');

            if (tempTariff === '') {
              tariffError = 'Required';
            } else if (tempTariff.length < 8 || tempTariff.length > 10) {
              tariffError =
                'Tariff code must be between 8 and 10 characters (excluding .)';
            }
          }

          if (item?.description === '' || item?.description.length < 3) {
            descriptionError = 'Description must be at least 3 characters';
          }

          tempErrors = {
            ...tempErrors,
            [ship.id]: {
              totalQuantity: totalQuantityError === '' ? false : totalQuantityError,
              description: descriptionError,
              value: item.value === '' ? 'Required' : false,
              quantity: item?.quantity === '' ? 'Required' : false,
              weight: item?.weight === '' ? 'Required' : false,
              tariff_code: tariffError,
              manufactured_in:
                  (vat.customs &&
                    (item?.manufactured_in === '' ? 'Required' : false)) ||
                  false,
            },
          };

          if (
            tempErrors[ship.id].description ||
            tempErrors[ship.id].value ||
            (vat.customs && tempErrors[ship.id].tariff_code) ||
            (vat.customs && tempErrors[ship.id].manufactured_in)
          ) {
            error = true;
          }
        });
      })
    } else if (container) {
      shipment.forEach((ship: any) => {
        let tariffError: any = false;
        let descriptionError: any = false;

        if (vat.customs) {
          const tempTariff = ship.tariff_code.replaceAll('.', '');

          if (tempTariff === '') {
            tariffError = 'Required';
          } else if (tempTariff.length < 8 || tempTariff.length > 10) {
            tariffError =
              'Tariff code must be between 8 and 10 characters (excluding .)';
          }
        }

        if (ship?.description === '' || ship?.description.length < 3) {
          descriptionError = 'Description must be at least 3 characters';
        }

        tempErrors = {
          ...tempErrors,
          [ship.id]: {
            ...tempErrors[ship.id],
            description: descriptionError,
            value: ship.value === '' ? 'Required' : false,
            quantity: ship?.quantity === '' ? 'Required' : false,
            tariff_code: tariffError,
            manufactured_in:
              (vat.customs &&
                (ship?.manufactured_in === '' ? 'Required' : false)) ||
              false,
          },
        };

        if (
          tempErrors[ship.id].description ||
          tempErrors[ship.id].value ||
          (vat.customs && tempErrors[ship.id].tariff_code) ||
          (vat.customs && tempErrors[ship.id].manufactured_in)
        ) {
          error = true;
        }
      })
      setShipmentErrors({ ...tempErrors });
      return error;
    }
  };


  const handleInsuranceChange = (value: any) => {
    if (insuranceError) {
      setInsuranceError(false);
    }

    dispatch(setInsuranceDetails(value));
    setSelectedInsurance(value);
  };

  const validateInsurance = () => {
    if (
      serviceType !== 'freight' &&
      serviceType !== 'seaFreight' &&
      !selectedInsurance?.insuranceId
    ) {
      setInsuranceError('Required');

      return true;
    }

    return false;
  };

  const validateCustoms = () => {
    if (!vat.customs) {
      return false;
    }

    let errors = { ...customsDetailsErrors };
    let error = false;

    if (customsDetails.exportReason === '') {
      errors = {
        ...errors,
        exportReason: 'Required',
      };

      error = true;
    }

    if (serviceType === 'freight' || serviceType === 'seaFreight') {
      if (customsDetails.incoTerms === '') {
        errors = {
          ...errors,
          incoTerms: 'Required',
        };

        error = true;
      }
    }

    setCustomsDetailsErrors({
      ...customsDetailsErrors,
      ...errors,
    });

    return error;
  };

  const formatAddress = () => {
    const newAddress = { ...deliveryAddress, ...recipientDetails };

    newAddress.addressLine1 = newAddress.addressOne;
    newAddress.addressLine2 = newAddress.addressTwo;
    newAddress.addressLine3 = newAddress.addressThree;
    newAddress.isDefault = false;

    delete newAddress.adressOne;
    delete newAddress.adressTwo;
    delete newAddress.adressThree;

    return newAddress;
  };

  const formatTariff = () => {
    if (!vat.customs) return shipment;
    return shipment.map((ship: any) => {
      if (ship.items?.length) {
        return {
          ...ship,
          items: ship.items.map((item: any) => {
            return {
              ...item,
              tariff_code: item.tariff_code.replaceAll('.', ''),
            };
          }),
        };
      } 
      return {
        ...ship,
        tariff_code: ship?.tariff_code?.replaceAll('.', ''),
      };
    });
  };

  const handleSubmit = async () => {
    const error = validateShipment();
    const errorInsurance = validateInsurance();
    const errorCustoms = validateCustoms();

    if (error || errorInsurance || errorCustoms || prohibitedError) {
      return;
    }

    if (addressBookToggle) {
      addNewAddress(formatAddress());
    }

    if (serviceType === 'freight') {
      dispatch(
        setInsuranceDetails({
          id: 18,
          price: 0,
          level: 25,
          insuranceId: 18,
        })
      );
    }

    if (serviceType === 'seaFreight') {
      dispatch(setSeaFreightDetails(seaFreightData));
    }
    dispatch(setCustomsDetailsAction(customsDetails));
    let shipmentDetails: any;
    if (serviceType === 'freight' && (items.loadType === 'FTL' || container))  {
      shipmentDetails = shipment;
    } else {
      shipmentDetails = formatTariff();
    }
    dispatch(setShipmentDetails(shipmentDetails, serviceType, order.packageDetails[serviceType].loadType));
    continueButtonClicked.current = true;
    dispatch(addOrderToBasket());
  };


    useEffect(() => {

      const captureData = async (dataToSend:any) => {
        const res = await sendBasketData(dataToSend)
        history.push('/order-basket');
        dispatch(resetState());
      }

      if(continueButtonClicked.current){
        const currentShipment = order.dims?.length? order.dims : order.packageDetails;
        currentShipment.vat_information = order.vat_information
        const dataToSend = {
          shipment: currentShipment,
          status:"added_to_basket",
          currentStep: order.currentStep,
          basketItemId: order.basketItemId,
          basket: {
            basketId: getCookie("basketId"),
            status: "item_added",
            itemCount: getCookie("basket")
          }
        }

      captureData(dataToSend).catch(console.error)
      continueButtonClicked.current = false
      }
  }, [order]);



  const handleBack = () => {
    history.push('/order-step2');
  };

  const itemsLength = () => {
    if (serviceType === 'freight') {
      return 1;
    }

    if (serviceType === 'seaFreight') {
      return items.package.items.length;
    }

    if (serviceType === 'parcel') {
      return items.length;
    }

    if (dims.length > 0) {
      return dims.reduce(
        (acc: any, current: any) => Number(acc) + Number(current.quantity),
        0
      );
    } else {
      return items.reduce(
        (acc: any, current: any) => Number(acc) + Number(current.quantity),
        0
      );
    }
  };

  return (
    <>
      <RowStyled id="service-row">
        <ColumnStyled id="service-column" sizeL={7} sizeM={7} sizeS={3.5}>
          <ShipmentDetails
            onChange={handleChange}
            onFreightChange={handleFreightChange}
            onNumPalletsChange={handleNumPalletsChange}
            shipment={shipment}
            itemsLength={itemsLength()}
            errors={shipmentErrors}
            vat={vat}
            deliveryCountry={deliveryCountry}
            prohibitedErrors={prohibitedErrors}
            setProhibitedErrors={setProhibitedErrors}
            serviceType={serviceType}
            numPallets={
              serviceType === 'freight' ? items?.numPallets || items[0]?.quantity || 26 : 26
            }
            addItem={addNewItem}
            removeItem={removeItem}
            copyToAll={copyToAllParcels}
            inputCount={inputCount}
            container={container}
          />
          {(vat?.customs || false) && (
            <CustomsDetails
              details={customsDetails}
              setDetails={setCustomsDetails}
              errors={customsDetailsErrors}
              setErrors={setCustomsDetailsErrors}
              serviceType={serviceType}
            />
          )}
          <InsuranceDetails
            insurance={selectedInsurance}
            changeInsurance={handleInsuranceChange}
            shipment={shipment}
            error={insuranceError}
            serviceType={serviceType}
          />
        </ColumnStyled>
        <Service />
      </RowStyled>
      <RowLast>
        <ColumnLast sizeL={7} sizeM={7} sizeS={3.5}>
          <Button color="black" onClick={handleBack}>
            Back
          </Button>
          <Button
            color="secondary"
            disable={buttonDisabled}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </ColumnLast>
      </RowLast>
    </>
  );
};

const RowStyled = styled(Row)`
  padding-top: 64px;
  justify-content: flex-start;

  @media ${device.laptop} {
    padding-top: 32px;
  }
`;

const RowLast = styled(Row)`
  padding-bottom: 40px;
  justify-content: flex-start;
  margin-top: 32px;
`;

const ColumnStyled = styled(Column)`
  padding: 0;

  @media ${device.tablet} {
    padding: 0 15px;
  }
`;

const ColumnLast = styled(Column)`
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${device.tablet} {
    padding: 0 15px;
  }
`;

export default StepThree;
