import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { landingPageUrl } from '../../../../utils/env';
import { A, Button, Caption, Column, H6, PSmall } from '../../../../../assets/elements';
import device from '../../../../utils/device';
import { SelectInput } from '../../../Inputs';
import { validateMultiBookCSV } from '../../../../utils/APICalls/Account';
import Loader from '../../../Loader';
import { authorize, importFailed, importStatus } from '../../../../utils/APICalls/Sockets';

const typeOfServices = [{
  label: 'Cheapest',
  value: 'cheapest',
}, {
  label: 'Quickest',
  value: 'quickest',
}];

const StepOne = ({
  setCurrentStep,
  setRatesData,
  setFiles,
  rows,
  setRows,
  serviceType,
  setServiceType,
  setImportError,
}: any) => {
  useEffect(() => {
    authorize((res: { result: boolean }) => {
      if (!res.result) {
        // throw new Error('Unauthorized');
      }

      importStatus((data: any) => {
        const temp = data[Object.keys(data)[0]];

        setRatesData({
          ...temp,
          rate: {
            ...temp.rate,
            selectedInsurance: {},
            selectedCollectionDate: '',
            exportReason: '',
            key: uuid(),
          },
        });
      });

      importFailed((error: any) => {
        setImportError(error);
      });
    });
  }, []);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(false);
  const [success, setSuccess] = useState(false);
  const [myFiles, setMyFiles] = useState([]);

  const setErrorText = (errorText: any) => {
    return (
      <>
        {
          errorText.map((e: any) => {
            return (
              <>
                {
                  Object.keys(e).map((key: any, i: any) => {
                    return <p key={key + i}>{key}: {e[key].errors[0]}</p>;
                  })
                }
              </>
            );
          })
        }
      </>
    );
  };

  const onDrop = useCallback((files: any) => {
    if (!files[0]) {
      return;
    }

    setMyFiles(files);

    setLoading(true);

    const formData = new FormData();
    formData.append('file', files[0]);

    validateMultiBookCSV(formData)
      .then((res: any) => {
        setRows(rows + res.data.rows);

        setError(false);
        setSuccess(true);
      })
      .catch(({ errorDetails: { details } }: any = { errorDetails: { details: '' } }) => {
        setError(setErrorText(details));
        setSuccess(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (myFiles[0] !== undefined) {
      setFiles(myFiles[0]);
    }
  }, [myFiles]);

  const {
    getRootProps,
    getInputProps,
    open,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop,
    accept: '.csv',
  });

  const goToGuide = () => {
    window.open(`${landingPageUrl}faq/multi-book`, '_blank');
  };

  // @ts-ignore
  const acceptedFile = () => myFiles.length > 0 ? myFiles[0].path : '';

  const handleServiceChange = (value: any) => {
    setServiceType(value);
  };

  const uploadAnother = () => {
    setError(false);
    setLoading(false);
    setSuccess(false);
    setMyFiles([]);
  };

  const handleProceed = () => {
    setCurrentStep(2);
  };

  const downloadTemplate = (e: any) => {
    e.preventDefault();
    window.open('https://egistics.s3.eu-west-2.amazonaws.com/Files/multibook-template.csv', '_blank');
  };

  return (
    <>
      <ColumnStyled sizeL={6} sizeM={6} sizeS={5} sizeXS={4}>
        <Card>
          <H6Styled>
            Download our CSV template to book multiple parcels in just 2 easy steps.
          </H6Styled>
          <ButtonStyled
            color="primary"
            block
            onClick={downloadTemplate}
          >
            Get the template
          </ButtonStyled>
          <TopTips>
            Our top tips:
            <Ul>
              <Li>All required fields in the template contain sample data in the download for easy uploading</Li>
              <Li>Each row should contain only one order</Li>
              <Li>Please note there is a maximum of 50 orders in 1 upload</Li>
            </Ul>
          </TopTips>
          <TopTips>
            If you're still having troubles,
            our guide is sure to help so take a look –
            it doesn’t take long to become an expert.
            <GuideLink onClick={goToGuide}>Guide to using the CSV upload tool</GuideLink>
          </TopTips>
        </Card>
      </ColumnStyled>
      <ColumnStyled sizeL={6} sizeM={6} sizeS={5} sizeXS={4}>
        <Card>
          <H6Styled>
            Upload CSV file
          </H6Styled>
          {
            myFiles.length > 0 ? (
              <>
                {
                  loading && <Loader>Loading</Loader>
                }
                {
                  !loading && (success ? (
                    <UploadBox>
                      <UploadedTitle>Uploaded document</UploadedTitle>
                      <UploadedFileName>{ acceptedFile() }</UploadedFileName>
                      <SuccessBox>
                        { rows } order{ rows === 0 || rows > 1 ? 's have ' : ' has ' }
                        been uploaded
                      </SuccessBox>
                    </UploadBox>
                  ) : (
                    <UploadBox>
                      <UploadedTitle>Uploaded document</UploadedTitle>
                      <UploadedFileName>{ acceptedFile() }</UploadedFileName>
                      <ErrorBox>
                        We were not able to upload your document as{' '}
                        data in the following columns does not look correct.
                        Please try again.
                        {error && error}
                      </ErrorBox>
                    </UploadBox>
                  ))
                }
              </>

            ) : (
              <UploadBox {...getRootProps()}>
                <input {...getInputProps()} />
                <UploadInfo>Drag your CSV file here</UploadInfo>
                <UploadInfo>or, if you prefer</UploadInfo>
                <Button
                  color="black"
                  onClick={open}
                >
                  Upload from your device
                </Button>
              </UploadBox>
            )
          }

          {
            myFiles.length > 0 && (
              <>
                <ServiceBox>
                  <LabelStyled>
                    What type of service do you want?
                  </LabelStyled>
                  <SelectInput
                    title="Please select collection address"
                    data={typeOfServices}
                    error={false}
                    resetError={() => []}
                    defaultValue={1}
                    value={serviceType}
                    onChange={handleServiceChange}
                  />
                </ServiceBox>
                <ButtonAction
                  color="secondary"
                  block
                  disable={loading || !!error}
                  disabled={loading || !!error}
                  onClick={handleProceed}
                >
                  Proceed
                </ButtonAction>
                <ButtonAction
                  color="black"
                  block
                  onClick={uploadAnother}
                  disable={loading}
                  disabled={loading}
                >
                  Upload another file
                </ButtonAction>
              </>
            )
          }
        </Card>
      </ColumnStyled>
    </>
  );
};

const H6Styled = styled(H6)`
  margin-bottom: 32px;
`;

const Card = styled.div`
  background: ${({ theme }: any) => theme.colors.White};
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};
  padding: 32px 34px;
`;

const ColumnStyled = styled(Column)`
  margin-bottom: 24px;

  @media ${device.laptopL} {
    margin-bottom: 0;
  }
`;

const ButtonStyled = styled(Button)`
  margin-bottom: 32px;
`;

const TopTips = styled(PSmall)`
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const GuideLink = styled(A)`
  margin-top: 8px;
`;

const Ul = styled.ul``;

const Li = styled.li`
  margin-bottom: 8px;

  &:first-child {
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  :before {
    content: '•';
    margin-right: 8px;
  }
`;

const UploadBox = styled.div`
  border: 1px dashed ${({ theme }: any) => theme.colors.LightGrey};
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UploadInfo = styled(PSmall)`
  color: ${({ theme }: any) => theme.colors.MidGrey};

  &:last-of-type {
    margin-bottom: 24px;
  }
`;

const UploadedTitle = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.MidGrey};
  margin-bottom: 8px;
`;

const UploadedFileName = styled(PSmall)`
  color: ${({ theme }: any) => theme.colors.Black};
`;

const SuccessBox = styled.div`
  color: #148D47;
  background: #EEF4E2;
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  padding: 8px;
  text-align: center;
  margin-top: 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  width: 100%;
`;

const ErrorBox = styled(SuccessBox)`
  color: ${({ theme }: any) => theme.colors.Error};
  background: #F9DBE0;
`;
const ServiceBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 32px 0;
`;

const LabelStyled = styled(PSmall)`
  margin-bottom: 16px;
`;

const ButtonAction = styled(Button)`
  &:first-of-type {
    margin-bottom: 16px;
  }
`;

export default StepOne;
