import React, { useRef } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Settings from './components/Settings';
import { Column, Container, Row } from '../../../assets/elements';
import { landingPageUrl } from '../../utils/env';
import Dashboard from './components/Dashboard';
import device from '../../utils/device';
import Invoices from './components/Invoices';
import AddressBook from './components/AddressBook';
import { AdditionalTopup } from './components/AdditionalMessage';

const MyAccount = (props: any) => {
  const session = useSelector((state: any) => state.user.session);
  const location = useLocation();
  const topUpRef = useRef(null);

  if (session.accessToken === '') {
    window.location.href = landingPageUrl;

    return <></>;
  }

  return (
    <>
      <Wrapper>
        <ContainerStyled>
          <Row>
            <Column sizeL={10} sizeM={12} sizeS={6} sizeXS={4}>
              <Switch location={props.location}>
                <Route path="/settings" component={() => <Settings />} />
                <Route
                  path="/dashboard"
                  component={() => <Dashboard topupRef={topUpRef} session={session} />}
                />
                <Route path="/invoices" component={() => <Invoices />} />
                <Route path="/address-book" component={() => <AddressBook />} />
              </Switch>
            </Column>
          </Row>
        </ContainerStyled>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 76px);
  background-color: #e5e5e5;
`;

const ContainerStyled = styled(Container)`
  padding: 32px 0;

  @media ${device.laptop} {
    padding: 48px 0;
  }

  @media ${device.laptopL} {
    padding: 72px 0 !important;
  }
`;

export default MyAccount;
