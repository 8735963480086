import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FilterItem from './FilterItem';
import device from '../../../utils/device';

import UpIcon from '../../../../assets/icons/UpWhite.svg';
import DownIcon from '../../../../assets/icons/DownWhite.svg';

const MultiOptions = ({ options, onChange, selected, title }: any) => {
  const [filterOpen, setFilterOpen] = useState(false);

  const clickFunction = () => {
    setFilterOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', clickFunction, false);

    return () => {
      document.removeEventListener('click', clickFunction, false);
    };
  }, []);

  const handleClick = (value: any) => {
    onChange(value);
    setFilterOpen(false);
  };

  const handleSelectedClick = (e: any) => {
    e.nativeEvent.stopImmediatePropagation();

    setFilterOpen(!filterOpen);
  };

  return (
    <Container>
      <Title>{ title }</Title>
      <FilterContainer>
        <SelectedFilter onClick={(e) => handleSelectedClick(e)}>
          {options.filter(({ name, id }: any) => selected === id)[0]?.label || ''}
          <img src={filterOpen ? UpIcon : DownIcon} alt="Dropdown arrow" />
        </SelectedFilter>
        <Wrapper open={filterOpen}>
          {options.map(({ id, label }: any) => (
            <FilterItem
              key={id}
              id={id}
              selected={selected === id}
              onClick={() => handleClick(id)}
            >
              {label}
            </FilterItem>
          ))}
        </Wrapper>
      </FilterContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column !important;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: auto;
`;

const Wrapper = styled.div.attrs(({ open }: any) => ({
  open,
}))`
  display: ${({ open }: any) => (open ? 'flex' : 'none')};

  ${({ open, theme }: any) =>
    open
      ? `position: absolute;
    min-width: 200px;
    left: 0;
    top: 100%;
    flex-direction: column;
    background: ${theme.colors.White};
    box-shadow: ${theme.box.BoxShadow};
    z-index: 2;
    padding: 8px;

    & > div {
      width: 100%;
      background: ${theme.colors.White};
      color: ${theme.colors.Black};
      margin-right: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.01em;
      padding: 8px
    }`
      : ''}

  @media ${device.desktop} {
    display: flex;
    flex-direction: row;
    position: relative;
    min-width: unset;
  }
`;

const SelectedFilter = styled.div`
  padding: 8px 20px;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }: any) => theme.colors.Black};
  color: ${({ theme }: any) => theme.colors.White};
  border-radius: 60px;
  margin-right: 0;
  align-items: center;

  @media ${device.desktop} {
    display: none;
  }
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  margin-bottom: 8px;
`;

export default MultiOptions;
