import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import useDebounce from '../../../../../../../utils/useDebounce';

import { Input, Label } from '../../../../../../../../assets/elements';
import { SelectInput } from '../../../../../../Inputs';
import { PostcoderKey } from '../../../../../../../utils/env';
import Required from '../../../../../../../../assets/elements/Required';

const PostcodeBox = ({
  address,
  setAddress,
  theme,
  addressErrors: errors,
  setAddressErrors: setErrors,
  setManually,
}: any) => {
  const [data, setData] = useState([]);

  const debouncedSearchTerm = useDebounce(address.zip, 500);

  const handlePostalCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAddress({
      ...address,
      zip: e.target.value,
    });

    setErrors({
      ...errors,
      zip: false,
    });
  };

  const handleAddressChange = (selected: any) => {
    setAddress({
      ...address,
      ...selected,
    });

    setErrors({
      ...errors,
      company: selected.company !== '' && (selected.company.length < 3 || selected.company.length > 34) ? 'This must be between 3 and 34 characters' : false,
      addressOne: selected.addressOne.length > 30 ? 'This must be less than 30 characters' : false,
      addressTwo: (selected.addressTwo !== '' && selected.addressTwo.length > 30) ? 'This must be less than 30 characters' : false,
      city: (selected.city !== '' && selected.city.length > 30) ? 'This must be less than 30 characters' : false,
      country: false,
      zip: false,
    });

    setManually(true);
  };

  const fetchPostCoder = () => {
    axios
      .get(
        `https://ws.postcoder.com/pcw/${PostcoderKey}/address/${address.country}/${address.zip}?format=json&lines=3`
      )
      .then((response: any) => {
        setData(
          response.data.map((item: any) => {
            return {
              value: {
                addressOne: item?.addressline1 || item?.street || '',
                addressTwo: item?.addressline2 || '',
                addressThree: item?.addressline3 || '',
                city: item?.posttown || '',
                company: item?.organisation || '',
              },
              label: item.summaryline,
            };
          })
        );
      })
      .catch(() => {
        return [];
      });
  };

  React.useEffect(() => {
    if (debouncedSearchTerm) {
      fetchPostCoder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <>
      {address.zip ? (
        <SubTitle>
          You have requested collection from{' '}
          <span style={{ fontWeight: 'bold' }}>{address.zip}</span>. Choose an
          address from the list below
        </SubTitle>
      ) : (
        <SubTitle>Select the collection address below</SubTitle>
      )}
      <LabelStyled>Postcode</LabelStyled>
      <Input
        placeholder="Postcode"
        value={address.zip}
        onChange={(e: any) => handlePostalCodeChange(e)}
        theme={theme}
        type="text"
        error={false}
        autoComplete="off"
        disabled={true}
      />
      <LabelStyled><Required />Collection Address</LabelStyled>
      <SelectWrapper>
        <SelectInput
          title="Please select collection address"
          data={data}
          error={
            errors.addressOne || errors.city || errors.country || errors.zip
          }
          resetError={() => []}
          defaultValue="GB"
          onChange={handleAddressChange}
          noOptionsMessage="No options found. Please enter manually."
        />
      </SelectWrapper>
    </>
  );
};

const SubTitle = styled.p<any>`
  margin: 20px 0;
  line-height: 150%;
`;
const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: normal;
  margin-top: 30px;
`;
const SelectWrapper = styled.div`
  height: 50px;
`;
export default PostcodeBox;
