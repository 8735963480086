import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import Service from './Service';
import {
  Column,
  Button,
  Row,
  Container,
  H4,
  PSmall,
  Caption,
  Input,
} from '../../../../../../assets/elements';
import device from '../../../../../utils/device';
import PaymentMethods from './components/PaymentMethods';
import CardDetails from './components/PaymentMethods/components/CardDetails';
import {
  createOrderRequest,
  completeStripeTransaction,
  resetBasket,
  setOrderData,
  sendBasketData
} from '../../../../../store/actions';
import { GTMECommerce } from '../../../../../utils/tracking';
import TechnicalError from './components/TechnicalError';
import AccountCredit from './components/PaymentMethods/components/AccountCredit';
import BuyNowPayLater from './components/PaymentMethods/components/BuyNowPayLater';
import { useQuery } from '../../../../../utils/hooks/useQuery';
import { getUserInfo } from '../../../../../utils/APICalls/Account';
import ProcessingPayment from './components/ProcessingPayment';
import { UK_VAT } from '../../../../../utils/variables';
import NICheck from '../../../../../utils/NICheck';
import { getCookie, setCookie } from '../../../../../utils/cookies';

const Payment = ({ theme }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const query = useQuery();

  const loggedIn = useSelector<any, any>(
    (state: any) => state.user.session.accessToken !== ''
  );

  const bookerEmail = useSelector<any, any>((state) => state.main.bookerEmail);

  const payError = query.get('error') || false;

  const [email, setEmail] = useState(bookerEmail);
  const [emailError, setEmailError] = useState<any>(false);

  const [selectedMethod, setSelectedMethod] = useState('');
  const [paymentError, setPaymentError] = useState<any>(false);
  const [creditError, setCreditError] = useState(false);
  const [technicalError, setTechnicalError] = useState<any>(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [acceptedKriyaStatement, setAcceptedKriyaStatement] = useState(false);

  const discount = useSelector((state: any) => state.main.discount);

  const [billingAddress, setBillingAddress] = useState({
    zip: '',
  });

  const [cardDetailsErrors, setCardDetailsErrors] = useState<any>({
    zip: null,
    cardNumber: null,
    expiryDate: null,
    cvc: null,
  });

  const basket =
    useSelector<any, any>((state: any) => state.order.basket) || [];

  const checkForNI = (item: any) =>
    item.serviceType === 'pallet' &&
    NICheck(item.collectionAddress, item.deliveryAddress);

  useEffect(() => {
    if (basket.length) {
      let quotes = 0;
      let palletAndWrap = 0;
      let insurance = 0;
      let VAT = 0;

      basket.forEach((item: any) => {
        quotes += Number(item.item.price);
        palletAndWrap += Number(
          item?.palletAndWrap ? 25 - 25 * ((discount?.value || 0) / 100) : 0
        );
        insurance += Number(item.item?.insurance?.price || 0);
        VAT +=
          !item.item.vat.customs || checkForNI(item)
            ? item.item.priceIncVat -
              item.item.price +
              Number(item.item?.insurance?.price || 0) * (UK_VAT / 100) +
              (palletAndWrap * (UK_VAT / 100))
            : 0;
      });

      setTotalPrice(quotes + palletAndWrap + insurance + VAT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basket, discount]);

  useEffect(() => {
    if (payError && payError === 'true') {
      setPaymentError(true);
    } else if (payError && payError === 'technical') {
      setTechnicalError(query.get('order'));
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      getUserInfo().then((res: any) => {
        setEmail(res?.data?.emails[0]?.address || '');
      });
    }
  }, [loggedIn]);

  useEffect(() => {
    let disabled = false;

    if (email === '') {
      disabled = true;
    }

    if (selectedMethod === 'stripe') {
      if (billingAddress.zip === '') {
        disabled = true;
      }

      if (validateCardDetails()) disabled = true;

    } else if (selectedMethod === 'balance') {
      if (creditError) {
        disabled = true;
      }
    } else if (selectedMethod === 'kriya' && !acceptedKriyaStatement) {
      disabled = true
    }
    setButtonDisabled(disabled);
  }, [selectedMethod, email, creditError, billingAddress, acceptedKriyaStatement, cardDetailsErrors.zip, cardDetailsErrors.cardNumber, cardDetailsErrors.expiryDate, cardDetailsErrors.cvc]);

  const handleKriyaAcceptance = () => {
    setAcceptedKriyaStatement(!acceptedKriyaStatement)
  }

  const handleSubmit = async (e: any) => {
    e.nativeEvent.preventDefault();
    await captureData('payment_attempted');
    let errorCard = false;
    const errorEmail = validateEmail();

    if (selectedMethod === 'stripe') {
      errorCard = validateCardDetails();
    }

    if (
      (selectedMethod === 'stripe' && (!stripe || !elements)) ||
      (selectedMethod === 'balance' && creditError) ||
      errorEmail ||
      errorCard
    ) {
      return;
    }

    setProcessingPayment(true);

    if (selectedMethod === 'stripe') {
      if (stripe === null) {
        throw new Error('BUG: stripe is null');
      }
      if (elements === null) {
        throw new Error('BUG: stripe elements is null');
      }
      const cardNumberElement = elements.getElement('cardNumber');

      if (cardNumberElement === null) {
        throw new Error('BUG: stripe elements element is null');
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          email,
          address: {
            postal_code: billingAddress.zip,
          },
        },
      });
      // if (paymentMethod === undefined) {
      //   console.log(33333, error)
      //   throw new Error('BUG: Payment method is undefined');
      // }

      if (error || !paymentMethod) {
        setPaymentError(error);
        setProcessingPayment(false);
      } else {
        const request = generateRequest(paymentMethod.id);

        createOrder(request);
      }
    } else if (selectedMethod === 'balance') {
      const request = generateRequest('Account Payment');
      createOrder(request);
      
    } else if (selectedMethod === 'paypal') {
      const request = generateRequest(selectedMethod);
      createPaypalOrder(request);

    } else if (selectedMethod === 'directdebit' || selectedMethod === 'finance') {
      const request = generateRequest('weekly');
      createOrder(request);

    } else if (selectedMethod === 'kriya') {
      const request = generateRequest('kriya');
      createKriyaOrder(request);
    }
  };

  const captureData = async (status: string, orderGroupId?: string) => {
    try{
      const dataToSend = {
        /* currentStep:
          6 = payment_attempted
          7 = order_made
        */

        currentStep: status === 'order_made' ? 7 : 6,
        basket: {
          basketId:getCookie("basketId"),
          status,
          orderGroupId
        }
      };

      const response = await sendBasketData(dataToSend);
      if (status === 'order_made') {
        setCookie([
          {
            name: 'basketId',
            value: 'undefined',
          },
        ]);
      }
      return response;
    } catch (error) {
      console.log(`Error sending data: ${error}`);
      throw error;
    }
  };

  const createKriyaOrder = (data: any) => {
    createOrderRequest(data)
      .then((res: any) => {
        if (!res.data.error) {
          window.location.href = res.data.payment.approveLink
        } else {
          setTechnicalError(true);
          setProcessingPayment(false);
        }
      })
      .catch((exception: any) => {
        if (exception?.errorDetails?.details?.technical) {
          setTechnicalError(
            exception.errorDetails.details.orderGroupId || true
          );
        } else {
          setPaymentError(exception?.message || 'Error');
        }
        setProcessingPayment(false);
      });
  };

  const createPaypalOrder = (data: any) => {
    createOrderRequest(data)
      .then((res: any) => {
        if (!res.data.error) {
          window.location.href = res.data.payment.approveLink;
        } else {
          setTechnicalError(true);
          setProcessingPayment(false);
        }
      })
      .catch((exception: any) => {
        if (exception?.errorDetails?.details?.technical) {
          setTechnicalError(
            exception.errorDetails.details.orderGroupId || true
          );
        } else {
          setPaymentError(exception?.message || 'Error');
        }
        setProcessingPayment(false);
      });
  };

  const handleStripeSCA = async (data: any, result: any) => {
    if (result.error) {
      setPaymentError('Payment failed please try again.');
      setProcessingPayment(false);
    } else {
      setPaymentError(false);
      completeStripeTransaction(result.paymentIntent.client_secret)
        .then((res: any) => {
          showOrderSuccessfullyBooked(res);
          setProcessingPayment(false);
        })
        .catch((exception: any) => {
          if (exception?.errorDetails?.details?.technical) {
            setTechnicalError(
              exception?.errorDetails?.details?.orderGroupId || true
            );
          } else {
            setPaymentError(exception?.message || 'Error with payment.');
          }
        });
    }
  };

  const showOrderSuccessfullyBooked = async (res: any) => {
    dispatch(resetBasket());
    dispatch(setOrderData(res.data.orders));

    let revenue = 0;
    let tax: any = 0;
    const { orderGroupId } = res.data.orders[0];

    res.data.orders.forEach((order: any) => {
      revenue += order.price;
      tax += Number(
        (
          Number(order.orderData.selectedQuote.priceIncVat) -
          Number(order.orderData.selectedQuote.price)
        ).toFixed(2)
      );
    });

    GTMECommerce(
      {
        purchase: {
          actionField: {
            id: orderGroupId,
            affiliation: 'Online Store',
            revenue,
            tax,
          },
          products: res.data.orders.map((order: any) => ({
            name: `${
              order.orderData.selectedQuote?.carrier
                ? `${order.orderData.selectedQuote.carrier} - `
                : ''
            }${order.orderData.selectedQuote.name}`,
            id: order.orderData.selectedQuote.rateId,
            price: Number(order.orderData.selectedQuote.priceIncVat),
            category: order.type,
            variant:
              order.type === 'parcel'
                ? order.orderData.selectedQuote.type
                : 'collection',
            quantity: 1,
          })),
        },
      },
      'productPurchase'
    );
    await captureData('order_made', orderGroupId);
    window.location.href = `/success?${orderGroupId || ''}`;
  };

  const createOrder = (data: any) => {
    data.bookerEmail = bookerEmail;
    createOrderRequest(data)
      .then((res: any) => {
        if (!res.data.error) {
          if (res.data.payment && res.data.payment.requiresAction) {
            if (stripe === null) {
              throw new Error('BUG: stripe is null');
            }
            return stripe
              .confirmCardPayment(res.data.payment.paymentIntentSecret)
              .then(handleStripeSCA.bind(null, res.data));
          } else {
            showOrderSuccessfullyBooked(res);
          }
        } else {
          setTechnicalError(true);
        }
      })
      .catch((exception: any) => {
        if (exception?.errorDetails?.details?.technical) {
          setTechnicalError(
            exception?.errorDetails?.details?.orderGroupId || true
          );
        } else {
          setPaymentError(exception?.message || 'Error with payment.');
        }
      })
      .finally(() => {
        window.scroll(0, 0);
        setProcessingPayment(false);
      });
  };

  const handleBack = () => {
    history.push('/order-basket');
  };

  const handleEmailChange = (e: any) => {
    if (emailError) {
      setEmailError(false);
    }

    const { value } = e.target;

    setEmail(value);
  };

  const handleZipChange = (e: any) => {
    const { value } = e.target;

    if (cardDetailsErrors.zip) {
      setCardDetailsErrors({
        ...cardDetailsErrors,
        zip: false,
      });
    }

    setBillingAddress({
      ...billingAddress,
      zip: value,
    });
  };

  const validateEmail = () => {
    let error: boolean;

    if (email === '') {
      setEmailError('Required');
      error = true;
    } else if (
      !email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/)
    ) {
      setEmailError('Please add a valid email');
      error = true;
    } else {
      setEmailError(false);
      error = false;
    }

    return error;
  };

  const validateCardDetails = () => {
    let error: boolean = false;
    for (const [, value] of Object.entries(cardDetailsErrors)) {
      if(value || value === null) error = true;
    }
    const errors: {
      zip: boolean | string;
    } = {
      zip: false,
    };

    setCardDetailsErrors({
      ...cardDetailsErrors,
      ...errors,
    });

    return error;
  };

  const generateRequest = (paymentMethodId: any) => {
    const parcels = cloneDeep(
      basket.filter((item: any) => item.serviceType === 'parcel')
    );

    const pallets = cloneDeep(
      basket.filter((item: any) => item.serviceType === 'pallet')
    );

    const freight = cloneDeep(
      basket.filter((item: any) => item.serviceType === 'freight')
    );

    const seafreight = cloneDeep(
      basket.filter((item: any) => item.serviceType === 'seaFreight')
    );

    pallets.forEach((item: any) => {
      item.collectionDate = item.item.collectionDate;
      item.deliveryDate = item.item.deliveryDate;
      item.deliveryPrice = Number(
        (Number(item.item.price) + Number(item.item.insurance.price)).toFixed(2)
      );
      item.totalPrice = Number(
        (
          Number(
            !item.item.vat.customs || checkForNI(item)
              ? item.item.priceIncVat
              : item.item.price
          ) +
          Number(item.item.insurance.price) +
          Number(
            !item.item.vat.customs || checkForNI(item)
              ? ((item.item.insurance?.price || 0) * 20) / 100
              : 0
          )
        ).toFixed(2)
      );
      item.serviceId = item.item.serviceId;
      item.rateId = item.item.rateId;
      item.insuranceId = item.item?.insurance?.insuranceId || '';
      item.selectedQuote = item.item;

      item.deliveryLocation = {
        ...item.deliveryAddress,
        address1: item.deliveryAddress.addressOne,
        address2: item.deliveryAddress?.addressTwo || '',
        address3: item.deliveryAddress?.addressThree || '',
        postcode: item.deliveryAddress.zip,
        name: `${item.deliveryAddress.firstName}${
          item.deliveryAddress.lastName !== ''
            ? ` ${item.deliveryAddress.lastName}`
            : ''
        }`,
      };

      item.collectionLocation = {
        ...item.collectionAddress,
        address1: item.collectionAddress.addressOne,
        address2: item.collectionAddress?.addressTwo || '',
        address3: item.collectionAddress?.addressThree || '',
        postcode: item.collectionAddress.zip,
        name: `${item.collectionAddress.firstName}${
          item.collectionAddress.lastName !== ''
            ? ` ${item.collectionAddress.lastName}`
            : ''
        }`,
      };
      if (item.packageDetails) {
        item.packageDetails = item.packageDetails.map((p: any) => {
          return {
            palletSizeId: p.palletSizeId,
            items: p.items.map((pi: any) => {
              let temp = {
                ...pi,
                value: Number(pi.value),
              };
              if (item.item.vat.customs) {
                temp = {
                  ...temp,
                  weight: Number(pi.weight),
                  quantity: Number(pi.quantity),
                };
              }
              return {
                ...temp,
              };
            }),
          };
        });
      } else if (item.dims) {
        item.dims = item.dims.map((p: any) => {
          return {
            ...p,
            items: p.items.map((pi: any) => {
              let temp = {
                ...pi,
                value: Number(pi.value),
              };
              if (item.item.vat.customs) {
                temp = {
                  ...temp,
                  weight: Number(pi.weight),
                  quantity: Number(pi.quantity),
                };
              }
              return {
                ...temp,
              };
            }),
          };
        });
      }

      item.export_reason = item.vat_information.export_reason;

      delete item.id;
      delete item.item;
      delete item.serviceType;
      delete item.collectionAddress;
      delete item.deliveryAddress;
      delete item.dropoffPoint;
      delete item.collectionLocation.firstName;
      delete item.collectionLocation.lastName;
      delete item.collectionLocation.addressOne;
      delete item.collectionLocation.addressTwo;
      delete item.collectionLocation.addressThree;
      delete item.collectionLocation.zip;
      delete item.deliveryLocation.firstName;
      delete item.deliveryLocation.lastName;
      delete item.deliveryLocation.addressOne;
      delete item.deliveryLocation.addressTwo;
      delete item.deliveryLocation.addressThree;
      delete item.deliveryLocation.zip;
      delete item.vat_information;
    });

    freight.forEach((item: any) => {
      item.collectionDate = item.item.collectionDate;
      item.deliveryDate = item.item.deliveryDate;
      item.deliveryPrice = Number(
        (Number(item.item.price) + Number(item.item.insurance.price)).toFixed(2)
      );
      item.totalPrice = Number(
        (
          Number(
            !item.item.vat.customs || checkForNI(item)
              ? item.item.priceIncVat
              : item.item.price
          ) +
          Number(item.item.insurance.price) +
          Number(
            !item.item.vat.customs || checkForNI(item)
              ? ((item.item.insurance?.price || 0) * 20) / 100
              : 0
          )
        ).toFixed(2)
      );
      item.serviceId = item.item.serviceId;
      item.rateId = item.item.rateId;
      item.insuranceId = item.item?.insurance?.insuranceId || '';
      item.item.deliveryTime =
        item.item.deliveryTime === null || item.item.deliveryTime === false
          ? 0
          : item.item.deliveryTime;
      item.selectedQuote = item.item;

      item.deliveryLocation = {
        ...item.deliveryAddress,
        address1: item.deliveryAddress.addressOne,
        address2: item.deliveryAddress?.addressTwo || '',
        address3: item.deliveryAddress?.addressThree || '',
        postcode: item.deliveryAddress.zip,
        name: `${item.deliveryAddress.firstName}${
          item.deliveryAddress.lastName !== ''
            ? ` ${item.deliveryAddress.lastName}`
            : ''
        }`,
      };

      item.collectionLocation = {
        ...item.collectionAddress,
        address1: item.collectionAddress.addressOne,
        address2: item.collectionAddress?.addressTwo || '',
        address3: item.collectionAddress?.addressThree || '',
        postcode: item.collectionAddress.zip,
        name: `${item.collectionAddress.firstName}${
          item.collectionAddress.lastName !== ''
            ? ` ${item.collectionAddress.lastName}`
            : ''
        }`,
      };

      item.export_reason = item.vat_information.export_reason;
      item.incoterms = item?.vat_information?.incoTerms;
      item.cpcCode = item?.vat_information?.CPCCode;

      delete item.id;
      delete item.item;
      delete item.serviceType;
      delete item.collectionAddress;
      delete item.deliveryAddress;
      delete item.dropoffPoint;
      delete item.collectionLocation.firstName;
      delete item.collectionLocation.lastName;
      delete item.collectionLocation.addressOne;
      delete item.collectionLocation.addressTwo;
      delete item.collectionLocation.addressThree;
      delete item.collectionLocation.zip;
      delete item.deliveryLocation.firstName;
      delete item.deliveryLocation.lastName;
      delete item.deliveryLocation.addressOne;
      delete item.deliveryLocation.addressTwo;
      delete item.deliveryLocation.addressThree;
      delete item.deliveryLocation.zip;
      delete item.packageDetails.id;
      delete item.vat_information;
    });

    seafreight.forEach((item: any) => {
      item.collectionDate = item.item.collectionDate;
      item.deliveryDate = item.item.deliveryDate;
      item.deliveryPrice = Number(Number(item.item.price).toFixed(2));

      item.totalPrice = Number(
        (
          Number(
            !item.item.vat.customs || checkForNI(item)
              ? item.item.priceIncVat
              : item.item.price
          ) +
          Number(
            !item.item.vat.customs || checkForNI(item)
              ? ((item.item.insurance?.price || 0) * 20) / 100
              : 0
          )
        ).toFixed(2)
      );
      item.serviceId = item.item.serviceId;
      item.rateId = item.item.rateId;
      item.insuranceId = item.item?.insurance?.insuranceId || '';
      item.item.deliveryTime =
        item.item.deliveryTime === null || item.item.deliveryTime === false
          ? 0
          : item.item.deliveryTime;
      item.selectedQuote = item.item;

      // item.packageDetails = item.packageDetails.map((pd: any) => ({
      //
      // }));

      item.deliveryLocation = {
        ...item.deliveryAddress,
        address1: item.deliveryAddress.addressOne,
        address2: item.deliveryAddress?.addressTwo || '',
        address3: item.deliveryAddress?.addressThree || '',
        postcode: item.deliveryAddress.zip,
        name: `${item.deliveryAddress.firstName}${
          item.deliveryAddress.lastName !== ''
            ? ` ${item.deliveryAddress.lastName}`
            : ''
        }`,
      };

      item.collectionLocation = {
        ...item.collectionAddress,
        address1: item.collectionAddress.addressOne,
        address2: item.collectionAddress?.addressTwo || '',
        address3: item.collectionAddress?.addressThree || '',
        postcode: item.collectionAddress.zip,
        name: `${item.collectionAddress.firstName}${
          item.collectionAddress.lastName !== ''
            ? ` ${item.collectionAddress.lastName}`
            : ''
        }`,
      };

      item.export_reason = item.vat_information.export_reason;
      item.incoterms = item?.vat_information?.incoTerms;
      item.cpcCode = item?.vat_information?.CPCCode;

      item.packageType = item.seaFreightDetails.packageType;

      delete item.id;
      delete item.item;
      delete item.serviceType;
      delete item.collectionAddress;
      delete item.deliveryAddress;
      delete item.dropoffPoint;
      delete item.collectionLocation.firstName;
      delete item.collectionLocation.lastName;
      delete item.collectionLocation.addressOne;
      delete item.collectionLocation.addressTwo;
      delete item.collectionLocation.addressThree;
      delete item.collectionLocation.zip;
      delete item.deliveryLocation.firstName;
      delete item.deliveryLocation.lastName;
      delete item.deliveryLocation.addressOne;
      delete item.deliveryLocation.addressTwo;
      delete item.deliveryLocation.addressThree;
      delete item.deliveryLocation.zip;
      delete item.packageDetails.id;
      delete item.vat_information;
      delete item.seaFreightDetails;
    });

    parcels.forEach((item: any) => {
      item.type = item.item.type;
      item.rateId = item.item.rateId;
      item.insuranceId = item.item?.insurance?.insuranceId || '';
      item.selectedQuote = item.item;
      item.price = Number(
        (
          Number(
            !item.item.vat.customs || checkForNI(item)
              ? item.item.priceIncVat
              : item.item.price
          ) +
          Number(item.item.insurance?.price || 0) +
          Number(
            !item.item.vat.customs || checkForNI(item)
              ? ((item.item.insurance?.price || 0) * 20) / 100
              : 0
          )
        ).toFixed(2)
      );

      item.packageDetails = item.packageDetails.map((p: any) => {
        return {
          ...p,
          items: p.items.map((pi: any) => {
            const temp = {
              ...pi,
              value: Number(pi.value),
            };
            // if (item.item.vat.customs) {
            //   const weight = Number((Number(p.weight.amount) / p.items.length).toFixed(2));

            //   temp = {
            //     ...temp,
            //     weight,
            //     quantity: 1,
            //   };
            // }
            return {
              ...temp,
            };
          }),
        };
      });

      item.deliveryAddress = {
        ...item.deliveryAddress,
        street1: item.deliveryAddress.addressOne,
        street2: item.deliveryAddress?.addressTwo || '',
        street3: item.deliveryAddress?.addressThree || '',
      };

      item.collectionAddress = {
        ...item.collectionAddress,
        street1: item.collectionAddress.addressOne,
        street2: item.collectionAddress?.addressTwo || '',
        street3: item.collectionAddress?.addressThree || '',
        zip:
          item.collectionAddress?.postcode || item.collectionAddress?.zip || '',
      };

      if (item.type === 'collection') {
        item.collectionDate = item.item.collectionDate;

        delete item.dropoffPoint;
      }

      if (item.item.vat.customs) {
        item.export_reason = item.vat_information.export_reason;
      }

      delete item.id;
      delete item.item;
      delete item.serviceType;
      delete item.collectionAddress.addressOne;
      delete item.collectionAddress.addressTwo;
      delete item.collectionAddress.addressThree;
      delete item.deliveryAddress.addressOne;
      delete item.deliveryAddress.addressTwo;
      delete item.deliveryAddress.addressThree;
      delete item.vat_information;
    });

    return {
      parcels,
      pallets,
      freight,
      seafreight,
      paymentMethod: selectedMethod,
      paymentMethodId,
      billingAddress: {
        ...billingAddress,
        email,
      },
      bookerEmail,
    };
  };

  return (
    <>
      <RowStyled>
        <ColumnStyled sizeL={6} sizeM={7} sizeS={3.5}>
          <TechnicalError active={technicalError} order={technicalError} />
          <Wrapper>
            <H4Styled>Payment</H4Styled>
            <PSmallStyled>
              Please complete your card details below, we securely process your
              card.
            </PSmallStyled>
            {!loggedIn && (
              <EmailContainer>
                <CaptionStyled>Email address</CaptionStyled>
                <Input
                  theme={theme}
                  value={email}
                  type="text"
                  placeholder="Your email address"
                  onChange={handleEmailChange}
                  error={emailError}
                />
                {emailError && <ErrorText>{emailError}</ErrorText>}
              </EmailContainer>
            )}
            <PaymentMethods
              selectedMethod={selectedMethod}
              setSelectedMethod={setSelectedMethod}
              error={paymentError}
            />

            {selectedMethod === 'stripe' && (
              <CardDetails
                billingAddress={billingAddress}
                handleChange={handleZipChange}
                errors={cardDetailsErrors}
                setErrors={setCardDetailsErrors}
              />
            )}

            {selectedMethod === 'balance' && (
              <AccountCredit
                value={totalPrice}
                setCreditError={setCreditError}
              />
            )}

            {selectedMethod === 'kriya' && (
              <BuyNowPayLater
                value={totalPrice}
                handleChange={handleKriyaAcceptance}
                acceptedKriyaStatement={acceptedKriyaStatement}
              />
            )}
          </Wrapper>
        </ColumnStyled>
        <ColumnStyled sizeL={4} sizeM={5} sizeS={2.5}>
          <Service items={basket} totalPrice={totalPrice} />
        </ColumnStyled>
      </RowStyled>
      <RowLast>
        <ColumnLast sizeL={6} sizeM={7} sizeS={3.5}>
          <Button color="black" onClick={handleBack}>
            Back
          </Button>
          <Button
            color="secondary"
            disabled={buttonDisabled || processingPayment}
            disable={buttonDisabled || processingPayment}
            onClick={handleSubmit}
          >
            Place order
          </Button>
        </ColumnLast>
        <ProcessingPayment show={processingPayment} />
      </RowLast>
    </>
  );
};

const RowStyled = styled(Row)`
  padding-top: 32px;
  justify-content: center;
`;

const RowLast = styled(Row)`
  padding-bottom: 40px;
  justify-content: flex-start;
  margin-top: 32px;
`;

const ColumnStyled = styled(Column)`
  padding: 0;

  @media ${device.tablet} {
    padding: 0 15px;
  }
`;

const ColumnLast = styled(Column)`
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${device.tablet} {
    padding: 0 15px;
  }

  @media ${device.desktop} {
    margin-left: 8.3333%;
  }
`;

const Wrapper = styled(Container)`
  background: #ffffff;
  border-radius: 8px;
  && {
    padding: 15px;
    @media ${device.tablet} {
      padding: 30px;
    }
  }
  height: auto;
  margin-bottom: 16px;

  @media ${device.laptop} {
    margin-bottom: 24px;
  }
`;

const H4Styled = styled(H4)`
  margin-bottom: 22px;
`;

const PSmallStyled = styled(PSmall)`
  margin-bottom: 27px;
`;

const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  position: relative;
`;

const CaptionStyled = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.MidGrey};
  margin-bottom: 4px;
  display: inline-block;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: none;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;

  @media ${device.laptopL} {
    display: inline-block;
  }
`;

export default Payment;
