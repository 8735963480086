import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  A,
  Button,
  Column,
  Container,
  H4,
  Lead,
  PSmall,
  Row,
  Table,
  TableCell,
  TableRow,
} from '../../../assets/elements';
import { setTrackingInfo } from '../../store/actions';
import { useQuery } from '../../utils/hooks/useQuery';
import Loader from '../Loader';

import DoneIcon from '../../../assets/icons/Done.svg';
import BookIcon from '../../../assets/images/Book-delivery.png';
import TrackIcon from '../../../assets/images/Track-delivery.png';
import device from '../../utils/device';
import { landingPageUrl } from '../../utils/env';
import Modal from '../../../assets/Combos/Modal';
import OrderItemFreight from '../MyAccount/components/Dashboard/LatestOrders/OrderItemDetail/OrderItemFreight';
import { getSingleOrder } from '../../utils/APICalls/Order';
import P4DTracking from './P4DTracking';
import MPDTracking from './MPDTracking';
import moment from 'moment-timezone';

const TrackingInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();

  const trackingInfo = useSelector((state: any) => state.main.trackingInfo);
  const error = useSelector((state: any) => state.main.errors.tracking);
  const loading = useSelector((state: any) => state.main.loading.tracking);
  const [noTrackingInfo, setNoTrackingInfo] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [order, setOrder] = useState<any>({});
  const [freightOrder, setFreightOrder] = useState<any>(false);
  const [iframe, setIframe] = useState(false);
  const [mpd, setMpd] = useState(false);

  const orderNumber = query.get('order-number');

  useEffect(() => {
    if (!orderNumber) {
      history.push('/tracking');
    }

    if (
      orderNumber?.startsWith('P4D') &&
      !orderNumber.includes('-')
    ) {
      setIframe(true);
    } else if (
      orderNumber?.startsWith('MPD') &&
      orderNumber?.split('-')[0]?.length > 3
    ) {
      setMpd(true);
    } else {
      dispatch(setTrackingInfo(orderNumber));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error === 'No order found') {
      history.push('/tracking');
      setNoTrackingInfo(true);
    } else if (error) {
      setNoTrackingInfo(true);
    } else {
      setNoTrackingInfo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!loading && trackingInfo.type === 'freight') {
      setFreightOrder(true);
      setNoTrackingInfo(true);
      getSingleOrder(orderNumber)
        .then((res: any) => {
          setOrder(res.data);
        });
    }
  }, [trackingInfo, loading]);

  const handleClick = (url: any) => {
    history.push(url);
  };

  const handleBook = () => {
    window.location.href = landingPageUrl;
  };

  const handleContact = () => {
    const url = landingPageUrl.includes('https://') ?
      landingPageUrl.replace('https://', 'https://help.') :
      landingPageUrl.replace('http://', 'https://help.');

    window.open(`${url}support/tickets/new`, '_blank');
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const formatDate = (eventDate: string) => {
    return moment(eventDate).format('DD MMM YYYY HH:mm')
  }

  return (
    <>
      {
        iframe ? (
          <P4DTracking orderNumber={orderNumber} />
        ) : (
          <Wrapper>
            <Container>
              <RowStyled>
                <Column sizeL={8} sizeM={10} sizeS={6} sizeXS={4}>
                  <H4Styled>Tracking information for {orderNumber}</H4Styled>
                  {loading ? (
                    <Loader>Loading information</Loader>
                  ) : (
                    <ContainerStyled>
                      {
                        mpd ? (
                          <MPDTracking />
                        ) :
                          <>
                            <LeadStyled>
                              {noTrackingInfo ? (
                                <Span>
                                  Current status: Tracking information not available
                                </Span>
                              ) : (
                                <Span>
                                  Current status: {trackingInfo[0]?.status || ''}{' '}
                                  <StatusIcon src={DoneIcon} alt="Delivered" />
                                </Span>
                              )}
                            </LeadStyled>
                            {
                              freightOrder ? (
                                <ErrorWrapper>
                                  <ErrorText>
                                    We’re currently unable to provide dynamic tracking info for our freight services.
                                    If you have an urgent question,
                                    please <A onClick={handleContact}>contact us</A>{' '}
                                    and one of our team will get back to you as soon as they can.
                                  </ErrorText>
                                  <Button
                                    color="primary"
                                    onClick={() => setModalOpen(true)}
                                  >
                                    View order details
                                  </Button>
                                </ErrorWrapper>
                              ) : (
                                <Table>
                                  <TableRow>
                                    <TableCell head={true} width={30}>
                                      Date
                                    </TableCell>
                                    <TableCell head={true} width={70}>
                                      Description
                                    </TableCell>
                                  </TableRow>
                                  {trackingInfo?.stages &&
                                  trackingInfo.stages.map(({ type, title, description, eventDate }: any, i: any) => (
                                    <TableRow key={i}>
                                      <TableCell width={30}>
                                        {formatDate(eventDate)}
                                      </TableCell>
                                      <TableCell width={70}>{title}</TableCell>
                                    </TableRow>
                                  ))}
                                </Table>
                              )
                            }
                          </>
                      }
                    </ContainerStyled>
                  )}
                </Column>
              </RowStyled>
            </Container>
          </Wrapper>
        )
      }
      <TilesWrapper>
        <Container>
          <Row>
            <Column sizeL={8} sizeM={10} sizeS={6} sizeXS={4}>
              <Row>
                <ColumnStyled sizeL={6} sizeS={3} sizeXS={4}>
                  <TrackTile>
                    <Icon src={BookIcon} alt="book delivery" />
                    <Button color="secondary" onClick={handleBook}>
                      Book another delivery
                    </Button>
                  </TrackTile>
                </ColumnStyled>
                <ColumnStyled sizeL={6} sizeS={3} sizeXS={4}>
                  <TrackTile>
                    <Icon src={TrackIcon} alt="track delivery" />
                    <Button
                      color="secondary"
                      onClick={() => handleClick('/tracking')}
                    >
                      Track another order
                    </Button>
                  </TrackTile>
                </ColumnStyled>
              </Row>
            </Column>
          </Row>
        </Container>
      </TilesWrapper>
      {
        (order.orderData && order.type === 'freight') && (
          <Modal
            open={modalOpen}
            close={handleModalClose}
            back={false}
            width={8}
            floatingClose={true}
          >
            <OrderItemFreight item={{ ...order?.orderData, orderId: order.orderId }} />
          </Modal>
        )
      }
    </>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }: any) => theme.colors.WhiteGrey};
`;

const ContainerStyled = styled.div`
  background: ${({ theme }: any) => theme.colors.White};
  padding: 24px;
  border-radius: 8px;
`;

const RowStyled = styled(Row)`
  padding: 64px 0 8px;
`;

const H4Styled = styled(H4)`
  margin: 0 0 32px;
`;

const LeadStyled = styled(Lead)`
  margin-bottom: 32px;
`;

const TilesWrapper = styled.div`
  padding: 8px 0 64px;
  background: ${({ theme }: any) => theme.colors.WhiteGrey};

  @media ${device.laptop} {
    padding: 8px 0 64px;
  }
`;

const TrackTile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 48px;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: ${({ theme }: any) => theme.colors.White};
  margin-bottom: 32px;
  border-radius: 8px;

  @media ${device.laptop} {
    margin-bottom: 0;
  }
`;

const ColumnStyled = styled(Column)`
  padding: 0;

  @media ${device.laptop} {
    &:first-of-type {
      padding-right: 15px
    }

    &:last-of-type {
      padding-left: 15px
    }
  }
`;

const Icon = styled.img`
  margin-bottom: 24px;
`;

const StatusIcon = styled.img`
  width: 40px;

  @media ${device.laptop} {
    width: auto;
  }
`;

const Span = styled.span`
  display: flex;
`;

const ErrorWrapper = styled.div`
  padding: 32px;
  background: ${({ theme }: any) => theme.colors.Pastel2};
`;

const ErrorText = styled(PSmall)`
  margin-bottom: 24px;
`;

export default TrackingInfo;
