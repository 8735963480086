import React from 'react';
import styled from 'styled-components';
import { Button, Column, Container, H4, Row } from '../../elements';
import CloseIcon from '../../icons/close.svg';
import CloseWhiteIcon from '../../icons/Close-white.svg';
import device from '../../../app/utils/device';

const Modal = ({
  children,
  title = '',
  TitleElement = H4,
  open,
  close,
  back,
  width,
  floatingClose = false,
  closeIcon = true,
  outsideClose = true,
}: any) => {
  if (!width) {
    return <></>;
  }

  return (
    open ? (
      <Wrapper open={open}>
        <ContainerStyled>
          <RowStyled>
            <Column sizeL={width} sizeS={6}>
              <ModalBody>
                {title !== '' && (
                  <ModalHead>
                    <TitleElement>{title}</TitleElement>
                    {closeIcon && (
                      <CloseIconWrapper onClick={close}>
                        <img src={CloseIcon} alt="Close modal" />
                      </CloseIconWrapper>
                    )}
                  </ModalHead>
                )}
                {floatingClose && (
                  <FloatingCloseIconWrapper onClick={close}>
                    <img src={CloseWhiteIcon} alt="Close modal" />
                  </FloatingCloseIconWrapper>
                )}
                <ModalContent>
                  {children}
                  {back && (
                    <div>
                      <ButtonStyled onClick={close} color="black">
                        Back
                      </ButtonStyled>
                    </div>
                  )}
                </ModalContent>
              </ModalBody>
            </Column>
          </RowStyled>
        </ContainerStyled>
        <Backdrop onClick={outsideClose ? close : null} />
      </Wrapper>
    ) : <></>
  );
};

const Wrapper = styled.div.attrs(({ open }: any) => ({
  open,
}))`
  display: ${({ open }: any) => (open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 18;
`;

const RowStyled = styled(Row)`
  width: 100%;
`;

const ContainerStyled = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 20;
  width: 100%;
  height: auto;
  position: relative;
`;

const ModalHead = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ModalBody = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }: any) => theme.colors.White};
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};

  @media ${device.laptop} {
    padding: 32px;
  }
`;

const ModalContent = styled.div`
  max-height: calc(100% - 60px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 160px);
  overflow-y: auto;
`;

const Backdrop = styled.div`
  display: block;
  z-index: 17;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0.6;
`;

const ButtonStyled = styled(Button)`
  width: auto;
  color: #505050;
  border: 2px solid #505050;
  margin-top: 16px;
`;

const FloatingCloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: -47px;
  right: 15px;
  cursor: pointer;

  & > img {
    width: 32px;
    height: 32px;
  }
`;

export default Modal;
