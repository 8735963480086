import React from 'react';
import styled from 'styled-components';

const Table = ({ children }: any) => {
  return (
    <Wrapper>
      <TableContent>
        { children }
      </TableContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  overflow-y: unset;
  border: 1px solid ${({ theme }: any) => theme.colors.LightGrey};
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};
`;

const TableContent = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export default Table;
