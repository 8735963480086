import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deleteCookie } from '../../utils/cookies';
import { landingPageUrl } from '../../utils/env';
import { resetUserSession } from '../../store/actions';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    deleteCookie([
      'userId',
      'userName',
      'accessToken',
      'refreshToken',
      'refreshTokenExpiry',
      'accessTokenExpiry',
    ]);
    dispatch(resetUserSession());

    window.location.href = landingPageUrl;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default Logout;
