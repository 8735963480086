import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Column, Row } from '../../../../../../assets/elements';
import DeliveryAddress from './components/DeliveryAddress/DeliveryAddress';
import DeliveryDetails from './components/DeliveryDetails/DeliveryDetails';
import Service from '../Service';
import device from '../../../../../utils/device';
import {
  addressBookToggle,
  setDeliveryAddress,
  setRecipientInfo,
  sendBasketData
} from '../../../../../store/actions';

const requiredAddressKeys = ['addressOne', 'city', 'country', 'zip'];

const requiredRecipientKeys = ['name', 'phone', 'email'];

const StepTwo = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const address = useSelector(
    (state: any) => state.order.deliveryAddress || {}
  );
  const recipient = useSelector((state: any) => state.order.recipientDetails);
  const vat = useSelector<any, any>((state) => state.order.item.vat);
  const order = useSelector((state: any) => state.order)

  const [addressData, setAddressData] = React.useState({
    company: '',
    addressOne: '',
    addressTwo: '',
    addressThree: '',
    city: '',
    country: '',
    zip: '',
  });

  const [recipientDetails, setRecipientDetails] = React.useState({
    name: '',
    email: '',
    phone: '',
    customsData: {
      VATStatus: (vat.customs && order.serviceType === 'pallet') ? 'company_vat' : '',
      VATNumber: '',
      EORINumber: '',
    }
  });

  const [recipientDetailsErrors, setRecipientDetailsErrors] = React.useState<any>({
    name: '',
    email: '',
    phone: '',
    customsData: {
      VATStatus: false,
      VATNumber: false,
      EORINumber: false,
    },
  });

  const [addressErrors, setAddressErrors] = React.useState<any>({
    company: false,
    addressOne: false,
    addressTwo: false,
    city: false,
    country: false,
    zip: false,
  });

  const [buttonDisabled, setButtonDisabled] = React.useState<any>(true);

  const [inputManually, setInputManually] = React.useState<any>(true);
  const [addressIncomplete, setAddressIncomplete] = React.useState<any>(false);
  const [addToAddressBook, setAddToAddressBook] = useState(false);

  useEffect(() => {
    setAddressData({
      ...addressData,
      ...address,
    });

    setRecipientDetails({
      ...recipientDetails,
      name: `${recipient.firstName}${
        recipient.lastName ? ` ${recipient.lastName}` : ''
      }`,
      phone: recipient.phone,
      email: recipient.email,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, recipient]);

  useEffect(() => {
    let blank = false;

    requiredAddressKeys.forEach((key: any) => {
      // @ts-ignore
      if (addressData[key] === '') {
        blank = true;
      }
    });

    if (order.serviceType === 'seaFreight' && addressData.company === '') {
      blank = true;
    }

    if (
      (addressData.company !== '' && (addressData.company.length < 3 || addressData.company.length > 35)) ||
      (addressData.addressOne !== '' && addressData.addressOne.length > 30) ||
      (addressData.addressTwo !== '' && addressData.addressTwo.length > 30) ||
      (addressData.city !== '' && addressData.city.length > 30)
    ) {
      blank = true;
    }

    requiredRecipientKeys.forEach((key: any) => {
      // @ts-ignore
      if (recipientDetails[key] === '') {
        blank = true;
      }
    });

    if (recipientDetails.email.length > 50) {
      blank = true;
    }

    if (recipientDetails.name.length > 30) {
      blank = true;
    }

    if (vat.customs) {
      if (recipientDetails.customsData.VATStatus === '') {
        blank = true;
      }

      if (recipientDetails.customsData.VATStatus === 'company_vat') {
        if (
          recipientDetails.customsData.VATNumber === '' ||
          recipientDetails.customsData.EORINumber === ''
        ) {
          blank = true;
        }
      }
    }

    setButtonDisabled(blank);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressData, recipientDetails]);

  const validateAddress = () => {
    let error = false;
    let errors = { ...addressErrors };

    if (order.serviceType === 'seaFreight' && addressData.company === '') {
      errors = {
        ...errors,
        company: 'Required',
      };

      error = true;
    }

    if (
      addressData.company !== '' &&
      (addressData.company.length < 3 ||
      addressData.company.length > 35)
    ) {
      errors = {
        ...errors,
        company: 'This must be between 3 and 30 characters',
      };

      error = true;
    }

    if (addressData.addressOne === '') {
      errors = {
        ...errors,
        addressOne: 'Required',
      };

      error = true;
    } else if (addressData.addressOne.length > 30) {
      errors = {
        ...errors,
        addressOne: 'This must be less than 30 characters',
      };
    }

    if (
      addressData.addressTwo !== '' &&
      addressData.addressTwo.length > 30
    ) {
      errors = {
        ...errors,
        addressTwo: 'This must be less than 30 characters',
      };
    }

    if (addressData.city === '') {
      errors = {
        ...errors,
        city: 'Required',
      };

      error = true;
    } else if (addressData.city.length > 30) {
      errors = {
        ...errors,
        city: 'This must be less than 30 characters',
      };

      error = true;
    }

    if (addressData.country === '') {
      errors = {
        ...errors,
        country: 'Required',
      };

      error = true;
    }

    if (addressData.zip === '') {
      errors = {
        ...errors,
        zip: 'Required',
      };

      error = true;
    }

    setAddressErrors({ ...errors });

    return error;
  };

  const validateRecipientDetails = () => {
    let error = false;
    let errors = { ...recipientDetailsErrors };

    if (recipientDetails.name === '') {
      errors = {
        ...errors,
        name: 'Required',
      };

      error = true;
    } else if (recipientDetails.name.length > 30) {
      errors = {
        ...errors,
        name: 'This must be less than 30 characters',
      };

      error = true;
    }

    if (recipientDetails.email === '') {
      errors = {
        ...errors,
        email: 'Required',
      };

      error = true;
    } else if (recipientDetails.email.length > 50) {
      errors = {
        ...errors,
        email: 'Email must not exceed 50 characters',
      };

      error = true;
    } else if (
      !recipientDetails.email.match(
        // eslint-disable-next-line no-useless-escape
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/
      )
    ) {
      errors = {
        ...errors,
        email: 'Please enter valid email',
      };

      error = true;
    }

    if (recipientDetails.phone === '') {
      errors = {
        ...errors,
        phone: 'Required',
      };

      error = true;
    } else if (
      recipientDetails.phone !== '' &&
      !recipientDetails.phone.match(
        // eslint-disable-next-line no-useless-escape
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
      )
    ) {
      errors = {
        ...errors,
        phone: 'Please enter valid number',
      };

      error = true;
    } else if (recipientDetails.phone.replace(' ', '').length < 11) {
      errors = {
        ...errors,
        phone: `Phone number must be at least ${10 - (recipientDetails.phone?.split(' ')[0].length - 1)} characters`,
      };

      error = true;
    } else if (recipientDetails.phone.replace(' ', '').length > 14) {
      errors = {
        ...errors,
        phone: `Phone number must less than or equal to ${13 - (recipientDetails.phone?.split(' ')[0].length - 1)} characters`,
      };

      error = true;
    }

    if (vat.customs) {
      if (recipientDetails.customsData.VATStatus === '') {
        errors = {
          ...errors,
          customsData: {
            ...errors.customsData,
            VATStatus: 'Required',
          },
        };

        error = true;
      }

      if (recipientDetails.customsData.VATStatus === 'company_vat') {
        if (recipientDetails.customsData.VATNumber === '') {
          errors = {
            ...errors,
            customsData: {
              ...errors.customsData,
              VATNumber: 'Required',
            },
          };

          error = true;
        }

        if (recipientDetails.customsData.EORINumber === '') {
          errors = {
            ...errors,
            customsData: {
              ...errors.customsData,
              EORINumber: 'Required',
            },
          };

          error = true;
        } else if (recipientDetails.customsData.EORINumber.length > 17) {
          errors = {
            ...errors,
            customsData: {
              ...errors.customsData,
              EORINumber: 'EORI numbers should be 17 characters or less',
            },
          };

          error = true;
        }
      }
    }

    setRecipientDetailsErrors({
      ...recipientDetailsErrors,
      ...errors,
    });

    return error;
  };

  const handleBack = () => {
    history.push('/order-step1');
  };

  const handleSubmit = () => {
    const errorAddress = validateAddress();
    const errorRecipient = validateRecipientDetails();

    if (errorAddress) {
      setAddressIncomplete(true);
      setInputManually(true);
    }

    if (!errorAddress && !errorRecipient) {
      captureData()
      dispatch(addressBookToggle(addToAddressBook));
      dispatch(setDeliveryAddress(addressData));
      dispatch(setRecipientInfo(recipientDetails));
      history.push('/order-step3');
    } else {
      window.scroll(0, 0);
    }
  };

  const captureData = () => {

    const dataToSend = {
      deliveryData: {...recipientDetails, ...addressData},
      currentStep: order.currentStep,
      basketItemId:order.basketItemId,
      status:"delivery"
    }

    sendBasketData(dataToSend)

    .catch((error: any) => {
      console.log(`Error sending data: ${error.message}`)
    });
  }

  return (
    <>
      <RowStyled id="service-row">
        <ColumnStyled id="service-column" sizeL={7} sizeM={6}>
          <DeliveryAddress
            address={addressData}
            setAddress={setAddressData}
            errors={addressErrors}
            setErrors={setAddressErrors}
            inputManually={inputManually}
            setInputManually={setInputManually}
            addressIncomplete={addressIncomplete}
            setAddressIncomplete={setAddressIncomplete}
            addToAddressBook={addToAddressBook}
            setAddToAddressBook={setAddToAddressBook}
            serviceType={order.serviceType}
          />
          <DeliveryDetails
            details={recipientDetails}
            setDetails={setRecipientDetails}
            errors={recipientDetailsErrors}
            setErrors={setRecipientDetailsErrors}
            country={addressData.country}
          />
        </ColumnStyled>
        <Service />
      </RowStyled>
      <RowLast>
        <ColumnLast sizeL={7} sizeM={6} sizeS={3}>
          <Button color="black" onClick={handleBack}>
            Back
          </Button>
          <Button
            color="secondary"
            disable={buttonDisabled}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </ColumnLast>
      </RowLast>
    </>
  );
};

const RowStyled = styled(Row)`
  padding-top: 64px;
  justify-content: flex-start;

  @media ${device.laptop} {
    padding-top: 32px;
  }
`;

const RowLast = styled(Row)`
  padding-bottom: 40px;
  justify-content: flex-start;
  margin-top: 32px;
`;

const ColumnStyled = styled(Column)`
  padding: 0px;

  @media ${device.tablet} {
    padding: 0 15px;
  }
`;

const ColumnLast = styled(Column)`
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${device.tablet} {
    padding: 0 15px;
  }
`;

export default StepTwo;
