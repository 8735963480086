import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';

import StepOnePallet from './Pallet/StepOne';
import StepOneParcel from './Parcel/StepOne';
import StepOneFreight from './Freight/StepOne';
import QuoteTab from './QuoteTab';
import ParcelIcon from '../../../../../assets/icons/ParcelIcon';
import PalletIcon from '../../../../../assets/icons/PalletIcon';
import FreightIcon from '../../../../../assets/icons/FreightIcon';
import device from '../../../../utils/device';
import { Column, A, Row, PSmall } from '../../../../../assets/elements';
import { landingPageUrl } from '../../../../utils/env';
import { homePage, HomePage } from '../../../../utils/homePage';

const Quote = () => {
  const { pathname } = useLocation();

  const Tabs = [
    {
      id: 1,
      label: 'Parcel',
      Icon: ParcelIcon,
      url: homePage === HomePage.PARCEL ? '/' : '/parcel-delivery',
    },
    {
      id: 2,
      label: 'Pallet',
      Icon: PalletIcon,
      url: homePage === HomePage.PALLET ? '/' : '/pallet-delivery',
    },
    {
      id: 3,
      label: 'Freight',
      Icon: FreightIcon,
      url: homePage === HomePage.FREIGHT ? '/' : '/freight-delivery',
    },
  ];

  const goToGuidelines = () => {
    window.location.href = `${landingPageUrl}faq/parcel-dimension-guidelines`;
  };

  const goToFreightGuide = () => {
    window.open(`${landingPageUrl}road-freight`, '_blank');
  };

  const goToPalletFAQ = () => {
    window.open(`${landingPageUrl}faq/pallet-faq`, '_blank');
  };

  const goToSizeInfo = () => {
    window.open(
      `${landingPageUrl}/faq/pallet-size-information`,
      '_blank'
    );
  };

  const getQuotesDisplay = () => {
    if ((homePage === HomePage.PARCEL && pathname === '/') || pathname === '/parcel-delivery') {
      return <StepOneParcel />;
    }

    if ((homePage === HomePage.PALLET && pathname === '/') || pathname === '/pallet-delivery') {
      return <StepOnePallet />;
    }

    if ((homePage === HomePage.FREIGHT && pathname === '/') || pathname === '/freight-delivery') {
      return <StepOneFreight />;
    }

    return <StepOneParcel />;
  };

  return (
    <QuoteContainer>
      <TabsContainer>
        {Tabs.map((Tab: any) => {
          const tabData = { ...Tab };
          tabData.current = Tab.url === pathname;

          return <QuoteTab key={Tab.id} {...tabData} />;
        })}
      </TabsContainer>
      <QuoteTabsContent>
        {
          getQuotesDisplay()
        }
      </QuoteTabsContent>
      <RowHelp>
        <Column sizeL={12} sizeS={6}>
          <PSmallStyled>
            {
              ((homePage === HomePage.PALLET && pathname === '/') || pathname === '/pallet-delivery') && (
                <>
                  Unsure what to pick? Check{' '}
                  <A onClick={goToPalletFAQ}>Pallet FAQs</A> and{' '}
                  <A onClick={goToSizeInfo}>size info</A>
                </>
              )
            }
            {
              ((homePage === HomePage.PARCEL && pathname === '/') || pathname === '/parcel-delivery') && (
                <>
                  Not sure about dimensions?{' '}
                  <A onClick={goToGuidelines}>Check out our guidelines</A>
                </>
              )
            }
            {
              ((homePage === HomePage.FREIGHT && pathname === '/') || pathname === '/freight-delivery') && (
                <>
                  Not sure about road freight?{' '}
                  <A onClick={goToFreightGuide}>Check out our guide here</A>
                </>
              )
            }
          </PSmallStyled>
        </Column>
      </RowHelp>
    </QuoteContainer>
  );
};

const QuoteContainer = styled.div`
  padding: 10px;
  background-color: ${({ theme }: any) => theme.colors.Black};
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};
  box-shadow: ${({ theme }: any) => theme.box.BoxShadow};
`;

const TabsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;

  @media ${device.laptop} {
    justify-content: flex-start;
  }
`;

const QuoteTabsContent = styled.div`
  display: flex;
  border-radius: ${({ theme }: any) => theme.box.BorderRadius};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${({ theme }: any) => theme.colors.White};
  padding: 16px;

  @media ${device.laptop} {
    padding: 24px;
  }
`;

const RowHelp = styled(Row)`
  padding: 17px 24px;
  text-align: center;
  justify-content: center;
  background-color: ${({ theme }: any) => theme.colors.WhiteGrey};
  border-bottom-left-radius: ${({ theme }: any) => theme.box.BorderRadius};
  border-bottom-right-radius: ${({ theme }: any) =>
    theme.box.BorderRadius};

  @media ${device.laptop} {
    text-align: left;
    justify-content: flex-start;
  }
`;

const PSmallStyled = styled(PSmall)`
  font-size: 16px;
  line-height: 120%;
`;

export default Quote;
