import moment from 'moment';
import {
  ADD_ORDER_TO_BASKET,
  BACK_TO_TOKEN_EXPIRED,
  REMOVE_ORDER_FROM_BASKET,
  RESET_BASKET,
  RESET_STATE,
  RESET_USER_SESSION,
  SET_ACTIVE_TAB,
  SET_COLLECTION_ADDRESS,
  SET_COLLECTION_DETAILS,
  SET_CUSTOMS_DETAILS,
  SET_DELIVERY_ADDRESS,
  SET_DROPOFF_POINT,
  SET_EARLIEST_COLLECTION_DATE,
  SET_EARLIEST_DELIVERY_DATE,
  SET_ERRORS,
  SET_INSURANCE_DETAILS,
  SET_ITEM_DETAILS,
  SET_LOADERS,
  SET_ORDER_DATA,
  SET_PALLET_QUOTES,
  SET_PALLET_VALUES,
  SET_PARCEL_QUOTES,
  SET_PARCEL_VALUES,
  SET_RECIPIENT_DETAILS,
  SET_RESET_FILTERS,
  SET_SELECTED_SERVICE,
  SET_SHIPMENT_DETAILS,
  SET_DELIVERYTIME,
  SET_TRACKING_DETAILS,
  SET_USER_LOGGED,
  SET_USER_SESSION,
  SET_CURRENT_ORDER_STEP,
  SET_CURRENT_QUICKBOOK_STEP,
  GOTO_NEXT_QUICKBOOK_STEP,
  ADD_PARCEL_TO_QUICKBOOK,
  ADD_ADDRESS_TO_ADDRESS_BOOK_TOGGLE,
  SET_FREIGHT_VALUES,
  SET_FREIGHT_QUOTES,
  SET_PALLET_QUOTES_SORT,
  SET_FREIGHT_QUOTES_SORT,
  RESET_QUOTES,
  ADD_ORDER_TO_BASKET_MANUAL,
  SET_PARCEL_COLLECTION_DATE,
  ADD_ACCOUNT_DISCOUNT,
  SET_SEA_FREIGHT_VALUES,
  SET_SEA_FREIGHT_QUOTES, 
  SET_SEA_FREIGHT_DETAILS,
  SET_AFFLIATE_SCRIPT_LOADING,
  SET_QUOTE_ID,
  SET_BASKET_ITEM_ID,
  SET_BOOKER_EMAIL,
  SET_QUOTE_URL,
} from '../actionConstants';
import axios from '../../utils/API/axios';
import { sort } from '../../utils/sorting';
import { landingPageUrl, noPalletQuoteUrl, noFreightQuoteUrl, noParcelQuoteUrl } from '../../utils/env';
import { mapQuotesToGTM } from '../../utils/mapQuotesToGTM';
import { GTMECommerce } from '../../utils/tracking';
import { calculateForAnyTime } from '../../utils/deliveryDate';
import { getCookie, setCookie } from '../../utils/cookies';

const cookieConsent = getCookie('cookie_consent');

export const setBookerEmail = (payload: any) => ({
  type: SET_BOOKER_EMAIL,
  payload,
});

export const setQuoteUrl = (payload: any) => ({
  type: SET_QUOTE_URL,
  payload,
});

export const setParcelQuotesAction = (payload: any) => ({
  type: SET_PARCEL_QUOTES,
  payload,
});

export const setPalletQuotesAction = (payload: any) => ({
  type: SET_PALLET_QUOTES,
  payload,
});

export const setPalletQuotesSortedAction = (payload: any) => ({
  type: SET_PALLET_QUOTES_SORT,
  payload,
});

export const setFreightQuotesAction = (payload: any) => ({
  type: SET_FREIGHT_QUOTES,
  payload,
});

export const setSeaFreightQuotesAction = (payload: any) => ({
  type: SET_SEA_FREIGHT_QUOTES,
  payload,
});

export const setFreightQuotesSortedAction = (payload: any) => ({
  type: SET_FREIGHT_QUOTES_SORT,
  payload,
});

export const setParcelCollectionDate = (payload: any) => ({
  type: SET_PARCEL_COLLECTION_DATE,
  payload,
});

export const setEarliestCollectionDate = (payload: any) => ({
  type: SET_EARLIEST_COLLECTION_DATE,
  payload,
});

export const setEarliestDeliveryDate = (payload: any) => ({
  type: SET_EARLIEST_DELIVERY_DATE,
  payload,
});

export const setQuoteId = (payload: any) => ({
  type: SET_QUOTE_ID,
  payload,
});

const fetchParcelQuotes = (data: any) => {
  return axios.post('parcels/rates', data);
};

const fetchPalletQuotes = (data: any) => {
  return axios.post('pallets/rates', data);
};

const fetchFreightQuotes = (data: any) => {
  return axios.post('freight/rates', data);
};

const fetchTrackingInfo = (data: any) => {
  return axios.get(`tracking/${data}`);
};

const sendPalletManualQuote = (data: any) => {
  return axios.post('pallets/manual/send-request', data);
}

export const setParcelQuotes = (payload: any) => (dispatch: any) => {
  dispatch(setLoading('quotes', true));
  dispatch(setResetFilters(true));

  fetchParcelQuotes(payload)
    .then((res: any) => {
      if (!res?.data?.length) return;
      const data = res.data.map((rate: any) => {
        return {
          ...rate,
          active: true,
          printer: !!rate.printer,
        };
      });

      const impressions = mapQuotesToGTM(data);

      GTMECommerce({
        currencyCode: 'GBP',
        impressions,
      },
      'productImpression');

      dispatch(setParcelQuotesAction(sort(data, 'price')));
      dispatch(setError('quotes', false));
    })
    .catch((e: any) => {
      dispatch(setError('quotes', e.message));
    })
    .finally(() => {
      dispatch(setLoading('quotes', false));
    });
};

export const setPalletQuotes = (
  payload: any,
  sorted: any = false,
  setInFreight: any = false,
  merge: any = false,
) => (dispatch: any) => {
  dispatch(setLoading('quotes', true));
  dispatch(setResetFilters(true));

  fetchPalletQuotes(payload)
    .then((res: any) => {
      const rates = res.data.rates ? res.data.rates : res.data;
      if (rates.length === 0 && noPalletQuoteUrl) {
        // Only redirect for env that do have a noPalletQuoteUrl.
        // MPD and P4D have extra functionality to do inline manual quotes
        window.location.href = `${landingPageUrl}${noPalletQuoteUrl}`;
      } else {
        const data = rates.map((rate: any) => {
          return {
            ...rate,
            active: true,
            quoteType: 'pallet',
          };
        });

        const impressions = mapQuotesToGTM(data);

        GTMECommerce({
          currencyCode: 'GBP',
          impressions,
        },
        'productImpression');

        if (res.data.quoteId) {
          dispatch(setQuoteId(res.data.quoteId));
        }

        if (setInFreight) {
          dispatch(setFreightQuotesAction({
            data: sort(data, 'price'),
            merge: true
          }));
        } else if (sorted) {
          dispatch(setPalletQuotesSortedAction(sort(data, 'price')));
        } else {
          dispatch(setPalletQuotesAction({
            data: sort(data, 'price'),
            merge,
          }));
        }
      
        dispatch(
          setEarliestCollectionDate(
            sort(data, 'earliestCollectionDate', true)[0].earliestCollectionDate
          )
        );
        dispatch(
          setEarliestDeliveryDate(
            sort(data, 'deliveryDate', true).map((item: any) => ({
              ...item,
              deliveryDate: calculateForAnyTime(item.deliveryTime, item.earliestCollectionDate, item.deliveryDate, item.deliveryDays)
            }))[0].deliveryDate
          )
        );
        dispatch(setError('quotes', false));
      }
    })
    .catch((e: any) => {
      dispatch(setError('quotes', e.message));
    })
    .finally(() => {
      dispatch(setLoading('quotes', false));
    });
};

export const setFreightQuotes = (
  payload: any,
  setInPallets: any = false,
  sorted: any = false,
  merge: any = false,
) => (dispatch: any) => {
  dispatch(setLoading('quotes', true));
  dispatch(setResetFilters(true));

  fetchFreightQuotes(payload)
    .then((res: any) => {
      if (res.data.length === 0 && !setInPallets) {
        return [];
      } else {
        const data = res.data.map((rate: any) => {
          return {
            ...rate,
            active: true,
            quoteType: 'freight',
          };
        });

        const impressions = mapQuotesToGTM(data);

        GTMECommerce({
          currencyCode: 'GBP',
          impressions,
        },
        'productImpression');

        if (setInPallets) {
          dispatch(setPalletQuotesAction({
            data: sort(data, 'price'),
            merge: true
          }));
        } else if (sorted) {
          dispatch(setFreightQuotesSortedAction(sort(data, 'price')));
        } else {
          dispatch(setFreightQuotesAction({
            data: sort(data, 'price'),
            merge,
          }));
        }

        dispatch(setError('quotes', false));
      }
    })
    .catch((e: any) => {
      dispatch(setError('quotes', e.message));
    })
    .finally(() => {
      dispatch(setLoading('quotes', false));
    });
};

export const setSeaFreightQuotes = (
  payload: any,
  setInPallets: any = false,
  sorted: any = false,
) => (dispatch: any) => {
  dispatch(setLoading('quotes', true));
  dispatch(setResetFilters(true));

  fetchFreightQuotes(payload)
    .then((res: any) => {
      if (res.data.length === 0) {
        window.location.href = `${landingPageUrl}${noFreightQuoteUrl}`;
      } else {
        const data = res.data.map((rate: any) => {
          return {
            ...rate,
            active: true,
            quoteType: 'seaFreight',
          };
        });

        const impressions = mapQuotesToGTM(data);

        GTMECommerce({
          currencyCode: 'GBP',
          impressions,
        },
        'productImpression');

        if (setInPallets) {
          dispatch(setPalletQuotesAction({
            data: sort(data, 'price'),
            merge: true
          }));
        } else if (sorted) {
          dispatch(setFreightQuotesSortedAction(sort(data, 'price')));
        } else {
          dispatch(setSeaFreightQuotesAction({
            data: sort(data, 'price'),
            merge: false,
          }));
        }

        dispatch(setError('quotes', false));
      }
    })
    .catch((e: any) => {
      dispatch(setError('quotes', e.message));
      window.location.href = `${landingPageUrl}${noFreightQuoteUrl}`;
    })
    .finally(() => {
      dispatch(setLoading('quotes', false));
    });
};

export const setLoading = (key: any, value: any) => ({
  type: SET_LOADERS,
  payload: {
    key,
    value,
  },
});

export const setError = (key: any, value: any) => ({
  type: SET_ERRORS,
  payload: {
    key,
    value,
  },
});

export const setActiveTab = (value: any) => ({
  type: SET_ACTIVE_TAB,
  payload: value,
});

export const setResetFilters = (value: any) => ({
  type: SET_RESET_FILTERS,
  payload: value,
});

export const setParcelValues = (parcel: any) => ({
  type: SET_PARCEL_VALUES,
  payload: parcel,
});

export const setFreightValues = (freight: any) => ({
  type: SET_FREIGHT_VALUES,
  payload: freight,
});

export const setSeaFreightValues = (freight: any) => ({
  type: SET_SEA_FREIGHT_VALUES,
  payload: freight,
});

export const setPallet = (pallet: any) => ({
  type: SET_PALLET_VALUES,
  payload: pallet,
});

const setTrackingInfoAction = (payload: any) => ({
  type: SET_TRACKING_DETAILS,
  payload,
});

export const setTrackingInfo = (payload: any) => (dispatch: any) => {
  dispatch(setLoading('tracking', true));
  dispatch(setError('tracking', false));

  fetchTrackingInfo(payload)
    .then((res: any) => {
      if (res.data?.stages?.length === 0 || res.data?.type === 'unknown') {
        dispatch(setError('tracking', 'Sea freight tracking not available right now.'));
        dispatch(setTrackingInfoAction([]));
      } else {
        dispatch(setTrackingInfoAction(res.data));
      }
    })
    .catch((e: any) => {
      dispatch(setError('tracking', e.message));
      dispatch(setTrackingInfoAction([]));
    })
    .finally(() => {
      dispatch(setLoading('tracking', false));
    });
};

export const setSelectedService = (service: any, type: any) => ({
  type: SET_SELECTED_SERVICE,
  payload: {
    service,
    type,
  },
});

export const submitManualPalletQuote = (data: any) => (dispatch: any) => {
  sendPalletManualQuote(data)
    .then((res: any) => {
      console.log('success')
    })
};

export const setItemDetails = (item: any, type: any, loadType?: any) => ({
  type: SET_ITEM_DETAILS,
  payload: {
    item,
    type,
    loadType
  },
});

export const setShipmentDetails = (shipment: any, type: any, loadType?: any) => ({
  type: SET_SHIPMENT_DETAILS,
  payload: {
    shipment,
    type,
    loadType
  },
});

export const setCustomsDetails = (payload: any) => ({
  type: SET_CUSTOMS_DETAILS,
  payload,
});

export const setSeaFreightDetails = (payload: any) => ({
  type: SET_SEA_FREIGHT_DETAILS,
  payload,
});

export const setInsuranceDetails = (payload: any) => ({
  type: SET_INSURANCE_DETAILS,
  payload,
});

export const addOrderToBasket = () => ({
  type: ADD_ORDER_TO_BASKET,
});

export const addOrderToBasketManual = (payload: any) => ({
  type: ADD_ORDER_TO_BASKET_MANUAL,
  payload,
});

export const removeOrderFromBasket = (payload: any) => ({
  type: REMOVE_ORDER_FROM_BASKET,
  payload,
});

export const resetBasket = () => ({
  type: RESET_BASKET,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const resetQuotes = () => ({
  type: RESET_QUOTES,
});

export const createOrderRequest = (data: any) => {
  return axios.post('order', data);
};

export const sendBasketData = async (data: any) => {
  if (cookieConsent === 'deny') return false;
  const response = await axios.post('basket', data);
  return response;
};

export const completeStripeTransaction = (token: any) => {
  return axios.get(`/payment/stripe/complete/${token}`);
};

export const setDeliveryAddress = (payload: any) => ({
  type: SET_DELIVERY_ADDRESS,
  payload,
});

export const setRecipientInfo = (payload: any) => ({
  type: SET_RECIPIENT_DETAILS,
  payload,
});

export const setCollectionAddress = (payload: any) => ({
  type: SET_COLLECTION_ADDRESS,
  payload,
});

export const setCollectionDetails = (payload: any) => ({
  type: SET_COLLECTION_DETAILS,
  payload,
});

export const addressBookToggle = (payload: any) => ({
  type: ADD_ADDRESS_TO_ADDRESS_BOOK_TOGGLE,
  payload,
});

export const DropOffPoints = (payload: any) => (dispatch: any) => {
  dispatch(setLoading('dropOffPoints', true));

  fetchParcelQuotes(payload)
    .then((res: any) => {
      const data = res.data.map((rate: any) => {
        return {
          ...rate,
          active: true,
          printer: !!rate.printer,
        };
      });
      dispatch(setParcelQuotesAction(sort(data, 'price')));
      dispatch(setError('dropOffPoints', false));
    })
    .catch((e: any) => {
      dispatch(setError('dropOffPoints', e.message));
    })
    .finally(() => {
      dispatch(setLoading('dropOffPoints', false));
    });
};

export const setDropoffPoint = (payload: any) => ({
  type: SET_DROPOFF_POINT,
  payload,
});

export const setOrderData = (payload: any) => ({
  type: SET_ORDER_DATA,
  payload,
});

export const logInUserAction = (payload: boolean) => ({
  type: SET_USER_LOGGED,
  payload,
});

export const setUserSession = (payload: any) => ({
  type: SET_USER_SESSION,
  payload,
});

export const resetUserSession = () => ({
  type: RESET_USER_SESSION,
});

export const setBackToTokenExpired = (payload: any) => ({
  type: BACK_TO_TOKEN_EXPIRED,
  payload,
});

export const setTimeSlot = (payload: any) => ({
  type: SET_DELIVERYTIME,
  payload,
});

export const setCurrentOrderStep = (payload: any) => {
  return ({
    type: SET_CURRENT_ORDER_STEP,
    payload,
  });
};

export const goToNextQuickBookStep = (payload: any = 0) => ({
  type: GOTO_NEXT_QUICKBOOK_STEP,
  payload,
});

export const setCurrentQuickBookStep = (payload: any) => ({
  type: SET_CURRENT_QUICKBOOK_STEP,
  payload,
});

export const addParcelToQuickbook = (payload: any) => ({
  type: ADD_PARCEL_TO_QUICKBOOK,
  payload,
});

export const addAccountDiscount = (payload: any) => ({
  type: ADD_ACCOUNT_DISCOUNT,
  payload,
});

export const setAffliateLoaded = (payload = false) => ({
  type: SET_AFFLIATE_SCRIPT_LOADING,
  payload,
});

export const setBasketItemId = (payload: number) => ({
  type: SET_BASKET_ITEM_ID,
  payload,
});
