import { v4 as uuidv4 } from 'uuid';
import {
  SET_ERRORS,
  SET_LOADERS,
  SET_RESET_FILTERS,
  SET_PARCEL_QUOTES,
  SET_PARCEL_COLLECTION_COUNTRY,
  SET_PARCEL_COLLECTION_POSTCODE,
  SET_PARCEL_VALUES,
  SET_PALLET_VALUES,
  SET_ACTIVE_TAB,
  SET_PALLET_QUOTES,
  SET_EARLIEST_COLLECTION_DATE,
  SET_EARLIEST_DELIVERY_DATE,
  SET_TRACKING_DETAILS,
  RESET_STATE,
  SET_FREIGHT_VALUES,
  SET_FREIGHT_QUOTES,
  SET_PALLET_QUOTES_SORT,
  SET_FREIGHT_QUOTES_SORT,
  RESET_QUOTES,
  SET_PARCEL_COLLECTION_DATE,
  ADD_ACCOUNT_DISCOUNT,
  SET_SEA_FREIGHT_VALUES,
  SET_SEA_FREIGHT_QUOTES,
  SET_AFFLIATE_SCRIPT_LOADING,
  SET_QUOTE_ID,
  SET_BOOKER_EMAIL,
  SET_QUOTE_URL
} from '../actionConstants';
import { sort } from '../../utils/sorting';

const initParcel = {
  collectionLocation: {
    country: '',
    postcode: '',
  },
  packageDetails: [
    {
      id: uuidv4(),
      length: {
        amount: '',
        units: 'cm',
      },
      width: {
        amount: '',
        units: 'cm',
      },
      height: {
        amount: '',
        units: 'cm',
      },
      weight: {
        amount: '',
        units: 'kg',
      },
    },
  ],
  deliveryLocation: {
    country: '',
    postcode: '',
  },
};

const initPallet = {
  collectionLocation: {
    country: '',
    postcode: '',
  },
  deliveryLocation: {
    country: '',
    postcode: '',
  },
  packageDetails: {
    mini: {
      palletSizeId: 1,
      quantity: '',
    },
    quarter: {
      palletSizeId: 2,
      quantity: '',
    },
    half: {
      palletSizeId: 3,
      quantity: '',
    },
    light: {
      palletSizeId: 5,
      quantity: '',
    },
    full: {
      palletSizeId: 6,
      quantity: '',
    },
  },
  dims: [],
  palletAndWrap: false,
};

const initFreight = {
  collectionLocation: {
    country: '',
    postcode: '',
  },
  deliveryLocation: {
    country: '',
    postcode: '',
  },
  packageDetails: {
    numPallets: 26,
  },
  readyDate: '',
};

const initSeaFreight = {
  collectionLocation: {
    country: '',
    postcode: '',
  },
  deliveryLocation: {
    country: '',
    postcode: '',
  },
  packageDetails: {
    readyDate: '',
    hazardous: false,
    stackable: false,
    customs: false,
    package: {
      type: 'FCL',
      subType: 'pallet',
      items: [
        {
          id: uuidv4(),
          weight: {
            amount: '',
            unit: 'kg',
          },
          length: {
            amount: '',
            unit: 'cm',
          },
          width: {
            amount: '',
            unit: 'cm',
          },
          height: {
            amount: '',
            unit: 'cm',
          },
          quantity: {
            amount: 1,
          },
        },
      ],
    },
  },
};

const initState: any = {
  loading: {
    quotes: true,
    tracking: false,
  },
  errors: {
    quotes: false,
    tracking: false,
  },
  activeTab: 'parcel',
  activeParcel: true,
  activePallet: false,
  parcel: initParcel,
  pallet: initPallet,
  freight: initFreight,
  seaFreight: initSeaFreight,
  parcelOrder: {
    activeNav: 1,
    activeStep: 1,
    address: {
      completed: false,
    },
    yourItems: {
      completed: false,
    },
    orderSummary: {
      completed: false,
    },
    pay: {
      completed: false,
    },
  },
  parcelQuotes: [],
  palletQuotes: [],
  freightQuotes: [],
  seaFreightQuotes: [],
  resetFilters: false,
  earliestCollectionDate: '',
  earliestDeliveryDate: '',
  trackingInfo: [],
  discount: {
    value: 0,
    applicableFrom: '',
  },
  isAffliateScriptLoaded: false,
  quoteId: false,
  bookerEmail:'',
  quoteUrl:''
};

const mainReducer = (state = initState, { type, payload }: any): any => {
  switch (type) {
    case SET_LOADERS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload.key]: payload.value,
        },
      };
    case SET_ERRORS:
      return {
        ...state,
        errors: {
          ...state.loading,
          [payload.key]: payload.value,
        },
      };
    case RESET_STATE:
      return {
        ...state,
        parcel: initParcel,
        pallet: initPallet,
        freight: initFreight,
        parcelQuotes: [],
        palletQuotes: [],
        freightQuotes: [],
      };
    case RESET_QUOTES:
      return {
        ...state,
        parcelQuotes: [],
        palletQuotes: [],
        freightQuotes: [],
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
      };
    case SET_PARCEL_VALUES:
      return {
        ...state,
        parcel: {
          ...payload,
        },
      };
    case SET_PALLET_VALUES:
      return {
        ...state,
        pallet: {
          ...payload,
        },
      };
    case SET_FREIGHT_VALUES:
      return {
        ...state,
        freight: {
          ...payload,
        },
      };
    case SET_SEA_FREIGHT_VALUES:
      return {
        ...state,
        seaFreight: {
          ...payload,
        },
      };
    case SET_PARCEL_QUOTES:
      return {
        ...state,
        parcelQuotes: [...payload],
      };
    case SET_PARCEL_COLLECTION_DATE:
      return {
        ...state,
        parcelQuotes: state.parcelQuotes.map((quote: any) => {
          if (quote.id === payload.id) {
            return {
              ...quote,
              collectionDate: payload.date,
            };
          }

          return { ...quote };
        }),
      };
    case SET_PALLET_QUOTES:
      // eslint-disable-next-line no-case-declarations
      let quotesTemp;

      if (payload.merge) {
        quotesTemp = [...state.palletQuotes, ...payload.data];
      } else {
        quotesTemp = [...payload.data];
      }

      return {
        ...state,
        palletQuotes: [...sort(quotesTemp, 'price')],
      };
    case SET_PALLET_QUOTES_SORT:
      return {
        ...state,
        palletQuotes: [...payload],
      };
    case SET_FREIGHT_QUOTES:
      // eslint-disable-next-line no-case-declarations
      let tempQuotes;

      if (payload.merge) {
        tempQuotes = [...state.freightQuotes, ...payload.data];
      } else {
        tempQuotes = [...payload.data];
      }

      return {
        ...state,
        freightQuotes: [...sort(tempQuotes, 'price')],
      };
    case SET_SEA_FREIGHT_QUOTES:
      return {
        ...state,
        seaFreightQuotes: [...sort(payload.data, 'price')],
      };
    case SET_FREIGHT_QUOTES_SORT:
      return {
        ...state,
        freightQuotes: [...payload],
      };
    case SET_EARLIEST_COLLECTION_DATE:
      return {
        ...state,
        earliestCollectionDate: payload,
      };
    case SET_EARLIEST_DELIVERY_DATE:
      return {
        ...state,
        earliestDeliveryDate: payload,
      };
    case SET_RESET_FILTERS:
      return {
        ...state,
        resetFilters: payload,
      };
    case SET_PARCEL_COLLECTION_COUNTRY:
      return {
        ...state,
        parcel: {
          ...state.parcel,
          collectionLocation: {
            ...state.parcel.collectionLocation,
            country: payload,
          },
        },
      };
    case SET_PARCEL_COLLECTION_POSTCODE:
      return {
        ...state,
        parcel: {
          ...state.parcel,
          collectionLocation: {
            ...state.parcel.collectionLocation,
            postcode: payload,
          },
        },
      };
    case SET_TRACKING_DETAILS:
      return {
        ...state,
        trackingInfo: payload,
      };
    case ADD_ACCOUNT_DISCOUNT:
      return {
        ...state,
        discount: {
          value: payload.value,
          applicableFrom: payload.applicableFrom,
        },
      };

    case SET_AFFLIATE_SCRIPT_LOADING:
      return {
        ...state,
        isAffliateScriptLoaded: payload,
      };
    case SET_QUOTE_ID:
      return {
        ...state,
        quoteId: payload,
      };
    case SET_BOOKER_EMAIL:
      return {
        ...state,
        bookerEmail: payload,
      };
    case SET_QUOTE_URL:
      return {
        ...state,
        quoteUrl: payload,
      };
    default:
      return state;
  }
};

export default mainReducer;
