import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Container, Row } from '../../../../../assets/elements';
import device from '../../../../utils/device';
import DeliveryAddress from './DeliveryAddress';
import RecipientDetails from './RecipientDetails';
import SavedAddress from '../Elements/SavedAddress';
import ColumnWrapper from '../Elements/ColumnWrapper';
import ContinueRow from '../Elements/ContinueRow';
import {
  addressBookToggle,
  setCollectionAddress,
  setCollectionDetails as setCollectionInfo, setDeliveryAddress, setRecipientInfo,
} from '../../../../store/actions';

const addressErrorKeys = [
  'addressOne',
  'city',
  'country',
  'zip',
];

const DeliveryDetails = ({
  setDone,
  deliveryType,
  done,
  customs,
  checkCustoms,
  address: defAddress,
  handleContinue,
  resetServices,
}: any) => {
  const dispatch = useDispatch();
  const address = useSelector((state: any) => state.order.deliveryAddress);
  const [doneValues, setDoneValues] = useState<any>([]);

  const [addressData, setAddressData] = useState<any>({
    company: '',
    addressOne: '',
    addressTwo: '',
    addressThree: '',
    city: '',
    country: 'GB',
    zip: '',
  });

  const [addressErrors, setAddressErrors] = useState<any>({
    addressOne: false,
    city: false,
    country: false,
    zip: false,
  });

  const [recipientDetails, setRecipientDetails] = useState<any>({
    name: '',
    email: '',
    phone: '',
    customsData: {
      VATStatus: 'personal_individual',
      VATNumber: '',
      EORINumber: '',
    },
  });

  const [recipientDetailsErrors, setRecipientDetailsErrors] = useState<any>({
    name: false,
    email: false,
    phone: false,
    customsData: {
      VATStatus: false,
      VATNumber: false,
      EORINumber: false,
    },
  });

  const [addToAddressBook, setAddToAddressBook] = useState(false);

  const [editAddress, setEditAddress] = useState(false);

  const handleDone = (type: string, value: boolean) => {
    setDoneValues(value ? [ ...doneValues, type ] : doneValues.filter((c: any) => c !== type));
  };

  useEffect(() => {
    if (deliveryType === 'inbound') {
      handleDone('address', true);
    } else {
      handleDone('address', false);
    }
  }, [deliveryType]);

  useEffect(() => {
    if (!doneValues.includes('address') || !doneValues.includes('details')) {
      setDone(false);
    }
  }, [doneValues]);

  useEffect(() => {
    resetServices();
  }, [addressData, recipientDetails]);

  const validateAddress = () => {
    const errors = { ...addressErrors };
    let error = false;

    let addressValues: any;

    if (deliveryType === 'inbound' && !editAddress) {
      addressValues = defAddress;
    } else {
      addressValues = addressData;
    }

    addressErrorKeys.forEach((key: any) => {
      if (addressValues[key] === '') {
        errors[key] = 'Required';
        error = true;
      }
    });

    setAddressErrors({
      ...addressErrors,
      ...errors,
    });

    return error;
  };

  const validateDetails = () => {
    const errors = { ...recipientDetailsErrors };
    let error = false;

    for (const [key] of Object.entries(recipientDetailsErrors)) {
      if (key !== 'customsData') {
        if (recipientDetails[key] === '') {
          errors[key] = 'Required';
          error = true;
        }

        if (key === 'email' &&
          !recipientDetails[key]
            .match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/)
        ) {
          errors[key] = 'Enter valid email';
          error = true;
        }

        if (key === 'phone' &&
          !recipientDetails[key]
            .match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
        ) {
          errors[key] = 'Enter valid number';
          error = true;
        }
      } else {
        if (recipientDetails.customsData.VATStatus === '') {
          error = true;
          errors.customsData.VATStatus = 'Required';
        }

        if (recipientDetails.customsData.VATStatus === 'company_vat') {
          if (recipientDetails.customsData.VATNumber === '') {
            error = true;
            errors.customsData.VATNumber = 'Required';
          }

          if (recipientDetails.customsData.EORINumber === '') {
            error = true;
            errors.customsData.EORINumber = 'Required';
          } else if (recipientDetails.customsData.EORINumber.length > 17) {
            error = true;
            errors.customsData.EORINumber = 'EORI numbers should be 17 characters or less';
          }
        }
      }
    }

    setRecipientDetailsErrors({
      ...recipientDetailsErrors,
      ...errors,
    });

    return error;
  };

  const handleSubmit = () => {
    const error = validateDetails() || validateAddress();

    if (error) {
      return;
    }

    let addressValues: any;

    if (deliveryType === 'inbound' && !editAddress) {
      addressValues = defAddress;
    } else {
      addressValues = addressData;
    }

    dispatch(setRecipientInfo(recipientDetails));
    dispatch(addressBookToggle(addToAddressBook));
    dispatch(setDeliveryAddress(addressValues));
    handleContinue();
  };

  return (
    <Container>
      <Row>
        <ColumnStyled sizeL={6} sizeM={6} sizeS={3} sizeXS={4}>
          {
            deliveryType === 'inbound' && !editAddress ? (
              <ColumnWrapper heading="Default address">
                <SavedAddress address={address} edit={setEditAddress} />
              </ColumnWrapper>
            ) : (
              <DeliveryAddress
                done={done}
                setDone={(value: any) => handleDone('address', value)}
                checkCustoms={checkCustoms}
                defAddress={defAddress}
                deliveryType={deliveryType}
                addressData={addressData}
                setAddressData={setAddressData}
                addressErrors={addressErrors}
                setAddressErrors={setAddressErrors}
                addToAddressBook={addToAddressBook}
                setAddToAddressBook={setAddToAddressBook}
              />
            )
          }
        </ColumnStyled>
        <ColumnStyled sizeL={6} sizeM={6} sizeS={3} sizeXS={4}>
          <RecipientDetails
            setDone={(value: any) => handleDone('details', value)}
            customs={customs}
            recipientDetails={recipientDetails}
            setRecipientDetails={setRecipientDetails}
            recipientDetailsErrors={recipientDetailsErrors}
            setRecipientDetailsErrors={setRecipientDetailsErrors}
          />
        </ColumnStyled>
      </Row>
      <ContinueRow
        handleClick={handleSubmit}
        disable={!doneValues.includes('address') || !doneValues.includes('details')}
      />
    </Container>
  );
};

const ColumnStyled = styled(Column)`
  padding: 0;
  margin-bottom: 16px;

  @media ${device.laptop} {
    margin-bottom: 0;

    &:first-of-type {
      padding-right: 12px;
    }

    &:last-of-type {
      padding-left: 12px;
    }
  }
`;

export default DeliveryDetails;
