import React, { useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { Row, Column, Input, A } from '../../../../../../../../assets/elements';
import AddIcon from '../../../../../../../../assets/icons/AddIcon';
import RemoveIcon from '../../../../../../../../assets/icons/RemoveIcon';
import device from '../../../../../../../utils/device';
import { v4 as uuidv4 } from 'uuid';

const CustomDimensions = ({
  theme,
  setQuotesDisabled,
  handleCustomDimensionsSwitch,
  validateQuantity,
  pallet,
  setPallet,
}: any) => {
  const [packageError, setPackageError] = useState<any>({
    package: {
      items: {},
    },
  });

  const addNewItem = () => {
    const id = uuidv4();

    setPallet({
      ...pallet,
      dims: [
        ...pallet.dims,
        {
          id,
          weight: '',
          length: '',
          width: '',
          height: '',
          quantity: '1',
        },
      ],
    });

    setPackageError({
      ...packageError,
      package: {
        ...packageError.package,
        items: {
          ...packageError.package.items,
          [id]: {
            length: false,
            width: false,
            height: false,
            weight: false,
            quantity: '',
            dimensions: false,
          },
        },
      },
    });
  };

  const handleDimensionsChange = (value: any, id: any, key: string) => {
    setPallet({
      ...pallet,
      dims: [
        ...pallet.dims.map((item: any) => {
          if (item.id === id) {
            return {
              ...item,
              [key]: value,
            };
          }

          return { ...item };
        }),
      ],
    });
  };

  const handleReadyDateChange = (date: any) => {
    setPallet({
      ...pallet,
      dims: {
        ...pallet.dims,
        readyDate: date,
      },
    });
  };

  const checkDimensions = (length: number, width: number, height: number) => {
    let error = false;

    //check for invalid characters
    if (length < 0 || width < 0 || height < 0) {
      return true;
    } else if (isNaN(Number(length + width + height))) {
      return true;
    }

    // check for max dimensions (120 x 120 x 220)
    if (length > 120 || width > 120 || height > 220) {
      error = true;
    }

    return error;
  };

  const removeItem = (id: any) => {
    const items = pallet.dims.filter((item: any) => item.id !== id);
    setPallet({
      ...pallet,
      dims: [ ...items ],
    });

    const tempErrors = { ...packageError };
    delete tempErrors.package.items[id];

    setPackageError({
      ...tempErrors,
    });
  };

  useEffect(() => {
    const tempErrors: any = {};
    pallet.dims.forEach((item: any) => {
      tempErrors[item.id] = {
        weight: Number(item.weight) > 1000 || Number(item.weight) < 0,
        dimensions: checkDimensions(item.length, item.width, item.height),
        quantity: String(validateQuantity(item.quantity)),
        length: item.length !== '' && Number(item.length) < 0,
        width: item.width !== '' && Number(item.width) < 0,
        height: item.height !== '' && Number(item.height) < 0,
      };
    });

    setPackageError({
      ...packageError,
      package: {
        ...packageError.package,
        items: {
          ...packageError.package.items,
          ...tempErrors,
        },
      },
    });
  }, [pallet.dims]);

  useEffect(() => {
    let error = false;
    Object.keys(packageError.package.items).map((id: any) => {
      if (
        packageError.package.items[id].weight ||
        packageError.package.items[id].dimensions ||
        packageError.package.items[id].quantity !== '' ||
        packageError.package.items[id].length ||
        packageError.package.items[id].width ||
        packageError.package.items[id].height
      ) {
        error = true;
        return;
      }
    });
    setQuotesDisabled(error);
  }, [packageError.package.items]);

  return (
    <Wrapper>
      {pallet.dims.map((item: any, index: any) => (
        <React.Fragment key={index}>
          <RowStyled key={index}>
            <ColumnPad sizeL={6} sizeM={6} sizeS={6}>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <LabelStyled>Dimensions</LabelStyled>
              <Roww>
                <ColumnStyled sizeL={3.33} sizeM={3} sizeS={1.5} sizeXS={1}>
                  <Input
                    placeholder="Length"
                    value={item.length}
                    onChange={(e: any) =>
                      handleDimensionsChange(e.target.value, item.id, 'length')
                    }
                    theme={undefined}
                    type="text"
                    error={
                      packageError.package.items[item.id]
                        ? packageError.package.items[item.id].dimensions
                        : false
                    }
                  />
                  <ErrorText>
                    {packageError.package.items[item.id]
                      ? packageError.package.items[item.id].length
                      : false}
                  </ErrorText>
                </ColumnStyled>
                <ColumnStyledMargin
                  sizeL={3.33}
                  sizeM={3}
                  sizeS={1.5}
                  sizeXS={1}
                >
                  <Input
                    placeholder="Width"
                    value={item.width}
                    onChange={(e: any) =>
                      handleDimensionsChange(e.target.value, item.id, 'width')
                    }
                    theme={undefined}
                    type="text"
                    error={
                      packageError.package.items[item.id]
                        ? packageError.package.items[item.id].dimensions
                        : false
                    }
                  />
                  <ErrorText>
                    {packageError.package.items[item.id]
                      ? packageError.package.items[item.id].width
                      : false}
                  </ErrorText>
                </ColumnStyledMargin>
                <ColumnStyledMargin sizeL={5.33} sizeM={6} sizeS={3} sizeXS={2}>
                  <Input
                    placeholder="Height"
                    value={item.height}
                    onChange={(e: any) =>
                      handleDimensionsChange(e.target.value, item.id, 'height')
                    }
                    theme={undefined}
                    type="text"
                    error={
                      packageError.package.items[item.id]
                        ? packageError.package.items[item.id].dimensions
                        : false
                    }
                  />
                  <InputUnitsLabel>cm</InputUnitsLabel>
                  <ErrorText>
                    {packageError.package.items[item.id]
                      ? packageError.package.items[item.id].height
                      : false}
                  </ErrorText>
                </ColumnStyledMargin>
              </Roww>
            </ColumnPad>
            <ColumnWeightWrap sizeL={3} sizeM={3} sizeS={3} sizeXS={2}>
              <Columnm>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <LabelStyled>Weight</LabelStyled>
                <Roww>
                  <ColumnWeight sizeL={12} sizeM={12}>
                    <Input
                      placeholder="Weight"
                      value={item.weight}
                      onChange={(e: any) =>
                        handleDimensionsChange(
                          e.target.value,
                          item.id,
                          'weight'
                        )
                      }
                      theme={undefined}
                      type="number"
                      error={
                        packageError.package.items[item.id]
                          ? packageError.package.items[item.id].weight
                          : false
                      }
                    />
                    <InputUnitsLabel>kg</InputUnitsLabel>
                  </ColumnWeight>
                  <ErrorText>
                    {packageError.package.items[item.id]
                      ? packageError.package.items[item.id].weight
                      : false}
                  </ErrorText>
                </Roww>
              </Columnm>
            </ColumnWeightWrap>
            <ColumnQuantity sizeL={3} sizeM={3} sizeS={4} sizeXS={1.7}>
              <ColumnPad sizeL={8} sizeM={8} sizeS={2} sizeXS={3}>
                <div>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <LabelStyled>Quantity</LabelStyled>
                  <div>
                    <InputStyled
                      title="Quantity"
                      error={
                        packageError.package.items[item.id]
                          ? packageError.package.items[item.id].quantity !== ''
                          : false
                      }
                      value={item.quantity}
                      onChange={(e) => {
                        handleDimensionsChange(
                          e.target.value,
                          item.id,
                          'quantity'
                        );
                      }}
                      width="100%"
                      height="50px"
                    />
                  </div>
                </div>
              </ColumnPad>
              <ColumnPad sizeL={4} sizeM={4} sizeS={2} sizeXS={1}>
                <ColumnIcon
                  style={{ padding: 0 }}
                  sizeL={12}
                  sizeM={12}
                  sizeS={6}
                  sizeXS={4}
                >
                  {pallet.dims.length > 1 && (
                    <IconContainer>
                      {/* eslint-disable-next-line react/button-has-type */}
                      <AButton
                        data-tip=""
                        data-for="remove-icon"
                        onClick={() => removeItem(item.id)}
                      >
                        <RemoveIcon width={20} height={20} />
                      </AButton>
                      <ReactTooltip id="remove-icon" effect="solid">
                        Remove item
                      </ReactTooltip>
                    </IconContainer>
                  )}
                </ColumnIcon>
              </ColumnPad>
            </ColumnQuantity>
          </RowStyled>
          {packageError.package.items[item.id] !== undefined &&
            (packageError.package.items[item.id].weight ||
              packageError.package.items[item.id].dimensions ||
              packageError.package.items[item.id].quantity !== '') && (
              <ErrorBox>
                {packageError.package.items[item.id].dimensions && (
                  <ErrorLine>
                    Please check dimensions. Maximum dimensions are 120cm (L) x
                    120cm (W) x 220cm (H)
                  </ErrorLine>
                )}
                {packageError.package.items[item.id].weight && (
                  <ErrorLine>
                    Please check weight. Maximum weight is 1000kg
                  </ErrorLine>
                )}
                {packageError.package.items[item.id].quantity !== '' ? (
                  <ErrorLine>
                    {packageError.package.items[item.id].quantity}
                  </ErrorLine>
                ) : null}
              </ErrorBox>
            )}
        </React.Fragment>
      ))}
      <BottomWrapper>
        <AddAnother onClick={addNewItem}>
          <AddIcon width={16} height={16} color={theme.colors.Primary} />
          <AddAnotherText>Add another package</AddAnotherText>
        </AddAnother>
        <CustomDimetionsSwitch onClick={() => handleCustomDimensionsSwitch()}>
          I know rough dimensions of my pallet
        </CustomDimetionsSwitch>
      </BottomWrapper>
    </Wrapper>
  );
};

const RowStyled = styled(Row)`
  margin-bottom: 24px;
  justify-content: flex-start;
  flex-direction: column;

  @media ${device.laptop} {
    flex-direction: row;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const LabelStyled = styled.label`
  margin-top: 16px;
  font-style: normal;
  display: inline-block;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 8px;
  text-transform: none;
  color: ${({ theme }: any) => theme.colors.DarkGrey};

  @media ${device.laptopL} {
    margin-top: 0;
  }
`;

const Wrapper = styled.div`
  margin-top: 16px;
`;

const ColumnStyled = styled(Column)`
  padding: 0;
  position: relative;

  & > input {
    border-radius: unset;
  }

  &:first-child > input {
    border-top-left-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
    border-bottom-left-radius: ${({ theme }: any) =>
      theme.box.BorderRadiusSmall};
  }

  &:last-child > input {
    border-top-right-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
    border-bottom-right-radius: ${({ theme }: any) =>
      theme.box.BorderRadiusSmall};
  }
`;

const ColumnStyledMargin = styled(ColumnStyled)`
  margin-left: -4px;
`;

const ColumnWeight = styled(ColumnStyled)`
  & > input {
    border-radius: 4px !important;
  }
`;

const ColumnPad = styled(Column)`
  margin-bottom: 0;
  padding: 0;
  text-align: left;

  @media ${device.laptopL} {
    padding: 0 15px;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
`;

const ColumnWeightWrap = styled(Column)`
  margin-bottom: 0;
  padding: 0;
  text-align: left;
`;

const ColumnIcon = styled(Column)`
  justify-content: flex-start;
  display: flex;
  padding: 0 0 0 16px !important;
  align-items: center;
  height: 100%;
`;

const ColumnQuantity = styled(ColumnPad)`
  display: flex;
`;

const AddAnother = styled(A)`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const AddAnotherText = styled.span`
  margin-left: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  color: ${({ theme }: any) => theme.colors.Secondary};
`;

const TotalsContainer = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-top: 24px;
`;

const ErrorBox = styled.div`
  padding: 8px 12px;
  border-radius: 4px;
  background: ${({ theme }: any) => theme.colors.ErrorBackground};
  color: ${({ theme }: any) => theme.colors.Error};
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 8px;
`;

const ErrorLine = styled.span`
  display: block;
`;

const TotalErrorBox = styled(ErrorBox)`
  margin-top: 16px;
  margin-bottom: 0;
`;

const CustomDimetionsSwitch = styled(A)`
  float: right;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 0 10px 0 10px;
`;

const InputStyled = styled.input.attrs(({ error }: any) => ({
  error,
}))`
  border: 2px solid
    ${({ error, theme }: any) =>
      error ? theme.colors.Error : theme.colors.LightGrey};
  border-radius: 4px;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  background-color: ${({ theme }: any) => theme.colors.White};
  width: 100%;
  padding: 12px 14px;
  outline: unset;

  &::placeholder {
    color: ${({ theme }: any) => theme.colors.LightGrey};
  }

  &:focus {
    border-color: ${({ theme }: any) => theme.colors.Secondary};
  }
`;

const Roww = styled.div`
  box-sizing: border-box;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 1440px) {
    max-width: 1140px;
  }
`;

const InputUnitsLabel = styled.p`
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
  font-size: 1rem;
  line-height: 150%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }: any) => theme.colors.GrayWhite};
  border-radius: 0 4px 4px 0;
  position: absolute;
  top: 2px;
  right: 2px;
  width: 40%;
  bottom: 2px;
  height: calc(100% - 4px);
  margin: 0;
`;

const ErrorText = styled.span`
  font-weight: normal;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 120%;
  color: ${({ theme }: any) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

const Columnm = styled.div`
  padding: 0;
  position: relative;
`;

const IconContainer = styled.div`
  display: flex;
  flex: 0 0 50%;
  justify-content: center;
  max-width: 50%;
  padding-left: 8px;
  align-items: flex-end;
  height: 100%;

  @media (min-width: 1024px) {
    padding-left: 0;
    align-items: unset;
    height: auto;
  }
`;

const AButton = styled.button`
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-style: inherit;
  color: ${({ theme }: any) => theme.colors.Secondary};
  border: unset;
  outline: unset;
  background: unset;
  box-shadow: unset;
  cursor: pointer;
`;
export default withTheme(CustomDimensions);
