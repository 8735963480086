import styled from 'styled-components';

export const A = styled.button.attrs({
  type: 'button',
})`
  font-size: inherit;
  font-style: inherit;
  line-height: inherit;
  font-weight: inherit;

  color: ${({ theme }) => theme.colors.Secondary};
  border: unset;
  outline: unset;
  background: unset;
  box-shadow: unset;
  cursor: pointer;
`;
