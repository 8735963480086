import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
  Button,
  Caption,
  Input,
  Label,
  PSmall,
} from '../../../assets/elements';
import CardDetails from './CardDetails';
import device from '../../utils/device';
import { topUpAccount } from '../../utils/APICalls/Account';

const TopUp = ({ theme, close }: any) => {
  const stripe = useStripe();
  const elements = useElements();

  const [amount, setAmount] = useState<any>('');
  const [amountError, setAmountError] = useState<any>(false);
  const [zip, setZip] = useState('');
  const [cardDetails, setCardDetails] = useState<any>({
    cardNumber: true,
    expiryDate: true,
    cvv: true,
  });
  const [cardDetailsErrors, setCardDetailsErrors] = useState<any>({
    zip: false,
    cardNumber: false,
    expiryDate: false,
    cvv: false,
  });

  const [buttonDisabled, setButtonDisabled] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [paymentError, setPaymentError] = useState<any>(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    let disable = false;

    if (amount === '' || amount === 0) {
      disable = true;
    }

    if (zip === '') {
      disable = true;
    }

    Object.values(cardDetails).forEach((value: any) => {
      if (value) {
        disable = true;
      }
    });

    Object.values(cardDetailsErrors).forEach((value: any) => {
      if (value) {
        disable = true;
      }
    });

    setButtonDisabled(disable);
  }, [amount, zip, cardDetails, cardDetailsErrors]);

  const handleAmountChange = (e: any) => {
    const { value } = e.target;
    const numbers = /^[0-9]+$/;

    if (value !== '' && !value.match(numbers)) {
      setAmountError('Please enter a number to the nearest £1');
      setAmount(value);
    } else {
      setAmountError(false);
      setAmount(value);
    }
  };

  const handleZipChange = (e: any) => {
    const { value } = e.target;

    setCardDetailsErrors({
      ...cardDetailsErrors,
      zip: false,
    });

    setZip(value);
  };

  const validateAmount = () => {
    const numbers = /^[0-9]+$/;
    let error = false;

    if (amount === '' || amount === 0) {
      setAmountError('Please enter an amount');
    } else if (!amount.match(numbers)) {
      setAmountError('Please enter a number to the nearest £1');
      error = true;
    } else {
      setAmountError(false);
    }

    return error;
  };

  const validateZip = () => {
    let error = false;

    if (zip === '') {
      setCardDetailsErrors({
        ...cardDetailsErrors,
        zip: 'Required',
      });
      error = true;
    } else {
      setCardDetailsErrors({
        ...cardDetailsErrors,
        zip: false,
      });
    }

    return error;
  };

  const validateCardDetails = () => {
    let error = false;
    let errors = { ...cardDetailsErrors };

    Object.entries(cardDetails).forEach(({ key, value }: any) => {
      if (value) {
        errors = {
          ...errors,
          [key]: 'Required',
        };

        error = true;
      }
    });

    setCardDetailsErrors({ ...errors });

    return error;
  };

  const handleClose = (refresh: any = false) => {
    close(refresh);
    resetData();
  };

  const resetData = () => {
    setCardDetailsErrors({
      zip: false,
      cardNumber: false,
      expiryDate: false,
      cvv: false,
    });

    setAmount('');
    setAmountError(false);
    setZip('');
    setPaymentError(false);
  };

  const handleStripeSCA = async (data: any, result: any) => {
    if (result.error) {
      setPaymentError('Payment failed please try again.');
      setProcessing(false)
    } else {
      setPaymentError(false)
      const request = {
        amount: Number(amount),
        paymentMethodId: 'not-needed',
        paymentIntentId: data.paymentIntentId
      };

      topUpAccount(request)
        .then((res: any) => {
          const cardNumberElement = elements && elements.getElement('cardNumber');
          const cardExpiryElement = elements && elements.getElement('cardExpiry');
          const cardCvcElement = elements && elements.getElement('cardCvc');
          cardNumberElement && cardNumberElement.clear();
          cardExpiryElement && cardExpiryElement.clear();
          cardCvcElement && cardCvcElement.clear();
          setProcessing(false);
          handleClose(true);
        }).catch((exception: any) => {
          setPaymentError(exception?.message || 'Error with payment.');
          setProcessing(false)
        })
    }
  }

  const handleSubmit = async () => {
    const errorAmount = validateAmount();
    const errorZip = validateZip();
    const errorCard = validateCardDetails();

    if (errorAmount || errorZip || errorCard) {
      return;
    }

    setProcessing(true);

    const cardNumberElement = elements && elements.getElement('cardNumber');
    const cardExpiryElement = elements && elements.getElement('cardExpiry');
    const cardCvcElement = elements && elements.getElement('cardCvc');

    // @ts-ignore
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        address: {
          postal_code: zip,
        },
      },
    });

    if (error) {
      setPaymentError(error);
      setProcessing(false);
    } else {
      const request = {
        amount: Number(amount),
        paymentMethodId: paymentMethod.id,
      };
      setPaymentError(false)
      topUpAccount(request)
        .then((res: any) => {
          if (res.data.requiresAction) {
            if (stripe === null) {
              throw new Error('BUG: stripe is null');
            }
            return stripe.confirmCardPayment(
              res.data.paymentIntentSecret
            ).then(handleStripeSCA.bind(null, res.data))
          } else {
            cardNumberElement && cardNumberElement.clear();
            cardExpiryElement && cardExpiryElement.clear();
            cardCvcElement && cardCvcElement.clear();
            setProcessing(false);
            handleClose(true);
          }
      }).catch((exception: any) => {
        setPaymentError(exception?.message || 'Error with payment.');
        setProcessing(false)
      })
    }
  };

  return (
    <Wrapper>
      <PSmallStyled>
        You don't want to dig out your credit card every time you book a
        delivery! Our account holders add account credit to make their lives
        easier by speeding up the checkout process.
      </PSmallStyled>

      {
        paymentError && (
          <PaymentError>{ (paymentError?.message || 'There was an error with your payment') }</PaymentError>
        )
      }

      <InputContainer>
        <LabelStyled>Top up amount (Integers only) </LabelStyled>
        <ValueContainer>
          <CurrencyLabel>£</CurrencyLabel>
          <Input
            theme={theme}
            value={amount}
            type="text"
            placeholder="Enter amount"
            onChange={handleAmountChange}
            error={amountError}
          />
        </ValueContainer>
        {amountError && <ErrorText>{amountError}</ErrorText>}
      </InputContainer>

      <CardDetails
        zip={zip}
        errors={cardDetailsErrors}
        setErrors={setCardDetailsErrors}
        handleChange={handleZipChange}
        details={cardDetails}
        setDetails={setCardDetails}
      />

      <ButtonsRow>
        <Button color="black" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="secondary"
          disable={buttonDisabled || processing}
          disabled={buttonDisabled || processing}
          onClick={handleSubmit}
        >
          {processing ? 'Processing...' : 'Top up'}
        </Button>
      </ButtonsRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PSmallStyled = styled(PSmall)`
  margin-bottom: 24px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const LabelStyled = styled(Label)`
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 6px;
  color: ${({ theme }: any) => theme.colors.DarkGrey};
`;

const ValueContainer = styled.div`
  display: flex;
  position: relative;

  & > input {
    padding-left: 56px;
  }
`;

const CurrencyLabel = styled.div`
  border-radius: 4px 0px 0px 4px;
  color: ${({ theme }: any) => theme.colors.Black};
  border: 2px solid #f2f3f2;
  background: #f2f3f2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15.5px 14px;
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  margin-top: 4px;

  @media ${device.laptopL} {
    display: inline-block;
  }
`;

const PaymentError = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  margin: 4px 0 4px;

  @media ${device.laptopL} {
    display: inline-block;
  }
`;

export default TopUp;
