import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../../../assets/elements';

const ContinueButton = ({
  children,
  onClick,
  disable = false,
}: any) => {
  return (
    <ButtonStyled
      color="primary"
      block={true}
      disable={disable}
      disabled={disable}
      onClick={onClick}
    >
      { children }
    </ButtonStyled>
  );
};

const ButtonStyled = styled(Button)`
  margin-top: 24px;
`;

export default ContinueButton;
