import React from 'react';
import './PaymentProcessing.css';
import styled from 'styled-components';

const PaymentProcessing = ({ color }: any) => {
  return (
    <Wrapper>
      <svg
        id="payment-processing"
        viewBox="0 0 88 104"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        width="88px"
        height="88px"
      >
        <path
          id="payment-processing-paper"
          d="M88,0L0,0L0,104L69,104L88,85L88,0Z"
          fill="rgb(242,243,242)"
          stroke="none"
          strokeWidth="1"
        />
        <rect
          id="payment-processing-rec1"
          width="24"
          height="4"
          rx="0"
          ry="0"
          transform="matrix(1 0 0 1 12 22)"
          fill={color}
          stroke="none"
          strokeWidth="1"
        />
        <rect
          id="payment-processing-rec2"
          width="64"
          height="4"
          rx="0"
          ry="0"
          transform="matrix(1 0 0 1 12 36)"
          fill={color}
          stroke="none"
          strokeWidth="1"
        />
        <rect
          id="payment-processing-rec3"
          width="40"
          height="4"
          rx="0"
          ry="0"
          transform="matrix(1 0 0 1 12 50)"
          fill={color}
          stroke="none"
          strokeWidth="1"
        />
        <path
          id="payment-processing-corner"
          d="M69,85L69,104L88,85L69,85Z"
          fill="rgb(188,192,191)"
          stroke="none"
          strokeWidth="1"
        />
      </svg>
      <Text>
        Processing order and payment... <br />
        Please wait.
      </Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: center;
`;

const Text = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${({ theme }: any) => theme.colors.Black};
  margin-top: 24px;
`;

export default PaymentProcessing;
