export const ENV = {
  DEV: 'DEV',
  PROD: 'PROD',
  APP: 'APP',
};

export const Environments = {
  DEV: 'DEV',
  STAGING: 'STAGING',
  CMP: 'CMP',
  PALLETS4DELIVERY: 'PALLETS4DELIVERY',
  MPD: 'MPD',
  P4D: 'P4D',
  ARW: 'ARW',
  AUC: 'AUC',
};

const ORIGINS = {
  [ENV.DEV]: [
    'localhost:3000',
    'e-gistics-frontend.herokuapp',
    'app.egistics.pwiltsher',
  ],
  [ENV.PROD]: ['app.collectmyparcel'],
};

export const WEBSOCKET_URL = {
  [ENV.DEV]: {
    url: 'ws://backend-api-staging.herokuapp.com',
    secure: false,
  },
  [ENV.PROD]: {
    url: 'wss://api.rate-club.com',
    secure: true,
  },
  [Environments.STAGING]: {
    url: 'wss://backend-api-staging.herokuapp.com',
    secure: true,
  },
  [Environments.CMP]: {
    url: 'wss://api.rate-club.com',
    secure: true,
  },
  [Environments.PALLETS4DELIVERY]: {
    url: 'wss://api.rate-club.com',
    secure: true,
  },
  [Environments.MPD]: {
    url: 'wss://api.rate-club.com',
    secure: true,
  },
  [Environments.P4D]: {
    url: 'wss://api.rate-club.com',
    secure: true,
  },
  [Environments.ARW]: {
    url: 'wss://api.rate-club.com',
    secure: true,
  },
  [Environments.AUC]: {
    url: 'wss://api.rate-club.com',
    secure: true,
  },
};

export const COMPANY_NAME = {
  STAGING: 'Haulable',
  CMP: 'CollectMyParcel',
  PALLETS4DELIVERY: 'Pallets4Delivery',
  MPD: 'MyParcelDelivery',
  P4D: 'P4D',
  ARW: 'Action Roadways Ltd',
  AUC: 'Auction Deliveries',
};

export const MAINTENANCE_MODE = {
  [ENV.DEV]: false,
  [ENV.PROD]: false,
  [Environments.STAGING]: false,
  [Environments.CMP]: false,
  [Environments.PALLETS4DELIVERY]: false,
  [Environments.MPD]: false,
  [Environments.P4D]: false,
  [Environments.ARW]: false,
  [Environments.AUC]: false,
};

const API_URL = {
  [ENV.DEV]: 'http://localhost:5002/v1/',
  [ENV.PROD]: 'https://api.rate-club.com/v1/',
  [Environments.STAGING]: 'https://backend-api-staging.herokuapp.com/v1/',
  [Environments.CMP]: 'https://api.rate-club.com/v1/',
  [Environments.PALLETS4DELIVERY]: 'https://api.rate-club.com/v1/',
  [Environments.MPD]: 'https://api.rate-club.com/v1/',
  [Environments.P4D]: 'https://api.rate-club.com/v1/',
  [Environments.ARW]: 'https://api.rate-club.com/v1/',
  [Environments.AUC]: 'https://api.rate-club.com/v1/',
};

const APP_URL = {
  [ENV.DEV]: 'http://localhost:3000/',
  [ENV.PROD]: 'https://app.collectmyparcel.com/',
  [Environments.STAGING]: 'https://staging-app.haulable.co.uk/',
  [Environments.CMP]: 'https://app.collectmyparcel.com/',
  [Environments.PALLETS4DELIVERY]: 'https://app.pallets4delivery.com/',
  [Environments.MPD]: 'https://app.myparceldelivery.com/',
  [Environments.P4D]: 'https://app.p4d.co.uk/',
  [Environments.ARW]: 'https://app.action-roadways.co.uk/',
  [Environments.AUC]: 'https://app.auctiondeliveries.com/',
};

const LANDING_PAGE_URL = {
  [ENV.DEV]: 'http://localhost:3000/',
  [Environments.STAGING]: 'https://staging.haulable.co.uk/',
  [ENV.APP]: 'https://staging.e-gistics.com/',
  [ENV.PROD]: 'https://www.collectmyparcel.com/',
  [Environments.CMP]: 'https://www.collectmyparcel.com/',
  [Environments.PALLETS4DELIVERY]: 'https://www.pallets4delivery.com/',
  [Environments.MPD]: 'https://www.myparceldelivery.com/',
  [Environments.P4D]: 'https://www.p4d.co.uk/',
  [Environments.ARW]: 'https://quote.action-roadways.co.uk/',
  [Environments.AUC]: 'https://quote.auctiondeliveries.com/',
};

// const FAQ_URL = {
//   [ENV.DEV]: 'https://help.collectmyparcel.com/support/home',
//   [Environments.STAGING]: 'https://help.collectmyparcel.com/support/home',
//   [ENV.PROD]: 'https://help.collectmyparcel.com/support/home',
//   [Environments.CMP]: 'https://help.collectmyparcel.com/support/home',
//   [Environments.PALLETS4DELIVERY]:
//     'https://help.pallets4delivery.com/support/home',
//   [Environments.MPD]: 'https://help.myparceldelivery.com/',
//   [Environments.P4D]: 'https://help.p4d.co.uk/support/home',
//   [Environments.ARW]: 'https://action-roadways.co.uk/contact-us',
//   [Environments.AUC]: 'https://help.auctiondeliveries.com/support/home',
// };

const FAQ_URL = {
  [ENV.DEV]: 'https://www.p4d.co.uk/contact-support',
  [Environments.STAGING]: 'https://www.p4d.co.uk/contact-support',
  [ENV.PROD]: 'https://www.p4d.co.uk/contact-support',
  [Environments.CMP]: 'https://www.p4d.co.uk/contact-support',
  [Environments.PALLETS4DELIVERY]: 'https://www.p4d.co.uk/contact-support',
  [Environments.MPD]: 'https://www.p4d.co.uk/contact-support',
  [Environments.P4D]: 'https://www.p4d.co.uk/contact-support',
  [Environments.ARW]: 'https://action-roadways.co.uk/contact-us',
  [Environments.AUC]: 'https://www.p4d.co.uk/contact-support',
};

const SUPPORT_URL = {
  [ENV.DEV]: 'https://www.p4d.co.uk/contact-support',
  [Environments.STAGING]: 'https://www.p4d.co.uk/contact-support',
  [ENV.PROD]: 'https://www.p4d.co.uk/contact-support',
  [Environments.CMP]: 'https://www.p4d.co.uk/contact-support',
  [Environments.PALLETS4DELIVERY]: 'https://www.p4d.co.uk/contact-support',
  [Environments.MPD]: 'https://www.p4d.co.uk/contact-support',
  [Environments.P4D]: 'https://www.p4d.co.uk/contact-support',
  [Environments.ARW]: 'https://action-roadways.co.uk/contact-us',
  [Environments.AUC]: 'https://www.p4d.co.uk/contact-support',
};

const TICKETS_URL = {
  [ENV.DEV]: 'https://www.p4d.co.uk/contact-support',
  [Environments.STAGING]: 'https://www.p4d.co.uk/contact-support',
  [ENV.PROD]: 'https://www.p4d.co.uk/contact-support',
  [Environments.CMP]: 'https://www.p4d.co.uk/contact-support',
  [Environments.PALLETS4DELIVERY]: 'https://www.p4d.co.uk/contact-support',
  [Environments.MPD]: 'https://www.p4d.co.uk/contact-support',
  [Environments.P4D]: 'https://www.p4d.co.uk/contact-support',
  [Environments.ARW]: 'https://action-roadways.co.uk/contact-us',
  [Environments.AUC]: 'https://www.p4d.co.uk/contact-support',
};

// const SUPPORT_URL = {
//   [ENV.DEV]: 'https://help.collectmyparcel.com/support/home',
//   [Environments.STAGING]: 'https://help.collectmyparcel.com/support/home',
//   [ENV.PROD]: 'https://help.collectmyparcel.com/support/home',
//   [Environments.CMP]: 'https://help.collectmyparcel.com/support/home',
//   [Environments.PALLETS4DELIVERY]:
//     'https://help.pallets4delivery.com/support/home',
//   [Environments.MPD]: 'https://help.myparceldelivery.com/support/home',
//   [Environments.P4D]: 'https://help.p4d.co.uk/support/home',
//   [Environments.ARW]: 'https://action-roadways.co.uk/contact-us',
//   [Environments.AUC]: 'https://help.auctiondeliveries.com/support/home',
// };

// const TICKETS_URL = {
//   [ENV.DEV]: 'https://help.collectmyparcel.com/support/tickets/new',
//   [Environments.STAGING]:
//     'https://help.collectmyparcel.com/support/tickets/new',
//   [ENV.PROD]: 'https://help.collectmyparcel.com/support/tickets/new',
//   [Environments.CMP]: 'https://help.collectmyparcel.com/support/tickets/new',
//   [Environments.PALLETS4DELIVERY]:
//     'https://help.pallets4delivery.com/support/tickets/new',
//   [Environments.MPD]: 'https://help.myparceldelivery.com/support/tickets/new',
//   [Environments.P4D]: 'https://help.p4d.co.uk/support/tickets/new',
//   [Environments.ARW]: 'https://action-roadways.co.uk/contact-us',
//   [Environments.AUC]: 'https://help.auctiondeliveries.com/support/tickets/new',
// };

const NO_PARCEL_QUOTE_URL = {
  [ENV.DEV]: 'bespoke-shipment',
  [Environments.STAGING]: 'bespoke-shipment',
  [ENV.APP]: 'bespoke-shipment/',
  [ENV.PROD]: 'bespoke-shipment',
  [Environments.CMP]: 'bespoke-shipment',
  [Environments.PALLETS4DELIVERY]: 'bespoke-shipment',
  [Environments.MPD]: 'bespoke-shipment',
  [Environments.P4D]: 'bespoke-shipment',
  [Environments.ARW]: 'bespoke-shipment',
  [Environments.AUC]: 'bespoke-shipment',
};

const NO_PALLET_QUOTE_URL = {
  [ENV.DEV]: '',
  [Environments.STAGING]: '',
  [ENV.APP]: 'bespoke-shipment/',
  [ENV.PROD]: 'bespoke-shipment',
  [Environments.CMP]: 'bespoke-shipment',
  [Environments.PALLETS4DELIVERY]: 'bespoke-shipment',
  [Environments.MPD]: '',
  [Environments.P4D]: '',
  [Environments.ARW]: 'bespoke-shipment',
  [Environments.AUC]: 'bespoke-shipment',
};

const SALE_ENQUIRY_URL = {
  [ENV.DEV]: 'http://localhost:3000/contact-sales',
  [Environments.STAGING]: 'http://localhost:3000/contact-sales',
  [ENV.APP]: '',
  [ENV.PROD]: '',
  [Environments.CMP]: '',
  [Environments.PALLETS4DELIVERY]: '',
  [Environments.MPD]: '',
  [Environments.P4D]: 'https://www.p4d.co.uk/contact-sales',
  [Environments.ARW]: '',
  [Environments.AUC]: '',
};

const NO_FREIGHT_QUOTE_URL = {
  [ENV.DEV]: 'bespoke-shipment',
  [Environments.STAGING]: 'bespoke-shipment',
  [ENV.APP]: 'bespoke-shipment/',
  [ENV.PROD]: 'bespoke-shipment',
  [Environments.CMP]: 'bespoke-shipment',
  [Environments.PALLETS4DELIVERY]: 'bespoke-shipment',
  [Environments.MPD]: 'bespoke-freight-shipments',
  [Environments.P4D]: 'bespoke-freight-shipments',
  [Environments.ARW]: 'bespoke-shipment',
  [Environments.AUC]: 'bespoke-shipment',
};

const HAZARDOUS_QUOTE_URL = {
  [ENV.DEV]: 'bespoke-shipment',
  [Environments.STAGING]: 'bespoke-shipment',
  [ENV.APP]: 'bespoke-shipment/',
  [ENV.PROD]: 'bespoke-shipment',
  [Environments.CMP]: 'bespoke-shipment',
  [Environments.PALLETS4DELIVERY]: 'bespoke-shipment',
  [Environments.MPD]: 'bespoke-freight-shipments',
  [Environments.P4D]: 'bespoke-freight-shipments',
  [Environments.ARW]: 'bespoke-shipment',
  [Environments.AUC]: 'bespoke-shipment',
};

const SEAFREIGHT_FULL_LOAD_QUOTE_URL = {
  [ENV.DEV]: 'bespoke-shipment',
  [Environments.STAGING]: 'bespoke-shipment',
  [ENV.APP]: 'bespoke-shipment/',
  [ENV.PROD]: 'bespoke-shipment',
  [Environments.CMP]: 'bespoke-shipment',
  [Environments.PALLETS4DELIVERY]: 'bespoke-shipment',
  [Environments.MPD]: 'bespoke-freight-shipments',
  [Environments.P4D]: 'bespoke-freight-shipments',
  [Environments.ARW]: 'bespoke-shipment',
  [Environments.AUC]: 'bespoke-shipment',
};

const SALES_EMAIL = {
  [ENV.DEV]: 'tom@e-gistics.com',
  [Environments.STAGING]: 'tom@e-gistics.com',
  [ENV.APP]: 'sales@p4d.co.uk',
  [ENV.PROD]: 'sales@p4d.co.uk',
  [Environments.CMP]: 'sales@p4d.co.uk',
  [Environments.PALLETS4DELIVERY]: 'sales@p4d.co.uk',
  [Environments.MPD]: 'sales@myparceldelivery.com',
  [Environments.P4D]: 'sales@p4d.co.uk',
  [Environments.ARW]: 'sales@p4d.co.uk',
  [Environments.AUC]: 'sales@p4d.co.uk',
};

export const AFFILIATE_MERCHANT_ID = {
  [Environments.MPD]: 6982,
  [Environments.P4D]: 7345,
};

const getEnv = (origin = window.location.origin) =>
  Object.entries(ORIGINS).reduce(
    (acc, [key, array]) =>
      array.some((url) => origin.includes(url)) ? key : acc,
    ENV.DEV
  );

export const env = getEnv();
export const environment =
  process.env.REACT_APP_CURRENT_ENVIRONMENT?.toString() || 'dev';
export const apiUrl = API_URL[environment.toUpperCase()];
export const appUrl = APP_URL[environment.toUpperCase()];
export const isProd = getEnv() === ENV.PROD;
export const token =
  process.env[`REACT_APP_BEARER_API_TOKEN_${environment.toUpperCase()}`];
export const landingPageUrl = LANDING_PAGE_URL[environment.toUpperCase()];
export const specificLandingPageUrl = (site) => LANDING_PAGE_URL[site];
export const companyName = COMPANY_NAME[environment.toUpperCase()];
export const GTM_ID =
  process.env[`REACT_APP_GTM_ID_${environment.toUpperCase()}`];
export const GTM_AUTH =
  process.env[`REACT_APP_GTM_AUTH_${environment.toUpperCase()}`];
export const GTM_ENV =
  process.env[`REACT_APP_GTM_ENV_${environment.toUpperCase()}`];
export const faqUrl = FAQ_URL[environment.toUpperCase()];
export const AFFILIATE_MERCHANT =
  AFFILIATE_MERCHANT_ID[environment.toUpperCase()];
export const ticketUrl = TICKETS_URL[environment.toUpperCase()];
export const supportUrl = SUPPORT_URL[environment.toUpperCase()];
export const PostcoderKey =
  process.env[`REACT_APP_POSTCODER_API_KEY_${environment.toUpperCase()}`];
export const ZonosAPIKey = process.env.REACT_APP_ZONOS_API_KEY;

export const noParcelQuoteUrl = NO_PARCEL_QUOTE_URL[environment.toUpperCase()];
export const noPalletQuoteUrl = NO_PALLET_QUOTE_URL[environment.toUpperCase()];
export const noFreightQuoteUrl =
  NO_FREIGHT_QUOTE_URL[environment.toUpperCase()];

export const hazardousQuoteUrl = HAZARDOUS_QUOTE_URL[environment.toUpperCase()];
export const seaFreightFullLoadQuoteUrl =
  SEAFREIGHT_FULL_LOAD_QUOTE_URL[environment.toUpperCase()];

export const salesEmailAddress = SALES_EMAIL[environment.toUpperCase()];
export const salesEnquiryUrl = SALE_ENQUIRY_URL[environment.toUpperCase()];
