import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import SingleResultFreight from './SingleResult';
import SingleResultPallet from '../PalletQuotes/SingleResult';
import { Container, PSmall } from '../../../../../assets/elements';
import {
  setItemDetails,
  setSelectedService,
  setFreightQuotesSortedAction,
  resetQuotes,
  setSeaFreightValues,
  setSeaFreightQuotes,
} from '../../../../store/actions';
import NotFound from '../../../NotFound';
import Modal from '../../../../../assets/Combos/Modal';
import StepOneSeaFreight from '../../../LandingPageQuote/components/QuoteForm/SeaFreight/StepOne';
import Sort from '../../../Sort';
import { sort } from '../../../../utils/sorting';
import DeliverBanner from './DeliverBanner';
import { GTMECommerce } from '../../../../utils/tracking';
import device from '../../../../utils/device';
import QuoteLoader from '../../../QuoteLoader';
import EmailQuote from '../EmailQuote';

const sortOptions = [
  {
    id: 0,
    label: 'Cheapest',
  },
  {
    id: 1,
    label: 'Quickest',
  },
];

export const SortValuesMap = {
  CHEAPEST: 0,
  QUICKEST: 1,
};

const SeaFreightQuotes = ({ openInsurance, openEmailModal, setOpenEmailModal }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    collection,
    delivery,
    date,
    stackable,
    customs,
    all,
    load,
    subType,
    items: freightItems,
  } = useParams();

  const [editDetailsModal, setEditDetailsModal] = useState(false);
  const [resetValues, setResetValues] = useState(false);
  const [moreInfoValue, setMoreInfoValue] = useState<any>({});
  const [moreInfoText, setMoreInfoText] = useState<any>();
  const [moreInfoModal, setMoreInfoModal] = useState(false);
  const [sortSelected, setSortSelected] = useState(0);
  const [collectionDate, setCollectionDate] = useState<any>('');

  const quotes = useSelector<any, any>((state) => state.main.seaFreightQuotes);

  const freight = useSelector<any, any>((state) => state.main.seaFreight);

  const loading = useSelector<any, any>((state) => state.main.loading.quotes);

  useEffect(() => {
    if (collection && !freight.collectionLocation.country) {
      const collectionData = collection.split(':');
      const deliveryData = delivery.split(':');

      !collectionData[0] &&
        freight.collectionLocation.country === '' &&
        history.push('/');

      const items = freightItems.split(':');
      const packageItems = items.map((pack: any) => {
        const item = pack.split(',');

        return {
          id: uuid(),
          length: {
            amount: item[0],
            unit: 'cm',
          },
          width: {
            amount: item[1],
            unit: 'cm',
          },
          height: {
            amount: item[2],
            unit: 'cm',
          },
          weight: {
            amount: item[3],
            unit: 'kg',
          },
          quantity: {
            amount: item[4],
          },
        };
      });

      const dateArray = date.split("-")

      dispatch(
        setSeaFreightValues({
          collectionLocation: {
            country: collectionData[0],
            postcode: !collectionData[1] ? '' : collectionData[1],
          },
          deliveryLocation: {
            country: deliveryData[0],
            postcode: deliveryData[1] || '',
          },
          packageDetails: {
            readyDate: new Date(dateArray[2], dateArray[1] - 1, dateArray[0]),
            hazardous: false,
            stackable,
            customs,
            package: {
              type: load,
              subType,
              items: packageItems,
            },
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // dispatch(setFreightQuotesSortedAction([]));

    if (freight.collectionLocation.country !== '') {
      dispatch(setSeaFreightQuotes(generateRequest(), false, false));

    }
  }, [freight, dispatch]);

  const generateRequest = () => {
    return {
      collectionLocation: {
        ...freight.collectionLocation,
      },
      deliveryLocation: {
        ...freight.deliveryLocation,
      },
      type: ['seafreight'],
      readyDate: freight.packageDetails.readyDate,
      packageDetails: freight.packageDetails.package.items.map((item: any) => ({
        length: Number(item.length.amount),
        width: Number(item.width.amount),
        height: Number(item.height.amount),
        weight: Number(item.weight.amount),
        qty: Number(item.quantity.amount),
      })),
      packageType: freight.packageDetails.package.subType.toUpperCase(),
      hazardous: freight.packageDetails.hazardous,
      stackable: freight.packageDetails.stackable === 'true',
      customClearance: freight.packageDetails.customs === 'true',
    };
  };

  useEffect(() => {
    if (moreInfoValue.intro) {
      setMoreInfoText(
        <>
          <PSmallStyled>{moreInfoValue.intro}</PSmallStyled>
          <ul>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                Same day collection: { moreInfoValue.sameDayCollection ? 'Available' : 'Not available' }
              </PSmall>
            </Li>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                Delivery days: {moreInfoValue.deliveryDays}
              </PSmall>
            </Li>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                Delivery window: {moreInfoValue.deliveryWindow}
              </PSmall>
            </Li>
            <Li>
              <PSmall>
                <span role="img" aria-label="Dot">
                  ◾
                </span>
                Notification options: {moreInfoValue.notificationOptions.map((option: any, i: any) =>
                  `${option}${moreInfoValue.notificationOptions.length < i + 1 ? ', ' : ''}`)}
              </PSmall>
            </Li>
          </ul>
        </>
      );
    }
  }, [moreInfoValue]);

  const setMoreInfoData = (value: any) => {
    setMoreInfoValue(value);
  };

  const handleSortChange = (data: any) => {
    setSortSelected(data);

    let payload;

    if (data === SortValuesMap.CHEAPEST) {
      payload = sort(quotes, 'price');
    } else if (data === SortValuesMap.QUICKEST) {
      payload = sort(quotes, 'deliveryDate', true);
    }

    dispatch(setFreightQuotesSortedAction(payload));
  };

  const handleSelect = (selected: any, type: any = 'seaFreight') => {
    let position = 0;

    const selectedQuote = quotes.filter(
      ({ serviceId }: any, i: any) => {
        if (serviceId === selected) {
          position = i + 1;
          return true;
        }

        return false;
      }
    )[0];

    const tempQuote = cloneDeep(selectedQuote);

    tempQuote.collectionDate = moment(collectionDate).format('yyyy-MM-DD');

    if (tempQuote.quoteType === 'pallet') {
      tempQuote.deliveryDate =
        moment(collectionDate).add(tempQuote.deliveryTime, 'days').format('yyyy-MM-DD');

      if (
        moment(tempQuote.deliveryDate).isoWeekday() === 6 ||
        moment(tempQuote.deliveryDate).isoWeekday() === 7) {
        tempQuote.deliveryDate = moment(tempQuote.deliveryDate).add(2, 'days').format('yyyy-MM-DD');
      }
    }

    GTMECommerce(
      {
        click: {
          products: [{
            name: selectedQuote.name,
            id: `${selectedQuote.rateId}-${selectedQuote.serviceId}-freight`,
            price: selectedQuote.priceIncVat,
            category: 'seaFreight',
            variant: 'collection',
            position,
          }],
        }
      },
      'productClick',
    );

    dispatch(setSelectedService(tempQuote, selectedQuote.quoteType || 'seaFreight'));
    if (type === 'seaFreight') {
      dispatch(setItemDetails(freight, selectedQuote.quoteType || 'seaFreight'));
    } else {
      dispatch(setItemDetails(freightToPallet(), selectedQuote.quoteType || 'seaFreight'));
    }
    dispatch(resetQuotes());

    history.push('/order-step1');
  };

  const freightToPallet = () => {
    return {
      collectionLocation: { ...freight.collectionLocation },
      deliveryLocation: { ...freight.deliveryLocation },
      palletAndWrap: false,
      packageDetails: [{
        palletSizeId: 6,
        quantity: freight.packageDetails.numPallets,
      }]
    };
  };

  const notFoundText =
    'Sorry! No results found. Please remove any filters or update your item details to find a quote';

  const noQuotesFound = quotes.length === 0 && (
    <NotFound>{notFoundText}</NotFound>
  );

  const SortJSX = (
    <SortWrapper>
      <Sort
        options={sortOptions}
        selected={sortSelected}
        onChange={handleSortChange}
      />
    </SortWrapper>
  );

  const quotesDisplay =
    quotes.filter(({ active }: any) => active).length === 0 ? (
      <NotFound>{notFoundText}</NotFound>
    ) : (
      quotes.map(
        ({
          serviceId: id,
          name,
          logo,
          status,
          expires,
          deliveryTime,
          deliveryDate,
          deliveryDays,
          earliestCollectionDate,
          price,
          priceIncVat,
          active,
          moreInfo,
          quoteType,
          timed,
          vat,
          timeslots,
          pricesBeforeDiscount,
          coveredInsurance = 0,
        }: any) => {
          return (
            active && (
              quoteType === 'seaFreight' ?
                <SingleResultFreight
                  key={id}
                  id={id}
                  name={name}
                  logo={logo}
                  status={status}
                  deliveryTime={deliveryTime}
                  expires={expires}
                  price={price}
                  priceIncVat={priceIncVat}
                  customs={vat.customs}
                  moreInfo={moreInfo}
                  setMoreInfo={setMoreInfoData}
                  openMoreInfoModal={() => setMoreInfoModal(true)}
                  onSelect={handleSelect}
                  pricesBeforeDiscount={pricesBeforeDiscount}
                  coveredInsurance={coveredInsurance}
                  openInsurance={openInsurance}
                  setOpenEmailModal={setOpenEmailModal}
                /> :
                <SingleResultPallet
                  key={id}
                  id={id}
                  name={name}
                  logo={logo}
                  status={status}
                  deliveryTime={deliveryTime}
                  deliveryDate={deliveryDate}
                  deliveryDays={deliveryDays}
                  earliestCollectionDate={earliestCollectionDate}
                  price={price}
                  priceIncVat={priceIncVat}
                  customs={vat.customs}
                  moreInfo={moreInfo}
                  setMoreInfo={setMoreInfoData}
                  openMoreInfoModal={() => setMoreInfoModal(true)}
                  selectedCD={earliestCollectionDate}
                  selectedDD={deliveryDate}
                  onSelect={(value: any) => handleSelect(value, 'pallet')}
                  timed={timed}
                  timeslots={timeslots}
                  pricesBeforeDiscount={pricesBeforeDiscount}
                  coveredInsurance={coveredInsurance}
                  openInsurance={openInsurance}
                  setOpenEmailModal={setOpenEmailModal}
                />
            )
          );
        }
      )
    );

  const toggleEditDetailsModal = (value: boolean) => {
    setEditDetailsModal(value);
  };

  const handleEditDetailsClick = () => {
    setResetValues(true);
    toggleEditDetailsModal(true);
  };

  const handleMoreInfoClose = () => {
    setMoreInfoModal(false);
    setMoreInfoData({});
  };

  return (
    <>
      <DeliverBanner
        data={freight}
        handleEditDetailsClick={handleEditDetailsClick}
      />
      <Container>
        <ResultsWrapper>
          {loading ? (
            <QuoteLoader />
          ) : (
            noQuotesFound || (
              <>
                <FilterWrapper>
                  {SortJSX}
                </FilterWrapper>
                <EmailWrapper>
                  <EmailQuote packageType='seaFreight' open={openEmailModal} setOpen={setOpenEmailModal} />
                </EmailWrapper>
                {quotesDisplay}
              </>
            )
          )}
        </ResultsWrapper>
      </Container>
      <Modal
        title="Edit details"
        open={editDetailsModal}
        close={() => toggleEditDetailsModal(false)}
        width={10}
      >
        <StepOneSeaFreight
          resetValues={resetValues}
          setResetValues={setResetValues}
          onQuotesPage={true}
          closeModal={() => toggleEditDetailsModal(false)}
        />
      </Modal>
      <Modal
        title="More information"
        open={moreInfoModal}
        close={() => handleMoreInfoClose()}
        back={true}
        width={8}
      >
        {moreInfoText}
      </Modal>
    </>
  );
};

const ResultsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Li = styled.li`
  margin-bottom: 16px;
`;

const PSmallStyled = styled(PSmall)`
  margin-bottom: 16px;
`;

const SortWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FilterWrapper = styled.div`
  margin-bottom: 38px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

const EmailWrapper = styled.div`
  margin: 0 0 24px 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export default SeaFreightQuotes;
