import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from '../../../../../utils/hooks/useQuery';
import Loader from '../../../../Loader';
import { completeKriyaTransaction } from '../../../../../utils/APICalls/Order';
import { resetBasket, sendBasketData } from '../../../../../store/actions';
import { GTMECommerce } from '../../../../../utils/tracking';
import { setCookie } from '../../../../../utils/cookies';

const ConfirmKriya = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const kriyaTransactionId = query.get('tId');

  useEffect(() => {
    completeKriyaTransaction(kriyaTransactionId)
      .then(async (res: any) => {
        let revenue = 0;
        let tax: any = 0;

        const { orderGroupId = '', orders = [] } = res.data.data.orderGroupId;
        if (orderGroupId === '') {
          history.push(`/payment?error=true`);
        }

        dispatch(resetBasket());

        orders.forEach((order: any) => {
          revenue += Number(order.price);
          tax += Number((Number(order.priceIncVat) -
            Number(order.price))
            .toFixed(2));
        });

        GTMECommerce({
          purchase: {
            actionField: {
              id: orderGroupId,
              affiliation: 'Online Store',
              revenue,
              tax,
            },
            products: orders.map((order: any) => ({
              name: `${order?.carrier?.name ? `${order?.carrier?.name} - ` : ''}${order?.service?.name || ''}`,
              id: order.rateId,
              price: Number(order.priceIncVat),
              category: order.type,
              variant: order.type === 'parcel' ? (order?.orderType || 'collection') : 'collection',
              quantity: 1,
            })),
          }
        },
        'productPurchase');
        await captureData(orderGroupId);
        window.location.href = `/success?${orderGroupId || ''}`;
      })
      .catch((err: any) => {
        const { technical, orderGroupId } = err.errorDetails.details;
        history.push(`/payment?error=${technical ? `technical&order=${orderGroupId}` : 'true'}`);
      });
  });

  const captureData = async (orderGroupId: any) => {
    try {
      const dataToSend = {
        currentStep: 7,
        basket: {
          basketId:getCookie('basketId'),
          status: 'order_made',
          orderGroupId
        }
      };
      setCookie([
        {
          name: 'basketId',
          value: 'undefined',
        },
      ]);

      const response = await sendBasketData(dataToSend);
      return response;
    } catch (error) {
      console.log(`Error sending data: ${error}`);
      throw error;
    }
  };

  function getCookie(name: string) {
    const nameEQ = `${name  }=`;
    const ca = document.cookie.split(';');
  
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0)
        return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  return (
    <Wrapper>
      <Loader>Completing your order, please wait </Loader>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 50vh;
  align-items: center;
`;

export default ConfirmKriya;
