import { format } from 'date-fns';
import { appUrl } from './env';
import { euCountries } from '../mocks/quoteData';
import { landingPageUrl, seaFreightFullLoadQuoteUrl } from './env';

const palletSizes: any = {
  1: 'mini',
  2: 'quarter',
  3: 'half',
  5: 'light',
  6: 'full',
};

export const generateQuotesUrl = (
  item: any,
  type: any = 'parcel',
  all: Boolean = false,
  load: String = 'full'
) => {
  let url = `${appUrl}quotes/`;
  let quantity = 0;

  if (type === 'pallet' || type === 'dims') {
    url += 'pallet/';
  } else if (type === 'freight') {
    url += 'freight/road/';
  } else if (type === 'seaFreight') {
    url += 'freight/sea/';
  }

  url += `${item.collectionLocation.country}${
    item.collectionLocation.postcode
      ? `:${item.collectionLocation.postcode.split(' ').join('')}`
      : ''
  }/${item.deliveryLocation.country}${
    item.deliveryLocation.postcode
      ? `:${item.deliveryLocation.postcode.split(' ').join('')}`
      : ''
  }/`;

  if (type === 'parcel') {
    item.packageDetails.forEach((pack: any, i: any) => {
      url += `${pack.weight.amount},${pack.length.amount},${
        pack.width.amount
      },${pack.height.amount}${
        item.packageDetails.length === i + 1 ? '' : ':'
      }`;
    });
  } else if (type === 'pallet') {
    const items = Object.values(item.packageDetails).filter(
      (it: any) => it.quantity
    );

    items.forEach((pack: any, i: any) => {
      quantity += pack.quantity;
      url += `${palletSizes[pack.palletSizeId]},${pack.quantity}${
        items.length === i + 1 ? '' : ':'
      }`;
    });

    url += `/${item.palletAndWrap}`;
  } else if (type === 'dims') {
    item.dims.forEach((pack: any, i: any) => {
      quantity += pack.quantity;
      url += `${pack.length},${pack.width},${pack.height},${pack.weight},${
        pack.quantity
      }${item.dims.length === i + 1 ? '' : ':'}`;
    });
    url += `/${item.palletAndWrap}`;
  } else if (type === 'freight') {
    url += `${format(item.readyDate, 'MM-dd-yyyy')}/`;
    url += `${item.packageDetails.loadType},`;
    url += item.packageDetails.numPallets;
    quantity += item.packageDetails.numPallets;
  } else if (type === 'seaFreight') {
    url += `${format(item.packageDetails.readyDate, 'dd-MM-yyyy')}/${
      item.packageDetails.hazardous
    }/${item.packageDetails.stackable}/${item.packageDetails.customs}/${all}/`;
    if (load === 'full') {
      //url += 'FCL';
      url = `${landingPageUrl}${seaFreightFullLoadQuoteUrl}`;
      quantity += 26;
    } else if (load === 'less') {
      url += `LCL/${item.packageDetails.package.subType}/`;
      item.packageDetails.package.items.forEach((pack: any, i: any) => {
        quantity += pack.quantity.amount;
        url += `${pack.length.amount},${pack.width.amount},${
          pack.height.amount
        },${pack.weight.amount},${pack.quantity.amount}${
          item.packageDetails.package.items.length === i + 1 ? '' : ':'
        }`;
      });
    }
  }

  //query params
  url += '?type=';
  if (
    item.collectionLocation.country === 'GB' &&
    item.deliveryLocation.country === 'GB'
  ) {
    url += 'Domestic';
  } else if (
    item.collectionLocation.country === 'GB' &&
    euCountries.includes(item.deliveryLocation.country)
  ) {
    url += 'ExportEurope';
  } else if (
    euCountries.includes(item.collectionLocation.country) &&
    item.deliveryLocation.country === 'GB'
  ) {
    url += 'ImportEurope';
  } else if (
    item.collectionLocation.country === 'GB' &&
    !euCountries.includes(item.deliveryLocation.country)
  ) {
    url += 'ExportROW';
  } else if (
    !euCountries.includes(item.collectionLocation.country) &&
    item.deliveryLocation.country === 'GB'
  ) {
    url += 'ImportROW';
  } else if (
    euCountries.includes(item.collectionLocation.country) &&
    euCountries.includes(item.deliveryLocation.country)
  ) {
    url += 'ThirdEurope';
  } else if (
    !euCountries.includes(item.collectionLocation.country) &&
    !euCountries.includes(item.deliveryLocation.country)
  ) {
    url += 'ThirdROW';
  } else if (
    euCountries.includes(item.collectionLocation.country) &&
    !euCountries.includes(item.deliveryLocation.country)
  ) {
    url += 'ThirdInt';
  } else if (
    !euCountries.includes(item.collectionLocation.country) &&
    euCountries.includes(item.deliveryLocation.country)
  ) {
    url += 'ThirdInt';
  }

  url += '&rank=';

  if (type === 'parcel' || quantity <= 3) {
    url += 'four';
  } else if (quantity <= 10) {
    url += 'three';
  } else if (quantity <= 15) {
    url += 'two';
  } else {
    url += 'one';
  }

  return url;
};
