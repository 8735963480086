import React from 'react';
import styled from 'styled-components';
import { Caption, P } from '../../../../../../assets/elements';
import device from '../../../../../utils/device';

const DateTile = ({ label, value, caption, selected, onChange }: any) => {
  return (
    <Wrapper>
      <Container selected={selected} onClick={() => onChange(value)}>
        <P>{label}</P>
        {caption && <Caption>{caption}</Caption>}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex: 0 0 25%;
  max-width: 25%;
  height: 68px;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 4px;

  @media ${device.desktop} {
    flex: 0 0 20%;
    max-width: 20%;
  }
`;

const Container = styled.div.attrs(({ selected }: any) => ({
  selected,
}))`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  background: ${({ theme }: any) => theme.colors.White};
  border: 2px solid
    ${({ theme, selected }: any) =>
    selected ? theme.colors.Secondary : theme.colors.WhiteGrey};
  border-radius: ${({ theme }: any) => theme.box.BorderRadiusSmall};
  padding: 10px 10px;
  cursor: pointer;
`;

export default DateTile;
