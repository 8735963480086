import React from 'react';
import styled from 'styled-components';
import { Container } from '../../../../../../../../assets/elements';
import Delivery from './Delivery';
import device from '../../../../../../../utils/device';

const DeliveryDetails = (props: any) => {
  return (
    <Wrapper>
      <Delivery {...props} />
    </Wrapper>
  );
};
const Wrapper = styled(Container)`
  background: #ffffff;
  border-radius: 8px;
  && {
    padding: 15px;
    @media ${device.tablet} {
      padding: 30px;
    }
  }
  height: auto;
  margin: 40px 0;
`;

export default DeliveryDetails;
