import React from 'react';
import styled from 'styled-components';
import SortItem from './SortItem';
import device from '../../utils/device';

const Sort = ({ options, selected, onChange }: any) => {
  return (
    <Wrapper>
      {options.map(({ id, label }: any) => {
        return (
          <SortItem
            key={id}
            id={id}
            selected={selected === id}
            onClick={onChange}
          >
            {label}
          </SortItem>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  margin-bottom: 24px;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`;

export default Sort;
