import React from 'react';
import { Checkbox, withStyles } from '@material-ui/core';
import styled, { withTheme } from 'styled-components';
import { PSmall } from '../../../assets/elements';

const CheckBoxInput = ({
  label,
  checked,
  onChange,
  theme,
}: any) => {
  const CheckBoxStyled = withStyles({
    root: {
      color: theme.colors.LightGrey,
      '&$checked': {
        color: theme.colors.Secondary,
      },
      width: '18px',
      height: '18px',
      marginRight: '10px',
    },
    checked: {},
  })((props: any) => <Checkbox color="default" {...props} />);

  return (
    <Wrapper>
      <CheckBoxStyled
        checked={checked}
        onChange={onChange}
        color="default"
        inputProps={{ 'aria-label': 'checkbox with default color' }}
        size="small"
      />
      <PSmall>{label}</PSmall>
    </Wrapper>
  );
};

const Wrapper = styled.div<any>`
  display: flex;
  width: auto;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 20px;
`;

export default withTheme(CheckBoxInput);
