import { io } from 'socket.io-client';
import { environment, WEBSOCKET_URL } from '../env';
import { getCookie } from '../cookies';

const token = getCookie('accessToken');

let socket = io(WEBSOCKET_URL[environment.toUpperCase()].url, {
  path: '/v1/socket',
  withCredentials: true,
  transports: ['websocket'],
  secure: WEBSOCKET_URL[environment.toLocaleUpperCase()].secure
});

export const authorize = (cb: any) => {
  if (!token) {
    return;
  }

  socket.emit('authentication', token);

  socket.on('authentication', (result: any) => cb(result));
};

socket.on('disconnect', (reason: any) => {
  console.log(`DISCONNECTING FROM SOCKET ${ reason }`);
});

export const importStatus = (cb: any) => {
  socket.on('import_success', (data: any) => cb(data));
};

export const importFailed = (cb: any) => {
  socket.on('import_failed', (error: any) => cb(error));
};

export const disconnectSocket = () => {
  socket.disconnect();

  socket = io(WEBSOCKET_URL[environment.toUpperCase()].url, {
    path: '/v1/socket',
    withCredentials: true,
    transports: ['websocket'],
    secure: WEBSOCKET_URL[environment.toLocaleUpperCase()].secure
  });
};
