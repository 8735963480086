import React from 'react';
import styled from 'styled-components';

export const Error = ({ children }: any) => <Wrapper>{children}</Wrapper>;

const Wrapper = styled.div`
  display: inline-block;
  padding: 12px;
  background: ${({ theme }: any) => theme.colors.ErrorBackground};
  border-radius: 4px;
  color: ${({ theme }: any) => theme.colors.Error};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin-bottom: 24px;
`;
