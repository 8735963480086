import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import PostcodeBox from './PostcodeBox';
import ManuallyBox from './ManuallyBox';

const TypeCollection = (props: any) => {
  const [inputManually, setInputManually] = React.useState<any>(true);

  const handleTypeChange = () => {
    setInputManually(!inputManually);
  };

  const { zip: postcode } = useSelector<any, any>(
    (state) => state.order.collectionAddress
  );

  useEffect(() => {
    postcode.length && setInputManually(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <H2>Collection address</H2>
      {!inputManually ? <PostcodeBox {...props} setManually={setInputManually} /> : <ManuallyBox {...props} />}
      <ActionText onClick={() => handleTypeChange()}>
        {!inputManually ? 'Enter address manually' : 'Enter postcode'}
      </ActionText>
    </>
  );
};

const H2 = styled.h2`
  font-size: 32px;
  line-height: 135%;
  color: ${({ theme }: any) => theme.colors.Black};
`;
const ActionText = styled.p`
  margin-top: 50px;
  cursor: pointer;
  color: ${({ theme }: any) => theme.colors.Secondary};
`;

export default TypeCollection;
