/*
* M: Mobile devices
* L: Larger devices (Laptop/Tablet)
*
* FS: Font size
* */

export const mpd = {
  Primary: '#5FB047',
  Secondary: '#EF6224',
};