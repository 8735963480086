import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Caption, Input } from '../../../../../../assets/elements';
import { goToNextQuickBookStep, setRecipientInfo } from '../../../../../store/actions';
import { SelectInput } from '../../../../Inputs';
import { VATStatusOptions } from '../../../../../utils/variables';
import MobileNumber from '../../../../../../assets/Combos/MobileNumber';
import ColumnWrapper from '../../Elements/ColumnWrapper';

const RecipientDetails = ({
  setDone,
  theme,
  customs,
  recipientDetails,
  setRecipientDetails,
  recipientDetailsErrors,
  setRecipientDetailsErrors,
}: any) => {
  const dispatch = useDispatch();

  const details = useSelector((state: any) => state.order.recipientDetails);
  const country = useSelector((state: any) => state.order.deliveryAddress.country || 'GB');

  const [detailsComplete, setDetailsComplete] = useState(false);

  useEffect(() => {
    setRecipientDetails({
      ...recipientDetails,
      name: `${details.firstName}${details.lastName ? ` ${details.lastName}` : ''}`,
      email: details.email,
      phone: details.phone,
      customsData: {
        ...recipientDetails.customsData,
        ...details.customsData,
      },
    });
  }, [details]);

  useEffect(() => {
    let done = true;

    for (const [key] of Object.entries(recipientDetailsErrors)) {
      if (key !== 'customsData') {
        if (recipientDetails[key] === '') {
          done = false;
        }
      } else {
        if (recipientDetails.customsData.VATStatus === '') {
          done = false;
        }

        if (recipientDetails.customsData.VATStatus === 'company_vat') {
          if (
            recipientDetails.customsData.VATNumber === '' ||
            recipientDetails.customsData.EORINumber === ''
          ) {
            done = false;
          }
        }
      }
    }

    setDetailsComplete(done);
  }, [recipientDetails]);

  useEffect(() => {
    setDone(detailsComplete);

    dispatch(setRecipientInfo(recipientDetails));
  }, [detailsComplete]);

  const validateDetails = () => {
    const errors = { ...recipientDetailsErrors };
    let error = false;

    for (const [key] of Object.entries(recipientDetailsErrors)) {
      if (key !== 'customsData') {
        if (recipientDetails[key] === '') {
          errors[key] = 'Required';
          error = true;
        }

        if (key === 'email' &&
          !recipientDetails[key]
            .match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/)
        ) {
          errors[key] = 'Enter valid email';
          error = true;
        }

        if (key === 'phone' &&
          !recipientDetails[key]
            .match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
        ) {
          errors[key] = 'Enter valid number';
          error = true;
        }
      } else {
        if (recipientDetails.customsData.VATStatus === '') {
          error = true;
          errors.customsData.VATStatus = 'Required';
        }

        if (recipientDetails.customsData.VATStatus === 'company_vat') {
          if (recipientDetails.customsData.VATNumber === '') {
            error = true;
            errors.customsData.VATNumber = 'Required';
          }

          if (recipientDetails.customsData.EORINumber === '') {
            error = true;
            errors.customsData.EORINumber = 'Required';
          } else if (recipientDetails.customsData.EORINumber.length > 17) {
            error = true;
            errors.customsData.EORINumber = 'EORI numbers should be 17 characters or less';
          }
        }
      }
    }

    setRecipientDetailsErrors({
      ...recipientDetailsErrors,
      ...errors,
    });

    return error;
  };

  const handleSubmit = () => {
    const error = validateDetails();

    if (error) {
      return;
    }

    dispatch(setRecipientInfo(recipientDetails));
    dispatch(goToNextQuickBookStep());
  };

  const handleChange = (value: any, key: any) => {
    if (recipientDetailsErrors[key]) {
      setRecipientDetailsErrors({
        ...recipientDetailsErrors,
        [key]: false,
      });
    }

    setRecipientDetails({
      ...recipientDetails,
      [key]: value,
    });
  };

  const handleCustomsChange = (value: any, key: any) => {
    setRecipientDetailsErrors({
      ...recipientDetailsErrors,
      customsData: {
        ...recipientDetailsErrors.customsData,
        [key]: false,
      },
    });

    setRecipientDetails({
      ...recipientDetails,
      customsData: {
        ...recipientDetails.customsData,
        [key]: value,
      }
    });
  };

  return (
    <ColumnWrapper title="Contact info">
      <>
        <InputContainer>
          <Label>First name and last name</Label>
          <Input
            placeholder="Name"
            value={recipientDetails.name}
            onChange={(e: any) => handleChange(e.target.value, 'name')}
            theme={theme}
            type="text"
            error={recipientDetailsErrors.name}
          />
          {
            recipientDetailsErrors.name && (
              <ErrorText>{ recipientDetailsErrors.name }</ErrorText>
            )
          }
        </InputContainer>
        <InputContainer>
          <Label>Email address</Label>
          <Input
            placeholder="Email address"
            value={recipientDetails.email}
            onChange={(e: any) => handleChange(e.target.value, 'email')}
            theme={theme}
            type="text"
            error={recipientDetailsErrors.email}
          />
          {
            recipientDetailsErrors.email && (
              <ErrorText>{ recipientDetailsErrors.email }</ErrorText>
            )
          }
        </InputContainer>
        <InputContainer>
          <Label>Mobile number required to recieve free SMS notifications</Label>
          <MobileNumber
            country={country}
            value={recipientDetails.phone}
            onChange={(selected: any) => handleChange(selected, 'phone')}
            error={recipientDetailsErrors.phone}
          />
        </InputContainer>
        {
          customs && (
            <>
              <InputContainer>
                <Label>VAT Status</Label>
                <SelectInput
                  error={recipientDetailsErrors.customsData.VATStatus}
                  resetError={() => {}}
                  value={recipientDetails.customsData.VATStatus}
                  onChange={(selected: any) => handleCustomsChange(selected, 'VATStatus')}
                  data={VATStatusOptions}
                  height="48px"
                />
                {
                  recipientDetailsErrors.customsData.VATStatus && (
                    <ErrorText>{ recipientDetailsErrors.customsData.VATStatus }</ErrorText>
                  )
                }
              </InputContainer>
              {
                recipientDetails.customsData.VATStatus === 'company_vat' && (
                  <>
                    <InputContainer>
                      <Label>VAT Number</Label>
                      <Input
                        placeholder="VAT Number"
                        value={recipientDetails.customsData.VATNumber}
                        onChange={(e: any) => handleCustomsChange(e.target.value, 'VATNumber')}
                        theme={theme}
                        type="text"
                        error={recipientDetailsErrors.customsData.VATNumber}
                      />
                      {
                        recipientDetailsErrors.customsData.VATNumber && (
                          <ErrorText>{recipientDetailsErrors.customsData.VATNumber}</ErrorText>
                        )
                      }
                    </InputContainer>
                    <InputContainer>
                      <Label>EORI Number</Label>
                      <Input
                        placeholder="EORI Number"
                        value={recipientDetails.customsData.EORINumber}
                        onChange={(e: any) => handleCustomsChange(e.target.value, 'EORINumber')}
                        theme={theme}
                        type="text"
                        error={recipientDetailsErrors.customsData.EORINumber}
                      />
                      {
                        recipientDetailsErrors.customsData.EORINumber && (
                          <ErrorText>{recipientDetailsErrors.customsData.EORINumber}</ErrorText>
                        )
                      }
                    </InputContainer>
                  </>
                )
              }
            </>
          )
        }
      </>
    </ColumnWrapper>
  );
};

const SavedDetails = styled.div`
  color: ${({ theme }: any) => theme.colors.LightGrey};
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
`;

const SavedSecondLine = styled.div``;

const SecondLineData = styled.span`
  display: inline-block;
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
`;

export const InputContainer = styled.div.attrs(({ half = false }: any) => ({
  half,
}))`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: ${({ half }: any) => half ? '50%' : '100%'};

  &:last-child {
    margin-bottom: 0;
  }

  position: relative;
`;

export const Label = styled.label`
  display: flex;
  color: ${({ theme }: any) => theme.colors.MidGrey};
  font-size: 14px;
  line-height: 120%;
  font-weight: 500;
  margin-bottom: 6px;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

export default RecipientDetails;
