import React, { useEffect } from 'react';
import styled from 'styled-components';

const PasswordCriteria = ({
  password,
  active,
  criteriaMeet,
  setCriteriaMeet,
}: any) => {
  useEffect(() => {
    const checkCriteria = () => {
      const newCriteriaMeet = {
        lowercase: /[a-z]/.test(password),
        uppercase: /[A-Z]/.test(password),
        number: /[0-9]/.test(password),
        special: /(?=.*[!@#$%^&*])/.test(password),
        minimum: password.length > 8,
        maximum: password.length < 50,
      };

      setCriteriaMeet(newCriteriaMeet);
    };
    checkCriteria();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return (
    <Wrapper active={active}>
      <List>
        <ListItem active={criteriaMeet.lowercase}>
          One lowercase character
        </ListItem>
        <ListItem active={criteriaMeet.uppercase}>
          One uppercase character
        </ListItem>
        <ListItem active={criteriaMeet.number}>One number</ListItem>
        <ListItem active={criteriaMeet.special}>One special character</ListItem>
        <ListItem active={criteriaMeet.minimum}>8 characters minimum</ListItem>
        <ListItem active={criteriaMeet.maximum}>50 characters maximum</ListItem>
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div<any>`
  max-height: ${({ active }) => (active ? '500px' : '0')};
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
`;
const List = styled.ul`
  list-style: inside;
  padding-left: 5px;
`;
const ListItem = styled.li<any>`
  color: ${({ active }) => (active ? 'black' : '#aaa')};
  display: list-item;
  padding-top: 5px;
`;

export default PasswordCriteria;
