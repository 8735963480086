import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import device from '../../utils/device';
import {
  A,
  Button,
  Caption,
  Column,
  Container,
  H4,
  Input,
  Label,
  P,
  PSmall,
  Row,
} from '../../../assets/elements';
import { setBackToTokenExpired, setUserSession } from '../../store/actions';
import { setCookie } from '../../utils/cookies';
import { landingPageUrl } from '../../utils/env';
import { logInUser } from '../../utils/APICalls/Account';
import { logo } from '../../../assets/styles/themes';
import { GTMEvent } from '../../utils/tracking';
import LoginHelp from './LoginHelp';

const Login = ({ theme }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loginCredentials, setLoginCredentials] = useState({
    email: '',
    password: '',
  });

  const [loginErrors, setLoginErrors] = useState<any>({
    email: false,
    password: false,
  });

  const [signingIn, setSigningIn] = useState(false);

  const [loginError, setLoginError] = useState<any>(false);

  const [helpModal, setHelpModal] = useState(false);

  const backToTokenExpired = useSelector(
    (state: any) => state.user.backToTokenExpired
  );

  const handleChange = (e: any, key: any) => {
    setLoginErrors({
      ...loginErrors,
      [key]: false,
    });

    setLoginCredentials({
      ...loginCredentials,
      [key]: e.target.value,
    });
  };

  const handleForgotPassword = (e: any) => {
    e.nativeEvent.preventDefault();

    history.push('/forgot-password');
  };

  const usernameHelp = () => {
    setHelpModal(true);
  };

  const handleSignup = (e: any) => {
    e.nativeEvent.preventDefault();

    history.push('/register');
  };

  const validateForm = () => {
    let errors = { ...loginErrors };
    let error = false;

    if (loginCredentials.email === '') {
      errors = {
        ...errors,
        email: 'Required',
      };

      error = true;
    } else if (
      !loginCredentials.email.match(
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/
      )
    ) {
      errors = {
        ...errors,
        email: 'Enter valid email',
      };

      error = true;
    }

    if (loginCredentials.password === '') {
      errors = {
        ...errors,
        password: 'Required',
      };

      error = true;
    }

    setLoginErrors({
      ...loginErrors,
      ...errors,
    });

    return error;
  };

  const handleSubmit = (e: any) => {
    e.nativeEvent.preventDefault();

    setLoginError(false);
    const error = validateForm();

    if (!error) {
      setSigningIn(true);

      logInUser({
        user: {
          email: loginCredentials.email,
        },
        password: loginCredentials.password,
      })
        .then((res: any) => {
          const { user, tokens, expires } = res.data;

          GTMEvent('userLogin');

          dispatch(
            setUserSession({
              userName: user.name,
              userId: user.id,
              accessToken: tokens.accessToken,
              refreshToken: tokens.refreshToken,
            })
          );

          setCookie([
            {
              name: 'accessToken',
              value: tokens.accessToken,
              expiry: new Date(
                Number(expires.access_token) * 1000
              ).toUTCString(),
            },
            {
              name: 'accessTokenExpiry',
              value: expires.access_token,
              expiry: new Date(
                Number(expires.access_token) * 1000
              ).toUTCString(),
            },
            {
              name: 'refreshToken',
              value: tokens.refreshToken,
              expiry: new Date(
                Number(expires.refresh_token) * 1000
              ).toUTCString(),
            },
            {
              name: 'refreshTokenExpiry',
              value: expires.refresh_token,
              expiry: new Date(
                Number(expires.refresh_token) * 1000
              ).toUTCString(),
            },
            {
              name: 'userName',
              value: user.name,
              expiry: new Date(
                Number(expires.refresh_token) * 1000
              ).toUTCString(),
            },
            {
              name: 'userId',
              value: user.id,
              expiry: new Date(
                Number(expires.refresh_token) * 1000
              ).toUTCString(),
            },
          ]);

          if (backToTokenExpired) {
            dispatch(setBackToTokenExpired(false));
            history.push('/token-expired');
          } else {
            window.location.href = landingPageUrl;
          }
        })
        .catch(() => {
          setSigningIn(false);
          setLoginError('Sorry! Your email address or password is incorrect');
          window.scroll(0, 0);
        });
    }
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <Column sizeL={4} sizeM={4} sizeS={3} sizeXS={4}>
            <ImageWrapper>
              <Image src={logo} alt="Site logo" />
            </ImageWrapper>
            <H4Styled>Welcome back!</H4Styled>
            <SubTitle>Sign into your account here</SubTitle>

            {loginError && <ErrorStyled>{loginError}</ErrorStyled>}

            <InputWrapper>
              <LabelStyled>Email address</LabelStyled>
              <Input
                placeholder="example@mail.com"
                value={loginCredentials.email}
                onChange={(e: any) => handleChange(e, 'email')}
                theme={theme}
                type="text"
                error={loginErrors.email}
              />
              {loginErrors.email && <ErrorText>{loginErrors.email}</ErrorText>}
            </InputWrapper>
            <InputWrapper>
              <LabelStyled>Password</LabelStyled>
              <Input
                placeholder="Enter your password"
                value={loginCredentials.password}
                onChange={(e: any) => handleChange(e, 'password')}
                theme={theme}
                type="password"
                error={loginErrors.password}
              />
              {loginErrors.password && (
                <ErrorText>{loginErrors.password}</ErrorText>
              )}
            </InputWrapper>

            <AStyled onClick={handleForgotPassword}>
              Forgot your password?
            </AStyled>

            <AStyled onClick={usernameHelp}>
              Used to sign in with username?
            </AStyled>

            <ButtonsContainer>
              <Button
                onClick={handleSubmit}
                color="secondary"
                disable={signingIn}
                disabled={signingIn}
              >
                {signingIn ? 'Signing in...' : 'Sign in'}
              </Button>
              <PStyled>
                Don't have an account? <A onClick={handleSignup}>Sign up</A>
              </PStyled>
            </ButtonsContainer>
          </Column>
        </Row>
      </Container>
      <LoginHelp
        open={helpModal}
        setOpen={setHelpModal}
        goToReset={handleForgotPassword}
      />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background: ${({ theme }: any) => theme.colors.GrayWhite};
  min-height: calc(100vh - 76px);
  display: flex;
  justify-content: center;
  padding: 32px 0;

  @media ${device.laptop} {
    padding: 48px 0;
  }
`;

const ImageWrapper = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  height: 40px;
  margin-bottom: 48px;
`;

const H4Styled = styled(H4)`
  text-align: center;
  margin-bottom: 16px;
  font-size: 25px;
  line-height: 34px;

  @media ${device.laptopL} {
    font-size: 25px;
  }
`;

const SubTitle = styled(PSmall)`
  text-align: center;
  margin-bottom: 32px;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  position: relative;
`;

const LabelStyled = styled(Label)`
  margin-bottom: 8px;
  font-weight: 400;
  text-align: left;
  color: ${({ theme }: any) => theme.colors.MidGrey};
`;

const AStyled = styled(A)`
  display: block;
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: ${({ theme }: any) => theme.colors.Black};

  &:last-of-type {
    margin-bottom: 48px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PStyled = styled(P)`
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-top: 24px;

  @media ${device.laptopL} {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
  }
`;

const ErrorStyled = styled.div`
  display: inline-block;
  padding: 12px;
  background: rgba(224, 76, 102, 0.2);
  border-radius: 4px;
  color: ${({ theme }: any) => theme.colors.Error};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin-bottom: 24px;
  text-align: center;
`;

const ErrorText = styled(Caption)`
  color: ${({ theme }: any) => theme.colors.Error};
  display: inline-block;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
`;

export default Login;
