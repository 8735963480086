import styled from 'styled-components';
import device from '../../../../app/utils/device';

export const H1 = styled.h1`
  color: ${({ theme }: any) => theme.colors.Black};
  font-size: ${({ theme }: any) => theme.typography.H1_M_FS};
  line-height: ${({ theme }: any) => theme.typography.LineHeightHeadings};
  font-weight: ${({ theme }: any) => theme.typography.FontWeightHeadings};

  @media ${device.laptopL} {
    font-size: ${({ theme }: any) => theme.typography.H1_L_FS};
  }
`;

export const H3 = styled.h4`
  color: ${({ theme }: any) => theme.colors.Black};
  font-size: ${({ theme }: any) => theme.typography.H3_M_FS};
  line-height: ${({ theme }: any) => theme.typography.LineHeightHeadings};
  font-weight: ${({ theme }: any) => theme.typography.FontWeightHeadings};

  @media ${device.laptopL} {
    font-size: ${({ theme }: any) => theme.typography.H3_L_FS};
  }
`;

export const H4 = styled.h4`
  color: ${({ theme }: any) => theme.colors.Black};
  font-size: ${({ theme }: any) => theme.typography.H4_M_FS};
  line-height: ${({ theme }: any) => theme.typography.LineHeightHeadings};
  font-weight: ${({ theme }: any) => theme.typography.FontWeightHeadings};

  @media ${device.laptopL} {
    font-size: ${({ theme }: any) => theme.typography.H4_L_FS};
  }
`;

export const H5 = styled.h5`
  color: ${({ theme }: any) => theme.colors.Black};
  font-size: ${({ theme }: any) => theme.typography.H5_M_FS};
  line-height: ${({ theme }: any) => theme.typography.LineHeightHeadings};
  font-weight: ${({ theme }: any) => theme.typography.FontWeightHeadings};

  @media ${device.laptopL} {
    font-size: ${({ theme }: any) => theme.typography.H5_L_FS};
  }
`;

export const H6 = styled.h6`
  color: ${({ theme }: any) => theme.colors.Black};
  font-size: ${({ theme }: any) => theme.typography.H6_M_FS};
  line-height: ${({ theme }: any) => theme.typography.LineHeightHeadings};
  font-weight: ${({ theme }: any) => theme.typography.FontWeightHeadings};

  @media ${device.laptopL} {
    font-size: ${({ theme }: any) => theme.typography.H6_L_FS};
  }
`;
